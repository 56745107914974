import { service } from '@ember/service';
import AuthenticatedRoute from './-base-authenticated-route';
import NavigationController from './-base-navigation-controller';
import SettingsService from 'fabscale-app/services/settings';
import { RootNavigations } from 'fabscale-app/models/default-navigation-configuration';

export abstract class NavigationRoute extends AuthenticatedRoute {
  @service settings: SettingsService;

  protected abstract rootRoute: RootNavigations;

  model({ fromBreadcrumb }: any) {
    if (fromBreadcrumb !== 'true') {
      this.transitionToDefaultPage();
    }
  }

  setupController(controller: NavigationController) {
    if (!(controller instanceof NavigationController)) {
      throw new Error(
        `Parameter 'controller' is not instanceof NavigationController.`
      );
    }
  }

  resetController(controller: NavigationController, isExiting: boolean) {
    if (isExiting) {
      controller.fromBreadcrumb = null;
    }
  }

  private transitionToDefaultPage() {
    if (this.settings.userFrontendSettings) {
      const { navigationConfig } = this.settings.userFrontendSettings;

      if (navigationConfig[this.rootRoute]?.length) {
        this.router.transitionTo(navigationConfig[this.rootRoute]!);
      }
    }
  }
}
