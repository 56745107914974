import { assert } from '@ember/debug';
import { dateIsRelative } from 'fabscale-app/utilities/utils/format-date-relative';
import { DateTime } from 'luxon';

export default function dateIsRelativeHelper(date: DateTime) {
  assert(
    'format-date-is-relative helper expects a Luxon date',
    date instanceof DateTime
  );

  return dateIsRelative(date);
}
