import { tracked } from '@glimmer/tracking';
import {
  BooleanRoastBatchParameterType,
  NumericRoastBatchParameterType,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { Unit, UnitType } from 'fabscale-app/models/enums/units';

export class EditingNumericParameter {
  parameterType: NumericRoastBatchParameterType;
  externalId?: string;
  units: UnitType;
  @tracked value?: number;
  @tracked unit: Unit;
  originalValue?: number;
  originalUnit: Unit;

  get hasChanged() {
    return (
      typeof this.value !== 'undefined' &&
      (this.value !== this.originalValue || this.unit !== this.originalUnit)
    );
  }

  constructor({
    parameterType,
    externalId,
    value,
    unit,
    units,
  }: {
    parameterType: NumericRoastBatchParameterType;
    externalId?: string;
    value?: number;
    unit: Unit;
    units: UnitType;
  }) {
    this.parameterType = parameterType;
    this.value = value;
    this.unit = unit;
    this.units = units;
    this.externalId = externalId;
    this.originalValue = value;
    this.originalUnit = unit;
  }
}

export class EditingBooleanParameter {
  parameterType: BooleanRoastBatchParameterType;
  externalId?: string;
  @tracked value?: boolean;
  originalValue?: boolean;

  get hasChanged() {
    return (
      typeof this.value !== 'undefined' && this.value !== this.originalValue
    );
  }

  get optionIndex() {
    if (this.value === true) {
      return 0;
    }

    if (this.value === false) {
      return 1;
    }

    return -1;
  }

  constructor({
    parameterType,
    externalId,
    value,
  }: {
    parameterType: BooleanRoastBatchParameterType;
    externalId?: string;
    value?: boolean;
  }) {
    this.parameterType = parameterType;
    this.value = value;
    this.externalId = externalId;
    this.originalValue = value;
  }
}
