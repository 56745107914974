import {
  tableSortData,
  TableSortDataOptions,
} from 'fabscale-app/utilities/utils/table-sort-data';

export default function dataTableSortData<T>(
  data: T[],
  {
    sortBy,
    sortDirection,
    defaultSortBy,
    defaultSortDirection,
  }: TableSortDataOptions
): T[] {
  return tableSortData(data, {
    sortBy,
    sortDirection,
    defaultSortBy,
    defaultSortDirection,
  });
}
