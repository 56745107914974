import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ReportType } from 'fabscale-app/models/enums/template';

interface Args<T> {
  label: string;
  value: T;
  options: T[];
  placeholder: string;
  allowClear: boolean;
  isRequired: boolean;
  error: any;
  // Additional args as optional ones, depending on each particular case
  reportType?: keyof typeof ReportType;
  onChange: (option: T) => void;
}

export default abstract class SharedDropdownBase<T> extends Component<Args<T>> {
  get label() {
    return this.args.label;
  }

  get value(): T {
    return this.args.value;
  }

  get options(): T[] {
    return this.args.options;
  }

  get placeholder() {
    return this.args.placeholder;
  }

  get allowClear() {
    return this.args.allowClear;
  }

  get isRequired() {
    return this.args.isRequired;
  }

  get error() {
    return this.args.error;
  }

  @action
  onChange(option: T): void {
    this.args.onChange(option);
  }
}
