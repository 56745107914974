import { DateTimeString } from 'fabscale-app';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { logError } from './log-error';
import {
  deserializeDate,
  deserializeOptionalDate,
  serializeOptionalDate,
} from './serialize-date';

export interface DateRangeSerialized {
  start?: DateTimeString;
  end?: DateTimeString;
}

export function serializeDateRange(dateRange: DateRangeOptional): string {
  let { start, end } = dateRange;

  return JSON.stringify({
    start: serializeOptionalDate(start),
    end: serializeOptionalDate(end),
  });
}

// For now, we also allow the dateRangeSerialized to be a JSON object instead of a string
// This is for backwards compatibility of existing settings
export function deserializeDateRange(dateRangeSerialized: string) {
  let start, end;

  try {
    let json =
      typeof dateRangeSerialized === 'string'
        ? JSON.parse(dateRangeSerialized)
        : dateRangeSerialized;

    ({ start, end } = json);
  } catch (error) {
    logError(
      new Error(
        `deserializeDateRange: Could not deserialize date range ${dateRangeSerialized}`
      )
    );
  }

  return new DateRangeOptional({
    start: deserializeOptionalDate(start),
    end: deserializeOptionalDate(end),
  });
}

export function deserializeDateRangeObject(dateRangeSerializedObject: {
  start: any;
  end: any;
}) {
  let start, end;

  try {
    ({ start, end } = dateRangeSerializedObject);
  } catch (error) {
    logError(
      new Error(
        `deserializeDateRange: Could not deserialize date range ${dateRangeSerializedObject}`
      )
    );
  }

  return new DateRange({
    start: deserializeDate(start),
    end: deserializeDate(end),
  });
}
