import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CalendarMode } from 'fabscale-app/components/page/maintenance-tasks/calendar';
import { MaintenanceTaskFiltersSerializable } from 'fabscale-app/components/page/maintenance-tasks/filters';
import { serializeLocalDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { TASK_CALENDAR_TODAY } from './route';

export default class TasksCalendarDateController extends Controller {
  @service router: RouterService;

  // Serialzed form
  @tracked filters: string | null = null;
  @tracked mode: CalendarMode;

  queryParams = ['filters', 'mode'];

  get actualFilters(): MaintenanceTaskFiltersSerializable {
    let { filters } = this;

    if (!filters) {
      return {
        status: 'OPEN',
      };
    }

    let parsed: MaintenanceTaskFiltersSerializable = JSON.parse(filters);

    if (!parsed.status) {
      parsed.status = 'OPEN';
    }

    return parsed;
  }

  @action
  updateFilters(filters: MaintenanceTaskFiltersSerializable) {
    let normalizedFilters =
      filters && Object.keys(filters).length > 0 ? filters : null;

    this.filters = normalizedFilters ? JSON.stringify(normalizedFilters) : null;
  }

  @action
  updateMode(mode: CalendarMode) {
    this.mode = mode;
  }

  @action
  updateDate(date: DateTime | typeof TASK_CALENDAR_TODAY) {
    this.router.transitionTo(
      'routes/maintenance.tasks.calendar.date',
      date === TASK_CALENDAR_TODAY
        ? TASK_CALENDAR_TODAY
        : serializeLocalDate(date),
      {
        queryParams: {
          mode: this.mode,
          filters: this.filters,
        },
      }
    );
  }
}
