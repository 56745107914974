import Component from '@glimmer/component';
import { ChartData, ChartOptions } from 'chart.js';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { DateRange } from 'fabscale-app/models/date-range';
import { service } from '@ember/service';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';

interface Args {
  data: ChartData;
  options: ChartOptions;
  dateRange: DateRange;
}

export default class UiDashboardCardsKpiPerPlantAssetChart extends Component<Args> {
  @service l10n: L10nService;

  get data() {
    const { data } = this.args;

    return data;
  }

  get options() {
    const { options } = this.args;

    return options;
  }

  get placeholderMessage(): string {
    const { dateRange } = this.args;
    const formattedDate = formatDate(dateRange.start, DateFormat.Date);

    return this.l10n.t(`No data found on ${formattedDate}.`);
  }
}
