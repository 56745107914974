import Component from '@glimmer/component';
import { service } from '@ember/service';
import AnalyticsService from 'fabscale-app/services/analytics';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { PlantAlarmsPerRecipe } from 'fabscale-app/models/alarm-report';

interface Args {
  alarmsPerRecipe: PlantAlarmsPerRecipe[];
  plantAlarmTypeReportFilters: any;
  intervalDefinition: any;
}

export default class PageAlarmReportTypeReportPerRecipe extends Component<Args> {
  @service analytics: AnalyticsService;
  @tracked selectedRecipesWithColor: {
    id: string;
    name: string;
    color: string;
  }[] = [];

  @tracked toggledRecipeIndex: number | undefined;

  get filters() {
    const { plantAlarmTypeReportFilters } = this.args;
    const selectedRecipeIds = this.selectedRecipesWithColor.map(
      (item) => item.id
    );
    plantAlarmTypeReportFilters.recipeIds = selectedRecipeIds;

    return plantAlarmTypeReportFilters;
  }

  get chartSelectorItems() {
    return this.selectedRecipesWithColor.map((recipe) => {
      return {
        ...recipe,
        label: `${recipe.id} (${recipe.name})`,
      };
    });
  }

  @action
  removeSelectedRecipeId(recipeId: string) {
    const selectedRecipes = this.selectedRecipesWithColor.map((item) => {
      return { id: item.id, name: item.name };
    });
    const updatedRecipes = selectedRecipes.filter(
      (recipe) => recipe.id !== recipeId
    );

    this.updateSelectedRecipes(updatedRecipes);
  }

  @action
  resetSelectedRecipeIds() {
    this.selectedRecipesWithColor = [];
  }

  @action
  updateSelectedRecipes(selectedRecipes: { id: string; name: string }[]) {
    const currentSelectedRecipesWithColor = this.selectedRecipesWithColor;
    // We want to update the selected recipes, but keep previously set colors
    // Otherwise, the colors would always change when removing a recipe from the middle (for all following recipes)
    // This is a bit tricky...
    const selectedRecipesWithOptionalColor: {
      id: string;
      name: string;
      color?: string;
    }[] = selectedRecipes.map((selectedRecipe) => {
      const existing = currentSelectedRecipesWithColor.find(
        (item) => item.id === selectedRecipe.id
      );
      if (existing) {
        return existing;
      }

      return {
        ...selectedRecipe,
        color: undefined,
      };
    });

    // Now add new colors...
    // We want to start colors after # of plant assets
    const allPlantAssetsCount = this.args.alarmsPerRecipe.length;

    const usedColors = selectedRecipesWithOptionalColor.map(
      (item) => item.color
    );

    let remainingColors = chartColors
      .slice(allPlantAssetsCount)
      .filter((color) => !usedColors.includes(color));

    const selectedRecipesWithColor = selectedRecipesWithOptionalColor.map(
      (item) => {
        const { id, name, color: optionalColor } = item;

        if (optionalColor) {
          return { id, name, color: optionalColor };
        }

        const color = remainingColors.shift()!;

        // If you select too many records, the colors repeat
        if (remainingColors.length === 0) {
          remainingColors = chartColors.slice();
        }

        return { id, name, color };
      }
    );

    this.selectedRecipesWithColor = selectedRecipesWithColor;

    this.analytics.addEvent('reports-level-report-select-recipe', [
      { name: 'selectedCount', value: selectedRecipesWithColor.length },
    ]);
  }

  @action
  toggleRecipe(recipeIndex: number) {
    if (recipeIndex === this.toggledRecipeIndex) {
      this.toggledRecipeIndex = undefined;
    } else {
      this.toggledRecipeIndex = recipeIndex;
    }
  }
}
