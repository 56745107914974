import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DeviceType } from '.';

export default class SettingsSecurityMfaSetupSelectDeviceType extends Component {
  @service l10n: L10nService;

  @tracked selectedDeviceType: DeviceType = 'ANDROID';

  availableDeviceTypes: { value: DeviceType; label: string }[];

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let { l10n } = this;
    this.availableDeviceTypes = [
      { value: 'ANDROID', label: l10n.t('Android') },
      { value: 'IOS', label: l10n.t('iOS') },
      { value: 'OTHER', label: l10n.t('Other') },
    ];
  }

  @action
  updateSelectedDeviceType(deviceType: DeviceType) {
    this.selectedDeviceType = deviceType;
  }
}
