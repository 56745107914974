import {
  TableColumnDefinition,
  TableColumnDefinitionInput,
} from 'fabscale-app/models/table-column-definition';

export default function dataTableBuildColumns(
  columns: TableColumnDefinitionInput[]
): TableColumnDefinition[] {
  return columns.map((columnData) => {
    return new TableColumnDefinition(columnData);
  });
}
