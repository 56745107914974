import { GoalResultStatus } from 'fabscale-app/models/enums/goal-result-status';

export default function getGoalResultStatusDisplay(status: GoalResultStatus): {
  colorClass: string;
  icon: string;
} {
  let colorMap: { [key in GoalResultStatus]: string } = {
    PASSED: 'color-success',
    FAILED: 'color-error',
    UNIT_NOT_CONVERTABLE: 'color-warning',
  };

  let iconMap: { [key in GoalResultStatus]: string } = {
    PASSED: 'check',
    FAILED: 'error',
    UNIT_NOT_CONVERTABLE: 'warning',
  };

  return {
    colorClass: colorMap[status],
    icon: iconMap[status],
  };
}
