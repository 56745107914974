import Route from '@ember/routing/route';
import { DateString } from 'fabscale-app';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import TasksCalendarDateController from './controller';

export const TASK_CALENDAR_TODAY = 'TODAY' as const;

export default class TasksCalendarDateRoute extends Route {
  model(params: { date: DateString | typeof TASK_CALENDAR_TODAY }) {
    let { date } = params;

    return date === TASK_CALENDAR_TODAY
      ? DateTime.local().startOf('day')
      : deserializeDate(date).startOf('day');
  }

  resetController(controller: TasksCalendarDateController, isExiting: boolean) {
    if (isExiting) {
      controller.filters = null;
    }
  }
}
