import { service } from '@ember/service';
import StorePlantAssetAlertService from 'fabscale-app/services/store/plant-asset-alert';
import BaseAsyncResource from './base-async-resource';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

interface LoadAvailableAlertExternalIdsOptions {
  alarmType: PlantAssetAlarmType;
}

export default class AvailableAlarmExternalIdsResource extends BaseAsyncResource<
  string[],
  any
> {
  @service('store/plant-asset-alert')
  plantAssetAlertStore: StorePlantAssetAlertService;

  loadData({ alarmType }: LoadAvailableAlertExternalIdsOptions) {
    return this.plantAssetAlertStore.availableAlertExternalIds(alarmType);
  }
}
