import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { MfaCodeMismatchError } from 'fabscale-app/models/errors/cognito';
import { FormDataModel } from 'fabscale-app/models/form-data';
import CognitoService from 'fabscale-app/services/cognito';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

interface Args {
  username: string;
}

export default class LoginPageMfaCodeForm extends Component<Args> {
  @service cognito: CognitoService;
  @service l10n: L10nService;
  @service userAuthentication: UserAuthenticationService;

  formData: FormData;
  formModel: FormDataModel<FormData>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;

    this.formData = new FormData();

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'mfaCode',
          message: l10n.t('The code must be 6 digits, e.g. 123456.'),
          validate: (value) => value && value.length === 6,
        },
      ],
    });
  }

  submitMfaCodeTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { cognito, userAuthentication } = this;
    let { mfaCode } = this.formData;
    let { username } = this.args;

    try {
      await cognito.mfaCompleteAuthentication(mfaCode);
      await userAuthentication.loginSuccessful(username);
    } catch (error) {
      this._handleError(error);
      return;
    }
  });

  @action
  updateMfaCode(mfaCode: string) {
    this.formModel.updateProperty('mfaCode', mfaCode);
  }

  _handleError(error: any) {
    let { l10n } = this;

    if (error instanceof MfaCodeMismatchError) {
      this.formModel.addError(
        l10n.t('The provided code is invalid, please try again.'),
        'mfaCode'
      );
      return;
    }

    this.formModel.addError(error.message || l10n.t('An error occurred!'));
  }
}

class FormData {
  @tracked mfaCode = '';
}
