import { PlantAssetAreaType } from 'fabscale-app/models/enums/plant-asset-area-types';
import { PlantAssetType } from 'fabscale-app/models/enums/plant-asset-types';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { PlantAssetModel } from 'fabscale-app/models/plant-asset-model';

export interface PlantAssetAreaPojo {
  id: string;
  name: string;
  type: PlantAssetAreaType;
  description?: string;
  plantAsset?: PlantAssetPojo;
}

export interface PlantAssetPojo {
  id: string;
  name: string;
  type: PlantAssetType;
  model?: PlantAssetModel;
  year?: string;
  referenceNumber?: string;
  position?: string;
  idPerLocation?: string;
  areas?: PlantAssetAreaPojo[];
}

export function transformPlantAssetArea({
  id,
  name,
  type,
  description,
  plantAsset,
}: PlantAssetAreaPojo): PlantAssetArea {
  let plantAssetModel = plantAsset
    ? transformPlantAsset(plantAsset)
    : undefined;

  return new PlantAssetArea({
    id,
    name,
    type,
    description,
    plantAsset: plantAssetModel,
  });
}

export function transformPlantAsset(input: PlantAssetPojo): PlantAsset {
  let plantAssetAreas = (input.areas || []).map((area) =>
    transformPlantAssetArea(area)
  );

  return new PlantAsset(
    Object.assign({}, input, {
      areas: plantAssetAreas,
    })
  );
}
