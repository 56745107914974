import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { SuccessMessage } from 'fabscale-app';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { uniq, uniqBy } from 'fabscale-app/utilities/utils/array';

export class FormDataModel<FormData extends { [key: string]: any }> {
  @tracked hasChanges = false;
  // These are all error messages that cannot be assigned to a property
  @tracked generalErrors: any[] = [];
  // These are all error messages per property
  @tracked perPropertyErrors: Partial<Record<keyof FormData, string>> = {};
  @tracked successMessage?: SuccessMessage;

  data: FormData;

  get isValid(): boolean {
    return this.errors.length === 0;
  }

  get isInvalid(): boolean {
    return !this.isValid;
  }

  constructor(options: {
    validations?: FormDataValidationInput<FormData>[];
    data: FormData;
  }) {
    assert(
      'FormDataModel: You have to specify an array of validations',
      !options.validations || Array.isArray(options.validations)
    );

    assert('FormDataModel: You have to specify data', !!options.data);

    let validations = options.validations || [];
    this.data = options.data;

    this.parseValidations(validations);
  }

  updateProperty<Prop extends keyof FormData>(
    propertyName: Prop,
    value: FormData[Prop],
    { debounced = false } = {}
  ) {
    this.data[propertyName] = value;

    if (debounced) {
      this.validatePropertyDebouncedTask.perform(propertyName);
    } else {
      this.validateProperty(propertyName);
    }

    this.hasChanges = true;
    this.successMessage = undefined;
  }

  hasSuccess({ title, description }: SuccessMessage) {
    this.successMessage = { title, description };
  }

  async validate() {
    await this.validatePropertyDebouncedTask.cancelAll();

    const allValidations = this.parsedValidations;
    const invalidValidations = await this.runValidations(allValidations);
    const { perPropertyErrors, generalErrors } =
      this.buildMessages(invalidValidations);

    this.perPropertyErrors = perPropertyErrors;
    this.generalErrors = generalErrors;

    return this.isValid;
  }

  async hasValidationErrors() {
    await this.validatePropertyDebouncedTask.cancelAll();

    const allValidations = this.parsedValidations;
    const invalidValidations = await this.runValidations(allValidations);

    return invalidValidations.length > 0;
  }

  async validateProperty(propertyName: keyof FormData) {
    const invalidValidations = await this.runValidations(
      this.parsedValidations.filter(
        (validation) => validation.propertyName === propertyName
      )
    );
    const { perPropertyErrors } = this.buildMessages(invalidValidations);
    const newPerPropertyErrors = {
      ...this.perPropertyErrors,
      ...perPropertyErrors,
    };

    if (!perPropertyErrors[propertyName]) {
      delete newPerPropertyErrors[propertyName];
    }

    this.perPropertyErrors = newPerPropertyErrors;

    return !!this.perPropertyErrors[propertyName];
  }

  clearErrors(propertyName?: keyof FormData) {
    if (propertyName) {
      const perPropertyErrors = { ...this.perPropertyErrors };
      delete perPropertyErrors[propertyName];
      this.perPropertyErrors = perPropertyErrors;
    } else {
      this.generalErrors = [];
    }
  }

  validatePropertyDebouncedTask = restartableTask(
    async (
      propertyName: keyof FormData,
      timeoutTime = TIMEOUTS.validationDebounce
    ) => {
      this.clearErrors(propertyName);
      await timeout(timeoutTime);
      await this.validateProperty(propertyName);
    }
  );

  addError(message: string, propertyName?: keyof FormData) {
    if (propertyName) {
      this.perPropertyErrors = {
        ...this.perPropertyErrors,
        [propertyName]: message,
      };
    } else {
      this.generalErrors = [...this.generalErrors, message];
    }
  }

  private parsedValidations: FormDataValidation<FormData>[] = [];

  // These are simply all error messages
  get errors() {
    const { generalErrors, perPropertyErrors } = this;
    const propertyErrors = Object.values(perPropertyErrors);

    return uniq([...generalErrors, ...propertyErrors]);
  }

  private parseValidations(validations: FormDataValidationInput<FormData>[]) {
    this.parsedValidations = validations.map(
      (validation) => new FormDataValidation(validation)
    );
  }

  private async runValidations(
    validations: FormDataValidation<FormData>[]
  ): Promise<FormDataValidation<FormData>[]> {
    const { data } = this;
    const checkedValidations = await Promise.all(
      validations.map(async (validation) => {
        const { propertyName, options } = validation;
        const value = propertyName ? data[propertyName] : undefined;
        const isValid = await validation.validate(value, {
          propertyName,
          data,
          options,
        });
        return !isValid ? validation : false;
      })
    );

    // @ts-ignore
    return checkedValidations.filter(Boolean);
  }

  private buildMessages(validations: FormDataValidation<FormData>[]) {
    const generalErrors = validations
      .filter((validation) => !validation.propertyName)
      .map((validation) => this.getMessage(validation));
    const perPropertyErrors: Partial<Record<keyof FormData, string>> = {};

    uniqBy(
      validations.filter((validation) => validation.propertyName),
      'propertyName'
    ).forEach((validation) => {
      const { propertyName } = validation;
      // @ts-ignore
      perPropertyErrors[propertyName] = this.getMessage(validation);
    });

    return {
      perPropertyErrors,
      generalErrors: uniq(generalErrors),
    };
  }

  private getMessage(validation: FormDataValidation<FormData>) {
    const { data } = this;
    const { propertyName, options } = validation;
    const value = propertyName ? data[propertyName] : undefined;

    return validation.buildMessage({ value, propertyName, data, options });
  }
}

export interface FormDataValidationInput<FormData> {
  propertyName?: keyof FormData;
  message: string;
  validate: ValidateFunction<FormData>;
  options?: any;
}

class FormDataValidation<FormData> {
  validate: ValidateFunction<FormData>;

  buildMessage: ({
    value,
    propertyName,
    data,
    options,
  }: {
    value: any;
    propertyName?: keyof FormData;
    data: any;
    options: any;
  }) => string;

  propertyName?: keyof FormData;
  options: any;

  constructor(input: FormDataValidationInput<FormData>) {
    const { propertyName, message, validate, options = {} } = input;
    const buildMessage = typeof message === 'string' ? () => message : message;

    assert(
      'FormDataModel: validate must be a function',
      typeof validate === 'function'
    );

    assert(
      'FormDataModel: message must be a string or an object',
      typeof buildMessage === 'function'
    );

    this.propertyName = propertyName;
    this.validate = validate;
    this.options = options;
    this.buildMessage = buildMessage;
  }
}

type ValidateFunction<FormData> = (
  value: any,
  options: { propertyName?: keyof FormData; data: FormData; options: any }
) => boolean;
