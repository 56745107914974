import { EnumsBase } from './-base';

export const PlantAssetAreaTypes = [
  'ROASTER_ROASTING',
  'ROASTER_COOLING',
  'ROASTER_WATER',
  'ROASTER_TRANSPORT',
  'ROASTER_EXHAUST',
  'ROASTER_PREHEATING',
  'ROASTER_HEATING',
  'ROASTER_AIR_SUPPLY',
  'OTHER',
] as const;

export type PlantAssetAreaType = typeof PlantAssetAreaTypes[number];

export class PlantAssetAreaTypesEnums extends EnumsBase<PlantAssetAreaType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in PlantAssetAreaType]: string } = {
      ROASTER_ROASTING: l10n.t('Roasting'),
      ROASTER_COOLING: l10n.t('Cooling'),
      ROASTER_WATER: l10n.t('Water'),
      ROASTER_TRANSPORT: l10n.t('Transport'),
      ROASTER_EXHAUST: l10n.t('Exhaust'),
      ROASTER_PREHEATING: l10n.t('Preheating'),
      ROASTER_HEATING: l10n.t('Heating'),
      ROASTER_AIR_SUPPLY: l10n.t('Air supply'),
      OTHER: l10n.t('Other'),
    };

    this.labels = labels;
  }
}
