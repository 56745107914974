export function getScrollParent(element: HTMLElement) {
  let overflowRegex = /(auto|scroll)/;
  let currentElement: HTMLElement | null = element;

  while (currentElement) {
    let style = window.getComputedStyle(currentElement);

    if (style.position === 'fixed') {
      break;
    }

    if (
      overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
    ) {
      return currentElement;
    }

    currentElement = currentElement!.parentElement;
  }

  return document.body;
}
