import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Transition from '@ember/routing/-private/transition';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import StatusMapController from './controller';

export default class StatusMapRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;

  afterModel() {
    if (this.abilities.cannot('view plant-asset-info')) {
      throw new PermissionError(
        this.l10n.t(
          "You don't have the permission to view plant asset status infos."
        )
      );
    }
  }

  resetController(
    controller: StatusMapController,
    isExiting: boolean,
    transition: Transition
  ) {
    controller.date = undefined;
    controller.plantAssetId = undefined;

    super.resetController(controller, isExiting, transition);
  }
}
