import transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import MaintenanceTaskManagerService from 'fabscale-app/services/maintenance-task-manager';

export default class MaintenanceTasksShowLoadingRoute extends Route {
  @service('maintenance-task-manager')
  maintenanceTaskManager: MaintenanceTaskManagerService;

  activate(transition: transition): void {
    super.activate(transition);

    let { from } = transition;
    this.maintenanceTaskManager.capturePreviousPage(from);
  }
}
