import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import UserSessionService from 'fabscale-app/services/user-session';

export default class SettingsCompanyUsersRoute extends AuthenticatedRoute {
  @service userSession: UserSessionService;
  @service userAuthentication: UserAuthenticationService;
  @service l10n: L10nService;

  async model(params: { id: string }) {
    let location = this.userSession.locations.find(
      (location) => location.id === params.id
    );

    if (!location) {
      throw new PermissionError(
        this.l10n.t("You cannot access the plant with ID '{{id}}'.", {
          id: params.id,
        })
      );
    }

    await this.userSession.setCurrentLocation(location);
    await this.userAuthentication.redirectAfterLogin();
  }
}
