import { modifier } from 'ember-could-get-used-to-this';

export function printObserver(
  _: unknown,
  [onPrint]: [({ isPrinting }: { isPrinting: boolean }) => void]
) {
  let beforePrintHandler = () => onPrint({ isPrinting: true });
  let afterPrintHandler = () => onPrint({ isPrinting: false });

  window.addEventListener('beforeprint', beforePrintHandler);
  window.addEventListener('afterprint', afterPrintHandler);

  return () => {
    window.removeEventListener('beforeprint', beforePrintHandler);
    window.removeEventListener('afterprint', afterPrintHandler);
  };
}

export default modifier(printObserver);
