import { action } from '@ember/object';
import Component from '@glimmer/component';
import { MaintenanceTaskFiltersSerializable } from '.';

interface Args {
  filters: MaintenanceTaskFiltersSerializable;
  quickOptions: { label: string; filter: MaintenanceTaskFiltersSerializable }[];
  updateFilters: (filter: MaintenanceTaskFiltersSerializable) => void;
}

export default class MaintenanceTasksFiltersQuickFilter extends Component<Args> {
  get selectedQuickOption() {
    let { quickOptions, filters } = this.args;

    return quickOptions.find(
      ({ filter }) => JSON.stringify(filter) === JSON.stringify(filters)
    );
  }

  @action
  selectQuickOption(option: {
    label: string;
    filter: MaintenanceTaskFiltersSerializable;
  }) {
    this.args.updateFilters(option.filter);
  }
}
