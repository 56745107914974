import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import RoastBatchesIndexController from './index/controller';

export default class RoastBatchesRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;

  afterModel() {
    if (this.abilities.cannot('view roast-batches')) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to view roast batches.")
      );
    }
  }

  deactivate() {
    let controller = this.controllerFor(
      'routes/roast-batches.index'
    ) as RoastBatchesIndexController;

    next(() => {
      controller.dateRange = undefined;
      controller.timeRange = undefined;
      controller.plantAssets = undefined;
      controller.recipes = undefined;
      controller.goalResults = undefined;
      controller.page = undefined;
      controller.pageSize = undefined;
      controller.sortBy = undefined;
      controller.sortDirection = undefined;
    });
  }
}
