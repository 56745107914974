import { service } from '@ember/service';
import { PageDef, SortDef } from 'fabscale-app';
import {
  RoastBatchSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import BackgroundJobService from 'fabscale-app/services/background-job';
import StoreRoastBatchService, {
  PaginatedRoastBatches,
  RoastBatchGoalResultFilter,
} from 'fabscale-app/services/store/roast-batch';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import BaseAsyncResource from './base-async-resource';

export interface LoadRoastBatchOptions {
  dateRange: DateRangeOptional;
  plantAssetIds?: string[];
  recipeIds?: string[];
  goalResults?: RoastBatchGoalResultFilter;
  // Pagination-related
  page: number;
  pageSize: number;
  sortBy: RoastBatchSortOption;
  sortDirection: SortDirection;
}

export const LOAD_ROAST_BATCHES_REFETCH_INTERVAL = 30;
const JOB_ID = 'LoadRoastBatches';

export default class LoadRoastBatchesResource extends BaseAsyncResource<
  PaginatedRoastBatches,
  LoadRoastBatchOptions
> {
  @service('store/roast-batch') roastBatchStore: StoreRoastBatchService;
  @service backgroundJob: BackgroundJobService;

  setup() {
    // Just to ensure shouldAutoRefresh is tracked
    // See: https://github.com/pzuraq/ember-could-get-used-to-this/issues/48
    this.args.named.shouldAutoRefresh;

    this.backgroundJob.registerJob({
      id: JOB_ID,
      intervalSeconds: LOAD_ROAST_BATCHES_REFETCH_INTERVAL,
      callback: () => {
        if (this.args.named.shouldAutoRefresh) {
          let [options] = this.args.positional;
          this.loadDataTask.perform(options);
        }
      },
    });

    super.setup();
  }

  teardown(): void {
    this.backgroundJob.unregisterJob(JOB_ID);

    super.teardown();
  }

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  loadData(options: LoadRoastBatchOptions) {
    let {
      page,
      pageSize,
      sortBy,
      sortDirection,
      dateRange,
      plantAssetIds,
      recipeIds,
      goalResults,
    } = options;

    let pageDef: PageDef = {
      page,
      pageSize,
    };

    let sortDef: SortDef = {
      sortBy,
      sortDirection,
    };

    let filters = {
      dateFrom: serializeOptionalDate(dateRange.start),
      dateTo: serializeOptionalDate(dateRange.end),
      plantAssetIds,
      recipeIds,
      goalResults,
    };

    return this.roastBatchStore.queryPaginated(filters, pageDef, sortDef);
  }
}
