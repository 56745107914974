import { assert } from '@ember/debug';

export default function mathDivide(a: number, b: number) {
  assert(
    `{{math-divide}} expects two numbers as arguments, but got ${a} & ${b}`,
    typeof a === 'number' && typeof b === 'number'
  );

  assert(`{{math-divie}} divider > 0 ${b}`, typeof b === 'number' && b > 0);

  return a / b;
}
