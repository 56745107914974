import { service } from '@ember/service';
import {
  SortDirection,
  TaskSortOption,
} from 'fabscale-app/models/enums/sort-options';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import StoreMaintenanceTaskService, {
  MaintenanceTaskQueryOptions,
} from 'fabscale-app/services/store/maintenance-task';
import BaseAsyncResource from './base-async-resource';

interface LoadMaintenanceTasksOptions {
  query: MaintenanceTaskQueryOptions;
  pageSize: number;
  page: number;
  sortBy?: TaskSortOption;
  sortDirection?: SortDirection;
}

export default class LoadMaintenanceTasksResource extends BaseAsyncResource<
  MaintenanceTask[],
  LoadMaintenanceTasksOptions
> {
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  async loadData({
    query,
    pageSize,
    page,
    sortBy,
    sortDirection,
  }: LoadMaintenanceTasksOptions) {
    let { tasks } = await this.maintenanceTaskStore.queryPaginated(
      query,
      {
        page,
        pageSize,
      },
      {
        sortBy: sortBy || 'DUE_DATE',
        sortDirection: sortDirection || 'ASC',
      }
    );

    return tasks;
  }
}
