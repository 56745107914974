import { assert } from '@ember/debug';
import { UnitsTime, UnitTime } from 'fabscale-app/models/enums/units';

export function convertTimeAmount(
  amount: number,
  sourceUnit: UnitTime,
  targetUnit: UnitTime
): number {
  assert(`amount is not a number`, typeof amount === 'number');
  assert(`sourceUnit is not a time unit`, UnitsTime.includes(sourceUnit));
  assert(`targetUnit is not a time unit`, UnitsTime.includes(targetUnit));

  if (sourceUnit === targetUnit) {
    return amount;
  }

  let factor = 1;

  switch (sourceUnit) {
    case 'HOUR':
      factor *= 60 * 60;
      break;
    case 'MINUTE':
      factor *= 60;
      break;
    case 'SECOND':
      break;
    case 'MILLISECOND':
      factor /= 1000;
      break;
  }

  switch (targetUnit) {
    case 'HOUR':
      factor /= 60 * 60;
      break;
    case 'MINUTE':
      factor /= 60;
      break;
    case 'SECOND':
      break;
    case 'MILLISECOND':
      factor *= 1000;
      break;
  }

  return amount * factor;
}
