import { DateTimeString } from 'fabscale-app';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { PlantAssetStatusReason } from 'fabscale-app/models/enums/plant-asset-status-reason';
import { UnitTime } from 'fabscale-app/models/enums/units';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { convertTimeAmount } from 'fabscale-app/utilities/utils/unit-converter';
import { DateTime } from 'luxon';

const TARGET_UNIT: UnitTime = 'SECOND';

export class PlantAssetStateInfo {
  id: string;
  startDate: DateTime;
  duration: number | null;
  durationUnit: UnitTime;
  status: PlantAssetStatus;
  reason?: PlantAssetStatusReason;
  comment?: string;
  plantAsset?: { id: string; name: string };

  get endDate() {
    if (!this.duration) {
      return null;
    }

    return this.startDate.plus({ seconds: this.duration });
  }

  constructor(input: PlantAssetStateInfoInput) {
    this.id = input.id;
    this.duration = input.duration
      ? convertTimeAmount(input.duration, input.unit, TARGET_UNIT)
      : null;

    this.durationUnit = TARGET_UNIT;
    this.startDate = deserializeDate(input.startDate);
    this.status = input.status;
    this.reason = input.reason;
    this.comment = input.comment;
    this.plantAsset = input.plantAsset;
  }
}

export interface PlantAssetStateInfoInput {
  id: string;
  startDate: DateTimeString;
  duration: number | null;
  status: PlantAssetStatus;
  unit: UnitTime;
  reason?: PlantAssetStatusReason;
  comment?: string;
  plantAsset?: { id: string; name: string };
}
