import { assert } from '@ember/debug';
import { PlantAlarmOverTime } from 'fabscale-app/models/alarm-report';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { groupBy, transform } from 'lodash-es';

export default function chartAlarmReportAlarmLevelsPerPlantAssetChartData(
  plantAlarmsOverTime: PlantAlarmOverTime[]
) {
  assert(
    `chartAlarmReportAlarmLevelsPerPlantAssetChartData: plantAlarmsOverTime undefined`,
    !!plantAlarmsOverTime
  );

  const filteredAlarmOverTimeItems = plantAlarmsOverTime.filter(
    (plantAlarmOverTime: PlantAlarmOverTime) =>
      ['INFO', 'WARNING', 'ALARM'].includes(plantAlarmOverTime.alarmLevel)
  );

  const alarmOverTimeItemsOrder = { ALARM: 0, WARNING: 1, INFO: 2 };

  let accumulatorObj: {
    [key: string]: number[];
  } = {};

  // Transform the response from alarm levels to a object
  // that maps each roaster to an array with each of alarm level sums
  const dataListPerPlantAsset = transform(
    filteredAlarmOverTimeItems,
    (result, plantAlarmOvertime) => {
      const alarmLevel = plantAlarmOvertime.alarmLevel;
      const perPlantAsset = groupBy(
        plantAlarmOvertime.alarmOverTimeItems,
        'idNamePair.name'
      );

      for (const [plantAssetName, alarmOverTimeItems] of Object.entries(
        perPlantAsset
      )) {
        // If the roaster does not exist in the result map,
        // initialize a new array with the values for Alarms, Warnings, Infos equal to 0
        if (result[plantAssetName] === undefined) {
          result[plantAssetName] = [0, 0, 0];
        }

        // Calculate the alarm sum from all of the date bins and adds it to the roaster data
        let alarmSum = alarmOverTimeItems[0]!.dateValueTimeSeries.reduce(
          (acc, elem) => acc + elem.value,
          0
        );
        // The result is an object with each plant asset.
        // And of each plant asset there is an array with 3 values for [Alarms, Warnings, Info]
        // Here we call the specific plantAsset and then we use an helper object to turn the alarm level into the array index
        // We will put the array index result['R8 Px120'][alarmOverTimeItemsOrder[WARNING]] with the alarmSum
        result[plantAssetName]![alarmOverTimeItemsOrder[alarmLevel]] = alarmSum;
      }
    },
    accumulatorObj
  );

  const datasets = Object.entries(dataListPerPlantAsset).map(
    ([key, value], index) => {
      return {
        label: key,
        data: value,
        backgroundColor: chartColors[index],
      };
    }
  );

  return {
    labels: ['ALARM', 'WARNING', 'INFO'],
    datasets: datasets,
  };
}
