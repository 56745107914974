import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import getOverTimeInterval from 'fabscale-app/helpers/get-over-time-interval';
import {
  AlarmLevelReportFilters,
  PlantAlarmFilters,
  PlantAlarmsOverTimeFilters,
} from 'fabscale-app/models/alarm-report';
import { TimeRange } from 'fabscale-app/models/time-range';
import SettingsService from 'fabscale-app/services/settings';
import StoreAlarmLevelReportService from 'fabscale-app/services/store/alarm-level-report';
import UserSessionService from 'fabscale-app/services/user-session';
import { getExactDateRangeWithTimeRange } from 'fabscale-app/utilities/utils/date-range';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import { DateTime } from 'luxon';

interface PageAlarmReportLevelReportArgs {
  filters: AlarmLevelReportFilters;
  updateFilters: (filters: AlarmLevelReportFilters) => void;
}

export default class PageAlarmReportLevelReport extends Component<PageAlarmReportLevelReportArgs> {
  @service settings: SettingsService;
  @service userSession: UserSessionService;
  @service('store/alarm-level-report')
  alarmLevelReportStore: StoreAlarmLevelReportService;

  @action
  updateFilters(filters: AlarmLevelReportFilters) {
    const { dateRange, timeRange, alarmLevels, plantAssetIds, recipeIds } =
      filters;
    this.args.updateFilters({
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    });
  }

  @cached
  get filters() {
    const { dateRange, timeRange, alarmLevels, plantAssetIds, recipeIds } =
      this.args.filters || {};

    return {
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    };
  }

  @cached
  get plantAlarmFilters(): PlantAlarmFilters {
    const { dateRange, alarmLevels, plantAssetIds, recipeIds } =
      this.args.filters || {};

    return {
      dateFrom: dateRange.start,
      dateTo: dateRange.end,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    };
  }

  @cached
  get intervalDefinition() {
    const { dateRange } = this.args.filters || {};
    const { startDayOfWeek, dayStartTime } = this.settings.locationSettings;
    const { timezoneName } = this.userSession.currentLocation!;

    return {
      startDayOfWeek,
      dayStartTime,
      interval: getOverTimeInterval(
        getExactDateRangeWithTimeRange(dateRange, this.timeRange)
      ),
      timezoneName,
    };
  }

  @cached
  get plantAlarmOvertimeFilters(): PlantAlarmsOverTimeFilters {
    const { dateRange, plantAssetIds, recipeIds } = this.args.filters || {};

    return {
      dateFrom: dateRange.start,
      dateTo: dateRange.end,
      plantAssetIds,
      recipeIds,
      intervalDefinition: this.intervalDefinition,
    };
  }

  get alarmLevels() {
    const { alarmLevels } = this.filters;

    return alarmLevels;
  }

  get isAlarmSelected() {
    const { filters } = this.args;
    const { alarmLevels } = filters;

    return undefined || !alarmLevels?.length || alarmLevels?.includes('ALARM');
  }

  get isWarningSelected() {
    const { filters } = this.args;
    const { alarmLevels } = filters;

    return (
      undefined || !alarmLevels?.length || alarmLevels?.includes('WARNING')
    );
  }

  get isInfoSelected() {
    const { filters } = this.args;
    const { alarmLevels } = filters;

    return undefined || !alarmLevels?.length || alarmLevels?.includes('INFO');
  }

  get timeRange(): TimeRange {
    return Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );
  }

  @cached
  get defaultTimeRange() {
    const { dayStartTime } = this.settings.locationSettings;
    const end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get overallAlarmLevelsDescription() {
    const { dateRange } = this.filters;

    return `${dateRange.start.toLocaleString(
      DateTime.DATETIME_SHORT
    )} - ${dateRange.end.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get hasAllRequiredData() {
    return Boolean(
      this.args.filters?.dateRange?.start && this.args.filters?.dateRange?.end
    );
  }
}
