import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { AlarmLevelReportFilters } from 'fabscale-app/models/alarm-report';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';

export default class AlarmReportLevelReportController extends Controller {
  queryParams = [
    'dateRange',
    'timeRange',
    'plantAssetIds',
    'alarmLevels',
    'recipeIds',
  ];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssetIds?: string;
  @tracked alarmLevels?: string;
  @tracked recipeIds?: string;

  get filters() {
    return {
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      plantAssetIds: this.parsedPlantAssetIds,
      alarmLevels: this.parsedAlarmLevels,
      recipeIds: this.parsedRecipeIds,
    };
  }

  get parsedDateRange() {
    return this.dateRange ? deserializeDateRange(this.dateRange) : undefined;
  }

  get parsedTimeRange() {
    return this.timeRange ? JSON.parse(this.timeRange) : undefined;
  }

  get parsedPlantAssetIds() {
    return this.plantAssetIds
      ? deserializeArray(this.plantAssetIds)
      : undefined;
  }

  get parsedAlarmLevels() {
    return this.alarmLevels ? deserializeArray(this.alarmLevels) : undefined;
  }

  get parsedRecipeIds() {
    return this.recipeIds ? deserializeArray(this.recipeIds) : undefined;
  }

  @action
  updateFilters(filters: AlarmLevelReportFilters) {
    const { plantAssetIds, dateRange, timeRange, alarmLevels, recipeIds } =
      filters;

    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssetIds = plantAssetIds
      ? JSON.stringify(plantAssetIds)
      : undefined;
    this.alarmLevels = alarmLevels ? serializeArray(alarmLevels) : undefined;
    this.recipeIds = recipeIds ? serializeArray(recipeIds) : undefined;
  }

  resetFilters() {
    this.dateRange =
      this.timeRange =
      this.plantAssetIds =
      this.alarmLevels =
      this.recipeIds =
        undefined;
  }
}
