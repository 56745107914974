import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import CognitoService from 'fabscale-app/services/cognito';

export default class SettingsMyProfileMfaSetupPasswordRoute extends AuthenticatedRoute {
  @service cognito: CognitoService;
  @service router: RouterService;

  afterModel() {
    if (this.cognito.hasAuthenticatedForMfaSetup) {
      this.router.replaceWith('routes/my-settings.security.mfa-setup');
    }
  }
}
