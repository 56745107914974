import Component from '@glimmer/component';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { service } from '@ember/service';
import ScreenService from 'fabscale-app/services/screen';
import { tracked } from '@glimmer/tracking';
import { isTesting } from '@embroider/macros';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import ExcelService from 'fabscale-app/services/excel';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import CellActions from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/actions';
import CellAlertLevel from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/alert-level';
import CellAlarmType from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/alarm-type';
import CellDuration from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/duration';
import CellAlertExternalId from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/alert-external-id';
import { action } from '@ember/object';

const DEFAULT_SORT_BY = 'RECORDING_DATE';
const DEFAULT_SORT_DIRECTION = 'DESC';

interface Args {
  page?: number;
  pageSize?: number;
  sortBy?: PlantAssetAlarmSortOption;
  sortDirection?: SortDirection;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: PlantAssetAlarmSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageAlarmReportTypeReportPerRoaster extends Component<Args> {
  @service screen: ScreenService;
  @service l10n: L10nService;
  @service excel: ExcelService;
  @service enumLabels: EnumLabelsService;

  @tracked currentOffset = 0;
  @tracked _shouldAutoRefresh = false;

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  get sortBy() {
    return this.args.sortBy || DEFAULT_SORT_BY;
  }

  get sortDirection() {
    return this.args.sortDirection || DEFAULT_SORT_DIRECTION;
  }

  get shouldAutoRefresh() {
    return isTesting() || this.screen.isMobile
      ? false
      : this._shouldAutoRefresh;
  }

  get columns(): TableColumnDefinitionInput[] {
    const { l10n } = this;

    const cols: TableColumnDefinitionInput[] = [
      {
        propertyName: 'sourceSystemRecordingDate',
        title: l10n.t('Recording date'),
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
        sortBy: 'RECORDING_DATE',
      },
      {
        propertyName: 'title',
        title: l10n.t('Title'),
        sortBy: 'TITLE',
      },
      {
        propertyName: 'alert.plantAsset.name',
        title: l10n.t('Plant asset'),
        sortBy: 'PLANT_ASSET',
      },
      {
        propertyName: 'alert.alarmType',
        title: l10n.t('Alarm type'),
        component: CellAlarmType,
        sortBy: 'ALARM_TYPE',
      },
      {
        propertyName: 'alert.externalId',
        title: l10n.t('Alarm external ID'),
        component: CellAlertExternalId,
        sortBy: 'ALARM_EXTERNAL_ID',
      },
      {
        propertyName: 'alert.alertLevel',
        title: l10n.t('Alarm level'),
        component: CellAlertLevel,
        sortBy: 'ALARM_LEVEL',
      },
      {
        propertyName: 'alert.sourceSystemFinishDate',
        title: l10n.t('Duration'),
        component: CellDuration,
        sortBy: 'DURATION',
      },
      {
        propertyName: '',
        title: '',
        disableSorting: true,
        component: CellActions,
        tdClass: 'mobile-order-first',
      },
    ];

    return cols;
  }

  get isDefaultSortAndPage() {
    return this.page === 1;
  }

  @action
  updateSort(sortBy: PlantAssetAlarmSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
    }
  }
}
