import { KpiData } from 'fabscale-app/models/kpi-data';
import {
  calculateTendency,
  checkTendencyIsPositive,
} from 'fabscale-app/utilities/utils/transform-kpi-data';

export default function kpiDataGetTendency(
  kpiData: KpiData,
  comparativeData: KpiData
): { tendency: number | undefined; tendencyIsPositive: boolean } {
  let { value, higherIsBetter } = kpiData;
  let previousValue = comparativeData.value;

  let tendency =
    !value && !previousValue
      ? undefined
      : calculateTendency(previousValue, value);

  let tendencyIsPositive =
    typeof tendency === 'number'
      ? checkTendencyIsPositive(tendency, higherIsBetter)
      : true;

  return { tendency: tendency ? tendency * 100 : tendency, tendencyIsPositive };
}
