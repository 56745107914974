import { macroCondition, isTesting } from '@embroider/macros';

export function scrollToTop() {
  if (macroCondition(isTesting())) {
    let container = document.querySelector('#ember-testing-container');
    container!.scrollTo(0, 0);
  } else {
    window.scrollTo(0, 0);
  }
}
