import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { cached, tracked } from '@glimmer/tracking';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import { service } from '@ember/service';
import AnalyticsService from 'fabscale-app/services/analytics';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import { dropTask } from 'ember-concurrency';
import {
  AlarmLevelReportFilters,
  AlarmLevelReportFormData,
} from 'fabscale-app/models/alarm-report';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import {
  PlantAssetAlertLevel,
  PlantAssetAlertLevels,
} from 'fabscale-app/models/enums/plant-asset-alert-level';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { use } from 'ember-could-get-used-to-this';
import LoadRecipesInUseResource from 'fabscale-app/helpers/resources/load-recipes-in-use';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import UserSessionService from 'fabscale-app/services/user-session';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import PdfService from 'fabscale-app/services/pdf';
import { DateTime } from 'luxon';
import StoreTemplate, {
  ITemplate,
  ITemplateInput,
  TemplateInput,
} from 'fabscale-app/services/store/template';
import jsonParse from 'fabscale-app/helpers/json-parse';
import { ReportType } from 'fabscale-app/models/enums/template';
import { deserializeDateRangeObject } from 'fabscale-app/utilities/utils/serialize-date-range';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';

interface Args {
  filters: AlarmLevelReportFilters;
  updateFilters: (filters: AlarmLevelReportFilters) => void;
  allPlantAssets: PlantAsset[];
  defaultTimeRange: TimeRange;
  canClose: boolean;
}

export default class PageAlarmReportLevelReportFilters extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @tracked formModel: FormDataModel<AlarmLevelReportFormData>;
  @tracked formData: AlarmLevelReportFormData;
  @tracked label: string;
  @service kpiDataFilter: KpiDataFilterService;
  @service enumLabels: EnumLabelsService;
  @service userSession: UserSessionService;
  availableTemplates: KpiDataFilterTemplate[];
  allAlarmLevels = PlantAssetAlertLevels;
  @service('store/template')
  templateService: StoreTemplate;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds,
      alarmLevels,
      recipeIds,
    } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    });

    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    this.label =
      this.availableTemplates.length > 0
        ? 'Choose template or Time frame'
        : 'Choose timeframe';
  }

  private initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    alarmLevels?: PlantAssetAlertLevel[];
    plantAssetIds?: string[];
    recipeIds?: string[];
  }) {
    const { l10n } = this;
    const { dateRange, timeRange, alarmLevels, plantAssetIds } = initialData;

    this.formData = new AlarmLevelReportFormData();

    Object.assign(this.formData, {
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
    });

    this.formModel = new FormDataModel<AlarmLevelReportFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },
          message: l10n.t('You have to select a date range.'),
        },
      ],
    });
  }

  @use
  allRecipesAsyncResource = new LoadRecipesInUseResource(() => [
    { dateRange: this.exactDateRange, pageSize: 999 },
  ]);

  get selectedAlarmLevels() {
    const { alarmLevels } = this.formData;

    if (!alarmLevels) {
      return [];
    }

    return alarmLevels
      .map((alarm) =>
        PlantAssetAlertLevels.find((alarmLevel) => alarmLevel === alarm)
      )
      .filter(Boolean) as PlantAssetAlertLevel[];
  }

  get selectedPlantAssets() {
    const { allPlantAssets } = this.args;
    const { plantAssetIds } = this.formData;

    if (!allPlantAssets || !plantAssetIds) {
      return [];
    }

    return plantAssetIds
      .map((id) => allPlantAssets.find((plantAsset) => plantAsset.id === id))
      .filter(Boolean) as PlantAsset[];
  }

  get allRecipes() {
    return getAsyncResourceData(this.allRecipesAsyncResource, []);
  }

  get selectedRecipes() {
    const { allRecipes } = this;
    const { recipeIds } = this.formData;

    if (!allRecipes || !recipeIds) {
      return [];
    }

    return allRecipes.filter((recipe) => recipeIds!.includes(recipe.id));
  }

  @cached
  get exactDateRange(): DateRange | undefined {
    const { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange,
      maxNow: true,
    });
  }

  get activeFilters(): { label: string; value: string }[] {
    const { l10n, enumLabels } = this;

    const dateStart = this.args.filters?.dateRange?.start;
    const dateEnd = this.args.filters?.dateRange?.end;

    const { alarmLevels } = this.formData;

    const filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Alarm levels'),
        value:
          alarmLevels && alarmLevels.length > 0
            ? alarmLevels
                .map((alarmLevel: PlantAssetAlertLevel) =>
                  enumLabels.plantAssetAlertLevel(alarmLevel)
                )
                .join(', ')
            : l10n.t('All alarm levels'),
      },
      {
        label: l10n.t('Roasters'),
        value:
          this.selectedPlantAssets.length > 0
            ? this.selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All roasters'),
      },
      {
        label: l10n.t('Recipes'),
        value:
          this.selectedRecipes.length > 0
            ? this.selectedRecipes.map((recipe) => recipe.name).join(', ')
            : l10n.t('All recipes'),
      },
    ];

    return filters;
  }

  get filtersForTemplateSelection() {
    const { dateRange, timeRange, alarmLevels, plantAssetIds, recipeIds } =
      this.formData;

    return {
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds: plantAssetIds,
      recipeIds,
    };
  }

  @action
  handleTemplateChange(template: ITemplate) {
    const {
      relativeTimeframe,
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    } = jsonParse(template.templateFilters);

    this.formData.relativeTimeframe = relativeTimeframe;
    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe)
      : deserializeDateRangeObject(dateRange);

    this.formData.timeRange =
      timeRange && timeRange.start && timeRange.end
        ? (timeRange as TimeRange)
        : this.args.defaultTimeRange;

    this.formData.alarmLevels = alarmLevels || [];
    this.formData.plantAssetIds = plantAssetIds || [];
    this.formData.recipeIds = recipeIds || [];
  }

  @action
  updateDateRange(dateRange: DateRange) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('alarm-level-report-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('alarm-level-report-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updateAlertLevel(alarmLevels?: PlantAssetAlertLevel[]) {
    this.formModel.updateProperty('alarmLevels', alarmLevels);

    this.analytics.addEvent('alarm-level-report-filter-alarm-levels');
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    const plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('alarm-level-report-filter-plant-assets');
  }

  @action
  updateRecipes(recipes: { id: string; name: string }[]) {
    const recipeIds = recipes.map((recipe) => recipe.id);
    this.formModel.updateProperty('recipeIds', recipeIds);

    this.analytics.addEvent('alarm-level-report-recipes', [
      { name: 'selectedCount', value: recipes.length },
    ]);
  }

  @action
  onSelectRelativeTimeframe(relativeTimeframe: RelativeTimeframe) {
    this.formData.relativeTimeframe = relativeTimeframe;
    const { timeRange } = this.formData;

    if (relativeTimeframe) {
      this.formData.dateRange = relativeTimeframeToDateRange(
        relativeTimeframe,
        timeRange
      );
    }
  }

  @action
  clearFilters() {
    this.initFormModel({
      dateRange: undefined,
      timeRange: this.args?.defaultTimeRange,
      alarmLevels: [],
      plantAssetIds: [],
      recipeIds: [],
    });
  }

  @action
  resetFilters() {
    const {
      dateRange,
      timeRange = this.args?.defaultTimeRange,
      alarmLevels = [],
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;
    this.initFormModel({
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    });
  }

  printPageTask = dropTask(async () => {
    const fileName = `fabscale-alarm-level-report-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    const isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    const {
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
      templateName,
      relativeTimeframe,
    } = this.formData;

    if (templateName) {
      const templateFilters = JSON.stringify({
        dateRange,
        relativeTimeframe,
        timeRange,
        alarmLevels,
        plantAssetIds,
        recipeIds,
      });

      const template: ITemplateInput = new TemplateInput({
        userId: this.userSession.user!.id,
        templateName: templateName,
        templateFilters: templateFilters,
        reportType: ReportType.ALARM_LEVEL_REPORT,
      });

      await this.templateService.createTemplate(template);
      this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    }

    this.args.updateFilters({
      dateRange,
      timeRange,
      alarmLevels,
      plantAssetIds,
      recipeIds,
    });

    // Push selected filters to the GTM data layer
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'filter_used',
      reportName: ReportType.ALARM_LEVEL_REPORT,
      filters: {
        dateRange: dateRange
          ? {
              start: serializeOptionalDate(dateRange.start),
              end: serializeOptionalDate(dateRange.end),
            }
          : undefined,
        timeRange,
        alarmLevels,
        plantAssetIds,
        recipeIds,
      },
    });

    return true;
  });
}
