import { DateTimeString } from 'fabscale-app';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateRange } from './date-range';

export class DateBinValue {
  value: number;
  dateRange: DateRange;

  constructor({ dateFrom, dateTo, value }: DateBinValueInput) {
    this.value = value;
    this.dateRange = new DateRange({
      start: deserializeDate(dateFrom),
      end: deserializeDate(dateTo),
    });
  }
}

export interface DateBinValueInput {
  value: number;
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
}
