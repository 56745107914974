import { EnumsBase } from './-base';

export const PlantAssetStatuses = [
  'OFF',
  'OFFLINE',
  'START_DRIVES',
  'HEATING_UP',
  'IN_PRODUCTION',
  'MACHINE_FAILURE',
  'IDLE',
  'SHUTTING_DOWN',
  'STANDBY_MANUAL',
  'STANDBY_LACK_OF_RAW_MATERIAL',
  'STANDBY_TRANSPORT_BLOCKED',
  'OTHER',
] as const;

export type PlantAssetStatus = typeof PlantAssetStatuses[number];

export class PlantAssetStatusEnums extends EnumsBase<PlantAssetStatus> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in PlantAssetStatus]: string } = {
      OFF: l10n.t('Off'),
      OFFLINE: l10n.t('No data received'),
      START_DRIVES: l10n.t('Start drives'),
      IDLE: l10n.t('Idle'),
      HEATING_UP: l10n.t('Heating up'),
      IN_PRODUCTION: l10n.t('In production'),
      MACHINE_FAILURE: l10n.t('Machine failure'),
      SHUTTING_DOWN: l10n.t('Shutting down'),
      STANDBY_MANUAL: l10n.t('Standby (manual)'),
      STANDBY_LACK_OF_RAW_MATERIAL: l10n.t('Standby (lack of raw material)'),
      STANDBY_TRANSPORT_BLOCKED: l10n.t('Standby (transport blocked)'),
      OTHER: l10n.t('Other'),
    };

    this.labels = labels;
  }
}
