import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { Comment } from 'fabscale-app/models/comment';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { UserInfo } from 'fabscale-app/models/user-info';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import { removeItem, sortBy } from 'fabscale-app/utilities/utils/array';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import {
  dateIsRelative,
  formatDateRelative,
} from 'fabscale-app/utilities/utils/format-date-relative';
import { DateTime } from 'luxon';

interface Args {
  task: MaintenanceTask;
}

interface Update {
  date: DateTime;
  user?: UserInfo;
  icon: string;
  message: string | SafeString;
  textClass?: string;
  iconBackgroundType?: string;
}

interface TimelineItem {
  date: DateTime;
  update?: Update;
  comment?: Comment;
}

export default class MaintenanceTasksDetailPageUpdates extends Component<Args> {
  @service l10n: L10nService;
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  @tracked comments: Comment[];

  @cached
  get _timelineUpdates() {
    let { l10n } = this;
    let { task } = this.args;

    let {
      creationDate,
      createdBy,
      lastModifiedDate,
      lastModifiedBy,
      completionDate,
      dueDate,
    } = task;

    let timelineItems: TimelineItem[] = [
      {
        date: creationDate!,
        update: {
          date: creationDate!,
          user: createdBy,
          icon: 'plus',
          message: htmlSafe(
            dateIsRelative(creationDate!)
              ? l10n.t('{{userName}} created this task {{todayOrYesterday}}', {
                  userName: `<span class='color-text'>${createdBy.name}</span>`,
                  todayOrYesterday: formatDateRelative(creationDate!, {
                    withTime: true,
                  }),
                })
              : l10n.t('{{userName}} created this task on {{date}}', {
                  userName: `<span class='color-text'>${createdBy.name}</span>`,
                  date: formatDate(creationDate!, DateFormat.DateTime),
                })
          ),
        },
      },
    ];

    if (completionDate) {
      timelineItems.push({
        date: completionDate,
        update: {
          date: completionDate,
          icon: 'check',
          iconBackgroundType: 'success',
          message: dateIsRelative(completionDate)
            ? l10n.t('Task was completed {{todayOrYesterday}}', {
                todayOrYesterday: formatDateRelative(completionDate, {
                  withTime: true,
                }),
              })
            : l10n.t('Task was completed on {{date}}', {
                date: formatDate(completionDate, DateFormat.DateTime),
              }),
        },
      });
    }

    if (
      lastModifiedDate!.valueOf() !== creationDate?.valueOf() &&
      lastModifiedDate!.valueOf() !== completionDate?.valueOf()
    ) {
      timelineItems.push({
        date: lastModifiedDate!,
        update: {
          date: lastModifiedDate!,
          user: lastModifiedBy,
          icon: 'edit',
          message: htmlSafe(
            dateIsRelative(lastModifiedDate!)
              ? l10n.t(
                  '{{userName}} last edited this task {{todayOrYesterday}}',
                  {
                    userName: `<span class='color-text'>${createdBy.name}</span>`,
                    todayOrYesterday: formatDateRelative(lastModifiedDate!, {
                      withTime: true,
                    }),
                  }
                )
              : l10n.t('{{userName}} last edited this task on {{date}}', {
                  userName: `<span class='color-text'>${lastModifiedBy.name}</span>`,
                  date: formatDate(lastModifiedDate!, DateFormat.DateTime),
                })
          ),
        },
      });
    }

    let duedateIsBeforeToday =
      dueDate.valueOf() < DateTime.local().startOf('day').valueOf();

    let duedateIsBeforeCompletionDate =
      completionDate && completionDate.valueOf() <= dueDate.valueOf();

    if (duedateIsBeforeToday && !duedateIsBeforeCompletionDate) {
      timelineItems.push({
        // to ensure it is sorted after creation/last modified dates
        date: dueDate.endOf('day'),
        update: {
          date: dueDate,
          icon: 'error',
          iconBackgroundType: 'error',
          textClass: 'color-error',
          message: dateIsRelative(dueDate)
            ? l10n.t('Due date reached {{todayOrYesterday}}', {
                todayOrYesterday: formatDateRelative(dueDate),
              })
            : l10n.t('Due date reached on {{date}}', {
                date: formatDate(dueDate, DateFormat.DateLong),
              }),
        },
      });
    }

    return timelineItems;
  }

  @cached
  get _timelineComments() {
    let { comments } = this;

    return comments.map((comment) => {
      return {
        date: comment.creationDate!,
        comment,
      };
    });
  }

  get timelineItems() {
    let { _timelineComments, _timelineUpdates } = this;

    let timelineItems = _timelineUpdates.concat(_timelineComments);

    return sortBy(timelineItems, 'date');
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.comments = this.args.task.comments!.slice();
  }

  createCommentTask = dropTask(
    async (
      { message }: { message: string },
      { shouldMarkAsCompleted }: { shouldMarkAsCompleted?: boolean } = {}
    ) => {
      let maintenanceTaskId = this.args.task.id;

      let comment: Comment = await this.maintenanceTaskStore.createComment(
        maintenanceTaskId,
        {
          message,
        }
      );

      if (shouldMarkAsCompleted) {
        let { completionDate } = await this.maintenanceTaskStore.updateStatus(
          maintenanceTaskId,
          {
            status: 'COMPLETED',
          }
        );

        let { task } = this.args;
        task.status = 'COMPLETED';
        task.completionDate = completionDate;
      }

      let comments = this.comments.slice();
      comments.push(comment);
      this.comments = comments;
    }
  );

  deleteCommentTask = dropTask(async (commentId: string) => {
    let maintenanceTaskId = this.args.task.id;

    await this.maintenanceTaskStore.deleteComment(commentId, {
      maintenanceTaskId,
    });

    let comments = this.comments.slice();
    let comment = comments.find((comment) => comment.id === commentId);
    removeItem(comments, comment);

    this.comments = comments;
  });

  updateCommentTask = dropTask(
    async ({ commentId, message }: { commentId: string; message: string }) => {
      let maintenanceTaskId = this.args.task.id;

      let newComment: Comment = await this.maintenanceTaskStore.updateComment(
        commentId,
        {
          message,
          maintenanceTaskId,
        }
      );

      let comments = this.comments.slice();
      let oldComment = comments.find((comment) => comment.id === commentId);

      let pos = comments.indexOf(oldComment!);
      comments[pos] = newComment;

      this.comments = comments;
    }
  );
}
