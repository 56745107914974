import { modifier } from 'ember-could-get-used-to-this';

export default modifier(function inputOnChange(
  element: HTMLInputElement,
  _: unknown,
  {
    onChange,
    onInput,
    isNumber,
  }: {
    onChange?: (value: undefined | string | number) => void;
    onInput?: (value: undefined | string | number) => void;
    isNumber?: boolean;
  }
) {
  function parseValue(value: undefined | string | number) {
    return isNumber ? parseNumber(value) : value;
  }

  let onChangeHandler = onChange
    ? () => onChange(parseValue(element.value))
    : undefined;
  let onInputHandler = onInput
    ? () => onInput(parseValue(element.value))
    : undefined;

  if (onChangeHandler) {
    element.addEventListener('change', onChangeHandler);
  }

  if (onInputHandler) {
    element.addEventListener('input', onInputHandler);
  }

  return () => {
    if (onChangeHandler) {
      element.removeEventListener('change', onChangeHandler);
    }

    if (onInputHandler) {
      element.removeEventListener('input', onInputHandler);
    }
  };
});

function parseNumber(value: undefined | string | number) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'undefined') {
    return undefined;
  }

  let parsed = parseFloat(value.replace(',', '.'));
  return Number.isNaN(parsed) ? undefined : parsed;
}
