import { scaleBand } from 'd3-scale';
import { AxisConfigOrdinal } from 'fabscale-app/components/chart';

export default function chartScaleOrdinal(
  labels: string[],
  width: number,
  padding?: { inner?: number; outer?: number }
): AxisConfigOrdinal {
  let scale = getScale({ width, labels }, padding || {});

  return {
    scale,
    bandwidth: scale.bandwidth(),
  };
}

function getScale(
  { width, labels }: { width: number; labels: string[] },
  { inner = 0.5, outer = 0.9 }
) {
  return scaleBand()
    .domain(labels)
    .range([0, width])
    .paddingInner(inner)
    .paddingOuter(outer);
}
