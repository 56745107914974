import { modifier } from 'ember-could-get-used-to-this';

export function selectOnFocus(element: HTMLInputElement) {
  let autoSelect = () => element.select();

  element.addEventListener('focus', autoSelect);

  return () => {
    element.removeEventListener('focus', autoSelect);
  };
}

export default modifier(selectOnFocus);
