import { service } from '@ember/service';
import Component from '@glimmer/component';
import { enqueueTask, dropTask } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { FilePending } from 'fabscale-app/models/file-pending';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { formatFileSize } from 'fabscale-app/utilities/utils/format-file-size';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import { FileWrapper } from 'fabscale-app/models/file-wrapper';
import StoreFileService from 'fabscale-app/services/store/file';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import ErrorParserService from 'fabscale-app/services/error-parser';

interface Args {
  inputId?: string;
  maxKb: number;
  allowedExtensions: string[];
  files: FileWrapper[];
  multiple?: boolean;
  onFileUploaded: (file: FileWrapper) => Promise<void>;
  deleteFile: (file: FileWrapper) => void;
  onFileUpdated: (file: FileWrapper) => void;
}

export default class ModuleFileUploadList extends Component<Args> {
  @service('store/file') fileStore: StoreFileService;
  @service l10n: L10nService;
  @service('error-parser') errorParser: ErrorParserService;

  guid = guidFor(this);

  @tracked filesPending: FilePending[] = [];
  @tracked errors: { fileName: string; message: string }[] = [];

  @tracked lightboxFile?: FileWrapper;

  get fileAccept() {
    return this.args.allowedExtensions
      .map((extension) => `.${extension}`)
      .join(',');
  }

  get maxSizeFormatted() {
    return formatFileSize(this.args.maxKb * 1024);
  }

  get inputId() {
    return this.args.inputId || `upload-input-${this.guid}`;
  }

  @action
  closeLightbox() {
    this.lightboxFile = undefined;
  }

  @action
  selectLightboxFile(file: FileWrapper) {
    this.lightboxFile = file;
  }

  @action
  closeError(error: { fileName: string; message: string }) {
    let errors = this.errors.slice();
    removeItem(errors, error);
    this.errors = errors;
  }

  @action
  uploadFiles(files: File[]) {
    this.errors = [];

    let date = DateTime.local();

    let fileWrappers = files
      .map((file, i) => {
        let filePending = new FilePending(file);
        // To ensure the sorting is stable
        filePending.creationDate = date.minus({
          milliseconds: i,
        });
        return filePending;
      })
      .filter((fileWrapper) => {
        return this._validateFile(fileWrapper);
      });

    let filesPending = this.filesPending.slice();
    filesPending = fileWrappers.concat(filesPending);
    this.filesPending = filesPending;

    this._uploadNextFileTask.perform();
  }

  updateFileTask = dropTask(
    async (file: FileWrapper, { name }: { name: string }) => {
      try {
        await this.fileStore.update(file.id, { name });
      } catch (error) {
        this._addError(
          file.name,
          this.l10n.t(
            ' An error occurred when trying to update the file: {{error}}',
            {
              error: this.errorParser.getErrorMessage(error),
            }
          )
        );
        return false;
      }

      this.args.onFileUpdated(file);
      return true;
    }
  );

  deleteFileTask = enqueueTask(async (file: FileWrapper) => {
    try {
      await this.args.deleteFile(file);
    } catch (error) {
      this._addError(
        file.name,
        this.l10n.t(
          ' An error occurred when trying to delete the file: {{error}}',
          {
            error: this.errorParser.getErrorMessage(error),
          }
        )
      );
    }
  });

  _uploadNextFileTask = enqueueTask(async () => {
    let filesToUpload = this.filesPending.slice().reverse();
    let [filePending] = filesToUpload;

    if (!filePending) {
      return;
    }

    await this._uploadFileTask.perform(filePending);
    this._uploadNextFileTask.perform();
  });

  _uploadFileTask = enqueueTask(async (filePending: FilePending) => {
    try {
      let file: FileWrapper = await this.fileStore.uploadFile({
        filePending,
        name: filePending.name,
      });
      await this.args.onFileUploaded(file);
    } catch (error) {
      this._addError(
        filePending.name,
        this.l10n.t(
          ' An error occurred when trying to upload the file: {{error}}',
          {
            error: this.errorParser.getErrorMessage(error),
          }
        )
      );
    }

    let filesPending = this.filesPending.slice();
    removeItem(filesPending, filePending);
    this.filesPending = filesPending;
  });

  _validateFile(filePending: FilePending): boolean {
    let { l10n, maxSizeFormatted } = this;
    let { maxKb, allowedExtensions } = this.args;

    if (!allowedExtensions.includes(filePending.extension.toLowerCase())) {
      this._addError(
        filePending.name,
        l10n.t(
          'The file type "{{extension}}" is not allowed, the allowed file types are: {{allowedTypes}}.',
          {
            extension: filePending.extension,
            allowedTypes: allowedExtensions.join(', '),
          }
        )
      );

      return false;
    }

    if (filePending.size > maxKb * 1024) {
      this._addError(
        filePending.name,
        l10n.t(
          'The file size ({{fileSize}}) exceeds the allowed size of {{allowedFileSize}}.',
          {
            fileSize: filePending.sizeFormatted,
            allowedFileSize: maxSizeFormatted,
          }
        )
      );

      return false;
    }

    if (
      (this.args.files?.length || this.filesPending.length) &&
      !this.args.multiple
    ) {
      this._addError(
        filePending.name,
        l10n.t('You can only upload a single file.')
      );

      return false;
    }

    return true;
  }

  _addError(fileName: string, message: string) {
    let errors = this.errors.slice();
    errors.push({ fileName, message });
    this.errors = errors;
  }
}
