import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import StoreRoastBatchService from 'fabscale-app/services/store/roast-batch';

export default class RoastBatchesShowRoute extends AuthenticatedRoute {
  @service('store/roast-batch') roastBatchStore: StoreRoastBatchService;

  model(params: { id: string }) {
    let { roastBatchStore } = this;
    let { id } = params;

    return roastBatchStore.findById(id);
  }
}
