export type TableGetRowInfoHandler = (record: any) => TableRowInfo;

export interface TableRowInfo {
  class?: string;
}

export default function dataTableGetRowInfo(
  getRowInfoHandler: TableGetRowInfoHandler,
  record: any
): TableRowInfo {
  if (getRowInfoHandler) {
    return getRowInfoHandler(record);
  }

  return { class: undefined };
}
