import { modifier } from 'ember-could-get-used-to-this';
import { assert } from '@ember/debug';

function getFocusableElements(element: HTMLElement) {
  return Array.from(
    element.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((element) => !element.hasAttribute('disabled')) as HTMLElement[];
}

export function TrapFocus(
  element: HTMLElement,
  [closeFn]: [
    (closeData: {
      event: KeyboardEvent;
      escape: boolean;
      focusableElements?: HTMLElement[];
      focusedElement?: HTMLElement;
    }) => void
  ],
  { autoFocus = true }
) {
  // Focus first element initially
  let focusableElements = getFocusableElements(element);
  if (autoFocus && focusableElements[0]) {
    focusableElements[0].focus({
      preventScroll: true,
    });
  }

  assert(
    `{{trap-focus}}: Must pass in an action to call when focus leaves the element`,
    typeof closeFn === 'function'
  );

  let checkFocus = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeFn({
        event,
        escape: true,
      });
      return;
    }

    if (event.key !== 'Tab') {
      return;
    }

    let focusableElements = getFocusableElements(element);

    let firstFocusableElement = focusableElements[0];
    let lastFocusableElement = focusableElements[focusableElements.length - 1];

    // If the user tabs outside of the dropdown, close it
    // This will revert focus to the toggle button
    if (
      (event.shiftKey && elementHasFocus(firstFocusableElement)) ||
      (!event.shiftKey && elementHasFocus(lastFocusableElement))
    ) {
      closeFn({
        escape: false,
        event,
        focusableElements,
        focusedElement: (document.activeElement as HTMLElement) || undefined,
      });
      event.preventDefault();
    }
  };

  element.addEventListener('keydown', checkFocus);

  return () => {
    element.removeEventListener('keydown', checkFocus);
  };
}

export default modifier(TrapFocus);

function elementHasFocus(element?: HTMLElement) {
  if (!element) {
    return false;
  }

  let activeElement = (document.activeElement as HTMLElement) || undefined;

  if (element === activeElement) {
    return true;
  }

  // Special case: For radio buttons, TAB will not move to the next radio button, but to the next input
  // So we need to consider all radio buttons "one focusable item"
  if (elementIsRadioButton(element) && elementIsRadioButton(activeElement)) {
    let name = element.getAttribute('name');
    let activeName = activeElement.getAttribute('name');
    return name === activeName;
  }

  return false;
}

function elementIsRadioButton(
  element: HTMLElement
): element is HTMLInputElement {
  return (
    element.tagName === 'INPUT' && element.getAttribute('type') === 'radio'
  );
}
