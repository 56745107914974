import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';
import { scrollToTop } from 'fabscale-app/utilities/utils/dom/scroll-to-top';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export class AuthenticatedRoute extends Route {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service router: RouterService;
  @service userAuthentication: UserAuthenticationService;

  _beforeModelRun = false;

  beforeModel(transition: Transition) {
    this._beforeModelRun = true;

    let { userAuthentication, userSession, l10n, router } = this;

    if (!userAuthentication.isAuthenticated) {
      let url = this._getTargetUrlForTransition(transition);
      userAuthentication.cacheUrlAfterLogin(url);

      userAuthentication.logout(
        l10n.t('You need to sign in to access this page.')
      );
      return;
    }

    if (!userSession.currentLocation) {
      let url = this._getTargetUrlForTransition(transition);
      userAuthentication.cacheUrlAfterLogin(url);

      router.transitionTo('routes/select-location');
    }
  }

  activate(transition: Transition) {
    super.activate(transition);
    scrollToTop();
  }

  @action
  didTransition() {
    assert(
      'You have to call `super.beforeModel(transition) when extending AuthenticatedRoute.',
      this._beforeModelRun
    );
  }

  refreshRouteIfMatches(
    transition: Transition | undefined,
    targetName: string
  ) {
    if (transition?.to?.name.startsWith(targetName)) {
      // NOTE: We wait a tick to refresh the router
      // Otherwise, this can collide with the transitioning, leading to the model hook being weirdly called twice
      next(() => this.router.refresh(targetName));
    }
  }

  _getTargetUrlForTransition(transition: Transition) {
    try {
      return this.router.urlFor(transition.to.name);
    } catch (error) {
      // Ignore, e.g. in integration tests
    }

    return undefined;
  }
}

export default AuthenticatedRoute;
