import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { TimeRange } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';

interface Args {
  timeRange?: TimeRange;
  updateTimeRange?: (timeRange: TimeRange) => void;
}

export default class UiDateRangePickerDateTimeInputs extends Component<Args> {
  @service analytics: AnalyticsService;

  @action
  updateStartTime(time: string) {
    let timeRange = Object.assign({}, this.args.timeRange, { start: time });

    this.args.updateTimeRange!(timeRange);

    this.analytics.addEvent('date-range-picker-update-time', [
      { name: 'isStart', value: 1 },
      { name: 'isEnd', value: 0 },
      { name: 'time', value: time },
    ]);
  }

  @action
  updateEndTime(time: string) {
    let timeRange = Object.assign({}, this.args.timeRange, { end: time });

    this.args.updateTimeRange!(timeRange);

    this.analytics.addEvent('date-range-picker-update-time', [
      { name: 'isStart', value: 0 },
      { name: 'isEnd', value: 1 },
      { name: 'time', value: time },
    ]);
  }
}
