import {
  getGraphqlErrorExtra,
  GraphqlError,
} from 'fabscale-app/models/errors/graphql-error';

export class ResourceInUseError extends GraphqlError {
  usedBy?: string[];
  translatedMessage?: string;

  constructor(error: any) {
    super(error);

    this.code = 'RESOURCE_IN_USE';
    let extra = getGraphqlErrorExtra(error);

    this.usedBy = extra['usedBy'];
  }
}
