import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';
import { TableColumnDefinition } from 'fabscale-app/models/table-column-definition';

interface Args {
  sortOptions: {
    sortBy: string;
    sortDirection: SortDirection;
    defaultSortBy: string;
    defaultSortDirection: SortDirection;
    updateSort: (sortBy: string) => void;
    externalSort: boolean;
  };
  columns: TableColumnDefinition[];
}

export default class UiDataTableCompactSort extends Component<Args> {
  get sortableColumns() {
    let { columns } = this.args;

    return columns.filter((column) => column.enableSorting);
  }

  @cached
  get sortSelectOptions() {
    let { sortableColumns } = this;

    return sortableColumns.map((column) => {
      return {
        value: column.sortBy,
        label: column.title,
      };
    });
  }

  get selectedSortOption() {
    let { sortSelectOptions } = this;
    let { sortBy } = this.args.sortOptions;

    return sortSelectOptions.find((option) => option.value === sortBy);
  }

  @action
  updateSortOption({ value: sortBy }: { value: string }) {
    this.args.sortOptions.updateSort(sortBy);
  }

  @action
  toggleSortDirection() {
    this.args.sortOptions.updateSort(this.args.sortOptions.sortBy);
  }
}
