import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';

export default class SettingsRolesRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;

  afterModel() {
    if (this.abilities.cannot('edit user-role')) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to edit roles.")
      );
    }
  }
}
