import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';

/*
 * This helper is activated only on _updates_ to it's arguments (both positional
 * and named). It does not run during or after initial render, or before it is
 * un-rendered (removed from the DOM).
 */
export default class RunOnUpdateHelper extends Helper<{
  PositionalArgs: [fn: (...rest: any[]) => void, ...rest: any[]];
}> {
  didRun = false;

  lastArguments?: any;

  compute([fn, ...rest]: [(...rest: any[]) => void, any[]]) {
    assert(
      `\`{{run-on-update fn}}\` expects a function as the first parameter. You provided: ${fn}`,
      typeof fn === 'function'
    );

    if (!this.didRun) {
      this.didRun = true;
      this.lastArguments = rest;
      return;
    }

    // To accomodate: https://github.com/pzuraq/ember-could-get-used-to-this/issues/49
    if (rest.some((arg, index) => arg !== this.lastArguments![index])) {
      fn(...rest);
    }

    this.lastArguments = rest;
  }
}
