import { EnumsBase } from './-base';
import {
  Unit,
  UnitsMassFlowrate,
  UnitsTemperature,
  UnitsVolume,
  UnitsWeight,
  UnitType,
  UnitVolume,
} from './units';

export const UnitSystems = ['METRIC', 'IMPERIAL', 'US_CUSTOMARY'] as const;

export type UnitSystem = typeof UnitSystems[number];

export function getDefaultUnit(
  unitSystem: UnitSystem,
  unitType: UnitType
): Unit {
  if (unitType === UnitsWeight) {
    return unitSystem === 'METRIC' ? 'KG' : 'LB';
  }

  if (unitType === UnitsTemperature) {
    return unitSystem === 'METRIC' ? 'CELSIUS' : 'FAHRENHEIT';
  }

  if (unitType === UnitsVolume) {
    let map: { [key in UnitSystem]: UnitVolume } = {
      METRIC: 'CUBIC_METER',
      IMPERIAL: 'GALLON_UK',
      US_CUSTOMARY: 'GALLON_US',
    };

    return map[unitSystem];
  }

  if (unitType === UnitsMassFlowrate) {
    return unitSystem === 'METRIC' ? 'KG_PER_HOUR' : 'LB_PER_HOUR';
  }

  return unitType[0];
}

export class UnitSystemsEnums extends EnumsBase<UnitSystem> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;

    let labels: { [key in UnitSystem]: string } = {
      METRIC: `${l10n.t('Metric')} (kg, °C, m³)`,
      US_CUSTOMARY: `${l10n.t('US Customary')} (lb, °F, us gal)`,
      IMPERIAL: `${l10n.t('Imperial')} (lb, °C, imp gal)`,
    };

    this.labels = labels;
  }
}
