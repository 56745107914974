import { KpiDataOverTimeGrouped } from 'fabscale-app/helpers/resources/load-kpi-data-over-time-grouped';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { DateTimeFormatOptions } from 'luxon';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';

export default function kpiDataOverTimeGroupedToBarChart(
  kpiDataGrouped: KpiDataOverTimeGrouped[],
  groupByPlantAsset: boolean,
  groupByRecipe: boolean,
  groupByLocation: boolean,
  dateFormat: DateTimeFormatOptions,
  allPlantAssets: PlantAsset[]
) {
  if (!kpiDataGrouped) {
    return {
      datasets: [],
    };
  }

  let mappedData = kpiDataGrouped.map(
    (item: KpiDataOverTimeGrouped, index: number) => {
      let label: string | undefined;
      if (groupByPlantAsset) {
        label = allPlantAssets.find(
          (plantAsset: PlantAsset) => plantAsset.id === item.groupId
        )?.name;
      }

      if (groupByRecipe) {
        label = item.groupId.split('-')[1];
      }

      if (groupByLocation) {
        label = item.groupId;
      }

      return {
        data: item.data!.dateBinValues,
        backgroundColor: chartColors[index],
        borderColor: chartColors[index],
        label: label,
        groupId: item.groupId,
        unit: item.data!.unit,
        parsing: {
          xAxisKey: 'dateValue',
          yAxisKey: 'value',
        },
      };
    }
  );

  mappedData.forEach((item) => {
    item.data = item.data.map((dataItem) => {
      return {
        ...dataItem,
        dateValue: dataItem.dateRange.start.toLocaleString(dateFormat),
      };
    });
  });

  return {
    datasets: mappedData,
  };
}
