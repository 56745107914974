import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { OeeKpiReportFilters } from 'fabscale-app/models/oee-kpi-report-models';
import { TimeRangeOptionalEnd } from 'fabscale-app/models/time-range';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import { serializeKpiDataFilterSettings } from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-settings';

export default class OeeKpiReportFindTemplateForFiltersHelper extends Helper<{
  PositionalArgs: [filters: OeeKpiReportFilters];
}> {
  @service kpiDataFilter: KpiDataFilterService;

  compute([filters]: [OeeKpiReportFilters]): KpiDataFilterTemplate | null {
    const availableTemplates = this.kpiDataFilter.getAvailableTemplates();

    if (!filters || !filters.dateRange) {
      return null;
    }

    const { dateRange, timeRange, oeeKpiTypes, plantAssetIds, recipeIds } =
      filters;

    const kpiFilterSettings: KpiDataFilterSettings = new KpiDataFilterSettings({
      absoluteTimeframe: dateRange,
      timeRange: timeRange as TimeRangeOptionalEnd,
      kpiTypes: oeeKpiTypes,
      plantAssetIds,
      recipeIds,
    });

    const serializedFilterSettings =
      serializeKpiDataFilterSettings(kpiFilterSettings);

    return (
      availableTemplates.find((template) => {
        return (
          serializedFilterSettings ===
          serializeKpiDataFilterSettings(template.filterSettings)
        );
      }) || null
    );
  }
}
