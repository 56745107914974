import { action } from '@ember/object';
import Component from '@glimmer/component';
import { ChartConfiguration, ChartData, Plugin, ChartOptions } from 'chart.js';
import { Chart } from 'chart.js/auto';
import { StandardLineChart } from 'fabscale-app/models/line-chart';

interface UiLineChartArgs {
  id: string;
  data: ChartData;
  options?: ChartOptions;
  plugins?: Plugin[];
  toggledDatasetIndex: number | undefined;
  forceUpdateChart: boolean | number | undefined;
}

export default class UiLineChart extends Component<UiLineChartArgs> {
  private chart: Chart;
  private canvas: HTMLCanvasElement | undefined = undefined;

  @action
  onInit() {
    const { id, data, options, plugins } = this.args;

    if (!this.canvas) {
      this.canvas = <HTMLCanvasElement>document.getElementById(id);
    }
    const ctx: CanvasRenderingContext2D | null = this.canvas.getContext('2d');

    if (ctx) {
      const config: ChartConfiguration = new StandardLineChart(
        data,
        options,
        plugins
      ).config;

      this.chart = new Chart(ctx, config);
    }
  }

  private resetDatasetHiddenStatus() {
    this.chart.data.datasets.forEach(
      (dataset: any) => (dataset.hidden = false)
    );
    this.chart.update();
  }

  @action
  forceUpdateChart() {
    const { forceUpdateChart } = this.args;

    if (forceUpdateChart !== undefined) {
      this.chart.destroy();
      this.onInit();
    }
  }

  @action
  toggleDataset() {
    const { toggledDatasetIndex } = this.args;

    this.resetDatasetHiddenStatus();

    if (toggledDatasetIndex !== undefined) {
      this.chart.data.datasets.forEach((dataset: any, index: number) => {
        if (index !== toggledDatasetIndex) {
          dataset.hidden = true;
        }
      });
      this.chart.update();
    }
  }
}
