import { action } from '@ember/object';
import Component from '@glimmer/component';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { DateTime } from 'luxon';

interface Args {
  date: DateTime;
  updateDate: (date: DateTime | undefined) => void;
  plantAssetId?: string;
  updatePlantAssetId: (plantAssetId: string) => void;
}

export default class PlantAssetStatusMapHeader extends Component<Args> {
  get dateFormatOptions(): Intl.DateTimeFormatOptions {
    return { month: 'long', year: 'numeric', day: 'numeric' };
  }

  get canGoNext() {
    return +this.args.date.startOf('day') < +DateTime.local().startOf('day');
  }

  @action
  gotoToday() {
    this.args.updateDate(undefined);
  }

  @action
  gotoPrevious() {
    let date = this.args.date.minus({ day: 1 }).startOf('day');
    this.args.updateDate(date);
  }

  @action
  gotoNext() {
    let date = this.args.date.plus({ day: 1 }).startOf('day');
    this.args.updateDate(date);
  }

  @action
  updatePlantAsset(plantAsset: PlantAsset) {
    this.args.updatePlantAssetId(plantAsset.id);
  }
}
