import { assert } from '@ember/debug';
import ColumnHeaderComponent from 'fabscale-app/components/ui/data-table/-column-header';
import SortableColumnHeaderComponent from 'fabscale-app/components/ui/data-table/-sortable-column-header';
import CellAmount from 'fabscale-app/components/ui/table/cell/amount';
import CellDate from 'fabscale-app/components/ui/table/cell/date';
import CellDefault from 'fabscale-app/components/ui/table/cell/default';
import CellNumeric from 'fabscale-app/components/ui/table/cell/numeric';
import CellToggleCheckbox from 'fabscale-app/components/ui/table/cell/toggle-checkbox';
import { CellType } from 'fabscale-app/models/enums/table';

export interface TableColumnDefinitionInput {
  title?: string;
  propertyName?: string;
  disableSorting?: boolean;
  sortBy?: string;
  component?: unknown;
  headerComponent?: unknown;
  cellData?: any;
  cellType?: CellType;
  tdClass?: string;
  thClass?: string;
  hideIfCompact?: boolean;
  noCompactTitle?: boolean;
}

export class TableColumnDefinition {
  title?: string;
  propertyName?: string;
  enableSorting: boolean;
  sortBy?: string;
  component: unknown;
  headerComponent: unknown;
  cellData: any;
  cellType: CellType;
  hideIfCompact: boolean;
  noCompactTitle: boolean;
  _tdClass?: string;
  _thClass?: string;

  constructor(options: TableColumnDefinitionInput) {
    this.title = options.title;
    this.propertyName = options.propertyName;
    this.sortBy = options.sortBy || options.propertyName;
    this.cellType = options.cellType || 'DEFAULT';
    this.cellData = options.cellData || {};
    this._tdClass = options.tdClass;
    this._thClass = options.thClass;
    this.noCompactTitle = options.noCompactTitle || false;
    this.hideIfCompact = options.hideIfCompact || false;
    this.enableSorting = Boolean(this.sortBy && !options.disableSorting);

    assert(
      'If you specify cellType=amount, you need to specify cellData.unit!',
      this.cellType !== 'AMOUNT' || !!this.cellData.unit
    );

    // Cell types: text, date, numeric, amount, default
    let componentMap = {
      DATE: CellDate,
      NUMERIC: CellNumeric,
      AMOUNT: CellAmount,
      TOGGLE_CHECKBOX: CellToggleCheckbox,
      DEFAULT: CellDefault,
    };

    this.component = options.component || componentMap[this.cellType];

    let defaultHeaderComponent = this.enableSorting
      ? SortableColumnHeaderComponent
      : ColumnHeaderComponent;

    this.headerComponent = options.headerComponent || defaultHeaderComponent;
  }

  get tdClass() {
    let { cellType, _tdClass, noCompactTitle, hideIfCompact } = this;

    let classMap = {
      NUMERIC: 'text-right nowrap',
      AMOUNT: 'text-right nowrap',
      DATE: 'nowrap',
      TOGGLE_CHECKBOX: 'width-1 padding-right-0',
      DEFAULT: '',
    };

    let tdClass = classMap[cellType];

    let classes = [tdClass, _tdClass];

    if (noCompactTitle) {
      classes.push('no-compact-title');
    }

    if (hideIfCompact) {
      classes.push('hide-if-compact');
    }

    return classes.filter(Boolean).join(' ');
  }

  get thClass() {
    let { cellType, _thClass } = this;

    let classMap = {
      AMOUNT: 'text-right',
      NUMERIC: 'text-right',
      DATE: '',
      TOGGLE_CHECKBOX: '',
      DEFAULT: '',
    };

    let thClass = classMap[cellType];

    let classes = [thClass, _thClass];
    return classes.filter(Boolean).join(' ');
  }
}
