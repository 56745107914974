import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { GoalResultStatus } from 'fabscale-app/models/enums/goal-result-status';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

export default class RoastBatchgetGoalResultMessageHelper extends Helper<{
  PositionalArgs: [status: GoalResultStatus, amountString: string];
}> {
  @service l10n: L10nService;
  @service enumLabels: EnumLabelsService;

  compute([status, amountString]: [GoalResultStatus, string]) {
    let { l10n, enumLabels } = this;

    if (status === 'FAILED') {
      return l10n.t('Goal has failed. Target was {{amount}}', {
        amount: amountString,
      });
    }

    return enumLabels.goalResultStatus(status);
  }
}
