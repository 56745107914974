import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  username?: string;
  verificationCode?: string;
}

export default class ResetPasswordIndex extends Component<Args> {
  @tracked showPasswordForm = false;
  @tracked username?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.username && this.args.verificationCode) {
      this.showPasswordForm = true;
    }

    this.username = this.args.username;
  }

  @action
  toggleShowPasswordForm(username: string) {
    this.showPasswordForm = true;
    this.username = username;
  }

  @action
  toggleShowSendVerificationCodeForm() {
    this.showPasswordForm = false;
  }
}
