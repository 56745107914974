import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import LoadPlantAssetsInUseResource from 'fabscale-app/helpers/resources/load-plant-assets-in-use';
import LoadRecipesInUseResource from 'fabscale-app/helpers/resources/load-recipes-in-use';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import AvailableDataService from 'fabscale-app/services/available-data';
import PdfService from 'fabscale-app/services/pdf';
import { RoastBatchGoalResultFilter } from 'fabscale-app/services/store/roast-batch';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { getActualDateRange, RoastBatchesOverviewFilters } from '.';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';

interface Args {
  filters: RoastBatchesOverviewFilters;
  defaultTimeRange: TimeRange;
  updateFilters: (filters: RoastBatchesOverviewFilters) => void;
}

class RoastBatchOverviewFilterFormData {
  @tracked dateRange?: DateRangeOptional;
  @tracked timeRange: TimeRange;
  @tracked plantAssetIds: string[];
  @tracked recipeIds: string[];
  @tracked goalResults?: RoastBatchGoalResultFilter;
}

export default class RoastBatchesOverviewFiltersComponent extends Component<Args> {
  @service availableData: AvailableDataService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service pdf: PdfService;

  @tracked formModel: FormDataModel<RoastBatchOverviewFilterFormData>;
  @tracked formData: RoastBatchOverviewFilterFormData;

  @cached
  get exactDateRange(): DateRange | undefined {
    let { timeRange, dateRange } = this.formData;

    return getExactDateRangeHelper({
      dateRange: getActualDateRange(dateRange),
      timeRange,
      maxNow: true,
    });
  }

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsInUseResource(() => [
    { dateRange: this.exactDateRange },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  @use
  allRecipesAsyncResource = new LoadRecipesInUseResource(() => [
    { dateRange: this.exactDateRange, pageSize: 9999 },
  ]);

  get allRecipes() {
    return getAsyncResourceData(this.allRecipesAsyncResource, []);
  }

  @cached
  get allGoalResultOptions(): {
    value: RoastBatchGoalResultFilter;
    label: string;
  }[] {
    let { l10n } = this;

    return [
      {
        value: 'NO_FAILED',
        label: l10n.t('Without failed goals'),
      },
      {
        value: 'FAILED',
        label: l10n.t('Any failed goal'),
      },
      {
        value: 'PASSED_ONLY',
        label: l10n.t('Passed goals only'),
      },
      {
        value: 'NO_GOAL',
        label: l10n.t('Without goals'),
      },
      {
        value: 'WARNING',
        label: l10n.t('Any warning'),
      },
    ];
  }

  get selectedPlantAssets() {
    let { allPlantAssets } = this;
    let { plantAssetIds } = this.formData;

    if (!allPlantAssets || !plantAssetIds) {
      return [];
    }

    return plantAssetIds
      .map((id) => allPlantAssets.find((plantAsset) => plantAsset.id === id))
      .filter(Boolean) as PlantAsset[];
  }

  get selectedRecipes() {
    let { allRecipes } = this;
    let { recipeIds } = this.formData;

    if (!allRecipes || !recipeIds) {
      return [];
    }

    return recipeIds
      .map((id) => allRecipes.find((recipe) => recipe.id === id))
      .filter(Boolean) as { id: string; name: string }[];
  }

  get selectedGoalResultOption() {
    let { goalResults } = this.formData;

    return goalResults
      ? this.allGoalResultOptions.find(
          (goalResultOption) => goalResultOption.value === goalResults
        )
      : undefined;
  }

  get activeFilters(): { label: string; value: string }[] {
    let {
      l10n,
      selectedRecipes,
      selectedPlantAssets,
      selectedGoalResultOption,
    } = this;

    let dateStart = this.args.filters.dateRange?.start;
    let dateEnd = this.args.filters.dateRange?.end;

    let filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Roasters'),
        value:
          selectedPlantAssets.length > 0
            ? selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All roasters'),
      },
      {
        label: l10n.t('Recipes'),
        value:
          selectedRecipes.length > 0
            ? selectedRecipes.map((recipe) => recipe.name).join(', ')
            : l10n.t('All recipes'),
      },
      {
        label: l10n.t('Goals'),
        value: selectedGoalResultOption
          ? selectedGoalResultOption.label
          : l10n.t('Any goal status'),
      },
    ];

    return filters;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
      goalResults,
    } = this.args.filters;

    this._initFormModel({
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
      goalResults,
    });
  }

  @action
  updateDateRange(dateRange?: DateRangeOptional) {
    this.formModel.updateProperty('dateRange', dateRange);

    this.analytics.addEvent('roast-batches-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: dateRange ? getDateRangeLength(dateRange) : -1,
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('roast-batches-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    let plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('roast-batches-filter-plant-assets', [
      { name: 'selectedCount', value: plantAssets.length },
    ]);
  }

  @action
  updateRecipes(recipes: { id: string; name: string }[]) {
    let recipeIds = recipes.map((recipe) => recipe.id);
    this.formModel.updateProperty('recipeIds', recipeIds);

    this.analytics.addEvent('roast-batches-filter-recipes', [
      { name: 'selectedCount', value: recipes.length },
    ]);
  }

  @action
  updateGoalResults(goalResult?: {
    value: RoastBatchGoalResultFilter;
    label: string;
  }) {
    this.formModel.updateProperty('goalResults', goalResult?.value);

    this.analytics.addEvent('roast-batches-filter-goal-results', [
      { name: 'status', value: goalResult?.value || 'ALL' },
    ]);
  }

  applyFiltersTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    let { dateRange, timeRange, plantAssetIds, recipeIds, goalResults } =
      this.formData;

    let actualTimeRange =
      JSON.stringify(timeRange) === JSON.stringify(this.args.defaultTimeRange)
        ? undefined
        : timeRange;

    this.args.updateFilters({
      dateRange,
      timeRange: actualTimeRange,
      plantAssetIds,
      recipeIds,
      goalResults,
    });

    // Push selected filters to the GTM data layer
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'filter_used',
      reportName: 'ROAST_BATCHES',
      filters: {
        dateRange: dateRange
          ? {
              start: serializeOptionalDate(dateRange.start),
              end: serializeOptionalDate(dateRange.end),
            }
          : undefined,
        timeRange,
        plantAssetIds,
        recipeIds,
        goalResults,
      },
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      dateRange: undefined,
      timeRange: this.args.defaultTimeRange,
      plantAssetIds: [],
      recipeIds: [],
      goalResults: undefined,
    });
  }

  @action
  resetFilters() {
    let {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
      goalResults,
    } = this.args.filters;

    this._initFormModel({
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
      goalResults,
    });
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roast-batches.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  _initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    plantAssetIds: string[];
    recipeIds: string[];
    goalResults?: RoastBatchGoalResultFilter;
  }) {
    let { dateRange, timeRange, recipeIds, plantAssetIds, goalResults } =
      initialData;

    this.formData = new RoastBatchOverviewFilterFormData();

    Object.assign(this.formData, {
      dateRange,
      timeRange,
      recipeIds,
      plantAssetIds,
      goalResults,
    });

    this.formModel = new FormDataModel<RoastBatchOverviewFilterFormData>({
      data: this.formData,
      validations: [],
    });
  }
}
