import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { service } from '@ember/service';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import { uniq } from 'fabscale-app/utilities/utils/array';
import { restartableTask } from 'ember-concurrency';
import UserSessionService from 'fabscale-app/services/user-session';
import SettingsService from 'fabscale-app/services/settings';
import AnalyticsService from 'fabscale-app/services/analytics';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { DateRange } from 'fabscale-app/models/date-range';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { KpiCompareReportFilters } from '..';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

interface Args {
  filters: KpiCompareReportFilters;
  exactDateRange: DateRange;
}

export default class KpiCompareReportOverallDataIndex extends Component<Args> {
  @service userSession: UserSessionService;
  @service settings: SettingsService;
  @service analytics: AnalyticsService;
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;
  @service('error-parser') errorParser: ErrorParserService;
  @service enumLabels: EnumLabelsService;

  @tracked error?: string;
  @tracked selectedKpiTypeA: KpiType;
  @tracked selectedKpiTypeB?: KpiType;
  @tracked highlightedKpiType?: KpiType;
  @tracked overallKpiData?: KpiData[];

  get selectedKpiTypesForChart() {
    let { selectedKpiTypeA, selectedKpiTypeB } = this;

    return uniq([selectedKpiTypeA, selectedKpiTypeB]).filter(
      (i): i is KpiType => i !== undefined
    );
  }

  get colorA() {
    return chartColors[0];
  }

  get colorB() {
    return chartColors[1];
  }

  get chartColors() {
    return chartColors;
  }

  get hasInvalidDateRange() {
    return (
      this.args.exactDateRange.end.valueOf() <=
      this.args.exactDateRange.start.valueOf()
    );
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.initialiseSelectedKpiTypes();

    this.loadOverallKpiDataTask.perform();
  }

  @action
  initialiseSelectedKpiTypes() {
    this.selectedKpiTypeA = this.args.filters.kpiTypes[0]!;
    this.selectedKpiTypeB = this.args.filters.kpiTypes[1];
  }

  @action
  updateSelectedKpiTypeA(kpiType: KpiType) {
    this.selectedKpiTypeA = kpiType;
  }

  @action
  updateSelectedKpiTypeB(kpiType: KpiType) {
    this.selectedKpiTypeB = kpiType;
  }

  @action
  updateHighlightedKpiType(kpiType: KpiType) {
    this.highlightedKpiType =
      this.highlightedKpiType === kpiType ? undefined : kpiType;
  }

  loadOverallKpiDataTask = restartableTask(async () => {
    let { exactDateRange } = this.args;
    let { kpiTypes, plantAssetIds, recipeIds } = this.args.filters;

    this.error = undefined;

    try {
      let { days } = exactDateRange.end.diff(exactDateRange.start, 'days');
      this.overallKpiData = await this._query(
        kpiTypes,
        {
          dateRange: exactDateRange,
          plantAssetIds,
          recipeIds,
        },
        {
          loadInParallel: days < 30 * 3,
        }
      );
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);

      logError(error);
    }
  });

  async _query(
    kpiTypes: KpiType[],
    variables: {
      dateRange: DateRange;
      plantAssetIds?: string[];
      recipeIds?: string[];
    },
    { loadInParallel = true } = {}
  ): Promise<KpiData[]> {
    let { kpiDataStore } = this;

    // Load all KPIs via "kpiDataMultiple", in one big request
    // For larger time periods/many KPIs, this can become very slow...
    if (loadInParallel) {
      return kpiDataStore.findMultiple(kpiTypes, variables);
    }

    // For longer time periods, this can lead to an API timeout, so we rather make the requests one by one
    return promiseQueue(kpiTypes, (kpiType) => {
      return kpiDataStore.find(kpiType, variables);
    });
  }
}
