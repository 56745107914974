import { EnumsBase } from './-base';

export type Interval = 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export class IntervalsEnums extends EnumsBase<Interval> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in Interval]: string } = {
      HOUR: l10n.t('Hour'),
      DAY: l10n.t('Day'),
      WEEK: l10n.t('Week'),
      MONTH: l10n.t('Month'),
      YEAR: l10n.t('Year'),
    };

    this.labels = labels;
  }
}
