import { assert } from '@ember/debug';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import { DateTime } from 'luxon';
import getColorForPlantAsset from 'fabscale-app/utilities/utils/get-color-for-plant-asset';

export interface TaskGroup {
  plantAsset?: PlantAsset;
  color?: string;
  label: string;
  tasks: MaintenanceTask[];
}

export default function groupByPlantAssetForDate(
  allTasks: MaintenanceTask[],
  date: DateTime,
  options: { groupByCompletionDate: boolean }
) {
  assert(
    '{{maintenance-tasks/group-by-plant-asset-for-date}}: You have to provide an array of tasks',
    Array.isArray(allTasks)
  );

  assert(
    '{{maintenance-tasks/group-by-plant-asset-for-date}}: You have to provide a date as second argument',
    date instanceof DateTime
  );

  // If a task has a completionDate, it is completed and we want to group by this field.
  // Else always group by dueDate
  let tasks = allTasks.filter((task) => {
    let taskDate = options.groupByCompletionDate
      ? task.completionDate || task.dueDate
      : task.dueDate;

    return taskDate.hasSame(date, 'day');
  });

  let groups: TaskGroup[] = [];

  let otherGroup: TaskGroup = {
    plantAsset: undefined,
    tasks: [],
    label: 'Other',
    color: undefined,
  };

  tasks.forEach((task) => {
    let { plantAsset } = task;

    if (!plantAsset) {
      otherGroup.tasks.push(task);
      return;
    }

    let group = groups.find((group) => group.plantAsset?.id === plantAsset?.id);

    if (group) {
      group.tasks.push(task);
    } else {
      group = {
        plantAsset,
        tasks: [task],
        label: plantAsset.name,
        color: getColorForPlantAsset(plantAsset),
      };
      groups.push(group);
    }
  });

  let sortedGroups = sortBy(groups, 'plantAsset.name');

  if (otherGroup.tasks.length > 0) {
    sortedGroups.push(otherGroup);
  }

  return sortedGroups;
}
