import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { FormDataModel } from 'fabscale-app/models/form-data';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface PageLoginSimulationArgs {
  email?: string;
}

export default class PageLoginSimulation extends Component<PageLoginSimulationArgs> {
  @service userAuthentication: UserAuthenticationService;
  @service l10n: L10nService;

  formData: FormData;
  formModel: FormDataModel<FormData>;

  constructor(owner: unknown, args: PageLoginSimulationArgs) {
    super(owner, args);

    this.formData = new FormData();

    if (this.args.email) {
      this.formData.email = this.args.email;
    }

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'email',
          message:
            'You must enter a valid E-Mail address to use the Fabscale app',
          validate: (value: string) => !!value && emailRegex.test(value),
        },
        {
          propertyName: 'acceptLegal',
          message: 'You must accept the legal notice to use the Fabscale app',
          validate: (value) => value,
        },
      ],
    });
    this.formModel.validate();
  }

  loginTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }
    const { userAuthentication } = this;
    const { email } = this.formData;

    await userAuthentication.loginSimulation(email);
  });

  @action
  updateEmail(email: string) {
    this.formModel.updateProperty('email', email);
  }

  @action
  changeAcceptLegal(checked: boolean) {
    this.formModel.updateProperty('acceptLegal', checked);
  }
}

class FormData {
  @tracked email = '';
  @tracked acceptLegal = false;
}
