import { assert } from '@ember/debug';
import { guidFor } from '@ember/object/internals';
import { isNone } from '@ember/utils';
import {
  TimelineBarChartData,
  TimelineBarChartDataInput,
} from 'fabscale-app/components/chart';
import { DateRange } from 'fabscale-app/models/date-range';
import { sortBy, uniq } from 'fabscale-app/utilities/utils/array';
import { getLocalTimezone } from 'fabscale-app/utilities/utils/timezone';
import { DateTime } from 'luxon';

export default function chartTimelineBarChartData({
  data,
  startDate,
  endDate,
  labels,
  lineAtDate,
}: {
  data: TimelineBarChartDataInput[];
  startDate: DateTime;
  endDate: DateTime;
  labels?: string[];
  lineAtDate?: DateTime;
}) {
  // D3 uses local dates, so we need to convert them here
  // We want to keep the time as the same it was, just change the timezone
  let zoneName = getLocalTimezone();

  let localStartDate = startDate.setZone(zoneName, { keepLocalTime: true });
  let localEndDate = endDate.setZone(zoneName, { keepLocalTime: true });

  let parsedData = parseData(data, zoneName, {
    start: localStartDate,
    end: localEndDate,
  });
  let sortedData = sortBy(parsedData, 'startDate');

  if (!labels) {
    labels = uniq(parsedData.map((item) => item.label));
  }

  // If few labels, we want a very high aspect ratio (=less high)
  // If many labels, we want a lower aspect ratio (=higher)
  let innerHeight = 50 + labels.length * 35;

  // If lineAtDate is set, we want to show a line there (if it is in the current range)
  let lineAtDateJs =
    lineAtDate && +lineAtDate > +startDate && +lineAtDate < +endDate
      ? lineAtDate.setZone(zoneName, { keepLocalTime: true }).toJSDate()
      : undefined;

  return {
    parsedData: sortedData,
    labels,
    startDate: localStartDate,
    endDate: localEndDate,
    innerHeight,
    lineAtDateJs,
  };
}

function parseData(
  data: TimelineBarChartDataInput[],
  zoneName: string,
  dateRange: DateRange
): TimelineBarChartData[] {
  assert(
    'TimelineBarChart: data must be an array of objects',
    Array.isArray(data) && !data.find((item) => typeof item !== 'object')
  );

  assert(
    `TimelineBarChart: data must have at least one item, but has only ${data.length}`,
    data.length >= 1
  );

  assert(
    'TimelineBarChart: all data items must have the following properties: label, startDate, ',
    !data.some(
      (item) =>
        isNone(item.startDate) || isNone(item.endDate) || isNone(item.label)
    )
  );

  let minStart = dateRange.start;
  let maxEnd = dateRange.end;

  return data.map((item) => {
    let { startDate, endDate } = item;

    let localStartDate = startDate.setZone(zoneName, { keepLocalTime: true });
    let localEndDate = endDate.setZone(zoneName, { keepLocalTime: true });

    let startDateJs =
      +localStartDate < +minStart
        ? minStart.toJSDate()
        : localStartDate.toJSDate();
    let endDateJs =
      +localEndDate > +maxEnd ? maxEnd.toJSDate() : localEndDate.toJSDate();

    return Object.assign(
      {
        colorType: item.colorType || 'info',
        itemGuid: guidFor(item),
        startDateJs,
        endDateJs,
        hasComment: false,
      },
      item
    );
  });
}
