import { assert } from '@ember/debug';
import {
  PlantAlarmOverTime,
  PlantAlarmsPerRecipe,
} from 'fabscale-app/models/alarm-report';

export default function plantAlarmsOverTimeGroupByRecipe(
  plantAlarmsOverTime: {
    data: PlantAlarmsPerRecipe[];
    recipeId: string;
  }[],
  selectedRecipesWithColor: {
    id: string;
    name: string;
    color: string;
  }[] = []
) {
  assert(
    `plantAlarmsOverTimeGroupByRecipe: plantAlarmsOverTime undefined`,
    !!plantAlarmsOverTime
  );
  assert(
    `plantAlarmsOverTimeGroupByRecipe: selectedRecipesWithColor undefined`,
    !!selectedRecipesWithColor
  );
  assert(
    `plantAlarmsOverTimeGroupByRecipe: selectedRecipesWithColor length 0F`,
    !!selectedRecipesWithColor?.length
  );

  plantAlarmsOverTime.forEach((item) => {
    item.data = item.data.filter((plantAlarmOverTime: any) =>
      ['INFO', 'WARNING', 'ALARM'].includes(plantAlarmOverTime.alarmLevel)
    );
  });

  let finalRecords = [];

  for (let index = 0; index < plantAlarmsOverTime.length; index++) {
    // iterating over each groupedPerRecipe
    const alarms: PlantAlarmOverTime = plantAlarmsOverTime[index]!.data.find(
      (item: any) => item.alarmLevel === 'ALARM'
    ) as unknown as PlantAlarmOverTime;
    const warnings: PlantAlarmOverTime = plantAlarmsOverTime[index]!.data.find(
      (item: any) => item.alarmLevel === 'WARNING'
    ) as unknown as PlantAlarmOverTime;
    const infos: PlantAlarmOverTime = plantAlarmsOverTime[index]!.data.find(
      (item: any) => item.alarmLevel === 'INFO'
    ) as unknown as PlantAlarmOverTime;

    // --alarms--
    let summedAlarms: any = [];

    if (alarms && alarms.alarmOverTimeItems?.length) {
      summedAlarms = alarms.alarmOverTimeItems[0]!.dateValueTimeSeries;

      for (let j = 1; j < alarms.alarmOverTimeItems.length; j++) {
        summedAlarms = summedAlarms.map((v: any, index: any) => {
          return {
            dateFrom: v.dateFrom,
            dateTo: v.dateTo,
            value:
              v.value +
              alarms.alarmOverTimeItems[j]!.dateValueTimeSeries[index]!.value,
          };
        });
      }
    }

    // --warnings--
    let summedWarnings: any = [];

    if (warnings && warnings.alarmOverTimeItems?.length) {
      summedWarnings = warnings.alarmOverTimeItems[0]!.dateValueTimeSeries;

      for (let j = 1; j < warnings!.alarmOverTimeItems.length; j++) {
        summedWarnings = summedWarnings.map((v: any, index: any) => {
          return {
            dateFrom: v.dateFrom,
            dateTo: v.dateTo,
            value:
              v.value +
              warnings.alarmOverTimeItems[j]!.dateValueTimeSeries[index]!.value,
          };
        });
      }
    }

    // --infos--
    let summedInfos: any = [];

    if (infos && infos.alarmOverTimeItems.length) {
      summedInfos = infos.alarmOverTimeItems[0]!.dateValueTimeSeries;

      for (let j = 1; j < infos!.alarmOverTimeItems.length; j++) {
        summedInfos = summedInfos.map((v: any, index: any) => {
          return {
            dateFrom: v.dateFrom,
            dateTo: v.dateTo,
            value:
              v.value +
              infos.alarmOverTimeItems[j]!.dateValueTimeSeries[index]!.value,
          };
        });
      }
    }

    const maxLength = Math.max(
      summedAlarms?.length,
      summedWarnings?.length,
      summedInfos?.length
    );

    let summedRecords: any = [];

    for (let i = 0; i < maxLength; i++) {
      summedRecords[i] = {
        recipeId: plantAlarmsOverTime[index]!.recipeId,
        dateFrom:
          (summedAlarms && summedAlarms[i] && summedAlarms[i].dateFrom) ||
          (summedWarnings && summedWarnings[i] && summedWarnings[i].dateFrom) ||
          (summedInfos && summedInfos[i] && summedInfos[i].dateFrom),
        dateTo:
          (summedAlarms && summedAlarms[i] && summedAlarms[i].dateTo) ||
          (summedWarnings && summedWarnings[i] && summedWarnings[i].dateTo) ||
          (summedInfos && summedInfos[i] && summedInfos[i].dateTo),
        alarms: (summedAlarms && summedAlarms[i] && summedAlarms[i].value) || 0,
        warnings:
          (summedWarnings && summedWarnings[i] && summedWarnings[i].value) || 0,
        infos: (summedInfos && summedInfos[i] && summedInfos[i].value) || 0,
        totalValue:
          ((summedAlarms && summedAlarms[i] && summedAlarms[i].value) || 0) +
          ((summedWarnings && summedWarnings[i] && summedWarnings[i].value) ||
            0) +
          ((summedInfos && summedInfos[i] && summedInfos[i].value) || 0),
      };
    }

    finalRecords.push({
      data: summedRecords,
      borderColor: selectedRecipesWithColor[index]!.color,
      parsing: {
        xAxisKey: 'dateFrom',
        yAxisKey: 'totalValue',
      },
    });
  }

  return {
    datasets: finalRecords,
  };
}
