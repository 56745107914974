import { action } from '@ember/object';
import Component from '@glimmer/component';
import { PermissionDefinition } from 'fabscale-app/services/store/user-role';

interface Args {
  record: PermissionDefinition;
  tableData: {
    selectedRecords: PermissionDefinition[];
    toggleRecord: (record: PermissionDefinition, isSelected: boolean) => void;
  };
}

export default class PageSettingsRolesPermissionSelectCellName extends Component<Args> {
  get isDisabled() {
    let { record } = this.args;
    let { selectedRecords } = this.args.tableData;

    // If it has a parent & it is selected, you cannot un-select the child
    return (
      typeof record.parentPermission !== 'undefined' &&
      selectedRecords.some((def) => def.permission === record.parentPermission)
    );
  }

  @action
  onToggle(isSelected: boolean) {
    let { record } = this.args;

    this.args.tableData.toggleRecord(record, isSelected);
  }
}
