import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Unit } from 'fabscale-app/models/enums/units';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

export default class EnumsSearchMatcherUnit extends Helper {
  @service enumLabels: EnumLabelsService;

  compute() {
    let { enumLabels } = this;

    return function (unit: Unit, searchTerm: string) {
      let normalizedSearch = searchTerm.toLowerCase();

      if (unit.toLowerCase().includes(normalizedSearch)) {
        return 1;
      }

      return enumLabels.unit(unit).toLowerCase().includes(normalizedSearch)
        ? 1
        : -1;
    };
  }
}
