import Component from '@glimmer/component';
import { AlarmLevelReportFilters } from 'fabscale-app/models/alarm-report';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import { getDateFormatForInterval } from 'fabscale-app/utilities/utils/date-interval';
import { DateTime } from 'luxon';
import { Interval } from 'fabscale-app/models/enums/intervals';

interface Args {
  intervalDefinition: {
    interval: Interval;
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    timezoneName: string;
  };
  filters: AlarmLevelReportFilters;
}

export default class PageAlarmReportLevelReportPerPlantAsset extends Component<Args> {
  alarmLevelReportAlarmChartTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    const dateFrom = DateTime.fromISO(
      tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
        .dateFrom
    ).toLocaleString(DateTime.DATETIME_SHORT);
    const dateTo = DateTime.fromISO(
      tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex].dateTo
    ).toLocaleString(DateTime.DATETIME_SHORT);
    const label = `${dateFrom} - ${dateTo}`;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      bodyLines.forEach((body: any, i: any) => {
        const colors = tooltip.labelColors[i];
        const roasterName =
          tooltip.dataPoints[i].dataset.data[0].idNamePair.name;

        const span = document.createElement('span');
        span.style.background = colors.borderColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = cssObj.spacings._0px;
        span.style.marginRight = cssObj.spacings._10px;
        span.style.height = cssObj.spacings._12px;
        span.style.width = cssObj.spacings._12px;
        span.style.display = cssObj.display.inlineBlock;

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let span1 = document.createElement('span');
        span1.style.display = cssObj.display.flex;
        span1.style.flexDirection = cssObj.flex.flexDirection.row;

        let p1 = document.createElement('p');
        p1.innerHTML = `${roasterName}:`;
        p1.style.marginRight = cssObj.spacings._8px;

        let p2 = document.createElement('p');
        p2.innerHTML = body;

        p1.style.marginBottom = p2.style.marginBottom = cssObj.spacings._0px;

        span1.append(p1, p2);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';

        tableDataContainer.append(span, span1);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      let p4 = document.createElement('p');
      p4.style.marginBottom = cssObj.spacings._0px;
      p4.style.color = cssObj.colors.sonicSilver;
      p4.style.fontSize = cssObj.spacings._12px;
      p4.innerHTML = label;

      tableBody.appendChild(p4);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${Number(positionY) + Number(tooltip.caretY)}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  get alarmLevelsChartOptions() {
    const { intervalDefinition } = this.args;
    const { interval } = intervalDefinition;
    const ticksDateFormat = getDateFormatForInterval(interval);

    return {
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this.alarmLevelReportAlarmChartTooltip,
        },
      },
      scales: {
        x: {
          offset: true,
          border: {
            display: true,
          },
          grid: {
            display: true,
            drawTicks: true,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
          ticks: {
            display: true,
            autoSkip: false,
            source: 'data',
            callback: function (value: any) {
              const dateFrom = DateTime.fromISO(
                (this as any).getLabelForValue(value)
              ).toLocaleString(ticksDateFormat);
              const dateTo = DateTime.fromISO(
                (this as any).getLabelForValue(value)
              )
                .plus({ hours: 1 })
                .toLocaleString(ticksDateFormat);

              if (ticksDateFormat.hour) {
                let formatedTick = `${dateFrom} - ${dateTo}`;

                formatedTick = formatedTick.replaceAll('AM', '');
                formatedTick = formatedTick.replaceAll('PM', '');

                return formatedTick;
              }

              if (ticksDateFormat.month) {
                return dateFrom;
              }
            },
          },
        },
        y: {
          offset: false,
          position: cssObj.position.left,
          ticks: {
            display: function (context: any) {
              const hasRecords =
                !!context.scale.chart.config._config.data.datasets.length;
              return hasRecords;
            },
            maxTicksLimit: 5,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
            lineWidth: (context: any) => Number(!!context.index),
          },
        },
      },
    };
  }

  alarmLevelReportAlarmsPerPlantAssetChartTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      bodyLines.forEach((body: any, i: any) => {
        const color = tooltip.dataPoints[i].dataset.backgroundColor;

        const coloredSquare = document.createElement('span');
        coloredSquare.style.background = color;
        coloredSquare.style.borderColor = color;
        coloredSquare.style.borderWidth = cssObj.spacings._0px;
        coloredSquare.style.marginRight = cssObj.spacings._10px;
        coloredSquare.style.height = cssObj.spacings._12px;
        coloredSquare.style.width = cssObj.spacings._12px;
        coloredSquare.style.display = cssObj.display.inlineBlock;

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let tooltipRowContainer = document.createElement('span');
        tooltipRowContainer.style.display = cssObj.display.flex;
        tooltipRowContainer.style.flexDirection = cssObj.flex.flexDirection.row;

        let rowText = document.createElement('p');
        rowText.innerHTML = body;
        rowText.style.marginBottom = cssObj.spacings._0px;

        tooltipRowContainer.append(rowText);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';

        tableDataContainer.append(coloredSquare, tooltipRowContainer);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${
      Number(positionY) + Number(tooltip.caretY) + 40
    }px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  get alarmLevelsPerPlantAssetChartOptions() {
    return {
      interaction: {
        mode: 'nearest',
      },
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this.alarmLevelReportAlarmsPerPlantAssetChartTooltip,
        },
      },
      scales: {
        x: {
          stacked: true,
          offset: true,
          border: {
            display: true,
          },
          grid: {
            stacked: true,
            display: true,
            drawTicks: false,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
          ticks: {
            callback: function (value: any) {
              let label = (this as any).getLabelForValue(value).toLowerCase();
              return `${label.slice(0, 1).toUpperCase()}${label.slice(1)}s`;
            },
          },
        },
        y: {
          stacked: true,
          offset: false,
          position: cssObj.position.left,
          ticks: {
            display: function (context: any) {
              return !!context.scale.chart.config._config.data.datasets.length;
            },
            maxTicksLimit: 5,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
            lineWidth: (context: any) => Number(!!context.index),
          },
        },
      },
    };
  }
}
