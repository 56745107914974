import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  onCancel: () => void;
  onYes: () => void;
}

export default class SharedUnsavedChangesDialog extends Component<Args> {
  @action
  onCancel() {
    this.args.onCancel();
  }

  @action
  onYes() {
    this.args.onYes();
  }
}
