import { service } from '@ember/service';
import {
  RecipeSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { DateRange } from 'fabscale-app/models/date-range';
import AvailableDataService from 'fabscale-app/services/available-data';
import { cutOffDateRange } from 'fabscale-app/utilities/utils/date-range';
import { DateTime } from 'luxon';
import BaseAsyncResource from './base-async-resource';

interface LoadRecipesInUseOptions {
  dateRange?: DateRange;
  pageSize: number;
  sortBy?: RecipeSortOption;
  sortDirection?: SortDirection;
  cutOffDate?: DateTime;
}

export default class LoadRecipesInUseResource extends BaseAsyncResource<
  { id: string; name: string }[],
  LoadRecipesInUseOptions
> {
  @service availableData: AvailableDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData(options: LoadRecipesInUseOptions) {
    let { dateRange, pageSize, sortBy, sortDirection, cutOffDate } = options;

    if (!dateRange) {
      return [];
    }

    dateRange = cutOffDateRange(dateRange, cutOffDate);

    return this.availableData.getAvailableRecipes({
      dateRange,
      pageSize,
      sortBy,
      sortDirection,
    });
  }
}
