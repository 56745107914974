import { service } from '@ember/service';
import Component from '@glimmer/component';
import {
  UnitRoastColor,
  UnitsRoastColor,
} from 'fabscale-app/models/enums/units';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

interface Args {
  defaultRoastColorUnit: UnitRoastColor;
}

export default class PlantSettingsDefaultRoastColorUnit extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  roastColorUnitOptions: { value: UnitRoastColor; label: string }[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initOptions();
  }

  _initOptions() {
    let { enumLabels } = this;

    this.roastColorUnitOptions = UnitsRoastColor.map((value) => {
      return { value, label: enumLabels.unit(value) };
    });
  }
}
