import transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import MaintenanceTaskManagerService from 'fabscale-app/services/maintenance-task-manager';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';

export default class MaintenanceTasksShowRoute extends AuthenticatedRoute {
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  @service('maintenance-task-manager')
  maintenanceTaskManager: MaintenanceTaskManagerService;

  @service router: RouterService;

  model(params: { id: string }) {
    let { maintenanceTaskStore } = this;
    let { id } = params;

    return maintenanceTaskStore.findById(id);
  }

  activate(transition: transition): void {
    super.activate(transition);

    let { from } = transition;
    this.maintenanceTaskManager.capturePreviousPage(from);
  }
}
