import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import CognitoService from 'fabscale-app/services/cognito';

export default class SettingsSecurityRoute extends AuthenticatedRoute {
  @service cognito: CognitoService;

  deactivate() {
    // When leaving the security page, the password check for the MFA settings is reset
    this.cognito.hasAuthenticatedForMfaSetup = false;
  }
}
