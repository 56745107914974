import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { DateRange } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

interface Args {
  currentMonth: DateTime;
  startDayOfWeek: DayOfWeek;
}

export default class UiDatePickerDays extends Component<Args> {
  get startDayOfWeek() {
    let { startDayOfWeek } = this.args;

    let map: { [key in DayOfWeek]: number } = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 7,
    };

    return map[startDayOfWeek];
  }

  get endDayOfWeek() {
    let { startDayOfWeek } = this;

    if (startDayOfWeek === 1) {
      return 7;
    }

    return startDayOfWeek - 1;
  }

  get weekDays() {
    let { weeks } = this;

    return weeks[0]!.map((date) => date?.weekdayShort);
  }

  @cached
  get firstDay() {
    let { currentMonth } = this.args;
    let { startDayOfWeek } = this;

    let firstDay = currentMonth.startOf('month');

    if (firstDay.weekday === startDayOfWeek) {
      return firstDay;
    }

    if (firstDay.weekday > startDayOfWeek) {
      return firstDay.set({ weekday: startDayOfWeek }).startOf('day');
    }

    return firstDay
      .set({ weekday: startDayOfWeek })
      .minus({ weeks: 1 })
      .startOf('day');
  }

  @cached
  get lastDay() {
    let { currentMonth } = this.args;
    let { endDayOfWeek } = this;

    let lastDay = currentMonth.endOf('month').startOf('day');

    if (lastDay.weekday === endDayOfWeek) {
      return lastDay;
    }

    if (lastDay.weekday < endDayOfWeek) {
      return lastDay.set({ weekday: endDayOfWeek }).startOf('day');
    }

    return lastDay
      .set({ weekday: endDayOfWeek })
      .plus({ weeks: 1 })
      .startOf('day');
  }

  get dateRange() {
    return new DateRange({ start: this.firstDay, end: this.lastDay });
  }

  @cached
  get weeks() {
    let { firstDay, lastDay } = this;

    let currentWeek: DateTime[] = [];

    let weeks = [currentWeek];
    let currentDate = firstDay;

    while (currentDate.valueOf() <= lastDay.valueOf()) {
      if (currentWeek.length === 7) {
        currentWeek = [];

        weeks.push(currentWeek);
      }

      currentWeek.push(currentDate);

      currentDate = currentDate.plus({ days: 1 }).startOf('day');
    }

    return weeks;
  }
}
