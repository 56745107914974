import { EnumsBase } from './-base';

type RelativeTimeframeUnit = 'day' | 'week' | 'month' | 'year';

export interface RelativeTimeframe {
  id: RelativeTimeframeId;
  unit: RelativeTimeframeUnit;
  includeThis: boolean;
}

export type RelativeTimeframeId = `${'DAY' | 'WEEK' | 'MONTH' | 'YEAR'}_${
  | 'THIS'
  | 'LAST'}`;

export const RELATIVE_TIMEFRAME_TODAY: RelativeTimeframe = {
  id: 'DAY_THIS',
  unit: 'day',
  includeThis: true,
};

export const RELATIVE_TIMEFRAME_YESTERDAY: RelativeTimeframe = {
  id: 'DAY_LAST',
  unit: 'day',
  includeThis: false,
};

export const RELATIVE_TIMEFRAME_THIS_WEEK: RelativeTimeframe = {
  id: 'WEEK_THIS',
  unit: 'week',
  includeThis: true,
};

export const RELATIVE_TIMEFRAME_LAST_WEEK: RelativeTimeframe = {
  id: 'WEEK_LAST',
  unit: 'week',
  includeThis: false,
};

export const RELATIVE_TIMEFRAME_THIS_MONTH: RelativeTimeframe = {
  id: 'MONTH_THIS',
  unit: 'month',
  includeThis: true,
};

export const RELATIVE_TIMEFRAME_LAST_MONTH: RelativeTimeframe = {
  id: 'MONTH_LAST',
  unit: 'month',
  includeThis: false,
};

export const RELATIVE_TIMEFRAME_THIS_YEAR: RelativeTimeframe = {
  id: 'YEAR_THIS',
  unit: 'year',
  includeThis: true,
};

export const RELATIVE_TIMEFRAME_LAST_YEAR: RelativeTimeframe = {
  id: 'YEAR_LAST',
  unit: 'year',
  includeThis: false,
};

export const ALL_RELATIVE_TIMEFRAMES = [
  RELATIVE_TIMEFRAME_TODAY,
  RELATIVE_TIMEFRAME_YESTERDAY,
  RELATIVE_TIMEFRAME_THIS_WEEK,
  RELATIVE_TIMEFRAME_LAST_WEEK,
  RELATIVE_TIMEFRAME_THIS_MONTH,
  RELATIVE_TIMEFRAME_LAST_MONTH,
  RELATIVE_TIMEFRAME_THIS_YEAR,
  RELATIVE_TIMEFRAME_LAST_YEAR,
];

export class RelativeTimeframeEnums extends EnumsBase<RelativeTimeframeId> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in RelativeTimeframeId]: string } = {
      DAY_THIS: l10n.t('Today'),
      DAY_LAST: l10n.t('Yesterday'),
      WEEK_THIS: l10n.t('This week'),
      WEEK_LAST: l10n.t('Last week'),
      MONTH_THIS: l10n.t('This month'),
      MONTH_LAST: l10n.t('Last month'),
      YEAR_THIS: l10n.t('This year'),
      YEAR_LAST: l10n.t('Last year'),
    };

    this.labels = labels;
  }
}
