import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { loadKpiDataOverTime } from 'fabscale-app/helpers/resources/load-kpi-data-over-time';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import BaseAsyncResource from './base-async-resource';

export interface LoadKpiDataOverTimeGroupedOptions {
  kpiType: KpiType;
  dateRange: DateRange;
  interval: Interval;
  plantAssetIds?: string[];
  recipeIds?: string[];
  groupBy: KpiDataOverTimeGrouping;
}

export interface KpiDataOverTimeGrouped {
  data?: KpiDataOverTime;
  groupId: string;
}

enum KpiDataOverTimeGrouping {
  PlantAsset = 'PLANT_ASSET',
  Recipe = 'RECIPE',
}

export default class LoadKpiDataOverTimeGroupedResource extends BaseAsyncResource<
  KpiDataOverTimeGrouped[],
  LoadKpiDataOverTimeGroupedOptions
> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData(options: LoadKpiDataOverTimeGroupedOptions) {
    let { kpiDataStore } = this;
    let { kpiType, dateRange, interval, plantAssetIds, recipeIds, groupBy } =
      options;

    assert(
      `{{resources/load-kpi-data-over-time-grouped}}: dateRange.start has to be at 00:00:00, but is ${serializeDate(
        dateRange.start
      )}`,
      +dateRange.start === +dateRange.start.startOf('day')
    );
    assert(
      `{{resources/load-kpi-data-over-time-grouped}}: dateRange.end has to be at 00:00:00, but is ${serializeDate(
        dateRange.end
      )}`,
      +dateRange.end === +dateRange.end.startOf('day')
    );

    assert(
      `{{resources/load-kpi-data-over-time-grouped}}: You have to provide a valid groupBy`,
      groupBy && Object.values(KpiDataOverTimeGrouping).includes(groupBy)
    );

    let groupIds =
      groupBy === KpiDataOverTimeGrouping.PlantAsset
        ? (plantAssetIds as string[])
        : (recipeIds as string[]);

    assert(
      `{{resources/load-kpi-data-over-time-grouped}}: You have to provide ids for the grouping`,
      groupIds && groupIds.length > 0
    );

    let data = await promiseQueue(groupIds, async (groupId) => {
      if (groupBy === KpiDataOverTimeGrouping.PlantAsset) {
        plantAssetIds = [groupId];
      } else {
        recipeIds = [groupId];
      }

      let data = await loadKpiDataOverTime(kpiDataStore, kpiType, {
        dateRange,
        interval,
        plantAssetIds,
        recipeIds,
      });

      let group: KpiDataOverTimeGrouped = {
        groupId,
        data,
      };

      return group;
    });

    return data;
  }
}
