import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { CognitoNotAuthenticatedError } from 'fabscale-app/models/errors/cognito';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import CustomAnalyticsService from 'fabscale-app/services/analytics';
import CognitoService from 'fabscale-app/services/cognito';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import LuxonService from 'fabscale-app/services/luxon';
import SettingsService from 'fabscale-app/services/settings';
import UserSessionService from 'fabscale-app/services/user-session';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';
import 'fabscale-app/scss/app.scss';

export default class ApplicationRoute extends Route {
  @service cognito: CognitoService;
  @service luxon: LuxonService;
  @service userSession: UserSessionService;
  @service settings: SettingsService;
  @service analytics: CustomAnalyticsService;
  @service l10n: L10nService;

  async beforeModel() {
    // Init Luxon before anything else
    this.luxon;

    try {
      await this.cognito.restoreAndLoad();
      await this.userSession.setupUserSession();
    } catch (error) {
      const browserLanguage = window.navigator.language;
      const locale = browserLanguage.startsWith('zh') ? 'zh-cn' : 'en';

      await this.l10n.setLocale(locale);

      // If `restoreAndLoad` fails, it will reject with an CognitoNotAuthenticatedError error
      // In that case, we don't need to log to the console
      // But if `loadUserData` fails, we want to see the error in the console
      if (!(error instanceof CognitoNotAuthenticatedError)) {
        sentryCaptureException(error);
      }
    }

    await this.settings.loadSettings();

    this.analytics.trackAllPageViews();
  }

  activate() {
    // router.currentRouteName is only available after transition is completed
    schedule('afterRender', () =>
      this.analytics.addEvent({ name: 'app-load' })
    );
  }

  @action
  error(error: any) {
    // For some errors, suppress the error logging
    if (
      error instanceof PermissionError ||
      error instanceof ResourceNotFoundError
    ) {
      this.intermediateTransitionTo('error', error);
      return;
    }

    return true;
  }
}
