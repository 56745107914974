import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { getOwnConfig } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';
import DashboardConfigManagerService from 'fabscale-app/services/dashboard-config-manager';
import Dashboard from 'fabscale-app/services/dashboard/service';
import UserSessionService from 'fabscale-app/services/user-session';
import { getUser } from 'fabscale-app/utilities/utils/local-storage';

interface Args {
  dateOption: DashboardDateOption;
  updateDateOption: (opt: DashboardDateOption) => void;
}

export default class DashboardHeader extends Component<{ Args: Args }> {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service dashboardConfigManager: DashboardConfigManagerService;
  @service('services/dashboard') dashboardService: Dashboard;
  @tracked isCollapsed = false;

  dateOptions: { option: DashboardDateOption; label: string }[] = [];

  get selectedDateOption() {
    let { dateOption } = this.args;
    return this.dateOptions.find((option) => option.option === dateOption)!;
  }

  get userName() {
    return getOwnConfig<any>().isSimulation
      ? getUser(true)?.email
      : this.userSession.user?.name;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let { l10n } = this;
    this.dateOptions = [
      { option: 'LAST_24H', label: l10n.t('Last 24h') },
      { option: 'TODAY', label: l10n.t('Today') },
      { option: 'YESTERDAY', label: l10n.t('Yesterday') },
      { option: 'THIS_WEEK', label: l10n.t('This week') },
      { option: 'LAST_WEEK', label: l10n.t('Last week') },
    ];

    this._initIsCollapsed();
  }

  @action
  updateDateOption(opt: { option: DashboardDateOption; label: string }) {
    this.dashboardService.dateRange = opt;
    this.args.updateDateOption(opt.option);
  }

  @action
  toggleIsCollapsed() {
    let isCollapsed = !this.isCollapsed;

    this.isCollapsed = isCollapsed;

    this.dashboardConfigManager.saveDashboardHeaderIsCollapsed(isCollapsed);
  }

  _initIsCollapsed() {
    let { userFrontendSettings } = this.dashboardConfigManager;

    this.isCollapsed =
      userFrontendSettings.dashboardConfig?.headerIsCollapsed || false;
  }
}
