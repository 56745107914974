import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  select: any;
}

export default class UiPowerSelectMultipleTrigger extends Component<Args> {
  @action
  removeSelectedItem(option: any, event: Event) {
    this.args.select.actions.choose(option);

    event.preventDefault();
    event.stopPropagation();
  }
}
