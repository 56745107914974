import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import { tracked } from '@glimmer/tracking';
import { ICssConfig } from 'fabscale-app/models/css-config';
import {
  POPOVER_ARROW_DEFAULT_CSS_CONFIG,
  PopoverArrowTypes,
} from 'fabscale-app/models/popover-config';
import { service } from '@ember/service';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  record: PlantAssetStateInfo & { dateRange?: DateRange };
  propertyName: string;
  tableData: {
    plantAssetId: string;
  };
}

export default class PagePlantAssetStatusMapCellsAlarm extends Component<Args> {
  @service screen: ScreenService;

  @tracked
  displayAlarms = false;

  @tracked
  private _pageSize = 5;

  get pageSize() {
    return this._pageSize;
  }

  @action
  onPopoverToggle() {
    this.displayAlarms = !this.displayAlarms;
  }

  @action
  onViewAll(totalItemCount: number) {
    this._pageSize = totalItemCount;
  }

  get isMobile() {
    return this.screen.isMobile;
  }

  get plantAssetId(): string {
    const { plantAssetId } = this.args.tableData;

    return plantAssetId;
  }

  get dateRange(): DateRange | undefined {
    const { dateRange } = this.args.record;

    return dateRange;
  }

  get popoverCssConfig(): ICssConfig {
    return this.isMobile
      ? {
          margin: '15px 0 0 -165px',
          padding: '10px',
          width: '428px',
          zIndex: '1',
        }
      : ({
          margin: '15px 0 0 -59px',
          padding: '10px',
        } as ICssConfig);
  }

  get arrowType(): number {
    return PopoverArrowTypes.Custom;
  }

  get customArrowType(): ICssConfig {
    const customArrowType = this.isMobile
      ? POPOVER_ARROW_DEFAULT_CSS_CONFIG.topCenter
      : POPOVER_ARROW_DEFAULT_CSS_CONFIG.topLeft;

    if (this.isMobile) {
      customArrowType.transform = 'translate(-981%, -50%) rotate(45deg)';
    } else {
      customArrowType.transform = 'translate(239%, -50%) rotate(45deg)';
    }

    return customArrowType;
  }
}
