import AuthenticatedRoute from '../base/-base-authenticated-route';
import UserSessionService from 'fabscale-app/services/user-session';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Transition from '@ember/routing/transition';

export default class RoutesRoastPic extends AuthenticatedRoute {
  @service userSession: UserSessionService;
  @service router: RouterService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (!this.userSession.permissionMap.ROASTPIC_VIEW) {
      window.history.back();
    }
  }
}
