import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Signature {
  Args: {
    record: Record<string, DateTime>;
    propertyName: string;
    tableData: {
      date: DateTime;
    };
  };
}

export default class PagePlantAssetStatusMapCellsDate extends Component<Signature> {
  get value() {
    return this.args.record[this.args.propertyName];
  }

  get showDate() {
    let { value } = this;
    let { date } = this.args.tableData;

    return value && !date.hasSame(value, 'day');
  }
}
