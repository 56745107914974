import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MaintenanceTasksController extends Controller {
  @service router: RouterService;

  @tracked isOverview = false;

  get isCalendar() {
    return this.router.currentRouteName.startsWith(
      'routes/maintenance.tasks.calendar'
    );
  }
}
