/* eslint-disable */
// THIS FILE IS GENERATED BY @ember-gettext/gettext-parser
// DO NOT MODIFY THIS FILE YOURSELF - ANY CHANGE WILL BE OVERWRITTEN

const map = {
  de: () => import('./de.json'),
  en: () => import('./en.json'),
  it: () => import('./it.json'),
  'zh-cn': () => import('./zh-cn.json')
};  

export default map;
