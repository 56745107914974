import { service } from '@ember/service';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetStateInfosOptions {
  dateRange: DateRange;
  plantAssetId: string;
  status?: PlantAssetStatus[];
  reason?: (string | null)[];
}

export default class LoadPlantAssetStateInfosResource extends BaseAsyncResource<
  PlantAssetStateInfo[],
  LoadPlantAssetStateInfosOptions
> {
  @service('store/plant-asset-info') plantAssetInfo: StorePlantAssetInfoService;

  async loadData(options: LoadPlantAssetStateInfosOptions) {
    let { plantAssetId, dateRange, status, reason } = options;
    let filters = {
      dateRange,
      plantAssetId,
      status,
      reason,
    };

    return this.plantAssetInfo.findAll(filters);
  }
}
