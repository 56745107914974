import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import {
  Permission,
  SORTED_PERMISSIONS,
} from 'fabscale-app/models/enums/permissions';
import { ResourceInUseError } from 'fabscale-app/models/errors/resource-in-use-error';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import createMutation from 'fabscale-app/gql/mutations/create-user-role.graphql';
import deleteMutation from 'fabscale-app/gql/mutations/delete-user-role.graphql';
import updateMutation from 'fabscale-app/gql/mutations/update-user-role.graphql';
import availablePermissionDefinitionsQuery from 'fabscale-app/gql/queries/available-permission-definitions.graphql';
import byIdQuery from 'fabscale-app/gql/queries/user-role-by-id.graphql';
import allQuery from 'fabscale-app/gql/queries/user-roles-for-company.graphql';
import { UserRole, UserRoleInput } from 'fabscale-app/models/user-role';
import GraphQLService from 'fabscale-app/services/-graphql';
import UserSessionService from 'fabscale-app/services/user-session';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import EnumLabelsService from '../enum-labels';

export interface PermissionDefinition {
  permission: Permission;
  label: string;
  requiredPermissions: Permission[];
  parentPermission?: Permission;
}

export default class StoreUserRoleService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;
  @service enumLabels: EnumLabelsService;

  get companyId() {
    return this.userSession.company!.id;
  }

  // METHODS
  async findAll(opt?: { companyId?: string }): Promise<UserRole[]> {
    let { graphql, companyId } = this;

    let variables = {
      companyId: opt?.companyId || companyId,
    };

    let userRoles: UserRoleInput[] = await graphql.query(
      {
        query: allQuery,
        variables,
        namespace: 'userRolesForCompany',
      },
      { cacheEntity: 'UserRole' }
    );

    return sortBy(userRoles, 'name').map(
      (userRoleData) => new UserRole(userRoleData)
    );
  }

  async findById(id: string): Promise<UserRole> {
    let { graphql, l10n } = this;

    let variables = {
      id,
    };

    try {
      let userRole: UserRoleInput = await graphql.query(
        {
          query: byIdQuery,
          variables,
          namespace: 'userRoleById',
        },
        { cacheEntity: 'UserRole', cacheId: id }
      );

      return new UserRole(userRole);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The role with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async create({
    name,
    permissions,
  }: {
    name: string;
    permissions: Permission[];
  }): Promise<UserRole> {
    let { graphql, companyId } = this;

    let variables = {
      input: { name, permissions },
      companyId,
    };

    let userRole: UserRoleInput = await graphql.mutate(
      {
        mutation: createMutation,
        variables,
        namespace: 'createUserRole',
      },
      {
        invalidateCache: [{ cacheEntity: 'UserRole' }],
      }
    );

    return new UserRole(userRole);
  }

  async update(
    id: string,
    {
      name,
      permissions,
    }: {
      name: string;
      permissions: Permission[];
    }
  ): Promise<UserRole> {
    let { graphql } = this;

    let variables = {
      input: { name, permissions },
      id,
    };

    let userRole: UserRoleInput = await graphql.mutate(
      {
        mutation: updateMutation,
        variables,
        namespace: 'updateUserRole',
      },
      {
        invalidateCache: [
          { cacheEntity: 'UserRole' },
          { cacheId: id, cacheEntity: 'UserRole' },
        ],
      }
    );

    return new UserRole(userRole);
  }

  async delete(id: string): Promise<void> {
    let { l10n, graphql } = this;

    let variables = {
      id,
    };

    try {
      await graphql.mutate(
        {
          mutation: deleteMutation,
          variables,
        },
        {
          invalidateCache: [
            { cacheEntity: 'UserRole' },
            { cacheId: id, cacheEntity: 'UserRole' },
          ],
        }
      );
    } catch (error) {
      if (error instanceof ResourceInUseError && Array.isArray(error.usedBy)) {
        let { usedBy } = error;

        error.translatedMessage =
          usedBy.length > 0
            ? l10n.n(
                'You cannot delete this role, because {{count}} user has that role: {{userNames}}',
                'You cannot delete this role, because {{count}} users have that role: {{userNames}}',
                usedBy.length,
                { userNames: usedBy.join(', ') }
              )
            : l10n.t(
                'You cannot delete this role, because some user has that role.'
              );
      }

      throw error;
    }
  }

  async getAvailablePermissionDefinitions(opt?: {
    companyId?: string;
  }): Promise<PermissionDefinition[]> {
    let { graphql, companyId, enumLabels } = this;

    let variables = { companyId: opt?.companyId || companyId };

    let response: {
      permission: Permission;
      requiredPermissions: Permission[];
    }[] = await graphql.query(
      {
        query: availablePermissionDefinitionsQuery,
        variables,
        namespace: 'availablePermissionDefinitionsForCompany',
      },
      { cacheEntity: 'Other' }
    );

    let permissionOptions: PermissionDefinition[] = response.map(
      ({ permission, requiredPermissions }) => {
        return {
          label: enumLabels.permission(permission),
          permission,
          requiredPermissions,
          parentPermission: undefined,
        };
      }
    );

    // Now set parentPermissions
    permissionOptions.forEach((def) => {
      let { permission } = def;

      let parentPermission = permissionOptions.find((parent) =>
        parent.requiredPermissions.includes(permission)
      );

      if (parentPermission) {
        def.parentPermission = parentPermission.permission;
      }
    });

    return permissionDefinitionSort(permissionOptions);
  }
}

function permissionDefinitionSort(
  defs: PermissionDefinition[]
): PermissionDefinition[] {
  let sorted = defs.slice();
  sorted.sort((a, b) => {
    return (
      SORTED_PERMISSIONS.indexOf(a.permission) -
      SORTED_PERMISSIONS.indexOf(b.permission)
    );
  });

  return sorted;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/user-role': StoreUserRoleService;
  }
}
