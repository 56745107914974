import Component from '@glimmer/component';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { serializeArray } from 'fabscale-app/utilities/utils/serialize-array';
import { serializeDateRange } from 'fabscale-app/utilities/utils/serialize-date-range';
import { KpiCompareReportFilters } from '../..';

interface Args {
  filters: KpiCompareReportFilters;
  kpiType: KpiType;
}

export default class KpiCompareReportOverallCardItem extends Component<Args> {
  get detailsQueryParams() {
    let { filters, kpiType } = this.args;

    let { dateRange, timeRange, plantAssetIds, recipeIds } = filters;

    return {
      kpiType,
      dateRange: serializeDateRange(dateRange!),
      timeRange: timeRange ? JSON.stringify(timeRange) : undefined,
      plantAssets: plantAssetIds ? serializeArray(plantAssetIds) : undefined,
      recipes: recipeIds ? serializeArray(recipeIds) : undefined,
    };
  }
}
