export const Locales = ['en', 'en_gb', 'de', 'it', 'zh_cn'] as const;

export type Locale = typeof Locales[number];

export interface LocaleLabelPair {
  locale: Locale;
  label: string;
}

export const LocaleLabels: LocaleLabelPair[] = [
  { locale: 'en', label: 'English US (en)' },
  { locale: 'en_gb', label: 'English UK (en-gb)' },
  { locale: 'de', label: 'Deutsch (de)' },
  { locale: 'it', label: 'Italiano (it)' },
  { locale: 'zh_cn', label: 'Chinese Simplified (zh-cn)' },
];
