import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import CognitoService from 'fabscale-app/services/cognito';
import UserSessionService from 'fabscale-app/services/user-session';

type Args = any;

export default class SettingsSecurityMfaDisableMfa extends Component<Args> {
  @service cognito: CognitoService;
  @service userSession: UserSessionService;
  @service router: RouterService;
  @service l10n: L10nService;

  @tracked error?: string;

  disableMfaTask = dropTask(async () => {
    let { cognito, l10n } = this;

    this.error = undefined;

    try {
      await cognito.cognitoData!.mfa.disable();
    } catch (error) {
      this.error = (error as any)?.message || l10n.t('An error occurred');
      return;
    }

    this.router.transitionTo('routes/my-settings.security.index');
  });
}
