import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { KpiCompareReportTableData } from './table-columns';

export default function kpiCompareReportTransformKpiDataGrouped(
  data: KpiDataGrouped[]
): KpiCompareReportTableData[] {
  let groupedData: KpiCompareReportTableData[] = [];

  data.forEach((kpiDataGrouped) => {
    let { type, values, unit } = kpiDataGrouped;

    values.forEach((kpiDataGroup) => {
      let { name, value } = kpiDataGroup;

      let group = groupedData.find((item) => item.label === name);

      if (!group) {
        group = { label: name, searchLabel: name.toLowerCase() };
        groupedData.push(group);
      }

      group[type] = { value, unit };
    });
  });

  return groupedData;
}
