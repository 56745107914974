import Component from '@glimmer/component';
import { service } from '@ember/service';
import { DateTime } from 'luxon';
import SettingsService from 'fabscale-app/services/settings';
import { action } from '@ember/object';
import AnalyticsService from 'fabscale-app/services/analytics';

interface Args {
  value?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  allowClear: boolean;
  allowToday: boolean;
  hasValue: boolean;
  updateDate: (date?: DateTime) => void;
  centerMonth: DateTime;
}

export default class UiDatePicker extends Component<Args> {
  @service settings: SettingsService;
  @service analytics: AnalyticsService;

  get selectedIsNotToday() {
    return !this.args.value || !this.today.hasSame(this.args.value, 'day');
  }

  get startDayOfWeek() {
    return this.settings.locationSettings.startDayOfWeek;
  }

  get today() {
    return DateTime.local();
  }

  get showTodayButton() {
    return this.args.allowToday && this.selectedIsNotToday;
  }

  get showClearButton() {
    return this.args.allowClear && this.args.hasValue;
  }

  @action
  selectToday() {
    this.analytics.addEvent('date-picker-select-today');
    this.args.updateDate(DateTime.local().startOf('day'));
  }

  @action
  selectClear() {
    this.analytics.addEvent('date-picker-select-clear');
    this.args.updateDate(undefined);
  }
}
