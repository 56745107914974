import { DateRange } from 'fabscale-app/models/date-range';

export default function dashboardGetComparativeDateRange(dateRange: DateRange) {
  let daysDiff = Math.ceil(dateRange.end.diff(dateRange.start, 'days').days);

  return new DateRange({
    start: dateRange.start.minus({ days: daysDiff }),
    end: dateRange.end.minus({ days: daysDiff }),
  });
}
