import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Transition from '@ember/routing/-private/transition';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';

export default class RoastBatchesShowEditRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;

  afterModel() {
    if (this.abilities.cannot('edit roast-batches')) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to edit roast batches.")
      );
    }
  }

  deactivate(transition?: Transition) {
    this.refreshRouteIfMatches(transition, 'routes/roast-batches.show');
  }
}
