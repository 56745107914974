import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { KpiCompareReportFilters } from 'fabscale-app/components/page/kpi-compare-report/index';
import {
  RelativeTimeframe,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import {
  deserializeRelativeTimeframe,
  serializeRelativeTimeframe,
} from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { ReportType } from 'fabscale-app/models/enums/template';
import { ITemplate } from 'fabscale-app/services/store/template';
import { tracked } from '@glimmer/tracking';

interface Args {
  filters: KpiCompareReportFilters;
  handleTemplateChange: (template: ITemplate) => void;
  updateRelativeTimeframe: (relativeTimeframe?: RelativeTimeframe) => void;
}

export default class KpiCompareReportFilterTemplateSelectionIndex extends Component<Args> {
  @service kpiDataFilter: KpiDataFilterService;
  @tracked _selectedTemplate: ITemplate;

  get selectedRelativeTimeframeId() {
    let { relativeTimeframe } = this.args.filters;
    return relativeTimeframe
      ? serializeRelativeTimeframe(relativeTimeframe)
      : undefined;
  }

  get reportType() {
    return ReportType.KPI_COMPARE_REPORT;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get selectedTemplate(): ITemplate {
    return this._selectedTemplate;
  }

  @action
  handleTemplateChange(selectedTemplate: ITemplate) {
    this._selectedTemplate = selectedTemplate;
    this.args.handleTemplateChange(selectedTemplate);
  }

  @action
  handleTimeframeChange(relativeTimeframeId: RelativeTimeframeId | undefined) {
    const relativeTimeframe = relativeTimeframeId
      ? deserializeRelativeTimeframe(relativeTimeframeId)
      : undefined;

    this.args.updateRelativeTimeframe(relativeTimeframe);
  }
}
