import EmberRouter from '@embroider/router';
import config from 'fabscale-app/config/environment';
import { macroCondition, getOwnConfig } from '@embroider/macros';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('routes/index', { path: '/' });

  this.route('routes/login', { path: '/login' });
  this.route('routes/logout', { path: '/logout' });
  this.route('routes/select-location', { path: '/select-location' });
  this.route('routes/login-successful', { path: '/login-successful' });
  this.route('routes/reset-password', { path: '/reset-password' });

  this.route('routes/debug', { path: '/debug' });

  this.route('routes/plant-asset-monitoring', {
    path: '/plant-asset-monitoring',
  });

  this.route('routes/roast-pic', {
    path: '/roast-pic',
  });

  this.route('routes/roast-batches', { path: '/roast-batches' }, function () {
    this.route('show', { path: '/:id' }, function () {
      this.route('edit');
    });
  });

  this.route('routes/reports', { path: '/reports' }, function () {
    this.route('kpi-details');
    this.route('kpi-compare', function () {
      this.route('manage-templates');
    });
    this.route('plant-compare');
    this.route('status-map');
    this.route('oee-kpi');
    this.route('alarm', function () {
      this.route('level-report');
      this.route('type-report');
    });
  });

  // TODO FN: Remove this eventually
  this.route('routes/settings', { path: '/settings' }, function () {
    this.route('overview'); // Mobile only

    this.route('my-profile');

    this.route('security', function () {
      this.route('mfa-setup');
      this.route('mfa-setup-password');
    });

    this.route('company-users', function () {
      this.route('create');
      this.route('show', { path: '/:email' }, function () {
        this.route('edit');
      });
    });

    this.route('roles', function () {
      this.route('create');
      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
      });
    });
  });

  this.route('routes/plant', { path: '/plant' }, function () {
    this.route('overview'); // Mobile only

    this.route('status-map');

    this.route('settings');

    this.route('plant-assets', function () {
      this.route('create');

      this.route('areas', { path: '/areas/:id' }, function () {
        this.route('edit');
      });

      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
        this.route('create-area');
      });
    });

    this.route('parameter-goals', function () {
      this.route('create');
      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
      });
    });
  });

  this.route('routes/dashboard', { path: '/dashboard' }, function () {
    this.route('customize');
    this.route('kpi-overview');
  });

  this.route('routes/maintenance', { path: '/maintenance' }, function () {
    this.route('tasks', function () {
      this.route('create');
      this.route('create-bulk');

      this.route('calendar', function () {
        this.route('date', { path: '/:date' });
      });

      this.route('open');
      this.route('completed');
      this.route('my');
      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
      });
    });
  });

  this.route('routes/status-map', { path: '/status-map' });

  this.route(
    'routes/plant-asset-alerts',
    { path: '/plant-asset-alerts' },
    function () {
      this.route('show', { path: '/:id' });
    }
  );

  this.route('routes/my-settings', { path: '/my-settings' }, function () {
    this.route('my-profile');

    this.route('security', function () {
      this.route('mfa-setup');
      this.route('mfa-setup-password');
    });
  });

  this.route('routes/plant-settings', { path: '/plant-settings' }, function () {
    this.route('settings');

    this.route('plant-assets', function () {
      this.route('create');

      this.route('areas', { path: '/areas/:id' }, function () {
        this.route('edit');
      });

      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
        this.route('create-area');
      });
    });

    this.route('parameter-goals', function () {
      this.route('create');
      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
      });
    });

    this.route('shifts');
  });

  this.route(
    'routes/company-settings',
    { path: '/company-settings' },
    function () {
      this.route('users', function () {
        this.route('create');
        this.route('show', { path: '/:email' }, function () {
          this.route('edit');
        });
      });

      this.route('roles', function () {
        this.route('create');
        this.route('show', { path: '/:id' }, function () {
          this.route('edit');
        });
      });
    }
  );

  this.route(
    'routes/change-location',
    { path: '/change-location' },
    function () {
      this.route('location', { path: '/:id' });
    }
  );

  // Styleguide - only available outside of production
  // Content is added in lib/styleguide (and stripped out in production)
  this.route('routes/styleguide', { path: '/styleguide' }, function () {
    this.route('base');
    this.route('layout');
    this.route('form');
    this.route('buttons');
    this.route('content');
    this.route('table');
    this.route('other');

    // For UI link tests
    this.route('link-test');
    this.route('link-model-test', { path: '/link-model-test/:id' });
  });

  if (macroCondition(getOwnConfig<any>().adminMode)) {
    this.route('admin', function () {
      this.route('analytics');

      this.route('companies', function () {
        this.route('show', { path: '/:id' });
      });

      this.route('users', function () {
        this.route('show', { path: '/:email' });
      });

      this.route('cognito-app-clients', function () {
        this.route('create');
      });

      this.route('base-data', function () {
        this.route('features');
      });
    });
  }

  // 404 Page
  this.route('route-not-found', { path: '/*path' });
});
