import { assert } from '@ember/debug';
import { ButtonType } from 'fabscale-app/models/enums/button-types';

export default function buttonClass(
  type: ButtonType = 'primary',
  {
    isSmall,
    isLoading,
    isButtonGroup,
  }: { isSmall?: boolean; isLoading?: boolean; isButtonGroup?: boolean }
): string {
  const typeClasses = BUTTON_TYPE_TO_CLASS[type];

  assert(
    `The button type "${type} is not valid, only ${Object.keys(
      BUTTON_TYPE_TO_CLASS
    ).join(', ')} are allowed.`,
    !!typeClasses
  );

  const classes = [typeClasses];

  if (isSmall) {
    classes.push('button--small');
  }

  if (isLoading) {
    classes.push('button--loading');
  }

  if (isButtonGroup) {
    classes.push('button--button-group');
  }

  return classes.join(' ');
}

export const BUTTON_TYPE_TO_CLASS: { [key in ButtonType]: string } = {
  primary: 'button button--primary',
  secondary: 'button button--secondary',
  link: 'button--plain default-link',
  plain: 'button--plain',
};
