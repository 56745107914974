import { get } from '@ember/object';
import { compare } from '@ember/utils';
import { SortDirection } from 'fabscale-app/models/enums/sort-options';
import { DateTime } from 'luxon';

export interface TableSortDataOptions {
  sortBy?: string;
  sortDirection?: SortDirection;
  defaultSortBy?: string;
  defaultSortDirection?: SortDirection;
}

export function tableSortData(
  data: any[],
  {
    sortBy,
    sortDirection,
    defaultSortBy,
    defaultSortDirection,
  }: TableSortDataOptions
) {
  if (!sortBy) {
    return data;
  }

  let defaultSortDirectionDesc = defaultSortDirection === 'DESC';

  let sortedData = data.slice();

  sortedData.sort((a, b) => {
    let compare1 = compare(getValue(a, sortBy), getValue(b, sortBy));

    if (compare1) {
      return compare1;
    }

    // Fall back to default sort, if possible
    if (defaultSortBy) {
      let compare2 = compare(
        getValue(a, defaultSortBy),
        getValue(b, defaultSortBy)
      );
      if (defaultSortDirectionDesc) {
        return compare2 * -1;
      }

      return compare2;
    }

    return 0;
  });

  if (sortDirection === 'DESC') {
    sortedData.reverse();
  }

  return sortedData;
}

function getValue(item: any, property: string) {
  let value = get(item, property);

  if (value instanceof DateTime) {
    return +value;
  }

  return value;
}
