import Service, { service } from '@ember/service';
import { getOwnConfig, isTesting, macroCondition } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import { getApiHost } from 'fabscale-app/utilities/utils/get-host';
import BackgroundJobService from './background-job';

export const API_STATUS_REFETCH_INTERVAL = 35;
const JOB_ID = 'ApiStatus';

export default class ApiStatusService extends Service {
  @service backgroundJob: BackgroundJobService;

  @tracked hasApiError = false;
  @tracked apiVersion?: string;

  _statusUrl = `${getApiHost()}/v1/status`;

  constructor(owner: any) {
    super(owner);

    this.backgroundJob.registerJob({
      id: JOB_ID,
      intervalSeconds: API_STATUS_REFETCH_INTERVAL,
      callback: () => {
        this.checkApiTask.perform();
      },
    });

    this.checkApiTask.perform();
  }

  willDestroy(): void {
    super.willDestroy();

    this.backgroundJob.unregisterJob(JOB_ID);
  }

  checkApiTask = restartableTask(async () => {
    let url = this._statusUrl;

    let json: { status?: string; version?: string } = {};

    try {
      json = await this._fetch(url);
    } catch (error) {
      // Continue
    }

    let hasApiError = json.status !== 'OK';

    this.hasApiError = hasApiError;
    this.apiVersion = json.version;
  });

  async _fetch(url: string): Promise<{ status?: string; version?: string }> {
    if (macroCondition(getOwnConfig<any>().mockGraphqlApi || isTesting())) {
      return { version: 'mock-api', status: 'OK' };
    }

    let response: Response = await fetch(url);
    return response.json();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'api-status': ApiStatusService;
  }
}
