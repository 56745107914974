import { TableColumnDefinition } from 'fabscale-app/models/table-column-definition';

export type TableGetColumnInfoHandler = (
  column: TableColumnDefinition,
  record: any
) => TableColumnInfo;

export interface TableColumnInfo {
  colspan?: number;
  showColumn: boolean;
}

export default function dataTableGetColumnInfo(
  getColumnInfoHandler: TableGetColumnInfoHandler,
  column: TableColumnDefinition,
  record: any
): TableColumnInfo {
  if (getColumnInfoHandler) {
    return getColumnInfoHandler(column, record);
  }

  return { colspan: undefined, showColumn: true };
}
