import { AuthenticatedRoute } from 'fabscale-app/routes/base/-base-authenticated-route';
import { service } from '@ember/service';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import { AbilitiesService } from 'ember-can';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Transition from '@ember/routing/-private/transition';

export default class PlantPlantAssetAreaEditRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;

  afterModel() {
    if (this.abilities.cannot('edit plant-asset-area')) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to edit plant asset areas.")
      );
    }
  }

  deactivate(transition?: Transition) {
    this.refreshRouteIfMatches(
      transition,
      'routes/plant-settings.plant-assets.areas'
    );
  }
}
