import { tracked } from '@glimmer/tracking';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';

export interface OeeKpiReportFilters {
  oeeKpiTypes: KpiType[];
  relativeTimeframe?: RelativeTimeframe;
  dateRange?: DateRangeOptional;
  timeRange?: TimeRangeOptional;
  plantAssetIds: string[];
  recipeIds: string[];
}

export class OeeKpiReportFilterFormData {
  @tracked dateRange?: DateRangeOptional;
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked timeRange: TimeRange;
  @tracked recipeIds: string[];
  @tracked plantAssetIds: string[];
  @tracked oeeKpiTypes: KpiType[];
  @tracked templateName = '';
}
