import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import { isOeeKpi, KpiType } from 'fabscale-app/models/enums/kpi-types';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { UserFrontendSettings } from 'fabscale-app/models/user-frontend-settings';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import SettingsService from './settings';
import UserSessionService from './user-session';

export default class KpiDataFilterService extends Service {
  @service l10n: L10nService;
  @service settings: SettingsService;
  @service userSession: UserSessionService;

  get userFrontendSettings(): UserFrontendSettings {
    return this.settings.userFrontendSettings;
  }

  getAvailableTemplates(): KpiDataFilterTemplate[] {
    if (!this.userSession.permissionMap['OEE_MANAGE']) {
      this.getCustomTemplates().filter((template) =>
        this.hasNoOeeKpis(template.filterSettings.kpiTypes)
      );
    }

    return this.getCustomTemplates();
  }

  private hasNoOeeKpis(kpiTypes: KpiType[]) {
    return kpiTypes.filter((kpiType) => isOeeKpi(kpiType)).length === 0;
  }

  private hasOnlyOeeKpis(kpiTypes: KpiType[]) {
    return (
      kpiTypes.filter((kpiType) => isOeeKpi(kpiType)).length === kpiTypes.length
    );
  }

  getOeeAvailableTemplates(): KpiDataFilterTemplate[] {
    return this.getCustomTemplates().filter((template) =>
      this.hasOnlyOeeKpis(template.filterSettings.kpiTypes)
    );
  }

  getCustomTemplates(): KpiDataFilterTemplate[] {
    return this.userFrontendSettings.kpiDataFilterTemplates.slice();
  }

  async addCustomTemplate(kpiDataFilterTemplate: KpiDataFilterTemplate) {
    let { userFrontendSettings } = this;

    let kpiDataFilterTemplates = this.getCustomTemplates();
    kpiDataFilterTemplates.unshift(kpiDataFilterTemplate);

    userFrontendSettings.kpiDataFilterTemplates = kpiDataFilterTemplates;
    await userFrontendSettings.save();
  }

  async deleteCustomTemplate(kpiDataFilterTemplate: KpiDataFilterTemplate) {
    let { userFrontendSettings } = this;

    let kpiDataFilterTemplates = this.getCustomTemplates();
    removeItem(kpiDataFilterTemplates, kpiDataFilterTemplate);

    userFrontendSettings.kpiDataFilterTemplates = kpiDataFilterTemplates;
    await userFrontendSettings.save();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'kpi-data-filter': KpiDataFilterService;
  }
}
