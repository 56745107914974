import Transition from '@ember/routing/-private/transition';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import { AuthenticatedRoute } from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';

export default class PlantPlantAssetsAreaRoute extends AuthenticatedRoute {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;
  @service abilities: AbilitiesService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (this.abilities.cannot('view plant-asset-area')) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to view areas.")
      );
    }
  }

  model(params: { id: string }) {
    let { plantAssetStore } = this;
    let { id } = params;

    return plantAssetStore.findAreaById(id);
  }
}
