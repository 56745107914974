import Component from '@glimmer/component';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import { DateTime } from 'luxon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Args {}

export default class PageStatusMapReportPerPlantAsset extends Component<Args> {
  externalTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.zIndex = '1';
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    const dateFrom = DateTime.fromISO(
      tooltip.dataPoints[0].dataset.data[0].dateRange.start
    ).toLocaleString(DateTime.DATETIME_SHORT);
    const dateTo = DateTime.fromISO(
      tooltip.dataPoints[0].dataset.data[0].dateRange.end
    ).toLocaleString(DateTime.DATETIME_SHORT);
    const dateLabel = `${dateFrom} - ${dateTo}`;

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      bodyLines.forEach((body: any, i: any) => {
        const color = tooltip.dataPoints[i].dataset.backgroundColor;

        const coloredSquare = document.createElement('span');
        coloredSquare.style.background = color;
        coloredSquare.style.borderColor = color;
        coloredSquare.style.borderWidth = cssObj.spacings._0px;
        coloredSquare.style.marginRight = cssObj.spacings._10px;
        coloredSquare.style.height = cssObj.spacings._12px;
        coloredSquare.style.width = cssObj.spacings._12px;
        coloredSquare.style.display = cssObj.display.inlineBlock;

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let tooltipRowContainer = document.createElement('span');
        tooltipRowContainer.style.display = cssObj.display.flex;
        tooltipRowContainer.style.flexDirection = cssObj.flex.flexDirection.row;
        tooltipRowContainer.style.justifyContent = 'space-between';
        tooltipRowContainer.style.width = '100%';

        const dataIndex = tooltip.dataPoints[i].dataIndex;

        let rowTextLabel = document.createElement('p');
        rowTextLabel.style.marginBottom = cssObj.spacings._0px;
        rowTextLabel.innerHTML =
          tooltip.dataPoints[i].dataset.data[dataIndex].label;

        let rowTextValue = document.createElement('p');
        rowTextValue.innerHTML = `${tooltip.dataPoints[i].dataset.data[
          dataIndex
        ].value.toFixed(2)} h`;
        rowTextValue.style.fontWeight = 'bold';

        tooltipRowContainer.append(rowTextLabel);
        tooltipRowContainer.append(rowTextValue);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';

        tableDataContainer.append(coloredSquare, tooltipRowContainer);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      let pElementDateLabel = document.createElement('p');
      pElementDateLabel.style.marginBottom = cssObj.spacings._0px;
      pElementDateLabel.style.color = cssObj.colors.sonicSilver;
      pElementDateLabel.style.fontSize = cssObj.spacings._12px;
      pElementDateLabel.innerHTML = dateLabel;

      tableBody.appendChild(pElementDateLabel);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${
      Number(positionY) + Number(tooltip.caretY) + 40
    }px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  get chartOptions() {
    return {
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'x',
      },
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this.externalTooltip,
        },
      },
      scales: {
        x: {
          stacked: true,
          offset: true,
          border: {
            display: true,
          },
          grid: {
            stacked: true,
            display: true,
            drawTicks: false,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
        },
        y: {
          beginAtZero: true,
          stacked: true,
          offset: false,
          position: cssObj.position.left,
          ticks: {
            display: function (context: any) {
              return !!context.scale.chart.config._config.data.datasets.length;
            },
            maxTicksLimit: 6,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
            lineWidth: (context: any) => Number(!!context.index),
          },
        },
      },
    };
  }
}
