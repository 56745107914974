export class Feature {
  id: string;
  name: string;
  label?: string;

  constructor(options: { id: string; name: string; label?: string }) {
    this.id = options.id;
    this.name = options.name;
    this.label = options.label;
  }
}
