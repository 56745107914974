import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import { DateTimeString, PageDef, PageInfo, SortDef } from 'fabscale-app';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import queryFindById from 'fabscale-app/gql/queries/plant-asset-alert-by-id.graphql';
import queryPaginated from 'fabscale-app/gql/queries/plant-asset-alerts-paginated.graphql';
import plantAlarmsExternalIdSummaryQuery from 'fabscale-app/gql/queries/plant-alarms-external-id-summary.graphql';
import availableAlertExternalIds from 'fabscale-app/gql/queries/available-alert-external-ids.graphql';
import {
  PlantAssetAlert,
  PlantAssetAlertPojo,
} from 'fabscale-app/models/plant-asset-alert';
import GraphQLService from 'fabscale-app/services/-graphql';
import UserSessionService from '../user-session';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

export interface PaginatedPlantAssetAlerts {
  pageInfo: PageInfo;
  plantAssetAlerts: PlantAssetAlert[];
}

export interface PlantAssetAlarmFilters {
  startDate?: DateTimeString;
  endDate?: DateTimeString;
  alarmLevels?: PlantAssetAlertLevel[];
  alarmTypes?: PlantAssetAlarmType[];
  plantAssetIds?: string[];
  alarmExternalIds?: string[];
  alarmTitle?: string;
}

export interface PlantAlarmExternalIdSummaryFilters {
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
  plantAssetIds?: string[];
  recipeIds?: string[];
  alarmType: PlantAssetAlarmType;
  alarmExternalId?: string[];
}

export default class StorePlantAssetAlertService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  // METHODS

  async findById(id: string): Promise<PlantAssetAlert> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let response: PlantAssetAlertPojo = await graphql.query(
        { query: queryFindById, variables, namespace: 'plantAssetAlertById' },
        {
          cacheSeconds: 300,
          cacheEntity: 'PlantAssetAlert',
          cacheId: id,
        }
      );

      return new PlantAssetAlert(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The alert with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async queryPaginated(
    filters: PlantAssetAlarmFilters,
    pageDef: PageDef,
    { sortBy, sortDirection }: SortDef
  ): Promise<PaginatedPlantAssetAlerts> {
    const { graphql, locationId } = this;

    const variables = {
      filters: Object.assign({ locationId }, filters),
      pageDef,
      sortBy,
      sortDirection,
    };

    const response: {
      items: PlantAssetAlertPojo[];
      pageInfo: PageInfo;
    } = await graphql.query(
      {
        query: queryPaginated,
        variables,
        namespace: 'plantAssetAlertsPaginated',
      },
      {
        cacheSeconds: 10,
        cacheEntity: 'PlantAssetAlert',
      }
    );

    return {
      plantAssetAlerts: response.items.map((item) => new PlantAssetAlert(item)),
      pageInfo: response.pageInfo,
    };
  }

  async availableAlertExternalIds(
    alarmType?: PlantAssetAlarmType
  ): Promise<string[] | undefined> {
    const { graphql } = this;

    const locationId = this.userSession.currentLocation?.id;

    if (!locationId) {
      return undefined;
    }

    const variables = {
      locationId,
      alarmType,
    };

    const data = await graphql.query({
      query: availableAlertExternalIds,
      variables,
      namespace: 'availableAlertExternalIds',
    });

    return data.items;
  }

  async queryPlantAlarmsExternalIdSummary(
    filters: PlantAssetAlarmFilters
  ): Promise<any> {
    const { graphql } = this;

    const locationId = this.userSession.currentLocation?.id;

    if (!locationId) {
      return undefined;
    }

    const variables = {
      locationId,
      filters: filters,
    };

    return await graphql.query({
      query: plantAlarmsExternalIdSummaryQuery,
      variables,
      namespace: 'plantAlarmsExternalIdSummary',
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/plant-asset-alert': StorePlantAssetAlertService;
  }
}
