export const FileExtensions = [
  // Web-image formats
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  // Other image formats
  'bmp',
  'tif',
  'tiff',

  // Word
  'doc',
  'docx',

  // Excel
  'xls',
  'xlsx',
  'csv',

  // Video
  'mp4',
  'mov',
  'avi',
  'webm',
  'mpg',
  'mpeg',

  // Other formats
  'pdf',
  'txt',
];

export const FileExtensionsImage = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'bmp',
  'tif',
  'tiff',
];

export const FileExtensionsWebViewable = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'svg',
];

export const FileExtensionsVideo = ['mp4', 'mov', 'avi', 'webm', 'mpg', 'mpeg'];

export const FileExtensionsWord = ['doc', 'docx'];

export const FileExtensionsExcel = ['csv', 'xls', 'xlsx'];
