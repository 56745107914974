import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { LineChartDataInput } from 'fabscale-app/components/chart';
import { UnitOrdinal } from 'fabscale-app/models/enums/units';
import { NumericCurveRoastBatchParameter } from 'fabscale-app/models/roast-batch';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { createRorCurve } from 'fabscale-app/utilities/utils/curve-ror';
import { DateTime } from 'luxon';

interface Args {
  numericCurveRoastBatchParameter: NumericCurveRoastBatchParameter;
}

export default class RoastBatchesNumericCurve extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  @tracked rorType = 30;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (
      this.args.numericCurveRoastBatchParameter.parameterType !==
      'PRODUCT_TEMPERATURE'
    ) {
      this.rorType = 0;
    }
  }

  get curveLabel() {
    return this.enumLabels.roastBatchParameterType(
      this.args.numericCurveRoastBatchParameter.parameterType
    );
  }

  get rorLabel() {
    let { rorType } = this;

    return rorType ? `RoR ${rorType}s` : undefined;
  }

  get colorA() {
    return chartColors[0];
  }

  get colorB() {
    return chartColors[1];
  }

  @cached
  get rorCurveValues() {
    return createRorCurve(
      this.args.numericCurveRoastBatchParameter.value,
      this.rorType
    );
  }

  @cached
  get lineChartData(): LineChartDataInput[] {
    let { numericCurveRoastBatchParameter } = this.args;
    let { colorA, curveLabel, rorLabel } = this;

    let { unit, value } = numericCurveRoastBatchParameter;

    let curveItems: LineChartDataInput[] = value.map((timeValuePair) => {
      let date = DateTime.fromMillis(timeValuePair.time);

      return {
        label: curveLabel,
        date,
        value: timeValuePair.value,
        unit,
        color: colorA,
      };
    });

    if (!rorLabel) {
      return curveItems;
    }

    let { rorCurveValues, colorB } = this;

    let rorItems: LineChartDataInput[] = rorCurveValues.map((timeValuePair) => {
      let date = DateTime.fromMillis(timeValuePair.time);

      return {
        label: rorLabel!,
        date,
        value: timeValuePair.value || 0,
        unit: 'COUNT' as UnitOrdinal,
        color: colorB,
      };
    });

    return curveItems.concat(rorItems);
  }

  @action
  updateRorType(rorType: number) {
    this.rorType = rorType;
  }
}
