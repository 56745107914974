import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import { PageDef } from 'fabscale-app';
import { Interval } from 'fabscale-app/models/enums/intervals';
import {
  PlantAssetStatus,
  PlantAssetStatuses,
} from 'fabscale-app/models/enums/plant-asset-status';
import { PlantAssetStatusReason } from 'fabscale-app/models/enums/plant-asset-status-reason';
import { UnitTime } from 'fabscale-app/models/enums/units';
import mutationUpdateInfo from 'fabscale-app/gql/mutations/update-plant-asset-state-info-reason.graphql';
import querySummaryOverTime from 'fabscale-app/gql/queries/plant-asset-info-summaries-over-time.graphql';
import querySummaries from 'fabscale-app/gql/queries/plant-asset-info-summaries.graphql';
import querySummaryGrouped from 'fabscale-app/gql/queries/plant-asset-info-summary-grouped.graphql';
import querySummary from 'fabscale-app/gql/queries/plant-asset-info-summary.graphql';
import queryList from 'fabscale-app/gql/queries/plant-asset-state-info-list.graphql';
import queryPaginated from 'fabscale-app/gql/queries/plant-asset-state-info-paginated.graphql';
import { DateBinValueInput } from 'fabscale-app/models/date-bin-value';
import { DateRange } from 'fabscale-app/models/date-range';
import {
  PlantAssetInfoSummary,
  PlantAssetInfoSummaryInput,
  PlantAssetInfoSummaryOverTime,
} from 'fabscale-app/models/plant-asset-info-summary';
import {
  PlantAssetStateInfo,
  PlantAssetStateInfoInput,
} from 'fabscale-app/models/plant-asset-state-info';
import { sortBy, sortByList } from 'fabscale-app/utilities/utils/array';
import {
  serializeDate,
  serializeLocalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { convertTimeAmount } from 'fabscale-app/utilities/utils/unit-converter';
import GraphQLService from '../-graphql';
import EnumLabelsService from '../enum-labels';
import SettingsService from '../settings';
import UserSessionService from '../user-session';

type PlantAssetStatusOrTotal = PlantAssetStatus | 'CALCULATED_UPTIME';

export interface PlantAssetInfoSummaryGrouped {
  runtimeInHours: number;
  status: PlantAssetStatus;
  plantAsset?: { id: string; name: string };
}

export default class StorePlantAssetInfoService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;
  @service enumLabels: EnumLabelsService;
  @service settings: SettingsService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  // METHODS
  async findAll(filters: {
    dateRange: DateRange;
    plantAssetId: string;
    status?: PlantAssetStatus[];
    reason?: (string | null)[];
  }): Promise<PlantAssetStateInfo[]> {
    let { graphql } = this;

    let variables = {
      filters: {
        dateFrom: serializeDate(filters.dateRange.start),
        dateTo: serializeDate(filters.dateRange.end),
        plantAssetId: filters.plantAssetId,
        status: filters.status,
        reason: filters.reason,
      },
    };

    let response: PlantAssetStateInfoInput[] = await graphql.query(
      {
        query: queryList,
        variables,
        namespace: 'plantAssetStateInfoList',
      },
      {
        cacheSeconds: 600,
        cacheEntity: 'PlantAssetStateInfo',
      }
    );

    return sortBy(
      response.map((input) => new PlantAssetStateInfo(input)),
      'startDate'
    );
  }

  async findPaginated(
    filters: {
      plantAssetIds?: string[];
      status?: PlantAssetStatus[];
      reasonType?: 'ANY' | 'WITH_REASON' | 'WITHOUT_REASON';
    },
    pageDef: PageDef
  ): Promise<{
    items: PlantAssetStateInfo[];
    pageInfo: { totalItemCount: number };
  }> {
    let { graphql, locationId } = this;

    let variables = {
      filters: {
        locationId,
        plantAssetIds: filters.plantAssetIds,
        status: filters.status,
        reasonType: filters.reasonType,
      },
      pageDef,
    };

    let response: {
      items: PlantAssetStateInfoInput[];
      pageInfo: { totalItemCount: number };
    } = await graphql.query(
      {
        query: queryPaginated,
        variables,
        namespace: 'plantAssetStateInfoPaginated',
      },
      {
        cacheSeconds: 600,
        cacheEntity: 'PlantAssetStateInfo',
      }
    );

    let items = response.items.map((input) => new PlantAssetStateInfo(input));

    return { items, pageInfo: response.pageInfo };
  }

  async getUptimeSummary(filters: {
    dateRange: DateRange;
    plantAssetId: string;
  }): Promise<PlantAssetInfoSummary> {
    let { graphql } = this;

    let variables = {
      dateFrom: serializeDate(filters.dateRange.start),
      dateTo: serializeDate(filters.dateRange.end),
      plantAssetId: filters.plantAssetId,
    };

    let response: PlantAssetInfoSummaryInput = await graphql.query(
      {
        query: querySummary,
        variables,
        namespace: 'plantAssetStateInfoSummary',
      },
      {
        cacheSeconds: 600,
        cacheEntity: 'PlantAssetStateInfo',
      }
    );

    return new PlantAssetInfoSummary(
      response,
      this._getLabel('CALCULATED_UPTIME')
    );
  }

  async findSummaries(filters: {
    dateRange: DateRange;
    plantAssetIds: string[];
  }): Promise<PlantAssetInfoSummary[]> {
    let { graphql } = this;

    let variables = {
      dateFrom: serializeDate(filters.dateRange.start),
      dateTo: serializeDate(filters.dateRange.end),
      plantAssetIds: filters.plantAssetIds,
    };

    let response: {
      [key in PlantAssetStatusOrTotal]: PlantAssetInfoSummaryInput;
    } = await graphql.query(
      {
        query: querySummaries,
        variables,
      },
      {
        cacheSeconds: 600,
        cacheEntity: 'PlantAssetStateInfo',
      }
    );

    return this._parseSummaryResponse(response);
  }

  async getSummariesGrouped(filters: {
    dateRange: DateRange;
    plantAssetIds: string[];
    plantAssetStatus: PlantAssetStatus[];
    groupByPlantAsset: boolean;
  }): Promise<PlantAssetInfoSummaryGrouped[]> {
    let { graphql } = this;

    let variables = {
      dateFrom: serializeDate(filters.dateRange.start),
      dateTo: serializeDate(filters.dateRange.end),
      plantAssetIds: filters.plantAssetIds,
      plantAssetStatus: filters.plantAssetStatus,
      groupByPlantAsset: filters.groupByPlantAsset,
    };

    let response: {
      unit: UnitTime;
      values: {
        value: number;
        status: PlantAssetStatus;
        plantAsset?: { id: string; name: string };
      }[];
    } = await graphql.query(
      {
        query: querySummaryGrouped,
        variables,
        namespace: 'plantAssetStateInfoSummaryGrouped',
      },
      {
        cacheSeconds: 600,
        cacheEntity: 'PlantAssetStateInfo',
      }
    );

    let { unit, values } = response;

    return values.map((group) => {
      let { value: originalValue, status, plantAsset } = group;
      let runtimeInHours = convertTimeAmount(originalValue, unit, 'HOUR');

      return {
        runtimeInHours,
        status,
        plantAsset,
      };
    });
  }

  async getSummariesOverTime(
    plantAssetStatus: PlantAssetStatus,
    filters: {
      dateRange: DateRange;
      plantAssetIds: string[];
      interval: Interval;
    }
  ): Promise<PlantAssetInfoSummaryOverTime> {
    let { graphql, settings } = this;

    let { startDayOfWeek, dayStartTime } = settings.locationSettings;
    let { timezoneName } = this.userSession.currentLocation!;
    let { dateRange, plantAssetIds, interval } = filters;

    let variables = {
      filters: {
        dateFrom: serializeLocalDate(dateRange.start),
        dateTo: serializeLocalDate(dateRange.end),
        plantAssetIds,
        plantAssetStatus: [plantAssetStatus],
        intervalDefinition: {
          startDayOfWeek,
          dayStartTime,
          interval,
          timezoneName,
        },
      },
    };

    let response: {
      unit: UnitTime;
      values: DateBinValueInput[];
    } = await graphql.query(
      {
        query: querySummaryOverTime,
        variables,
        namespace: 'plantAssetStateInfoSummaryOverTime',
      },
      {
        cacheSeconds: 600,
        cacheEntity: 'PlantAssetStateInfo',
      }
    );

    let { unit } = response;

    return new PlantAssetInfoSummaryOverTime({
      unit,
      bins: response.values,
      plantAssetStatus,
    });
  }

  async update(
    id: string,
    {
      reason,
      comment,
    }: {
      reason: PlantAssetStatusReason | undefined;
      comment: string | undefined;
    }
  ): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
      input: { reason, comment },
    };

    await graphql.mutate(
      {
        mutation: mutationUpdateInfo,
        variables,
        namespace: 'updatePlantAssetStateInfoReason',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAssetStateInfo',
          },
          {
            cacheEntity: 'PlantAssetStateInfo',
            cacheId: id,
          },
        ],
      }
    );
  }

  _parseSummaryResponse(response: {
    [key in PlantAssetStatusOrTotal]: PlantAssetInfoSummaryInput;
  }) {
    let keys = ['CALCULATED_UPTIME'].concat(
      PlantAssetStatuses
    ) as PlantAssetStatusOrTotal[];

    return sortByList(
      Object.keys(response) as PlantAssetStatusOrTotal[],
      keys
    ).map((plantAssetStatus: PlantAssetStatusOrTotal) => {
      let data = response[plantAssetStatus];

      return new PlantAssetInfoSummary(data, this._getLabel(plantAssetStatus));
    });
  }

  _getLabel(status: PlantAssetStatusOrTotal) {
    let { l10n, enumLabels } = this;

    if (status === 'CALCULATED_UPTIME') {
      return l10n.t('Total uptime');
    }

    return enumLabels.plantAssetStatus(status);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/plant-asset-info': StorePlantAssetInfoService;
  }
}
