import Transition from '@ember/routing/-private/transition';
import { getOwnConfig, macroCondition } from '@embroider/macros';

export function initialize(appInstance: any) {
  if (macroCondition(!getOwnConfig<any>().adminMode)) {
    return;
  }

  let router = appInstance.lookup('service:router');
  let userAuthentication = appInstance.lookup('service:user-authentication');

  // Force user to stay in admin area
  router.on('routeDidChange', (transition: Transition) => {
    let targetRouteName = transition.to.name;

    if (
      userAuthentication.isAuthenticated &&
      !targetRouteName.startsWith('admin.')
    ) {
      router.transitionTo('admin');
    }
  });
}

export default {
  initialize,
  after: 'global-polyfill',
};
