import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { OeeKpiReportTableData } from './table-columns';

export default function oeeKpiReportTransformKpiDataGrouped(
  data: KpiDataGrouped[]
): OeeKpiReportTableData[] {
  const groupedData: OeeKpiReportTableData[] = [];

  data.forEach((kpiDataGrouped) => {
    const { type, values, unit } = kpiDataGrouped;

    values.forEach((kpiDataGroup) => {
      const { name, value } = kpiDataGroup;

      let group = groupedData.find((item) => item.label === name);

      if (kpiDataGroup.externalId && group) {
        group.externalId = kpiDataGroup.externalId;
      }
      if (!group) {
        group = { label: name, searchLabel: name.toLowerCase() };
        groupedData.push(group);
      }

      group[type] = { value, unit };
    });
  });

  return groupedData;
}
