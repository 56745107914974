import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { PlantAlarmOverTimeTimeSeries } from 'fabscale-app/models/alarm-type-report';

export default function plantAlarmsTypeOverTimeChartData(
  data: any,
  selectedRecipesWithColor: {
    id: string;
    name: string;
    color: string;
  }[] = []
) {
  if (!data) {
    return {
      datasets: [],
    };
  }
  const datasets = data.map(
    (item: PlantAlarmOverTimeTimeSeries, index: number) => {
      const data = item.dateValueTimeSeries.map((dateValue: any) => {
        return {
          dateFrom: dateValue.dateFrom,
          dateTo: dateValue.dateTo,
          date: dateValue.dateFrom,
          value: dateValue.value,
          label: item.idNamePair.name,
        };
      });

      return {
        data: data,
        backgroundColor: selectedRecipesWithColor.length
          ? selectedRecipesWithColor[index]!.color
          : chartColors[index],
        borderColor: selectedRecipesWithColor.length
          ? selectedRecipesWithColor[index]!.color
          : chartColors[index],
        label: item.idNamePair.name,
        parsing: {
          xAxisKey: 'date',
          yAxisKey: 'value',
        },
      };
    }
  );

  return {
    datasets: datasets,
  };
}
