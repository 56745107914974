import { service } from '@ember/service';
import {
  BooleanRoastBatchParameterType,
  NumericCurveRoastBatchParameterType,
  NumericRoastBatchParameterType,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import AvailableDataService from 'fabscale-app/services/available-data';
import BaseAsyncResource from './base-async-resource';

export default class LoadAvailableRoastBatchParameterTypesResource extends BaseAsyncResource<
  {
    numeric: NumericRoastBatchParameterType[];
    boolean: BooleanRoastBatchParameterType[];
    numericCurve: NumericCurveRoastBatchParameterType[];
  },
  any
> {
  @service availableData: AvailableDataService;

  loadData() {
    return this.availableData.getAvailableRoastBatchParameterTypes();
  }
}
