import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import CognitoService from 'fabscale-app/services/cognito';

type Args = any;

export default class SettingsMyProfileMfaSetup extends Component<Args> {
  @service cognito: CognitoService;
  @service router: RouterService;

  mfaIsEnabled?: boolean;
  @tracked shouldShowSetupForm = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._checkMfa();
  }

  async _checkMfa() {
    let mfaIsEnabled = await this.cognito.cognitoData!.mfa.isEnabled();
    this.shouldShowSetupForm = !mfaIsEnabled;
    this.mfaIsEnabled = mfaIsEnabled;
  }

  @action
  showSetupForm() {
    this.shouldShowSetupForm = true;
  }

  @action
  onSetupGoBack() {
    if (this.mfaIsEnabled) {
      this.shouldShowSetupForm = false;
    } else {
      this.router.transitionTo('routes/my-settings.security.index');
    }
  }
}
