import { EnumsBase } from './-base';

export const PlantAssetAlarmTypes = [
  'AFTERBURNER_DISTURBED',
  'AFTERBURNER_SWITCHED_OFF',
  'AFTERBURNER_SWITCHED_ON',
  'ALARM_SYSTEM_NOT_READY',
  'BATCH_PROTOCOL_DISTURBED',
  'BATCH_PROTOCOL_NOT_GENERATED',
  'CLOSED',
  'COMMUNICATION_DISTURBED',
  'COOLER_NOT_DISCHARGED',
  'COOLER_OUTLET_BIN_SELECTED',
  'COOLING_TIME_EXCEEDED',
  'DEFECT',
  'DISTURBED',
  'DMS_ISSUE',
  'EMERGENCY_STOP_ACTIVATED',
  'EMERGENCY_WATER_ACTIVATED',
  'EMERGENCY_WATER_MISSING',
  'EMPTY',
  'FAIL_TO_CLOSE',
  'FAIL_TO_OPEN',
  'FILLED',
  'FINAL_TEMPERATURE_NOT_OK',
  'HIGH_ALARM',
  'INPUT_IN_PROGRESS',
  'INPUT_WEIGHT_NOT_OK',
  'INTERLOCK_AIRLOCK_COOLER_CYCLONE',
  'INTERLOCK_AIRLOCK_PREHEATER_CYCLONE',
  'INTERLOCK_AIRLOCK_ROAST_CYCLONE',
  'INTERLOCK_BUCKET_ELEVATOR',
  'INTERLOCK_CHAFF_ASPIRATION_BURNER',
  'INTERLOCK_CHAFF_ASPIRATION_FEEDING',
  'INTERLOCK_COOLER_DISCHARGE',
  'INTERLOCK_ROASTER_DISCHARGE',
  'LOW_ALARM',
  'MAINBURNER_DISTURBED',
  'MAINBURNER_SWITCHED_OFF',
  'MAINBURNER_SWITCHED_ON',
  'MEASURING_SYSTEM_NOT_OK',
  'MODE_CHANGE_MAINTENANCE',
  'MODE_CHANGE_OPERATOR',
  'MODE_CHANGE_PROGRAM',
  'MOISTURE_NOT_OK',
  'NOT_EMPTY',
  'NOT_IN_POSITION',
  'NOT_PROGRAM_MODE',
  'NOT_READY',
  'NO_PLANT_RELEASE_DISCHARGE',
  'NO_PLANT_RELEASE_FEEDING',
  'OPEN',
  'OPENED',
  'OPEN_WIRE',
  'OTHER',
  'OUTLET_BIN_IN_MANUAL',
  'OUTLET_SCALE_IN_MANUAL',
  'OUTPUT_TRANSPORT_DISTURBED',
  'OVERFULL',
  'OVERLOAD',
  'OVERVOLTAGE_PROTECTION_NOT_OK',
  'PID_MODE_LOCAL',
  'PID_MODE_MANUAL',
  'PLANT_AIR_HANDLING_DISTURBED',
  'PLANT_RECIPE_CHANGE',
  'POWER_SUPPLY_NOT_OK',
  'PRECOOLING_WATER_TOO_LOW',
  'PREHEATER_EMPTY',
  'PREHEATING_COMPLETED_RECENTLY',
  'PREHEATING_IN_PROGRESS',
  'PRODUCT_TEMPERATURE_NOT_CHANGING',
  'QUENCHING_TIME_EXCEEDED',
  'RECIPE_CHANGE_DISTURBED',
  'ROAST_COLOR_REACHED',
  'ROAST_COLOR_SENSOR_DISTURBED',
  'ROAST_TIME_TOO_LONG',
  'ROAST_TIME_TOO_SHORT',
  'SHRINKAGE_NOT_OK',
  'SPEED_NOT_OK',
  'STANDBY_ACTIVATED',
  'STANDBY_PREPARED',
  'STANDBY_RECENTLY',
  'STANDBY_TIMEOUT',
  'STARTED',
  'STOPPED',
  'TEMPERATURE_TOO_HIGH',
  'THERMAL_CLEANING_ACKNOWLEDGED',
  'THERMAL_CLEANING_NOT_ACKNOWLEDGED',
  'THERMAL_CLEANING_RECOMMENDED',
  'THERMAL_CLEANING_REQUIRED',
  'UPS_NOT_OK',
  'WATER_BUFFER_STATION_NOT_OK',
  'WATER_TAPS_CLOSED',
  'WEIGHT_EXCEEDED',
] as const;

export type PlantAssetAlarmType = typeof PlantAssetAlarmTypes[number];

export class PlantAssetAlarmTypesEnums extends EnumsBase<PlantAssetAlarmType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in PlantAssetAlarmType]: string } = {
      THERMAL_CLEANING_ACKNOWLEDGED: l10n.t('Thermal cleaning acknowledged'),
      ALARM_SYSTEM_NOT_READY: l10n.t('Alarm system not ready'),
      PRECOOLING_WATER_TOO_LOW: l10n.t('Precooling water too low'),
      EMERGENCY_WATER_MISSING: l10n.t('Emergency water missing'),
      NOT_READY: l10n.t('Not ready'),
      AFTERBURNER_SWITCHED_OFF: l10n.t('Afterburner switched off'),
      BATCH_PROTOCOL_NOT_GENERATED: l10n.t('Batch protocol not generated'),
      DISTURBED: l10n.t('Disturbed'),
      SPEED_NOT_OK: l10n.t('Speed not ok'),
      NOT_IN_POSITION: l10n.t('Not in position'),
      NO_PLANT_RELEASE_DISCHARGE: l10n.t('No plant release discharge'),
      INPUT_IN_PROGRESS: l10n.t('Input in progress'),
      NO_PLANT_RELEASE_FEEDING: l10n.t('No plant release feeding'),
      INPUT_WEIGHT_NOT_OK: l10n.t('Input weight not ok'),
      INTERLOCK_AIRLOCK_COOLER_CYCLONE: l10n.t(
        'Interlock airlock cooler cyclone'
      ),
      FILLED: l10n.t('Filled'),
      INTERLOCK_ROASTER_DISCHARGE: l10n.t('Interlock roaster discharge'),
      STOPPED: l10n.t('stopped'),
      PLANT_RECIPE_CHANGE: l10n.t('Plant recipe change'),
      SHRINKAGE_NOT_OK: l10n.t('Shrinkage not ok'),
      PRODUCT_TEMPERATURE_NOT_CHANGING: l10n.t(
        'Product temperature not changing'
      ),
      OVERLOAD: l10n.t('Overload'),
      NOT_PROGRAM_MODE: l10n.t('Not program mode'),
      INTERLOCK_CHAFF_ASPIRATION_FEEDING: l10n.t(
        'Interlock chaff aspiration feeding'
      ),
      HIGH_ALARM: l10n.t('High alarm'),
      WATER_BUFFER_STATION_NOT_OK: l10n.t('Water buffer station not ok'),
      STANDBY_TIMEOUT: l10n.t('Standby timeout'),
      UPS_NOT_OK: l10n.t('Ups not ok'),
      DMS_ISSUE: l10n.t('Dms issue'),
      MAINBURNER_SWITCHED_OFF: l10n.t('Mainburner switched off'),
      OPEN_WIRE: l10n.t('Open wire'),
      OVERFULL: l10n.t('Overfull'),
      THERMAL_CLEANING_RECOMMENDED: l10n.t('Thermal cleaning recommended'),
      COOLER_NOT_DISCHARGED: l10n.t('Cooler not discharged'),
      ROAST_TIME_TOO_LONG: l10n.t('Roast time too long'),
      OPEN: l10n.t('Open'),
      COOLING_TIME_EXCEEDED: l10n.t('Cooling time exceeded'),
      FINAL_TEMPERATURE_NOT_OK: l10n.t('Final temperature not ok'),
      INTERLOCK_AIRLOCK_PREHEATER_CYCLONE: l10n.t(
        'Interlock airlock preheater cyclone'
      ),
      STANDBY_RECENTLY: l10n.t('Standby recently'),
      OUTPUT_TRANSPORT_DISTURBED: l10n.t('Output transport disturbed'),
      ROAST_COLOR_REACHED: l10n.t('Roast color reached'),
      INTERLOCK_COOLER_DISCHARGE: l10n.t('Interlock cooler discharge'),
      TEMPERATURE_TOO_HIGH: l10n.t('Temperature too high'),
      DEFECT: l10n.t('Defect'),
      OPENED: l10n.t('Opened'),
      EMERGENCY_WATER_ACTIVATED: l10n.t('Emergency water activated'),
      THERMAL_CLEANING_NOT_ACKNOWLEDGED: l10n.t(
        'Thermal cleaning not acknowledged'
      ),
      INTERLOCK_AIRLOCK_ROAST_CYCLONE: l10n.t(
        'INTERLOCK_AIRLOCK_ROAST_CYCLONE'
      ),
      MAINBURNER_DISTURBED: l10n.t('Mainburner disturbed'),
      PID_MODE_MANUAL: l10n.t('Pid mode manual'),
      NOT_EMPTY: l10n.t('Not empty'),
      AFTERBURNER_DISTURBED: l10n.t('Afterburner disturbed'),
      STARTED: l10n.t('Started'),
      WATER_TAPS_CLOSED: l10n.t('Water taps closed'),
      INTERLOCK_CHAFF_ASPIRATION_BURNER: l10n.t(
        'Interlock chaff aspiration burner'
      ),
      OUTLET_SCALE_IN_MANUAL: l10n.t('Outlet scale in manual'),
      AFTERBURNER_SWITCHED_ON: l10n.t('Afterburner switched on'),
      PLANT_AIR_HANDLING_DISTURBED: l10n.t('Plant air handling disturbed'),
      EMERGENCY_STOP_ACTIVATED: l10n.t('Emergency stop activated'),
      MODE_CHANGE_MAINTENANCE: l10n.t('Mode change maintenance'),
      PREHEATER_EMPTY: l10n.t('Preheater empty'),
      MODE_CHANGE_PROGRAM: l10n.t('Mode change program'),
      PREHEATING_COMPLETED_RECENTLY: l10n.t('Preheating completed recently'),
      CLOSED: l10n.t('Closed'),
      ROAST_COLOR_SENSOR_DISTURBED: l10n.t('Roast color sensor disturbed'),
      WEIGHT_EXCEEDED: l10n.t('Weight exceeded'),
      STANDBY_ACTIVATED: l10n.t('Standby activated'),
      MODE_CHANGE_OPERATOR: l10n.t('Mode change operator'),
      OVERVOLTAGE_PROTECTION_NOT_OK: l10n.t('Overvoltage protection not ok'),
      PID_MODE_LOCAL: l10n.t('Pid mode local'),
      PREHEATING_IN_PROGRESS: l10n.t('Preheating in progress'),
      INTERLOCK_BUCKET_ELEVATOR: l10n.t('Interlock bucket elevator'),
      QUENCHING_TIME_EXCEEDED: l10n.t('Quenching time exceeded'),
      LOW_ALARM: l10n.t('Low alarm'),
      COOLER_OUTLET_BIN_SELECTED: l10n.t('Cooler outlet bin selected'),
      FAIL_TO_CLOSE: l10n.t('Fail to close'),
      EMPTY: l10n.t('Empty'),
      FAIL_TO_OPEN: l10n.t('Fail to open'),
      THERMAL_CLEANING_REQUIRED: l10n.t('Thermal cleaning required'),
      BATCH_PROTOCOL_DISTURBED: l10n.t('Batch protocol disturbed'),
      ROAST_TIME_TOO_SHORT: l10n.t('Roast time too short'),
      RECIPE_CHANGE_DISTURBED: l10n.t('Recipe changed disturbed'),
      OUTLET_BIN_IN_MANUAL: l10n.t('Outlet bin in manual'),
      MAINBURNER_SWITCHED_ON: l10n.t('Mainburner switched on'),
      MEASURING_SYSTEM_NOT_OK: l10n.t('Measuring system not ok'),
      COMMUNICATION_DISTURBED: l10n.t('Communication disturbed'),
      POWER_SUPPLY_NOT_OK: l10n.t('Power supply not ok'),
      MOISTURE_NOT_OK: l10n.t('Moisture not ok'),
      STANDBY_PREPARED: l10n.t('Standby prepared'),
      OTHER: l10n.t('Other'),
    };

    this.labels = labels;
  }
}
