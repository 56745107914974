import Transition from '@ember/routing/-private/transition';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';

export default class TasksShowEditRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;

  afterModel(model: MaintenanceTask) {
    if (this.abilities.cannot('edit maintenance-task', model)) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to edit this task.")
      );
    }
  }

  deactivate(transition?: Transition) {
    this.refreshRouteIfMatches(transition, 'routes/maintenance.tasks.show');
  }
}
