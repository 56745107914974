import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { determineAfterCommaPlaces } from 'fabscale-app/utilities/utils/format-number';
import { DateTime } from 'luxon';
import ReferenceRoastBatchCell from 'fabscale-app/components/page/oee-kpi-report/per-recipe/table/cell/link-reference-roast-batch';

export type OeeKpiReportTableData = {
  label: string | DateTime;
  searchLabel: string;
  externalId?: string;
} & {
  [key in KpiType]?: ValueUnitPair;
};

export interface ValueUnitPair {
  value: number;
  unit: Unit;
}

function checkValueUnitPair(
  valueUnitPair: ValueUnitPair | undefined | string
): valueUnitPair is ValueUnitPair {
  return (
    typeof valueUnitPair === 'object' &&
    typeof valueUnitPair?.value === 'number'
  );
}

export default class OeeKpiReportTableColumnsHelper extends Helper<{
  PositionalArgs: [
    options: {
      data: OeeKpiReportTableData[];
      labelColumn: TableColumnDefinitionInput;
      referenceColumn?: TableColumnDefinitionInput;
    }
  ];
}> {
  @service enumLabels: EnumLabelsService;

  compute([{ data, labelColumn, kpiTypes, referenceColumn }]: [
    {
      data: OeeKpiReportTableData[];
      labelColumn: TableColumnDefinitionInput;
      kpiTypes: KpiType[];
      referenceColumn?: TableColumnDefinitionInput;
    }
  ]) {
    let { enumLabels } = this;

    if (data.length === 0) {
      return [];
    }
    if (referenceColumn) {
      referenceColumn = {
        ...referenceColumn,
        component: ReferenceRoastBatchCell,
      };
    }
    const dataColumns: TableColumnDefinitionInput[] = kpiTypes.map(
      (kpiType) => {
        const valueUnitPairs = data
          .map((rowData) => rowData[kpiType])
          .filter(checkValueUnitPair);

        if (valueUnitPairs.length === 0) {
          return {
            title: enumLabels.kpiType(kpiType),
            propertyName: `${kpiType}.value`,
          };
        }

        const allValues = valueUnitPairs.map(({ value }) => value);
        const maxValue = Math.max(...allValues);
        let afterComma = Math.max(determineAfterCommaPlaces(maxValue), 2);

        // Special case: All numbers have no decimal places (e.g. # of batches)
        if (!allValues.some((value) => !Number.isInteger(value))) {
          afterComma = 0;
        }

        const { unit } = valueUnitPairs[0]!;

        return {
          title: enumLabels.kpiType(kpiType),
          propertyName: `${kpiType}.value`,
          cellType: 'AMOUNT',
          cellData: {
            unit,
            // Use at least 2 decimal places here, otherwise let it be auto-determined
            afterComma,
            afterCommaExact: true,
          },
        };
      }
    );

    const idColumns = referenceColumn
      ? [referenceColumn, labelColumn]
      : [labelColumn];

    return [...idColumns, ...dataColumns];
  }
}
