import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Unit } from 'fabscale-app/models/enums/units';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

export default class FormatUnitHelper extends Helper<{
  PositionalArgs: [unit: Unit, opt?: { standalone?: boolean }];
}> {
  @service enumLabels: EnumLabelsService;

  compute([unit, { standalone = false } = {}]: [
    Unit,
    { standalone?: boolean }
  ]) {
    let unitString = this.enumLabels.unit(unit) || '#';

    if (standalone) {
      return `[${unitString}]`;
    }

    return unitString;
  }
}
