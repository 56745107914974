import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { DateRange } from 'fabscale-app/models/date-range';
import StorePlantAssetInfoService, {
  PlantAssetInfoSummaryGrouped,
} from 'fabscale-app/services/store/plant-asset-info';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetInfoSummariesGroupedOptions {
  dateRange: DateRange;
  plantAssetIds: string[];
  plantAssetStatus: PlantAssetStatus[];
  groupByPlantAsset: boolean;
}

export default class LoadPlantAssetinfoSummariesGroupedResource extends BaseAsyncResource<
  PlantAssetInfoSummaryGrouped[],
  LoadPlantAssetInfoSummariesGroupedOptions
> {
  @service('store/plant-asset-info')
  plantAssetInfoStore: StorePlantAssetInfoService;

  async loadData({
    dateRange,
    groupByPlantAsset,
    plantAssetIds,
    plantAssetStatus,
  }: LoadPlantAssetInfoSummariesGroupedOptions) {
    let { plantAssetInfoStore } = this;

    assert(
      '{{resource/load-plant-asset-info-summaries-grouped}}: You have to pass groupByPlantAsset',
      typeof groupByPlantAsset === 'boolean'
    );

    let fetchOptions = {
      dateRange,
      plantAssetStatus,
      plantAssetIds,
      groupByPlantAsset,
    };

    return plantAssetInfoStore.getSummariesGrouped(fetchOptions);
  }
}
