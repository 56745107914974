import { action } from '@ember/object';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { DataTableSortDataOptions } from '../sort-container';

interface Args {
  data: any[];
  pageSize?: number;
  sortOptions: DataTableSortDataOptions;
  updateSort: (sortBy: string) => void;
  columns: TableColumnDefinitionInput[];
  tableData: any;
  showStripes?: boolean;
  emptyMessage?: string;
}

export default class UiDataTablePaginatedInner extends Component<Args> {
  @tracked currentPage = 1;
  @tracked isPrinting = false;
  defaultPageSize = 25;
  tableElement: HTMLElement;
  @tracked _pageSize: number;

  get pageSize() {
    if (this.isPrinting) {
      return this.totalItems;
    }

    return this._pageSize || this.args.pageSize || this.defaultPageSize;
  }

  get totalPages() {
    let { pageSize, totalItems } = this;

    return Math.ceil(totalItems / pageSize);
  }

  get dataOffsetStart() {
    let { currentPage, pageSize } = this;

    return (currentPage - 1) * pageSize;
  }

  get dataOffsetEnd() {
    let { currentPage, pageSize, totalItems } = this;

    return Math.min(currentPage * pageSize, totalItems);
  }

  get totalItems() {
    return this.args.data.length;
  }

  get paginatedData() {
    let { data } = this.args;
    let { dataOffsetStart, dataOffsetEnd } = this;

    return data.slice(dataOffsetStart, dataOffsetEnd);
  }

  get extendedSortOptions() {
    return Object.assign({}, this.args.sortOptions, {
      updateSort: (sortBy: string) => this._updateSort(sortBy),
    });
  }

  @action
  updatePageSize(pageSize: number) {
    this._pageSize = pageSize;
    this._scrollToTableTopTask.perform();
  }

  @action
  movePrevious() {
    this.currentPage--;
    this._scrollToTableTopTask.perform();
  }

  @action
  moveNext() {
    this.currentPage++;
    this._scrollToTableTopTask.perform();
  }

  @action
  moveTo(currentPage: number) {
    this.currentPage = currentPage;
    this._scrollToTableTopTask.perform();
  }

  @action
  onTogglePrinting({ isPrinting }: { isPrinting: boolean }) {
    this.isPrinting = isPrinting;
    this.currentPage = 1;
  }

  @action
  registerTableElement(element: HTMLElement) {
    this.tableElement = element;
  }

  _scrollToTableTopTask = task(async () => {
    await timeout(1);

    if (!this.tableElement) {
      return;
    }

    if (macroCondition(!isTesting())) {
      this.tableElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  });

  _updateSort(sortBy: string) {
    this.args.sortOptions.updateSort(sortBy);

    this.currentPage = 1;
  }
}
