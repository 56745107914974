import { assert } from '@ember/debug';
import { guidFor } from '@ember/object/internals';
import { compare, isNone } from '@ember/utils';
import {
  BarChartDataInput,
  HorizontalBarChartData,
} from 'fabscale-app/components/chart';
import { uniq } from 'fabscale-app/utilities/utils/array';

export interface HorizontalBarChartConfig {
  color?: string;
  percentageMode?: boolean;
}

export default function chartBarChartData(
  data: BarChartDataInput[],
  config?: HorizontalBarChartConfig
) {
  let parsedData = parseData(data, config);

  let values = getValues(parsedData);
  let labels = getLabels(parsedData);

  return {
    parsedData,
    values,
    labels,
  };
}

function parseData(
  data: BarChartDataInput[],
  config?: HorizontalBarChartConfig
): HorizontalBarChartData[] {
  assert(
    'HorizontalBarChart: data must be an array of objects',
    Array.isArray(data) && !data.find((item) => typeof item !== 'object')
  );

  assert(
    `HorizontalBarChart: data must have at least one item, but has only ${data.length}`,
    data.length >= 1
  );

  assert(
    'HorizontalBarChart: all data items must have the following properties: value, label',
    !data.find((item) => isNone(item.value) || isNone(item.label))
  );

  let maxValue = getMaxValue(data, config);

  return data.map((item) => {
    let percentage = (item.value / maxValue) * 100;

    return Object.assign(
      {
        color: config?.color,
        itemGuid: guidFor(item),
        percentage,
      },
      item
    );
  });
}

function getValues(data: HorizontalBarChartData[]) {
  return data.map((item) => item.value);
}

function getLabels(data: HorizontalBarChartData[]) {
  let uniqueLabels = uniq(data.map((item) => item.label));
  return uniqueLabels.sort(compare);
}

function getMaxValue(
  data: BarChartDataInput[],
  config?: HorizontalBarChartConfig
) {
  let values = data.map((item) => item.value);

  if (config?.percentageMode) {
    return values.reduce((total, value) => total + value, 0);
  }

  return Math.max(...values);
}
