import { BooleanRoastBatchParameterTypes } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  BooleanRoastBatchParameter,
  RoastBatch,
} from 'fabscale-app/models/roast-batch';

export default function getBooleanRoastBatchParameters(roastBatch: RoastBatch) {
  let parameters = roastBatch.booleanRoastBatchParameters;
  return BooleanRoastBatchParameterTypes.map((type) =>
    parameters.find((parameter) => parameter.parameterType === type)
  ).filter(Boolean) as BooleanRoastBatchParameter[];
}
