import { assert } from '@ember/debug';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { DateTime } from 'luxon';

export function getWeekStart(
  date: DateTime,
  { startDayOfWeek }: { startDayOfWeek?: DayOfWeek } = {}
): DateTime {
  assert(`getWeekStart: You have to specify startDayOfWeek`, !!startDayOfWeek);

  if (startDayOfWeek === 'SUNDAY') {
    return date.weekday === 7
      ? date.startOf('day')
      : date.set({ weekday: 1 }).minus({ days: 1 }).startOf('day');
  }

  // Default: monday
  return date.set({ weekday: 1 }).startOf('day');
}

export function getWeekEnd(
  date: DateTime,
  { startDayOfWeek }: { startDayOfWeek?: DayOfWeek } = {}
): DateTime {
  assert(`getWeekEnd: You have to specify startDayOfWeek`, !!startDayOfWeek);

  let weekStart = getWeekStart(date, { startDayOfWeek });

  return weekStart.plus({ weeks: 1 }).minus({ days: 1 }).startOf('day');
}
