import api from "!../../../../../../home/runner/work/fabscale-app/fabscale-app/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../home/runner/work/fabscale-app/fabscale-app/node_modules/css-loader/dist/cjs.js!../../../../../../home/runner/work/fabscale-app/fabscale-app/node_modules/sass-loader/dist/cjs.js!./task-calendar.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};