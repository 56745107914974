import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import { timeout } from 'ember-concurrency';
import { Unit } from 'fabscale-app/models/enums/units';
import { DateTime } from 'luxon';
import EnumLabelsService from './enum-labels';

// We use a manual interface here, as lazy loading modules & type imports don't play well together...
// See: https://github.com/ef4/ember-auto-import/issues/169
interface Workbook {
  xlsx: any;
  addWorksheet(name: string): any;
}

export interface ExcelSheetConfig {
  sheetName: string;
  columns: ExcelSheetColumn[];
  rows: any[];
  autoFilter?: boolean;
}

interface ExcelSheetColumn {
  header: string;
  id: string;
}

export class ExcelAmountCell {
  amount: number;
  unit: Unit;

  constructor({ amount, unit }: { amount: number; unit: Unit }) {
    this.amount = amount;
    this.unit = unit;
  }
}

export default class ExcelService extends Service {
  @service enumLabels: EnumLabelsService;

  async create(
    sheetConfig: ExcelSheetConfig | ExcelSheetConfig[],
    fileName?: string
  ) {
    let workbook = await this.createWorkbook();

    if (Array.isArray(sheetConfig)) {
      sheetConfig.forEach((sheetConfig) => {
        this.createWorksheet(workbook, sheetConfig);
      });
    } else {
      this.createWorksheet(workbook, sheetConfig);
    }

    let actualFileName =
      fileName || `fabscale-export-${DateTime.local().toISODate()}.xlsx`;
    await this._downloadWorkbook(workbook, actualFileName);

    return workbook;
  }

  async _downloadWorkbook(workbook: Workbook, fileName: string) {
    let buffer = await workbook.xlsx.writeBuffer();
    let blob = new Blob([buffer]);

    if (macroCondition(isTesting())) {
      return;
    }

    await this._triggerDownloadBlob(blob, fileName);
  }

  async createWorkbook() {
    let Workbook = await this._loadAndGetWorkbookClass();

    let workbook = new Workbook();
    workbook.creator = 'Fabscale';
    workbook.created = new Date();
    workbook.modified = new Date();

    return workbook;
  }

  createWorksheet(workbook: Workbook, sheetConfig: ExcelSheetConfig) {
    let { sheetName, columns, rows, autoFilter } = sheetConfig;
    let { enumLabels } = this;

    let worksheet = workbook.addWorksheet(sheetName);

    if (autoFilter) {
      let lastCell = getExcelCellIdentifier(columns.length, rows.length + 1);
      worksheet.autoFilter = `A1:${lastCell}`;
    }

    // HEADERS
    worksheet.columns = columns.map((column) => {
      return { header: column.header, key: column.id, font: { name: 'Arial' } };
    });

    // Make header row bold
    worksheet.getRow(1).font = { name: 'Arial', bold: true };

    // Store a list of cells that should have a special number format
    // We need to set this after adding the data, to prevent rows from being messed up
    let numberFormatList: { row: number; prop: string; numFmt: string }[] = [];

    // ROWS
    rows.forEach((row, i) => {
      assert(
        `Excel: rows should only have fields that are defined as columns, but row ${i} has "${Object.keys(
          row
        ).find(
          (propertyName) =>
            !columns.find((column) => column.id === propertyName)
        )}"`,
        !Object.keys(row).find(
          (propertyName) =>
            !columns.find((column) => column.id === propertyName)
        )
      );

      // Transform dates to JS date, and adjust timezone to UTC (which is what excel expects...)
      let rowData = Object.assign({}, row);
      Object.keys(rowData).forEach((prop) => {
        let value = rowData[prop];
        if (value && value instanceof DateTime) {
          rowData[prop] = value
            .setZone('utc', { keepLocalTime: true })
            .toJSDate();
        }

        if (value && value instanceof ExcelAmountCell) {
          rowData[prop] = value.amount;

          let unitLabel = enumLabels.unit(value.unit);

          if (unitLabel) {
            numberFormatList.push({
              prop,
              row: i + 2,
              numFmt: `0.00 "${unitLabel}"`,
            });
          }
        }
      });

      worksheet.addRow(rowData);
    });

    numberFormatList.forEach((numFormat) => {
      let worksheetRow = worksheet.getRow(numFormat.row);
      worksheetRow.getCell(numFormat.prop).numFmt = numFormat.numFmt;
    });

    return worksheet;
  }

  async _triggerDownloadBlob(blob: Blob, fileName: string) {
    let url = window.URL.createObjectURL(blob);

    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();

    // Wait, in order for everything to settle
    // In some edge cases, e.g. for very large files, it could otherwise break
    await timeout(1);

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  async _loadAndGetWorkbookClass() {
    // This is lazy-imported via ember-auto-import
    // This will only load the bundle if required, making the main bundle smaller
    let { Workbook } = await import('exceljs');
    return Workbook;
  }
}

export function mapNumberToExcelColumnName(num: number) {
  let charMap = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  assert(`Number 0 is not a valid Excel column - start with 1!`, num > 0);

  let charCount = charMap.length;

  // 1 = A
  // 2 = B
  // ....
  // 26 = Z
  // 27 = AA
  // 28 = AB
  // ...

  let colName = '';
  let dividend = Math.floor(Math.abs(num));
  let rest;

  while (dividend > 0) {
    rest = (dividend - 1) % charCount;
    colName = charMap[rest] + colName;
    dividend = Math.floor((dividend - rest) / charCount);
  }

  return colName;
}

function getExcelCellIdentifier(x: number, y: number) {
  // E.g. x=1, y=1 --> A1
  let columnName = mapNumberToExcelColumnName(x);
  let row = y;

  return `${columnName}${row}`;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    excel: ExcelService;
  }
}
