import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import MaintenanceTaskManagerService from 'fabscale-app/services/maintenance-task-manager';

export default class TasksRoute extends AuthenticatedRoute {
  @service abilities: AbilitiesService;
  @service l10n: L10nService;
  @service maintenanceTaskManager: MaintenanceTaskManagerService;

  afterModel() {
    if (this.abilities.cannot('view maintenance-task')) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to view maintenance tasks.")
      );
    }
  }

  resetController() {
    this.maintenanceTaskManager.previousRouteInfo = undefined;
  }
}
