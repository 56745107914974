import { assert } from '@ember/debug';

export default function mathRound(a: number) {
  assert(
    `{{math-round}} expects number as argument, but got ${a}`,
    typeof a === 'number'
  );

  return Math.round(a);
}
