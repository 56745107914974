import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { AbilitiesService } from 'ember-can';
import CellDate from 'fabscale-app/components/page/plant-asset-status-map/cells/date';
import CellReason from 'fabscale-app/components/page/plant-asset-status-map/cells/reason';
import CellStatus from 'fabscale-app/components/page/plant-asset-status-map/cells/status';
import CellComment from 'fabscale-app/components/page/plant-asset-status-map/cells/comment';
import CellActions from 'fabscale-app/components/page/plant-asset-status-map/cells/actions';
import CellAlarm from 'fabscale-app/components/page/plant-asset-status-map/cells/alarm';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import {
  TableColumnDefinition,
  TableColumnDefinitionInput,
} from 'fabscale-app/models/table-column-definition';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { TableGetRowInfoHandler } from 'fabscale-app/helpers/data-table/get-row-info';
import { TableGetColumnInfoHandler } from 'fabscale-app/helpers/data-table/get-column-info';
import { DateRange } from 'fabscale-app/models/date-range';

interface Args {
  date: DateTime;
  statusInfos: PlantAssetStateInfo[];
}

type StatusInfoOrEditRow =
  | (PlantAssetStateInfo & { dateRange?: DateRange })
  | {
      edit: boolean;
      statusInfo: PlantAssetStateInfo & { dateRange?: DateRange };
    };

export default class PlantPlantAssetStatusMapTable extends Component<Args> {
  @service l10n: L10nService;
  @service abilities: AbilitiesService;

  @tracked editId?: string;

  // We keep a list of infos we updated here,
  // to avoid needing to re-fetch the whole page (showing a loading spinner etc.)
  @tracked updatedStatusInfos: PlantAssetStateInfo[] = [];

  columns: TableColumnDefinitionInput[];

  @cached
  get rowData(): StatusInfoOrEditRow[] {
    let statusInfos: (PlantAssetStateInfo & { dateRange?: DateRange })[] =
      this.args.statusInfos.slice();
    let { updatedStatusInfos, editId } = this;

    statusInfos.forEach((statusInfo: any) => {
      const start = statusInfo.startDate;
      const end = statusInfo.endDate;
      statusInfo.dateRange = new DateRange({ start, end });
    });

    // "Fix" roasts that have just been updated in-place
    updatedStatusInfos.forEach((statusInfo) => {
      let existingPos = statusInfos.findIndex(
        (existing) => existing.id === statusInfo.id
      );

      if (existingPos > -1) {
        statusInfos.splice(existingPos, 1, statusInfo);
      }
    });

    // Add an "edit" row below a status info, if editId is set
    if (!editId) {
      return statusInfos;
    }

    let rowData: StatusInfoOrEditRow[] = statusInfos;

    let statusInfo:
      | (PlantAssetStateInfo & {
          dateRange?: DateRange;
        })
      | undefined = statusInfos.find(
      (
        statusInfo: PlantAssetStateInfo & {
          dateRange?: DateRange;
        }
      ) => statusInfo.id === editId
    );

    if (statusInfo) {
      let pos: number = rowData.indexOf(statusInfo);

      let editRow: StatusInfoOrEditRow = {
        edit: true,
        statusInfo,
      };

      rowData.splice(pos + 1, 0, editRow);
    }

    return rowData;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;
    let columns: TableColumnDefinitionInput[] = [
      {
        title: l10n.t('Start'),
        propertyName: 'startDate',
        component: CellDate,
        tdClass: 'nowrap',
        disableSorting: true,
      },
      {
        title: l10n.t('End'),
        propertyName: 'endDate',
        component: CellDate,
        tdClass: 'nowrap',
        disableSorting: true,
      },
      {
        title: l10n.t('Alarm'),
        propertyName: 'dateRange',
        component: CellAlarm,
        tdClass: 'nowrap',
        disableSorting: true,
      },
      {
        title: l10n.t('Status'),
        propertyName: 'status',
        component: CellStatus,
        disableSorting: true,
      },
      {
        title: l10n.t('Reason'),
        propertyName: 'reason',
        component: CellReason,
        disableSorting: true,
        tdClass: 'min-width-4',
      },
      {
        title: l10n.t('Comment'),
        propertyName: 'comment',
        component: CellComment,
        disableSorting: true,
        tdClass: 'min-width-4',
      },
    ];

    if (this.abilities.can('edit plant-asset-info')) {
      columns.push({
        title: '',
        propertyName: '',
        component: CellActions,
        tdClass: 'nowrap',
        disableSorting: true,
      });
    }

    this.columns = columns;
  }

  @cached
  get getRowInfoHandler(): TableGetRowInfoHandler | undefined {
    let { rowData, editId } = this;

    if (editId) {
      return (record: StatusInfoOrEditRow) => {
        let index = rowData.indexOf(record);

        let classes = ['transition-common'];

        if (index % 2) {
          classes.push('table__tr--striped');
        }

        if (!('edit' in record) && record.id !== editId) {
          classes.push('opacity-30');
        } else {
          classes.push('table__tr--striped');
        }

        return { class: classes.join(' ') };
      };
    }

    return (record: StatusInfoOrEditRow) => {
      let index = rowData.indexOf(record);

      let classes = ['transition-common'];

      if (index % 2) {
        classes.push('table__tr--striped');
      }

      return { class: classes.join(' ') };
    };
  }

  @cached
  get getColumnInfoHandler(): TableGetColumnInfoHandler | undefined {
    let { editId } = this;

    if (!editId) {
      return undefined;
    }

    let columnCount = this.columns.length;

    return (column: TableColumnDefinition, record: StatusInfoOrEditRow) => {
      if (!('edit' in record)) {
        return { colspan: undefined, showColumn: true };
      }

      // Editing this record - only show action column
      return column.propertyName === ''
        ? { colspan: columnCount, showColumn: true }
        : { showColumn: false };
    };
  }

  @action
  toggleEditRow(statusInfo?: PlantAssetStateInfo) {
    this.editId = statusInfo?.id;
  }

  @action
  onEditRowSuccess(updatedStatusInfo: PlantAssetStateInfo) {
    let updatedStatusInfos = this.updatedStatusInfos.slice();
    updatedStatusInfos.push(updatedStatusInfo);
    this.updatedStatusInfos = updatedStatusInfos;

    this.editId = undefined;
  }
}
