import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/-private/transition';
import { service } from '@ember/service';
import { MaintenanceError } from 'fabscale-app/models/errors/maintenance-error';
import SettingsService from 'fabscale-app/services/settings';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import UserSessionService from 'fabscale-app/services/user-session';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';
import { CognitoNotAuthenticatedError } from 'fabscale-app/models/errors/cognito';

export default class LoginSuccessfulRoute extends Route {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service settings: SettingsService;
  @service userAuthentication: UserAuthenticationService;

  async beforeModel() {
    let { l10n, userSession, userAuthentication } = this;

    try {
      await userSession.setupUserSession();
    } catch (error) {
      if (!(error instanceof CognitoNotAuthenticatedError)) {
        sentryCaptureException(error);
      }

      let errorMessage = l10n.t(
        'An error occurred when trying to load your user. Please login again.'
      );
      if (error instanceof MaintenanceError) {
        errorMessage = l10n.t(
          'We are currently conducting maintenance on our server. We will be back soon!'
        );
      }

      userAuthentication.logout(errorMessage);
      return;
    }

    await this.settings.loadSettings();
  }

  model() {
    return this.userAuthentication.afterLoginMessage;
  }

  async afterModel(loginMessage?: string) {
    if (loginMessage) {
      return;
    }

    this.userAuthentication.redirectAfterLogin();
  }

  deactivate(transition: Transition) {
    super.deactivate(transition);

    this.userAuthentication.afterLoginMessage = undefined;
  }
}
