import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import continuousDataQuery from 'fabscale-app/gql/queries/plant-asset-monitoring.graphql';
import monitoringSensorsQuery from 'fabscale-app/gql/queries/plant-asset-monitoring-sensors.graphql';
import GraphQLService from '../-graphql';
import EnumLabelsService from '../enum-labels';
import SettingsService from '../settings';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateRange } from 'fabscale-app/models/date-range';
import { ContinuousDataType } from 'fabscale-app/models/enums/plant-asset-monitoring';
import {
  MonitoringSensor,
  MonitoringSensorsFilters,
  MonitoringSensorsPayload,
} from 'fabscale-app/models/plant-asset-monitoring';
import {
  getPlantAssetMonitoringSensorColors,
  setPlantAssetMonitoringSensorColors,
} from 'fabscale-app/utilities/utils/local-storage';

export default class StorePlantAssetMonitoringService extends Service {
  @service l10n: L10nService;
  @service graphql: GraphQLService;
  @service enumLabels: EnumLabelsService;
  @service settings: SettingsService;

  private allMonitoringSensorsByPlantAsset: MonitoringSensor[] = [];

  setSensorColors(sensorColors: { [key: string]: string }) {
    setPlantAssetMonitoringSensorColors(sensorColors);
  }

  getSensorColors() {
    return getPlantAssetMonitoringSensorColors();
  }

  setAllMonitoringSensorsByPlantAsset(sensorNames: MonitoringSensor[]): void {
    this.allMonitoringSensorsByPlantAsset = sensorNames;
  }

  getAllMonitoringSensorByPlantAsset(): MonitoringSensor[] {
    return this.allMonitoringSensorsByPlantAsset;
  }

  async findAll(
    dateRange: DateRange,
    plantAssetId: string,
    sensorCategories: ContinuousDataType[],
    selectedSensors: string[]
  ): Promise<any> {
    const { graphql } = this;

    const variables = {
      filters: {
        dateFrom: serializeDate(dateRange.start),
        dateTo: serializeDate(dateRange.end),
        plantAssetIds: plantAssetId,
        sensorTypes: sensorCategories,
        sensorNames: selectedSensors,
      },
    };

    const response: any = await graphql.query({
      query: continuousDataQuery,
      variables,
      namespace: 'getContinuousData',
    });

    return response;
  }

  async getMonitoringSensors(
    filters: MonitoringSensorsFilters
  ): Promise<MonitoringSensorsPayload> {
    const { graphql } = this;
    const { locationId, dateFrom, dateTo } = filters;

    const variables = {
      filters: {
        locationId: locationId,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    };

    const response: MonitoringSensorsPayload = await graphql.query({
      query: monitoringSensorsQuery,
      variables,
      namespace: 'getMonitoringSensors',
    });

    return response;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/plant-asset-monitoring': StorePlantAssetMonitoringService;
  }
}
