import { DateTimeString } from 'fabscale-app';
import { PlantAssetType } from 'fabscale-app/models/enums/plant-asset-types';
import { RecipeIdentificationStrategy } from 'fabscale-app/models/enums/recipe-identification-strategies';
import { PlantAssetArea } from './plant-asset-area';
import { PlantAssetModel } from './plant-asset-model';

export class PlantAsset {
  id: string;
  name: string;
  type?: PlantAssetType;
  model?: PlantAssetModel;
  year?: string;
  referenceNumber?: string;
  displayColor?: string;
  position?: string;
  idPerLocation?: string;
  recipeIdentificationStrategy?: RecipeIdentificationStrategy;
  areas?: PlantAssetArea[];

  constructor(options: PlantAssetInput) {
    this.id = options.id;
    this.name = options.name;
    this.type = options.type;
    this.model = options.model;
    this.year = options.year;
    this.referenceNumber = options.referenceNumber;
    this.displayColor = options.displayColor;
    this.position = options.position;
    this.idPerLocation = options.idPerLocation;
    this.recipeIdentificationStrategy = options.recipeIdentificationStrategy;
    this.areas = options.areas;
  }
}

export interface PlantAssetInput {
  id: string;
  name: string;
  type?: PlantAssetType;
  model?: PlantAssetModel;
  year?: string;
  referenceNumber?: string;
  displayColor?: string;
  position?: string;
  idPerLocation?: string;
  recipeIdentificationStrategy?: RecipeIdentificationStrategy;
  areas?: PlantAssetArea[];
}

export interface PlantAssetFilters {
  locationId?: string;
  search?: string;
  type?: PlantAssetType;
  used?: {
    dateFrom: DateTimeString;
    dateTo: DateTimeString;
    plantAssetIds?: string[];
    recipeIds?: string[];
  };
  hasStatusInfo?: boolean;
}
