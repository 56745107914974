import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { assert } from '@ember/debug';
import ApiStatusService from 'fabscale-app/services/api-status';

const PROP_NAMES = ['apiVersion', 'hasApiError'] as const;
type PropName = typeof PROP_NAMES[number];

export default class ApiStatusHelper extends Helper<{
  PositionalArgs: [propName: PropName];
}> {
  @service apiStatus: ApiStatusService;

  compute([propName]: [PropName]) {
    assert(
      `{{api-status}} accepts "apiVersion" or "hasApiError" as first argument (got: "${propName}")`,
      PROP_NAMES.includes(propName)
    );

    return this.apiStatus[propName];
  }
}
