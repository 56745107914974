import { schedule } from '@ember/runloop';
import { AuthenticatedRoute } from 'fabscale-app/routes/base/-base-authenticated-route';
import KpiDetailsController from './controller';

export default class ReportsKpiDetailsRoute extends AuthenticatedRoute {
  resetController(controller: KpiDetailsController, isExiting: boolean) {
    if (isExiting) {
      // Without the schedule part here, it leads to weird issues
      // with the arguments changing for components right before they are destroyed
      // This could be seen e.g. when setting filters, then going to the dashboard
      schedule('afterRender', () => {
        controller.resetFilters();
      });
    }
  }
}
