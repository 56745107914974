import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class VerticalNavNested extends Component<{
  Args: { route: string };
}> {
  @service router: RouterService;

  get routeIsActive() {
    return this.router.currentRouteName.startsWith(this.args.route);
  }

  get showSubNav() {
    return this.routeIsActive;
  }
}
