import { DateTimeString } from 'fabscale-app';
import { deserializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { UserInfo } from './user-info';

export class Comment {
  id: string;
  message: string;

  // Dates
  creationDate?: DateTime;
  lastModifiedDate?: DateTime;

  // Relationships
  createdBy: UserInfo;
  lastModifiedBy?: UserInfo;

  constructor(options: CommentInput) {
    this.id = options.id;
    this.message = options.message;

    this.creationDate = deserializeOptionalDate(options.creationDate);
    this.lastModifiedDate = deserializeOptionalDate(options.lastModifiedDate);

    this.createdBy = options.createdBy;
    this.lastModifiedBy = options.lastModifiedBy;

    Object.freeze(this);
  }
}

export interface CommentInput {
  id: string;
  message: string;

  // Dates
  creationDate: DateTimeString;
  lastModifiedDate?: DateTimeString;

  // Relationships
  createdBy: UserInfo;
  lastModifiedBy?: UserInfo;
}
