import { service } from '@ember/service';
import { PageDef, SortDef } from 'fabscale-app';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  ParameterGoalSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import StoreParameterGoalService, {
  PaginatedParameterGoals,
} from 'fabscale-app/services/store/parameter-goal';
import BaseAsyncResource from './base-async-resource';

export interface LoadParameterGoalsOptions {
  search?: string;
  parameterType?: NumericRoastBatchParameterType;
  // Pagination-related
  page: number;
  pageSize: number;
  sortBy: ParameterGoalSortOption;
  sortDirection: SortDirection;
}

export default class LoadRoastBatchesResource extends BaseAsyncResource<
  PaginatedParameterGoals,
  LoadParameterGoalsOptions
> {
  @service('store/parameter-goal')
  parameterGoalStore: StoreParameterGoalService;

  loadData(options: LoadParameterGoalsOptions) {
    let { page, pageSize, sortBy, sortDirection, search, parameterType } =
      options;

    let pageDef: PageDef = {
      page,
      pageSize,
    };

    let sortDef: SortDef = {
      sortBy,
      sortDirection,
    };

    let filters = {
      search,
      parameterType,
    };

    return this.parameterGoalStore.queryPaginated(filters, pageDef, sortDef);
  }
}
