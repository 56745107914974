export enum ContinuousDataType {
  GAS_CONSUMPTION_MAINBURNER = 'GAS_CONSUMPTION_MAINBURNER',
  GAS_CONSUMPTION_AFTERBURNER = 'GAS_CONSUMPTION_AFTERBURNER',
  GAS_CONSUMPTION = 'GAS_CONSUMPTION',
  POWER_CONSUMPTION = 'POWER_CONSUMPTION',
  WATER_CONSUMPTION = 'WATER_CONSUMPTION',
  AIR_CONSUMPTION = 'AIR_CONSUMPTION',
  BATCH_COUNTER = 'BATCH_COUNTER',
  BATCH_COUNTER_SINCE_MAINTENANCE = 'BATCH_COUNTER_SINCE_MAINTENANCE',
  OPERATING_HOURS = 'OPERATING_HOURS',
  OPERATING_HOURS_SINCE_MAINTENENANCE = 'OPERATING_HOURS_SINCE_MAINTENENANCE',
  MOTOR_STARTS = 'MOTOR_STARTS',
  MOTOR_RUNTIME_SINCE_LAST_START = 'MOTOR_RUNTIME_SINCE_LAST_START',
  MOTOR_TOTAL_RUNTIME = 'MOTOR_TOTAL_RUNTIME',
  MOTOR_MAX_CONTINUOUS_RUNTIME = 'MOTOR_MAX_CONTINUOUS_RUNTIME',
  PROCESS_VALUE = 'PROCESS_VALUE',
  SETPOINT_VALUE = 'SETPOINT_VALUE',
  PRODUCT_STATUS = 'PRODUCT_STATUS',
  STATE = 'STATE',
  MOTOR_CURRENT = 'MOTOR_CURRENT',
  MOTOR_POWER = 'MOTOR_POWER',
  MOTOR_TEMPERATURE = 'MOTOR_TEMPERATURE',
}
