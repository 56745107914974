import { registerDeprecationHandler } from '@ember/debug';
import { isDevelopingApp, macroCondition } from '@embroider/macros';

export function initialize(): void {
  if (macroCondition(isDevelopingApp())) {
    handleDeprecationWarnings();
  }
}

export default {
  initialize,
};

function handleDeprecationWarnings() {
  let silencedDeprecationIds: string[] = ['ember-polyfills.deprecate-assign'];

  registerDeprecationHandler((message, options, next) => {
    if (options && silencedDeprecationIds.includes(options.id)) {
      return;
    }

    next(message, options);
  });
}
