import { assert } from '@ember/debug';

export default function inArray(array: any[], value: any) {
  assert(
    `You need to pass an array and a value to {{in-array}}, but you passed "${array}" and "${value}"`,
    Array.isArray(array) !== Array.isArray(value)
  );

  // Swap them if the order of arguments is wrong - we take care of this common error case
  if (Array.isArray(value)) {
    let _ = array;
    array = value;
    value = _;
  }

  return array.includes(value);
}
