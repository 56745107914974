import { ChartConfiguration, ChartData, Plugin, ChartOptions } from 'chart.js';

export class StandardDoughtnutChart {
  private _config: ChartConfiguration;

  constructor(data: ChartData, options?: ChartOptions, plugins?: Plugin[]) {
    this._config = {
      type: 'doughnut',
      data: data,
      options: options,
      plugins: plugins,
    };
  }

  get config() {
    return this._config;
  }
}
