import ApplicationInstance from '@ember/application/instance';
import { getOwnConfig, isTesting } from '@embroider/macros';
import { Locale } from 'fabscale-app/models/enums/locales';
import CognitoService from 'fabscale-app/services/cognito';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import { mockCognitoData } from 'fabscale-app/utilities/utils/cognito-mock/cognito-data';
import { LOCATION_ID } from 'fabscale-app/utils/mock-graphql/mock-db-constants';

export function generateMockUser(
  owner: ApplicationInstance,
  mockUser?: { email: string; locale: Locale }
) {
  const userAuthentication = owner.lookup(
    'service:user-authentication'
  ) as UserAuthenticationService;

  if (getOwnConfig<any>().isSimulation && !isTesting()) {
    const cognitoData = mockCognitoData();
    const locationId = LOCATION_ID;
    const locale = mockUser?.locale ?? 'en';
    const email =
      mockUser?.email ?? (getOwnConfig<any>().mockUsername as string);

    userAuthentication.saveCurrentUserData({ email, locationId, locale });
    if (cognitoData) {
      const cognito = owner.lookup('service:cognito') as CognitoService;
      cognito.cognitoData = cognitoData;
    }
  }
}
