import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';

// TODO FN: Make this work over multiple runs...
const map = new Map();

export default function getColorForPlantAsset(plantAsset: PlantAsset) {
  return getColor(plantAsset);
}

function getColor(plantAsset: PlantAsset) {
  let { id, displayColor } = plantAsset;

  if (displayColor) {
    return displayColor;
  }

  if (map.get(id)) {
    return map.get(id);
  }

  let colors = chartColors.slice().reverse();
  let color = colors[map.size];
  map.set(id, color);

  return color;
}

// This is just for tests
export function resetColorsForPlantAssets() {
  map.clear();
}
