import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DashboardCardConfigOeeKpi } from 'fabscale-app/models/dashboard-config';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import Dashboard from 'fabscale-app/services/dashboard/service';
import SettingsService from 'fabscale-app/services/settings';
import { getDashboardDateOptionDateRange } from 'fabscale-app/utils/mock-graphql/dashboard-date-option';
import { oeekpiTypes } from 'fabscale-app/utilities/utils/oee-cards';
import { DateTime } from 'luxon';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';

interface Args<T> {
  id: number;
  config: T;
  updateConfig: (config: DashboardCardConfigOeeKpi) => void;
  validateConfig: (config: DashboardCardConfigOeeKpi) => boolean;
}
export default class PageDashboardCustomizeOeeKpiConfigOptions<
  T extends DashboardCardConfigOeeKpi
> extends Component<{
  Args: Args<T>;
}> {
  @service('services/dashboard') dashboardService: Dashboard;
  @service settings: SettingsService;
  @service l10n: L10nService;

  @tracked now = DateTime.local();

  private configAlreadyInit() {
    const { config } = this.args;
    return config?.heading && config?.availablePlantAssets;
  }

  private isSmallCard(cardType: string) {
    return cardType === 'OEE_KPI';
  }

  private getHeading(cardType: string, plantAsset?: PlantAsset) {
    if (this.isSmallCard(cardType)) {
      return plantAsset ? `OEE for ${plantAsset.name}` : 'OEE for All roasters';
    }
    return 'Overall Equipment Effectiveness (OEE)';
  }

  private getConfigHeading(cardType: string) {
    return this.isSmallCard(cardType)
      ? this.l10n.t('OEE')
      : this.l10n.t('Overall Equipment Effectiveness (OEE)');
  }

  get hasError() {
    return !this.args.validateConfig(this.args.config);
  }

  get dateRange() {
    return getDashboardDateOptionDateRange(
      this.dashboardService.dateRange?.option,
      this.settings.locationSettings,
      this.now
    );
  }

  @action
  initConfig(availablePlantAssets: PlantAsset[]) {
    const { config } = this.args;

    if (!this.configAlreadyInit()) {
      const newConfig = Object.assign({}, config, {
        kpiTypes: oeekpiTypes(),
        heading: this.getHeading(config.cardType),
        configHeading: this.getConfigHeading(config.cardType),
        availablePlantAssets: availablePlantAssets,
      });
      this.args.updateConfig(newConfig);
    }
  }

  @action
  updateColor(color: string) {
    const { config } = this.args;

    const newConfig: T = Object.assign({}, config, {
      color,
    });
    this.args.updateConfig(newConfig);
  }

  @action
  updatePlantAsset(
    availablePlantAssets: PlantAsset[],
    plantAsset?: PlantAsset
  ) {
    const { config } = this.args;

    const newConfig = Object.assign({}, config, {
      heading: this.getHeading(config.cardType, plantAsset),
      plantAsset: plantAsset,
      availablePlantAssets: availablePlantAssets,
    });
    this.args.updateConfig(newConfig);
  }
}
