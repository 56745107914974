import { Ability } from 'ember-can';
import { service } from '@ember/service';
import { assert } from '@ember/debug';
import UserSessionService from 'fabscale-app/services/user-session';

export default class MaintenanceTaskAbility extends Ability {
  @service userSession: UserSessionService;

  get canView() {
    return Boolean(this.userSession.permissionMap.TASK_VIEW);
  }

  get canCreate() {
    return Boolean(this.userSession.permissionMap.TASK_MANAGE);
  }

  get canEdit() {
    let { model } = this;
    let { user } = this.userSession;

    assert(
      'You have to specify a model for (can "edit maintenance-task")',
      !!model
    );

    return model.assignedUser?.id === user!.id ||
      model.createdBy?.id === user!.id
      ? this.canEditOwn
      : this.canEditOther;
  }

  get canDelete() {
    return Boolean(this.userSession.permissionMap.TASK_MANAGE);
  }

  get canDeleteFile() {
    return Boolean(this.userSession.permissionMap.TASK_MANAGE);
  }

  get canEditOwn() {
    return (
      Boolean(this.userSession.permissionMap.TASK_VIEW) ||
      Boolean(this.userSession.permissionMap.TASK_MANAGE)
    );
  }

  get canEditOther() {
    return Boolean(this.userSession.permissionMap.TASK_MANAGE);
  }
}
