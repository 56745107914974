import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import {
  serializeRelativeTimeframe,
  deserializeRelativeTimeframe,
} from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { RelativeTimeframeId } from 'fabscale-app/models/enums/relative-timeframe';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';

export function serializeKpiDataFilterSettings(
  filterSettings: KpiDataFilterSettings
): string {
  let obj: {
    relativeTimeframe?: RelativeTimeframeId;
    absoluteTimeframe?: string;
    timeRange?: string;
    plantAssetIds?: string[];
    recipeIds?: string[];
    kpiTypes?: KpiType[];
    sensorNames?: string[];
    sensorCategories?: string[];
  } = {};

  if (filterSettings.plantAssetIds.length > 0) {
    obj.plantAssetIds = filterSettings.plantAssetIds;
  }

  if (filterSettings.recipeIds.length > 0) {
    obj.recipeIds = filterSettings.recipeIds;
  }

  if (filterSettings.kpiTypes.length > 0) {
    obj.kpiTypes = filterSettings.kpiTypes;
  }

  if (filterSettings.sensorNames.length > 0) {
    obj.sensorNames = filterSettings.sensorNames;
  }

  if (filterSettings.sensorCategories.length > 0) {
    obj.sensorCategories = filterSettings.sensorCategories;
  }

  if (filterSettings.relativeTimeframe) {
    obj.relativeTimeframe = serializeRelativeTimeframe(
      filterSettings.relativeTimeframe
    );
  }

  if (filterSettings.absoluteTimeframe) {
    obj.absoluteTimeframe = serializeDateRange(
      filterSettings.absoluteTimeframe
    );
  }

  if (filterSettings.timeRange) {
    obj.timeRange = JSON.stringify(filterSettings.timeRange);
  }

  return JSON.stringify(obj);
}

export function deserializeKpiDataFilterSettings(filterSettingsString: string) {
  let obj = JSON.parse(filterSettingsString);

  if (obj.relativeTimeframe) {
    obj.relativeTimeframe = deserializeRelativeTimeframe(obj.relativeTimeframe);
  }

  if (obj.absoluteTimeframe) {
    obj.absoluteTimeframe = deserializeDateRange(obj.absoluteTimeframe);
  }

  if (obj.timeRange) {
    obj.timeRange = JSON.parse(obj.timeRange);
  }

  return new KpiDataFilterSettings(obj);
}
