import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

export default class FormController extends Controller {
  @tracked selectValue?: string;
  @tracked selectMultipleValue?: string[];
  @tracked selectedDate?: DateTime;
  @tracked selectedDateRange?: DateRangeOptional;
  @tracked selectedSelectDropdownValue?: string;
  @tracked selectedColor?: string;

  @action
  noop() {
    // noop
  }

  @action
  async searchOptions() {
    await timeout(1000);

    let options = [];

    for (let i = 0; i < Math.floor(Math.random() * 3); i++) {
      options.push(`option ${i}`);
    }

    return options;
  }

  @action
  updateSelectValue(selectValue?: string) {
    this.selectValue = selectValue;
  }

  @action
  updateSelectMultipleValue(selectMultipleValue?: string[]) {
    this.selectMultipleValue = selectMultipleValue;
  }

  @action
  updateDate(date: DateTime) {
    this.selectedDate = date;
  }

  @action
  updateDateRange(dateRange: DateRangeOptional) {
    this.selectedDateRange = dateRange;
  }

  @action
  updateSelectedSelectDropdownValue(selectedSelectDropdownValue?: string) {
    this.selectedSelectDropdownValue = selectedSelectDropdownValue;
  }

  @action
  updateSelectedColor(color?: string) {
    this.selectedColor = color;
  }
}
