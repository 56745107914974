import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ChartData } from 'chart.js';
import { action } from '@ember/object';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';
import { service } from '@ember/service';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

interface Args {
  data: ChartData;
  allRecipes: { id: string; name: string }[];
  selectedRecipesWithColor: { id: string; color: string }[];
  removeSelectedRecipeId: (recipeId: string) => void;
}

export default class KpiDetailsReportPerRecipeOverTimeChart extends Component<Args> {
  @tracked toggledIndex: number | undefined;
  @service enumLabels: EnumLabelsService;

  get data() {
    const { data, selectedRecipesWithColor } = this.args;

    data.datasets.forEach((dataset, index) => {
      dataset.borderColor = dataset.backgroundColor =
        selectedRecipesWithColor[index]!.color;
    });

    return data;
  }

  get options() {
    return {
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          mode: 'index',
          external: this.chartTooltip,
        },
      },
      scales: {
        x: {
          offset: true,
          border: {
            display: true,
            color: cssObj.colors.lightGray,
          },
          grid: {
            display: true,
            drawTicks: true,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
          ticks: {
            display: function (context: any) {
              return !!context.scale.chart.config._config.data.datasets.length;
            },
            font: {
              size: 10,
              weight: 700,
              family: cssObj.fontFamily.lato,
            },
            autoSkipPadding: 10,
            autoSkip: true,
            color: cssObj.colors.sonicSilver,
          },
        },
        y: {
          beginAtZero: true,
          offset: false,
          display: (scale: any) => {
            return (
              scale.chart.config.data.datasets.length >= 1 &&
              window.innerWidth > 600
            );
          },
          position: cssObj.position.left,
          ticks: {
            size: 10,
            display: true,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
          },
        },
      },
    };
  }

  get chartSelectorItems() {
    const { selectedRecipesWithColor } = this.args;

    return selectedRecipesWithColor.map(({ id, color }) => {
      return {
        id,
        label: this.getRecipeName(id),
        color,
      };
    });
  }

  @action
  toggleRecipe(recipeIndex: number) {
    this.toggledIndex = recipeIndex;
  }

  @action
  removeSelectedRecipeId(recipeId: string) {
    this.args.removeSelectedRecipeId(recipeId);
  }

  private chartTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    const dateFrom =
      tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
        .dateRange.start;
    const dateTo =
      tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
        .dateRange.end;
    const formattedDateFrom = formatDate(dateFrom, DateFormat.DateTime);
    const formattedDateTo = formatDate(dateTo, DateFormat.DateTime);

    const dateLabel = `${formattedDateFrom} - ${formattedDateTo}`;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      bodyLines.forEach((body: any, i: any) => {
        const dataIndex = tooltip.dataPoints[i].dataIndex;
        const label = tooltip.dataPoints[i].dataset.label;
        const value = tooltip.dataPoints[i].dataset.data[dataIndex].value;
        const unit = tooltip.dataPoints[i].dataset.unit;

        const unitString = this.enumLabels.unit(unit);
        const valueUnitPairString = `${formatNumber(
          value,
          undefined
        )} ${unitString}`;
        const color = tooltip.dataPoints[i].dataset.borderColor;
        const coloredCircle = document.createElement('span');
        coloredCircle.style.background = color;
        coloredCircle.style.borderColor = color;
        coloredCircle.style.borderWidth = cssObj.spacings._0px;
        coloredCircle.style.height = '0.7em';
        coloredCircle.style.width = '0.7em';
        coloredCircle.style.borderRadius = '50%';
        coloredCircle.style.marginTop = '1px';
        coloredCircle.style.marginRight = '2px';
        coloredCircle.style.display = cssObj.display.inlineBlock;
        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let valueUnitPairElement = document.createElement('p');
        valueUnitPairElement.innerHTML = `${valueUnitPairString}`;
        valueUnitPairElement.style.marginRight = cssObj.spacings._8px;
        valueUnitPairElement.style.fontSize = cssObj.spacings._14px;
        valueUnitPairElement.style.fontWeight = cssObj.fontWeight._700;
        valueUnitPairElement.style.fontFamily = cssObj.fontFamily.lato;
        valueUnitPairElement.style.margin = '0';

        let labelElement = document.createElement('p');
        labelElement.innerHTML = `${label}:`;
        labelElement.style.marginRight = cssObj.spacings._8px;
        labelElement.style.fontSize = cssObj.spacings._14px;
        labelElement.style.fontFamily = cssObj.fontFamily.lato;
        labelElement.style.margin = '0';

        let rowTextContainer = document.createElement('div');
        rowTextContainer.style.display = cssObj.display.flex;
        rowTextContainer.style.flexDirection = cssObj.flex.flexDirection.row;
        rowTextContainer.style.justifyContent = 'space-between';
        rowTextContainer.style.width = '100%';
        rowTextContainer.append(labelElement);
        rowTextContainer.append(valueUnitPairElement);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';
        tableDataContainer.append(coloredCircle, rowTextContainer);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      let dateLabelElement = document.createElement('p');
      dateLabelElement.style.marginBottom = cssObj.spacings._0px;
      dateLabelElement.style.color = cssObj.colors.sonicSilver;
      dateLabelElement.style.fontSize = cssObj.spacings._12px;
      dateLabelElement.innerHTML = dateLabel;

      tableBody.appendChild(dateLabelElement);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${Number(positionY) + Number(tooltip.caretY)}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  private getRecipeName(recipeId: string) {
    const { allRecipes } = this.args;
    const recipe = allRecipes.find((recipe) => recipe.id === recipeId);

    return recipe ? recipe.name : recipeId;
  }
}
