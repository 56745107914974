export class ComparativeValue {
  tendency: number;
  previousValue: number;
  tendencyIsPositive: boolean;

  constructor(options: ComparativeValueInput) {
    // We take a tendency like 0.01 and transform it to 1%
    this.tendency = options.tendency * 100;
    this.previousValue = options.previousValue;
    this.tendencyIsPositive = options.tendencyIsPositive;
  }
}

export interface ComparativeValueInput {
  tendency: number;
  previousValue: number;
  tendencyIsPositive: boolean;
}
