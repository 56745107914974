import { assert } from '@ember/debug';
import { restartableTask } from 'ember-concurrency';
import { Modifier } from 'ember-could-get-used-to-this';

export default class QrCodeModifier extends Modifier {
  setup() {
    this.generateQrCodeTask.perform();
  }

  teardown() {
    this.generateQrCodeTask.cancelAll();
  }

  generateQrCodeTask = restartableTask(async () => {
    let { element } = this;
    let [url] = this.args.positional;

    assert(
      `{{qr-code}} modifier can only be used on <canvas> elements, but is used on ${element.tagName}`,
      element.tagName.toLowerCase() === 'canvas'
    );

    // This is lazy-imported via ember-auto-import
    // This will only load the bundle if required, making the main bundle smaller
    // @ts-ignore
    let QRCode = await import('qrcode');

    await QRCode.toCanvas(element, url, { margin: 0, width: 120 });
  });
}
