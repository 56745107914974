import { action } from '@ember/object';
import Component from '@glimmer/component';
import { FileExtensionsWebViewable } from 'fabscale-app/models/enums/file-extension';
import { FileOrPendingFile } from '../table';

interface Args {
  record: FileOrPendingFile;
  tableData: {
    selectLightboxFile: (file: FileOrPendingFile) => void;
    editRecord?: FileOrPendingFile;
  };
}

export default class FileUploadListCellsName extends Component<Args> {
  get isEditing() {
    return this.args.record === this.args.tableData.editRecord;
  }

  @action
  openLightbox(event: MouseEvent) {
    if (!FileExtensionsWebViewable.includes(this.args.record.extension)) {
      return;
    }

    event.preventDefault();

    this.args.tableData.selectLightboxFile(this.args.record);
  }
}
