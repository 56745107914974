import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import BaseAsyncResource from './base-async-resource';

interface LoadKpiDataOverTimeOptions {
  kpiTypes: KpiType[];
  dateRange: DateRange;
  interval: Interval;
  plantAssetIds?: string[];
  recipeIds?: string[];
  cutOffDate?: DateTime;
}

export default class LoadKpiDataOverTimeResource extends BaseAsyncResource<
  KpiDataOverTime[],
  LoadKpiDataOverTimeOptions
> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData({
    kpiTypes,
    dateRange,
    interval,
    plantAssetIds,
    recipeIds,
  }: LoadKpiDataOverTimeOptions) {
    let { kpiDataStore } = this;

    let fetchOptions = {
      dateRange,
      interval,
      plantAssetIds,
      recipeIds,
    };

    assert(
      `{{resources/load-kpi-data-over-time}}: dateRange.start has to be at 00:00:00, but is ${serializeDate(
        dateRange.start
      )}`,

      +dateRange.start === +dateRange.start.startOf('day')
    );
    assert(
      `{{resources/load-kpi-data-over-time}}: dateRange.end has to be at 00:00:00, but is ${serializeDate(
        dateRange.end
      )}`,
      +dateRange.end === +dateRange.end.startOf('day')
    );

    let data = await promiseQueue(kpiTypes, (kpiType) =>
      loadKpiDataOverTime(kpiDataStore, kpiType, fetchOptions)
    );

    // Remove empty items
    return data.filter(filterKpiDataOverTime);
  }
}

export function loadKpiDataOverTime(
  kpiDataStore: StoreKpiDataService,
  kpiType: KpiType,
  {
    dateRange,
    interval,
    plantAssetIds,
    recipeIds,
  }: {
    dateRange: DateRange;
    interval: Interval;
    plantAssetIds?: string[];
    recipeIds?: string[];
  }
) {
  return kpiDataStore.findOverTime(
    kpiType,
    {
      dateRange,
      plantAssetIds,
      recipeIds,
    },
    { interval }
  );
}

export function filterKpiDataOverTime(
  kpiDataOverTime?: KpiDataOverTime
): kpiDataOverTime is KpiDataOverTime {
  return !!kpiDataOverTime;
}
