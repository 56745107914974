import { EnumsBase } from './-base';

export const TaskRequirements = [
  'ASSET_SHUTDOWN',
  'AREA_SHUTDOWN',
  'SPARE_PARTS',
  'EXTERNAL',
] as const;

export type TaskRequirement = typeof TaskRequirements[number];

const requirementsColorMap: { [key in TaskRequirement]: string } = {
  ASSET_SHUTDOWN: '#C05621',
  AREA_SHUTDOWN: '#9A4B6C',
  SPARE_PARTS: '#287C7B',
  EXTERNAL: '#9B51E0',
};

export function getTaskRequirementColor(requirement: TaskRequirement) {
  return requirementsColorMap[requirement];
}

export class TaskRequirementsEnums extends EnumsBase<TaskRequirement> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in TaskRequirement]: string } = {
      ASSET_SHUTDOWN: l10n.t('Asset shutdown'),
      AREA_SHUTDOWN: l10n.t('Area shutdown'),
      SPARE_PARTS: l10n.t('Spare parts'),
      EXTERNAL: l10n.t('External'),
    };

    this.labels = labels;
  }
}
