import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { GroupBinValue } from './group-bin-value';

export class KpiDataGrouped {
  type: KpiType;
  unit: Unit;
  higherIsBetter: boolean;
  values: GroupBinValue[];

  constructor({ type, unit, higherIsBetter, values }: KpiDataGroupedInput) {
    this.type = type;
    this.unit = unit;
    this.higherIsBetter = higherIsBetter;
    this.values = values;
  }
}

export interface KpiDataGroupedInput {
  type: KpiType;
  higherIsBetter: boolean;
  unit: Unit;
  values: GroupBinValue[];
}
