import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AbilitiesService } from 'ember-can';
import { restartableTask, timeout } from 'ember-concurrency';
import CellLastMeasureDate from 'fabscale-app/components/page/plant/plant-assets/details/status-info/cells/last-measure-date';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { PlantAssetInfoSummary } from 'fabscale-app/models/plant-asset-info-summary';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';

interface Args {
  infoSummaries: PlantAssetInfoSummary[];
}

export default class PlantPlantAssetDetailsStatusInfoIndex extends Component<Args> {
  @service l10n: L10nService;
  @service abilities: AbilitiesService;

  @tracked searchTerm?: string;
  columns: TableColumnDefinitionInput[];

  get filteredData() {
    let { searchTerm } = this;
    let { infoSummaries } = this.args;

    if (!searchTerm) {
      return infoSummaries;
    }

    return filterRecords(infoSummaries, searchTerm, {
      propertyName: 'label',
    });
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;
    let columns: TableColumnDefinitionInput[] = [
      {
        title: l10n.t('Name'),
        propertyName: 'label',
        noCompactTitle: true,
      },
      {
        title: l10n.t('Total duration'),
        propertyName: 'totalRuntime',
        cellType: 'AMOUNT',
        cellData: {
          unit: 'HOUR',
        },
      },
      {
        title: l10n.t('Daily average'),
        propertyName: 'averageRuntimePerDay',
        cellType: 'AMOUNT',
        cellData: {
          unit: 'HOUR',
        },
      },
      {
        title: l10n.t('Last update'),
        propertyName: 'lastMeasureDate',
        component: CellLastMeasureDate,
      },
    ];

    this.columns = columns;
  }

  updateSearchTermTask = restartableTask(async (searchTerm: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.searchTerm = searchTerm;
  });
}
