import { EnumsBase } from './-base';

export const TaskCategories = ['CHECK', 'CLEAN', 'SERVICE', 'OTHER'] as const;

export type TaskCategory = typeof TaskCategories[number];

export class TaskCategoriesEnums extends EnumsBase<TaskCategory> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in TaskCategory]: string } = {
      CHECK: l10n.t('Check'),
      CLEAN: l10n.t('Clean'),
      SERVICE: l10n.t('Service'),
      OTHER: l10n.t('Other'),
    };

    this.labels = labels;
  }
}
