import Component from '@glimmer/component';
import { DateTimeFormatOptions } from 'luxon';
import { getDateFormatForInterval } from 'fabscale-app/utilities/utils/date-interval';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { DateRange } from 'fabscale-app/models/date-range';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  overTimeInterval: Interval;
  exactDateRange: DateRange;
  removeSelectedRecipeId: (recipeId: string) => void;
}
export default class PageKpiDetailsReportPerRecipeOverTime1 extends Component<Args> {
  @tracked toggledRecipeIndex: number | undefined;

  get dateFormat(): DateTimeFormatOptions {
    const { overTimeInterval, exactDateRange } = this.args;

    if (
      overTimeInterval === 'HOUR' &&
      !exactDateRange?.end.hasSame(exactDateRange.start, 'day')
    ) {
      return { day: 'numeric', month: 'numeric', hour: 'numeric' };
    }

    return getDateFormatForInterval(overTimeInterval);
  }

  @action
  removeSelectedRecipeId(recipeId: string) {
    this.args.removeSelectedRecipeId(recipeId);
  }
}
