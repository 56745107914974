import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { getOwnConfig, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import config from 'fabscale-app/config/environment';
import CognitoService from 'fabscale-app/services/cognito';
import UserSessionService from 'fabscale-app/services/user-session';
import { DeviceType } from '.';
import { generateMfaQrCodeUrl } from 'fabscale-app/utilities/utils/cognito/mfa-qr-code';
import { MfaCodeMismatchError } from 'fabscale-app/models/errors/cognito';

interface Args {
  deviceType: DeviceType;
}

export default class SettingsSecurityMfaSetupMfaSetupForm extends Component<Args> {
  @service cognito: CognitoService;
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service router: RouterService;

  @tracked mfaCode?: string;
  @tracked secret?: string;
  @tracked qrCodeUrl?: string;
  @tracked error?: string;

  get isAndroid() {
    return this.args.deviceType === 'ANDROID';
  }

  get isIOS() {
    return this.args.deviceType === 'IOS';
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.setupDeviceTask.perform();
  }

  @action
  updateMfaCode(mfaCode: string) {
    this.mfaCode = mfaCode;
  }

  setupDeviceTask = task(async () => {
    let secret: string = await this.cognito.cognitoData!.mfa.setupDevice();

    let user = this.userSession.user!.email;
    let label = config.environmentName
      ? `Fabscale ${config.environmentName}`
      : 'Fabscale';

    let url = generateMfaQrCodeUrl({
      user,
      label,
      secret,
    });

    this.secret = secret;
    this.qrCodeUrl = url;
  });

  submitFormTask = dropTask(async () => {
    let { mfaCode, cognito, l10n } = this;
    let { deviceType } = this.args;

    this.error = undefined;

    if (!mfaCode) {
      return;
    }

    if (mfaCode.length !== 6) {
      this.error = l10n.t('The code must be 6 digits, e.g. 123456.');
      return;
    }

    let deviceName = getDeviceName(deviceType, l10n);

    try {
      if (macroCondition(!getOwnConfig<any>().isSimulation)) {
        await cognito.cognitoData!.mfa.verifyDevice(mfaCode, deviceName);
        await cognito.cognitoData!.mfa.enable();
      }
    } catch (error) {
      if (error instanceof MfaCodeMismatchError) {
        this.error = l10n.t('The provided code is invalid, please try again.');
      } else {
        this.error = (error as any)?.message || l10n.t('An error occurred!');
      }

      return;
    }

    this.router.transitionTo('routes/my-settings.security.index');
  });
}

function getDeviceName(deviceType: DeviceType, l10n: L10nService) {
  if (deviceType === 'ANDROID') {
    return l10n.t('Android');
  }

  if (deviceType === 'IOS') {
    return l10n.t('iPhone');
  }

  return undefined;
}
