import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DashboardCardConfigOeeKpi } from 'fabscale-app/models/dashboard-config';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { oeekpiTypes, toArray } from 'fabscale-app/utilities/utils/oee-cards';

interface Args {
  config: DashboardCardConfigOeeKpi;
  id: number;
}

export default class PageDashboardCardsOeeKpiLarge extends Component<Args> {
  @tracked
  plantAssetIds: string[];

  @tracked
  selectedPlantAsset: any;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.initPlantAssetIds();
  }

  get oeekpiTypes(): KpiType[] {
    return oeekpiTypes();
  }

  @action
  onPlantAssetChange(ev: any) {
    if (!ev) {
      const { config } = this.args;

      this.plantAssetIds = config?.availablePlantAssets?.map(
        (plantAsset: PlantAsset) => plantAsset.id
      );
    } else {
      this.plantAssetIds = toArray(ev.option);
    }
    this.selectedPlantAsset = ev;
  }

  private initPlantAssetIds() {
    const { config } = this.args;

    this.selectedPlantAsset = config.plantAsset
      ? {
          option: config.plantAsset?.id,
          label: config.plantAsset?.name,
        }
      : null;

    this.plantAssetIds = config?.plantAsset
      ? toArray(config.plantAsset?.id)
      : config?.availablePlantAssets?.map(
          (plantAsset: PlantAsset) => plantAsset.id
        );
  }
}
