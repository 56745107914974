import { service } from '@ember/service';
import { PlantAssetModel } from 'fabscale-app/models/plant-asset-model';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import BaseAsyncResource from './base-async-resource';

export default class LoadMOCK_PLANT_ASSET_MODELSResource extends BaseAsyncResource<
  PlantAssetModel[],
  any
> {
  @service('store/plant-asset') plantAsseStore: StorePlantAssetService;

  loadData() {
    return this.plantAsseStore.findAvailableModels();
  }
}
