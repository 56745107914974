import Component from '@glimmer/component';
import { action } from '@ember/object';
import { assert } from '@ember/debug';

interface Args {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: (isChecked: boolean) => void;
}

export default class UiToggleButton extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    assert(
      '<Ui:ToggleButton @onChange> onChange must be set',
      typeof this.args.onChange === 'function' || this.args.isDisabled
    );
  }

  @action
  handleOnChange() {
    if (this.args.isDisabled) {
      return;
    }

    let isChecked = !this.args.isChecked;

    this.args.onChange(isChecked);
  }
}
