import { assert } from '@ember/debug';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { DateTime } from 'luxon';

export default function formatDateHelper(
  date: DateTime,
  {
    format = DateFormat.Date,
    formatOptions,
    formatString,
  }: {
    format?: DateFormat;
    formatOptions?: Intl.DateTimeFormatOptions;
    formatString?: string;
  } = {}
) {
  assert('format-date helper expects a Luxon date', date instanceof DateTime);

  if (formatString) {
    return date.toFormat(formatString);
  }

  if (formatOptions) {
    return date.toLocaleString(formatOptions);
  }

  return formatDate(date, format);
}
