import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { GroupBinValue } from 'fabscale-app/models/group-bin-value';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { HorizontalProgressItem } from 'fabscale-app/components/ui/horizontal-progress/item/component';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';

export default function kpiPerPlantAssetGroupedToProgressItems(
  kpiDataGrouped: KpiDataGrouped
): HorizontalProgressItem[] {
  if (!kpiDataGrouped) {
    return [];
  }

  const values: number[] = kpiDataGrouped.values.map(
    (groupBinValue: GroupBinValue) => groupBinValue.value
  );
  const totalValue: number = values.reduce((total, value) => total + value, 0);

  return kpiDataGrouped.values.map(
    (groupBinValue: GroupBinValue, index: number) => {
      return {
        label: groupBinValue.name,
        value: groupBinValue.value,
        percentage: formatNumber((groupBinValue.value / totalValue) * 100),
        unit: kpiDataGrouped.unit,
        color: chartColors[index] as string,
      };
    }
  );
}
