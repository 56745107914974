import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class UiHelpCentre extends Component {
  @tracked isTooltipVisible = false;
  @tracked isMenuVisible = false;

  @action
  showTooltip() {
    this.isTooltipVisible = true;
  }

  @action
  hideTooltip() {
    this.isTooltipVisible = false;
  }

  @action
  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }
}
