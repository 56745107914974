import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Args {
  value: DateTime;
  allowClear?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  updateDate: (closeFn: () => void, date?: DateTime) => void;
  close?: () => void;
  toggle?: () => void;
}

export default class UiDatePickerToggle extends Component<Args> {
  @action
  onUpdate(date?: DateTime) {
    if (date?.valueOf() !== this.args.value?.valueOf()) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.args.updateDate(() => {}, date);
    }
  }
}
