import { PlantAssetAreaType } from 'fabscale-app/models/enums/plant-asset-area-types';
import { PlantAsset } from './plant-asset';

export class PlantAssetArea {
  id: string;
  name: string;
  type: PlantAssetAreaType;
  description?: string;
  plantAsset?: PlantAsset;

  constructor(options: PlantAssetAreaInput) {
    this.id = options.id;
    this.name = options.name;
    this.type = options.type;
    this.description = options.description;
    this.plantAsset = options.plantAsset;
  }
}

export interface PlantAssetAreaInput {
  id: string;
  name: string;
  type: PlantAssetAreaType;
  description?: string;
  plantAsset?: PlantAsset;
}
