import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import config from 'fabscale-app/config/environment';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { generateHtmlSnapshot } from 'fabscale-app/utilities/utils/dom/generate-html-snapshot';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import AnalyticsService from './analytics';

export default class PdfService extends Service {
  @service l10n: L10nService;
  @service analytics: AnalyticsService;

  @tracked isPrinting = false;

  _rootElement = document.documentElement;
  _printWidth = 1400;
  _pdfEndpoint = config.pdfEndpoint;

  startPrintingMode() {
    this._rootElement.classList.add('show-print-styles');
  }

  endPrintingMode() {
    this._rootElement.classList.remove('show-print-styles');
  }

  _startPrintModeTask = task(async () => {
    this.isPrinting = true;

    let event = new Event('beforeprint');
    window.dispatchEvent(event);
    this.startPrintingMode();

    // If the body is too small, we temporarily make it bigger, to ensure the generated HTML output is correct
    // We re-set this after printing
    if (this._rootElement.offsetWidth < this._printWidth) {
      this._rootElement.style.width = `${this._printWidth}px`;
    }

    await timeout(TIMEOUTS.printResize);
  });

  _endPrintMode() {
    this._rootElement.style.width = '';
    this.isPrinting = false;

    let event = new Event('afterprint');
    window.dispatchEvent(event);

    this.endPrintingMode();
  }

  generateForCurrentPageTask = task(
    async (fileName = 'fabscale-export.pdf') => {
      await this._startPrintModeTask.perform();
      let html = generateHtmlSnapshot(this._rootElement);
      this._endPrintMode();

      let url = this._pdfEndpoint;

      let options = {
        html,
      };

      assert('You have to specify a process.env.PDF_GENERATOR_ENDPOINT', !!url);

      this.analytics.addEvent('print-pdf');

      try {
        // The API responds with a base64 string
        // We need to convert this to a PDF blob
        let base64: string = await this._fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify(options),
        });

        let fileUrl = `data:application/pdf;base64,${base64}`;
        let fileBlob: Response = await fetch(fileUrl);
        let blob: Blob = await fileBlob.blob();

        this._triggerDownload(fileName, blob);
      } catch (error) {
        // We fall back to simply printing the page, in case anything breaks...
        sentryCaptureException(error);
        window.print();
      }
    }
  );

  _triggerDownload(fileName: string, blob: Blob) {
    let url = window.URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;

    this._rootElement.appendChild(a);
    a.click();

    // For Firefox it is necessary to delay revoking the ObjectURL
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      this._rootElement.removeChild(a);
    }, 100);
  }

  async _fetch(url: string, options: RequestInit) {
    let response: Response = await fetch(url, options);

    // The API responds with a base64 string
    // We need to convert this to a PDF blob
    return response.text();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    pdf: PdfService;
  }
}
