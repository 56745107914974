export class GroupBinValue {
  name: string;
  id: string;
  value: number;
  externalId?: string;

  constructor({ group, value, externalId }: GroupBinValueInput) {
    this.name = group.name;
    this.id = group.id;
    this.value = value;
    this.externalId = externalId;
  }
}

export interface GroupBinValueInput {
  group: {
    id: string;
    name: string;
  };
  value: number;
  externalId?: string;
}
