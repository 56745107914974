import Service, { service } from '@ember/service';
import allCognitoAppClientsQuery from 'fabscale-app/gql/admin/queries/all-cognito-app-clients.graphql';
import createCognitoAppClientMutation from 'fabscale-app/gql/admin/mutations/create-cognito-app-client.graphql';
import deleteCognitoAppClientMutation from 'fabscale-app/gql/admin/mutations/delete-cognito-app-client.graphql';
import { CognitoAppClient } from 'fabscale-app/models/cognito-app-client';
import GraphQLService from 'fabscale-app/services/-graphql';
import { sortBy } from 'fabscale-app/utilities/utils/array';

export default class StoreCogntioAppClientService extends Service {
  @service graphql: GraphQLService;

  // METHODS
  async findAll(): Promise<CognitoAppClient[]> {
    let { graphql } = this;

    let variables = {
      filters: {},
    };

    let response = await graphql.query({
      query: allCognitoAppClientsQuery,
      variables,
      namespace: 'internal_cognitoAppClientsList',
    });

    return sortBy(
      response.map((responseData: any) => new CognitoAppClient(responseData)),
      'name'
    );
  }

  async create(options: {
    name: string;
    locationId: string;
  }): Promise<CognitoAppClient> {
    let { graphql } = this;

    let variables = {
      name: options.name,
      locationId: options.locationId,
    };

    let response = await graphql.mutate({
      mutation: createCognitoAppClientMutation,
      variables,
      namespace: 'internal_createCognitoAppClient',
    });

    return new CognitoAppClient(response);
  }

  async delete(id: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate({
      mutation: deleteCognitoAppClientMutation,
      variables,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/cognito-app-client': StoreCogntioAppClientService;
  }
}
