import Component from '@glimmer/component';
import { StatusMapReportTableType } from 'fabscale-app/routes/reports/status-map/controller';

interface Args {
  tableId: StatusMapReportTableType;
}

export default class StatusMapReportDetailsTableTabBar extends Component<Args> {
  queryParamPerDay = { tableId: 'PER_DAY' };
  queryParamPerWeek = { tableId: 'PER_WEEK' };
  queryParamPerMonth = { tableId: 'PER_MONTH' };

  get isActive() {
    let { tableId } = this.args;

    return {
      perDay: tableId === 'PER_DAY',
      perWeek: tableId === 'PER_WEEK',
      perMonth: tableId === 'PER_MONTH',
    };
  }
}
