import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { DoughnutChartTypes } from 'fabscale-app/models/enums/doughnut-chart';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { oeeCardsExternalTooltipHandler } from 'fabscale-app/utilities/utils/chart';
import {
  CHART_EMPTY_SPACE,
  OEE_CHART_HEIGHTS,
  OEE_COLORS,
  OEE_TYPES,
  computeAverage,
  getCircumferenceByPercentage,
} from 'fabscale-app/utilities/utils/oee-cards';

interface Args {
  id: string;
  oeeKpiDataPerPlantAsset: KpiDataGrouped[];
}

export default class PageDashboardCardsOeeKpiCard extends Component<Args> {
  @service l10n: L10nService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get chartId() {
    const { id } = this.args;

    return `oee-card-${id}`;
  }

  get chartHeight() {
    return OEE_CHART_HEIGHTS.OEE_KPI;
  }

  get chartType() {
    return DoughnutChartTypes.OeeKpiCard;
  }

  get legendItems() {
    const { oeeKpiDataPerPlantAsset } = this.args;

    return Object.values({
      OEE_AVAILABILITY: {
        label: this.l10n.t('OEE Availability'),
        color: OEE_COLORS.OEE_AVAILABILITY,
        value: computeAverage(
          oeeKpiDataPerPlantAsset.find(
            (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_AVAILABILITY
          )!.values
        ),
      },
      OEE_PERFORMANCE: {
        label: this.l10n.t('OEE Performance'),
        color: OEE_COLORS.OEE_PERFORMANCE,
        value: computeAverage(
          oeeKpiDataPerPlantAsset.find(
            (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_PERFORMANCE
          )!.values
        ),
      },
      OEE_QUALITY: {
        label: this.l10n.t('OEE Quality'),
        color: OEE_COLORS.OEE_QUALITY,
        value: computeAverage(
          oeeKpiDataPerPlantAsset.find(
            (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_QUALITY
          )!.values
        ),
      },
    });
  }

  get data() {
    const { oeeKpiDataPerPlantAsset } = this.args;

    const oeeAverage = computeAverage(
      oeeKpiDataPerPlantAsset.find(
        (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE
      )!.values
    );

    const oeePerformanceAverage = computeAverage(
      oeeKpiDataPerPlantAsset.find(
        (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_PERFORMANCE
      )!.values
    );

    const oeeQualityAverage = computeAverage(
      oeeKpiDataPerPlantAsset.find(
        (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_QUALITY
      )!.values
    );

    return {
      labels: [''],
      datasets: [
        {
          label: this.l10n.t('OEE Availability'),
          data: [oeeAverage],
          backgroundColor: [OEE_COLORS.OEE_AVAILABILITY],
          borderWidth: 0,
          circumference: getCircumferenceByPercentage(oeeAverage),
        },
        CHART_EMPTY_SPACE,
        {
          label: this.l10n.t('OEE Performance'),
          data: [oeePerformanceAverage],
          backgroundColor: [OEE_COLORS.OEE_PERFORMANCE],
          borderWidth: 0,
          circumference: getCircumferenceByPercentage(oeePerformanceAverage),
        },
        CHART_EMPTY_SPACE,
        {
          label: this.l10n.t('OEE Quality'),
          data: [oeeQualityAverage],
          backgroundColor: [OEE_COLORS.OEE_QUALITY],
          borderWidth: 0,
          circumference: getCircumferenceByPercentage(oeeQualityAverage),
        },
      ],
    };
  }

  get options() {
    return {
      responsive: false,
      maintainAspectRatio: false,
      interaction: {
        mode: 'point',
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: oeeCardsExternalTooltipHandler,
        },
      },
    };
  }

  get plugins() {
    const { id, oeeKpiDataPerPlantAsset } = this.args;

    return [
      {
        id: `oee-kpi-card-center-label-${id}`,
        beforeDatasetsDraw(chart: any) {
          const { ctx } = chart;

          ctx.font = 'bolder 24px Lato';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(
            `${computeAverage(
              oeeKpiDataPerPlantAsset.find(
                (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE
              )!.values
            )}%`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y - 8
          );
        },
      },
      {
        id: `oee-kpi-card-sub-center-label-${id}`,
        beforeDatasetsDraw(chart: any) {
          const { ctx } = chart;

          ctx.font = '14px Lato';
          ctx.fillStyle = '#70717A';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(
            `OEE`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y + 16
          );
        },
      },
    ];
  }
}
