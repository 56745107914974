import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  onSelectFiles: (files?: File[]) => void;
}

export default class UiFileInput extends Component<Args> {
  @action
  selectFiles(event: Event) {
    let target = event.target as HTMLInputElement;
    let { files } = target;

    this.args.onSelectFiles(Array.from(files || []));

    event.preventDefault();
    target.value = '';
  }
}
