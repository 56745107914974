import { service } from '@ember/service';
import Component from '@glimmer/component';
import UserSessionService from 'fabscale-app/services/user-session';

export default class SiteWrapperDesktopHeaderBar extends Component {
  @service userSession: UserSessionService;

  get locationName() {
    return this.userSession.currentLocation?.name;
  }
}
