export type ErrorType =
  | 'INTERNAL'
  | 'BAD_REQUEST'
  | 'NOT_FOUND'
  | 'PERMISSION_DENIED'
  | 'UNAUTHENTICATED'
  | 'FAILED_PRECONDITION';

export type ErrorDetail =
  | 'UNKNOWN'
  | 'INVALID_ARGUMENT'
  | 'INVALID_ARGUMENT_COMBINATION'
  | 'UNIQUE_CONSTRAINT'
  | 'INVALID_RESOURCE_STATE'
  | 'RESOURCE_IN_USE'
  | 'SERVICE_ERROR'
  | 'MISSING_RESOURCE'
  | 'UNIMPLEMENTED'
  | 'TOKEN_EXPIRED';

export class GraphqlError extends Error {
  code: string;
  errors: any[];
  message: string;
  extensions: any;
  stack: any;

  constructor(error: any) {
    super(error);

    let errors = getErrors(error);
    let firstError = errors[0];

    this.code = 'GRAPHQL_ERROR';
    this.errors = errors;
    this.message = firstError?.message || error.toString();
    this.extensions = firstError?.extensions || {};

    if (error.stack) {
      this.stack = error.stack;
    }
  }
}

export function getGraphqlErrorType(plainError: any): ErrorType | undefined {
  let errors = getErrors(plainError);
  let firstError = errors[0];

  return firstError?.extensions?.errorType;
}

export function getGraphqlErrorDetail(
  plainError: any
): ErrorDetail | undefined {
  let errors = getErrors(plainError);
  let firstError = errors[0];

  return firstError?.extensions?.errorDetail;
}

export function getGraphqlErrorExtra(plainError: any): Record<string, any> {
  let errors = getErrors(plainError);
  let firstError = errors[0];

  return firstError?.extensions?.extra || {};
}

function getErrors(plainError: any) {
  return plainError.errors || [];
}
