import Component from '@glimmer/component';
import { service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import { DateTime } from 'luxon';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { DateRange } from 'fabscale-app/models/date-range';
import SettingsService from 'fabscale-app/services/settings';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetAlertService from 'fabscale-app/services/store/plant-asset-alert';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

interface Args {
  dateRange: DateRange;
  alarmType: PlantAssetAlarmType;
  plantAssetIds?: string[];
  recipeIds?: string[];
  alarmExternalIds?: string[];
}

export default class AlarmTypeReportOverall extends Component<Args> {
  @service settings: SettingsService;
  @service l10n: L10nService;
  @service('store/plant-asset-alert')
  plantAssetAlertStore: StorePlantAssetAlertService;

  @service('error-parser') errorParser: ErrorParserService;

  @tracked overallAlarmsCount?: number;
  @tracked pastAlarmsCount?: number;
  @tracked tendency?: number;
  @tracked error?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadAlarmTypeTask.perform();
  }

  get currentTimeframeLabel() {
    return this._getDateRangeLabel(this.args.dateRange);
  }

  get hasInvalidDateRange() {
    return (
      this.args.dateRange.end.valueOf() <= this.args.dateRange.start.valueOf()
    );
  }

  get previousTimeframeLabel() {
    let { dateRange } = this.args;
    let previousDateRange = this._getPreviousDateRange(dateRange);
    return this._getDateRangeLabel(previousDateRange);
  }

  async _loadData({
    alarmType,
    dateRange,
    plantAssetIds,
    recipeIds,
  }: {
    alarmType: PlantAssetAlarmType;
    dateRange: DateRange;
    plantAssetIds: string[] | undefined;
    recipeIds: string[] | undefined;
  }) {
    if (!dateRange || !dateRange.start || !dateRange.end) {
      return;
    }

    let filters = {
      dateFrom: dateRange.start.toISO(),
      dateTo: dateRange.end.toISO(),
      plantAssetIds,
      recipeIds,
      alarmType,
    };

    return await this.plantAssetAlertStore.queryPlantAlarmsExternalIdSummary(
      filters
    );
  }

  loadAlarmTypeTask = restartableTask(async () => {
    let { dateRange, alarmType, plantAssetIds, recipeIds } = this.args;
    try {
      const currentAlarmsCount = await this._loadData({
        alarmType,
        dateRange,
        plantAssetIds,
        recipeIds,
      });

      const previousDateRange = this._getPreviousDateRange(dateRange);
      const previousAlarmsCount = await this._loadData({
        alarmType,
        dateRange: previousDateRange,
        plantAssetIds,
        recipeIds,
      });

      const tendency = this.calculateTendency(
        previousAlarmsCount,
        currentAlarmsCount
      );

      this.overallAlarmsCount = currentAlarmsCount;
      this.pastAlarmsCount = previousAlarmsCount;
      this.tendency = tendency;
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      logError(error);
      return;
    }
  });

  _getDateRangeLabel(dateRange: DateRange) {
    let { l10n } = this;
    let { start, end } = dateRange;

    if (!end.hasSame(start, 'day')) {
      return `${formatDate(start, DateFormat.DateTime)} - ${formatDate(
        end,
        DateFormat.DateTime
      )}`;
    }

    let today = DateTime.local();
    let yesterday = DateTime.local().minus({ days: 1 });

    let startTime = formatDate(start, DateFormat.Time);
    let endTime = formatDate(end, DateFormat.Time);

    if (end.hasSame(today, 'day')) {
      return `${l10n.t('Today')}, ${startTime} - ${endTime}`;
    }

    if (end.hasSame(yesterday, 'day')) {
      return `${l10n.t('Yesterday')}, ${startTime} - ${endTime}`;
    }

    return `${formatDate(end)}, ${startTime} - ${endTime}`;
  }

  _getPreviousDateRange(dateRange: DateRange) {
    if (!dateRange || !dateRange.start || !dateRange.end) {
      return new DateRange({ start: DateTime.now(), end: DateTime.now() });
    }

    let days = Math.max(
      Math.ceil(dateRange.end.diff(dateRange.start, 'days').days),
      1
    );

    return new DateRange({
      start: dateRange.start.minus({ days }),
      end: dateRange.end.minus({ days }),
    });
  }

  calculateTendency(previousCount: number, currentCount: number) {
    if (previousCount === currentCount) {
      return 0;
    }

    if (previousCount === 0) {
      return 0;
    }

    if (currentCount === 0) {
      return previousCount > 0 ? -1 : 1;
    }

    return (currentCount / previousCount - 1) * 100;
  }
}
