import { Ability } from 'ember-can';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';

export default class LocationAbility extends Ability {
  @service userSession: UserSessionService;

  get canEdit() {
    return Boolean(this.userSession.permissionMap.LOCATION_MANAGE);
  }

  get canHaveMultiple() {
    return this.userSession.locations.length > 1;
  }
}
