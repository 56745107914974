import { service } from '@ember/service';
import Component from '@glimmer/component';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import {
  calculateTendency,
  checkTendencyIsPositive,
} from 'fabscale-app/utilities/utils/transform-kpi-data';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import formatNumber from 'fabscale-app/helpers/format-number';

export default class PageDashboardCardsKpiPerPlantAssetCard extends Component<{
  Args: {
    kpiDataPerPlantAsset: KpiDataGrouped;
    comparativeData?: KpiData;
  };
}> {
  @service enumLabels: EnumLabelsService;

  get overallValue() {
    let { values } = this.args.kpiDataPerPlantAsset;

    return values.reduce((total, bin) => total + bin.value, 0);
  }

  get unit() {
    return this.args.kpiDataPerPlantAsset.unit;
  }

  get comparativeValue() {
    let { overallValue } = this;
    let { comparativeData } = this.args;

    if (!comparativeData) {
      return undefined;
    }

    const higherIsBetter = comparativeData.higherIsBetter;
    let previousValue = comparativeData.value;
    let tendency =
      !overallValue && !previousValue
        ? undefined
        : calculateTendency(previousValue, overallValue);
    let tendencyIsPositive =
      typeof tendency === 'number'
        ? checkTendencyIsPositive(tendency, higherIsBetter)
        : true;

    return {
      tendency: tendency ? tendency * 100 : tendency,
      tendencyIsPositive,
    };
  }

  private _chartTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    const dateFrom = tooltip.dataPoints[0].dataset.dateRange.start;
    const dateTo = tooltip.dataPoints[0].dataset.dateRange.end;
    const formattedDateFrom = formatDate(dateFrom, DateFormat.DateTime);
    const formattedDateTo = formatDate(dateTo, DateFormat.DateTime);

    const dateLabel = `${formattedDateFrom} - ${formattedDateTo}`;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      const unit = tooltip.dataPoints[0].dataset.unit;
      const value =
        tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
          .value;

      bodyLines.forEach(() => {
        const unitString = this.enumLabels.unit(unit);
        const valueUnitPairString = `${formatNumber(
          value,
          undefined
        )} ${unitString}`;

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let valueUnitPairElement = document.createElement('p');
        valueUnitPairElement.innerHTML = `${valueUnitPairString}`;
        valueUnitPairElement.style.marginRight = cssObj.spacings._8px;
        valueUnitPairElement.style.fontSize = cssObj.spacings._14px;
        valueUnitPairElement.style.fontWeight = cssObj.fontWeight._700;
        valueUnitPairElement.style.fontFamily = cssObj.fontFamily.lato;

        let rowTextContainer = document.createElement('span');
        rowTextContainer.style.display = cssObj.display.flex;
        rowTextContainer.style.flexDirection = cssObj.flex.flexDirection.row;
        rowTextContainer.append(valueUnitPairElement);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';
        tableDataContainer.append(rowTextContainer);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      let dateLabelElement = document.createElement('p');
      dateLabelElement.style.marginBottom = cssObj.spacings._0px;
      dateLabelElement.style.color = cssObj.colors.sonicSilver;
      dateLabelElement.style.fontSize = cssObj.spacings._12px;
      dateLabelElement.innerHTML = dateLabel;

      tableBody.appendChild(dateLabelElement);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${Number(positionY) + Number(tooltip.caretY)}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  get chartOptions() {
    return {
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'x',
      },
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this._chartTooltip,
        },
      },
      barThickness: 33,
      scales: {
        x: {
          stacked: true,
          offset: true,
          border: {
            display: true,
          },
          grid: {
            stacked: true,
            display: true,
            drawTicks: false,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
        },
        y: {
          stacked: true,
          offset: false,
          position: cssObj.position.left,
          ticks: {
            display: function (context: any) {
              return !!context.scale.chart.config._config.data.datasets.length;
            },
            maxTicksLimit: 6,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
            lineWidth: (context: any) => Number(!!context.index),
          },
        },
      },
    };
  }
}
