import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import {
  getActualMargins,
  Margins,
  OptionalMargins,
} from 'fabscale-app/utilities/utils/chart';

interface Args {
  innerHeight?: number;
  aspectRatio?: number;
  margins?: OptionalMargins;
  defaultMargins: Margins;
}

export default class ChartContainer extends Component<Args> {
  chartGuid = guidFor(this);
  @tracked _chartContainerWidth?: number;

  // Defaults
  defaultAspectRatio = 16 / 9;

  get outerWidth() {
    // Note: _chartContainerWidth is souet by the {{resize-observer}}.
    // So it _might_ not be set when this is called the first time,
    // which is why we fallback to just calculating the width directly for that case
    return this._chartContainerWidth || this._getChartContainerWidth();
  }

  get outerHeight() {
    if (this.args.innerHeight) {
      return this.args.innerHeight + this.margins.top + this.margins.bottom;
    }

    let aspectRatio = this.args.aspectRatio || this.defaultAspectRatio;
    return this.outerWidth / aspectRatio;
  }

  get margins(): Margins {
    let { margins, defaultMargins } = this.args;

    return getActualMargins(defaultMargins, margins);
  }

  get width() {
    return this.outerWidth - this.margins.left - this.margins.right;
  }

  get height() {
    return this.outerHeight - this.margins.top - this.margins.bottom;
  }

  resizeChartContainerDebouncedTask = restartableTask(async () => {
    await timeout(TIMEOUTS.chartResizeDebounce);
    this._resizeChartContainer();
  });

  resizeChartContainerImmediatelyTask = restartableTask(async () => {
    await timeout(1);
    this._resizeChartContainer();
  });

  _resizeChartContainer() {
    this._chartContainerWidth = this._getChartContainerWidth();
  }

  _getChartContainerWidth() {
    let chartContainer = document.querySelector(
      `[data-chart-container="${this.chartGuid}"]`
    ) as HTMLElement | undefined;

    // Handle the rare case (mostly in tests...) that this is called before the container is initialized
    return chartContainer ? chartContainer.offsetWidth : 0;
  }
}
