import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, timeout } from 'ember-concurrency';
import { DashboardCardType } from 'fabscale-app/models/dashboard-config';

export default class PageDashboardCustomizeAddItem extends Component<{
  Args: {
    cardType: DashboardCardType;
    width: number;
    height: number;
    startDrag: () => void;
    endDrag: () => void;
  };
}> {
  @tracked showCanDragDrop = false;

  _overlay?: HTMLElement;

  willDestroy() {
    super.willDestroy();

    this._cleanupOverlay();
  }

  @action
  registerOverlay(element: HTMLElement) {
    this._overlay = element;
  }

  @action
  onDragStart(event: DragEvent) {
    // event.dataTransfer can only be null if e.g. manually creating this event, but to be safe...
    if (!event.dataTransfer) {
      return;
    }

    let { cardType, width, height } = this.args;

    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('mode', 'add');
    event.dataTransfer.setData('cardType', cardType);
    event.dataTransfer.setData('width', `${width}`);
    event.dataTransfer.setData('height', `${height}`);

    this._setDragImage(event.dataTransfer);

    this.args.startDrag();
  }

  _setDragImage(dataTransfer: DataTransfer) {
    // We have a template for the drag image in the DOM
    // However this is hidden via display: none, to hide it from screenreaders etc.
    let overlayPlaceholder = this._overlay;

    if (!overlayPlaceholder) {
      return;
    }

    overlayPlaceholder.style.display = 'block';
    dataTransfer.setDragImage(overlayPlaceholder, 0, 0);
  }

  @action
  onDragEnd() {
    this._cleanupOverlay();
    this.args.endDrag();
  }

  onClickTask = dropTask(async () => {
    this.showCanDragDrop = true;

    await timeout(1000);

    this.showCanDragDrop = false;
  });

  _cleanupOverlay() {
    if (this._overlay) {
      this._overlay.style.display = 'none';
    }
  }
}
