import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import { BaseEnumHelper } from './-base';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

export default class EnumPlantAssetAlarmTypeHelper extends BaseEnumHelper<PlantAssetAlarmType> {
  @service l10n: L10nService;

  getLabel(enumValue: PlantAssetAlarmType) {
    return this.enumLabels.plantAssetAlarmType(enumValue);
  }
}
