import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { EnumBaseSearchMatcherHelper } from './-base';

export default class EnumSearchMatcherTaskStatusHelper extends EnumBaseSearchMatcherHelper<TaskStatus> {
  @service l10n: L10nService;

  getLabel(option: TaskStatus) {
    let { l10n } = this;
    return option === 'COMPLETED' ? l10n.t('Completed') : l10n.t('Open');
  }
}
