import { formatNumber } from './format-number';

export function formatFileSize(size: number) {
  let kb = size / 1024;
  let mb = kb / 1024;

  if (mb < 1) {
    return `${formatNumber(kb)} KB`;
  }

  return `${formatNumber(mb)} MB`;
}
