import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { oeeCardsExternalTooltipHandler } from 'fabscale-app/utilities/utils/chart';
import {
  DEFAULT_CARD_COLOR,
  GAUGE_ARCH_BACKGROUND,
  OEE_CHART_HEIGHTS,
  OEE_COLORS,
  OEE_TYPES,
  computeAverage,
} from 'fabscale-app/utilities/utils/oee-cards';

interface Args {
  oeeKpiDataPerPlantAsset: KpiDataGrouped[];
  availablePlantAssets: PlantAsset[];
  selectedPlantAsset: PlantAsset;
  onPlantAssetChange: (plantAsset: PlantAsset) => void;
  color: string;
  id: string;
}

export default class PageDashboardCardsOeeKpiLargeCard extends Component<Args> {
  @service l10n: L10nService;

  @tracked
  selectedPlantAsset: any;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.initSelectedPlantAsset();
  }

  private initSelectedPlantAsset() {
    const { selectedPlantAsset } = this.args;

    this.selectedPlantAsset = selectedPlantAsset;
  }

  @action
  onPlantAssetChange(ev: any) {
    this.selectedPlantAsset = ev;
    this.args.onPlantAssetChange(ev);
  }

  get chartId() {
    const { id } = this.args;

    return `oee-large-card-${id}`;
  }

  get chartHeight() {
    return OEE_CHART_HEIGHTS.OEE_KPI_LARGE;
  }

  get availablePlantAssets() {
    const { availablePlantAssets } = this.args;
    return availablePlantAssets;
  }

  get plantAssetsDropdownValues() {
    let { availablePlantAssets } = this.args;

    return [
      ...availablePlantAssets.map((plantAsset: any) => ({
        option: plantAsset.id,
        label: plantAsset.name,
      })),
    ];
  }

  get data() {
    const { oeeKpiDataPerPlantAsset, color } = this.args;

    const oeeAverage = computeAverage(
      oeeKpiDataPerPlantAsset.find(
        (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE
      )!.values
    );

    return {
      labels: [''],
      datasets: [
        {
          label: this.l10n.t('OEE'),
          data: [oeeAverage, 100 - oeeAverage],
          backgroundColor: [color ?? DEFAULT_CARD_COLOR, GAUGE_ARCH_BACKGROUND],
          borderWidth: 0,
        },
      ],
    };
  }

  get options() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      circumference: 180,
      rotation: 270,
      cutout: '80%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: oeeCardsExternalTooltipHandler,
          filter: function (tooltipItem: any) {
            if (tooltipItem.dataIndex === 1) {
              return false;
            }
            return true;
          },
        },
      },
    };
  }

  get plugins() {
    const { id, oeeKpiDataPerPlantAsset } = this.args;

    return [
      {
        id: `oee-kpi-large-card-center-label-${id}`,
        beforeDatasetsDraw(chart: any) {
          const { ctx } = chart;

          ctx.font = 'bolder 32px Lato';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(
            `${computeAverage(
              oeeKpiDataPerPlantAsset.find(
                (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE
              )!.values
            )}%`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y / 2 + 16
          );
        },
      },
      {
        id: `oee-kpi-large-card-center-label-${id}`,
        beforeDatasetsDraw(chart: any) {
          const { ctx } = chart;

          ctx.font = '16px Lato';
          ctx.fillStyle = '#70717A';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(
            `OEE`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y / 2 + 45
          );
        },
      },
    ];
  }

  get legendItems() {
    const { oeeKpiDataPerPlantAsset } = this.args;

    return {
      OEE_AVAILABILITY: {
        label: this.l10n.t('OEE Availability'),
        color: OEE_COLORS.OEE_AVAILABILITY,
        value: computeAverage(
          oeeKpiDataPerPlantAsset.find(
            (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_AVAILABILITY
          )!.values
        ),
      },
      OEE_PERFORMANCE: {
        label: this.l10n.t('OEE Performance'),
        color: OEE_COLORS.OEE_PERFORMANCE,
        value: computeAverage(
          oeeKpiDataPerPlantAsset.find(
            (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_PERFORMANCE
          )!.values
        ),
      },
      OEE_QUALITY: {
        label: this.l10n.t('OEE Quality'),
        color: OEE_COLORS.OEE_QUALITY,
        value: computeAverage(
          oeeKpiDataPerPlantAsset.find(
            (dataGroup: any) => dataGroup.type === OEE_TYPES.OEE_QUALITY
          )!.values
        ),
      },
    };
  }
}
