import { DateTime, Settings } from 'luxon';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isTesting, macroCondition } from '@embroider/macros';

export default class LuxonService extends Service {
  @tracked locale: string;
  @tracked detectedLocale: string;
  @tracked timeZone: string;
  @tracked detectedTimeZone: string;

  setLocale(locale: string): void {
    Settings.defaultLocale = locale;

    this.locale = locale;
  }

  setTimeZone(timeZone: string): void {
    Settings.defaultZone = timeZone;
    this.timeZone = timeZone;
  }

  detectLocale(): string {
    return macroCondition(isTesting()) ? 'en' : DateTime.local().locale;
  }

  detectTimeZone(): string {
    return macroCondition(isTesting()) ? 'UTC' : DateTime.local().zoneName;
  }

  constructor(...rest: any) {
    super(...rest);

    let initialLocale = this.detectLocale();
    this.detectedLocale = initialLocale;
    this.setLocale(initialLocale);

    let initialTimeZone = this.detectTimeZone();
    this.detectedTimeZone = initialTimeZone;
    this.setTimeZone(initialTimeZone);
  }

  willDestroy() {
    Settings.defaultLocale = this.detectedLocale;
    Settings.defaultZone = this.detectedTimeZone;

    super.willDestroy();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    luxon: LuxonService;
  }
}
