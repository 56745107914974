import { tracked } from '@glimmer/tracking';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import {
  UnitRoastColor,
  UnitsRoastColor,
} from 'fabscale-app/models/enums/units';
import {
  Location,
  LocationSettingsInput,
  LocationSettingsPojo,
  LocationShiftSettingsPojo,
} from 'fabscale-app/models/location';
import StoreLocationService from 'fabscale-app/services/store/location';
import { formatTimeStringToIso } from 'fabscale-app/utilities/utils/parse-time';

export class LocationSettings {
  // Private
  @tracked dayStartTime: string;
  @tracked startDayOfWeek: DayOfWeek;
  @tracked unitSystem: UnitSystem;
  @tracked defaultRoastColorUnit: UnitRoastColor;
  @tracked shiftEnabled: boolean;
  @tracked shiftSchedule?: string;

  _store: StoreLocationService;
  _location: Location;

  constructor({
    location,
    store,
  }: {
    location: Location;
    store: StoreLocationService;
  }) {
    this._location = location;
    this._store = store;

    this.fromLocationSettings(location.settings);
  }

  fromLocationSettings(settings: LocationSettingsPojo) {
    this.dayStartTime = ensureValidDayStartTime(settings.dayStartTime);
    this.startDayOfWeek = settings.startDayOfWeek || 'MONDAY';
    this.unitSystem = settings.unitSystem || 'METRIC';
    this.defaultRoastColorUnit =
      settings.defaultRoastColorUnit || UnitsRoastColor[0];
    this.shiftEnabled = settings.shiftEnabled || false;
    this.shiftSchedule = settings.shiftSchedule;
  }

  toLocationSettings(): LocationSettingsInput {
    let { dayStartTime, startDayOfWeek, unitSystem, defaultRoastColorUnit } =
      this;
    return {
      dayStartTime,
      startDayOfWeek,
      unitSystem,
      defaultRoastColorUnit,
    };
  }

  async save(): Promise<void> {
    let { _store: store, _location: location } = this;
    let locationSettings = this.toLocationSettings();

    await store.updateSettings(location.id, locationSettings);

    // Also update on location object itself, when e.g. switching locations etc.
    location.settings.dayStartTime = locationSettings.dayStartTime;
    location.settings.startDayOfWeek = locationSettings.startDayOfWeek;
    location.settings.unitSystem = locationSettings.unitSystem;
    location.settings.defaultRoastColorUnit =
      locationSettings.defaultRoastColorUnit;
  }

  async saveShiftSettings(
    locationShiftSettings: LocationShiftSettingsPojo
  ): Promise<void> {
    const { _store: store, _location: location } = this;

    await store.updateShiftSettings(location.id, locationShiftSettings);

    this.shiftEnabled = locationShiftSettings.shiftEnabled;
    location.settings.shiftEnabled = this.shiftEnabled;

    this.shiftSchedule = locationShiftSettings.shiftSchedule
      ? JSON.stringify(locationShiftSettings.shiftSchedule)
      : undefined;
    location.settings.shiftSchedule = this.shiftSchedule;
  }
}

function ensureValidDayStartTime(dayStartTime?: string): string {
  if (!dayStartTime) {
    return '00:00:00';
  }

  try {
    return formatTimeStringToIso(dayStartTime);
  } catch (error) {
    // fall back to default
    return '00:00:00';
  }
}
