export function initialize() {
  let loadingSpinner =
    document && document.querySelector('#application-loader');

  loadingSpinner?.parentElement?.removeChild(loadingSpinner);
}

export default {
  initialize,
};
