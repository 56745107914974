import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Args {
  centerMonth: DateTime;
  updateCenterMonth: (date: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}

export default class DateRangePickerNavFirst extends Component<Args> {
  get canGoPrevious() {
    let { minDate, centerMonth } = this.args;

    return !minDate || minDate.startOf('month') < centerMonth.startOf('month');
  }

  @action
  gotoPreviousMonth() {
    let newCenter = this.args.centerMonth.minus({ months: 1 }).startOf('month');
    this.args.updateCenterMonth(newCenter);
  }
}
