import {
  Permission,
  SORTED_PERMISSIONS,
} from 'fabscale-app/models/enums/permissions';

export class UserRole {
  id: string;
  name: string;
  permissions: Permission[];

  constructor(options: UserRoleInput) {
    this.id = options.id;
    this.name = options.name;
    this.permissions = permissionsSort(options.permissions);
  }
}

export interface UserRoleInput {
  id: string;
  name: string;
  permissions: Permission[];
}

function permissionsSort(permissions: Permission[]): Permission[] {
  let sorted = permissions.slice();
  sorted.sort((a, b) => {
    return SORTED_PERMISSIONS.indexOf(a) - SORTED_PERMISSIONS.indexOf(b);
  });

  return sorted;
}
