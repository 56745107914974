import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { sortBy, uniqBy } from 'fabscale-app/utilities/utils/array';

interface Args {
  chartGuid: string;
  data: { color: string; label: string }[];
  customLegendData: { color: string; label: string }[];
}

export default class ChartLegend extends Component<Args> {
  guid = guidFor(this);

  get legendData() {
    let { data, customLegendData } = this.args;

    if (customLegendData) {
      return customLegendData;
    }

    let uniqueGroups = uniqBy(data, 'label');
    let sortedGroups = sortBy(uniqueGroups, 'label');

    return sortedGroups.map((item) => {
      return {
        color: item.color,
        label: item.label,
      };
    });
  }

  get legendElementContainer() {
    return document.querySelector(
      `[data-chart-legend-wormhole="${this.args.chartGuid}"]`
    );
  }
}
