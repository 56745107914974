import Service, { service } from '@ember/service';
import uploadFileMutation from 'fabscale-app/gql/mutations/upload-file.graphql';
import updateFileMutation from 'fabscale-app/gql/mutations/update-file.graphql';
import UserSessionService from 'fabscale-app/services/user-session';
import GraphQLService from 'fabscale-app/services/-graphql';
import { FilePending } from 'fabscale-app/models/file-pending';
import {
  FileWrapper,
  FileWrapperInput,
} from 'fabscale-app/models/file-wrapper';

export default class StoreFileService extends Service {
  @service userSession: UserSessionService;
  @service graphql: GraphQLService;

  get companyId() {
    return this.userSession.company!.id;
  }

  // METHODS
  async uploadFile({
    name,
    filePending,
  }: {
    name: string;
    filePending: FilePending;
  }): Promise<FileWrapper> {
    let { graphql, companyId } = this;

    let variables = {
      name,
      companyId,
      file: filePending.file,
    };

    let fileData: FileWrapperInput = await graphql.mutate({
      mutation: uploadFileMutation,
      variables,
      namespace: 'uploadFile',
      headers: {
        'graphql-require-preflight': 'true',
      },
    });

    return new FileWrapper(fileData);
  }

  async update(id: string, { name }: { name: string }) {
    let { graphql } = this;

    let variables = {
      id,
      name,
    };

    await graphql.mutate({
      mutation: updateFileMutation,
      variables,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/file': StoreFileService;
  }
}
