import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Signature {
  Args: {
    message: string;
    dueDate?: DateTime;
    startDate: DateTime;
  };
}

export default class PageTaskDetailsRepeatConfig extends Component<Signature> {
  get today() {
    return DateTime.local().startOf('day');
  }

  get percentage() {
    let { dueDate, startDate } = this.args;
    let { today } = this;

    if (!dueDate) {
      return 0;
    }

    let totalDays = dueDate.diff(startDate, 'days').days;
    let pastDays = today.diff(startDate, 'days').days;

    let percentage = (pastDays * 100) / totalDays;

    return Math.min(Math.max(percentage, 0), 100);
  }

  get isOverdue() {
    let { dueDate } = this.args;

    return dueDate && +dueDate < +this.today;
  }
}
