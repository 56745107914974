import { service } from '@ember/service';
import Component from '@glimmer/component';
import { UserRole } from 'fabscale-app/models/user-role';
import UserSessionService from 'fabscale-app/services/user-session';

interface Args {
  roles: UserRole[];
}

export default class SettingsRolesOverviewIndex extends Component<Args> {
  @service userSession: UserSessionService;

  get company() {
    return this.userSession.company;
  }
}
