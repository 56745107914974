import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { TASK_CALENDAR_TODAY } from '../date/route';

export default class TasksCalendarIndexRoute extends Route {
  @service router: RouterService;

  beforeModel() {
    this.router.replaceWith(
      'routes/maintenance.tasks.calendar.date',
      TASK_CALENDAR_TODAY
    );
  }
}
