import { assert } from '@ember/debug';
import { compare } from '@ember/utils';
import { get } from '@ember/object';
import { DateTime } from 'luxon';

export function sortBy<T>(array: T[], propertyName: string): T[] {
  assert(
    `first argument passed to \`sortBy\` should be array`,
    Array.isArray(array)
  );
  assert(
    `second argument passed to \`sortBy\` should be string`,
    typeof propertyName === 'string'
  );

  // Note: JavaScript's .sort() changes the array in-place... so we need to slice it first to avoid mutating it
  return array.slice().sort((a, b) => {
    let propA = getValue(a, propertyName);
    let propB = getValue(b, propertyName);

    return compare(propA, propB);
  });
}

function getValue(item: any, propertyName: string): any {
  let value = get(item, propertyName);

  if (value instanceof DateTime) {
    return +value;
  }

  return value;
}

export function uniq<T>(array: T[]): T[] {
  assert(
    `first argument passed to \`uniq\` should be array`,
    Array.isArray(array)
  );

  return Array.from(new Set(array));
}

export function uniqBy<T>(array: T[], propertyName: string): T[] {
  assert(
    `first argument passed to \`uniqBy\` should be array`,
    Array.isArray(array)
  );
  assert(
    `second argument passed to \`uniqBy\` should be string`,
    typeof propertyName === 'string'
  );

  let items: any[] = [];
  let seen = new Set();

  array.forEach((item) => {
    let val = getValue(item, propertyName);

    if (!seen.has(val)) {
      seen.add(val);
      items.push(item);
    }
  });

  return items;
}

export function removeItem<T>(array: T[], item: any): void {
  assert(
    `first argument passed to \`removeItem\` should be array`,
    Array.isArray(array)
  );

  let pos = array.indexOf(item);

  if (pos > -1) {
    array.splice(pos, 1);
  }
}

// Takes an array of items, and sorts it in the order of a larger array of items
// E.g. if you have selectedValues=[5,2,1] and a compare array of [1,2,3,4,5],
// This will return an array [1,2,5]
export function sortByList<T>(
  array: T[],
  compareArray: T[] | readonly T[]
): T[] {
  assert(
    `first argument passed to \`sortByList\` should be array`,
    Array.isArray(array)
  );
  assert(
    `second argument passed to \`sortByList\` should be array`,
    Array.isArray(compareArray)
  );

  return compareArray.filter((item) => array.includes(item));
}

// Sort a list of objects by a list of a property
export function sortByPropertyInList<T, Prop extends keyof T>(
  array: T[],
  compareArray: T[Prop][] | readonly T[Prop][],
  propertyName: Prop
): T[] {
  assert(
    `first argument passed to \`sortByPropertyInList\` should be array`,
    Array.isArray(array)
  );
  assert(
    `second argument passed to \`sortByPropertyInList\` should be string`,
    typeof propertyName === 'string'
  );
  assert(
    `third argument passed to \`sortByPropertyInList\` should be array`,
    Array.isArray(compareArray)
  );

  return array.slice().sort((a, b) => {
    let valA = a[propertyName];
    let valB = b[propertyName];

    let indexA = compareArray.indexOf(valA);
    let indexB = compareArray.indexOf(valB);

    return compare(indexA, indexB);
  });
}

export function arrayValuesMatch(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  let sortedArr1 = arr1.sort();
  let sortedArr2 = arr2.sort();

  return !sortedArr1.some((valA, i) => {
    let valB = sortedArr2[i];

    return valA !== valB;
  });
}
