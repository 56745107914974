import { axisBottom } from 'd3-axis';
import { AxisConfigNumbers } from 'fabscale-app/components/chart';

export default function chartGridXAxis(options: {
  axisConfig: AxisConfigNumbers;
  height: number;
}) {
  return makeXAxis(options.height, options.axisConfig);
}

function makeXAxis(height: number, { scale, ticks }: AxisConfigNumbers) {
  return axisBottom(scale)
    .tickValues(ticks)
    .tickSizeInner(height)
    .tickSizeOuter(height)
    .tickFormat(() => '');
}
