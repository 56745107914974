import Service from '@ember/service';
import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';

export default class Dashboard extends Service {
  private _dateRange: { option: DashboardDateOption; label: string } = {
    option: 'LAST_24H',
    label: 'Last 24H',
  };

  get dateRange(): { option: DashboardDateOption; label: string } {
    return this._dateRange;
  }

  set dateRange(dateRange: { option: DashboardDateOption; label: string }) {
    this._dateRange = dateRange;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'services/dashboard': Dashboard;
  }
}
