import { axisBottom, axisTop } from 'd3-axis';

import { AxisConfigDates } from 'fabscale-app/components/chart';

export default function chartXAxisDate(
  { scale, d3TimeInterval, tickFormat }: AxisConfigDates,
  { position = 'bottom' }: { position?: 'top' | 'bottom' } = {}
) {
  return (position === 'top' ? axisTop(scale) : axisBottom(scale))
    .tickSizeInner(4)
    .tickSizeOuter(0)
    .ticks(d3TimeInterval)
    .tickFormat(tickFormat);
}
