import Component from '@glimmer/component';
import { DateRange } from 'fabscale-app/models/date-range';
import { MaintenanceTaskQueryOptions } from 'fabscale-app/services/store/maintenance-task';

interface Args {
  dateRange: DateRange;
  query: MaintenanceTaskQueryOptions;
}

export default class TaskCalendarMonthLoadTasks extends Component<Args> {
  get query(): MaintenanceTaskQueryOptions {
    let { dateRange, query } = this.args;

    return Object.assign({}, query, { dateRange });
  }
}
