import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

export abstract class EnumBaseSearchMatcherHelper<EnumType> extends Helper {
  @service enumLabels: EnumLabelsService;

  compute() {
    return (option: EnumType, searchTerm: string) => {
      let normalizedSearch = searchTerm.toLowerCase();

      return this.getLabel(option).toLowerCase().includes(normalizedSearch)
        ? 1
        : -1;
    };
  }

  abstract getLabel(option: EnumType): string;
}
