import { assert } from '@ember/debug';
import { ParameterGoalResult } from 'fabscale-app/models/parameter-goal';
import { NumericRoastBatchParameter } from 'fabscale-app/models/roast-batch';

interface ParameterGoalResultOptions {
  numericRoastBatchParameter?: NumericRoastBatchParameter;
  numericRoastBatchParameters?: NumericRoastBatchParameter[];
  goalResults?: ParameterGoalResult[];
}

export default function getGoalResultStatusSummaryHelper(
  options: ParameterGoalResultOptions
) {
  let goalResults = getGoalResultsFromOptions(options);

  let goalSummary = {
    passed: goalResults.filter((goalResult) => goalResult.status === 'PASSED'),
    failed: goalResults.filter((goalResult) => goalResult.status === 'FAILED'),
    warning: goalResults.filter(
      (goalResult) =>
        goalResult.status !== 'PASSED' && goalResult.status !== 'FAILED'
    ),
  };

  return Object.assign(
    {
      hasAnyResults: goalResults.length > 0,
      hasAnyNonPassed:
        goalSummary.failed.length > 0 || goalSummary.warning.length > 0,
    },
    goalSummary
  );
}

function getGoalResultsFromOptions(
  options: ParameterGoalResultOptions
): ParameterGoalResult[] {
  if (options.goalResults) {
    return options.goalResults;
  }

  if (options.numericRoastBatchParameter) {
    return options.numericRoastBatchParameter.goalResults || [];
  }

  if (options.numericRoastBatchParameters) {
    options.numericRoastBatchParameters.forEach((param) => {
      param.goalResults?.forEach((goalResult) => {
        goalResult['parameterType'] = param.parameterType;
      });
    });

    return options.numericRoastBatchParameters.flatMap(
      (param) => param.goalResults || []
    );
  }

  assert('No goalResults could be extracted from given options', false);
  return [];
}
