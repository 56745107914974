import { service } from '@ember/service';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import AvailableDataService from 'fabscale-app/services/available-data';
import BaseAsyncResource from './base-async-resource';

export default class LoadAvailableNumericRoastBatchParameterTypesResource extends BaseAsyncResource<
  NumericRoastBatchParameterType[],
  any
> {
  @service availableData: AvailableDataService;

  async loadData() {
    let data = await this.availableData.getAvailableRoastBatchParameterTypes();
    return data.numeric;
  }
}
