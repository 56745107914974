import Component from '@glimmer/component';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DashboardCardConfigOeeKpi } from 'fabscale-app/models/dashboard-config';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { oeekpiTypes, toArray } from 'fabscale-app/utilities/utils/oee-cards';

interface PageDashboardCardsOeeKpiArgs {
  config: DashboardCardConfigOeeKpi;
}

export default class PageDashboardCardsOeeKpi extends Component<PageDashboardCardsOeeKpiArgs> {
  get oeekpiTypes(): KpiType[] {
    return oeekpiTypes();
  }

  get plantAssetIds(): string[] {
    const { config } = this.args;

    return config?.plantAsset
      ? toArray(config.plantAsset?.id)
      : config?.availablePlantAssets?.map(
          (plantAsset: PlantAsset) => plantAsset.id
        );
  }
}
