import Service, { service } from '@ember/service';
import GraphQLService from '../-graphql';
import SettingsService from '../settings';
import UserSessionService from '../user-session';
import { ReportType } from 'fabscale-app/models/enums/template';
import templateListQuery from 'fabscale-app/gql/queries/available-templates.graphql';
import reportTemplateMutation from 'fabscale-app/gql/mutations/create-report-template.graphql';
import { DateTime } from 'luxon';

export interface ITemplate {
  id: string;
  userId: string;
  templateName: string;
  reportType: keyof typeof ReportType;
  templateFilters: string;
  creationDate: DateTime;
}

export class Template {
  id: string;
  userId: string;
  templateName: string;
  reportType: keyof typeof ReportType;
  templateFilters: string;
  creationDate: DateTime;

  constructor(template: ITemplate) {
    this.id = template.id;
    this.userId = template.userId;
    this.templateName = template.templateName;
    this.reportType = template.reportType;
    this.templateFilters = template.templateFilters;
    this.creationDate = template.creationDate;
  }
}

export interface ITemplateInput {
  userId: string;
  templateName: string;
  templateFilters: string;
  reportType: keyof typeof ReportType;
}

export class TemplateInput {
  userId: string;
  templateName: string;
  templateFilters: string;
  reportType: keyof typeof ReportType;

  constructor(templateInput: ITemplateInput) {
    this.userId = templateInput.userId;
    this.templateName = templateInput.templateName;
    this.templateFilters = templateInput.templateFilters;
    this.reportType = templateInput.reportType;
  }
}

export default class StoreTemplate extends Service {
  @service graphql: GraphQLService;
  @service settings: SettingsService;
  @service userSession: UserSessionService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  async createTemplate(template: ITemplateInput) {
    const { graphql } = this;

    const variables = {
      input: template,
    };
    await graphql.mutate({
      mutation: reportTemplateMutation,
      variables,
    });
  }

  async findTemplates(
    reportType: keyof typeof ReportType
  ): Promise<Template[]> {
    const { graphql, userSession } = this;

    const variables = {
      userId: userSession.user!.id,
      reportType: reportType,
    };

    return graphql.query({
      query: templateListQuery,
      variables,
      namespace: 'templateList',
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/template': StoreTemplate;
  }
}
