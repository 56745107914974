import {
  formatNumber,
  FormatNumberOptions,
} from 'fabscale-app/utilities/utils/format-number';

export default function formatNumberHelper(
  number: number,
  options?: FormatNumberOptions
) {
  return formatNumber(number, options);
}
