import { DateTime } from 'luxon';

export class DateRangeOptional {
  start?: DateTime;
  end?: DateTime;

  constructor(options: { start?: DateTime; end?: DateTime }) {
    this.start = options.start;
    this.end = options.end;
  }
}

export class DateRange {
  start: DateTime;
  end: DateTime;

  constructor(options: { start: DateTime; end: DateTime }) {
    this.start = options.start;
    this.end = options.end;
  }
}
