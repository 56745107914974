import { axisLeft } from 'd3-axis';
import { AxisConfigNumbers } from 'fabscale-app/components/chart';

export default function chartGridYAxis(options: {
  axisConfig: AxisConfigNumbers;
  width: number;
}) {
  return makeYAxis(options.width, options.axisConfig);
}

function makeYAxis(width: number, { scale, ticks }: AxisConfigNumbers) {
  return axisLeft(scale)
    .tickValues(ticks)
    .tickSizeInner(-width)
    .tickSizeOuter(-width)
    .tickFormat(() => '');
}
