import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { PlantAssetInfoSummaryGrouped } from 'fabscale-app/services/store/plant-asset-info';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { HorizontalProgressItem } from 'fabscale-app/components/ui/horizontal-progress/item/component';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';

export default class StatusSummariesGroupedToProgressItemsHelper extends Helper<{
  PositionalArgs: [summaries: PlantAssetInfoSummaryGrouped[]];
}> {
  @service enumLabels: EnumLabelsService;

  compute([summaries]: [
    PlantAssetInfoSummaryGrouped[]
  ]): HorizontalProgressItem[] {
    if (!summaries) {
      return [];
    }

    const { enumLabels } = this;
    const values: number[] = summaries.map(
      (summary: PlantAssetInfoSummaryGrouped) => summary.runtimeInHours
    );
    const totalValue: number = values.reduce(
      (total, value) => total + value,
      0
    );

    return summaries.map((summary: PlantAssetInfoSummaryGrouped) => {
      const { status, runtimeInHours } = summary;

      const label = enumLabels.plantAssetStatus(status);

      return {
        label,
        value: runtimeInHours,
        percentage: `${formatNumber(
          (summary.runtimeInHours / totalValue) * 100
        )}`,
        unit: 'HOUR',
        color: chartColors[0],
      };
    });
  }
}
