import { service } from '@ember/service';
import { UserRole } from 'fabscale-app/models/user-role';
import StoreUserRoleService from 'fabscale-app/services/store/user-role';
import BaseAsyncResource from './base-async-resource';

interface LoadUserRolesOptions {
  companyId?: string;
}

export default class LoadUserRolesResource extends BaseAsyncResource<
  UserRole[],
  LoadUserRolesOptions
> {
  @service('store/user-role') userRoleStore: StoreUserRoleService;

  loadData(opts: LoadUserRolesOptions) {
    return this.userRoleStore.findAll(opts);
  }
}
