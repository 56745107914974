import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, timeout } from 'ember-concurrency';
import ErrorParserService from 'fabscale-app/services/error-parser';

interface Args {
  onSubmit: (
    { message }: { message: string },
    { shouldMarkAsCompleted }: { shouldMarkAsCompleted: boolean }
  ) => Promise<void>;
}

export default class MaintenanceTasksDetailPageCommentForm extends Component<Args> {
  @service('error-parser') errorParser: ErrorParserService;

  scrollElement?: HTMLElement;
  @tracked message = '';
  @tracked error?: string;

  @action
  updateMessage(message: string) {
    this.message = message;
  }

  submitFormTask = dropTask(async (shouldMarkAsCompleted = false) => {
    let { message } = this;

    if (!message) {
      return;
    }

    this.error = undefined;

    try {
      await this.args.onSubmit({ message }, { shouldMarkAsCompleted });
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    this.message = '';

    await timeout(1);

    this.scrollElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  });

  @action
  registerScrollElement(element: HTMLElement) {
    this.scrollElement = element;
  }
}
