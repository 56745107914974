import { assert } from '@ember/debug';
import { htmlSafe, isHTMLSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import { escapeHtml } from 'fabscale-app/utilities/utils/escape-html';

const BREAK_TAG = '<br>';

export default function formatNl2br(str: string): SafeString {
  assert(
    '{{format-nl2br}}: You have to provide a string as first argument',
    typeof str === 'string'
  );

  if (isHTMLSafe(str)) {
    str = str.toString();
  }

  // First, escape all tags in there
  str = escapeHtml(str);

  return htmlSafe(str.replace(/([^>\r\n]?)(\r\n|\r|\n)/g, `$1${BREAK_TAG}$2`));
}
