import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import ErrorParserService from 'fabscale-app/services/error-parser';

export default class GetErrorMessageHelper extends Helper<{
  PositionalArgs: [error: any];
}> {
  @service('error-parser') errorParser: ErrorParserService;

  compute([error]: [any]) {
    return this.errorParser.getErrorMessage(error);
  }
}
