import { service } from '@ember/service';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import AvailableDataService from 'fabscale-app/services/available-data';
import BaseAsyncResource from './base-async-resource';

export default class LoadAvailableKpiTypesResource extends BaseAsyncResource<
  KpiType[],
  any
> {
  @service availableData: AvailableDataService;

  loadData() {
    return this.availableData.getAvailableKpiTypes();
  }
}
