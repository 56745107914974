import { assert } from '@ember/debug';
import { formatDateRelative } from 'fabscale-app/utilities/utils/format-date-relative';
import { DateTime } from 'luxon';

export default function formatDateRelativeHelper(
  date: DateTime,
  { withTime, isShort } = { withTime: false, isShort: false }
) {
  assert(
    'format-date-relative helper expects a Luxon date',
    date instanceof DateTime
  );

  if (isShort) {
    return date.toRelative();
  }

  return formatDateRelative(date, { withTime });
}
