import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { ParameterGoal } from 'fabscale-app/models/parameter-goal';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreParameterGoalService from 'fabscale-app/services/store/parameter-goal';
import { scrollToTop } from 'fabscale-app/utilities/utils/dom/scroll-to-top';

interface Args {
  parameterGoal: ParameterGoal;
}

export default class PagePlantParameterGoalsCreate extends Component<Args> {
  @service('store/parameter-goal')
  parameterGoalStore: StoreParameterGoalService;

  @service router: RouterService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked error?: string;

  deleteTask = dropTask(async () => {
    this.error = undefined;

    try {
      await this.parameterGoalStore.delete(this.args.parameterGoal.id);
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      scrollToTop();
      return;
    }

    this.router.transitionTo('routes/plant-settings.parameter-goals.index');
  });
}
