import { service } from '@ember/service';
import Component from '@glimmer/component';
import { ChartData, ChartOptions } from 'chart.js';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  data: ChartData;
  options: ChartOptions;
  selectedKpiTypeA: KpiType;
  selectedKpiTypeB: KpiType;
  colorA: string;
  colorB: string;
}

export default class PageKpiCompareReportOverallCardChart extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  @tracked
  toggledDatasetIndex: number | undefined;

  get data() {
    const { data } = this.args;

    return data;
  }

  get options() {
    const { options } = this.args;

    return options;
  }

  get chartSelectorItems() {
    const { selectedKpiTypeA, selectedKpiTypeB, colorA, colorB } = this.args;

    return [
      {
        label: this.enumLabels.kpiType(selectedKpiTypeA),
        color: colorA,
      },
      {
        label: this.enumLabels.kpiType(selectedKpiTypeB),
        color: colorB,
      },
    ];
  }

  get legendItems() {
    const { selectedKpiTypeA, selectedKpiTypeB, colorA, colorB } = this.args;

    const datasetsLength = this.data.datasets.length;

    return {
      legendItemA: datasetsLength >= 1 && {
        label: this.enumLabels.kpiType(selectedKpiTypeA),
        color: colorA,
        unit: (this.data.datasets[0] as any).unit,
      },
      legendItemB: datasetsLength >= 2 && {
        label: this.enumLabels.kpiType(selectedKpiTypeB),
        color: colorB,
        unit: (this.data.datasets[1] as any)?.unit,
      },
    };
  }

  @action
  updateToggledDatasetIndex(index: number) {
    this.toggledDatasetIndex = index;
  }
}
