import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';
import { AlarmTypeReportFilters } from 'fabscale-app/models/alarm-type-report';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';

export default class AlarmReportTypeReportController extends Controller {
  queryParams = [
    'dateRange',
    'timeRange',
    'plantAssetIds',
    'alarmType',
    'alarmExternalIds',
    'recipeIds',
  ];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssetIds?: string;
  @tracked alarmType?: string;
  @tracked alarmExternalIds?: string;
  @tracked recipeIds?: string;
  @tracked sortBy?: PlantAssetAlarmSortOption;
  @tracked sortDirection?: SortDirection;
  @tracked page?: number | string;
  @tracked pageSize?: number | string;

  get pageNumber() {
    return typeof this.page === 'string' ? parseInt(this.page) : this.page;
  }

  get pageSizeNumber() {
    return typeof this.pageSize === 'string'
      ? parseInt(this.pageSize)
      : this.pageSize;
  }

  get filters(): AlarmTypeReportFilters {
    return {
      dateRange: this.parsedDateRange,
      timeRange: this.parsedJson(this.timeRange),
      plantAssetIds: this.parsedValue(this.plantAssetIds),
      alarmType: this.alarmType as PlantAssetAlarmType,
      alarmExternalIds: this.parsedValue(this.alarmExternalIds),
      recipeIds: this.parsedValue(this.recipeIds),
    };
  }

  get parsedDateRange() {
    return this.dateRange
      ? deserializeDateRange(this.dateRange)
      : new DateRangeOptional({});
  }

  parsedJson(value: string | undefined) {
    return value ? JSON.parse(value) : undefined;
  }

  parsedValue(value: string | undefined) {
    return value ? deserializeArray(value) : undefined;
  }

  @action
  updateFilters(filters: AlarmTypeReportFilters) {
    const {
      plantAssetIds,
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      recipeIds,
    } = filters;

    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssetIds = plantAssetIds
      ? JSON.stringify(plantAssetIds)
      : undefined;
    this.alarmType = alarmType ? alarmType : undefined;
    this.alarmExternalIds = alarmExternalIds
      ? serializeArray(alarmExternalIds)
      : undefined;
    this.recipeIds = recipeIds ? serializeArray(recipeIds) : undefined;
  }

  @action
  updatePage(page?: number) {
    if (page !== this.page) {
      this.page = page;
    }
  }

  @action
  updatePageSize(pageSize?: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.page = undefined;
    }
  }

  @action
  updateSortBy(sortBy?: PlantAssetAlarmSortOption) {
    if (sortBy !== this.sortBy) {
      this.sortBy = sortBy;
      this.page = undefined;
    }
  }

  @action
  updateSortDirection(sortDirection?: SortDirection) {
    if (sortDirection !== this.sortDirection) {
      this.sortDirection = sortDirection;
      this.page = undefined;
    }
  }

  resetFilters() {
    this.dateRange =
      this.timeRange =
      this.plantAssetIds =
      this.alarmType =
      this.alarmExternalIds =
      this.recipeIds =
        undefined;
  }
}
