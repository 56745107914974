import { service } from '@ember/service';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import AvailableDataService from 'fabscale-app/services/available-data';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetsInUseOptions {
  dateRange?: DateRange;
}

export default class LoadPlantAssetsInUseResource extends BaseAsyncResource<
  PlantAsset[],
  LoadPlantAssetsInUseOptions
> {
  @service availableData: AvailableDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  loadData(options: LoadPlantAssetsInUseOptions) {
    let { dateRange } = options;

    if (!dateRange) {
      return Promise.resolve([]);
    }

    let fixedDateRange = new DateRange({
      start: dateRange.start,
      end: dateRange.end.endOf('day'),
    });

    return this.availableData.getAvailableRoasters(fixedDateRange);
  }
}
