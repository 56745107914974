import { service } from '@ember/service';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/-private/transition';
import { scrollToTop } from 'fabscale-app/utilities/utils/dom/scroll-to-top';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import RouterService from '@ember/routing/router-service';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export default class SelectLocationRoute extends Route {
  @service l10n: L10nService;
  @service router: RouterService;
  @service userAuthentication: UserAuthenticationService;

  beforeModel(transition: Transition) {
    let { userAuthentication, l10n, router } = this;

    if (!userAuthentication.isAuthenticated) {
      let url;

      try {
        url = router.urlFor(transition.to.name);
      } catch (error) {
        // Ignore
      }

      userAuthentication.cacheUrlAfterLogin(url);
      userAuthentication.logout(
        l10n.t('You need to sign in to access this page.')
      );
    }
  }

  activate() {
    scrollToTop();
  }
}
