import { assert } from '@ember/debug';

export function filterRecords<T>(
  records: T[],
  searchTerm: string,
  {
    propertyName,
    propertyNames,
  }: { propertyName?: string; propertyNames?: string[] }
): T[] {
  assert(
    `filterRecords: The first argument has to be an array`,
    Array.isArray(records)
  );
  assert(
    `filterRecords: The second argument has to be a string`,
    typeof searchTerm === 'string'
  );
  assert(
    `filterRecords: The third argument has to be an array of field names or a string`,
    Array.isArray(propertyNames) || typeof propertyName === 'string'
  );

  let search = searchTerm.toLowerCase();

  return records.filter((record) => {
    if (propertyNames) {
      return filterMultiple(record, propertyNames, search);
    }

    if (propertyName) {
      return propertyMatchesSearch(record, propertyName, search);
    }
  });
}

function filterMultiple(record: any, propertyNames: string[], search: string) {
  return propertyNames.some((propertyName) =>
    propertyMatchesSearch(record, propertyName, search)
  );
}

function propertyMatchesSearch(
  record: any,
  propertyName: string,
  search: string
) {
  const recordValue = valueForKeyPath(record, propertyName);

  return recordValue?.toLowerCase()?.includes(search);
}

function valueForKeyPath(obj: any, path: string) {
  const keys = path.split('.');
  keys.forEach((key: string) => (obj = obj[key]));

  return obj;
}
