import Controller from '@ember/controller';

export default class TableController extends Controller {
  get tableData() {
    let data = [];

    for (let i = 0; i < 10; i++) {
      data.push({
        name: `John Doe ${i}`,
        age: Math.round(Math.random() * 99),
        nickname: `Johnny ${i}`,
      });
    }

    return data;
  }

  tableColumns = [
    {
      title: 'Name',
      propertyName: 'name',
    },
    {
      title: 'Nickname',
      propertyName: 'nickname',
    },
    {
      title: 'Age',
      propertyName: 'age',
      cellType: 'numeric',
    },
  ];
}
