import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Unit, UnitTime } from 'fabscale-app/models/enums/units';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import {
  formatNumber,
  FormatNumberOptions,
} from 'fabscale-app/utilities/utils/format-number';

export default class FormatAmountHelper extends Helper<{
  PositionalArgs: [
    amount: number,
    unit: Unit,
    numberFormatOptions: FormatNumberOptions | undefined
  ];
}> {
  @service enumLabels: EnumLabelsService;

  formatTimeValue(value: number) {
    const totalMinutes = value * 60; // Convert hours to minutes
    const days = Math.floor(totalMinutes / (24 * 60));
    let hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    let minutes = Math.round(totalMinutes % 60); // Get remaining minutes

    // Adjust for rounding issues (e.g., 60 minutes becomes 1 hour)
    if (minutes === 60) {
      hours += 1; // Add the extra hour
      minutes = 0; // Reset minutes to 0
    }

    let formattedTime = '';
    if (days > 0) formattedTime += `${days} days `;
    if (hours > 0) formattedTime += `${hours} h `;
    if (minutes > 0) formattedTime += `${minutes} min`;

    return formattedTime.trim();
  }

  compute([amount, unit, numberFormatOptions]: [
    number,
    Unit,
    FormatNumberOptions | undefined
  ]) {
    let unitString = this.enumLabels.unit(unit);

    if (unit === ('HOUR' as UnitTime)) {
      return `${this.formatTimeValue(amount)}`;
    } else {
      return `${formatNumber(amount, numberFormatOptions)} ${unitString}`;
    }
  }
}
