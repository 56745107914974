import {
  DashboardCard,
  DashboardCardInput,
  DashboardConfig,
  DashboardConfigInput,
  DASHBOARD_CURRENT_VERSION,
} from 'fabscale-app/models/dashboard-config';

export function serializeDashboardConfig(
  dashboardConfig: DashboardConfig
): string {
  let json: DashboardConfigInput = {
    version: DASHBOARD_CURRENT_VERSION,
    cards: dashboardConfig.cards
      ? serializeDashboardCards(dashboardConfig.cards)
      : undefined,
    headerIsCollapsed: dashboardConfig.headerIsCollapsed || undefined,
  };

  return JSON.stringify(json);
}

export function deserializeDashboardConfig(
  dashboardConfigString: string
): DashboardConfig | undefined {
  let json: { version?: number } = JSON.parse(dashboardConfigString);

  // Old version? Just abort here
  if (!json.version || json.version < 3) {
    return undefined;
  }

  // Else, we have an up-to-date config
  let config = json as DashboardConfigInput;

  return {
    version: config.version,
    cards: config.cards ? deserializeDashboardCards(config.cards) : undefined,
    headerIsCollapsed: config.headerIsCollapsed,
  };
}

function deserializeDashboardCards(
  cards: DashboardCardInput[]
): DashboardCard[] {
  return cards.map((card, id) => {
    return Object.assign({ id }, card);
  });
}

function serializeDashboardCards(cards: DashboardCard[]): DashboardCardInput[] {
  return cards.map((card) => {
    return Object.assign({}, card, { id: undefined });
  });
}
