import { tracked } from '@glimmer/tracking';
import { DateString, DateTimeString } from 'fabscale-app';
import { TaskCategory } from 'fabscale-app/models/enums/task-categories';
import { TaskRequirement } from 'fabscale-app/models/enums/task-requirements';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import {
  deserializeDate,
  deserializeOptionalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { Comment } from './comment';
import { FileWrapper } from './file-wrapper';
import { PlantAsset } from './plant-asset';
import { PlantAssetArea } from './plant-asset-area';
import { RepeatConfig } from './repeat-config';
import { UserInfo } from './user-info';

export class MaintenanceTask {
  id: string;
  title: string;
  description: string;
  category: TaskCategory;
  estimatedDurationSeconds?: number;
  @tracked status: TaskStatus;
  requirements: TaskRequirement[];
  repeatConfig?: RepeatConfig;

  // Dates
  dueDate: DateTime;
  creationDate?: DateTime;
  lastModifiedDate?: DateTime;
  @tracked completionDate?: DateTime;

  // Relationships
  createdBy: UserInfo;
  lastModifiedBy: UserInfo;
  assignedUser?: UserInfo;
  plantAsset?: PlantAsset;
  plantAssetArea?: PlantAssetArea;
  comments: Comment[];
  files: FileWrapper[];
  previousMaintenanceTask?: MaintenanceTask;

  get isCompleted(): boolean {
    return this.status === 'COMPLETED';
  }

  constructor(options: MaintenanceTaskInput) {
    this.id = options.id;
    this.title = options.title;
    this.description = options.description || '';
    this.category = options.category;
    this.status = options.status;
    this.requirements = options.requirements || [];
    this.estimatedDurationSeconds = options.estimatedDurationSeconds;

    this.creationDate = deserializeOptionalDate(options.creationDate);
    this.lastModifiedDate = deserializeOptionalDate(options.lastModifiedDate);
    this.dueDate = deserializeDate(options.dueDate);
    this.completionDate = deserializeOptionalDate(options.completionDate);

    this.createdBy = options.createdBy;
    this.lastModifiedBy = options.lastModifiedBy;
    this.assignedUser = options.assignedUser;
    this.plantAsset = options.plantAsset;
    this.plantAssetArea = options.plantAssetArea;
    this.comments = options.comments || [];
    this.files = options.files || [];
    this.repeatConfig = options.repeatConfig;
    this.previousMaintenanceTask = options.previousMaintenanceTask;

    Object.freeze(this);
  }
}

export interface MaintenanceTaskInput {
  id: string;
  title: string;
  description?: string;
  category: TaskCategory;
  status: TaskStatus;
  estimatedDurationSeconds?: number;
  requirements?: TaskRequirement[];
  repeatConfig?: RepeatConfig;

  // Dates
  dueDate: DateString;
  creationDate?: DateTimeString;
  lastModifiedDate?: DateTimeString;
  completionDate?: DateTimeString;

  // Relationships
  createdBy: UserInfo;
  lastModifiedBy: UserInfo;
  assignedUser?: UserInfo;
  plantAsset?: PlantAsset;
  plantAssetArea?: PlantAssetArea;
  comments?: Comment[];
  files?: FileWrapper[];

  previousMaintenanceTask?: MaintenanceTask;
}
