import { GraphQLClientError } from '@ember-graphql-client/client/errors/graphql-client-error';
import { GraphQLNetworkError } from '@ember-graphql-client/client/errors/network-error';
import { AccessDeniedError } from 'fabscale-app/models/errors/access-denied-error';
import {
  getGraphqlErrorDetail,
  getGraphqlErrorType,
  GraphqlError,
} from 'fabscale-app/models/errors/graphql-error';
import { MaintenanceError } from 'fabscale-app/models/errors/maintenance-error';
import { OfflineError } from 'fabscale-app/models/errors/offline-error';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import { ServerError } from 'fabscale-app/models/errors/server-error';
import { UnauthenticatedError } from 'fabscale-app/models/errors/unauthenticated-error';
import { ResourceInUseError } from './resource-in-use-error';
import { UniqueConstraintError } from './unique-constraint-error';

export function parseGraphqlError(plainError: any) {
  if (plainError instanceof GraphQLNetworkError) {
    return new OfflineError();
  }

  let statusCode = getStatusCode(plainError);

  if (statusCode >= 502 && statusCode <= 504) {
    return new MaintenanceError();
  }

  if (statusCode === 400 || statusCode === 500) {
    return new ServerError();
  }

  if (statusCode === 401) {
    return new UnauthenticatedError();
  }

  // New error types
  let errorType = getGraphqlErrorType(plainError);
  let errorDetail = getGraphqlErrorDetail(plainError);

  if (errorType === 'PERMISSION_DENIED') {
    return new AccessDeniedError(plainError);
  }

  if (errorType === 'NOT_FOUND') {
    return new ResourceNotFoundError(plainError);
  }

  if (errorDetail === 'UNIQUE_CONSTRAINT') {
    return new UniqueConstraintError(plainError);
  }

  if (errorDetail === 'RESOURCE_IN_USE') {
    return new ResourceInUseError(plainError);
  }

  return new GraphqlError(plainError);
}

function getStatusCode(plainError: any) {
  if (plainError instanceof GraphQLClientError) {
    return plainError.httpStatus;
  }

  return 0;
}
