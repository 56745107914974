import { Locale } from 'fabscale-app/models/enums/locales';
import { DateTime } from 'luxon';

const DAY = 'dd';
const MONTH = 'mm';
const YEAR = 'yyyy';

// Returns something like: DD.MM.YYYY
export function getDatePatternString(locale?: Locale) {
  let date = DateTime.fromISO('2022-11-17');

  if (locale) {
    date.setLocale(locale);
  }

  let str = date.toLocaleString({
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });

  return str
    .replace('17', DAY)
    .replace('11', MONTH)
    .replace('2022', YEAR)
    .replace('22', YEAR);
}

export function parseDate(datePattern: string, date: DateTime) {
  let { day, month, year } = date;

  let dayStr = padNumber(day);
  let monthStr = padNumber(month);
  let yearStr = `${year}`;

  return datePattern
    .replace(DAY, dayStr)
    .replace(MONTH, monthStr)
    .replace(YEAR, yearStr);
}

export function parseDateString(datePattern: string, str: string) {
  // We either check the date format (e.g. d.M.yyyy)
  // Or alternatively, always allow ISO dates (yyyy-M-d)
  let localDateFormat = datePattern
    .replace(DAY, 'd')
    .replace(MONTH, 'M')
    .replace(YEAR, 'yyyy');

  let formats = [localDateFormat, 'yyyy-M-d'];

  for (let format of formats) {
    let date = DateTime.fromFormat(str, format);
    if (date?.isValid) {
      return date;
    }
  }

  return undefined;
}

function padNumber(num: number) {
  if (num < 10) {
    return `0${num}`;
  }

  return `${num}`;
}
