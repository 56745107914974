import { assert } from '@ember/debug';

export const classMap = {
  info: 'info-message--info',
  success: 'info-message--success',
  error: 'info-message--error',
} as const;

export default function infoMessageClassHelper(type: keyof typeof classMap) {
  assert(
    `{{info-message-class}}: given type does not exist, use one of: ${Object.keys(
      classMap
    ).join(', ')}`,
    typeof classMap[type] !== 'undefined'
  );

  return classMap[type];
}
