import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import DescriptionCell from 'fabscale-app/components/page/settings/roles/permission-select/cell/description';
import NameCell from 'fabscale-app/components/page/settings/roles/permission-select/cell/name';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { TableGetRowInfoHandler } from 'fabscale-app/helpers/data-table/get-row-info';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import ScreenService from 'fabscale-app/services/screen';
import { PermissionDefinition } from 'fabscale-app/services/store/user-role';

interface Args {
  permissions: Permission[];
  availablePermissionDefinitions: PermissionDefinition[];
  updatePermissions: (permissions: Permission[]) => void;
}

export default class PageSettingsRolesForm extends Component<Args> {
  @service l10n: L10nService;
  @service screen: ScreenService;

  @tracked searchTerm = '';

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    let columns: TableColumnDefinitionInput[] = [
      {
        propertyName: 'label',
        title: l10n.t('Name'),
        disableSorting: true,
        tdClass: 'nowrap',
        noCompactTitle: true,
        component: NameCell,
      },
    ];

    if (!this.screen.isMobile) {
      columns.push({
        propertyName: '',
        title: l10n.t('Description'),
        component: DescriptionCell,
      });
    }

    return columns;
  }

  @cached
  get filteredRows(): PermissionDefinition[] {
    let { searchTerm } = this;
    let { availablePermissionDefinitions } = this.args;

    if (!searchTerm) {
      return availablePermissionDefinitions;
    }

    let normalizedSearchTerm = searchTerm.trim().toLocaleLowerCase();
    return availablePermissionDefinitions.filter((def) => {
      if (
        this._permissionDefinitionMatchesSearchTerm(def, normalizedSearchTerm)
      ) {
        return true;
      }

      // Also show it if parent is shown
      if (def.parentPermission) {
        let parentDef = this._getPermissionDefinition(def.parentPermission);

        if (
          this._permissionDefinitionMatchesSearchTerm(
            parentDef,
            normalizedSearchTerm
          )
        ) {
          return true;
        }
      }

      // Also check if a child contains it
      return def.requiredPermissions.some((requiredPermission) => {
        let requiredDef = this._getPermissionDefinition(requiredPermission);

        return this._permissionDefinitionMatchesSearchTerm(
          requiredDef,
          normalizedSearchTerm
        );
      });
    });
  }

  get selectedPermissionDefinitions() {
    let { permissions, availablePermissionDefinitions } = this.args;

    return availablePermissionDefinitions.filter((def) =>
      permissions.includes(def.permission)
    );
  }

  @cached
  get getRowInfoHandler(): TableGetRowInfoHandler | undefined {
    let rows = this.filteredRows;

    function rowIsStriped(record: PermissionDefinition): boolean {
      let index = rows.indexOf(record);

      if (index === 0) {
        return false;
      }

      let previousRecord = rows[index - 1]!;

      let previousWasStriped = rowIsStriped(previousRecord);

      if (typeof record.parentPermission !== 'undefined') {
        return previousWasStriped;
      }

      return !previousWasStriped;
    }

    return (record: PermissionDefinition) => {
      let classes = [];

      if (rowIsStriped(record)) {
        classes.push('table__tr--striped');
      }

      if (record.requiredPermissions.length > 0) {
        classes.push('table__tr--no-bottom-border');
      }

      if (record.parentPermission) {
        classes.push('table__tr--nested');
      }

      return { class: classes.join(' ') };
    };
  }

  @action
  togglePermission(def: PermissionDefinition, isSelected: boolean) {
    let permissions = new Set(this.args.permissions);

    if (isSelected) {
      addPermission(permissions, def);
    } else {
      removePermission(permissions, def);
    }

    this.args.updatePermissions(Array.from(permissions));
  }

  @action
  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  _getPermissionDefinition(permission: Permission) {
    return this.args.availablePermissionDefinitions.find(
      (def) => def.permission === permission
    );
  }

  _permissionDefinitionMatchesSearchTerm(
    def: PermissionDefinition | undefined,
    searchTerm: string
  ) {
    return def && def.label.toLowerCase().includes(searchTerm);
  }
}

function addPermission(
  permissions: Set<Permission>,
  def: PermissionDefinition
) {
  permissions.add(def.permission);

  def.requiredPermissions.forEach((permission) => permissions.add(permission));
}

function removePermission(
  permissions: Set<Permission>,
  def: PermissionDefinition
) {
  permissions.delete(def.permission);

  def.requiredPermissions.forEach((permission) =>
    permissions.delete(permission)
  );
}
