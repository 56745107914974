import { Ability } from 'ember-can';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';

export default class UserRoleAbility extends Ability {
  @service userSession: UserSessionService;

  get canEdit() {
    return Boolean(this.userSession.permissionMap.USER_ROLE_MANAGE);
  }
}
