import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { ScaleTime, scaleTime } from 'd3-scale';
import { AxisConfigDates } from 'fabscale-app/components/chart';
import { DateRange } from 'fabscale-app/models/date-range';
import SettingsService from 'fabscale-app/services/settings';
import {
  getD3TickIntervalForDates,
  getDateAxisTickFormat,
} from 'fabscale-app/utilities/utils/chart';
import {
  getAutoDateInterval,
  getDateFormatForInterval,
} from 'fabscale-app/utilities/utils/date-interval';
import { DateTime } from 'luxon';

export default class ChartScaleDates extends Helper<{
  PositionalArgs: [dates: DateTime[], width: number];
}> {
  @service settings: SettingsService;

  compute([dates, width]: [DateTime[], number]): AxisConfigDates {
    let { startDayOfWeek } = this.settings.locationSettings;

    let minItemWidth = 42;
    let maxItemCount = Math.floor(width / minItemWidth);

    // Special case - time-only, meaning it is on Jan 1st 1970
    if (dates[0]!.hasSame(DateTime.fromMillis(0), 'day')) {
      return getChartScaleForTimesOnly({ dates, width, maxItemCount });
    }

    let scale = getScale({ width, dates });

    let interval = getAutoInterval(dates);

    let d3TimeInterval = getD3TickIntervalForDates(
      dates,
      interval,
      startDayOfWeek,
      maxItemCount
    );

    let tickFormat = getDateAxisTickFormat(getDateFormatForInterval(interval)!);

    return {
      scale,
      d3TimeInterval,
      tickFormat,
    };
  }
}

function getScale({
  width,
  dates,
}: {
  width: number;
  dates: DateTime[];
}): ScaleTime<number, number> {
  //  D3 needs native dates
  let jsDates = dates.map((date) => date.toJSDate());

  let minDate = jsDates[0]!;
  let maxDate = jsDates[jsDates.length - 1]!;

  return scaleTime().domain([minDate, maxDate]).range([0, width]);
}

function getAutoInterval(dates: DateTime[]) {
  assert('getAutoInterval expects at least one date', dates.length >= 1);
  let start = dates[0]!;
  let end = dates[dates.length - 1]!;

  let dateRange = new DateRange({ start, end });

  return getAutoDateInterval(dateRange);
}

function getChartScaleForTimesOnly({
  dates,
  width,
  maxItemCount,
}: {
  dates: DateTime[];
  width: number;
  maxItemCount: number;
}): AxisConfigDates {
  let scale = getScale({ dates, width });
  let d3TimeInterval = maxItemCount;

  let tickFormat = getDateAxisTickFormat({
    minute: '2-digit',
    second: '2-digit',
  });

  return { scale, d3TimeInterval, tickFormat };
}
