import { assert } from '@ember/debug';

export default function mapBy(array: any[], propertyName: string) {
  assert(
    `You need to pass an array to {{map-by}}, but you passed "${array}"`,
    Array.isArray(array)
  );

  assert(
    `You need to pass a propertyName to {{map-by}}, but you passed "${propertyName}"`,
    typeof propertyName === 'string'
  );

  return array.map((item) => item[propertyName]);
}
