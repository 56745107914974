import {
  KpiDataFilterTemplateConfig,
  KpiDataFilterTemplateConfigInput,
} from 'fabscale-app/models/kpi-data-filter-template-config';
import { serializeKpiDataFilterTemplate } from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-template';

export function serializeKpiDataFilterTemplateConfig(
  kpiDataFilterTemplateConfig: KpiDataFilterTemplateConfig
): string {
  let { version, templates } = kpiDataFilterTemplateConfig;

  let templatesJSON = templates.map((kpiDataFilterTemplate) =>
    serializeKpiDataFilterTemplate(kpiDataFilterTemplate)
  );

  return JSON.stringify({
    version,
    templates: templatesJSON,
  });
}

export function deserializeKpiDataFilterTemplateConfig(
  serializedString: string
): KpiDataFilterTemplateConfig {
  let json: KpiDataFilterTemplateConfigInput = JSON.parse(serializedString);

  return new KpiDataFilterTemplateConfig(json);
}
