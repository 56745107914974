import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import { logError } from 'fabscale-app/utilities/utils/log-error';

export default class PlantPlantAssetsOverviewIndex extends Component {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked triggerNewLoadCounter = 0;
  @tracked error?: string;

  deletePlantAssetTask = dropTask(async (id: string) => {
    let { plantAssetStore } = this;

    this.error = undefined;

    try {
      await plantAssetStore.delete(id);
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.triggerNewLoadCounter++;
  });

  _handleError(error: any) {
    logError(error);
    this.error = this.errorParser.getErrorMessage(error);
  }
}
