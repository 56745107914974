import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { PlantAssetAlertOverviewFilters } from 'fabscale-app/components/page/plant-asset-alerts/overview';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange } from 'fabscale-app/models/time-range';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';

export default class PlantAssetAlertsIndexController extends Controller {
  queryParams = [
    'dateRange',
    'timeRange',
    'plantAssets',
    'page',
    'pageSize',
    'sortBy',
    'sortDirection',
    'alarmLevel',
    'alarmTypes',
    'alarmExternalIds',
    'alarmTitle',
  ];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssets?: string;
  @tracked alarmLevels?: string;
  @tracked alarmTypes?: string;
  @tracked alarmExternalIds?: string;
  @tracked alarmTitle?: string;
  @tracked sortBy?: PlantAssetAlarmSortOption;
  @tracked sortDirection?: SortDirection;

  // These are deserialized from the URL as strings, so we need to ensure they are converted to numbers
  @tracked page?: number | string;
  @tracked pageSize?: number | string;

  get pageNumber() {
    return typeof this.page === 'string' ? parseInt(this.page) : this.page;
  }

  get pageSizeNumber() {
    return typeof this.pageSize === 'string'
      ? parseInt(this.pageSize)
      : this.pageSize;
  }

  get parsedDateRange(): DateRangeOptional | undefined {
    const { dateRange } = this;

    return dateRange ? deserializeDateRange(dateRange) : undefined;
  }

  get parsedTimeRange(): TimeRange | undefined {
    const { timeRange } = this;

    return timeRange ? JSON.parse(timeRange) : undefined;
  }

  get plantAssetIds(): string[] | undefined {
    const { plantAssets } = this;

    return plantAssets ? deserializeArray(plantAssets) : undefined;
  }

  get parsedAlarmExternalIds(): string[] | undefined {
    const { alarmExternalIds } = this;

    return alarmExternalIds ? deserializeArray(alarmExternalIds) : undefined;
  }

  get parsedAlarmTypes(): PlantAssetAlarmType[] | undefined {
    const { alarmTypes } = this;

    return alarmTypes ? deserializeArray(alarmTypes) : undefined;
  }

  get parsedAlarmLevels(): PlantAssetAlertLevel[] | undefined {
    const { alarmLevels } = this;

    return alarmLevels ? deserializeArray(alarmLevels) : undefined;
  }

  get parsedAlarmTitle(): string | undefined {
    const { alarmTitle } = this;
    return alarmTitle;
  }

  get filters(): PlantAssetAlertOverviewFilters {
    const {
      parsedDateRange: dateRange,
      parsedTimeRange: timeRange,
      plantAssetIds,
      parsedAlarmLevels: alarmLevels,
      parsedAlarmTypes: alarmTypes,
      parsedAlarmExternalIds: alarmExternalIds,
      parsedAlarmTitle: alarmTitle,
    } = this;

    return {
      dateRange,
      timeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    };
  }

  @action
  updateFilters(filters: PlantAssetAlertOverviewFilters) {
    const {
      dateRange,
      timeRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    } = filters;

    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssets =
      plantAssetIds && plantAssetIds.length > 0
        ? serializeArray(plantAssetIds)
        : undefined;

    this.page = undefined;
    this.alarmLevels = alarmLevels?.length
      ? serializeArray(alarmLevels)
      : undefined;
    this.alarmTypes = alarmTypes?.length
      ? serializeArray(alarmTypes)
      : undefined;
    this.alarmExternalIds = alarmExternalIds?.length
      ? serializeArray(alarmExternalIds)
      : undefined;

    this.alarmTitle = alarmTitle?.length ? alarmTitle : '';
  }

  @action
  updatePage(page?: number) {
    if (page !== this.page) {
      this.page = page;
    }
  }

  @action
  updatePageSize(pageSize?: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.page = undefined;
    }
  }

  @action
  updateSortBy(sortBy?: PlantAssetAlarmSortOption) {
    if (sortBy !== this.sortBy) {
      this.sortBy = sortBy;
      this.page = undefined;
    }
  }

  @action
  updateSortDirection(sortDirection?: SortDirection) {
    if (sortDirection !== this.sortDirection) {
      this.sortDirection = sortDirection;
      this.page = undefined;
    }
  }
}
