import transition from '@ember/routing/transition';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import DashboardCustomizeController from './controller';

export default class DashboardCustomizeIndexRoute extends AuthenticatedRoute {
  resetController(controller: DashboardCustomizeController): void {
    controller.newCardId = undefined;
  }

  setupController(
    controller: DashboardCustomizeController,
    model: unknown,
    transition: transition<unknown>
  ): void {
    // On initial load, make sure we clear any newCardId from the URL
    // As the IDs are not stable across reloads
    if (!transition.from) {
      controller.newCardId = undefined;
    }
  }
}
