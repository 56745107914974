import {
  FileExtensionsExcel,
  FileExtensionsImage,
  FileExtensionsVideo,
  FileExtensionsWord,
} from 'fabscale-app/models/enums/file-extension';

export default function fileTypeIcon(extension: string) {
  if (FileExtensionsImage.includes(extension)) {
    return 'image';
  }

  if (FileExtensionsVideo.includes(extension)) {
    return 'video';
  }

  if (FileExtensionsExcel.includes(extension)) {
    return 'file-excel';
  }

  if (FileExtensionsWord.includes(extension)) {
    return 'file-word';
  }

  if (extension === 'pdf') {
    return 'file-pdf';
  }

  // Fallback
  return 'file';
}
