export class UserInfo {
  id: string;
  name: string;

  constructor(options: UserInfoInput) {
    this.id = options.id;
    this.name = options.name;
  }
}

export interface UserInfoInput {
  id: string;
  name: string;
}
