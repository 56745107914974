import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { Location } from 'fabscale-app/models/location';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import BaseAsyncResource from './base-async-resource';

interface LoadKpiDataPerLocationOptions {
  kpiTypes: KpiType[];
  dateRange: DateRange;
  locations: Location[];
  unitSystem?: UnitSystem;
}

export default class LoadKpiDataPerLocationResource extends BaseAsyncResource<
  KpiDataGrouped[],
  LoadKpiDataPerLocationOptions
> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData({
    kpiTypes,
    dateRange,
    locations,
    unitSystem,
  }: LoadKpiDataPerLocationOptions) {
    assert(
      `{{resources/load-kpi-data-per-location}}: You have to provide a list of locations`,
      !!locations?.length
    );

    return await this.kpiDataStore.findMultiplePerLocation(
      kpiTypes,
      locations,
      {
        dateRange,
        unitSystem,
      }
    );
  }
}
