import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export type DeviceType = 'IOS' | 'ANDROID' | 'OTHER';

export default class SettingsSecurityMfaSetupEnableMfa extends Component {
  @tracked selectedDeviceType?: DeviceType;

  @action
  updateSelectedDeviceType(deviceType?: DeviceType) {
    this.selectedDeviceType = deviceType;
  }
}
