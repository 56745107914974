import { BarChartDataInput } from 'fabscale-app/components/chart';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';

export default function kpiDataGroupedToBarChartData(
  kpiDataGrouped: KpiDataGrouped,
  { color, exactDateRange }: { color?: string; exactDateRange: DateRange }
): BarChartDataInput[] {
  if (!kpiDataGrouped) {
    return [];
  }

  let { unit, type } = kpiDataGrouped;

  return kpiDataGrouped.values.map((item) => {
    let { name, value } = item;

    let groupedKpiItem: {
      label: string;
      value: number;
      unit: Unit;
      type: KpiType;
      dateRange: DateRange;
      color?: string;
    } = {
      label: name,
      value,
      unit,
      type,
      dateRange: exactDateRange,
    };

    if (typeof color === 'string') {
      groupedKpiItem.color = color;
    }

    return groupedKpiItem;
  });
}
