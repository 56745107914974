import { service } from '@ember/service';
import { DashboardCard } from 'fabscale-app/models/dashboard-config';
import DashboardConfigManagerService from 'fabscale-app/services/dashboard-config-manager';
import BaseAsyncResource from './base-async-resource';

export default class LoadDashboardCardsResource extends BaseAsyncResource<
  { cards: DashboardCard[]; isDefault: boolean },
  any
> {
  @service dashboardConfigManager: DashboardConfigManagerService;

  async loadData() {
    let { userFrontendSettings } = this.dashboardConfigManager;
    let { dashboardConfig } = userFrontendSettings;

    if (dashboardConfig?.cards) {
      return { cards: dashboardConfig.cards, isDefault: false };
    }

    let cards = await this.dashboardConfigManager.getDefaultDashboardCards();

    return { cards, isDefault: true };
  }
}
