import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import { uniq } from 'fabscale-app/utilities/utils/array';
import { ScaleTime } from 'd3-scale';
import { LineChartData } from 'fabscale-app/components/chart';

interface Args {
  height: number;
  width: number;
  xScale: ScaleTime<Date, number>;
  data: LineChartData[];
  updateTooltip: (items?: LineChartData[]) => void;
}

export default class ChartLineChartHoverOverlay extends Component<Args> {
  @action
  getIdentifierForX(x: number): string {
    let { xScale, data } = this.args;

    let jsDate = xScale.invert(x);
    let date = DateTime.fromJSDate(jsDate);

    // Find the closest matching data item
    let dates = uniq(data.map((item) => item.date));

    let dateAfter =
      dates.find((filterDate) => filterDate >= date) ||
      dates[dates.length - 1]!;

    let dateBefore =
      +dateAfter === +date ? dateAfter : dates[dates.indexOf(dateAfter) - 1];

    if (!dateBefore) {
      return dateAfter.toISO();
    }

    let closestDate =
      +date - +dateBefore > +dateAfter - +date ? dateAfter : dateBefore;

    return closestDate?.toISO();
  }

  @action
  updateTooltip(identifier?: string) {
    if (!identifier) {
      this.args.updateTooltip(undefined);
      return;
    }

    let date = DateTime.fromISO(identifier);
    let items = this.args.data.filter((item) => +item.date === +date);
    this.args.updateTooltip(items);
  }
}
