import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateString } from 'fabscale-app';
import {
  deserializeDate,
  serializeOptionalLocalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export type PlantAssetDetailMode = 'AREAS' | 'STATUS';

export default class StatusMapController extends Controller {
  @tracked date?: DateString;
  @tracked plantAssetId?: string;

  queryParams = ['date', 'plantAssetId'];

  get actualDate() {
    let { date } = this;

    return date ? deserializeDate(date) : DateTime.local().startOf('day');
  }

  @action
  updateDate(date: DateTime | undefined) {
    this.date = serializeOptionalLocalDate(date);
  }

  @action
  updatePlantAssetId(plantAssetId: string) {
    this.plantAssetId = plantAssetId;
  }
}
