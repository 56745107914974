interface TableConfigItem {
  id: string;
  data: any;
}

export default class TableConfig {
  _config: TableConfigItem[];

  constructor(tableConfig?: string) {
    let json = tableConfig
      ? (JSON.parse(tableConfig) as TableConfigItem[])
      : [];

    this._config = json;
  }

  update(tableId: string, data: any) {
    let config = this._config.find((configItem) => configItem.id === tableId);
    if (config) {
      config.data = data;
    } else {
      this._config.push({ id: tableId, data });
    }
  }

  dataForTableId(tableId: string) {
    let config = this._config.find((configItem) => configItem.id === tableId);

    return config?.data;
  }

  toJSON() {
    return JSON.stringify(this._config);
  }
}
