import Service, { service } from '@ember/service';
import { GraphqlError } from 'fabscale-app/models/errors/graphql-error';
import { MaintenanceError } from 'fabscale-app/models/errors/maintenance-error';
import { OfflineError } from 'fabscale-app/models/errors/offline-error';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import { AccessDeniedError } from 'fabscale-app/models/errors/access-denied-error';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import { ServerError } from 'fabscale-app/models/errors/server-error';
import { UnauthenticatedError } from 'fabscale-app/models/errors/unauthenticated-error';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { ResourceInUseError } from 'fabscale-app/models/errors/resource-in-use-error';
import { UniqueConstraintError } from 'fabscale-app/models/errors/unique-constraint-error';

export default class ErrorParserService extends Service {
  @service l10n: L10nService;

  getErrorMessage(error: any): string {
    let { l10n } = this;

    if (error instanceof MaintenanceError) {
      return l10n.t(
        'We are currently conducting maintenance on our server. We will be back soon!'
      );
    }

    if (error instanceof OfflineError) {
      return l10n.t('You appear to be offline.');
    }

    if (error instanceof PermissionError) {
      return (
        error.translatedMessage ||
        l10n.t('You do not have permission for this operation.')
      );
    }

    if (error instanceof ResourceNotFoundError) {
      return (
        error.translatedMessage || l10n.t('The resource could not be found.')
      );
    }

    if (error instanceof UnauthenticatedError) {
      return l10n.t('You are not signed in - maybe your session has expired.');
    }

    if (error instanceof AccessDeniedError) {
      return l10n.t(
        'You do not have access to this resource. Maybe it belongs to a different company, or you lack permission to access it.'
      );
    }

    if (error instanceof ServerError) {
      return l10n.t(
        'An unexpected error occurred on the server. Please contact us at support@fabscale.com if that keeps happening.'
      );
    }

    if (error instanceof UniqueConstraintError) {
      return l10n.t(
        'The action could not be completed because of a unique constraint.'
      );
    }

    if (error instanceof ResourceInUseError) {
      let { translatedMessage } = error;

      if (translatedMessage) {
        return translatedMessage;
      }

      return l10n.t(
        'The action could not be completed because the resource is still in use.'
      );
    }

    if (!(error instanceof GraphqlError)) {
      return error.toString();
    }

    if (Array.isArray(error.errors) && error.errors.length > 0) {
      return error.errors.map((item) => item.message).join('\n\n');
    }

    // @ts-ignore
    return error.message || error;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'error-parser': ErrorParserService;
  }
}
