import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import { timeout } from 'ember-concurrency';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export default class LogoutRoute extends Route {
  @service userAuthentication: UserAuthenticationService;

  async activate() {
    // Ensure previous page has been completely torn down before we actually log the user out
    await timeout(1);
    await this.userAuthentication.logout();

    // Reload the page to make sure all traces of the old user are gone
    // At this point, we have already been redirected to the login
    if (macroCondition(isTesting())) {
      return;
    }

    window.location.reload();
  }
}
