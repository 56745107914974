import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

// We do not extend from the base-authenticated-route here, as we do not want to show an info message in this case
export default class IndexRoute extends Route {
  @service userAuthentication: UserAuthenticationService;
  @service router: RouterService;

  beforeModel() {
    let { userAuthentication } = this;

    if (!userAuthentication.isAuthenticated) {
      userAuthentication.logout();
      return;
    }

    this.router.replaceWith('routes/dashboard');
  }
}
