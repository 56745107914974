import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import CellId from 'fabscale-app/components/page/roast-pic/table/cell/id';
import CellCoffeeType from 'fabscale-app/components/page/roast-pic/table/cell/coffee-type';
import CellCoffeeSize from 'fabscale-app/components/page/roast-pic/table/cell/coffee-size';
import CellCoffeeColor from 'fabscale-app/components/page/roast-pic/table/cell/coffee-color';
import CellCoffeeDefect from 'fabscale-app/components/page/roast-pic/table/cell/coffee-defect';
import CellActions from 'fabscale-app/components/page/roast-pic/table/cell/actions';

import { action } from '@ember/object';

const DEFAULT_SORT_BY = 'ID';
const DEFAULT_SORT_DIRECTION = 'ASC';

interface Args {
  page?: number;
  pageSize?: number;
  sortBy?: RoastpicSortOption;
  sortDirection?: SortDirection;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: RoastpicSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageRoastPicTable extends Component<Args> {
  @service l10n: L10nService;

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  get sortBy() {
    return this.args.sortBy || DEFAULT_SORT_BY;
  }

  get sortDirection() {
    return this.args.sortDirection || DEFAULT_SORT_DIRECTION;
  }

  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    let cols: TableColumnDefinitionInput[] = [
      {
        propertyName: 'id',
        title: l10n.t('ID'),
        component: CellId,
        thClass: 'nowrap',
        tdClass: 'nowrap',
        noCompactTitle: true,
        sortBy: 'ID',
      },
      {
        propertyName: 'createdDate',
        title: l10n.t('Date'),
        sortBy: 'CREATED_DATE',
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
      },
      {
        propertyName: 'lotNumber',
        title: l10n.t('Lot number'),
        sortBy: 'LOT_NUMBER',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'roastBatchNumber',
        title: l10n.t('Roast batch number'),
        sortBy: 'ROAST_BATCH_NUMBER',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'coffeeType',
        title: l10n.t('Coffee type'),
        sortBy: 'COFFEE_TYPE',
        component: CellCoffeeType,
      },
      {
        propertyName: 'coffeeVariety',
        title: l10n.t('Coffee variety'),
        sortBy: 'COFFEE_VARIETY',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'coffeeSize',
        title: l10n.t('Size'),
        thClass: 'nowrap',
        tdClass: 'nowrap',
        sortBy: 'COFFEE_SIZE',
        component: CellCoffeeSize,
      },
      {
        propertyName: 'coffeeColor',
        title: l10n.t('Color'),
        thClass: 'nowrap',
        tdClass: 'nowrap',
        sortBy: 'COFFEE_COLOR',
        component: CellCoffeeColor,
      },
      {
        propertyName: 'coffeeColorDeviation',
        title: l10n.t('Defect'),
        sortBy: 'NUMBER_DEFECTS',
        thClass: 'nowrap',
        tdClass: 'nowrap',
        component: CellCoffeeDefect,
      },
    ];

    cols.push({
      propertyName: '',
      title: '',
      component: CellActions,
    });

    return cols;
  }

  @action
  updateSort(sortBy: RoastpicSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
    }
  }
}
