import type { DateString, DateTimeString } from 'fabscale-app';
import { DateTime } from 'luxon';

export function serializeDate(date: DateTime): DateTimeString {
  return date.toISO() as DateTimeString;
}

export function deserializeDate(
  isoString: DateTimeString | DateString
): DateTime {
  return DateTime.fromISO(isoString);
}

export function serializeOptionalDate(
  date: DateTime | undefined | null
): DateTimeString | undefined {
  if (!date) {
    return undefined;
  }

  return serializeDate(date);
}

export function deserializeOptionalDate(
  isoString: DateTimeString | DateString | undefined | null
): DateTime | undefined {
  if (!isoString) {
    return undefined;
  }

  let date = deserializeDate(isoString);

  if (!date.isValid) {
    throw new Error(`date "${isoString}" is invalid: ${date.invalidReason}`);
  }

  return date;
}

export function serializeLocalDate(date: DateTime): DateString {
  return date.toISODate() as DateString;
}

export function serializeOptionalLocalDate(
  date: DateTime | undefined | null
): DateString | undefined {
  if (!date) {
    return undefined;
  }

  return serializeLocalDate(date);
}

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export function isValidLocalDateString(
  dateStr: DateTimeString | string
): dateStr is DateString {
  return !!(dateStr as string).match(DATE_REGEX);
}
