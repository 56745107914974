import { ChartConfiguration, ChartData, ChartOptions, Plugin } from 'chart.js';

export class BarChart {
  private _config: ChartConfiguration;

  constructor(data: ChartData, options?: ChartOptions, plugins?: Plugin[]) {
    this._config = {
      type: 'bar',
      data: data,
      options: options,
      plugins: plugins,
    };
  }

  get config() {
    return this._config;
  }
}
