import { Ability } from 'ember-can';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';

export default class CompanyAbility extends Ability {
  @service userSession: UserSessionService;

  get canEdit() {
    return Boolean(this.userSession.permissionMap.COMPANY_MANAGE);
  }

  get canHaveMultipleLocations() {
    return (
      !!this.userSession.company &&
      this.userSession.company.locationIds.length > 1
    );
  }
}
