import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import MaintenanceTasksShowIndexController from './controller';

export default class MaintenanceTasksShowIndexRoute extends AuthenticatedRoute {
  resetController(
    controller: MaintenanceTasksShowIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.details = undefined;
    }
  }
}
