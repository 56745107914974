import { action } from '@ember/object';
import { assert } from '@ember/debug';
import { ChartConfiguration, ChartData, Plugin, ChartOptions } from 'chart.js';
import Chart from 'chart.js/auto';
import Component from '@glimmer/component';
import { StandardDoughtnutChart } from 'fabscale-app/models/doughnut-chart';

interface Args {
  id: string;
  data: ChartData;
  options?: ChartOptions;
  plugins?: Plugin[];
}

export default class ChartDoughnutChart extends Component<Args> {
  @action
  initChart() {
    const { id, data, options, plugins } = this.args;
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
      document.getElementById(`oee-doughnut-${id}`)
    );
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    assert(`2d context not supported or canvas already initialized`, !!ctx);

    const config: ChartConfiguration = new StandardDoughtnutChart(
      data,
      options,
      plugins
    ).config;

    new Chart(ctx, config);
  }
}
