import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { Unit } from 'fabscale-app/models/enums/units';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import CellPercentageBar from 'fabscale-app/components/page/kpi-details-report/per-recipe/table/cell/percentage-bar';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';

interface Args {
  data: KpiDataGrouped;
  unit: Unit;
  selectedRecipesWithColor: { id: string; color: string }[];
  updateSelectedRecipeIds: (ids: string[]) => void;
}

interface TableRecord {
  isSelected: boolean;
  color: string | null;
  id: string;
  name: string;
  value: number;
  percentage: number;
}

export default class KpiDetailsReportPerRecipeTable extends Component<Args> {
  @service l10n: L10nService;

  @tracked searchTerm?: string;

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;
    let { unit } = this.args;

    return [
      {
        title: '',
        propertyName: '',
        cellType: 'TOGGLE_CHECKBOX',
        tdClass: 'print-hide',
        thClass: 'print-hide',
      },
      {
        title: l10n.t('Name'),
        propertyName: 'name',
        tdClass: 'nowrap',
      },
      {
        title: '',
        propertyName: '',
        component: CellPercentageBar,
        tdClass: 'width-100p mobile-display-none',
        thClass: 'mobile-display-none',
      },
      {
        title: l10n.t('Amount'),
        propertyName: 'value',
        cellType: unit ? 'AMOUNT' : 'NUMERIC',
        cellData: { unit },
      },
    ];
  }

  get maxValue() {
    let { data } = this.args;

    let values = data.values.map((item) => item.value);
    return Math.max(...values);
  }

  get selectedRecipeIds() {
    return this.args.selectedRecipesWithColor.map((item) => item.id);
  }

  @cached
  get tableData() {
    let { data } = this.args;
    let { maxValue } = this;

    return data.values.map((item) => {
      let { value, name, id } = item;

      let percentage = (value / maxValue) * 100;

      return { name, value, percentage, id };
    });
  }

  @cached
  get records(): TableRecord[] {
    let { tableData } = this;
    let { selectedRecipesWithColor } = this.args;

    return tableData.map((record) => {
      let selectedConfig = selectedRecipesWithColor.find(
        (item) => item.id === record.id
      );

      return Object.assign(
        {
          isSelected: !!selectedConfig,
          color: selectedConfig ? selectedConfig.color : null,
        },
        record
      );
    });
  }

  get filteredRecords() {
    let { records, searchTerm } = this;

    if (!searchTerm) {
      return records;
    }

    return filterRecords(records, searchTerm, { propertyName: 'name' });
  }

  get selectedRecords() {
    return this.records.filter((item) => item.isSelected);
  }

  @action
  toggleRecord(
    record: TableRecord,
    isSelected: boolean,
    { inRangeRecords }: { inRangeRecords?: TableRecord[] }
  ) {
    let selectedRecipeIds = this.selectedRecipeIds.slice();

    // These are records in range when holding shift while selecting rows
    if (inRangeRecords && inRangeRecords.length > 0) {
      inRangeRecords.forEach((record) => {
        this._toggleRecord(selectedRecipeIds, record, isSelected);
      });
    } else {
      this._toggleRecord(selectedRecipeIds, record, isSelected);
    }

    this.args.updateSelectedRecipeIds(selectedRecipeIds);
  }

  _toggleRecord(
    selectedRecipeIds: string[],
    record: TableRecord,
    isSelected: boolean
  ) {
    if (isSelected) {
      selectedRecipeIds.push(record.id);
    } else {
      removeItem(selectedRecipeIds, record.id);
    }
  }

  @action
  filterData(searchTerm: string) {
    this.searchTerm = searchTerm;
  }
}
