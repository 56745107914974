import Component from '@glimmer/component';

interface Args {
  value?: any;
  matchValueById?: boolean;
  options?: any[];
  fieldOptions?: any;
  isMultiSelect: boolean;
  onSearch: (search: string, ...rest: any) => any[];
}

export default class FormSelectComponentWrapper extends Component<Args> {
  get selectedValue() {
    let { value, matchValueById, options, isMultiSelect } = this.args;

    if (!matchValueById || !value) {
      return value;
    }

    if (!options?.length) {
      return value;
    }

    return isMultiSelect
      ? matchManyByIds(options, value)
      : matchById(options, value);
  }
}

function matchById(options: any[], id: string) {
  let match = options.find((item: { id: string }) => item.id === id);

  return match || undefined;
}

function matchManyByIds(options: any[], ids: string[]) {
  return options.filter((item: { id: string }) => ids.includes(item.id));
}
