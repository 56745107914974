import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import { PlantAssetAreaType } from 'fabscale-app/models/enums/plant-asset-area-types';
import { PlantAssetType } from 'fabscale-app/models/enums/plant-asset-types';
import { RecipeIdentificationStrategy } from 'fabscale-app/models/enums/recipe-identification-strategies';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import mutationCreatePlantAssetArea from 'fabscale-app/gql/mutations/create-plant-asset-area.graphql';
import mutationCreatePlantAsset from 'fabscale-app/gql/mutations/create-plant-asset.graphql';
import mutationDeletePlantAssetArea from 'fabscale-app/gql/mutations/delete-plant-asset-area.graphql';
import mutationDeletePlantAsset from 'fabscale-app/gql/mutations/delete-plant-asset.graphql';
import mutationUpdatePlantAssetArea from 'fabscale-app/gql/mutations/update-plant-asset-area.graphql';
import mutationUpdatePlantAsset from 'fabscale-app/gql/mutations/update-plant-asset.graphql';
import queryFindAreaById from 'fabscale-app/gql/queries/plant-asset-area-by-id.graphql';
import queryFindById from 'fabscale-app/gql/queries/plant-asset-by-id.graphql';
import queryPlantAssetsList from 'fabscale-app/gql/queries/plant-assets-list.graphql';
import queryPlantAssetModelsList from 'fabscale-app/gql/queries/plant-asset-models-list.graphql';
import { PlantAsset, PlantAssetFilters } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import {
  PlantAssetAreaPojo,
  PlantAssetPojo,
  transformPlantAsset,
  transformPlantAssetArea,
} from 'fabscale-app/utilities/utils/transform-plant-assets';
import GraphQLService from '../-graphql';
import UserSessionService from '../user-session';
import { PlantAssetModel } from 'fabscale-app/models/plant-asset-model';

export default class StorePlantAssetService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  // METHODS

  async findAvailableModels(): Promise<PlantAssetModel[]> {
    let { graphql } = this;

    let plantAssetModels: PlantAssetModel[] = await graphql.query(
      {
        query: queryPlantAssetModelsList,
        namespace: 'plantAssetModelsList',
      },
      {
        cacheEntity: 'Other',
      }
    );

    return plantAssetModels;
  }

  async findById(id: string): Promise<PlantAsset> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let response: PlantAssetPojo = await graphql.query(
        { query: queryFindById, variables, namespace: 'plantAssetById' },
        {
          cacheSeconds: 300,
          cacheEntity: 'PlantAsset',
          cacheId: id,
        }
      );

      return transformPlantAsset(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The plant asset with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async findAll(
    {
      hasStatusInfo,
    }: {
      hasStatusInfo?: boolean;
    },
    {
      type,
    }: {
      type?: PlantAssetType;
    }
  ): Promise<PlantAsset[]> {
    let { graphql, locationId } = this;

    let filters: PlantAssetFilters = {
      locationId,
      hasStatusInfo,
      type,
    };

    let variables = {
      filters,
    };

    let plantAssets: PlantAssetPojo[] = await graphql.query(
      {
        query: queryPlantAssetsList,
        variables,
        namespace: 'plantAssetsList',
      },
      {
        cacheSeconds: 300,
        cacheEntity: 'PlantAsset',
      }
    );

    return sortBy(
      plantAssets.map((plantAsset) => transformPlantAsset(plantAsset)),
      'name'
    );
  }

  async create(input: {
    name: string;
    type: PlantAssetType;
    modelIdentifier?: string;
    year?: string;
    referenceNumber?: string;
    displayColor?: string;
    position?: string;
    idPerLocation?: string;
    recipeIdentificationStrategy?: RecipeIdentificationStrategy;
  }): Promise<{ id: string }> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      input,
    };

    let { id } = await graphql.mutate(
      {
        mutation: mutationCreatePlantAsset,
        variables,
        namespace: 'createPlantAsset',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAsset',
          },
        ],
      }
    );

    return { id };
  }

  async update(
    id: string,
    input: {
      name: string;
      type: PlantAssetType;
      modelIdentifier?: string;
      year?: string;
      referenceNumber?: string;
      displayColor?: string;
      position?: string;
      idPerLocation?: string;
      recipeIdentificationStrategy?: RecipeIdentificationStrategy;
    }
  ): Promise<{ id: string }> {
    let { graphql } = this;

    let variables = {
      id,
      input,
    };

    await graphql.mutate(
      {
        mutation: mutationUpdatePlantAsset,
        variables,
        namespace: 'updatePlantAsset',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAsset',
          },
          { cacheEntity: 'PlantAsset', cacheId: id },
        ],
      }
    );

    return { id };
  }

  async delete(id: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate(
      {
        mutation: mutationDeletePlantAsset,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAsset',
          },
          { cacheEntity: 'PlantAsset', cacheId: id },
        ],
      }
    );
  }

  // Areas
  async findAreaById(id: string): Promise<PlantAssetArea> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let response: PlantAssetAreaPojo = await graphql.query(
        {
          query: queryFindAreaById,
          variables,
          namespace: 'plantAssetAreaById',
        },
        {
          cacheSeconds: 300,
          cacheEntity: 'PlantAssetArea',
          cacheId: id,
        }
      );

      return transformPlantAssetArea(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The plant asset area with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async createArea(
    input: {
      name: string;
      type: PlantAssetAreaType;
      description?: string;
    },
    { plantAssetId }: { plantAssetId: string }
  ): Promise<{ id: string }> {
    let { graphql } = this;

    let variables = {
      plantAssetId,
      input,
    };

    let { id } = await graphql.mutate(
      {
        mutation: mutationCreatePlantAssetArea,
        variables,
        namespace: 'createPlantAssetArea',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAsset',
            cacheId: plantAssetId,
          },
          { cacheEntity: 'PlantAssetArea' },
        ],
      }
    );

    return { id };
  }

  async updateArea(
    id: string,
    input: {
      name: string;
      type: PlantAssetAreaType;
      description?: string;
    },
    { plantAssetId }: { plantAssetId: string }
  ): Promise<{ id: string }> {
    let { graphql } = this;

    let variables = {
      id,
      input,
    };

    await graphql.mutate(
      {
        mutation: mutationUpdatePlantAssetArea,
        variables,
        namespace: 'updatePlantAssetCArea',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAsset',
            cacheId: plantAssetId,
          },
          { cacheEntity: 'PlantAssetArea', cacheId: id },
        ],
      }
    );

    return { id };
  }

  async deleteArea(
    id: string,
    { plantAssetId }: { plantAssetId: string }
  ): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate(
      {
        mutation: mutationDeletePlantAssetArea,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'PlantAsset',
            cacheId: plantAssetId,
          },
          { cacheEntity: 'PlantAssetArea' },
          { cacheEntity: 'PlantAssetArea', cacheId: id },
        ],
      }
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/plant-asset': StorePlantAssetService;
  }
}
