import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';

export default function prettifyParameterType(
  parameterType: NumericRoastBatchParameterType
): string {
  let prettifiedParameterType = parameterType
    .replaceAll('_', ' ')
    .toLowerCase();

  return (
    prettifiedParameterType.charAt(0).toUpperCase() +
    prettifiedParameterType.slice(1)
  );
}
