import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import UserSessionService from 'fabscale-app/services/user-session';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { MaintenanceTaskFiltersSerializable } from './filters';

interface Args {
  filters: null | MaintenanceTaskFiltersSerializable;
}

export default class MaintenanceTasksCompletedTasks extends Component<Args> {
  @service l10n: L10nService;
  @service userSession: UserSessionService;

  @cached
  get quickOptions(): {
    label: string;
    filter: null | MaintenanceTaskFiltersSerializable;
  }[] {
    return [
      { label: this.l10n.t('All completed tasks'), filter: null },
      {
        label: this.l10n.t('My completed tasks'),
        filter: { assignedUserIds: [this.userSession.user!.id] },
      },
      {
        label: this.l10n.t('Completed this week'),
        filter: {
          dateFrom: serializeDate(DateTime.local().startOf('week')),
          dateTo: serializeDate(DateTime.local().endOf('week')),
        },
      },
      {
        label: this.l10n.t('Completed last week'),
        filter: {
          dateFrom: serializeDate(
            DateTime.local().minus({ weeks: 1 }).startOf('week')
          ),

          dateTo: serializeDate(DateTime.local().minus({ weeks: 1 })),
        },
      },
      {
        label: this.l10n.t('Completed this month'),
        filter: {
          dateFrom: serializeDate(DateTime.local().startOf('month')),
          dateTo: serializeDate(DateTime.local().endOf('week')),
        },
      },
      {
        label: this.l10n.t('Completed last month'),
        filter: {
          dateFrom: serializeDate(
            DateTime.local().minus({ months: 1 }).startOf('month')
          ),

          dateTo: serializeDate(
            DateTime.local().minus({ months: 1 }).endOf('month')
          ),
        },
      },
    ];
  }
}
