import AnalyticsWriteService from '@fabscale/ember-fabscale-analytics/services/analytics-write';
import { service } from '@ember/service';
import { parseVersionNumeric } from 'fabscale-app/utilities/utils/parse-version-numeric';
import config from 'fabscale-app/config/environment';
import UserSessionService from 'fabscale-app/services/user-session';
import ScreenService from 'fabscale-app/services/screen';
import type RouterService from '@ember/routing/router-service';
import { getOwnConfig, macroCondition } from '@embroider/macros';
import {
  AnalyticsNumericProperty,
  AnalyticsStringProperty,
} from '@fabscale/ember-fabscale-analytics';

export default class AnalyticsService extends AnalyticsWriteService {
  @service userSession: UserSessionService;
  @service screen: ScreenService;
  @service router: RouterService;

  staticDefaultStringProperties: AnalyticsStringProperty[];
  staticDefaultNumericProperties: AnalyticsNumericProperty[];

  constructor(owner: any) {
    super(owner);

    let { version } = config;
    let versionNumeric = parseVersionNumeric(version);

    this.staticDefaultStringProperties = [{ name: 'version', value: version }];
    this.staticDefaultNumericProperties = [
      { name: 'versionNumeric', value: versionNumeric },
    ];
  }

  get defaultStringProperties(): AnalyticsStringProperty[] {
    let { userSession, router, staticDefaultStringProperties, screen } = this;
    let { user, currentLocation: location, company } = userSession;

    let properties = staticDefaultStringProperties.concat([
      {
        name: 'currentPage',
        value: router.currentRouteName,
      },
      {
        name: 'screenWidthCategory',
        value: screen.widthCategory,
      },
    ]);

    if (user) {
      properties.push({ name: 'userId', value: `${user.id}` });
      properties.push({ name: 'userEmail', value: user.email });
    }

    if (company) {
      properties.push({ name: 'companyId', value: `${company.id}` });
      properties.push({ name: 'companyName', value: company.name });
    }

    if (location) {
      properties.push({ name: 'locationId', value: `${location.id}` });
      properties.push({ name: 'locationName', value: location.name });
    }

    return properties;
  }

  get defaultNumericProperties(): AnalyticsNumericProperty[] {
    let { screen, staticDefaultNumericProperties } = this;

    let properties = staticDefaultNumericProperties.concat([
      { name: 'screenWidth', value: screen.width },
    ]);

    return properties;
  }

  trackAllPageViews(): void {
    this.router.on('routeDidChange', (transition) => {
      let { to: toRouteInfo, from: fromRouteInfo } = transition;
      let { name: routeName, queryParams } = toRouteInfo;
      let { name: previousRouteName } = fromRouteInfo || {};

      this._trackPageView({
        routeName,
        previousRouteName,
        queryParams,
        url: window.location.href,
      });
    });
  }

  _trackPageView({
    routeName,
    previousRouteName,
    queryParams,
    url,
  }: {
    routeName: string;
    previousRouteName?: string;
    queryParams: { [key: string]: any };
    url: string;
  }): void {
    this.addEvent(
      {
        name: 'page-view',
      },
      [
        { name: 'page', value: routeName },
        { name: 'previousPage', value: previousRouteName || '' },
        { name: 'queryParams', value: JSON.stringify(queryParams) },
        { name: 'url', value: url },
      ]
    );
  }

  get cannotWrite(): boolean {
    if (
      macroCondition(
        getOwnConfig<any>().mockGraphqlApi || getOwnConfig<any>().adminMode
      )
    ) {
      return true;
    }

    return super.cannotWrite;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
