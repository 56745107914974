import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { KpiType, KpiTypes } from 'fabscale-app/models/enums/kpi-types';
import {
  BooleanRoastBatchParameterType,
  NumericCurveRoastBatchParameterType,
  NumericCurveRoastBatchParameterTypes,
  NumericRoastBatchParameterType,
  NumericRoastBatchParameterTypes,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  RecipeSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import availableDataForLocationQuery from 'fabscale-app/gql/queries/available-data-for-location.graphql';
import availableTimezoneNamesQuery from 'fabscale-app/gql/queries/available-timezone-names.graphql';
import plantAssetsInUseQuery from 'fabscale-app/gql/queries/plant-assets-in-use.graphql';
import recipesInUseQuery from 'fabscale-app/gql/queries/recipes-in-use.graphql';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAssetFilters } from 'fabscale-app/models/plant-asset';
import GraphQLService from 'fabscale-app/services/-graphql';
import { sortByList } from 'fabscale-app/utilities/utils/array';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import UserSessionService from './user-session';

export default class AvailableDataService extends Service {
  @service userSession: UserSessionService;
  @service graphql: GraphQLService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  async getAvailableKpiTypes(): Promise<KpiType[]> {
    let data = await this.loadAvailableDataForLocation();

    if (!data) {
      return [];
    }

    return sortByList(data.kpiTypes, KpiTypes);
  }

  async getAvailableRoasters(
    dateRange: DateRange
  ): Promise<{ id: string; name: string }[]> {
    let { graphql, locationId } = this;

    assert(
      'getAvailableRoasters: You have to specify {dateRange.start}',
      !!dateRange.start
    );
    assert(
      'getAvailableRoasters: You have to specify {dateRange.end}',
      !!dateRange.end
    );

    let filters: PlantAssetFilters = {
      locationId,
      type: 'ROASTER',
      used: {
        dateFrom: serializeDate(dateRange.start),
        dateTo: serializeDate(dateRange.end),
      },
    };

    let variables = {
      filters,
    };

    // If the end date is today, we do not want to cache for a whole day, as new roasts might be coming in
    let isToday = dateRange.end.hasSame(DateTime.local(), 'day');

    let plantAssets: { id: string; name: string }[] = await graphql.query(
      {
        query: plantAssetsInUseQuery,
        variables,
        namespace: 'plantAssetsList',
      },
      {
        cacheEntity: 'PlantAsset',
        cacheSeconds: isToday ? 60 : 60 * 60 * 24,
      }
    );

    return plantAssets;
  }

  async getAvailableRecipes({
    dateRange,
    pageSize = 9999,
    sortBy = 'NAME',
    sortDirection = 'ASC',
  }: {
    dateRange: DateRange;
    pageSize?: number;
    sortBy?: RecipeSortOption;
    sortDirection?: SortDirection;
  }): Promise<{ id: string; name: string }[]> {
    let { graphql, locationId } = this;

    assert(
      'getAvailableRecipes: You have to specify {dateRange.start}',
      !!dateRange.start
    );
    assert(
      'getAvailableRecipes: You have to specify {dateRange.end}',
      !!dateRange.end
    );

    let variables = {
      filters: {
        locationId,
        used: {
          dateFrom: serializeDate(dateRange.start),
          dateTo: serializeDate(dateRange.end),
        },
      },

      sortBy,
      sortDirection,
      pageDef: {
        page: 1,
        pageSize,
      },
    };

    let response = await graphql.query(
      { query: recipesInUseQuery, variables, namespace: 'recipesPaginated' },
      {
        cacheEntity: 'Recipe',
        cacheSeconds: 120,
      }
    );

    let { items }: { items: { id: string; name: string }[] } = response;
    return items;
  }

  async getAvailableTimezoneNames(): Promise<string[]> {
    let { graphql } = this;
    let availableTimezoneNames: string[] = await graphql.query(
      {
        query: availableTimezoneNamesQuery,
        namespace: 'availableTimezoneNames',
      },
      {
        cacheEntity: 'Other',
      }
    );

    return availableTimezoneNames;
  }

  async getAvailableRoastBatchParameterTypes(): Promise<{
    numeric: NumericRoastBatchParameterType[];
    boolean: BooleanRoastBatchParameterType[];
    numericCurve: NumericCurveRoastBatchParameterType[];
  }> {
    let data = await this.loadAvailableDataForLocation();

    if (!data) {
      return {
        numeric: [],
        boolean: [],
        numericCurve: [],
      };
    }

    let {
      booleanRoastBatchParameterTypes,
      numericCurveRoastBatchParameterTypes,
    } = data;

    let numericSet = new Set(data.numericRoastBatchParameterTypes);

    let qualityTypes: NumericRoastBatchParameterType[] = [
      'ROAST_COLOR',
      'ROAST_DENSITY',
      'ROAST_MOISTURE',
      'SENSORIAL_SCORE',
    ];

    qualityTypes.forEach((type) => numericSet.add(type));
    numericSet.add('OUTPUT_WEIGHT');
    numericSet.add('WEIGHT_LOSS_ACTUAL');

    return {
      numeric: sortByList(
        Array.from(numericSet),
        NumericRoastBatchParameterTypes
      ),
      boolean: sortByList(
        booleanRoastBatchParameterTypes,
        booleanRoastBatchParameterTypes
      ),
      numericCurve: sortByList(
        numericCurveRoastBatchParameterTypes,
        NumericCurveRoastBatchParameterTypes
      ),
    };
  }

  async loadAvailableDataForLocation() {
    let { graphql } = this;
    let locationId = this.userSession.currentLocation?.id;

    if (!locationId) {
      return undefined;
    }

    let variables = {
      locationId,
    };

    let data: {
      kpiTypes: KpiType[];
      numericRoastBatchParameterTypes: NumericRoastBatchParameterType[];
      booleanRoastBatchParameterTypes: BooleanRoastBatchParameterType[];
      numericCurveRoastBatchParameterTypes: NumericCurveRoastBatchParameterType[];
    } = await graphql.query(
      {
        query: availableDataForLocationQuery,
        variables,
        namespace: 'availableDataForLocation',
      },
      {
        cacheEntity: 'Other',
      }
    );

    return data;
  }

  _getKpiTypes() {
    return KpiTypes;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'available-data': AvailableDataService;
  }
}
