import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import { getDateFormatForInterval } from 'fabscale-app/utilities/utils/date-interval';

export default class OverTimeLineChartDataHelper extends Helper<{
  PositionalArgs: [
    kpiDataOverTime: KpiDataOverTime[],
    options: {
      color?: string | string[];
      interval: Interval;
      useMultipleYAxis?: boolean;
    }
  ];
}> {
  @service enumLabels: EnumLabelsService;

  compute([kpiDataOverTime, options]: [
    any,
    {
      color?: string;
      colors?: string[];
      interval: Interval;
      useMultipleYAxis?: boolean;
    }
  ]) {
    const { enumLabels } = this;

    if (
      !kpiDataOverTime ||
      !kpiDataOverTime.length ||
      !Array.isArray(kpiDataOverTime)
    ) {
      return {
        datasets: [],
      };
    }

    const { color, interval, useMultipleYAxis } = options;
    const datasets = kpiDataOverTime?.map(
      (item: KpiDataOverTime, index: number) => {
        const dataToBePushed = item.dateBinValues.map((dateValue: any) => {
          return {
            date: dateValue.dateRange.start.toLocaleString(
              getDateFormatForInterval(interval)
            ),
            dateRange: dateValue.dateRange,
            value: dateValue.value,
            label: enumLabels.kpiType(item.type),
          };
        });

        return {
          data: dataToBePushed,
          unit: item.unit,
          borderColor: Array.isArray(color) ? color[index] : color,
          pointBackgroundColor: Array.isArray(color) ? color[index] : color,
          pointBorderColor: cssObj.colors.white,
          borderWidth: 3,
          pointBorderWidth: 2,
          pointRadius: 4.5,
          parsing: {
            xAxisKey: 'date',
            yAxisKey: 'value',
          },
          ...(useMultipleYAxis && { yAxisID: `y-axis-${index}` }),
        };
      }
    );

    return {
      datasets: datasets,
    };
  }
}
