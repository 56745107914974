import { tracked } from '@glimmer/tracking';
import { DateTimeString } from 'fabscale-app';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import {
  AdminLocation,
  AdminLocationInput,
} from 'fabscale-app/models/admin-location';

export interface AdminCompanyInput {
  id: string;
  name: string;
  features?: { name: string }[];
  locations: AdminLocationInput[];
  creationDate: DateTimeString;
  lastModifiedDate: DateTimeString;
}

export class AdminCompany {
  id: string;
  name: string;
  @tracked features: string[];
  creationDate: DateTime;
  lastModifiedDate: DateTime;
  locations: AdminLocation[];

  constructor(options: AdminCompanyInput) {
    this.id = options.id;
    this.name = options.name;
    this.features = options.features
      ? options.features.map((feature) => feature.name)
      : [];

    this.creationDate = deserializeDate(options.creationDate);
    this.lastModifiedDate = deserializeDate(options.lastModifiedDate);
    this.locations = options.locations.map(
      (locationData) => new AdminLocation(locationData)
    );
  }
}
