import Helper from '@ember/component/helper';
import { scaleTime } from 'd3-scale';
import { timeHour } from 'd3-time';
import { AxisConfigDates } from 'fabscale-app/components/chart';
import {
  getDateAxisTickFormat,
  getTimeIntervalSteps,
} from 'fabscale-app/utilities/utils/chart';
import { DateTime } from 'luxon';

export default class ChartScaleTimeRange extends Helper<{
  PositionalArgs: [startDate: DateTime, endDate: DateTime, width: number];
}> {
  compute([startDate, endDate, width]: [
    DateTime,
    DateTime,
    number
  ]): AxisConfigDates {
    let scale = getScale({ width, startDate, endDate });
    let tickFormat = getDateAxisTickFormat(DateTime.TIME_SIMPLE);

    let minItemWidth = 60;
    let maxItemCount = Math.floor(width / minItemWidth);

    let d3TimeInterval = timeHour.every(
      getTimeIntervalSteps(startDate, endDate, 'hours', maxItemCount)
    )!;

    return {
      scale,
      d3TimeInterval,
      tickFormat,
    };
  }
}

function getScale({
  startDate,
  endDate,
  width,
}: {
  startDate: DateTime;
  endDate: DateTime;
  width: number;
}) {
  //  D3 needs native dates
  return scaleTime()
    .domain([startDate.toJSDate(), endDate.toJSDate()])
    .range([0, width]);
}
