import { assert } from '@ember/debug';
import { PlantAssetInfoSummary } from 'fabscale-app/models/plant-asset-info-summary';
import { UptimeInterval } from 'fabscale-app/models/repeat-config';
import { DateTime } from 'luxon';

export default function estimateUptimeDuedateHelper(
  startDate: DateTime,
  uptimeInterval: UptimeInterval,
  uptimeSummary: PlantAssetInfoSummary
): DateTime | undefined {
  assert('Repeat config has no uptimeInterval', !!uptimeInterval);

  let today = DateTime.local().startOf('day');

  // These are always in HOUR
  let usedH = uptimeSummary.totalRuntime;
  let expectedH = uptimeInterval.amount;

  if (uptimeSummary.averageRuntimePerDay <= 0) {
    return undefined;
  }

  if (usedH >= expectedH) {
    let daysDiff = Math.floor(
      (usedH - expectedH) / uptimeSummary.averageRuntimePerDay
    );
    let dueDate = today.minus({ days: daysDiff });

    return +dueDate > +startDate ? dueDate : startDate;
  }

  let remainingH = expectedH - usedH;
  let estimatedDays = Math.ceil(
    remainingH / uptimeSummary.averageRuntimePerDay
  );

  return today.plus({ days: estimatedDays });
}
