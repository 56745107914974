import { BooleanRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { RoastBatch } from 'fabscale-app/models/roast-batch';

export default function getBooleanRoastBatchParameter(
  roastBatch: RoastBatch,
  parameterType: BooleanRoastBatchParameterType
): boolean | undefined {
  let { booleanRoastBatchParameters } = roastBatch;

  let parameter = booleanRoastBatchParameters.find(
    (parameter) => parameter.parameterType === parameterType
  );

  return parameter?.value;
}
