import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';
import { DateRange } from 'fabscale-app/models/date-range';
import { cutOffDateRange } from 'fabscale-app/utilities/utils/date-range';
import { DateTime } from 'luxon';

interface Args {
  dateOption: DashboardDateOption;
  exactDateRange: DateRange;
  cutOffDate?: DateTime;
}

export default class DashboardCardsRoastSummary extends Component<{
  Args: Args;
}> {
  @service l10n: L10nService;

  get dateRange() {
    let { exactDateRange, cutOffDate } = this.args;
    return cutOffDateRange(exactDateRange, cutOffDate);
  }

  get title() {
    let { l10n } = this;
    let { dateOption } = this.args;

    let map: Record<DashboardDateOption, string> = {
      LAST_24H: l10n.t("Last 24h's roast summary"),
      TODAY: l10n.t("Today's roast summary"),
      YESTERDAY: l10n.t("Yesterday's roast summary"),
      THIS_WEEK: l10n.t("This week's roast summary"),
      LAST_WEEK: l10n.t("Last week's roast summary"),
    };

    return map[dateOption];
  }
}
