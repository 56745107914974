import { formatFileSize } from 'fabscale-app/utilities/utils/format-file-size';
import { DateTime } from 'luxon';

export class FilePending {
  name: string;
  size: number; // in B
  type: string;
  file: File;
  creationDate: DateTime;

  get sizeFormatted() {
    return formatFileSize(this.size);
  }

  get extension() {
    let parts = this.name.split('.');
    return parts[parts.length - 1] || '';
  }

  constructor(file: File) {
    this.file = file;
    this.name = file.name;
    this.size = file.size;
    this.type = file.type;
    this.creationDate = DateTime.local();
  }
}
