import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { cutOffDateRange } from 'fabscale-app/utilities/utils/date-range';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import { DateTime } from 'luxon';
import BaseAsyncResource from './base-async-resource';

enum KpiDataGroupedGrouping {
  PlantAsset = 'PLANT_ASSET',
  Recipe = 'RECIPE',
}

interface LoadKpiDataGroupedOptions {
  kpiTypes: KpiType[];
  dateRange: DateRange;
  plantAssetIds?: string[];
  recipeIds?: string[];
  groupBy: KpiDataGroupedGrouping;
  cutOffDate?: DateTime;
}

export default class LoadKpiDataGroupedResource extends BaseAsyncResource<
  KpiDataGrouped[],
  LoadKpiDataGroupedOptions
> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData({
    kpiTypes,
    dateRange,
    plantAssetIds,
    recipeIds,
    groupBy,
    cutOffDate,
  }: LoadKpiDataGroupedOptions) {
    let fetchOptions = {
      dateRange: cutOffDateRange(dateRange, cutOffDate),
      plantAssetIds,
      recipeIds,
    };

    assert(
      `{{resources/load-kpi-data-grouped}}: You have to provide a valid groupBy`,
      Object.values(KpiDataGroupedGrouping).includes(groupBy)
    );

    let data = await promiseQueue(kpiTypes, (kpiType) =>
      this._loadKpiDataGrouped(kpiType, groupBy, fetchOptions)
    );

    // Remove empty items
    return data.filter(filterKpiDataGrouped);
  }

  async _loadKpiDataGrouped(
    kpiType: KpiType,
    groupBy: KpiDataGroupedGrouping,
    {
      dateRange,
      plantAssetIds,
      recipeIds,
    }: {
      dateRange: DateRange;
      plantAssetIds?: string[];
      recipeIds?: string[];
    }
  ) {
    let func =
      groupBy === KpiDataGroupedGrouping.PlantAsset
        ? this.kpiDataStore.findPerPlantAsset
        : this.kpiDataStore.findPerRecipe;

    let data: KpiDataGrouped = await func.apply(this.kpiDataStore, [
      kpiType,
      {
        dateRange,
        plantAssetIds,
        recipeIds,
      },
    ]);

    return data;
  }
}

export function filterKpiDataGrouped(
  kpiDataGrouped: KpiDataGrouped | undefined
): kpiDataGrouped is KpiDataGrouped {
  return !!kpiDataGrouped;
}
