import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { OeeKpiReportFilters } from 'fabscale-app/models/oee-kpi-report-models';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';

export default class ReportsOeeKpiController extends Controller {
  queryParams = [
    'kpiTypes',
    'dateRange',
    'timeRange',
    'plantAssets',
    'recipes',
  ];

  @tracked kpiTypes?: string;
  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssets?: string;
  @tracked recipes?: string;

  get filters() {
    return {
      oeeKpiTypes: this.parsedKpiTypes,
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      plantAssetIds: this.parsedPlantAssetIds,
      recipeIds: this.parsedRecipeIds,
    };
  }

  get parsedKpiTypes() {
    return this.kpiTypes ? deserializeArray(this.kpiTypes) : [];
  }

  get parsedDateRange() {
    return this.dateRange ? deserializeDateRange(this.dateRange) : undefined;
  }

  get parsedTimeRange() {
    return this.timeRange ? JSON.parse(this.timeRange) : undefined;
  }

  get parsedPlantAssetIds() {
    return this.plantAssets ? deserializeArray(this.plantAssets) : [];
  }

  get parsedRecipeIds() {
    return this.recipes ? deserializeArray(this.recipes) : [];
  }

  @action
  updateFilters(filters: OeeKpiReportFilters) {
    const { oeeKpiTypes, plantAssetIds, recipeIds, dateRange, timeRange } =
      filters;

    this.kpiTypes = oeeKpiTypes ? serializeArray(oeeKpiTypes) : undefined;
    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssets =
      plantAssetIds.length > 0 ? serializeArray(plantAssetIds) : undefined;
    this.recipes = recipeIds.length > 0 ? serializeArray(recipeIds) : undefined;
  }

  resetFilters() {
    this.kpiTypes =
      this.dateRange =
      this.timeRange =
      this.plantAssets =
      this.recipes =
        undefined;
  }
}
