import Component from '@glimmer/component';

interface UiPinmarkerArgs {
  pinned: boolean;
}

export default class UiPinmarker extends Component<UiPinmarkerArgs> {
  get displayedIcon(): string {
    return this.args.pinned ? 'pin-active' : 'pin-inactive';
  }
}
