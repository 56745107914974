import { assert } from '@ember/debug';
import { PlantAlarmOverTime } from 'fabscale-app/models/alarm-report';
import {
  PlantAssetAlertLevel,
  PlantAssetAlertLevels,
} from 'fabscale-app/models/enums/plant-asset-alert-level';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';

export default function plantAlarmsOvertimeChartDataByAlarm(
  plantAlarmsOverTime: PlantAlarmOverTime[],
  type: PlantAssetAlertLevel
) {
  assert(
    `plantAlarmsOvertimeChartDataByAlarm: plantAlarmsOverTime undefined`,
    !!plantAlarmsOverTime
  );
  assert(
    `plantAlarmsOvertimeChartDataByAlarm: incorrect value of type`,
    PlantAssetAlertLevels.includes(type)
  );

  const filteredAlarmOverTimeItems = plantAlarmsOverTime?.find(
    (plantAlarmOverTime: PlantAlarmOverTime) =>
      plantAlarmOverTime.alarmLevel === type
  )?.alarmOverTimeItems;
  const datasets = filteredAlarmOverTimeItems?.map((item, index) => {
    const dataToBePushed = item.dateValueTimeSeries.map((dateValue) => {
      return {
        dateFrom: dateValue.dateFrom,
        dateTo: dateValue.dateTo,
        value: dateValue.value,
        idNamePair: item.idNamePair,
      };
    });

    return {
      data: dataToBePushed,
      borderColor: chartColors[index],
      parsing: {
        xAxisKey: 'dateFrom',
        yAxisKey: 'value',
      },
    };
  });

  return {
    datasets: datasets,
  };
}
