import { service } from '@ember/service';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import BaseAsyncResource from './base-async-resource';
import { PlantAssetType } from 'fabscale-app/models/enums/plant-asset-types';

interface LoadPlantAssetsOptions {
  hasStatusInfo?: boolean;
  type?: PlantAssetType;
}

export default class LoadPlantAssetsResource extends BaseAsyncResource<
  PlantAsset[],
  LoadPlantAssetsOptions
> {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  loadData({ hasStatusInfo, type }: LoadPlantAssetsOptions) {
    return this.plantAssetStore.findAll({ hasStatusInfo }, { type });
  }
}
