import { modifier } from 'ember-could-get-used-to-this';

export function scrollIntoView(element: HTMLElement) {
  // If element is already in view, skip to avoid weird scrolling
  if (elementIsInView(element)) {
    return;
  }

  element.scrollIntoView({ behavior: 'smooth' });
}

function elementIsInView(element: HTMLElement) {
  let bounding = element.getBoundingClientRect();
  let height = document.documentElement.clientHeight || window.innerHeight;

  return bounding.top >= 0 && bounding.bottom <= height;
}

export default modifier(scrollIntoView);
