import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';
import { BaseEnumHelper } from './-base';

export default class EnumPlantAssetAlertLevelHelper extends BaseEnumHelper<PlantAssetAlertLevel> {
  @service l10n: L10nService;

  getLabel(enumValue: PlantAssetAlertLevel) {
    return this.enumLabels.plantAssetAlertLevel(enumValue);
  }
}
