import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NavSidebar extends Service {
  @tracked isOpen = true;

  @action toggleNavSidebar(flag: boolean) {
    this.isOpen = flag;
  }

  isNavSidebarOpen(): boolean {
    return this.isOpen;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'nav-sidebar': NavSidebar;
  }
}
