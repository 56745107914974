import Component from '@glimmer/component';

interface Args {
  targetAttachment: string;
  translucentOverlay: boolean;
}

export default class SharedBaseDialog extends Component<Args> {
  get targetAttachment() {
    return this.args.targetAttachment;
  }

  get translucentOverlay() {
    return this.args.translucentOverlay;
  }
}
