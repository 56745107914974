import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { DateBinValue } from './date-bin-value';

export class KpiDataOverTime {
  unit: Unit;
  type: KpiType;
  dateBinValues: DateBinValue[];
  higherIsBetter: boolean;

  constructor({
    type,
    dateBinValues,
    unit,
    higherIsBetter,
  }: KpiDataOverTimeInput) {
    this.type = type;
    this.unit = unit;
    this.dateBinValues = dateBinValues;
    this.higherIsBetter = higherIsBetter;
  }
}

interface KpiDataOverTimeInput {
  unit: Unit;
  type: KpiType;
  dateBinValues: DateBinValue[];
  higherIsBetter: boolean;
}
