import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ExcelService, { ExcelAmountCell } from 'fabscale-app/services/excel';
import CustomAnalyticsService from 'fabscale-app/services/analytics';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { tracked } from '@glimmer/tracking';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import { dropTask } from 'ember-concurrency';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateTime } from 'luxon';
import { ValueUnitPair } from 'fabscale-app/helpers/kpi-compare-report/table-columns';
import { action } from '@ember/object';
import { OeeKpiReportTableData } from 'fabscale-app/helpers/oee-kpi-report/table-columns';

interface Args {
  data: OeeKpiReportTableData[];
  columns: TableColumnDefinitionInput[];
}
export default class PageOeeKpiReportTable extends Component<Args> {
  @service l10n: L10nService;
  @service excel: ExcelService;
  @service analytics: CustomAnalyticsService;

  @tracked searchTerm?: string;

  get filteredData() {
    const { data } = this.args;
    const { searchTerm } = this;

    if (!searchTerm) {
      return data;
    }

    return filterRecords(data, searchTerm, { propertyName: 'searchLabel' });
  }

  downloadExcel = dropTask(async () => {
    const { data, columns } = this.args;
    const { excel, l10n } = this;

    const rows = data.map((record) => {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { label, searchLabel, ...fields } = record;

      const row: { label: string | DateTime } & {
        [key in KpiType]?: ExcelAmountCell;
      } = {
        label,
      };

      Object.keys(fields).forEach((field) => {
        const valueUnitPair = fields[field as KpiType] as ValueUnitPair;

        row[field as KpiType] = new ExcelAmountCell({
          amount: valueUnitPair.value,
          unit: valueUnitPair.unit,
        });
      });

      return row;
    });

    const sheetColumns = columns.map((column) => {
      const title = column.title!;
      const propertyName = column.propertyName!;

      const id = propertyName.replace('.value', '');

      return { header: title, id };
    });

    const sheetConfig = {
      sheetName: l10n.t('Fabscale OEE KPI Report data'),
      autoFilter: true,
      columns: sheetColumns,
      rows,
    };

    this.analytics.addEvent('oee-kpi-report-download-excel');

    await excel.create(sheetConfig);
  });

  @action
  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }
}
