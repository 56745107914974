import AuthenticatedRoute from '../base/-base-authenticated-route';
import DebugController from './controller';

export default class DebugRoute extends AuthenticatedRoute {
  resetController(controller: DebugController, isExiting: boolean): void {
    if (isExiting) {
      controller.impersonateUserEmail = undefined;
    }
  }
}
