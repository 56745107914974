import { DateRange } from 'fabscale-app/models/date-range';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { GroupBinValue } from 'fabscale-app/models/group-bin-value';

export default function kpiPerPlantAssetBarChartData(
  kpiDataGrouped: KpiDataGrouped,
  {
    color,
    exactDateRange,
  }: { color?: string | string[]; exactDateRange: DateRange }
) {
  if (!kpiDataGrouped) {
    return {
      datasets: [],
    };
  }

  const datasets = kpiDataGrouped.values.map(
    (item: GroupBinValue, index: number) => {
      return {
        data: [item],
        backgroundColor: Array.isArray(color) ? color[index] : color,
        label: kpiDataGrouped.type,
        dateRange: exactDateRange,
        unit: kpiDataGrouped.unit,
        parsing: {
          xAxisKey: 'name',
          yAxisKey: 'value',
        },
      };
    }
  );

  return {
    datasets: datasets,
  };
}
