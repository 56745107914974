import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import LoginController from './controller';
import { getOwnConfig } from '@embroider/macros';

export default class LoginRoute extends Route {
  @service userAuthentication: UserAuthenticationService;
  @service router: RouterService;

  beforeModel() {
    const { isSimulation } = getOwnConfig<any>();

    if (isSimulation) {
      const email = this.userAuthentication.getCurrentUserData()?.email;

      if (email) {
        this.userAuthentication.loginSimulation(email);
      }
    } else {
      if (this.userAuthentication.isAuthenticated) {
        this.router.transitionTo('routes/index');
      }
    }
  }

  resetController(controller: LoginController, isExiting: boolean) {
    if (isExiting) {
      controller.username = undefined;
    }
  }
}
