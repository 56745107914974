import Component from '@glimmer/component';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { StatusMapReportTableType } from 'fabscale-app/routes/reports/status-map/controller';

interface Args {
  tableId: StatusMapReportTableType;
}

export default class StatusMapReportDetailsOverTime extends Component<Args> {
  get interval(): Interval {
    let { tableId } = this.args;

    if (tableId === 'PER_WEEK') {
      return 'WEEK';
    }

    if (tableId === 'PER_MONTH') {
      return 'MONTH';
    }

    return 'DAY';
  }
}
