import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class PlantSettingsIndexRoute extends Route {
  @service router: RouterService;

  beforeModel() {
    // TODO FN: Actually put an overview page here instead
    this.router.replaceWith('routes/plant-settings.settings');
  }
}
