import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import { isNotOeeKpi, KpiType } from 'fabscale-app/models/enums/kpi-types';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import LoadAvailableKpiTypesResource from 'fabscale-app/helpers/resources/load-available-kpi-types';
import LoadPlantAssetsInUseResource from 'fabscale-app/helpers/resources/load-plant-assets-in-use';
import LoadRecipesInUseResource from 'fabscale-app/helpers/resources/load-recipes-in-use';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import AvailableDataService from 'fabscale-app/services/available-data';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import PdfService from 'fabscale-app/services/pdf';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { DateTime } from 'luxon';
import { KpiCompareReportFilters } from '..';
import StoreTemplate, {
  ITemplate,
  ITemplateInput,
  TemplateInput,
} from 'fabscale-app/services/store/template';
import UserSessionService from 'fabscale-app/services/user-session';
import { ReportType } from 'fabscale-app/models/enums/template';
import jsonParse from 'fabscale-app/helpers/json-parse';
import { deserializeDateRangeObject } from 'fabscale-app/utilities/utils/serialize-date-range';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';

interface Args {
  filters: KpiCompareReportFilters;
  updateFilters: (filters: KpiCompareReportFilters) => void;

  defaultTimeRange: TimeRange;
  canClose: boolean;
}

class KpiDetailsReportFilterFormData implements KpiCompareReportFilters {
  @tracked dateRange?: DateRangeOptional;
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked timeRange: TimeRange;
  @tracked recipeIds: string[];
  @tracked plantAssetIds: string[];
  @tracked kpiTypes: KpiType[];
  @tracked templateName = '';
}

export default class KpiCompareReportFiltersIndex extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service availableData: AvailableDataService;
  @service kpiDataFilter: KpiDataFilterService;
  @service enumLabels: EnumLabelsService;
  @tracked label: string;
  @tracked formModel: FormDataModel<KpiDetailsReportFilterFormData>;
  @tracked formData: KpiDetailsReportFilterFormData;
  availableTemplates: KpiDataFilterTemplate[];
  @service userSession: UserSessionService;
  @service('store/template')
  templateService: StoreTemplate;

  @cached
  get exactDateRange(): DateRange | undefined {
    let { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange,
      maxNow: true,
    });
  }

  @use
  availableKpiTypesAsyncResource = new LoadAvailableKpiTypesResource(() => [
    {},
  ]);

  get availableKpiTypes() {
    return getAsyncResourceData(this.availableKpiTypesAsyncResource, []);
  }

  get oeeRestrictedAvailableKpiTypes() {
    return getAsyncResourceData(
      this.availableKpiTypesAsyncResource,
      []
    )?.filter((kpiType) => isNotOeeKpi(kpiType));
  }

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsInUseResource(() => [
    { dateRange: this.exactDateRange },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  @use
  allRecipesAsyncResource = new LoadRecipesInUseResource(() => [
    { dateRange: this.exactDateRange, pageSize: 999 },
  ]);

  get allRecipes() {
    return getAsyncResourceData(this.allRecipesAsyncResource, []);
  }

  get selectedPlantAssets() {
    let { allPlantAssets } = this;
    let { plantAssetIds } = this.formData;

    if (!allPlantAssets || !plantAssetIds) {
      return [];
    }

    return allPlantAssets.filter((plantAsset) =>
      plantAssetIds!.includes(plantAsset.id)
    );
  }

  get selectedRecipes() {
    let { allRecipes } = this;
    let { recipeIds } = this.formData;

    if (!allRecipes || !recipeIds) {
      return [];
    }

    return allRecipes.filter((recipe) => recipeIds!.includes(recipe.id));
  }

  get activeFilters(): { label: string; value: string }[] {
    let { l10n, enumLabels } = this;

    let dateStart = this.args.filters.dateRange?.start;
    let dateEnd = this.args.filters.dateRange?.end;

    let filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('KPIs'),
        value: this.formData.kpiTypes
          .map((kpiType) => enumLabels.kpiType(kpiType))
          .join(', '),
      },
      {
        label: l10n.t('Roasters'),
        value:
          this.selectedPlantAssets.length > 0
            ? this.selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All roasters'),
      },
      {
        label: l10n.t('Recipes'),
        value:
          this.selectedRecipes.length > 0
            ? this.selectedRecipes.map((recipe) => recipe.name).join(', ')
            : l10n.t('All recipes'),
      },
    ];

    return filters;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let {
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;
    this._initFormModel({
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      recipeIds,
    });

    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    this.label =
      this.availableTemplates.length > 0
        ? 'Choose template or Time frame'
        : 'Choose timeframe';
  }

  @action
  updateDateRange(dateRange: DateRangeOptional) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.formData.relativeTimeframe = undefined;

    this.analytics.addEvent('kpi-compare-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('kpi-compare-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updateKpiTypes(kpiTypes: KpiType[]) {
    this.formModel.updateProperty('kpiTypes', kpiTypes);

    this.analytics.addEvent('kpi-compare-filter-kpi-type', [
      { name: 'kpiTypeCount', value: kpiTypes.length },
    ]);
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    let plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('kpi-compare-filter-plant-assets', [
      { name: 'selectedCount', value: plantAssets.length },
    ]);
  }

  @action
  updateRecipes(recipes: { id: string; name: string }[]) {
    let recipeIds = recipes.map((recipe) => recipe.id);
    this.formModel.updateProperty('recipeIds', recipeIds);

    this.analytics.addEvent('kpi-compare-filter-recipes', [
      { name: 'selectedCount', value: recipes.length },
    ]);
  }

  @action
  handleTemplateChange(template: ITemplate) {
    const {
      relativeTimeframe,
      absoluteTimeframe,
      timeRange,
      kpiTypes,
      plantAssetIds,
      recipeIds,
    } = jsonParse(template.templateFilters);

    this.formData.relativeTimeframe = relativeTimeframe;
    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe)
      : deserializeDateRangeObject(absoluteTimeframe);

    this.formData.timeRange =
      timeRange && timeRange.start && timeRange.end
        ? (timeRange as TimeRange)
        : this.args.defaultTimeRange;

    this.formData.kpiTypes = kpiTypes;
    this.formData.plantAssetIds = plantAssetIds || [];
    this.formData.recipeIds = recipeIds || [];
  }

  @action
  onSelectRelativeTimeframe(relativeTimeframe?: RelativeTimeframe) {
    this.formData.relativeTimeframe = relativeTimeframe;
    let { timeRange } = this.formData;

    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe, timeRange)
      : { start: undefined, end: undefined };
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-kpi-compare-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    let {
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      recipeIds,
      templateName,
    } = this.formData;

    if (templateName) {
      const templateFilters = JSON.stringify({
        kpiTypes,
        relativeTimeframe,
        absoluteTimeframe: relativeTimeframe ? undefined : dateRange,
        timeRange,
        plantAssetIds,
        recipeIds,
      });

      const template: ITemplateInput = new TemplateInput({
        userId: this.userSession.user!.id,
        templateName: templateName,
        templateFilters: templateFilters,
        reportType: ReportType.KPI_COMPARE_REPORT,
      });

      await this.templateService.createTemplate(template);
    }

    this.args.updateFilters({
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      recipeIds,
    });

    // Push selected filters to the GTM data layer
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'filter_used',
      reportName: ReportType.KPI_COMPARE_REPORT,
      filters: {
        kpiTypes,
        dateRange: dateRange
          ? {
              start: serializeOptionalDate(dateRange.start),
              end: serializeOptionalDate(dateRange.end),
            }
          : undefined,
        relativeTimeframe,
        timeRange,
        plantAssetIds,
        recipeIds,
      },
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      dateRange: undefined,
      relativeTimeframe: undefined,
      timeRange: this.args.defaultTimeRange,
      kpiTypes: [],
      plantAssetIds: [],
      recipeIds: [],
    });
  }

  @action
  resetFilters() {
    let {
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;

    this._initFormModel({
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      plantAssetIds,
      recipeIds,
    });
  }

  _initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    relativeTimeframe?: RelativeTimeframe;
    timeRange: TimeRangeOptional;
    plantAssetIds: string[];
    recipeIds: string[];
    kpiTypes?: KpiType[];
  }) {
    let { l10n } = this;
    let {
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      recipeIds,
      plantAssetIds,
    } = initialData;

    this.formData = new KpiDetailsReportFilterFormData();

    Object.assign(this.formData, {
      kpiTypes,
      dateRange,
      relativeTimeframe,
      timeRange,
      recipeIds,
      plantAssetIds,
    });

    this.formModel = new FormDataModel<KpiDetailsReportFilterFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },

          message: l10n.t('You have to select a date range.'),
        },
        {
          propertyName: 'kpiTypes',
          validate(value: KpiType[]) {
            return value.length > 0;
          },

          message: l10n.t('You have to select at least one KPI.'),
        },
      ],
    });
  }
}
