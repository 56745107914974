import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import { TaskFormData } from './task-form';

export default class MaintenanceTasksTaskDetails extends Component {
  @service('store/maintenance-task')
  maintenanceTaskManagerStore: StoreMaintenanceTaskService;

  @tracked createdTask?: { id: string; name: string };

  createMaintenanceTaskTask = dropTask(async (data: TaskFormData) => {
    this.createdTask = undefined;

    let id: string = data.repeatConfig
      ? await this._createTaskRepeating(data)
      : await this._createTask(data);

    this.createdTask = { id, name: data.title };
  });

  async _createTask(data: TaskFormData): Promise<string> {
    let { id } = await this.maintenanceTaskManagerStore.create(data);

    return id;
  }

  async _createTaskRepeating(data: TaskFormData): Promise<string> {
    let { id } = await this.maintenanceTaskManagerStore.createRepeating(
      data,
      data.repeatConfig!
    );

    return id;
  }
}
