import { assert } from '@ember/debug';

export default function joinArray(array: string[], separator = ', ') {
  assert(
    `You need to pass an array to {{join-array}}, but you passed "${array}"`,
    Array.isArray(array)
  );

  return array.join(separator);
}
