import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';
import { isTesting } from '@embroider/macros';

let _swallowIfTesting = false;

export function testErrors() {
  _swallowIfTesting = true;
}

export function testErrorsReset() {
  _swallowIfTesting = false;
}

export function logError(error: unknown) {
  sentryCaptureException(error);

  if (isTesting() && !_swallowIfTesting) {
    throw error;
  } else if (!isTesting()) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
