import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  getIdentifierForX: (x: number) => string | undefined;
  updateTooltip: (identifier?: string) => void;
}

export default class ChartHoverOverlay extends Component<Args> {
  _lastIdentifer?: string;

  _getX(event: MouseEvent) {
    let target = event.target as HTMLElement;
    let bbox = target.getBoundingClientRect();

    return event.clientX - bbox.left;
  }

  @action
  onHover(event: MouseEvent) {
    let x = this._getX(event);
    let identifer = this.args.getIdentifierForX(x);

    if (identifer !== this._lastIdentifer) {
      this._lastIdentifer = identifer;
      this.args.updateTooltip(identifer);
    }
  }

  @action
  onMouseLeave() {
    this._lastIdentifer = undefined;
    this.args.updateTooltip(undefined);
  }
}
