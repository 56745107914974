import ApplicationInstance from '@ember/application/instance';
import Service from '@ember/service';
import {
  GoalResultStatus,
  GoalResultStatusEnums,
} from 'fabscale-app/models/enums/goal-result-status';
import { Interval, IntervalsEnums } from 'fabscale-app/models/enums/intervals';
import {
  KpiType,
  KpiTypesDescriptionsEnums,
  KpiTypesEnums,
} from 'fabscale-app/models/enums/kpi-types';
import {
  Permission,
  PermissionDescriptionsEnums,
  PermissionsEnums,
} from 'fabscale-app/models/enums/permissions';
import {
  PlantAssetAlertLevel,
  PlantAssetAlertLevelsEnums,
} from 'fabscale-app/models/enums/plant-asset-alert-level';
import {
  PlantAssetAreaType,
  PlantAssetAreaTypesEnums,
} from 'fabscale-app/models/enums/plant-asset-area-types';
import { ContinuousDataType } from 'fabscale-app/models/enums/plant-asset-monitoring';
import { PlantAssetSensorCategoriesEnums } from 'fabscale-app/models/enums/plant-asset-sensor-categories';
import {
  PlantAssetStatus,
  PlantAssetStatusEnums,
} from 'fabscale-app/models/enums/plant-asset-status';
import {
  PlantAssetStatusReason,
  PlantAssetStatusReasonEnums,
} from 'fabscale-app/models/enums/plant-asset-status-reason';
import {
  PlantAssetType,
  PlantAssetTypesEnums,
} from 'fabscale-app/models/enums/plant-asset-types';
import {
  RecipeIdentificationStrategiesEnums,
  RecipeIdentificationStrategy,
} from 'fabscale-app/models/enums/recipe-identification-strategies';
import {
  RelativeTimeframeEnums,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import {
  RoastBatchParameterType,
  RoastBatchParameterTypesEnums,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  RoastBatchStatus,
  RoastBatchStatusEnums,
} from 'fabscale-app/models/enums/roast-batch-status';
import {
  TaskCategoriesEnums,
  TaskCategory,
} from 'fabscale-app/models/enums/task-categories';
import {
  TaskRequirement,
  TaskRequirementsEnums,
} from 'fabscale-app/models/enums/task-requirements';
import {
  UnitSystem,
  UnitSystemsEnums,
} from 'fabscale-app/models/enums/unit-systems';
import { Unit, UnitsEnums } from 'fabscale-app/models/enums/units';
import {
  PlantAssetAlarmType,
  PlantAssetAlarmTypesEnums,
} from 'fabscale-app/models/enums/plant-asset-alarm-type';

export default class EnumLabelsService extends Service {
  _kpiTypes: KpiTypesEnums;
  _kpiTypeDescriptionss: KpiTypesDescriptionsEnums;
  _plantAssetAreaTypes: PlantAssetAreaTypesEnums;
  _plantAssetTypes: PlantAssetTypesEnums;
  _plantAssetStatus: PlantAssetStatusEnums;
  _plantAssetStatusReasons: PlantAssetStatusReasonEnums;
  _taskCategories: TaskCategoriesEnums;
  _taskRequirements: TaskRequirementsEnums;
  _intervals: IntervalsEnums;
  _units: UnitsEnums;
  _unitSystems: UnitSystemsEnums;
  _roastBatchParameterTypes: RoastBatchParameterTypesEnums;
  _permissions: PermissionsEnums;
  _permissionDescriptions: PermissionDescriptionsEnums;
  _goalResultStatus: GoalResultStatusEnums;
  _roastBatchStatus: RoastBatchStatusEnums;
  _recipeIdentificationStrategies: RecipeIdentificationStrategiesEnums;
  _relativeTimeframes: RelativeTimeframeEnums;
  _plantAssetAlertLevels: PlantAssetAlertLevelsEnums;
  _plantAssetAlarmTypes: PlantAssetAlarmTypesEnums;
  _plantAssetSensorCategories: PlantAssetSensorCategoriesEnums;

  constructor(owner: ApplicationInstance) {
    super(owner);

    this._kpiTypes = new KpiTypesEnums(owner);
    this._kpiTypeDescriptionss = new KpiTypesDescriptionsEnums(owner);
    this._plantAssetAreaTypes = new PlantAssetAreaTypesEnums(owner);
    this._plantAssetTypes = new PlantAssetTypesEnums(owner);
    this._plantAssetStatus = new PlantAssetStatusEnums(owner);
    this._plantAssetStatusReasons = new PlantAssetStatusReasonEnums(owner);
    this._taskCategories = new TaskCategoriesEnums(owner);
    this._taskRequirements = new TaskRequirementsEnums(owner);
    this._intervals = new IntervalsEnums(owner);
    this._units = new UnitsEnums(owner);
    this._unitSystems = new UnitSystemsEnums(owner);
    this._roastBatchParameterTypes = new RoastBatchParameterTypesEnums(owner);
    this._permissions = new PermissionsEnums(owner);
    this._permissionDescriptions = new PermissionDescriptionsEnums(owner);
    this._goalResultStatus = new GoalResultStatusEnums(owner);
    this._roastBatchStatus = new RoastBatchStatusEnums(owner);
    this._recipeIdentificationStrategies =
      new RecipeIdentificationStrategiesEnums(owner);
    this._relativeTimeframes = new RelativeTimeframeEnums(owner);
    this._plantAssetAlertLevels = new PlantAssetAlertLevelsEnums(owner);
    this._plantAssetAlarmTypes = new PlantAssetAlarmTypesEnums(owner);
    this._plantAssetSensorCategories = new PlantAssetSensorCategoriesEnums(
      owner
    );
  }

  kpiType(enumValue: KpiType) {
    return this._kpiTypes.getLabel(enumValue);
  }

  kpiTypeDescription(enumValue: KpiType) {
    return this._kpiTypeDescriptionss.getLabel(enumValue);
  }

  plantAssetAreaType(enumValue: PlantAssetAreaType) {
    return this._plantAssetAreaTypes.getLabel(enumValue);
  }

  plantAssetType(enumValue: PlantAssetType) {
    return this._plantAssetTypes.getLabel(enumValue);
  }

  plantAssetStatus(enumValue: PlantAssetStatus) {
    return this._plantAssetStatus.getLabel(enumValue);
  }

  plantAssetStatusReason(enumValue: PlantAssetStatusReason) {
    return this._plantAssetStatusReasons.getLabel(enumValue);
  }

  taskCategory(enumValue: TaskCategory) {
    return this._taskCategories.getLabel(enumValue);
  }

  taskRequirement(enumValue: TaskRequirement) {
    return this._taskRequirements.getLabel(enumValue);
  }

  interval(enumValue: Interval) {
    return this._intervals.getLabel(enumValue);
  }

  unit(enumValue: Unit) {
    return this._units.getLabel(enumValue);
  }

  unitSystem(enumValue: UnitSystem) {
    return this._unitSystems.getLabel(enumValue);
  }

  roastBatchParameterType(enumValue: RoastBatchParameterType) {
    return this._roastBatchParameterTypes.getLabel(enumValue);
  }

  permission(enumValue: Permission) {
    return this._permissions.getLabel(enumValue);
  }

  permissionDescription(enumValue: Permission) {
    return this._permissionDescriptions.getLabel(enumValue);
  }

  goalResultStatus(enumValue: GoalResultStatus) {
    return this._goalResultStatus.getLabel(enumValue);
  }

  roastBatchStatus(enumValue: RoastBatchStatus) {
    return this._roastBatchStatus.getLabel(enumValue);
  }

  recipeIdentificationStrategy(enumValue: RecipeIdentificationStrategy) {
    return this._recipeIdentificationStrategies.getLabel(enumValue);
  }

  relativeTimeframe(enumValue: RelativeTimeframeId) {
    return this._relativeTimeframes.getLabel(enumValue);
  }

  plantAssetAlertLevel(enumValue: PlantAssetAlertLevel) {
    return this._plantAssetAlertLevels.getLabel(enumValue);
  }

  plantAssetAlarmType(enumValue: PlantAssetAlarmType) {
    return this._plantAssetAlarmTypes.getLabel(enumValue);
  }

  plantAssetSensorCategory(enumValue: ContinuousDataType) {
    return this._plantAssetSensorCategories.getLabel(enumValue);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'enum-labels': EnumLabelsService;
  }
}
