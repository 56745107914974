import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { service } from '@ember/service';
import SettingsService from 'fabscale-app/services/settings';
import UserSessionService from 'fabscale-app/services/user-session';
import PdfService from 'fabscale-app/services/pdf';
import AnalyticsService from 'fabscale-app/services/analytics';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { DateTime } from 'luxon';
import { DateRange } from 'fabscale-app/models/date-range';
import { TimeRange } from 'fabscale-app/models/time-range';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import {
  formatDate,
  DateFormat,
} from 'fabscale-app/utilities/utils/format-date';
import {
  RelativeTimeframe,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import {
  deserializeRelativeTimeframe,
  relativeTimeframeToDateRange,
} from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { LabSamplesListFilters } from 'fabscale-app/services/store/lab-sample';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';

interface Args {
  filters: LabSamplesListFilters & { timeRange: TimeRange };
  updateFilters: (
    filters: LabSamplesListFilters & { timeRange: TimeRange }
  ) => void;
}

class RoastpicFormData {
  @tracked dateRange: DateRange;
  @tracked timeRange: TimeRange;
  @tracked coffeeTypes: any;
}

export default class PageRoastPicFilters extends Component<Args> {
  @service settings: SettingsService;
  @service userSession: UserSessionService;
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @tracked formModel: FormDataModel<any>;
  @tracked formData: any;
  @tracked
  selectedRelativeTimeframe: RelativeTimeframe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const { dateRange, coffeeTypes } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange: this.defaultTimeRange,
      coffeeTypes,
    });
  }

  get selectedRelativeTimeframeId() {
    return this.selectedRelativeTimeframe?.id;
  }

  get dateRange() {
    return this.formData.dateRange;
  }

  get defaultTimeRange() {
    const { dayStartTime } = this.settings.locationSettings;
    const end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get coffeeTypes() {
    return this.formData.coffeeTypes;
  }

  get activeFilters(): { label: string; value: string }[] {
    const { l10n } = this;

    return [
      {
        label: l10n.t('Date from'),
        value: this.dateRange?.start
          ? formatDate(this.dateRange.start, DateFormat.Date)
          : '-',
      },
      {
        label: l10n.t('Date to'),
        value: this.dateRange?.end
          ? formatDate(this.dateRange.end, DateFormat.Date)
          : '-',
      },
      {
        label: l10n.t('Coffee types'),
        value: this.coffeeTypes?.length ? this.coffeeTypes : 'All coffee types',
      },
    ];
  }

  private initFormModel(initialData: {
    dateRange: any;
    timeRange: any;
    coffeeTypes: any;
  }) {
    const { l10n } = this;
    const { dateRange, timeRange, coffeeTypes } = initialData;

    this.formData = new RoastpicFormData();

    Object.assign(this.formData, {
      dateRange,
      timeRange,
      coffeeTypes,
    });

    this.formModel = new FormDataModel<RoastpicFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRange | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },
          message: l10n.t('You have to select a date range.'),
        },
      ],
    });
  }

  @action
  handleClearFilters() {
    this.initFormModel({
      dateRange: undefined,
      timeRange: undefined,
      coffeeTypes: [],
    });
  }

  @action
  handleResetFilters() {
    const { dateRange, timeRange, coffeeTypes } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      coffeeTypes,
    });
  }

  @action
  handleTimeframeChange(relativeTimeframeId: RelativeTimeframeId) {
    this.selectedRelativeTimeframe =
      deserializeRelativeTimeframe(relativeTimeframeId);

    if (this.selectedRelativeTimeframe) {
      this.formModel.updateProperty(
        'dateRange',
        relativeTimeframeToDateRange(
          this.selectedRelativeTimeframe,
          this.formData.timeRange
        )
      );
    }
  }

  @action
  handleDateRangeChange(dateRange: DateRange) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('roastpic-filter-date-range', [
      {
        name: 'dateRange',
        value: JSON.stringify(dateRange),
      },
    ]);
  }

  @action
  handleTimeRangeChange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('roastpic-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  handleCoffeeTypeChange(coffeeTypes: any) {
    this.formModel.updateProperty('coffeeTypes', coffeeTypes);
  }

  printPageTask = dropTask(async () => {
    const fileName = `fabscale-roastpic-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    const isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    const { dateRange, timeRange, coffeeTypes } = this.formData;

    this.args.updateFilters({
      dateRange,
      timeRange,
      coffeeTypes,
    });

    // Push selected filters to the GTM data layer
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'filter_used',
      reportName: 'ROAST_PIC',
      filters: {
        dateRange: dateRange
          ? {
              start: serializeOptionalDate(dateRange.start),
              end: serializeOptionalDate(dateRange.end),
            }
          : undefined,
        timeRange,
        coffeeTypes,
      },
    });

    return true;
  });
}
