import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { KpiCompareReportFilters } from 'fabscale-app/components/page/kpi-compare-report';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { TimeRangeOptionalEnd } from 'fabscale-app/models/time-range';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import { serializeKpiDataFilterSettings } from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-settings';

export default class KpiCompareReportFindTemplateForFiltersHelper extends Helper<{
  PositionalArgs: [filters: KpiCompareReportFilters];
}> {
  @service kpiDataFilter: KpiDataFilterService;

  compute([filters]: [
    KpiCompareReportFilters | any
  ]): KpiDataFilterTemplate | null {
    let availableTemplates = this.kpiDataFilter.getAvailableTemplates();

    if (!filters || !filters.dateRange) {
      return null;
    }

    let {
      relativeTimeframe,
      dateRange,
      timeRange,
      kpiTypes,
      plantAssetIds,
      recipeIds,
      sensorNames,
      sensorCategories,
    } = filters;

    let kpiFilterSettings: KpiDataFilterSettings = new KpiDataFilterSettings({
      relativeTimeframe,
      absoluteTimeframe: relativeTimeframe ? undefined : dateRange,
      timeRange: timeRange as TimeRangeOptionalEnd,
      kpiTypes,
      plantAssetIds,
      recipeIds,
      sensorNames,
      sensorCategories,
    });

    let serializedFilterSettings =
      serializeKpiDataFilterSettings(kpiFilterSettings);

    return (
      availableTemplates.find((template) => {
        return (
          serializedFilterSettings ===
          serializeKpiDataFilterSettings(template.filterSettings)
        );
      }) || null
    );
  }
}
