import { DateTimeString } from 'fabscale-app';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

interface LocationData {
  id: string;
  name: string;
  company: {
    id: string;
    name: string;
  };
}

export class CognitoAppClient {
  id: string;
  name: string;
  appClientId: string;
  appClientSecret?: string;
  creationDate: DateTime;

  location: LocationData;

  constructor(options: {
    id: string;
    name: string;
    appClientId: string;
    appClientSecret?: string;
    creationDate: DateTimeString;
    location: LocationData;
  }) {
    this.id = options.id;
    this.name = options.name;
    this.creationDate = deserializeDate(options.creationDate);
    this.appClientId = options.appClientId;
    this.appClientSecret = options.appClientSecret;
    this.location = options.location;
  }
}
