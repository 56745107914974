import { DateTimeString } from 'fabscale-app';
import { GoalResultStatus } from 'fabscale-app/models/enums/goal-result-status';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { Unit } from 'fabscale-app/models/enums/units';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import { deserializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export class ParameterGoal {
  id: string;
  parameterType: NumericRoastBatchParameterType;
  minValue: number;
  maxValue: number;
  unit: Unit;

  creationDate?: DateTime;
  lastModifiedDate?: DateTime;
  recipes?: { id: string; name: string }[];
  plantAssets?: { id: string; name: string }[];

  constructor(opts: ParameterGoalPojo) {
    Object.assign(this, opts);

    this.id = opts.id;
    this.parameterType = opts.parameterType;
    this.minValue = opts.minValue;
    this.maxValue = opts.maxValue;
    this.unit = opts.unit;

    this.recipes = opts.recipes ? sortBy(opts.recipes, 'name') : undefined;
    this.plantAssets = opts.plantAssets
      ? sortBy(opts.plantAssets, 'name')
      : undefined;

    this.creationDate = deserializeOptionalDate(opts.creationDate);
    this.lastModifiedDate = deserializeOptionalDate(opts.lastModifiedDate);
  }
}

export interface ParameterGoalPojo {
  id: string;
  parameterType: NumericRoastBatchParameterType;
  minValue: number;
  maxValue: number;
  unit: Unit;

  creationDate?: DateTimeString;
  lastModifiedDate?: DateTimeString;
  recipes?: { id: string; name: string }[];
  plantAssets?: { id: string; name: string }[];
}

export interface ParameterGoalInput {
  minValue: number;
  maxValue: number;
  parameterType: NumericRoastBatchParameterType;
  unit: Unit;
  recipeIds?: string[];
  plantAssetIds?: string[];
}

export interface ParameterGoalResult {
  goal?: { id: string };
  targetUnit: Unit;
  targetMinValue: number;
  targetMaxValue: number;
  actualValue?: number;
  status: GoalResultStatus;
  parameterType?: string;
}
