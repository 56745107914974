import { DateRangeOptional } from './date-range';
import { TimeRange } from './time-range';
import { tracked } from '@glimmer/tracking';
import { RelativeTimeframe } from './enums/relative-timeframe';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';
import { Interval } from './enums/intervals';
import { DayOfWeek } from './enums/day-of-week';
import { DateBinValue } from './date-bin-value';
import { IdNamePair } from './common';

export interface AlarmTypeReportFilters {
  dateRange: DateRangeOptional;
  timeRange?: TimeRange;
  alarmType?: PlantAssetAlarmType;
  alarmExternalIds?: string[];
  plantAssetIds?: string[];
  recipeIds?: string[];
  intervalDefinition?: {
    interval: Interval;
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    timezoneName: string;
  };
}

export class AlarmTypeReportFormData {
  @tracked dateRange: DateRangeOptional;
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked timeRange?: TimeRange;
  @tracked templateName = '';
  @tracked alarmType?: PlantAssetAlarmType;
  @tracked alarmExternalIds?: string[];
  @tracked plantAssetIds?: string[];
  @tracked recipeIds?: string[];
}

export interface PlantAlarmOverTimeTimeSeries {
  dateValueTimeSeries: DateBinValue[];
  idNamePair: IdNamePair;
}
