import ApplicationInstance from '@ember/application/instance';
import { getOwnConfig, importSync, macroCondition } from '@embroider/macros';

export function initialize(appInstance: ApplicationInstance) {
  if (macroCondition(getOwnConfig<any>().mockGraphqlApi)) {
    // We load this via importSync to ensure it is not loaded in production
    // @ts-ignore
    let { setupMockGraphql } = importSync(
      'fabscale-app/utils/mock-graphql/setup-mock-graphql'
    );

    // @ts-ignore
    setupMockGraphql(appInstance);
  }
}

export default {
  initialize,
};
