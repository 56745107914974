import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import StorePlantAssetAlertService from 'fabscale-app/services/store/plant-asset-alert';

export default class PlantAssetAlertsShowRoute extends AuthenticatedRoute {
  @service('store/plant-asset-alert')
  plantAssetAlertService: StorePlantAssetAlertService;

  model(params: { id: string }) {
    let { plantAssetAlertService } = this;
    let { id } = params;

    return plantAssetAlertService.findById(id);
  }
}
