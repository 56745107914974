import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import prettifyEnumString from 'fabscale-app/helpers/prettify-enum-string';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { ContinuousDataType } from 'fabscale-app/models/enums/plant-asset-monitoring';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import {
  TickableMonitoringSensor,
  MonitoringSensor,
  MonitoringSensorsFilters,
  MonitoringSensorsPayload,
  PlantAssetMonitoringFilters,
  PlantAssetMonitoringFormData,
  PlantAssetSensorsItem,
} from 'fabscale-app/models/plant-asset-monitoring';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import PdfService from 'fabscale-app/services/pdf';
import StorePlantAssetMonitoringService from 'fabscale-app/services/store/plant-asset-monitoring';
import UserSessionService from 'fabscale-app/services/user-session';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import {
  getDateRangeLength,
  getExactDateRangeWithTimeRange,
} from 'fabscale-app/utilities/utils/date-range';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { inRange } from 'fabscale-app/utilities/utils/in-range';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { DateTime } from 'luxon';
import StoreTemplate, {
  ITemplate,
  ITemplateInput,
  TemplateInput,
} from 'fabscale-app/services/store/template';
import jsonParse from 'fabscale-app/helpers/json-parse';
import { ReportType } from 'fabscale-app/models/enums/template';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';

interface Args {
  filters: PlantAssetMonitoringFilters;
  updateFilters: (filters: PlantAssetMonitoringFilters) => void;
  allPlantAssets: PlantAsset[];
  defaultTimeRange: TimeRange;
  canClose: boolean;
}

const EMPTY_SENSOR_PLACEHOLDER = {
  name: '',
  type: '',
  unit: '',
  isTicked: false,
};

export default class PagePlantAssetMonitoringFilters extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service enumLabels: EnumLabelsService;
  @service kpiDataFilter: KpiDataFilterService;
  @service userSession: UserSessionService;
  @service('store/plant-asset-monitoring')
  plantAssetMonitoringStore: StorePlantAssetMonitoringService;

  @tracked formModel: FormDataModel<PlantAssetMonitoringFormData>;
  @tracked formData: PlantAssetMonitoringFormData;

  @tracked allSensorCategories: ContinuousDataType[] =
    Object.values(ContinuousDataType);

  sensorCategoriesData: MonitoringSensorsPayload;

  @tracked
  filteredMonitoringSensors: any;

  @tracked
  searchTerm = '';

  @tracked
  sensorColors: { [key: string]: string } = {};

  @tracked
  sensorColorsReset = false;

  @tracked label: string;
  availableTemplates: KpiDataFilterTemplate[];
  @service('store/template')
  templateService: StoreTemplate;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    });

    if (dateRange && timeRange) {
      this.loadSensorCategories();
    }

    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    this.label =
      this.availableTemplates.length > 0
        ? 'Choose template or Time frame'
        : 'Choose timeframe';
  }

  private initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    plantAssetId: string | undefined;
    sensorCategories: ContinuousDataType[];
    sensorNames: string[];
  }) {
    const { l10n } = this;
    const {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    } = initialData;

    this.formData = new PlantAssetMonitoringFormData();

    Object.assign(this.formData, {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    });

    this.formModel = new FormDataModel<PlantAssetMonitoringFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },
          message: l10n.t('You have to select a date range.'),
        },
        {
          propertyName: 'plantAssetId',
          validate(value: string | undefined) {
            return !!value;
          },
          message: l10n.t('You have to select a roaster.'),
        },
      ],
    });
  }

  private computeMonitoringSensorsFilters(): MonitoringSensorsFilters {
    const exactDateRange = getExactDateRangeWithTimeRange(
      this.formData.dateRange as DateRange,
      this.formData.timeRange
    );

    return {
      locationId: this.locationId,
      dateFrom: exactDateRange.start,
      dateTo: exactDateRange.end,
    };
  }

  private async loadSensorCategories() {
    this.sensorCategoriesData =
      await this.plantAssetMonitoringStore.getMonitoringSensors(
        this.computeMonitoringSensorsFilters()
      );

    this.filterSensorCategoriesData();
  }

  private getPlantAssetSensorsItemByPlantAsset(
    plantAssetSensorsItems: PlantAssetSensorsItem[],
    plantAsset: PlantAsset
  ): PlantAssetSensorsItem | undefined {
    return plantAssetSensorsItems.find(
      (item: PlantAssetSensorsItem) => item.plantAsset.id === plantAsset?.id
    );
  }

  private getPlantAssetSensorsByCategory(
    plantAssetSensorsItemByPlantAsset: PlantAssetSensorsItem | undefined,
    selectedSensorCategories: ContinuousDataType[]
  ) {
    return selectedSensorCategories?.length
      ? plantAssetSensorsItemByPlantAsset?.monitoringSensors?.filter(
          (monitoringSensor: MonitoringSensor) =>
            selectedSensorCategories?.includes(monitoringSensor.type)
        )
      : (plantAssetSensorsItemByPlantAsset as PlantAssetSensorsItem)
          .monitoringSensors;
  }

  private resetSensorColors() {
    this.plantAssetMonitoringStore.setSensorColors({});
  }

  private filterSensorCategoriesData(): void {
    if (!this.sensorCategoriesData || !this.selectedPlantAsset) return;
    this.resetSensorColors();

    const plantAssetSensorsItemByPlantAsset =
      this.getPlantAssetSensorsItemByPlantAsset(
        this.sensorCategoriesData.items,
        this.selectedPlantAsset
      );

    const plantAssetSensorsByCategory = this.getPlantAssetSensorsByCategory(
      plantAssetSensorsItemByPlantAsset,
      this.selectedSensorCategories
    );

    if (!plantAssetSensorsByCategory?.length) {
      this.formModel.updateProperty('sensorNames', []);
      this.checkForTickedSensors();
    }

    if (plantAssetSensorsByCategory) {
      this.plantAssetMonitoringStore.setAllMonitoringSensorsByPlantAsset(
        plantAssetSensorsByCategory
      );
    }

    this.filteredMonitoringSensors = plantAssetSensorsByCategory;

    this.checkForTickedSensors();

    this.fillSensorsColors();

    this.fillSensorsGrid();
  }

  private fillSensorsGrid() {
    if (inRange(Number(this.filteredMonitoringSensors?.length % 16), 1, 3)) {
      this.filteredMonitoringSensors = [
        ...this.filteredMonitoringSensors,
        ...Array(4 - Number(this.filteredMonitoringSensors?.length % 16)).fill(
          EMPTY_SENSOR_PLACEHOLDER
        ),
      ];
    }
  }

  private checkForTickedSensors() {
    this.filteredMonitoringSensors?.map((sensor: TickableMonitoringSensor) => {
      sensor.isTicked = this.selectedSensorNames?.includes(sensor.name);
    });
  }

  private fillSensorsColors() {
    const sensorColors = this.plantAssetMonitoringStore.getSensorColors();

    if (sensorColors && Object.keys(sensorColors).length) {
      this.filteredMonitoringSensors.forEach(
        (sensor: TickableMonitoringSensor) =>
          (sensor.color = sensorColors[sensor.name])
      );
    } else {
      this.filteredMonitoringSensors.forEach(
        (sensor: TickableMonitoringSensor, index: number) => {
          sensor.color = chartColors[index % chartColors.length];
          this.sensorColors[sensor.name] = sensor.color!;
        }
      );

      this.plantAssetMonitoringStore.setSensorColors(this.sensorColors);
    }
  }

  @action
  handleTemplateChange(template: ITemplate) {
    const {
      relativeTimeframe,
      absoluteTimeframe,
      timeRange,
      plantAssetIds,
      sensorCategories,
      sensorNames,
    } = jsonParse(template.templateFilters);

    this.formData.relativeTimeframe = relativeTimeframe;
    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe)
      : absoluteTimeframe;

    this.formData.timeRange =
      timeRange && timeRange.start && timeRange.end
        ? (timeRange as TimeRange)
        : this.args.defaultTimeRange;

    if (plantAssetIds && plantAssetIds[0]) {
      this.formData.plantAssetId = plantAssetIds[0];
    }

    this.formData.sensorCategories = sensorCategories || [];
    this.formData.sensorNames = sensorNames || [];

    this.loadSensorCategories();
  }

  @action
  updatePlantAssets(plantAsset: PlantAsset) {
    this.formModel.updateProperty(
      'plantAssetId',
      plantAsset ? plantAsset.id : undefined
    );

    this.untickSensors();
    this.filterSensorCategoriesData();

    this.analytics.addEvent('plant-asset-monitoring-filter-plant-assets', [
      { name: 'selectedCount', value: plantAsset?.id },
    ]);
  }

  @action
  updateSensorCategories(sensorCategories: ContinuousDataType[]) {
    this.formModel.updateProperty('sensorCategories', sensorCategories);
    this.filterSensorCategoriesData();
  }

  onSearchForSensor = restartableTask(async (searchTerm: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.searchTerm = searchTerm;

    this.filteredMonitoringSensors = searchTerm
      ? filterRecords(this.filteredMonitoringSensors, searchTerm, {
          propertyName: 'name',
        })
      : this.plantAssetMonitoringStore.getAllMonitoringSensorByPlantAsset();

    this.fillSensorsGrid();
  });

  @action
  updateDateRange(dateRange: DateRange) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('plant-asset-monitoring-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);

    this.loadSensorCategories();
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('plant-asset-monitoring-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);

    this.loadSensorCategories();
  }

  @action
  onSelectRelativeTimeframe(relativeTimeframe?: RelativeTimeframe) {
    this.formData.relativeTimeframe = relativeTimeframe;
    const { timeRange } = this.formData;

    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe, timeRange)
      : { start: undefined, end: undefined };

    this.loadSensorCategories();
  }

  @action
  clearFilters() {
    this.initFormModel({
      dateRange: undefined,
      timeRange: this.args?.defaultTimeRange,
      plantAssetId: undefined,
      sensorCategories: [],
      sensorNames: [],
    });
  }

  @action
  resetFilters() {
    const {
      dateRange,
      timeRange = this.args?.defaultTimeRange,
      plantAssetId = undefined,
      sensorCategories = [],
      sensorNames = [],
    } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    });
  }

  @cached
  get exactDateRange(): DateRange | undefined {
    const { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange,
      maxNow: true,
    });
  }

  get activeFilters(): { label: string; value: string }[] {
    const { l10n } = this;

    const dateStart = this.args.filters?.dateRange?.start;
    const dateEnd = this.args.filters?.dateRange?.end;

    const filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Roasters'),
        value: this.selectedPlantAsset ? this.selectedPlantAsset.name : '-',
      },
      {
        label: l10n.t('Sensor categories'),
        value:
          this.selectedSensorCategories?.length > 0
            ? this.selectedSensorCategories
                .map((sensorCategory) => prettifyEnumString(sensorCategory))
                .join(', ')
            : l10n.t('All sensor categories'),
      },
      {
        label: l10n.t('Sensors'),
        value:
          this.selectedSensorNames?.length > 0
            ? this.selectedSensorNames.join(', ')
            : l10n.t('All sensors'),
      },
    ];

    return filters;
  }

  get filtersForTemplateSelection() {
    const {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    } = this.formData;

    return {
      dateRange,
      timeRange,
      plantAssetIds: [plantAssetId],
      sensorCategories,
      sensorNames,
    };
  }

  get selectedPlantAsset() {
    const allPlantAssets = this.args?.allPlantAssets;
    const { plantAssetId } = this.formData;

    if (!allPlantAssets || !plantAssetId) {
      return undefined;
    }

    return allPlantAssets.find(
      (plantAsset: any) => plantAssetId === plantAsset.id
    );
  }

  get selectedSensorCategories() {
    return this.formData.sensorCategories;
  }

  get selectedSensorNames() {
    return this.formData.sensorNames;
  }

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  printPageTask = dropTask(async () => {
    const fileName = `fabscale-plant-asset-monitoring-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    const isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    const {
      dateRange,
      timeRange,
      plantAssetId,
      templateName,
      sensorCategories,
      sensorNames,
      relativeTimeframe,
    } = this.formData;

    if (templateName) {
      const templateFilters = JSON.stringify({
        dateRange,
        relativeTimeframe,
        absoluteTimeframe: relativeTimeframe ? undefined : dateRange,
        timeRange,
        plantAssetIds: [plantAssetId as string],
        sensorNames,
        sensorCategories,
      });

      const template: ITemplateInput = new TemplateInput({
        userId: this.userSession.user!.id,
        templateName: templateName,
        templateFilters: templateFilters,
        reportType: ReportType.PLANT_ASSET_MONITORING,
      });

      await this.templateService.createTemplate(template);
      this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    }

    this.args.updateFilters({
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories: sensorCategories,
      sensorNames,
    });

    // Push selected filters to the GTM data layer
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'filter_used',
      reportName: ReportType.PLANT_ASSET_MONITORING,
      filters: {
        dateRange: dateRange
          ? {
              start: serializeOptionalDate(dateRange.start),
              end: serializeOptionalDate(dateRange.end),
            }
          : undefined,
        timeRange,
        plantAssetId,
        sensorCategories,
        sensorNames,
      },
    });

    return true;
  });

  @action
  onSensorTick(sensorToBeChecked: MonitoringSensor) {
    this.formModel.updateProperty(
      'sensorNames',
      this.selectedSensorNames
        ? [...this.selectedSensorNames, sensorToBeChecked.name]
        : [sensorToBeChecked.name]
    );

    this.checkForTickedSensors();
  }

  @action
  onSensorUntick(sensorToBeUnchecked: MonitoringSensor) {
    this.formModel.updateProperty(
      'sensorNames',
      this.selectedSensorNames
        ? [
            ...this.selectedSensorNames.filter(
              (sensorName) => sensorName !== sensorToBeUnchecked.name
            ),
          ]
        : []
    );

    this.checkForTickedSensors();
  }

  private untickSensors() {
    this.formModel.updateProperty('sensorNames', []);
  }
}
