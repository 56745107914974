import { Ability } from 'ember-can';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';

export default class PlantAssetAbility extends Ability {
  @service userSession: UserSessionService;

  get canEdit() {
    return Boolean(this.userSession.permissionMap.PLANT_ASSET_MANAGE);
  }

  get canView() {
    return Boolean(this.userSession.permissionMap.PLANT_ASSET_VIEW);
  }
}
