import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  toggledIndex: number | undefined;
  onToggle: (index: number) => void;
  onToggleOff: (index: number) => void;
}

export default class UiChartSelectorItem extends Component<Args> {
  get toggledIndex() {
    const { toggledIndex } = this.args;

    return toggledIndex;
  }

  @action
  onToggle(index: number) {
    this.args.onToggle(index);
  }

  @action
  onToggleOff(index: number) {
    this.args.onToggleOff(index);
  }
}
