import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';
import { Locale } from 'fabscale-app/models/enums/locales';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { Company } from 'fabscale-app/models/company';
import { Location } from 'fabscale-app/models/location';
import { User } from 'fabscale-app/models/user';
import CognitoService from './cognito';
import LuxonService from './luxon';
import SettingsService from './settings';
import UserAuthenticationService from './user-authentication';

export default class UserSessionService extends Service {
  @service cognito: CognitoService;
  @service luxon: LuxonService;
  @service l10n: L10nService;
  @service settings: SettingsService;
  @service userAuthentication: UserAuthenticationService;

  @tracked user?: User;
  @tracked company?: Company;
  @tracked locations: Location[] = [];
  @tracked currentLocation?: Location;

  @cached
  get permissionMap(): { [key in Permission]?: boolean } {
    if (!this.user) {
      return {};
    }

    let { permissions } = this.user.role;
    let map: { [key in Permission]?: boolean } = {};

    permissions.forEach((permission: Permission) => {
      map[permission] = true;
    });

    return map;
  }

  get features() {
    return this.company?.features || [];
  }

  async setupUserSession(): Promise<void> {
    let { user, company, locations } =
      await this.userAuthentication.loadUserData();

    this.user = user;
    this.company = company;
    this.locations = locations;

    Sentry.setUser({
      id: user.id,
      username: user.email,
      email: user.email,
    });

    Sentry.setTag('company_id', company.id);

    let locationId = this.userAuthentication.getCurrentLocationId();

    let currentLocation = locationId
      ? locations.find((location) => location.id === locationId)
      : undefined;

    if (currentLocation) {
      await this.setCurrentLocation(currentLocation);
    }

    // If there is just one location, directly sign into it
    // Note: if a locationId is set in the user data, we always redirect to the location selector
    // As this means that a location was set but is not available anymore
    if (locations.length === 1 && !locationId) {
      await this.setCurrentLocation(locations[0]!);
    }

    await this.setLocale(user.locale);
  }

  async setCurrentLocation(location: Location) {
    this.currentLocation = location;

    // This should usually always be set, except maybe in tests, so better safe than sorry!
    let { timezoneName } = location;
    if (timezoneName) {
      this.luxon.setTimeZone(timezoneName);
    }

    Sentry.setTag('location_id', location.id);

    this.userAuthentication.setCurrentLocationId(location.id);

    await this.settings.loadLocationSettings();
  }

  async setLocale(locale: Locale) {
    // GraphQL enum does not support `-`, so we use `_` there
    let fixedLocale = locale.replace('_', '-');

    await this.l10n.setLocale(fixedLocale);
    await this.luxon.setLocale(fixedLocale);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-session': UserSessionService;
  }
}
