import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';

export default class PlantPlantAssetsShowIndexRoute extends AuthenticatedRoute {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  model(params: { id: string }) {
    let { plantAssetStore } = this;
    let { id } = params;

    return plantAssetStore.findById(id);
  }
}
