import { DashboardCardPosition } from 'fabscale-app/models/dashboard-config';

interface Pos {
  x: number;
  y: number;
}

export function tryReplaceCardPositionsManually<
  T extends DashboardCardPosition
>(
  movedCard: T,
  originalCard: T,
  movedCards: T[],
  cardsToMove: T[]
): T[] | undefined {
  // Special cases
  // A A A B should return B A A A
  // but by default would return A B A A
  // A A A A B should return B A A A A, but would return A B A A A

  // In these cases, we move the original one over and just try to fit the others as good as possible

  let positionsToFill = findPositions(originalCard);
  let movedCardNewPositions = findPositions(movedCard);

  try {
    let reorderedCards = replaceCardPositionsManually(
      movedCardNewPositions,
      positionsToFill,
      cardsToMove
    );

    reorderedCards.forEach((card) => {
      let index = movedCards.findIndex((findCard) => findCard.id === card.id);

      if (index > -1) {
        movedCards.splice(index, 1, card);
      }
    });

    return movedCards;
  } catch (error) {
    return undefined;
  }
}

function findPositions<T extends DashboardCardPosition>(card: T): Pos[] {
  let pos: Pos[] = [];

  for (let y = card.y; y < card.y + card.height; y++) {
    for (let x = card.x; x < card.x + card.width; x++) {
      pos.push({ x, y });
    }
  }

  return pos;
}

function replaceCardPositionsManually<T extends DashboardCardPosition>(
  movedCardNewPositions: Pos[],
  positionsToFill: Pos[],
  cardsToMove: T[]
) {
  // Special cases
  // A A A B should return B A A A
  // but by default would return A B A A
  // A A A A B should return B A A A A, but would return A B A A A

  // In these cases, we move the original one over and just try to fit the others as good as possible

  // Remove new positions from originalPositions
  for (let newPos of movedCardNewPositions) {
    let index = positionsToFill.findIndex(
      (pos) => pos.x === newPos.x && pos.y === newPos.y
    );

    if (index > -1) {
      positionsToFill.splice(index, 1);
    }
  }

  // sort so that largest cards come first, so we can try moving them first
  cardsToMove.sort((a, b) => {
    if (a.width === b.width) {
      return b.height - a.height;
    }

    return b.width - a.width;
  });

  let movedCards: T[] = [];

  for (let card of cardsToMove) {
    let newPos = findFreePositions(positionsToFill, card);

    // This means we could not fit stuff :(
    if (!newPos || newPos.length === 0) {
      throw new Error(
        `Could not find free positions in grid for card ${card.id}`
      );
    }

    // Remove found pos from list to select from
    positionsToFill = positionsToFill.filter((pos) => !newPos!.includes(pos));

    let firstPos = newPos[0]!;

    let newCard = Object.assign({}, card, {
      x: firstPos.x,
      y: firstPos.y,
    });

    movedCards.push(newCard);
  }

  return movedCards;
}

function findFreePositions(
  availablePositions: Pos[],
  { width, height }: { width: number; height: number }
): Pos[] | undefined {
  for (let pos of availablePositions) {
    if (!fits(availablePositions, pos, { width, height })) {
      continue;
    }

    return findAllPos(pos, availablePositions, { width, height });
  }
}

function findAllPos(
  origin: Pos,
  availablePositions: Pos[],
  { width, height }: { width: number; height: number }
) {
  let allPos: Pos[] = [];

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      allPos.push(
        availablePositions.find(
          (pos) => pos.x === origin.x + x && pos.y === origin.y + y
        )!
      );
    }
  }

  return allPos;
}

function fits(
  availablePositions: Pos[],
  pos: Pos,
  size: { height: number; width: number }
) {
  if (size.height === 1 && size.width === 1) {
    return true;
  }

  // Make sure there are following positions to accomodate width/height
  for (let x = 0; x < size.width; x++) {
    for (let y = 0; y < size.height; y++) {
      let nextPos = { x: pos.x + x, y: pos.y + y };

      let hasPos = availablePositions.some(
        (pos) => pos.x === nextPos.x && pos.y === nextPos.y
      );

      if (!hasPos) {
        return false;
      }
    }
  }

  return true;
}
