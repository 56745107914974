import Component from '@glimmer/component';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import { getDateFormatForInterval } from 'fabscale-app/utilities/utils/date-interval';
import { DateTime } from 'luxon';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';

interface Args {
  intervalDefinition: {
    interval: Interval;
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    timezoneName: string;
  };
  data: ChartData;
  options: ChartOptions;
  plugins: Plugin[];
  toggledDatasetIndex: number | undefined;
}

export default class PageAlarmReportLevelReportPerRecipeChartOvertime extends Component<Args> {
  get data() {
    const { data } = this.args;

    return data;
  }

  chartTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.width = '256px';
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;
      table.style.width = '100%';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);
      const tableHead = document.createElement('thead');
      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      const dateFrom = DateTime.fromISO(
        tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
          .dateFrom
      ).toLocaleString(DateTime.DATETIME_SHORT);
      const dateTo = DateTime.fromISO(
        tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
          .dateTo
      ).toLocaleString(DateTime.DATETIME_SHORT);
      const dateLabel = `${dateFrom} - ${dateTo}`;

      bodyLines.forEach((body: any, i: any) => {
        const color = tooltip.dataPoints[i].dataset.borderColor;
        const alarmsValue = tooltip.dataPoints[i].raw.alarms;
        const warningsValue = tooltip.dataPoints[i].raw.warnings;
        const infosValue = tooltip.dataPoints[i].raw.infos;
        const createColoredSquare = () => {
          const coloredSquare = document.createElement('span');
          coloredSquare.style.background = color;
          coloredSquare.style.borderColor = color;
          coloredSquare.style.borderWidth = cssObj.spacings._0px;
          coloredSquare.style.marginRight = cssObj.spacings._10px;
          coloredSquare.style.height = cssObj.spacings._12px;
          coloredSquare.style.width = cssObj.spacings._12px;
          coloredSquare.style.display = cssObj.display.inlineBlock;

          return coloredSquare;
        };

        const createTooltipRow = (label: string, value: string) => {
          const rowTextContainerLabel = document.createElement('p');
          rowTextContainerLabel.innerHTML = label;
          rowTextContainerLabel.style.marginRight = cssObj.spacings._8px;
          rowTextContainerLabel.style.marginBottom = '0px';

          const rowTextContainerValue = document.createElement('p');
          rowTextContainerValue.innerHTML = value;
          rowTextContainerValue.style.fontWeight = '700';

          const row = document.createElement('div');
          row.style.display = cssObj.display.flex;
          row.style.flexDirection = cssObj.flex.flexDirection.row;
          row.style.width = '100%';
          row.style.alignItems = 'baseline';

          const rowTextContainer = document.createElement('div');
          rowTextContainer.style.width = '100%';
          rowTextContainer.style.display = cssObj.display.flex;
          rowTextContainer.style.flexDirection = cssObj.flex.flexDirection.row;
          rowTextContainer.style.justifyContent = 'space-between';

          rowTextContainer.append(rowTextContainerLabel, rowTextContainerValue);
          row.append(createColoredSquare(), rowTextContainer);

          return row;
        };

        const tableDataRowContainer = document.createElement('div');
        tableDataRowContainer.style.width = '100%';
        tableDataRowContainer.style.display = 'flex';
        tableDataRowContainer.style.flexDirection = 'column';
        tableDataRowContainer.style.alignItems = 'center';

        tableDataRowContainer.append(createTooltipRow('Alarms:', alarmsValue));
        tableDataRowContainer.append(
          createTooltipRow('Warnings:', warningsValue)
        );
        tableDataRowContainer.append(createTooltipRow('Infos:', infosValue));

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        td.appendChild(tableDataRowContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const p = document.createElement('p');
      p.style.marginBottom = cssObj.spacings._0px;
      p.style.color = cssObj.colors.sonicSilver;
      p.style.fontSize = cssObj.spacings._12px;
      p.innerHTML = dateLabel;

      tableBody.appendChild(p);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${
      Number(positionY) + Number(tooltip.caretY) + 40
    }px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  get options() {
    const { intervalDefinition } = this.args;
    const { interval } = intervalDefinition;
    const ticksDateFormat = getDateFormatForInterval(interval);

    return {
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this.chartTooltip,
        },
      },
      scales: {
        x: {
          offset: true,
          border: {
            display: true,
          },
          grid: {
            display: true,
            drawTicks: true,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
          ticks: {
            display: true,
            autoSkip: false,
            source: 'data',
            callback: function (value: any) {
              const dateFrom = DateTime.fromISO(
                (this as any).getLabelForValue(value)
              ).toLocaleString(ticksDateFormat);
              const dateTo = DateTime.fromISO(
                (this as any).getLabelForValue(value)
              )
                .plus({ hours: 1 })
                .toLocaleString(ticksDateFormat);

              if (ticksDateFormat.hour) {
                let formatedTick = `${dateFrom} - ${dateTo}`;

                formatedTick = formatedTick.replaceAll('AM', '');
                formatedTick = formatedTick.replaceAll('PM', '');

                return formatedTick;
              }

              if (ticksDateFormat.month) {
                return dateFrom;
              }
            },
          },
        },
        y: {
          beginAtZero: true,
          offset: false,
          position: cssObj.position.left,
          ticks: {
            display: function (context: any) {
              const hasRecords =
                !!context.scale.chart.config._config.data.datasets.length;
              return hasRecords;
            },
            maxTicksLimit: 5,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
            lineWidth: (context: any) => Number(!!context.index),
          },
        },
      },
    };
  }
}
