import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Unit, UnitTime } from 'fabscale-app/models/enums/units';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import {
  formatNumber,
  FormatNumberOptions,
} from 'fabscale-app/utilities/utils/format-number';
import formatDurationHelper from './format-duration';
import unitIsDurationHelper from './unit-is-duration';

export default class FormatAmountOrDurationHelper extends Helper<{
  PositionalArgs: [
    amount: number,
    unit: Unit,
    numberFormatOptions: FormatNumberOptions | undefined
  ];
}> {
  @service enumLabels: EnumLabelsService;

  compute([amount, unit, numberFormatOptions]: [
    number,
    Unit,
    FormatNumberOptions | undefined
  ]) {
    if (unitIsDurationHelper(unit)) {
      return formatDurationHelper(amount, unit as UnitTime);
    }

    let unitString = this.enumLabels.unit(unit);

    return `${formatNumber(amount, numberFormatOptions)} ${unitString}`;
  }
}
