import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { MaintenanceTaskFiltersSerializable } from 'fabscale-app/components/page/maintenance-tasks/filters';

export default class MaintenanceTasksOpenController extends Controller {
  queryParams = ['filters'];

  // Serialzed form
  @tracked filters: string | null = null;

  @action
  updateFilters(filters: MaintenanceTaskFiltersSerializable) {
    let normalizedFilters =
      filters && Object.keys(filters).length > 0 ? filters : null;

    this.filters = normalizedFilters ? JSON.stringify(normalizedFilters) : null;
  }
}
