import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import {
  DateString,
  DateTimeString,
  PageDef,
  PageInfo,
  SortDef,
} from 'fabscale-app';
import { TaskCategory } from 'fabscale-app/models/enums/task-categories';
import { TaskRequirement } from 'fabscale-app/models/enums/task-requirements';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import addFileToMaintenanceTaskMutation from 'fabscale-app/gql/mutations/add-file-to-maintenance-task.graphql';
import createCommentMutation from 'fabscale-app/gql/mutations/create-comment-for-maintenance-task.graphql';
import createMaintenanceTaskRepeatingMutation from 'fabscale-app/gql/mutations/create-maintenance-task-repeating.graphql';
import createMaintenanceTaskMutation from 'fabscale-app/gql/mutations/create-maintenance-task.graphql';
import deleteCommentMutation from 'fabscale-app/gql/mutations/delete-comment.graphql';
import deleteMaintenanceTaskMutation from 'fabscale-app/gql/mutations/delete-maintenance-task.graphql';
import removeFileFromMaintenanceTaskMutation from 'fabscale-app/gql/mutations/remove-file-from-maintenance-task.graphql';
import updateCommentMutation from 'fabscale-app/gql/mutations/update-comment.graphql';
import updateMaintenanceTaskDueDateMutation from 'fabscale-app/gql/mutations/update-maintenance-task-due-date.graphql';
import updateMaintenanceTaskRepeatConfigMutation from 'fabscale-app/gql/mutations/update-maintenance-task-repeat-config.graphql';
import updateMaintenanceTaskStatusMutation from 'fabscale-app/gql/mutations/update-maintenance-task-status.graphql';
import updateMaintenanceTaskMutation from 'fabscale-app/gql/mutations/update-maintenance-task.graphql';
import queryFindById from 'fabscale-app/gql/queries/maintenance-task-by-id.graphql';
import queryCount from 'fabscale-app/gql/queries/maintenance-task-count.graphql';
import queryDates from 'fabscale-app/gql/queries/maintenance-task-dates.graphql';
import querySearch from 'fabscale-app/gql/queries/maintenance-task-search.graphql';
import queryRelated from 'fabscale-app/gql/queries/maintenance-tasks-related.graphql';
import { Comment, CommentInput } from 'fabscale-app/models/comment';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import {
  FileWrapper,
  FileWrapperInput,
} from 'fabscale-app/models/file-wrapper';
import {
  MaintenanceTask,
  MaintenanceTaskInput,
} from 'fabscale-app/models/maintenance-task';
import { PlantAsset, PlantAssetInput } from 'fabscale-app/models/plant-asset';
import {
  PlantAssetArea,
  PlantAssetAreaInput,
} from 'fabscale-app/models/plant-asset-area';
import {
  deserializeRepeatConfig,
  RepeatConfigInput,
  RepeatConfigPojo,
  serializeRepeatConfigInput,
} from 'fabscale-app/models/repeat-config';
import { UserInfo, UserInfoInput } from 'fabscale-app/models/user-info';
import GraphQLService from 'fabscale-app/services/-graphql';
import UserSessionService from 'fabscale-app/services/user-session';
import {
  deserializeDate,
  deserializeOptionalDate,
  serializeLocalDate,
  serializeOptionalLocalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export interface PaginatedMaintenanceTask {
  pageInfo: PageInfo;
  tasks: MaintenanceTask[];
}

export interface MaintenanceTaskQueryOptions {
  search?: string;
  category?: TaskCategory;
  status: TaskStatus;
  assignedUserIds?: string[];
  plantAssetIds?: string[];
  plantAssetAreaIds?: string[];
  requirements?: TaskRequirement[];
  dateRange?: DateRangeOptional;
}

export interface MaintenanceTaskPojo {
  id: string;
  title: string;
  description: string;
  category: TaskCategory;
  status: TaskStatus;
  requirements: TaskRequirement[];

  // Dates
  dueDate: DateString;
  creationDate: DateTimeString;
  lastModifiedDate: DateTimeString;
  completionDate?: DateTimeString;

  // Relationships
  createdBy: UserInfoInput;
  lastModifiedBy: UserInfoInput;
  assignedUser?: UserInfoInput;
  plantAsset?: PlantAssetInput;
  plantAssetArea?: PlantAssetAreaInput;
  comments?: CommentInput[];
  files?: FileWrapperInput[];
  repeatConfig?: RepeatConfigPojo;
  previousMaintenanceTask?: MaintenanceTaskPojo;
}

export interface MaintenanceTaskGraphQLFilters {
  locationId: string;
  status: TaskStatus;
  search?: string;
  category?: TaskCategory;
  assignedUserIds?: string[];
  plantAssetIds?: string[];
  plantAssetAreaIds?: string[];
  requirements?: TaskRequirement[];
  dateFrom?: DateString;
  dateTo?: DateString;
}

interface PaginatedMaintenanceTaskPojo {
  items: MaintenanceTaskPojo[];
  pageInfo: PageInfo;
}

export interface MaintenanceTaskCountByDueDate {
  date: DateTime;
  count: number;
}

export default class StoreMaintenanceTaskService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  // METHODS

  async findById(id: string): Promise<MaintenanceTask> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let task: MaintenanceTaskPojo = await graphql.query(
        { query: queryFindById, variables, namespace: 'maintenanceTaskById' },
        {
          cacheEntity: 'MaintenanceTask',
          cacheId: id,
          cacheSeconds: 300,
        }
      );

      return this._normalizeTask(task);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The task with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async queryPaginated(
    {
      search,
      category,
      status,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateRange,
    }: MaintenanceTaskQueryOptions,
    pageDef: PageDef,
    { sortBy, sortDirection }: SortDef
  ): Promise<PaginatedMaintenanceTask> {
    let { graphql, locationId } = this;

    assert(
      `maintenanceTaskStore.queryPaginated: You have to specify sortBy`,
      !!sortBy
    );
    assert(
      `maintenanceTaskStore.queryPaginated: You have to specify sortDirection`,
      !!sortDirection
    );

    let filters: MaintenanceTaskGraphQLFilters = {
      locationId,
      search,
      category,
      status,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateFrom: serializeOptionalLocalDate(dateRange?.start),
      dateTo: serializeOptionalLocalDate(dateRange?.end),
    };

    let variables = {
      filters,
      pageDef,
      sortBy,
      sortDirection,
    };

    let { items, pageInfo }: PaginatedMaintenanceTaskPojo = await graphql.query(
      { query: querySearch, variables, namespace: 'maintenanceTasksPaginated' },
      {
        cacheSeconds: 10,
        cacheEntity: 'MaintenanceTask',
      }
    );

    return {
      tasks: items.map((task) => this._normalizeTask(task)),
      pageInfo,
    };
  }

  async queryCount({
    search,
    category,
    assignedUserIds,
    plantAssetIds,
    plantAssetAreaIds,
    requirements,
    dateRange,
  }: {
    search?: string;
    category?: TaskCategory;
    assignedUserIds?: string[];
    plantAssetIds?: string[];
    plantAssetAreaIds?: string[];
    requirements?: TaskRequirement[];
    dateRange?: DateRangeOptional;
  }): Promise<{
    open: number;
    completed: number;
    total: number;
  }> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      search,
      category,
      assignedUserIds,
      plantAssetIds,
      plantAssetAreaIds,
      requirements,
      dateFrom: serializeOptionalLocalDate(dateRange?.start),
      dateTo: serializeOptionalLocalDate(dateRange?.end),
    };

    let response: {
      open: {
        pageInfo: PageInfo;
      };
      completed: {
        pageInfo: PageInfo;
      };
    } = await graphql.query(
      { query: queryCount, variables },
      {
        cacheSeconds: 10,
        cacheEntity: 'MaintenanceTask',
      }
    );

    let open = response.open.pageInfo.totalItemCount;
    let completed = response.completed.pageInfo.totalItemCount;

    return { open, completed, total: open + completed };
  }

  async queryRelated({
    plantAssetAreaId,
  }: {
    plantAssetAreaId: string;
  }): Promise<MaintenanceTask[]> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      plantAssetAreaIds: [plantAssetAreaId],
    };

    let response: {
      openComponent: PaginatedMaintenanceTaskPojo;
      completedComponent: PaginatedMaintenanceTaskPojo;
    } = await graphql.query(
      { query: queryRelated, variables },
      {
        cacheEntity: 'MaintenanceTask',
        cacheSeconds: 300,
      }
    );

    let openTasks = response.openComponent.items.map(
      (task: MaintenanceTaskPojo) => {
        return this._normalizeTask(task);
      }
    );

    let completedTasks = response.completedComponent.items.map(
      (task: MaintenanceTaskPojo) => {
        return this._normalizeTask(task);
      }
    );

    return openTasks.concat(completedTasks);
  }

  async queryCountPerDueDate({
    assignedUserId,
    dateRange,
  }: {
    assignedUserId?: string;
    dateRange: DateRange;
  }): Promise<MaintenanceTaskCountByDueDate[]> {
    let { graphql, locationId } = this;

    let filters: MaintenanceTaskGraphQLFilters = {
      locationId,
      status: 'OPEN',
      assignedUserIds: assignedUserId ? [assignedUserId] : undefined,
      dateFrom: serializeLocalDate(dateRange.start),
      dateTo: serializeLocalDate(dateRange.end),
    };

    let variables = {
      filters,
    };

    let response: PaginatedMaintenanceTaskPojo = await graphql.query(
      { query: queryDates, variables, namespace: 'maintenanceTasksPaginated' },
      {
        cacheSeconds: 10,
        cacheEntity: 'MaintenanceTask',
      }
    );

    let groups: MaintenanceTaskCountByDueDate[] = [];

    response.items.forEach(({ dueDate }) => {
      let date = deserializeDate(dueDate).startOf('day');

      let group = groups.find((group) => +group.date === +date);
      if (group) {
        group.count++;
      } else {
        groups.push({
          date,
          count: 1,
        });
      }
    });

    return groups;
  }

  async create({
    title,
    description,
    category,
    dueDate,
    requirements,
    plantAssetId,
    plantAssetAreaId,
    assignedUserId,
    estimatedDurationSeconds,
  }: {
    title: string;
    description?: string;
    category: TaskCategory;
    dueDate: DateTime;
    requirements?: TaskRequirement[];
    plantAssetId?: string;
    plantAssetAreaId?: string;
    assignedUserId?: string;
    estimatedDurationSeconds?: number;
  }): Promise<{ id: string }> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      input: {
        title,
        description,
        category,
        estimatedDurationSeconds,
        requirements,
        plantAssetId,
        plantAssetAreaId,
        assignedUserId,
      },
      dueDate: serializeLocalDate(dueDate),
    };

    let { id }: { id: string } = await graphql.mutate(
      {
        mutation: createMaintenanceTaskMutation,
        variables,
        namespace: 'createMaintenanceTask',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
          },
        ],
      }
    );

    return { id };
  }

  async createRepeating(
    {
      title,
      description,
      category,
      requirements,
      plantAssetId,
      plantAssetAreaId,
      assignedUserId,
      estimatedDurationSeconds,
    }: {
      title: string;
      description?: string;
      category: TaskCategory;
      requirements?: TaskRequirement[];
      plantAssetId?: string;
      plantAssetAreaId?: string;
      assignedUserId?: string;
      estimatedDurationSeconds?: number;
    },
    repeatConfig: RepeatConfigInput
  ): Promise<{ id: string }> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      input: {
        title,
        description,
        category,
        estimatedDurationSeconds,
        requirements,
        plantAssetId,
        plantAssetAreaId,
        assignedUserId,
      },
      repeatConfig: serializeRepeatConfigInput(repeatConfig),
    };

    let { id }: { id: string } = await graphql.mutate(
      {
        mutation: createMaintenanceTaskRepeatingMutation,
        variables,
        namespace: 'createMaintenanceTaskRepeating',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
          },
        ],
      }
    );

    return { id };
  }

  async update(
    id: string,
    {
      title,
      description,
      category,
      // dueDate,
      requirements,
      estimatedDurationSeconds,
      plantAssetId,
      plantAssetAreaId,
      assignedUserId,
    }: {
      title: string;
      description?: string;
      category: TaskCategory;
      dueDate: DateTime;
      requirements?: TaskRequirement[];
      estimatedDurationSeconds?: number;
      plantAssetId?: string;
      plantAssetAreaId?: string;
      assignedUserId?: string;
    }
  ): Promise<{ id: string }> {
    let { graphql } = this;

    let variables = {
      id,
      input: {
        title,
        description,
        category,
        requirements,
        estimatedDurationSeconds,
        plantAssetId,
        plantAssetAreaId,
        assignedUserId,
      },
    };

    // TODO FN: Also set dueDate in separate mutation

    await graphql.mutate(
      {
        mutation: updateMaintenanceTaskMutation,
        variables,
        namespace: 'updateMaintenanceTask',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
          },
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: id,
          },
        ],
      }
    );

    return { id };
  }

  async delete(id: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate(
      {
        mutation: deleteMaintenanceTaskMutation,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
          },
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: id,
          },
        ],
      }
    );
  }

  async updateStatus(
    id: string,
    { status }: { status: TaskStatus }
  ): Promise<{ id: string; status: TaskStatus; completionDate?: DateTime }> {
    let { graphql } = this;

    let variables = {
      id,
      status,
    };

    let { completionDate }: { completionDate?: DateTimeString } =
      await graphql.mutate(
        {
          mutation: updateMaintenanceTaskStatusMutation,
          variables,
          namespace: 'updateMaintenanceTaskStatus',
        },
        {
          invalidateCache: [
            {
              cacheEntity: 'MaintenanceTask',
            },
            {
              cacheEntity: 'MaintenanceTask',
              cacheId: id,
            },
          ],
        }
      );

    return {
      id,
      status,
      completionDate: deserializeOptionalDate(completionDate),
    };
  }

  async updateRepeatConfig(
    id: string,
    repeatConfig: RepeatConfigInput
  ): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
      repeatConfig: serializeRepeatConfigInput(repeatConfig),
    };

    await graphql.mutate(
      {
        mutation: updateMaintenanceTaskRepeatConfigMutation,
        variables,
        namespace: 'updateMaintenanceTaskRepeatConfig',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: id,
          },
        ],
      }
    );
  }

  async updateDueDate(id: string, dueDate: DateTime): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
      dueDate: serializeLocalDate(dueDate),
    };

    await graphql.mutate(
      {
        mutation: updateMaintenanceTaskDueDateMutation,
        variables,
        namespace: 'updateMaintenanceTaskDueDate',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: id,
          },
        ],
      }
    );
  }

  async createComment(
    maintenanceTaskId: string,
    { message }: { message: string }
  ): Promise<Comment> {
    let { graphql } = this;

    let variables = {
      maintenanceTaskId,
      message,
    };

    let commentData: CommentInput = await graphql.mutate(
      {
        mutation: createCommentMutation,
        variables,
        namespace: 'createMaintenanceTaskComment',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: maintenanceTaskId,
          },
        ],
      }
    );

    return new Comment(commentData);
  }

  async updateComment(
    commentId: string,
    {
      message,
      maintenanceTaskId,
    }: { message: string; maintenanceTaskId: string }
  ): Promise<Comment> {
    let { graphql } = this;

    let variables = {
      id: commentId,
      message,
    };

    let commentData: CommentInput = await graphql.mutate(
      {
        mutation: updateCommentMutation,
        variables,
        namespace: 'updateComment',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: maintenanceTaskId,
          },
        ],
      }
    );

    return new Comment(commentData);
  }

  async deleteComment(
    id: string,
    { maintenanceTaskId }: { maintenanceTaskId: string }
  ): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate(
      {
        mutation: deleteCommentMutation,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: maintenanceTaskId,
          },
        ],
      }
    );
  }

  async addFileToMaintenanceTask(taskId: string, fileId: string) {
    let { graphql } = this;

    let variables = {
      taskId,
      fileId,
    };

    await graphql.mutate(
      {
        mutation: addFileToMaintenanceTaskMutation,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: taskId,
          },
        ],
      }
    );
  }

  async removeFileFromMaintenanceTask(
    taskId: string,
    fileId: string
  ): Promise<{ fileWasDeleted: boolean }> {
    let { graphql } = this;

    let variables = {
      taskId,
      fileId,
    };

    return graphql.mutate(
      {
        mutation: removeFileFromMaintenanceTaskMutation,
        variables,
        namespace: 'removeFileFromMaintenanceTask',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'MaintenanceTask',
            cacheId: taskId,
          },
        ],
      }
    );
  }

  _normalizeTask(task: MaintenanceTaskPojo) {
    let {
      plantAsset,
      plantAssetArea,
      assignedUser,
      createdBy,
      lastModifiedBy,
      comments,
      files,
      repeatConfig,
      previousMaintenanceTask,
    } = task;

    let taskInput: MaintenanceTaskInput = Object.assign({}, task, {
      plantAsset: plantAsset ? new PlantAsset(plantAsset) : undefined,

      plantAssetArea: plantAssetArea
        ? new PlantAssetArea(plantAssetArea)
        : undefined,

      assignedUser: assignedUser ? new UserInfo(assignedUser) : undefined,
      createdBy: createdBy ? new UserInfo(createdBy) : undefined,
      lastModifiedBy: lastModifiedBy ? new UserInfo(lastModifiedBy) : undefined,

      comments: comments
        ? comments.map((commentData) => new Comment(commentData))
        : [],

      files: files ? files.map((fileData) => new FileWrapper(fileData)) : [],
      repeatConfig: repeatConfig
        ? deserializeRepeatConfig(repeatConfig)
        : undefined,

      previousMaintenanceTask: previousMaintenanceTask
        ? this._normalizeTask(previousMaintenanceTask)
        : undefined,
    });

    return new MaintenanceTask(taskInput);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/maintenance-task': StoreMaintenanceTaskService;
  }
}
