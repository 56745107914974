import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRangeOptional } from 'fabscale-app/models/time-range';
import { dateToIsoTimeString } from 'fabscale-app/utilities/utils/parse-time';

export default function serializeDateRangeHelper(dateRange: DateRangeOptional) {
  let startTime = dateRange.start
    ? dateToIsoTimeString(dateRange.start)
    : undefined;
  let endTime = dateRange.end ? dateToIsoTimeString(dateRange.end) : undefined;

  let timeRange: TimeRangeOptional = { start: startTime, end: endTime };

  return JSON.stringify(timeRange);
}
