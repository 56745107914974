import Component from '@glimmer/component';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { ChartData, ChartOptions } from 'chart.js';
import { action } from '@ember/object';

interface Args {
  data: ChartData;
  options: ChartOptions;
}

export default class PageOeeKpiReportOverTimeOverTimeCardChart extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  @tracked
  toggledDatasetIndex: number | undefined;

  get data() {
    const { data } = this.args;

    return data;
  }

  get options() {
    const { options } = this.args;

    return options;
  }

  get chartSelectorItems() {
    return this.data.datasets.map((dataset) => {
      return {
        label: (dataset.data[0] as any).label,
        color: dataset.borderColor,
      };
    });
  }

  @action
  updateToggledDatasetIndex(index: number) {
    this.toggledDatasetIndex = index;
  }
}
