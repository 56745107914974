import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { PlantAlarmFilters } from 'fabscale-app/models/alarm-report';
import { TimeRange } from 'fabscale-app/models/time-range';
import SettingsService from 'fabscale-app/services/settings';
import StoreAlarmLevelReportService from 'fabscale-app/services/store/alarm-level-report';
import UserSessionService from 'fabscale-app/services/user-session';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import { AlarmTypeReportFilters } from 'fabscale-app/models/alarm-type-report';
import getOverTimeInterval from 'fabscale-app/helpers/get-over-time-interval';
import { getExactDateRangeWithTimeRange } from 'fabscale-app/utilities/utils/date-range';
import { DateRange } from 'fabscale-app/models/date-range';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';

interface PageAlarmReportTypeReportArgs {
  filters: AlarmTypeReportFilters;
  updateFilters: (filters: AlarmTypeReportFilters) => void;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: PlantAssetAlarmSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageAlarmReportTypeReport extends Component<PageAlarmReportTypeReportArgs> {
  @service settings: SettingsService;
  @service userSession: UserSessionService;
  @service('store/alarm-level-report')
  alarmLevelReportStore: StoreAlarmLevelReportService;

  @action
  updateFilters(filters: AlarmTypeReportFilters) {
    const {
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
      intervalDefinition,
    } = filters;

    this.args.updateFilters({
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
      intervalDefinition,
    });
  }

  get filters() {
    const {
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
    } = this.args.filters || {};

    return {
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
    };
  }

  get plantAlarmFilters(): PlantAlarmFilters {
    const {
      dateRange,
      plantAssetIds,
      recipeIds,
      alarmType,
      alarmExternalIds,
      intervalDefinition,
    } = this.args.filters || {};
    return {
      dateFrom: dateRange!.start!,
      dateTo: dateRange!.end!,
      plantAssetIds: plantAssetIds,
      recipeIds: recipeIds,
      alarmType: alarmType,
      alarmExternalIds: alarmExternalIds,
      intervalDefinition: intervalDefinition,
    };
  }

  get plantAlarmTypeReportFilters(): AlarmTypeReportFilters {
    const {
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
      alarmType,
      alarmExternalIds,
    } = this.args.filters || {};

    return {
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      recipeIds,
      plantAssetIds,
      intervalDefinition: this.intervalDefinition,
    };
  }

  get timeRange(): TimeRange {
    return Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );
  }

  get defaultTimeRange() {
    const { dayStartTime } = this.settings.locationSettings;
    const end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get intervalDefinition() {
    const { startDayOfWeek, dayStartTime } = this.settings.locationSettings;
    const { timezoneName } = this.userSession.currentLocation!;

    const dateRangeOpt = this.args.filters.dateRange;
    let startDate = dateRangeOpt!.start!;
    let endDate = dateRangeOpt!.end!;
    const dateRange = new DateRange({ start: startDate, end: endDate });

    return {
      startDayOfWeek,
      dayStartTime,
      interval: getOverTimeInterval(
        getExactDateRangeWithTimeRange(dateRange, this.timeRange)
      ),
      timezoneName,
    };
  }

  get hasAllRequiredData() {
    return Boolean(
      this.args.filters?.dateRange?.start && this.args.filters?.dateRange?.end
    );
  }
}
