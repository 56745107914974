import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class UiSelectMultiple extends Component {
  @action
  onKeyDown(dropdown: any, event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      return;
    }

    // Prevent submitting of a form here
    event.preventDefault();

    // When open, handle selecting/unselecting currently highlighted item

    if (!dropdown.isOpen) {
      return;
    }

    event.stopPropagation();

    // Nothing selected, just close
    if (dropdown.highlighted === undefined) {
      dropdown.actions.close(event);
      return false;
    }

    // Select/unselect currently highlighted item
    dropdown.actions.choose(dropdown.highlighted, event);

    return false;
  }
}
