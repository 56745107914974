import { CSS_COLORS, ICssConfig } from './css-config';

export enum PopoverArrowTypes {
  TopLeft,
  TopCenter,
  TopRight,
  Custom,
}

interface PopoverArrowDefaultCssConfig {
  topLeft: ICssConfig;
  topCenter: ICssConfig;
  topRight: ICssConfig;
}

export const POPOVER_DEFAULT_CSS_CONFIG: ICssConfig = {
  position: 'absolute',
  border: `1px solid ${CSS_COLORS.ColorGrey3}`,
  borderRadius: '3px',
  background: CSS_COLORS.ColorWhite,
};

export const POPOVER_ARROW_DEFAULT_CSS_CONFIG: PopoverArrowDefaultCssConfig = {
  topLeft: {
    height: '8px',
    width: '8px',
    background: CSS_COLORS.ColorWhite,
    position: 'absolute',
    top: '0',
    borderTop: `1px solid ${CSS_COLORS.ColorGrey3}`,
    borderLeft: `1px solid ${CSS_COLORS.ColorGrey3}`,
    transform: 'translate(50%, -50%) rotate(45deg)',
  },
  topCenter: {
    height: '8px',
    width: '8px',
    background: CSS_COLORS.ColorWhite,
    position: 'absolute',
    top: '0',
    left: '50%',
    borderTop: `1px solid ${CSS_COLORS.ColorGrey3}`,
    borderLeft: `1px solid ${CSS_COLORS.ColorGrey3}`,
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
  topRight: {
    height: '8px',
    width: '8px',
    background: CSS_COLORS.ColorWhite,
    position: 'absolute',
    top: '0',
    borderTop: `1px solid ${CSS_COLORS.ColorGrey3}`,
    borderLeft: `1px solid ${CSS_COLORS.ColorGrey3}`,
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
};
