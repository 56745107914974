import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { OeeKpiReportFilters } from 'fabscale-app/models/oee-kpi-report-models';
import { service } from '@ember/service';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  filters: OeeKpiReportFilters;
  exactDateRange: DateRange;
}

export default class PageOeeKpiReportPerRoaster extends Component<Args> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked error?: string;
  @tracked oeeKpiTypes: KpiType[];
  @tracked overallKpiData?: KpiData[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.initialiseSelectedKpiTypes();

    this.loadOverallKpiDataTask.perform();
  }

  @action
  initialiseSelectedKpiTypes() {
    this.oeeKpiTypes = this.args.filters.oeeKpiTypes;
  }

  loadOverallKpiDataTask = restartableTask(async () => {
    const { exactDateRange } = this.args;
    const { oeeKpiTypes, plantAssetIds, recipeIds } = this.args.filters;
    this.error = undefined;

    try {
      const { days } = exactDateRange.end.diff(exactDateRange.start, 'days');

      this.overallKpiData = await this._query(
        oeeKpiTypes,
        {
          dateRange: exactDateRange,
          plantAssetIds,
          recipeIds,
        },
        {
          loadInParallel: days < 30 * 3,
        }
      );
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);

      logError(error);
    }
  });

  async _query(
    oeeKpiTypes: KpiType[],
    variables: {
      dateRange: DateRange;
      plantAssetIds?: string[];
      recipeIds?: string[];
    },
    { loadInParallel = true } = {}
  ): Promise<KpiData[] | any> {
    const { kpiDataStore } = this;

    if (loadInParallel) {
      return kpiDataStore.findMultiple(oeeKpiTypes, variables);
    }
  }
}
