interface LineChartTwoAxesLegendConfig {
  label: string;
  color: string;
  unit: string;
}

export default function lineChartTwoAxesLegend(
  legendA: LineChartTwoAxesLegendConfig,
  legendB?: LineChartTwoAxesLegendConfig
) {
  let items = [
    {
      label: legendA.label,
      color: legendA.color,
      unit: legendA.unit,
    },
  ];

  if (legendB) {
    items.push({
      label: legendB.label,
      color: legendB.color,
      unit: legendB.unit,
    });
  }

  return items;
}
