import { assert } from '@ember/debug';
import { DateRange } from 'fabscale-app/models/date-range';
import { TimeRange } from 'fabscale-app/models/time-range';
import {
  getEndTimeIsoString,
  timeDiff,
} from 'fabscale-app/utilities/utils/parse-time';

export default function getOverTimeDateRangeHelper({
  dateRange,
  timeRange,
  dayStartTime,
}: {
  dateRange: DateRange;
  timeRange: TimeRange;
  dayStartTime: string;
}) {
  assert(
    `{{get-over-time-date-range}}: You have to provide {dateRange}`,
    !!dateRange
  );

  assert(
    `{{get-over-time-date-range}}: You have to provide {timeRange}`,
    !!timeRange
  );

  assert(
    `{{get-over-time-date-range}}: You have to provide {dayStartTime}`,
    !!dayStartTime
  );

  let baseStart = dateRange.start.startOf('day');
  let baseEnd = dateRange.end.startOf('day');

  if (dayStartTime === '00:00:00' || baseStart.equals(baseEnd)) {
    return new DateRange({
      start: baseStart,
      end: baseEnd,
    });
  }

  let dayEndTime = getEndTimeIsoString(dayStartTime);

  // In seconds
  let startTimeDiff = timeDiff(timeRange.start, dayStartTime);
  let endTimeDiff = timeDiff(timeRange.end, dayEndTime);

  // If time is before dayStart time, make sure to extend to a day before to cover everything
  // We allow up to 59 seconds, as the time will often be entered in hh:mm format only
  // Example: If dayStartTime is 08:00:00, and timeRange.start is 07:00:00,
  // we need to actually query starting from the day before -
  // otherwise, the period from 07:00-08:00 will not be included
  let start =
    startTimeDiff <= -60
      ? baseStart.minus({ day: 1 }).startOf('day')
      : baseStart;

  // If end is before dayEnd time, make sure to avoid including a day more than necessary
  // Example: If dayStartTime is 08:00:00, and timeRange.end is 06:00:00,
  // we need to actually query ending from the day before -
  // otherwise, we will include a full bin starting from 08:00 of the end date that we actually don't need
  let end =
    endTimeDiff <= 0 ? baseEnd.minus({ day: 1 }).startOf('day') : baseEnd;

  return new DateRange({ start, end });
}
