import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { DateRange } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';
import {
  queryDateRangeDay,
  queryDateRangeWeekExact,
} from 'fabscale-app/utilities/utils/date-range';

export function getDashboardDateOptionDateRange(
  dateOption: DashboardDateOption,
  {
    startDayOfWeek,
    dayStartTime,
  }: { startDayOfWeek: DayOfWeek; dayStartTime: string },
  now = DateTime.local()
): DateRange {
  let today = now.startOf('day');

  switch (dateOption) {
    case 'YESTERDAY':
      return queryDateRangeDay(
        today.minus({ day: 1 }).startOf('day'),
        dayStartTime
      );
    case 'THIS_WEEK':
      return queryDateRangeWeekExact(today, startDayOfWeek, dayStartTime);
    case 'LAST_WEEK':
      return queryDateRangeWeekExact(
        today.minus({ week: 1 }),
        startDayOfWeek,
        dayStartTime
      );
    case 'LAST_24H':
      return { start: now.minus({ hours: 24 }), end: now };
    case 'TODAY':
      return queryDateRangeDay(today, dayStartTime);
  }
}
