import { assert } from '@ember/debug';
import { DateTime, DateTimeFormatOptions } from 'luxon';

export enum DateFormat {
  Date = 'Date',
  DateLong = 'DateLong',
  DateTime = 'DateTime',
  DateTimeSeconds = 'DateTimeSeconds',
  Time = 'Time',
  TimeSeconds = 'TimeSeconds',
}

const formatMap = new Map<DateFormat, DateTimeFormatOptions>([
  [DateFormat.Date, DateTime.DATE_SHORT],
  [DateFormat.DateLong, DateTime.DATE_MED],
  [DateFormat.DateTime, DateTime.DATETIME_SHORT],
  [DateFormat.DateTimeSeconds, DateTime.DATETIME_SHORT_WITH_SECONDS],
  [DateFormat.Time, DateTime.TIME_SIMPLE],
  [DateFormat.TimeSeconds, DateTime.TIME_WITH_SECONDS],
]);

export function formatDate(
  date: DateTime,
  format: DateFormat = DateFormat.Date
): string {
  let dateFormat = formatMap.get(format);

  assert(
    `formatDate got invalid format "${format}", only these are allowed: ${Object.values(
      DateFormat
    ).join(', ')}`,
    !!dateFormat
  );

  return date.toLocaleString(dateFormat);
}
