import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { LabSamplesListFilters } from 'fabscale-app/services/store/lab-sample';

export default class RoastBatchesIndexController extends Controller {
  queryParams = ['dateRange', 'timeRange', 'coffeeTypes'];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked coffeeTypes?: string;
  @tracked sortBy?: RoastpicSortOption;
  @tracked sortDirection?: SortDirection;
  @tracked page?: number;
  @tracked pageSize?: number;

  get parsedDateRange(): DateRangeOptional | undefined {
    const { dateRange } = this;

    return dateRange ? deserializeDateRange(dateRange) : undefined;
  }

  get parsedTimeRange(): TimeRangeOptional | undefined {
    const { timeRange } = this;

    return timeRange ? JSON.parse(timeRange) : undefined;
  }

  get parsedCoffeeTypes(): string[] | undefined {
    const { coffeeTypes } = this;

    return coffeeTypes ? deserializeArray(coffeeTypes) : undefined;
  }

  get pageNumber() {
    return typeof this.page === 'string' ? parseInt(this.page) : this.page;
  }

  get pageSizeNumber() {
    return typeof this.pageSize === 'string'
      ? parseInt(this.pageSize)
      : this.pageSize;
  }

  get filters(): any {
    return {
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      coffeeTypes: this.parsedCoffeeTypes,
    };
  }

  @action
  updateFilters(filters: LabSamplesListFilters & { timeRange: TimeRange }) {
    const { dateRange, timeRange, coffeeTypes } = filters;

    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.coffeeTypes = coffeeTypes ? serializeArray(coffeeTypes) : undefined;
  }

  @action
  updatePage(page?: number) {
    if (page !== this.page) {
      this.page = page;
    }
  }

  @action
  updatePageSize(pageSize?: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.page = undefined;
    }
  }

  @action
  updateSortBy(sortBy?: RoastpicSortOption) {
    if (sortBy !== this.sortBy) {
      this.sortBy = sortBy;
      this.page = undefined;
    }
  }

  @action
  updateSortDirection(sortDirection?: SortDirection) {
    if (sortDirection !== this.sortDirection) {
      this.sortDirection = sortDirection;
      this.page = undefined;
    }
  }

  resetFilters() {
    this.dateRange = this.timeRange = this.coffeeTypes = undefined;
  }
}
