import { assert } from '@ember/debug';
import { DateTime } from 'luxon';

export class ParseTimeError extends Error {}
export class ParseTimeTooManyPartsError extends ParseTimeError {}
export class ParseTimeInvalidStringError extends ParseTimeError {}
export class ParseTimeInvalidTimeError extends ParseTimeError {}

export interface TimeParts {
  hour: number;
  minute: number;
  second: number;
  millisecond: number;
}

export function parseTime(
  timeString: string,
  { expectHours = true } = {}
): TimeParts {
  assert(
    `parseTime expects a string, but got ${timeString}`,
    typeof timeString === 'string'
  );

  if (!timeString) {
    timeString = '00:00:00';
  }

  let isPm = timeString.toLowerCase().endsWith('pm');
  let parts = timeString
    .replace(/am|AM|pm|PM/, '')
    .trim()
    .split(':');

  if (parts.length > 3) {
    throw new ParseTimeTooManyPartsError();
  }

  let hoursString = parts[0]!;
  let minutesString = parts[1] ? parts[1].trim() : '0';
  let secondsString = parts[2] ? parts[2].trim() : '0';

  if (
    !isNumeric(hoursString) ||
    !isNumeric(minutesString) ||
    !isNumeric(secondsString)
  ) {
    throw new ParseTimeInvalidStringError();
  }

  // Handle e.g. "10.5" as "10:30"
  if (
    parts.length === 1 &&
    (hoursString.includes('.') || hoursString.includes(','))
  ) {
    let hours = parseFloat(hoursString);
    let minutes = (hours % 1) * 60;
    hoursString = `${Math.floor(hours)}`;
    minutesString = `${Math.floor(minutes)}`;
  }

  if (!expectHours && parts.length <= 2) {
    secondsString = minutesString;
    minutesString = hoursString;
    hoursString = '0';
  }

  let second = parseInt(secondsString);
  let minute = parseInt(minutesString);
  let hour = parseInt(hoursString);

  if (isPm) {
    hour = hour + 12;
  }

  if (second >= 60 || minute >= 60 || hour > 24) {
    throw new ParseTimeInvalidTimeError();
  }

  return { hour, minute, second, millisecond: 0 };
}

export function getTimeValue(timeParts: TimeParts): number {
  return timeParts.hour * 60 * 60 + timeParts.minute * 60 + timeParts.second;
}

export function getEndTimeIsoString(timeString: string) {
  let timeParts = parseTime(timeString);
  let dateTime = DateTime.fromObject(timeParts);

  return dateToIsoTimeString(dateTime.minus({ second: 1 }));
}

export function dateToIsoTimeString(date: DateTime) {
  return date.toISOTime({
    includeOffset: false,
    suppressMilliseconds: true,
  });
}

export function formatTimeString(timeString: string) {
  let timeParts = parseTime(timeString);
  return formatTimeParts(timeParts);
}

export function formatTimeStringToIso(timeString: string) {
  let timeParts = parseTime(timeString);
  let dateTime = DateTime.fromObject(timeParts);

  return dateToIsoTimeString(dateTime);
}

export function formatTimeParts(timeParts: TimeParts): string {
  let dateTime = DateTime.fromObject(timeParts);
  return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
}

// Returns the diff in seconds between the given times
// Negative means timeB is before timeA
export function timeDiff(timeA: string, timeB: string): number {
  let partsA = parseTime(timeA);
  let valueA = getTimeValue(partsA);

  let partsB = parseTime(timeB);
  let valueB = getTimeValue(partsB);

  return valueA - valueB;
}

function isNumeric(str: string) {
  // @ts-ignore
  return !Number.isNaN(str * 1);
}
