import { getOwnConfig, macroCondition } from '@embroider/macros';
import applicationConfig from 'fabscale-app/config/environment';

export function getApiHost(
  host = window.location.host,
  config = applicationConfig
) {
  if (macroCondition(getOwnConfig<any>().mockGraphqlApi)) {
    return 'http://mocked-api.localhost';
  }

  if (config.apiHost) {
    return config.apiHost;
  }

  return _getHost('api', host);
}

export function getAppHost(host = window.location.host) {
  if (macroCondition(getOwnConfig<any>().mockGraphqlApi)) {
    return window.location.origin;
  }

  try {
    return _getHost('app', host);
  } catch (error) {
    return window.location.origin;
  }
}

export function _getHost(
  subdomain: string,
  host = window.location.host
): string {
  let hostParts = host.split('.');
  if (hostParts.length < 3) {
    throw new Error(
      '_getHost() only works if there is a subdomain. Else, you need to set an API_HOST env. variable'
    );
  }

  let fullHostParts = hostParts.slice(1);
  fullHostParts.unshift(subdomain);
  return `https://${fullHostParts.join('.')}`;
}
