import BaseGraphQLService from '@ember-graphql-client/client/services/graphql';
import {
  GraphQLRequestClientInterface,
  MutationOptions,
  QueryOptions,
} from '@ember-graphql-client/client/utils/graphql-request-client';
import { inject as service } from '@ember/service';
import { CacheEntity } from 'fabscale-app/models/enums/cache-entities';
import { parseGraphqlError } from 'fabscale-app/models/errors/parse-graphql-error';
import { UnauthenticatedError } from 'fabscale-app/models/errors/unauthenticated-error';
import CognitoService from 'fabscale-app/services/cognito';
import { getApiHost } from 'fabscale-app/utilities/utils/get-host';

type QueryCacheOptions = {
  cacheEntity?: CacheEntity;
  cacheSeconds?: number;
  cacheId?: string;
};

type MutationCacheOptions = {
  invalidateCache?: {
    cacheEntity: CacheEntity;
    cacheId?: string;
  }[];
};

export default class GraphQLService extends BaseGraphQLService {
  @service cognito: CognitoService;

  errorHandler = (error: any) => {
    let parsedError = parseGraphqlError(error);

    // This _shouldn't_ happen, but if for whatever reason the token expires and is not refreshed,
    // we want to reload everything
    if (parsedError instanceof UnauthenticatedError) {
      // Ensure we are signed out, to avoid the edge case of weirdly broken state
      this.cognito.logoutForce();

      window.location.reload();
    }

    return parsedError;
  };

  get jwtToken() {
    return this.cognito.cognitoData?.jwtToken;
  }

  get apiURL() {
    return `${getApiHost()}/v1/graphql`;
  }

  get headers(): Record<string, string> {
    if (!this.jwtToken) {
      return {};
    }

    return {
      Authorization: `Bearer ${this.jwtToken}`,
    };
  }

  async query(
    options: QueryOptions,
    cacheOptions?: QueryCacheOptions,
    client?: GraphQLRequestClientInterface
  ) {
    await this.maybeRefreshAccessToken();
    return super.query(options, cacheOptions, client);
  }

  async mutate(
    options: MutationOptions,
    cacheOptions?: MutationCacheOptions,
    client?: GraphQLRequestClientInterface
  ) {
    await this.maybeRefreshAccessToken();
    return super.mutate(options, cacheOptions, client);
  }

  async maybeRefreshAccessToken() {
    if (!this.cognito.cognitoTokenNeedsRefresh()) {
      return;
    }

    try {
      await this.cognito.refreshAccessTokenTask.perform();
    } catch (error) {
      // ignore errors here...
    }
  }
}
