import { assert } from '@ember/debug';

export interface FormatNumberOptions {
  locale?: string;
  afterComma?: number;
  afterCommaExact?: boolean;
}

export function formatNumber(
  number: number,
  { locale, afterComma, afterCommaExact = false }: FormatNumberOptions = {}
): string {
  assert(
    `formatNumber() expects a number as the first argument, but got "${number}"`,
    typeof number === 'number'
  );

  if (typeof afterComma === 'undefined') {
    afterComma = determineAfterCommaPlaces(number);
  }

  let afterCommaMin = afterCommaExact ? afterComma : 0;

  let options = {
    minimumFractionDigits: afterCommaMin,
    maximumFractionDigits: afterComma,
  };

  let localeArgument = locale ? [locale, 'en'] : undefined;

  return number.toLocaleString(localeArgument, options);
}

export function determineAfterCommaPlaces(number: number): number {
  if (number === 0) {
    return 0;
  }

  if (number > 1000) {
    return 0;
  }

  if (number > 0.05) {
    return 2;
  }

  return 4;
}
