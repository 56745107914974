import Component from '@glimmer/component';
import { isTesting, macroCondition } from '@embroider/macros';

interface Args {
  onDoesNotSupportIntersectionObserver: () => void;
  onBecomesVisible: (handlerArgs: HandlerArgs, ...args: any[]) => void;
  onBecomesInvisible: (handlerArgs: HandlerArgs, ...args: any[]) => void;
  root?: Element;
  threshold?: number;
  rootMargin?: number;
}

interface HandlerArgs {
  element: Element;
  elementWasEverVisible: boolean;
  options: IntersectionObserverEntry;
}

export default class FunctionalIntersectionObserver extends Component<Args> {
  /*
   * Attributes:
   * - onDoesNotSupportIntersectionObserver
   * - onBecomesVisible
   * - onBecomesInvisible
   * - root (optional)
   * - threshold (optional)
   * - rootMargin (optional)
   *
   * The actions receive parameters like this:
   * { element, options, elementWasEverVisible }, ...other attributes passed to modifier
   *
   * So if you have this modifier: {{observe-element intersectionObserver 1 2}}
   * You'll get these parameters on the handler:
   *
   * myHandler(options, 1, 2)
   */

  intersectionObserverProxy: IntersectionObserverProxy;

  get doesNotSupportIntersectionObserver() {
    return !this._hasIntersectionObserver();
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    this._setupIntersectionObserver();
  }

  willDestroy() {
    this._tearDownIntersectionObserver();
    super.willDestroy();
  }

  _hasIntersectionObserver() {
    // We use WeakMap to keep track of the elements, so we also check for that support here
    return 'IntersectionObserver' in window && 'WeakMap' in window;
  }

  _setupIntersectionObserver() {
    if (this.doesNotSupportIntersectionObserver) {
      this.args.onDoesNotSupportIntersectionObserver();
      return;
    }

    let defaultRoot = macroCondition(isTesting())
      ? document.querySelector('#ember-testing-container')
      : undefined;

    let options = {
      root: this.args.root || defaultRoot,
      rootMargin: this.args.rootMargin,
      threshold: this.args.threshold || 0,
    };

    let intersectionObserver = new window.IntersectionObserver((entries) => {
      entries.forEach((options: IntersectionObserverEntry) => {
        this._handleIntersection(options);
      }, options);
    });

    this.intersectionObserverProxy = new IntersectionObserverProxy({
      intersectionObserver,
    });
  }

  _handleIntersection(options: IntersectionObserverEntry) {
    let { target, isIntersecting } = options;

    if (isIntersecting) {
      this.intersectionObserverProxy.elementWasVisible(target);
    }

    if (this.args.onBecomesVisible && isIntersecting) {
      let handlerArgs = this._getHandlerArgs(target, options);
      if (handlerArgs) {
        this.args.onBecomesVisible(...handlerArgs);
      }
    }

    if (this.args.onBecomesInvisible && !isIntersecting) {
      let handlerArgs = this._getHandlerArgs(target, options);
      if (handlerArgs) {
        this.args.onBecomesInvisible(...handlerArgs);
      }
    }
  }

  _getHandlerArgs(
    element: Element,
    options: IntersectionObserverEntry
  ): [HandlerArgs, ...any] | null {
    let { intersectionObserverProxy } = this;
    let args = intersectionObserverProxy.getElementData(element);
    let elementWasEverVisible =
      intersectionObserverProxy.elementWasEverVisible(element);

    // Handle the case that it was destroyed in the meanwhile...
    if (!args) {
      return null;
    }

    let handlerArgs: HandlerArgs = {
      element,
      elementWasEverVisible,
      options,
    };

    return [handlerArgs, ...args];
  }

  _tearDownIntersectionObserver() {
    if (this.intersectionObserverProxy) {
      this.intersectionObserverProxy.disconnect();
    }
  }
}

export class IntersectionObserverProxy {
  _intersectionObserver;
  _elementData = new WeakMap();
  _elementWasEverVisible = new WeakMap();

  constructor(options: { intersectionObserver: IntersectionObserver }) {
    this._intersectionObserver = options.intersectionObserver;
  }

  observeElement(element: Element, ...args: any[]) {
    this._intersectionObserver.observe(element);
    this._elementData.set(element, args);
  }

  unobserveElement(element: Element) {
    this._intersectionObserver.unobserve(element);
    this._elementData.delete(element);
  }

  getElementData(element: Element) {
    return this._elementData.get(element);
  }

  elementWasVisible(element: Element) {
    this._elementWasEverVisible.set(element, true);
  }

  elementWasEverVisible(element: Element) {
    return this._elementWasEverVisible.has(element);
  }

  disconnect() {
    this._intersectionObserver.disconnect();
  }
}
