import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import { DateTime } from 'luxon';

interface Args {
  value?: DateRangeOptional;
  timeRange?: TimeRange;
  onChange: (date?: DateRangeOptional) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
  registerAPI?: (select: any) => void;
}

export default class UiDateRangePicker extends Component<Args> {
  @service analytics: AnalyticsService;

  @tracked _centerMonth?: DateTime;

  _isChangingEndDate = false;

  constructor(owner: any, args: Args) {
    super(owner, args);

    assert(
      '<Ui::DateRangePicker>: You have to specify an `onChange` action',
      typeof this.args.onChange === 'function'
    );
  }

  get centerMonth() {
    if (this._centerMonth) {
      return this._centerMonth;
    }

    return (
      this.args.value?.start?.startOf('month') ||
      DateTime.local().startOf('month')
    );
  }

  set centerMonth(date: DateTime | undefined) {
    this._centerMonth = date;
  }

  @action
  updateDate(date?: DateTime) {
    let { value } = this.args;
    let isChangingEndDate = this._isChangingEndDate;

    let dateRange = isChangingEndDate
      ? new DateRangeOptional({ start: value?.start, end: date })
      : new DateRangeOptional({ start: date, end: undefined });

    if (
      dateRange.start &&
      dateRange.end &&
      dateRange.end.valueOf() < dateRange.start.valueOf()
    ) {
      dateRange = new DateRangeOptional({
        start: dateRange.end,
        end: undefined,
      });
      isChangingEndDate = !isChangingEndDate;
    }

    this._isChangingEndDate = !isChangingEndDate;

    this.args.onChange(dateRange);
  }

  @action
  updateDateRange(dateRange?: DateRangeOptional) {
    this.args.onChange(dateRange);
    this._isChangingEndDate = false;
  }

  @action
  updateDatePart(startOrEnd: 'start' | 'end', _: () => void, date?: DateTime) {
    let dateRange = new DateRangeOptional({});

    let { value } = this.args;
    if (value) {
      dateRange.start = value.start;
      dateRange.end = value.end;
    }

    // Disallow selecting end date after start date
    if (
      date &&
      startOrEnd === 'end' &&
      dateRange.start &&
      dateRange.start > date
    ) {
      return;
    }

    // If new start date is after old end date, clear end date
    if (
      date &&
      startOrEnd === 'start' &&
      dateRange.end &&
      date > dateRange.end
    ) {
      dateRange.end = undefined;
    }

    dateRange[startOrEnd] = date;

    this.args.onChange(dateRange);

    this.analytics.addEvent('date-range-picker-update-input', [
      { name: 'isStart', value: startOrEnd === 'start' ? 1 : 0 },
      { name: 'isEnd', value: startOrEnd === 'end' ? 1 : 0 },
    ]);
  }

  @action
  toggleDropdown(toggleFn: () => void, startOrEnd: 'start' | 'end') {
    toggleFn();

    // Since toggle triggers onOpen, it would be overwritten immediately if we set _isChangingEndDate right here
    // So instead we have to wait a tick until `onOpen` is completed and then set it
    this.waitSetIsChangingEndDate.perform(startOrEnd === 'end');
  }

  waitSetIsChangingEndDate = task(async (isChangingEndDate: boolean) => {
    await timeout(1);

    this._isChangingEndDate = isChangingEndDate;
  });

  @action
  updateCenterMonth(date?: DateTime) {
    this.centerMonth = date;
  }

  @action
  onOpen(_: unknown, event?: Event) {
    let target = event?.target as HTMLElement | undefined;

    // If the user opens the date picker by clicking on the "to" field, we want to change only end, not start new
    this._isChangingEndDate = Boolean(
      target && target.closest('[data-date-picker-toggle-end]')
    );

    return true;
  }

  @action
  onClose() {
    this._centerMonth = undefined;
  }
}
