import { service } from '@ember/service';
import { TaskCategory } from 'fabscale-app/models/enums/task-categories';
import { TaskRequirement } from 'fabscale-app/models/enums/task-requirements';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import BaseAsyncResource from './base-async-resource';

interface LoadMaintenanceTaskCountOptions {
  search?: string;
  category?: TaskCategory;
  assignedUserIds?: string[];
  plantAssetIds?: string[];
  plantAssetAreaIds?: string[];
  requirements?: TaskRequirement[];
  dateRange?: DateRangeOptional;
}

export default class LoadMaintenanceTasksCountResource extends BaseAsyncResource<
  { total: number; open: number; completed: number },
  LoadMaintenanceTaskCountOptions
> {
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  async loadData({
    search,
    category,
    assignedUserIds,
    plantAssetIds,
    plantAssetAreaIds,
    requirements,
    dateRange,
  }: LoadMaintenanceTaskCountOptions) {
    let { total, open, completed } = await this.maintenanceTaskStore.queryCount(
      {
        search,
        category,
        assignedUserIds,
        plantAssetIds,
        plantAssetAreaIds,
        requirements,
        dateRange,
      }
    );

    return { total, open, completed };
  }
}
