export interface ICssConfig {
  // Position and layout
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  zIndex?: string;

  // Display and visibility
  display?: string;
  opacity?: string;
  transform?: string;

  // Clipping
  overflow?: string;
  clip?: string;

  // Animation
  animation?: string;
  transition?: string;

  // Box model
  margin?: string;
  boxShadow?: string;
  border?: string;
  borderTop?: string;
  borderLeft?: string;
  borderBottom?: string;
  borderRight?: string;

  borderRadius?: string;
  boxSizing?: string;
  width?: string;
  height?: string;
  padding?: string;

  // Background
  background?: string;
  cursor?: string;

  // Typography
  fontSize?: string;
  lineHeight?: string;
  fontFamily?: string;
  fontWeight?: string;
  fontStyle?: string;
  textAlign?: string;
  textTransform?: string;
  wordSpacing?: string;
  color?: string;
}

export enum CSS_COLORS {
  ColorWhite = '#FFFFFF',
  ColorGrey3 = '#D3D4D9',
}

export class CssConfig {
  public static toString(config: ICssConfig) {
    const stringArray: string[] = [];

    for (const [key, value] of Object.entries(config)) {
      const keyAsCssProp = key
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase();
      stringArray.push(`${keyAsCssProp}: ${value};`);
    }

    return stringArray.join(' ');
  }
}
