import { DASHBOARD_COLUMN_COUNT } from 'fabscale-app/components/page/dashboard-customize/card-list';
import {
  DashboardCard,
  DashboardCardConfig,
} from 'fabscale-app/models/dashboard-config';
import { addRowOnTop, cardHasNoOverlap } from './reorder-cards';

interface Size {
  width: number;
  height: number;
}

export function addCardOnTop(
  cards: DashboardCard[],
  size: Size,
  config: DashboardCardConfig
): DashboardCard[] {
  let newCards: DashboardCard[] | undefined;

  // If possible, we try to add it to empty space in the 4 top rows
  newCards = tryAddInSpace(cards, size, config);

  if (newCards) {
    return newCards;
  }

  // else, add rows until we reach the height of the new card
  // After each row added, try if the new card fits into it
  newCards = cards.slice();
  for (let i = 0; i < size.height; i++) {
    newCards = addRowOnTop(newCards);

    let added = tryAddInSpace(newCards, size, config);
    if (added) {
      return added;
    }
  }

  throw new Error('Could not add new row to top.');
}

function tryAddInSpace(
  cards: DashboardCard[],
  size: Size,
  config: DashboardCardConfig
): DashboardCard[] | undefined {
  let newCards = cards.slice();

  let id = Math.max(-1, ...newCards.map((card) => card.id)) + 1;

  let newCard = {
    id,
    x: 0,
    y: 0,
    width: size.width,
    height: size.height,
    config,
  };

  let maxX = DASHBOARD_COLUMN_COUNT - newCard.width;
  for (let y = 0; y < 4; y++) {
    for (let x = 0; x <= maxX; x++) {
      let tryCard = Object.assign(newCard, { x, y });

      if (cardHasNoOverlap(tryCard, newCards)) {
        newCards.unshift(tryCard);
        return newCards;
      }
    }
  }

  return undefined;
}
