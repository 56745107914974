import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { enqueueTask, timeout } from 'ember-concurrency';
import { DateTime } from 'luxon';

export default class SiteWrapperCurrentTime extends Component {
  currentTimeTask = enqueueTask(async () => {
    if (this.currentTimeTask.performCount > 0) {
      await timeout(60000);
    }

    if (macroCondition(!isTesting())) {
      this.currentTimeTask.perform();
    }

    return DateTime.local();
  });
}
