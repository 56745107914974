import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { compare } from '@ember/utils';
import {
  ChartTooltipData,
  ChartTooltipItem,
} from 'fabscale-app/components/chart';

interface Args {
  // This defines a factor to move the tooltip based on the given DOM element
  // e.g. 0.5 would move it to the center
  xOffset: number;
  yOffset: number;

  // The selector to find the records by in the DOM
  // e.g. data-bar-chart
  // This will look for [data-bar-chart="GUID"]
  domElementSelector: string;
}

export default class ChartWithTooltipContainer extends Component<Args> {
  @tracked tooltipItem?: ChartTooltipData;
  @tracked containerElement: HTMLElement;

  @action
  registerContainerElement(element: HTMLElement) {
    this.containerElement = element;
  }

  @action
  updateTooltip(items?: ChartTooltipItem[]) {
    if (items?.length) {
      this._showTooltip(items);
    } else {
      this._hideTooltip();
    }
  }

  _hideTooltip() {
    this.tooltipItem = undefined;
  }

  _getDomElementsForItems(
    containerElement: HTMLElement,
    items: ChartTooltipItem[]
  ): HTMLElement[] {
    let { domElementSelector } = this.args;

    return items
      .map((item) => {
        return containerElement.querySelector(
          `[${domElementSelector}="${item.itemGuid}"]`
        );
      })
      .filter(Boolean) as HTMLElement[];
  }

  _showTooltip(items: ChartTooltipItem[]) {
    let domElements = this._getDomElementsForItems(
      this.containerElement,
      items
    );

    if (domElements.length === 0) {
      return;
    }

    let { xPos, yPos } = this._getTooltipPosition(domElements);
    let { dateRange } = items[0]!;

    this.tooltipItem = {
      dateRange,
      xPos,
      yPos,
      items,
      domElements,
    };
  }

  _getTooltipPosition(elements: HTMLElement[]) {
    let { xOffset, yOffset } = this.args;
    let { x: containerX, y: containerY } = this._getContainerPosition();

    let highestDomElement = elements.sort((a, b) => {
      return compare(a.getBoundingClientRect().y, b.getBoundingClientRect().y);
    })[0]!;

    // We need to get the x/y position relative to the parent container (as it is position=relative)
    // This is a bit tricky, since the target is an SVG element, but we want to render the tooltip outside of the SVG
    let rect = highestDomElement.getBoundingClientRect();

    let xPosAbsolute = rect.x + rect.width * xOffset;
    let yPosAbsolute = rect.y + rect.height * yOffset;

    let xPos = xPosAbsolute - containerX;
    let yPos = yPosAbsolute - containerY;

    return { xPos, yPos };
  }

  _getContainerPosition() {
    let { x, y } = this.containerElement.getBoundingClientRect();
    return { x, y };
  }
}
