import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { deserializeKpiDataFilterTemplate } from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-template';

export interface KpiDataFilterTemplateConfigInput {
  version: number;
  templates: string[];
}

export class KpiDataFilterTemplateConfig {
  version: number;
  templates: KpiDataFilterTemplate[];

  constructor(options: KpiDataFilterTemplateConfigInput) {
    this.version = options.version;
    this.templates = options.templates.map((kpiDataFilterTemplate) =>
      deserializeKpiDataFilterTemplate(kpiDataFilterTemplate)
    );
  }
}
