import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Transition from '@ember/routing/transition';
import { schedule } from '@ember/runloop';
import UserSessionService from 'fabscale-app/services/user-session';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import AlarmReportTypeReportController from './controller';

export default class RoutesReportsAlarmTypeReport extends AuthenticatedRoute {
  @service userSession: UserSessionService;
  @service router: RouterService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (!this.userSession.permissionMap.PLANT_ASSET_ALERT_MANAGE) {
      this.router.transitionTo('routes/index');
    }
  }

  resetController(
    controller: AlarmReportTypeReportController,
    isExiting: boolean
  ) {
    if (isExiting) {
      schedule('afterRender', () => {
        controller.resetFilters();
      });
    }
  }
}
