import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';
import {
  deserializeRelativeTimeframe,
  serializeRelativeTimeframe,
} from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { StatusMapReportFilters } from 'fabscale-app/components/page/status-map-report';
import { RelativeTimeframeId } from 'fabscale-app/models/enums/relative-timeframe';

export type StatusMapReportTableType = 'PER_DAY' | 'PER_WEEK' | 'PER_MONTH';

export default class ReportsStatusMapController extends Controller {
  queryParams = [
    'relativeTimeframe',
    'dateRange',
    'timeRange',
    'plantAssets',
    'status',
    'tableId',
  ];

  @tracked relativeTimeframe?: RelativeTimeframeId;
  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssets?: string;
  @tracked status?: string;
  @tracked tableId?: StatusMapReportTableType;

  get filters(): StatusMapReportFilters {
    return {
      relativeTimeframe: this.parsedRelativeTimeframe,
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      plantAssetIds: this.parsedPlantAssetIds,
      status: this.parsedStatus,
    };
  }

  get actualTableId(): StatusMapReportTableType {
    return this.tableId || 'PER_DAY';
  }

  get parsedDateRange() {
    return this.dateRange ? deserializeDateRange(this.dateRange) : undefined;
  }

  get parsedTimeRange() {
    return this.timeRange ? JSON.parse(this.timeRange) : undefined;
  }

  get parsedRelativeTimeframe() {
    return this.relativeTimeframe
      ? deserializeRelativeTimeframe(this.relativeTimeframe)
      : undefined;
  }

  get parsedPlantAssetIds() {
    return this.plantAssets ? deserializeArray(this.plantAssets) : [];
  }

  get parsedStatus() {
    return this.status ? deserializeArray(this.status) : [];
  }

  @action
  updateFilters(filters: StatusMapReportFilters) {
    let { relativeTimeframe, plantAssetIds, status, dateRange, timeRange } =
      filters;

    this.relativeTimeframe = relativeTimeframe
      ? serializeRelativeTimeframe(relativeTimeframe)
      : undefined;
    this.dateRange =
      dateRange && !relativeTimeframe
        ? serializeDateRange(dateRange)
        : undefined;

    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssets =
      plantAssetIds.length > 0 ? serializeArray(plantAssetIds) : undefined;
    this.status = status.length > 0 ? serializeArray(status) : undefined;

    this.tableId = undefined;
  }

  resetFilters() {
    this.relativeTimeframe = undefined;
    this.dateRange = undefined;
    this.timeRange = undefined;
    this.plantAssets = undefined;
    this.status = undefined;
    this.tableId = undefined;
  }
}
