import { ChartDataset } from 'chart.js';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { GroupBinValue } from 'fabscale-app/models/group-bin-value';

export const DEFAULT_CARD_COLOR = '#107FA4';
export const GAUGE_ARCH_BACKGROUND = '#EEF2F8';
export const OEE_COLORS = {
  OEE: '',
  OEE_AVAILABILITY: '#40bd7e',
  OEE_PERFORMANCE: '#e67300',
  OEE_QUALITY: '#aaaa11',
};

export const OEE_TYPES = {
  OEE: 'OEE',
  OEE_AVAILABILITY: 'OEE_AVAILABILITY',
  OEE_PERFORMANCE: 'OEE_PERFORMANCE',
  OEE_QUALITY: 'OEE_QUALITY',
};

export const OEE_CHART_HEIGHTS = {
  OEE_KPI: '180',
  OEE_KPI_LARGE: '132',
};

export const CHART_TYPES = {
  DOUGHNUT: 'doughnut',
};

export const CHART_EMPTY_SPACE = {
  weight: 0.2,
} as ChartDataset;

export const OEE_DOUGHNUT_TOOLTIP_CONFIG = {
  enabled: true,
  backgroundColor: '#fff',
  borderColor: '#D3D4D9',
  borderWidth: 1,
  callbacks: {
    labelTextColor: function () {
      return '#000000';
    },
    label: function (context: any) {
      let label = `${context.dataset.label}: ${context.dataset.data[0]}% avg`;
      return label;
    },
  },
};

export function computeAverage(
  groupBinValueArray: GroupBinValue[] | undefined
): number {
  if (!groupBinValueArray) {
    return 0;
  }

  let sum = 0;
  groupBinValueArray.forEach((groupBinValue) => {
    sum += groupBinValue.value;
  });

  return sum && groupBinValueArray.length
    ? Math.round(sum / groupBinValueArray.length)
    : 0;
}

export function getCircumferenceByPercentage(percentage: number) {
  return (percentage / 100) * 360;
}

export function oeekpiTypes(): KpiType[] {
  return ['OEE', 'OEE_AVAILABILITY', 'OEE_QUALITY', 'OEE_PERFORMANCE'];
}

export function toArray<Type>(value: Type): Type[] {
  let arr = [] as Type[];
  arr.push(value);

  return arr;
}
