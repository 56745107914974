import { assert } from '@ember/debug';
import { service } from '@ember/service';
import BaseAsyncResource from './base-async-resource';
import StoreAlarmLevelReportService from 'fabscale-app/services/store/alarm-level-report';
import {
  PlantAlarmsOverTimeFilters,
  PlantAlarmOverTime,
} from 'fabscale-app/models/alarm-report';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';

export default class PlantAlarmOvertimeResource extends BaseAsyncResource<
  PlantAlarmOverTime[],
  PlantAlarmsOverTimeFilters
> {
  @service('store/alarm-level-report')
  alarmLevelReportStore: StoreAlarmLevelReportService;

  async loadData(filters: PlantAlarmsOverTimeFilters) {
    const { recipeIds } = filters;

    assert(
      `{{resources/load-plant-alarms-overtime-grouped}}: You have to provide recipeIds for the grouping`,
      recipeIds && recipeIds.length > 0
    );

    const data = await promiseQueue(recipeIds, async (recipeId) => {
      const data = await this.alarmLevelReportStore.findPlantAlarmsOvertime({
        ...filters,
        recipeIds: [recipeId],
      });

      const group: any = {
        recipeId,
        data,
      };

      return group;
    });

    return data;
  }
}
