import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { TimeRange } from 'fabscale-app/models/time-range';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import { service } from '@ember/service';
import SettingsService from 'fabscale-app/services/settings';
import { action } from '@ember/object';
import { OeeKpiReportFilters } from 'fabscale-app/models/oee-kpi-report-models';

interface Args {
  filters: OeeKpiReportFilters;
  updateFilters: (filters: OeeKpiReportFilters) => void;
}

export default class PageOeeKpiReport extends Component<Args> {
  @service settings: SettingsService;

  @action
  updateFilters(filters: OeeKpiReportFilters) {
    const { oeeKpiTypes, dateRange, timeRange, plantAssetIds, recipeIds } =
      filters;

    this.args.updateFilters({
      oeeKpiTypes,
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
    });
  }

  @cached
  get filters() {
    const {
      dateRange,
      timeRange,
      oeeKpiTypes = [],
      recipeIds = [],
      plantAssetIds = [],
    } = this.args.filters || {};

    return {
      dateRange,
      timeRange,
      oeeKpiTypes,
      recipeIds,
      plantAssetIds,
    };
  }

  get timeRange(): TimeRange {
    return Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );
  }

  @cached
  get defaultTimeRange() {
    const { dayStartTime } = this.settings.locationSettings;
    const end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get hasAllRequiredData() {
    return Boolean(
      this.args.filters?.dateRange?.start &&
        this.args.filters?.dateRange?.end &&
        this.args.filters?.oeeKpiTypes
    );
  }
}
