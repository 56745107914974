import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AbilitiesService } from 'ember-can';
import { dropTask } from 'ember-concurrency';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { PlantAssetDetailMode } from 'fabscale-app/routes/plant-settings/plant-assets/show/index/controller';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  plantAsset: PlantAsset;
  mode?: PlantAssetDetailMode;
}

export default class PlantPlantAssetsDetailsIndex extends Component<Args> {
  @service router: RouterService;
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;
  @service('error-parser') errorParser: ErrorParserService;
  @service abilities: AbilitiesService;

  @tracked error?: string;
  @tracked plantAssetAreas: PlantAssetArea[];

  get defaultMode(): PlantAssetDetailMode {
    return this.abilities.can('view plant-asset-area') ? 'AREAS' : 'STATUS';
  }

  get mode() {
    return this.args.mode || this.defaultMode;
  }

  get showAreas() {
    return this.mode === 'AREAS' && this.abilities.can('view plant-asset-area');
  }

  get showStatusInformation() {
    return (
      this.mode === 'STATUS' && this.abilities.can('view plant-asset-info')
    );
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.plantAssetAreas = this.args.plantAsset.areas || [];
  }

  deletePlantAssetTask = dropTask(async () => {
    let { plantAssetStore } = this;
    let { id } = this.args.plantAsset;

    this.error = undefined;

    try {
      await plantAssetStore.delete(id);
    } catch (error) {
      this._handleError(error);
      return;
    }

    this.router.transitionTo('routes/plant-settings.plant-assets.index');
  });

  deletePlantAssetAreaTask = dropTask(async (id: string) => {
    let { plantAssetStore } = this;
    let plantAssetId = this.args.plantAsset.id;

    this.error = undefined;

    try {
      await plantAssetStore.deleteArea(id, { plantAssetId });
    } catch (error) {
      this._handleError(error);
      return;
    }

    // Manually remove the component from the list (to avoid refetching all data)
    let { plantAssetAreas } = this;
    let item = plantAssetAreas.find((area) => area.id === id);
    if (item) {
      plantAssetAreas = plantAssetAreas.slice();
      removeItem(plantAssetAreas, item);
      this.plantAssetAreas = plantAssetAreas;
    }
  });

  _handleError(error: any) {
    logError(error);
    this.error = this.errorParser.getErrorMessage(error);
  }
}
