import transition from '@ember/routing/-private/transition';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import ParameterGoalsIndexController from './controller';

export default class ParameterGoalsIndexRoute extends AuthenticatedRoute {
  resetController(
    controller: ParameterGoalsIndexController,
    isExiting: boolean,
    transition: transition<unknown>
  ): void {
    if (isExiting) {
      controller.parameterType = undefined;
      controller.search = undefined;
      controller.sortBy = undefined;
      controller.sortDirection = undefined;
      controller.page = undefined;
      controller.pageSize = undefined;
    }

    return super.resetController(controller, isExiting, transition);
  }
}
