import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { UserFrontendSettings } from 'fabscale-app/models/user-frontend-settings';
import { LocationSettings } from 'fabscale-app/models/location-settings';
import UserSessionService from 'fabscale-app/services/user-session';
import StoreLocationService from 'fabscale-app/services/store/location';
import StoreUserService from 'fabscale-app/services/store/user';

export default class SettingsService extends Service {
  @service userSession: UserSessionService;
  @service('store/location') locationStore: StoreLocationService;
  @service('store/user') userStore: StoreUserService;

  // In reality, as soon as a user is signed in (so basically all the time)
  // These are always available!
  @tracked userFrontendSettings: UserFrontendSettings;
  @tracked locationSettings: LocationSettings;

  async loadSettings() {
    await this.loadUserSettings();
    await this.loadLocationSettings();
  }

  async loadUserSettings() {
    let { user } = this.userSession;
    let { userStore } = this;

    if (user) {
      this.userFrontendSettings = new UserFrontendSettings({
        user,
        store: userStore,
      });
    }
  }

  async loadLocationSettings() {
    let { currentLocation } = this.userSession;
    let { locationStore } = this;

    if (currentLocation) {
      this.locationSettings = new LocationSettings({
        location: currentLocation,
        store: locationStore,
      });
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    settings: SettingsService;
  }
}
