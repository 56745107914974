import { assert } from '@ember/debug';
import { DashboardCardPosition } from 'fabscale-app/models/dashboard-config';

type GridCell = number | undefined;

interface Pos {
  x: number;
  y: number;
}

export class CardGrid<T extends DashboardCardPosition> {
  cards: T[];
  cells: GridCell[][];
  movedCard: T;

  minX: number;
  minY: number;

  constructor(cards: T[], movedCard: T) {
    this.cards = cards;
    this.movedCard = movedCard;

    this.#buildCells();
  }

  #buildCells() {
    let { cards, movedCard } = this;

    // We want to build a grid spanning only the given cards
    let minX = Math.min(...cards.map((card) => card.x), movedCard.x);
    let minY = Math.min(...cards.map((card) => card.y), movedCard.y);
    let maxX = Math.max(
      ...cards.map((card) => card.x + card.width),
      movedCard.x + movedCard.width
    );
    let maxY = Math.max(
      ...cards.map((card) => card.y + card.height),
      movedCard.y + movedCard.height
    );

    let cells: GridCell[][] = [];

    for (let y = minY; y < maxY; y++) {
      let row: GridCell[] = new Array(maxX - minX);

      row.fill(undefined);

      cells.push(row);
    }

    for (let card of cards) {
      let { x: cardX, y: cardY, width, height, id } = card;

      let relativeX = cardX - minX;
      let relativeY = cardY - minY;

      for (let y = relativeY; y < relativeY + height; y++) {
        for (let x = relativeX; x < relativeX + width; x++) {
          cells[y]![x] = id;
        }
      }
    }

    this.cells = cells;
    this.minX = minX;
    this.minY = minY;
  }

  flipDiagonally() {
    this.flipVertically();
    this.flipHorizontally();
  }

  flipVertically() {
    let { cells } = this;

    let newCells = this.#flip(cells);

    this.cells = newCells;
  }

  flipHorizontally() {
    let { cells } = this;

    let newCells: GridCell[][] = [];

    cells.forEach((row) => {
      newCells.push(this.#flip(row));
    });

    this.cells = newCells;
  }

  getUpdatedCards(): T[] {
    let { cards, minX, minY } = this;

    return cards.map((card) => {
      let { x, y } = card;

      let newPos = this.#findTopLeftCell(card.id);

      assert(
        `Could not find card position for id ${card.id} in cells - this should not happen...`,
        !!newPos
      );

      x = newPos.x + minX;
      y = newPos.y + minY;

      return Object.assign({}, card, { x, y });
    });
  }

  #findTopLeftCell(cardId: number): Pos | undefined {
    let { cells } = this;

    for (let y = 0; y < cells.length; y++) {
      let row = cells[y]!;
      for (let x = 0; x < row.length; x++) {
        if (row[x] === cardId) {
          return { x, y };
        }
      }
    }

    // This should normally never happpen, but...
    return undefined;
  }

  #flip<T extends GridCell | GridCell[]>(cells: T[]): T[] {
    if (cells.length === 1) {
      return cells;
    }

    let middle = Math.floor(cells.length / 2);

    // Two cases: When flipping a round number, just replace the two parts
    // When there is a non-round number, we flip around the middle part (and leave that in place)

    // Just move first & last part
    if (cells.length % 2 === 0) {
      let part1 = cells.slice(0, middle);
      let part2 = cells.slice(middle);

      return part2.concat(part1);
    }

    // Move around middle, which stays the same
    let part1 = cells.slice(0, middle);
    let part2 = [cells[middle]!];
    let part3 = cells.slice(middle + 1);

    return part3.concat(part2, part1);
  }
}
