import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { colorIsLight } from 'fabscale-app/utilities/utils/color';

type BackgroundType = 'success' | 'error';

interface Args {
  backgroundColor?: string;
  backgroundType?: BackgroundType;
}

export default class UiContentInCircle extends Component<Args> {
  get color() {
    let { backgroundColor, backgroundType } = this.args;

    if (!backgroundColor && backgroundType) {
      backgroundColor = getColorForType(backgroundType);
    }

    let bgIsLight = backgroundColor ? colorIsLight(backgroundColor) : true;
    let color = bgIsLight ? '#000000' : '#FFFFFF';

    return htmlSafe(`color: ${color};`);
  }
}

function getColorForType(type: BackgroundType) {
  let map = {
    success: '#25924f',
    error: '#d42f31',
    warning: '#f5a623',
  };

  return map[type];
}
