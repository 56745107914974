import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { User } from 'fabscale-app/models/user';
import StoreUserService from 'fabscale-app/services/store/user';

export default class SettingsCompanyUsersShowRoute extends AuthenticatedRoute {
  @service('store/user') userStore: StoreUserService;

  async model(params: { email: string }): Promise<User> {
    let { userStore } = this;
    let { email } = params;

    return await userStore.findByEmail(email);
  }
}
