import { modifier } from 'ember-could-get-used-to-this';

// We store the elements in a weakmap, as in Safari it will, for whatever reason,
// re-run the modifier multiple times, triggering an infinite rendering loop
let _mapRunCount = new WeakMap();
let _mapWidth = new WeakMap();

export function resizeObserver(
  element: HTMLElement,
  [onResize]: [() => void],
  {
    hasResizeObserver = 'ResizeObserver' in window,
    observeElement: overwriteObserveElement,
  }: {
    hasResizeObserver: boolean;
    observeElement?: HTMLElement;
  }
) {
  // You can specify a different element to observe
  // This is usually not necessary, but can be relevant if observing complex elements that auto-layout themselves
  // There, observing the element itself can lead to a loop of resizing, so we can instead e.g. observe the parent element or document.body
  let observeElement = overwriteObserveElement || element;

  // Note: The resize observer will also run initially
  // If it is not supported, we only call it once, but it will not trigger on actual resizing
  // Note: This can be overwritten for tests
  if (!hasResizeObserver) {
    didResize(observeElement, onResize);
    return;
  }

  let resizeObserver = new window.ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      didResize(observeElement, onResize);
    });
  });

  resizeObserver.observe(observeElement);

  return () => resizeObserver.disconnect();
}

export default modifier(resizeObserver);

function didResize(
  element: HTMLElement,
  callback: (resizeData: {
    width: number;
    element: HTMLElement;
    runCount: number;
  }) => void
) {
  let width = Math.floor(element.offsetWidth);
  let previousWidth = _mapWidth.get(element);

  if (width === previousWidth) {
    return;
  }

  let runCount = _mapRunCount.get(element) || 0;
  _mapWidth.set(element, width);
  _mapRunCount.set(element, runCount + 1);

  callback({ width, element, runCount });
}
