import Transition from '@ember/routing/-private/transition';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';

export default class PagePlantParameterGoalsShowEditRoute extends AuthenticatedRoute {
  deactivate(transition?: Transition) {
    this.refreshRouteIfMatches(
      transition,
      'routes/plant-settings.parameter-goals.show'
    );
  }
}
