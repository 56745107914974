const PAGINATION_MAX_ITEMS = 5;

interface PaginationInput {
  pages: number;
  currentPage: number;
  maxItems?: number;
}

export function generatePaginationItems({
  pages,
  currentPage,
  maxItems = PAGINATION_MAX_ITEMS,
}: PaginationInput): number[] {
  let firstPage = 1;
  let lastPage = pages;

  /*
      We want to always show a max. of 5 items.
      So for example:
      (1) 2 3 4 5
      1 (2) 3 4
      5 6 (7) 8 9
      11 12 13 14 (15)
      1 (2)
    */
  let paginationCurrentOffset = (maxItems - 1) / 2;

  let firstPageToShow = currentPage - paginationCurrentOffset;
  let lastPageToShow = currentPage + paginationCurrentOffset;

  // Move left until last page is not out of bounds
  while (lastPageToShow > lastPage && firstPageToShow > firstPage) {
    firstPageToShow--;
    lastPageToShow--;
  }

  // Move right until first page is not out of bounds
  while (firstPageToShow < firstPage && lastPageToShow < lastPage) {
    firstPageToShow++;
    lastPageToShow++;
  }

  // Make sure it works for smaller data sets
  firstPageToShow = Math.max(firstPage, firstPageToShow);
  lastPageToShow = Math.min(lastPage, lastPageToShow);

  let groups = [];
  for (let i = firstPageToShow; i <= lastPageToShow; i++) {
    groups.push(i);
  }

  return groups;
}
