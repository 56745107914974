import Component from '@glimmer/component';
import { select } from 'd3-selection';
import 'd3-transition';
import { action } from '@ember/object';
import { isTesting, macroCondition } from '@embroider/macros';
import { AxisScale } from 'd3-axis';
import { tracked } from '@glimmer/tracking';
import { Resource, use } from 'ember-could-get-used-to-this';

interface Args {
  axis: AxisScale<any>;
}

export default class ChartGrid extends Component<Args> {
  @tracked container: HTMLElement;

  @use d3Grid = new ChartGridResource(() => [this.args.axis, this.container]);

  @action
  renderD3Grid(container: HTMLElement) {
    this.container = container;
  }
}

type PositionalArgs = [axis: AxisScale<any>, container: HTMLElement];

class ChartGridResource extends Resource<PositionalArgs, any> {
  setup() {
    let [axis, container] = this.args.positional;

    select(container).call(axis);
  }

  update() {
    let [axis, container] = this.args.positional;

    let transitionTime = macroCondition(isTesting()) ? 0 : 200;

    select(container).transition().duration(transitionTime).call(axis);
  }
}
