import { DefaultNavigationConfiguration } from 'fabscale-app/models/default-navigation-configuration';

export function serializeDefaultNavigationConfiguration(
  defaultNavigationConfiguration: DefaultNavigationConfiguration
): string {
  return JSON.stringify(defaultNavigationConfiguration);
}

export function deserializeDefaultNavigationConfiguration(
  json: string
): DefaultNavigationConfiguration {
  return <DefaultNavigationConfiguration>JSON.parse(json);
}
