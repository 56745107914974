import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { isAggregated, KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import SettingsService from 'fabscale-app/services/settings';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';

export interface KpiDetailsReportFilters {
  kpiType?: KpiType;
  dateRange?: DateRangeOptional;
  timeRange?: TimeRangeOptional;
  plantAssetIds: string[];
  recipeIds: string[];
}

interface Args {
  filters: KpiDetailsReportFilters;
  updateFilters: (filters: KpiDetailsReportFilters) => void;
}

export default class KpiDetailsReportIndex extends Component<Args> {
  @service settings: SettingsService;

  @tracked unit?: Unit;

  @cached
  get filters() {
    return Object.assign({}, this.args.filters, { timeRange: this.timeRange });
  }

  get timeRange(): TimeRange {
    return Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );
  }

  @cached
  get defaultTimeRange() {
    let { dayStartTime } = this.settings.locationSettings;
    let end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get isAggregated() {
    return this.args.filters.kpiType
      ? isAggregated(this.args.filters.kpiType)
      : false;
  }

  get hasAllRequiredData() {
    return Boolean(
      this.args.filters.dateRange?.start &&
        this.args.filters.dateRange?.end &&
        this.args.filters.kpiType
    );
  }

  @action
  setUnit(unit: Unit) {
    // The child component Overall loads the KPI data, and sends the unit here
    // We can pass this to other components, to use the unit e.g. in headings
    // This simplifys showing the unit, without always needing to deeply nest stuff
    // Note that we cannot assume to have the unit, as that can be set anytime (or never)
    this.unit = unit;
  }
}
