import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { TaskStatus } from 'fabscale-app/models/enums/task-status';
import { DateTime } from 'luxon';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import CellTitle from 'fabscale-app/components/module/task-table-paginated/cells/title';
import CellAssignedUser from 'fabscale-app/components/module/task-table-paginated/cells/assigned-user';
import CellDueDate from 'fabscale-app/components/module/task-table-paginated/cells/due-date';

interface Args {
  isCompletedTasks: boolean;
  tasks: MaintenanceTask[];
}

export default class ModuleTaskTablePaginated extends Component<Args> {
  @service l10n: L10nService;

  columns: TableColumnDefinitionInput[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._setupColumns();
  }

  _setupColumns() {
    let { l10n } = this;
    let { isCompletedTasks } = this.args;

    let columns: TableColumnDefinitionInput[] = [
      {
        title: l10n.t('Title'),
        propertyName: 'title',
        sortBy: 'TITLE',
        component: CellTitle,
        tdClass: 'min-width-5',
        noCompactTitle: true,
      },
      {
        title: l10n.t('Assignee'),
        propertyName: 'assignedUser',
        sortBy: 'ASSIGNED_USER',
        tdClass: 'order-last',
        hideIfCompact: true,
        component: CellAssignedUser,
      },
    ];

    if (isCompletedTasks) {
      columns.push({
        title: l10n.t('Completion date'),
        propertyName: 'completionDate',
        sortBy: 'COMPLETION_DATE',
        cellType: 'DATE',
        hideIfCompact: true,
        cellData: {
          dateFormat: DateFormat.DateLong,
        },

        thClass: 'text-right nowrap',
        tdClass: 'text-right',
      });
    } else {
      columns.push({
        title: l10n.t('Due date'),
        propertyName: 'dueDate',
        sortBy: 'DUE_DATE',
        hideIfCompact: true,
        component: CellDueDate,
        thClass: 'text-right nowrap',
      });
    }

    this.columns = columns;
  }

  @action
  onToggleStatus({
    id,
    status,
    completionDate,
  }: {
    id: string;
    status: TaskStatus;
    completionDate?: DateTime;
  }) {
    let task = this.args.tasks.find((task) => task.id === id);

    if (task) {
      task.status = status;
      task.completionDate = completionDate;
    }
  }
}
