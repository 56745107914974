import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  chartSelectorItems: any;
  onToggle: (index: number | undefined) => void;
  onToggleOff: (index: number) => void;
}

export default class UiChartSelector extends Component<Args> {
  @tracked
  toggledIndex: number | undefined;

  @action
  onToggle(index: number | undefined) {
    this.toggledIndex = this.toggledIndex === index ? undefined : index;
    this.args.onToggle(this.toggledIndex);
  }

  @action
  onToggleOff(index: number) {
    this.args.onToggleOff(index);
  }
}
