import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

export function checkDateRange(dateRange: DateRangeOptional | DateRange) {
  let { start } = dateRange;
  let end = dateRange.end || DateTime.local().endOf('day');

  let { days } = start ? end.diff(start, 'days') : { days: 99 };

  return {
    canViewPerDay: true,
    canViewPerHour: days <= 2,
    canViewPerWeek: days > 7,
    canViewPerMonth: days > 31,
  };
}
