import { EnumsBase } from './-base';

export const PlantAssetTypes = [
  'UNDEF',
  'SILO',
  'CELL',
  'ROASTER',
  'PACKING_BEANS',
  'GRINDER',
  'PACKING_GROUND',
  'WAREHOUSE',
  'BAG',
  'PALETTE_PACKET_COUNTER',
  'EXTERNAL_LOCATION',
  'MOB_BIN_ROAST',
  'MOB_BIN_GROUND',
  'OTHER',
] as const;

export type PlantAssetType = typeof PlantAssetTypes[number];

export class PlantAssetTypesEnums extends EnumsBase<PlantAssetType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in PlantAssetType]: string } = {
      UNDEF: l10n.t('Undef'),
      SILO: l10n.t('Silo'),
      CELL: l10n.t('Cell'),
      ROASTER: l10n.t('Roaster'),
      PACKING_BEANS: l10n.t('Packing beans'),
      PACKING_GROUND: l10n.t('Packing ground'),
      GRINDER: l10n.t('Grinder'),
      WAREHOUSE: l10n.t('Warehouse'),
      BAG: l10n.t('Bag'),
      PALETTE_PACKET_COUNTER: l10n.t('Palette packet counter'),
      EXTERNAL_LOCATION: l10n.t('External location'),
      MOB_BIN_ROAST: l10n.t('Mob bin roast'),
      MOB_BIN_GROUND: l10n.t('Mob bin ground'),
      OTHER: l10n.t('Other'),
    };

    this.labels = labels;
  }
}
