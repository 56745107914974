import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { DateTimeString, PageDef, PageInfo, SortDef } from 'fabscale-app';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import mutationSetRoastBatchParameters from 'fabscale-app/gql/mutations/set-roast-batch-parameters.graphql';
import queryFindById from 'fabscale-app/gql/queries/roast-batch-by-id.graphql';
import queryGoalSummary from 'fabscale-app/gql/queries/roast-batches-goal-summary.graphql';
import queryPaginated from 'fabscale-app/gql/queries/roast-batches-paginated.graphql';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import {
  BooleanRoastBatchParameter,
  NumericCurveRoastBatchParameter,
  NumericRoastBatchParameter,
  RoastBatch,
  RoastBatchPojo,
} from 'fabscale-app/models/roast-batch';
import GraphQLService from 'fabscale-app/services/-graphql';
import UserSessionService from '../user-session';

export interface PaginatedRoastBatches {
  pageInfo: PageInfo;
  roastBatches: RoastBatch[];
}

export interface RoastBatchesGoalSummary {
  failed: number;
  noFailed: number;
  noGoal: number;
  withGoal: number;
  total: number;
}

export type RoastBatchGoalResultFilter =
  | 'NO_FAILED'
  | 'NO_GOAL'
  | 'FAILED'
  | 'WARNING'
  | 'PASSED_ONLY';

export default class StoreRoastBatchService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  // METHODS

  async findById(id: string): Promise<RoastBatch> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let response: RoastBatchPojo = await graphql.query(
        { query: queryFindById, variables, namespace: 'roastBatchById' },
        {
          cacheSeconds: 300,
          cacheEntity: 'RoastBatch',
          cacheId: id,
        }
      );

      return new RoastBatch(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The roast batch with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async queryPaginated(
    filters: {
      dateFrom?: DateTimeString;
      dateTo?: DateTimeString;
      plantAssetIds?: string[];
      recipeIds?: string[];
      goalResults?: RoastBatchGoalResultFilter;
    },
    pageDef: PageDef,
    { sortBy, sortDirection }: SortDef
  ): Promise<PaginatedRoastBatches> {
    let { graphql, locationId } = this;

    assert(
      `roastBatchStore.queryPaginated: You have to specify sortBy`,
      !!sortBy
    );
    assert(
      `roastBatchStore.queryPaginated: You have to specify sortDirection`,
      !!sortDirection
    );

    let variables = {
      filters: Object.assign({ locationId }, filters),
      pageDef,
      sortBy,
      sortDirection,
    };

    let response: {
      items: RoastBatchPojo[];
      pageInfo: PageInfo;
    } = await graphql.query(
      { query: queryPaginated, variables, namespace: 'roastBatchesPaginated' },
      {
        cacheSeconds: 10,
        cacheEntity: 'RoastBatch',
      }
    );

    return {
      roastBatches: response.items.map((item) => new RoastBatch(item)),
      pageInfo: response.pageInfo,
    };
  }

  async roastBatchesGoalSummary(options: {
    dateRange?: DateRangeOptional;
  }): Promise<RoastBatchesGoalSummary> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      dateFrom: options.dateRange?.start?.toISO(),
      dateTo: options.dateRange?.end?.toISO(),
    };

    let response: {
      failed: { pageInfo: PageInfo };
      noFailed: { pageInfo: PageInfo };
      noGoal: { pageInfo: PageInfo };
    } = await graphql.query(
      { query: queryGoalSummary, variables },
      {
        cacheSeconds: 10,
        cacheEntity: 'RoastBatch',
      }
    );

    let failed = response.failed.pageInfo.totalItemCount;
    let noFailed = response.noFailed.pageInfo.totalItemCount;
    let noGoal = response.noGoal.pageInfo.totalItemCount;

    return {
      failed,
      noFailed,
      noGoal,
      total: failed + noFailed,
      withGoal: failed + noFailed - noGoal,
    };
  }

  async setRoastBatchParameters(
    roastBatchId: string,
    {
      numericRoastBatchParameters,
      booleanRoastBatchParameters,
      numericCurveRoastBatchParameters,
    }: {
      numericRoastBatchParameters?: NumericRoastBatchParameter[];
      booleanRoastBatchParameters?: BooleanRoastBatchParameter[];
      numericCurveRoastBatchParameters?: NumericCurveRoastBatchParameter[];
    }
  ) {
    let { graphql } = this;

    let variables = {
      roastBatchId,
      numericRoastBatchParameters,
      booleanRoastBatchParameters,
      numericCurveRoastBatchParameters,
    };

    await graphql.mutate(
      {
        mutation: mutationSetRoastBatchParameters,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'RoastBatch',
            cacheId: roastBatchId,
          },
          {
            cacheEntity: 'RoastBatch',
          },
        ],
      }
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/roast-batch': StoreRoastBatchService;
  }
}
