import { DateTimeString } from 'fabscale-app';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { UnitTime } from 'fabscale-app/models/enums/units';
import { deserializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import { convertTimeAmount } from 'fabscale-app/utilities/utils/unit-converter';
import { DateTime } from 'luxon';
import { DateBinValue, DateBinValueInput } from './date-bin-value';

const TARGET_UNIT: UnitTime = 'HOUR';

export class PlantAssetInfoSummary {
  totalRuntime: number;
  averageRuntimePerDay: number;
  runtimeUnit: UnitTime;
  firstMeasureDate: DateTime | undefined;
  lastMeasureDate: DateTime | undefined;
  label: string;

  constructor(input: PlantAssetInfoSummaryInput, label: string) {
    this.totalRuntime = convertTimeAmount(
      input.totalRuntime,
      input.runtimeUnit,
      TARGET_UNIT
    );
    this.averageRuntimePerDay = convertTimeAmount(
      input.averageRuntimePerDay,
      input.runtimeUnit,
      TARGET_UNIT
    );
    this.runtimeUnit = TARGET_UNIT;
    this.firstMeasureDate = deserializeOptionalDate(input.firstMeasureDate);
    this.lastMeasureDate = deserializeOptionalDate(input.lastMeasureDate);
    this.label = label;
  }
}

export interface PlantAssetInfoSummaryInput {
  totalRuntime: number;
  averageRuntimePerDay: number;
  runtimeUnit: UnitTime;
  firstMeasureDate: DateTimeString | undefined;
  lastMeasureDate: DateTimeString | undefined;
}

export class PlantAssetInfoSummaryOverTime {
  unit: UnitTime;
  plantAssetStatus: PlantAssetStatus;
  bins: DateBinValue[];

  constructor(input: {
    unit: UnitTime;
    plantAssetStatus: PlantAssetStatus;
    bins: DateBinValueInput[];
  }) {
    this.unit = input.unit;
    this.plantAssetStatus = input.plantAssetStatus;
    this.bins = input.bins.map((data) => new DateBinValue(data));
  }
}
