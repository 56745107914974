import Component from '@glimmer/component';
import { service } from '@ember/service';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { cached, tracked } from '@glimmer/tracking';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import CellPercentageBar from 'fabscale-app/components/page/kpi-details-report/per-recipe/table/cell/percentage-bar';
import { AlarmReportTableRecord } from 'fabscale-app/models/common';
import { action } from '@ember/object';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import { PlantAlarmsPerRecipe } from 'fabscale-app/models/alarm-report';

interface Args {
  alarmsPerRecipe: PlantAlarmsPerRecipe[];
  selectedRecipesWithColor: { id: string; name: string; color: string }[];
  updateSelectedRecipes: (recipes: { id: string; name: string }[]) => void;
}

export default class UiRecipeList extends Component<Args> {
  @service l10n: L10nService;
  @tracked searchTerm?: string;

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    return [
      {
        title: '',
        propertyName: '',
        cellType: 'TOGGLE_CHECKBOX',
        tdClass: 'print-hide',
        thClass: 'print-hide',
      },
      {
        title: l10n.t('Name'),
        propertyName: 'label',
        tdClass: 'nowrap',
      },
      {
        title: '',
        propertyName: '',
        component: CellPercentageBar,
        tdClass: 'width-100p mobile-display-none',
        thClass: 'mobile-display-none',
      },
      {
        title: l10n.t('Amount'),
        propertyName: 'value',
        tdClass: 'text-center',
      },
    ];
  }

  @cached
  get extendedAlarmPerRecipe() {
    const { alarmsPerRecipe } = this.args;

    return alarmsPerRecipe.map((alarm) => {
      const extendedRecipeIdNamePair = {
        ...alarm.recipeIdNamePair,
        label: `${alarm.recipeIdNamePair.id} (${alarm.recipeIdNamePair.name})`,
      };

      return {
        recipeIdNamePair: extendedRecipeIdNamePair,
        value: alarm.value,
      };
    });
  }

  get maxValue() {
    const alarmsPerRecipe = this.extendedAlarmPerRecipe;
    const values = alarmsPerRecipe?.map((item: any) => item.value);

    return Math.max(...values);
  }

  get selectedRecipes() {
    return this.args.selectedRecipesWithColor.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }

  @cached
  get tableData() {
    const alarmsPerRecipe = this.extendedAlarmPerRecipe;
    const { maxValue } = this;

    return alarmsPerRecipe?.map((item: any) => {
      const { value } = item;
      const { id, name, label } = item.recipeIdNamePair;
      const percentage = (value / maxValue) * 100;

      return { id, name, label, value, percentage };
    });
  }

  @cached
  get records(): AlarmReportTableRecord[] {
    const { tableData } = this;
    const { selectedRecipesWithColor } = this.args;

    return tableData.map((record: any) => {
      const selectedConfig = selectedRecipesWithColor?.find(
        (item) => item.id === record.id
      );
      return Object.assign(
        {
          isSelected: !!selectedConfig,
          color: selectedConfig ? selectedConfig.color : null,
        },
        record
      );
    });
  }

  @action
  filterData(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  get filteredRecords() {
    const { records, searchTerm } = this;

    if (!searchTerm) {
      return records;
    }

    return filterRecords(records, searchTerm, { propertyName: 'label' });
  }

  get selectedRecords() {
    return this.records.filter((item: any) => item.isSelected);
  }

  private _toggleRecord(
    selectedRecipes: { id: string; name: string }[],
    record: AlarmReportTableRecord,
    isSelected: boolean
  ) {
    if (isSelected) {
      selectedRecipes.push({
        id: record.id,
        name: record.name,
      });
    } else {
      const indexOfCurrentRecipe = selectedRecipes
        .map((recipe) => recipe.id)
        .indexOf(record.id);

      if (indexOfCurrentRecipe > -1) {
        selectedRecipes.splice(indexOfCurrentRecipe, 1);
      }
    }
  }

  @action
  toggleRecord(
    record: AlarmReportTableRecord,
    isSelected: boolean,
    { inRangeRecords }: { inRangeRecords?: AlarmReportTableRecord[] }
  ) {
    const selectedRecipes = this.selectedRecipes.slice();

    // These are records in range when holding shift while selecting rows
    if (inRangeRecords && inRangeRecords.length > 0) {
      inRangeRecords.forEach((record) => {
        this._toggleRecord(selectedRecipes, record, isSelected);
      });
    } else {
      this._toggleRecord(selectedRecipes, record, isSelected);
    }

    this.args.updateSelectedRecipes(selectedRecipes);
  }
}
