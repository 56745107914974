import BaseAsyncResource, {
  AsyncResource,
} from 'fabscale-app/helpers/resources/base-async-resource';

export type LazyTrackedArgs = {
  positional?: Array<unknown>;
  named?: Record<string, unknown>;
};

export function getAsyncResourceValue<
  SomeResource extends BaseAsyncResource<any, any>
>(instance: SomeResource): SomeResource['value'];

export function getAsyncResourceValue(instance: BaseAsyncResource<any, any>) {
  return instance;
}

export function getAsyncResourceData<T>(
  instance: BaseAsyncResource<T, any>,
  fallback: any = undefined
): T {
  let asyncResource = getAsyncResourceValue(instance);

  return getAsyncResourceDataOptional(asyncResource, fallback);
}

export function getAsyncResourceDataOptional<T>(
  asyncResource: AsyncResource<T>,
  fallback: any = undefined
) {
  let { isError, data, isLoading } = asyncResource;

  return typeof data === 'string' ||
    typeof data === 'undefined' ||
    isError ||
    isLoading
    ? fallback
    : data;
}
