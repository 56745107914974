/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface UiRoastersBannerArgs {
  onToggle: (index: number) => void;
}

export default class UiRoastersBanner extends Component<UiRoastersBannerArgs> {
  @tracked activeIndex?: number;
  @action
  handleToggle(index: number) {
    this.activeIndex = this.activeIndex === index ? undefined : index;
    this.args.onToggle(index);
  }
}
