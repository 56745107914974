import Component from '@glimmer/component';
import { DateTimeFormatOptions } from 'luxon';
import { getDateFormatForInterval } from 'fabscale-app/utilities/utils/date-interval';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { DateRange } from 'fabscale-app/models/date-range';

interface Args {
  overTimeInterval: Interval;
  exactDateRange: DateRange;
}

export default class PagePlantCompareReportKpiOverTime extends Component<Args> {
  get dateFormat(): DateTimeFormatOptions {
    const { overTimeInterval, exactDateRange } = this.args;

    if (
      overTimeInterval === 'HOUR' &&
      !exactDateRange?.end.hasSame(exactDateRange.start, 'day')
    ) {
      return { day: 'numeric', month: 'numeric', hour: 'numeric' };
    }

    return getDateFormatForInterval(overTimeInterval);
  }
}
