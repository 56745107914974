import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { parseTime } from 'fabscale-app/utilities/utils/parse-time';
import { DateTime, DurationObjectUnits } from 'luxon';
import { getWeekEnd, getWeekStart } from './date-week';
import { TimeRange } from 'fabscale-app/models/time-range';
import assert from 'fabscale-app/helpers/assert';

export function queryDateRangeDay(date: DateTime, dayStartTime: string) {
  let timeParts = getTimeParts(dayStartTime);

  let start = date.set(timeParts);
  let end = start.plus({ days: 1 }).minus({ milliseconds: 1 });

  return new DateRange({ start, end });
}

export function queryDateRange(dateRange: DateRange) {
  let start = dateRange.start.startOf('day');
  let end = dateRange.end.startOf('day');

  return new DateRange({ start, end });
}

export function cutOffDateRange(
  dateRange: DateRange,
  cutOffDate?: DateTime
): DateRange {
  if (!cutOffDate) {
    return dateRange;
  }

  if (cutOffDate.valueOf() < dateRange.end.valueOf()) {
    return {
      start: dateRange.start,
      end: cutOffDate,
    };
  }

  return dateRange;
}

export function queryDateRangeWeekExact(
  date: DateTime,
  startDayOfWeek: DayOfWeek,
  dayStartTime: string
) {
  let weekStart = getWeekStart(date, { startDayOfWeek });
  let weekEnd = getWeekEnd(date, { startDayOfWeek });

  let start = getExactDate(weekStart, dayStartTime);
  let end = getExactDate(weekEnd, dayStartTime)
    .plus({ days: 1 })
    .minus({ millisecond: 1 });

  return new DateRange({
    start,
    end,
  });
}

export function queryDateRangeRelative(
  end: DateTime,
  opts: DurationObjectUnits
) {
  let start = end.minus(opts);
  let dateRange = new DateRange({ start, end });
  return queryDateRange(dateRange);
}

export function getExactDate(date: DateTime, dayStartTime: string) {
  let timeParts = getTimeParts(dayStartTime);

  return date.set(timeParts).set({ millisecond: 0 });
}

export function getDateRangeLength(dateRange: DateRange | DateRangeOptional) {
  if (!dateRange.start || !dateRange.end) {
    return -1;
  }

  return Math.floor(dateRange.end.diff(dateRange.start, 'days').days) + 1;
}

function getTimeParts(dayStartTime = '00:00:00') {
  return parseTime(dayStartTime);
}

export function getExactDateRangeWithTimeRange(
  dateRange: DateRange,
  timeRange: TimeRange
) {
  assert(`dateRange cannot be undefined`, !!dateRange);
  assert(`timeRange cannot be undefined`, !!timeRange);

  const start = getExactDate(dateRange!.start!, timeRange!.start!);
  const end = getExactDate(dateRange!.end!, timeRange!.end!);

  return new DateRange({ start, end });
}
