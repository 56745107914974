import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { checkDateRange } from 'fabscale-app/utilities/utils/check-date-range';
import {
  deserializeKpiDataFilterSettings,
  serializeKpiDataFilterSettings,
} from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-settings';

export enum KpiCompareReportTableType {
  PerHour = 'PER_HOUR',
  PerDay = 'PER_DAY',
  PerWeek = 'PER_WEEK',
  PerMonth = 'PER_MONTH',
  PerPlantAsset = 'PER_PLANT_ASSET',
  PerRecipe = 'PER_RECIPE',
}

export default class ReportsKpiCompareIndexController extends Controller {
  queryParams = ['settings', 'tableId'];

  @tracked settings?: string;
  @tracked tableId?: KpiCompareReportTableType;

  get filterSettings() {
    let { settings } = this;
    return settings ? deserializeKpiDataFilterSettings(settings) : undefined;
  }

  get actualTableId() {
    if (this.tableId) {
      return this.tableId;
    }

    let checkDates = this.filterSettings
      ? checkDateRange(this.filterSettings.dateRange)
      : undefined;

    if (checkDates?.canViewPerHour) {
      return KpiCompareReportTableType.PerHour;
    } else if (checkDates?.canViewPerMonth) {
      return KpiCompareReportTableType.PerMonth;
    }

    return KpiCompareReportTableType.PerDay;
  }

  @action
  updateFilterSettings(filterSettings: KpiDataFilterSettings) {
    this.settings = serializeKpiDataFilterSettings(filterSettings);

    let checkDates = this.filterSettings
      ? checkDateRange(this.filterSettings.dateRange)
      : undefined;

    let tableId = KpiCompareReportTableType.PerDay;

    if (checkDates?.canViewPerHour) {
      tableId = KpiCompareReportTableType.PerHour;
    } else if (checkDates?.canViewPerMonth) {
      tableId = KpiCompareReportTableType.PerMonth;
    }

    this.tableId = tableId;
  }
}
