import { User } from 'fabscale-app/models/user';
import { DateTime } from 'luxon';

export default function parseUserStatus(user: User) {
  let { creationDate } = user;
  let { status } = user.authenticationInfo;

  return {
    confirmed: status === 'CONFIRMED',
    invited: status === 'INVITED',
    invitationExpired:
      status === 'INVITED' &&
      creationDate &&
      DateTime.local().diff(creationDate, 'days').days >= 90,

    unknown: status === 'UNKNOWN',
    noCognitoUserFound: status === 'NO_COGNITO_USER_FOUND',
  };
}
