import { service } from '@ember/service';
import StoreUserRoleService, {
  PermissionDefinition,
} from 'fabscale-app/services/store/user-role';
import BaseAsyncResource from './base-async-resource';

export default class LoadAvailablePermissionDefinitionsResource extends BaseAsyncResource<
  PermissionDefinition[],
  any
> {
  @service('store/user-role') userRoleStore: StoreUserRoleService;

  loadData() {
    return this.userRoleStore.getAvailablePermissionDefinitions();
  }
}
