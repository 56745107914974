import { NumericRoastBatchParameterTypes } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  NumericRoastBatchParameter,
  RoastBatch,
} from 'fabscale-app/models/roast-batch';

export default function getNumericRoastBatchParameters(roastBatch: RoastBatch) {
  let parameters = roastBatch.numericRoastBatchParameters;
  return NumericRoastBatchParameterTypes.map((type) =>
    parameters.find((parameter) => parameter.parameterType === type)
  ).filter(Boolean) as NumericRoastBatchParameter[];
}
