export function escapeHtml(str: string): string {
  if (!document) {
    return str;
  }

  let tempWrapper = document.createElement('div');
  let textNode = document.createTextNode(str);
  tempWrapper.appendChild(textNode);
  return tempWrapper.innerHTML;
}
