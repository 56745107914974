import GraphQLService from '@ember-graphql-client/client/services/graphql';
import Service, { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import labSamplesPaginated from 'fabscale-app/gql/queries/lab-samples.graphql';
import { PageDef, SortDef } from 'fabscale-app';
import { CoffeeType } from 'fabscale-app/components/shared/dropdown/coffee-type/component';
import { DateRange } from 'fabscale-app/models/date-range';
import {
  LabSample,
  LabSampleDto,
  PaginatedLabSamples,
} from 'fabscale-app/models/lab-sample';

export interface LabSamplesListFilters {
  dateRange: DateRange;
  coffeeTypes: CoffeeType[];
}

export default class LabSampleStore extends Service {
  @service graphql: GraphQLService;
  @service userSession: UserSessionService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  async getLabSamplesPaginated(
    filters: LabSamplesListFilters,
    pageDef: PageDef,
    sortDef: SortDef
  ): Promise<PaginatedLabSamples> {
    const { graphql, locationId } = this;

    const variables = {
      locationId: locationId,
      filters: {
        dateFrom: serializeDate(filters.dateRange!.start!),
        dateTo: serializeDate(filters.dateRange!.end!),
        coffeeTypes: filters.coffeeTypes,
      },
      pageDef,
      sortDirection: sortDef.sortDirection,
      sortBy: sortDef.sortBy,
    };

    return graphql
      .query({
        query: labSamplesPaginated,
        variables,
        namespace: 'labSamplesPaginated',
      })
      .then((response) => {
        const items = response.items.map(
          (item: LabSampleDto) => new LabSample(item)
        );
        return { items: items, pageInfo: response.pageInfo };
      });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/lab-sample': LabSampleStore;
  }
}
