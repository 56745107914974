import Component from '@glimmer/component';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { PlantAlarmOverTime } from 'fabscale-app/models/alarm-report';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  plantAlarmsOvertime: PlantAlarmOverTime[];
  data: ChartData;
  options: ChartOptions;
  plugins: Plugin[];
}

export default class PageAlarmReportLevelReportPerPlantAssetChartsAlarmLevelsPerPlantAsset extends Component<Args> {
  @tracked toggledDatasetIndex: number | undefined;
  get data() {
    const { data } = this.args;

    return data;
  }

  get options() {
    const { options } = this.args;

    return options;
  }

  get plugins() {
    const { plugins } = this.args;

    return plugins;
  }

  get roastersBannerData() {
    const { data } = this.args;

    const info = data?.datasets.map((item) => {
      return {
        color: item.backgroundColor,
        name: (item as any).label,
      };
    });

    return info;
  }

  @action
  handleToggle(index: number) {
    this.toggledDatasetIndex =
      this.toggledDatasetIndex === index ? undefined : index;
  }
}
