import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiData } from 'fabscale-app/models/kpi-data';
import ErrorParserService from 'fabscale-app/services/error-parser';
import SettingsService from 'fabscale-app/services/settings';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import UserSessionService from 'fabscale-app/services/user-session';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import { OeeKpiReportFilters } from 'fabscale-app/models/oee-kpi-report-models';

interface Args {
  filters: OeeKpiReportFilters;
  exactDateRange: DateRange;
}

export default class PageOeeKpiReportOverTime extends Component<Args> {
  @service userSession: UserSessionService;
  @service settings: SettingsService;
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked error?: string;
  @tracked oeeKpiTypes: KpiType[];
  @tracked highlightedOeeKpiType?: KpiType;
  @tracked overallKpiData?: KpiData[];

  get chartColors() {
    return [chartColors[15], chartColors[18], chartColors[14], chartColors[16]];
  }

  get hasInvalidDateRange() {
    return (
      this.args.exactDateRange.end.valueOf() <=
      this.args.exactDateRange.start.valueOf()
    );
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.initialiseSelectedOeeKpiTypes();

    this.loadOverallKpiDataTask.perform();
  }

  @action
  initialiseSelectedOeeKpiTypes() {
    this.oeeKpiTypes = this.args.filters.oeeKpiTypes;
  }

  @action
  updateHighlightedOeeKpiType(oeeKpiType: KpiType) {
    this.highlightedOeeKpiType =
      this.highlightedOeeKpiType === oeeKpiType ? undefined : oeeKpiType;
  }

  loadOverallKpiDataTask = restartableTask(async () => {
    const { exactDateRange } = this.args;
    const { oeeKpiTypes, plantAssetIds, recipeIds } = this.args.filters;
    this.error = undefined;

    try {
      const { days } = exactDateRange.end.diff(exactDateRange.start, 'days');

      this.overallKpiData = await this._query(
        oeeKpiTypes,
        {
          dateRange: exactDateRange,
          plantAssetIds,
          recipeIds,
        },
        {
          loadInParallel: days < 30 * 3,
        }
      );
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);

      logError(error);
    }
  });

  async _query(
    oeeKpiTypes: KpiType[],
    variables: {
      dateRange: DateRange;
      plantAssetIds?: string[];
      recipeIds?: string[];
    },
    { loadInParallel = true } = {}
  ): Promise<KpiData[] | any> {
    const { kpiDataStore } = this;

    if (loadInParallel) {
      return kpiDataStore.findMultiple(oeeKpiTypes, variables);
    }
  }
}
