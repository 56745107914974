import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Unit, UnitsTime, UnitTime } from 'fabscale-app/models/enums/units';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';
import formatDurationHelper from './format-duration';

export default class FormatRangeHelper extends Helper<{
  PositionalArgs: [minValue: number, maxValue: number, unit: Unit];
}> {
  @service enumLabels: EnumLabelsService;

  compute([minValue, maxValue, unit]: [number, number, Unit]) {
    if (UnitsTime.includes(unit as UnitTime)) {
      let min = formatDurationHelper(minValue, unit as UnitTime);
      let max = formatDurationHelper(maxValue, unit as UnitTime);

      if (minValue === maxValue) {
        return min;
      }

      return `${min}-${max}`;
    }

    let min = formatNumber(minValue);
    let max = formatNumber(maxValue);
    let unitLabel = this.enumLabels.unit(unit);

    if (minValue === maxValue) {
      return `${min} ${unitLabel}`.trim();
    }

    return `${min}-${max} ${unitLabel}`.trim();
  }
}
