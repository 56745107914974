import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import RouterService from '@ember/routing/router-service';
import StoreMaintenanceTaskService from 'fabscale-app/services/store/maintenance-task';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { TaskFormData } from './task-form';
import { RepeatConfigInput } from 'fabscale-app/models/repeat-config';
import { DateTime } from 'luxon';

interface Args {
  task: MaintenanceTask;
}

export default class MaintenanceTasksTasEdit extends Component<Args> {
  @service router: RouterService;
  @service('store/maintenance-task')
  maintenanceTaskStore: StoreMaintenanceTaskService;

  updateMaintenanceTaskTask = dropTask(async (data: TaskFormData) => {
    let { task } = this.args;
    let { id } = task;

    await this.maintenanceTaskStore.update(id, data);

    if (data.repeatConfig) {
      await this._maybeUpateRepeatConfig(data.repeatConfig);
    } else {
      await this._maybeUpdateDueDate(data.dueDate);
    }

    this.router.transitionTo('routes/maintenance.tasks.show.index', id);
  });

  async _maybeUpateRepeatConfig(repeatConfig: RepeatConfigInput) {
    let { task } = this.args;
    let { id } = task;

    if (JSON.stringify(repeatConfig) === JSON.stringify(task.repeatConfig)) {
      return;
    }

    await this.maintenanceTaskStore.updateRepeatConfig(id, repeatConfig);
  }

  async _maybeUpdateDueDate(dueDate: DateTime) {
    let { task } = this.args;
    let { id } = task;

    if (dueDate.hasSame(task.dueDate, 'day')) {
      return;
    }

    await this.maintenanceTaskStore.updateDueDate(id, dueDate);
  }
}
