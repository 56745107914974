import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import UserSessionService from 'fabscale-app/services/user-session';
import ReportsOeeKpiController from './controller';

export default class ReportsOeeKpi extends AuthenticatedRoute {
  @service userSession: UserSessionService;
  @service router: RouterService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (!this.userSession.permissionMap.OEE_MANAGE) {
      window.history.back();
    }
  }

  resetController(controller: ReportsOeeKpiController, isExiting: boolean) {
    if (isExiting) {
      schedule('afterRender', () => {
        controller.resetFilters();
      });
    }
  }
}
