import { DateString } from 'fabscale-app';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { UnitTime } from 'fabscale-app/models/enums/units';
import {
  deserializeDate,
  deserializeOptionalDate,
  serializeLocalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { convertTimeAmount } from 'fabscale-app/utilities/utils/unit-converter';
import { DateTime } from 'luxon';

export interface RepeatConfigInput {
  startDate: DateTime;
  timeInterval: TimeInterval;
  uptimeInterval?: UptimeInterval;
}

export interface TimeInterval {
  amount: number;
  interval: Interval;
}

export interface UptimeInterval {
  amount: number;
  unit: UnitTime;
}

export interface RepeatConfig {
  startDate: DateTime;
  timeInterval: TimeInterval;
  uptimeInterval?: UptimeInterval;
  estimatedDueDate: DateTime;
  estimatedTimeDueDate: DateTime;
  estimatedUptimeDueDate?: DateTime;
}

export interface RepeatConfigInputPojo {
  startDate: DateString;
  timeInterval: TimeInterval;
  uptimeInterval?: UptimeInterval;
}

export interface RepeatConfigPojo {
  startDate: DateString;
  timeInterval: TimeInterval;
  uptimeInterval?: UptimeInterval;
  estimatedDueDate: DateString;
  estimatedTimeDueDate: DateString;
  estimatedUptimeDueDate?: DateString;
}

export function deserializeRepeatConfig(
  repeatConfig: RepeatConfigPojo
): RepeatConfig {
  let {
    startDate,
    timeInterval,
    uptimeInterval,
    estimatedDueDate,
    estimatedTimeDueDate,
    estimatedUptimeDueDate,
  } = repeatConfig;

  // Always convert uptimeInterval to hours
  if (uptimeInterval && uptimeInterval.unit !== 'HOUR') {
    let amount = convertTimeAmount(
      uptimeInterval.amount,
      uptimeInterval.unit,
      'HOUR'
    );
    uptimeInterval.amount = amount;
    uptimeInterval.unit = 'HOUR';
  }

  return Object.assign(
    {},
    {
      startDate: deserializeDate(startDate),
      timeInterval,
      uptimeInterval,
      estimatedDueDate: deserializeDate(estimatedDueDate),
      estimatedTimeDueDate: deserializeDate(estimatedTimeDueDate),
      estimatedUptimeDueDate: deserializeOptionalDate(estimatedUptimeDueDate),
    }
  );
}

export function serializeRepeatConfigInput(
  repeatConfig: RepeatConfigInput
): RepeatConfigInputPojo {
  let { startDate, timeInterval, uptimeInterval } = repeatConfig;

  return Object.assign(
    {},
    {
      startDate: serializeLocalDate(startDate),
      timeInterval,
      uptimeInterval,
    }
  );
}
