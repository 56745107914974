import { service } from '@ember/service';
import StoreParameterGoalService from 'fabscale-app/services/store/parameter-goal';
import BaseAsyncResource from './base-async-resource';

export default class LoadParameterGoalCountResource extends BaseAsyncResource<
  number,
  any
> {
  @service('store/parameter-goal')
  parameterGoalStore: StoreParameterGoalService;

  async loadData() {
    return this.parameterGoalStore.queryCount();
  }
}
