import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { MonitoringSensor } from 'fabscale-app/models/plant-asset-monitoring';

interface UiSensorDetailsListArgs {
  sensors: MonitoringSensor[];
  pageSize: number;
}

export default class UiSensorDetailsList extends Component<UiSensorDetailsListArgs> {
  @tracked currentOffset = 0;

  @tracked
  currentPaginatedSensors = this.args.sensors.slice(
    this.args.pageSize * this.currentOffset,
    this.args.pageSize
  );

  @action
  updateCurrentPaginatedSensors() {
    this.currentPaginatedSensors = this.args.sensors.slice(
      this.args.pageSize * this.currentOffset,
      this.args.pageSize
    );
  }

  get isScrollable() {
    return this.args.sensors.length > this.args.pageSize;
  }

  @action
  moveTo(currentOffset: number) {
    this.currentOffset = currentOffset;
    const { pageSize } = this.args;

    this.currentPaginatedSensors = this.args.sensors.slice(
      pageSize * this.currentOffset,
      pageSize * this.currentOffset + pageSize
    );
  }
}
