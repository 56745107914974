import { service } from '@ember/service';
import Component from '@glimmer/component';
import {
  UnitSystem,
  UnitSystems,
} from 'fabscale-app/models/enums/unit-systems';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

interface Args {
  unitSystem: UnitSystem;
  onChange: (unitSystem: UnitSystem) => void;
}

export default class PlantSettingsUnitSystem extends Component<Args> {
  @service enumLabels: EnumLabelsService;

  unitSystemOptions: { value: UnitSystem; label: string }[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initOptions();
  }

  _initOptions() {
    let { enumLabels } = this;

    this.unitSystemOptions = UnitSystems.map((unitSystem) => {
      return { value: unitSystem, label: enumLabels.unitSystem(unitSystem) };
    });
  }
}
