import Controller from '@ember/controller';
import { service } from '@ember/service';
import { getOwnConfig } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export default class LoginController extends Controller {
  @service userAuthentication: UserAuthenticationService;
  isSimulation = getOwnConfig<any>().isSimulation;

  queryParams = ['username'];

  @tracked username?: string;

  get loginRequiredErrorMessage() {
    return this.userAuthentication.loginRequiredErrorMessage;
  }
}
