import Component from '@glimmer/component';
import { Unit } from 'fabscale-app/models/enums/units';

export interface HorizontalProgressItem {
  label: string;
  value: number;
  percentage: string;
  unit?: Unit;
  color: string;
}

interface Args {
  item: HorizontalProgressItem;
}

export default class UiHorizontalProgressItem extends Component<Args> {
  get percentage() {
    const { item } = this.args;

    return `${item.value}%`;
  }

  get style() {
    return `${this.width} ${this.color}`;
  }

  get width() {
    const { item } = this.args;

    return `width: ${parseInt(item.percentage)}%;`;
  }

  get color() {
    const { item } = this.args;

    return `background-color: ${item.color};`;
  }
}
