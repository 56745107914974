import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import plantAlarmsSummaryGroupedQuery from 'fabscale-app/gql/queries/plant-alarms-summary-grouped.graphql';
import plantAlarmsOvertimeQuery from 'fabscale-app/gql/queries/plant-alarms-overtime.graphql';
import plantAlarmPerRecipeQuery from 'fabscale-app/gql/queries/plant-alarm-per-recipe.graphql';
import GraphQLService from '../-graphql';
import EnumLabelsService from '../enum-labels';
import SettingsService from '../settings';
import UserSessionService from '../user-session';
import {
  PlantAlarmSummary,
  PlantAlarmOverTime,
  PlantAlarmFilters,
  PlantAlarmsOverTimeFilters,
} from 'fabscale-app/models/alarm-report';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';

export default class StoreAlarmLevelReportService extends Service {
  @service l10n: L10nService;
  @service graphql: GraphQLService;
  @service enumLabels: EnumLabelsService;
  @service settings: SettingsService;
  @service userSession: UserSessionService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  async findPlantAlarmsSummaryGrouped(
    filters: PlantAlarmFilters
  ): Promise<PlantAlarmSummary[]> {
    const { graphql, locationId } = this;

    const variables = {
      filters: {
        dateFrom: serializeDate(filters.dateFrom),
        dateTo: serializeDate(filters.dateTo),
        plantAssetIds: filters.plantAssetIds,
        recipeIds: filters.recipeIds,
      },
      locationId: locationId,
    };

    return graphql
      .query({
        query: plantAlarmsSummaryGroupedQuery,
        variables,
        namespace: 'plantAlarmsSummaryGrouped',
      })
      .then((response) => response.items);
  }

  async findPlantAlarmsOvertime(
    filters: PlantAlarmsOverTimeFilters
  ): Promise<PlantAlarmOverTime[]> {
    const { graphql, locationId } = this;

    const variables = {
      filters: {
        dateFrom: serializeDate(filters.dateFrom),
        dateTo: serializeDate(filters.dateTo),
        plantAssetIds: filters.plantAssetIds,
        recipeIds: filters.recipeIds,
        intervalDefinition: filters.intervalDefinition,
      },
      locationId: locationId,
    };

    return graphql.query(
      {
        query: plantAlarmsOvertimeQuery,
        variables,
        namespace: 'plantAlarmsOvertime',
      },
      {
        cacheEntity: 'PlantAlarmsOvertime',
        cacheSeconds: 300,
      }
    );
  }

  async findPlantAlarmPerRecipe(filters: PlantAlarmFilters): Promise<any[]> {
    const { graphql, locationId } = this;

    const variables = {
      filters: {
        dateFrom: serializeDate(filters.dateFrom),
        dateTo: serializeDate(filters.dateTo),
        plantAssetIds: filters.plantAssetIds,
        recipeIds: filters.recipeIds,
        alarmExternalId: filters.alarmExternalIds,
        alarmType: filters.alarmType,
        alarmLevels: filters.alarmLevels,
      },
      locationId: locationId,
    };

    return graphql.query({
      query: plantAlarmPerRecipeQuery,
      variables,
      namespace: 'plantAlarmPerRecipe',
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/alarm-level-report': StoreAlarmLevelReportService;
  }
}
