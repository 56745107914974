export function hashStringToColor(str: string): string {
  let hash = hashColorString(str);
  let r = (hash & 0xff0000) >> 16;
  let g = (hash & 0x00ff00) >> 8;
  let b = hash & 0x0000ff;

  let rStr = `0${r.toString(16)}`.substr(-2);
  let gStr = `0${g.toString(16)}`.substr(-2);
  let bStr = `0${b.toString(16)}`.substr(-2);

  return `#${rStr}${gStr}${bStr}`;
}

// Based on https://awik.io/determine-color-bright-dark-using-javascript/
export function colorIsLight(color: string): boolean {
  let { r, g, b } = hexToRgb(color);

  // HSP (Highly Sensitive Perceived brightness) equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 170;
}

// ==============================
// PRIVATE
// ==============================

function hexToRgb(hex: string) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result || result.length < 3) {
    throw new Error(`Could not convert "${hex}" to RGB color`);
  }

  return {
    r: parseInt(result[1]!, 16),
    g: parseInt(result[2]!, 16),
    b: parseInt(result[3]!, 16),
  };
}

function hashColorString(str: string): number {
  // Modify the seeds to change the generated colors
  let seed = 130;
  let seed2 = 90;
  let hash = 0;

  // make hash more sensitive for short string like 'a', 'b', 'c'
  str += 'x';
  // Note: Number.MAX_SAFE_INTEGER equals 9007199254740991
  let MAX_SAFE_INTEGER = Math.floor(9007199254740991 / seed2);

  for (let i = 0; i < str.length; i++) {
    if (hash > MAX_SAFE_INTEGER) {
      hash = Math.floor(hash / seed2);
    }

    hash = hash * seed + str.charCodeAt(i);
  }

  return hash;
}
