import { NumberValue, scaleLinear } from 'd3-scale';
import { AxisConfigNumbers } from 'fabscale-app/components/chart';
import {
  getAxisTicks,
  getPaddedMinMaxValues,
  MinMax,
} from 'fabscale-app/utilities/utils/chart';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';

interface ScaleNumberConfig {
  tickNumber?: number;
}

export default function chartScaleNumbers(
  values: number[],
  height: number,
  scaleConfig: ScaleNumberConfig
): AxisConfigNumbers {
  let filteredValues = values.filter((value) => value !== 0);

  let minMax = getMinMaxForValues(filteredValues);
  let scale = getScale({ height, minMax });

  let ticks = getYAxisTicks(values, minMax, scaleConfig);
  let tickFormat = (value: NumberValue) =>
    formatNumber(typeof value === 'number' ? value : value.valueOf());

  return {
    scale,
    ticks,
    tickFormat,
  };
}

function getMinMaxForValues(values: number[]) {
  let yScalePaddingMax = 0;
  let yScalePaddingMin = 0.2;

  return getPaddedMinMaxValues(values, yScalePaddingMax, yScalePaddingMin);
}

function getScale({ height, minMax }: { height: number; minMax: MinMax }) {
  let { minValue, maxValue } = minMax;

  return scaleLinear().domain([minValue, maxValue]).range([height, 0]);
}

function getYAxisTicks(
  values: number[],
  minMax: MinMax,
  scaleConfig?: ScaleNumberConfig
) {
  let yAxisTickNumber = scaleConfig?.tickNumber || 5;

  return getAxisTicks(minMax, yAxisTickNumber, values);
}
