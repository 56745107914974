import { service } from '@ember/service';
import { Ability } from 'ember-can';
import UserSessionService from 'fabscale-app/services/user-session';

export default class DataExportAbility extends Ability {
  @service userSession: UserSessionService;

  get canUse(): boolean {
    return Boolean(this.userSession.permissionMap.CAN_EXPORT_DATA);
  }
}
