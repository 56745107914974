import Component from '@glimmer/component';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';
import { action } from '@ember/object';
import { Location } from 'fabscale-app/models/location';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export default class SelectLocation extends Component {
  @service userSession: UserSessionService;
  @service userAuthentication: UserAuthenticationService;

  get locations() {
    return this.userSession.locations;
  }

  get userName() {
    return this.userSession.user!.name;
  }

  @action
  async selectLocation(location: Location) {
    await this.userSession.setCurrentLocation(location);
    await this.userAuthentication.redirectAfterLogin();
  }
}
