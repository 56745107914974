import Component from '@glimmer/component';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import UserSessionService from 'fabscale-app/services/user-session';
import { DateTime } from 'luxon';
import { Location } from 'fabscale-app/models/location';
import { sortBy } from 'fabscale-app/utilities/utils/array';

interface Args {
  availableLocations: Location[];
  selectLocation: (location: Location) => void;
}

export default class ModuleSelectLocation extends Component<Args> {
  @service router: RouterService;
  @service userSession: UserSessionService;

  currentDate = DateTime.local();

  get locations() {
    let { currentDate } = this;

    let locations = sortBy(this.args.availableLocations, 'name');

    return locations.map((location) => {
      let date = currentDate.setZone(location.timezoneName);
      return { location, date };
    });
  }
}
