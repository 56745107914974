import Component from '@glimmer/component';
import { MaintenanceTaskFiltersSerializable } from 'fabscale-app/components/page/maintenance-tasks/filters';

interface Args {
  plantAssetId: string;
  plantAssetAreaId: string;
}

export default class PlantAssetsComponentDetailsRelatedTasks extends Component<Args> {
  get linkQuery() {
    let { plantAssetId, plantAssetAreaId } = this.args;

    let filters: MaintenanceTaskFiltersSerializable = {
      plantAssetIds: [plantAssetId],
      plantAssetAreaIds: [plantAssetAreaId],
    };

    return { filters: JSON.stringify(filters) };
  }
}
