import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange } from 'fabscale-app/models/time-range';
import SettingsService from 'fabscale-app/services/settings';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import { DateTime } from 'luxon';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

export interface PlantAssetAlertOverviewFilters {
  dateRange?: DateRangeOptional;
  timeRange?: TimeRange;
  plantAssetIds?: string[];
  alarmLevels?: PlantAssetAlertLevel[];
  alarmTypes?: PlantAssetAlarmType[];
  alarmExternalIds?: string[];
  alarmTitle?: string;
}

interface Args {
  filters: PlantAssetAlertOverviewFilters;
  updateFilters: (filters: PlantAssetAlertOverviewFilters) => void;
}

export default class PlantAssetAlertOverviewIndex extends Component<Args> {
  @service settings: SettingsService;

  get filters(): PlantAssetAlertOverviewFilters {
    return {
      ...this.args.filters,
      timeRange: this.timeRange,
    };
  }

  get timeRange(): TimeRange {
    return {
      ...this.defaultTimeRange,
      ...this.args.filters.timeRange,
    };
  }

  @cached
  get defaultTimeRange() {
    const { dayStartTime: start } = this.settings.locationSettings;
    return { start, end: getEndTimeIsoString(start) };
  }

  get dateRange(): DateRange {
    return getActualDateRange(this.args.filters.dateRange);
  }
}

// The default range is supposed to cover "all", but allow us to always have a date range to work with.
export function getActualDateRange(dateRange?: DateRangeOptional) {
  return {
    start:
      dateRange?.start ?? DateTime.local().minus({ years: 10 }).startOf('day'),
    end: dateRange?.end ?? DateTime.local().plus({ days: 1 }).startOf('day'),
  };
}
