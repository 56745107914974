import {
  ALL_RELATIVE_TIMEFRAMES,
  RelativeTimeframe,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import SharedDropdownBase from '../../base/dropdown-base';

export default class SharedDropdownTimeframe extends SharedDropdownBase<RelativeTimeframeId> {
  override get options() {
    return ALL_RELATIVE_TIMEFRAMES.map(
      (timeframe: RelativeTimeframe) => timeframe.id
    );
  }
}
