import { EnumsBase } from './-base';

export type Permission =
  | 'GLOBAL_ADMIN'
  | 'COMPANY_MANAGE'
  | 'LOCATION_MANAGE'
  | 'USER_ROLE_MANAGE'
  | 'USER_VIEW'
  | 'USER_MANAGE'
  | 'PLANT_ASSET_MANAGE'
  | 'PLANT_ASSET_VIEW'
  | 'PLANT_ASSET_COMPONENT_MANAGE'
  | 'PLANT_ASSET_STATE_VIEW'
  | 'PLANT_ASSET_STATE_MANAGE'
  | 'PLANT_ASSET_ALERT_MANAGE'
  | 'PLANT_ASSET_MONITORING_VIEW'
  | 'TASK_VIEW'
  | 'TASK_MANAGE'
  | 'ROAST_BATCH_VIEW'
  | 'ROAST_BATCH_MANAGE'
  | 'PARAMETER_GOAL_MANAGE'
  | 'OEE_MANAGE'
  | 'CAN_EXPORT_DATA'
  | 'ROASTPIC_VIEW'
  | 'ROASTPIC_MANAGE';

export class PermissionsEnums extends EnumsBase<Permission> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in Permission]: string } = {
      GLOBAL_ADMIN: l10n.t('Global admin'),
      COMPANY_MANAGE: l10n.t('Manage company'),
      LOCATION_MANAGE: l10n.t('Manage plant'),
      USER_ROLE_MANAGE: l10n.t('Manage user roles'),
      USER_VIEW: l10n.t('View users'),
      USER_MANAGE: l10n.t('Manage users'),
      PLANT_ASSET_VIEW: l10n.t('View plant assets'),
      PLANT_ASSET_MANAGE: l10n.t('Manage plant assets'),
      PLANT_ASSET_COMPONENT_MANAGE: l10n.t('Manage plant asset components'),
      PLANT_ASSET_STATE_VIEW: l10n.t('View plant asset state info'),
      PLANT_ASSET_STATE_MANAGE: l10n.t('Manage plant asset state info'),
      PLANT_ASSET_ALERT_MANAGE: l10n.t('Manage plant alarms'),
      PLANT_ASSET_MONITORING_VIEW: l10n.t('View plant asset monitoring'),
      TASK_VIEW: l10n.t('View tasks'),
      TASK_MANAGE: l10n.t('Manage tasks'),
      ROAST_BATCH_VIEW: l10n.t('View roast batches'),
      ROAST_BATCH_MANAGE: l10n.t('Manage roast batches'),
      PARAMETER_GOAL_MANAGE: l10n.t('Manage parameter goals'),
      OEE_MANAGE: l10n.t('Manage OEE kpis'),
      CAN_EXPORT_DATA: l10n.t('Export data to PDF or Excel'),
      ROASTPIC_VIEW: l10n.t('View Roastpic'),
      ROASTPIC_MANAGE: l10n.t('Manage Roastpic'),
    };

    this.labels = labels;
  }
}

export class PermissionDescriptionsEnums extends EnumsBase<Permission> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in Permission]: string } = {
      GLOBAL_ADMIN: 'INTERNAL: Create & manage all companies & locations',
      COMPANY_MANAGE: l10n.t('View & edit company settings'),
      LOCATION_MANAGE: l10n.t('View & edit plant settings'),
      USER_ROLE_MANAGE: l10n.t('View & edit user roles'),
      USER_VIEW: l10n.t('View all users of your company'),
      USER_MANAGE: l10n.t('Invite & edit all users of your company'),
      PLANT_ASSET_VIEW: l10n.t('View plant asset details'),
      PLANT_ASSET_MANAGE: l10n.t('Edit plant assets'),
      PLANT_ASSET_COMPONENT_MANAGE: l10n.t('Edit plant asset components'),
      PLANT_ASSET_STATE_VIEW: l10n.t('View plant asset state info'),
      PLANT_ASSET_STATE_MANAGE: l10n.t('Edit plant asset state info & reasons'),
      PLANT_ASSET_ALERT_MANAGE: l10n.t('View plant alarms'),
      PLANT_ASSET_MONITORING_VIEW: l10n.t('View plant asset monitoring'),
      TASK_VIEW: l10n.t('View tasks & edit tasks assigned to you'),
      TASK_MANAGE: l10n.t('Edit all tasks'),
      ROAST_BATCH_VIEW: l10n.t('View all roast data'),
      ROAST_BATCH_MANAGE: l10n.t('Edit roast data'),
      PARAMETER_GOAL_MANAGE: l10n.t('View & edit parameter goals'),
      OEE_MANAGE: l10n.t('Manage OEE kpis'),
      CAN_EXPORT_DATA: l10n.t('Export data to PDF or Excel'),
      ROASTPIC_VIEW: l10n.t('View Roastpic'),
      ROASTPIC_MANAGE: l10n.t('Manage Roastpic'),
    };

    this.labels = labels;
  }
}

// NOTE: The manage permissions must be above the view permissions here
// Otherwise, the nested UI will not work as expected
export const SORTED_PERMISSIONS: Permission[] = [
  'GLOBAL_ADMIN',
  'COMPANY_MANAGE',
  'LOCATION_MANAGE',
  'USER_ROLE_MANAGE',
  'USER_MANAGE',
  'USER_VIEW',
  'PLANT_ASSET_MANAGE',
  'PLANT_ASSET_VIEW',
  'PLANT_ASSET_COMPONENT_MANAGE',
  'PLANT_ASSET_STATE_MANAGE',
  'PLANT_ASSET_STATE_VIEW',
  'PLANT_ASSET_ALERT_MANAGE',
  'PLANT_ASSET_MONITORING_VIEW',
  'TASK_MANAGE',
  'TASK_VIEW',
  'ROAST_BATCH_MANAGE',
  'ROAST_BATCH_VIEW',
  'ROASTPIC_MANAGE',
  'ROASTPIC_VIEW',
  'PARAMETER_GOAL_MANAGE',
  'OEE_MANAGE',
  'CAN_EXPORT_DATA',
];
