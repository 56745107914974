import { assert } from '@ember/debug';
import { guidFor } from '@ember/object/internals';
import { compare, isNone } from '@ember/utils';
import {
  LineChartData,
  LineChartDataInput,
} from 'fabscale-app/components/chart';
import { uniq } from 'fabscale-app/utilities/utils/array';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { getLocalTimezone } from 'fabscale-app/utilities/utils/timezone';
import { DateTime } from 'luxon';

interface LineChartConfig {
  color?: string;
}

export default function chartLineChartData(
  data: LineChartDataInput[],
  config?: LineChartConfig
) {
  let parsedData = parseData(data, config);
  let sortedData = sortData(parsedData);

  let values = getValuesBeforeNow(sortedData);
  let dates = getDates(sortedData);
  let labels = getLabels(sortedData);

  return {
    parsedData: sortedData,
    values,
    dates,
    labels,
  };
}

function parseData(
  data: LineChartDataInput[],
  config?: LineChartConfig
): LineChartData[] {
  assert(
    'LineChart: data must be an array of objects',
    Array.isArray(data) && !data.find((item) => typeof item !== 'object')
  );

  assert(
    `LineChart: data must have at least two items, but has only ${data.length}`,
    data.length >= 2
  );

  assert(
    'LineChart: all data items must have the following properties: date, value, label',
    !data.some(
      (item) => isNone(item.value) || isNone(item.date) || isNone(item.label)
    )
  );

  let colorLabelMap = getColorsPerLabel(data);

  // D3 uses local dates, so we need to convert them here
  // We want to keep the time as the same it was, just change the timezone
  let zoneName = getLocalTimezone();

  return data.map((item) => {
    let { date } = item;

    let localDate = date.setZone(zoneName, { keepLocalTime: true });

    return Object.assign(
      {
        color: config?.color || colorLabelMap[item.label]!,
        itemGuid: guidFor(item),
        jsDate: localDate.toJSDate(),
      },
      item,
      {
        date: localDate,
      }
    );
  });
}

function sortData(data: LineChartData[]): LineChartData[] {
  return data.sort((a, b) => {
    return compare(a.date.valueOf(), b.date.valueOf());
  });
}

function getValuesBeforeNow(data: LineChartData[]) {
  let zoneName = getLocalTimezone();
  let now = +DateTime.local().setZone(zoneName, { keepLocalTime: true });
  return data.filter((item) => +item.date <= now).map((item) => item.value);
}

function getDates(data: LineChartData[]) {
  let dates: DateTime[] = [];

  data.forEach((item) => {
    if (!dates.some((date) => +date === +item.date)) {
      dates.push(item.date);
    }
  });

  return dates;
}

function getLabels<T extends { label: string }>(data: T[]): T['label'][] {
  let uniqueLabels: T['label'][] = uniq(data.map((item) => item.label));
  return uniqueLabels.sort(compare);
}

function getColorsPerLabel<T extends LineChartDataInput>(
  data: T[]
): { [key in T['label']]: string } {
  let labels = getLabels(data);

  let map = Object.fromEntries(
    labels.map((label, index) => {
      return [label, getColorForIndex(index)];
    })
  ) as { [key in T['label']]: string };

  return map;
}

function getColorForIndex(colorIndex: number) {
  while (colorIndex > chartColors.length) {
    colorIndex -= chartColors.length;
  }

  return chartColors[colorIndex];
}
