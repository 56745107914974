import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { HorizontalProgressItem } from 'fabscale-app/components/ui/horizontal-progress/item/component';
import { PlantAlarmOverTimeTimeSeries } from 'fabscale-app/models/alarm-type-report';
import { DateBinValue } from 'fabscale-app/models/date-bin-value';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';

export default function plantAlarmsTypeOverTimeToProgressItems(
  data: PlantAlarmOverTimeTimeSeries[]
): HorizontalProgressItem[] {
  if (!data) {
    return [];
  }

  const totalValue: number = data.reduce(
    (total: number, dataItem: PlantAlarmOverTimeTimeSeries) => {
      const values: number[] = dataItem.dateValueTimeSeries.map(
        (dateBinValue: DateBinValue) => dateBinValue.value
      );

      return total + values.reduce((total, value) => total + value, 0);
    },
    0
  );

  return data.map((dataItem: PlantAlarmOverTimeTimeSeries, index: number) => {
    const totalPerDataItem: number = dataItem.dateValueTimeSeries.reduce(
      (total, dateBinValue: DateBinValue) => total + dateBinValue.value,
      0
    );

    return {
      label: dataItem.idNamePair.name,
      value: totalPerDataItem,
      percentage:
        totalValue === 0
          ? '0'
          : `${formatNumber((totalPerDataItem / totalValue) * 100)}`,
      color: chartColors[index] as string,
    };
  });
}
