import { action } from '@ember/object';
import Component from '@glimmer/component';
import { ScaleBand, ScaleTime } from 'd3-scale';
import { TimelineBarChartData } from '..';

interface Signature {
  Args: {
    item: TimelineBarChartData;
    updateTooltip: (items: TimelineBarChartData[] | undefined) => void;
    xScale: ScaleTime<Date, number>;
    yScale: ScaleBand<string>;
  };
}

export default class ChartTimelineBarChartBar extends Component<Signature> {
  get xStart() {
    return this.args.xScale(this.args.item.startDateJs);
  }

  get xEnd() {
    return this.args.xScale(this.args.item.endDateJs);
  }

  get y() {
    return this.args.yScale(this.args.item.label);
  }

  // We want to have a min. width of 2, to avoid mini-bars that are basically invisible
  get width() {
    return Math.max(2, this.xEnd - this.xStart);
  }

  // Note this is SVG-border radius, so a bit different from CSS one
  get borderRadius() {
    let { width } = this;

    if (width <= 3) {
      return 2;
    }

    if (width <= 8) {
      return 3;
    }

    if (width <= 12) {
      return 4;
    }

    if (width <= 16) {
      return 5;
    }

    return 7;
  }

  @action
  onMouseEvent() {
    this.args.updateTooltip([this.args.item]);
  }

  @action
  onMouseLeave() {
    this.args.updateTooltip(undefined);
  }
}
