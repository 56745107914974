import Component from '@glimmer/component';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import { DateRange } from 'fabscale-app/models/date-range';
import { DashboardDateOption } from 'fabscale-app/routes/dashboard/index/controller';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateTime } from 'luxon';
import { DateBinValue } from 'fabscale-app/models/date-bin-value';
import {
  calculateTendency,
  checkTendencyIsPositive,
} from 'fabscale-app/utilities/utils/transform-kpi-data';
import { cssObj } from 'fabscale-app/utilities/utils/chart';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { service } from '@ember/service';
import formatNumber from 'fabscale-app/helpers/format-number';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';

export default class UiDashboardCardsKpiOverTimeCard extends Component<{
  Args: {
    overTimeData: KpiDataOverTime;
    dateRange: DateRange;
    kpiType: KpiType;
    cutOffDate?: DateTime;
    dateOption: DashboardDateOption;
  };
}> {
  @service enumLabels: EnumLabelsService;

  get mustFetchOverallKpiData() {
    // In this case, we cannot get it from the overall records, but must fetch it separately
    return this.args.dateOption === 'LAST_24H';
  }

  get overallValue(): number {
    let { dateBinValues } = this.args.overTimeData;

    return getDateBinValuesSum(dateBinValues, this.args.dateRange);
  }

  get unit() {
    return this.args.overTimeData.unit;
  }

  get comparativeValue() {
    let { dateBinValues, higherIsBetter } = this.args.overTimeData;
    let { dateRange } = this.args;
    let { overallValue } = this;

    let dayDiff = Math.ceil(dateRange.end.diff(dateRange.start, 'days').days);

    let previousValue = getDateBinValuesSum(dateBinValues, {
      start: dateRange.start.minus({ days: dayDiff }),
      end: dateRange.end.minus({ days: dayDiff }),
    });

    let tendency =
      !overallValue && !previousValue
        ? undefined
        : calculateTendency(previousValue, overallValue);
    let tendencyIsPositive =
      typeof tendency === 'number'
        ? checkTendencyIsPositive(tendency, higherIsBetter)
        : true;

    return {
      tendency: tendency ? tendency * 100 : tendency,
      tendencyIsPositive,
    };
  }

  private _chartTooltip = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipId = `${chart.canvas.id}-tooltip`;

    // Get or create tooltip
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!tooltip?.dataPoints) {
      return;
    }

    const dateFrom =
      tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
        .dateRange.start;
    const dateTo =
      tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
        .dateRange.end;
    const formatedDateFrom = formatDate(dateFrom, DateFormat.DateTime);
    const formatedDateTo = formatDate(dateTo, DateFormat.DateTime);

    const dateLabel = `${formatedDateFrom} - ${formatedDateTo}`;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      const unit = tooltip.dataPoints[0].dataset.unit;
      const value =
        tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]
          .value;

      bodyLines.forEach(() => {
        const unitString = this.enumLabels.unit(unit);
        const valueUnitPairString = `${formatNumber(
          value,
          undefined
        )} ${unitString}`;

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let valueUnitPairElement = document.createElement('p');
        valueUnitPairElement.innerHTML = `${valueUnitPairString}`;
        valueUnitPairElement.style.marginRight = cssObj.spacings._8px;
        valueUnitPairElement.style.fontSize = cssObj.spacings._14px;
        valueUnitPairElement.style.fontWeight = cssObj.fontWeight._700;
        valueUnitPairElement.style.fontFamily = cssObj.fontFamily.lato;

        let rowTextContainer = document.createElement('span');
        rowTextContainer.style.display = cssObj.display.flex;
        rowTextContainer.style.flexDirection = cssObj.flex.flexDirection.row;
        rowTextContainer.append(valueUnitPairElement);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';
        tableDataContainer.append(rowTextContainer);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      let dateLabelElement = document.createElement('p');
      dateLabelElement.style.marginBottom = cssObj.spacings._0px;
      dateLabelElement.style.color = cssObj.colors.sonicSilver;
      dateLabelElement.style.fontSize = cssObj.spacings._12px;
      dateLabelElement.innerHTML = dateLabel;

      tableBody.appendChild(dateLabelElement);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${Number(positionX) + Number(tooltip.caretX)}px`;
    tooltipEl.style.top = `${Number(positionY) + Number(tooltip.caretY)}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  get chartOptions() {
    return {
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this._chartTooltip,
        },
      },
      scales: {
        x: {
          offset: true,
          border: {
            display: true,
            color: cssObj.colors.lightGray,
          },
          grid: {
            display: true,
            drawTicks: true,
            color: cssObj.colors.transparent,
            tickColor: cssObj.colors.lightGray,
            tickLength: 10,
          },
          ticks: {
            font: {
              size: 10,
              weight: 700,
              family: cssObj.fontFamily.lato,
            },
            display: true,
            autoSkipPadding: 10,
            autoSkip: true,
            color: cssObj.colors.sonicSilver,
          },
        },
        y: {
          beginAtZero: true,
          offset: false,
          position: cssObj.position.left,
          ticks: {
            display: function (context: any) {
              const hasRecords =
                !!context.scale.chart.config._config.data.datasets.length;
              return hasRecords;
            },
            font: {
              size: 10,
            },
            maxTicksLimit: 5,
          },
          border: {
            display: false,
            dash: [10, 10],
            dashOffset: 2.0,
          },
          grid: {
            tickLength: 4,
            tickColor: cssObj.colors.cultured,
            color: cssObj.colors.lightGray,
          },
        },
      },
    };
  }
}

function getDateBinValuesSum(
  dateBinValues: DateBinValue[],
  dateRange: DateRange
) {
  let startVal = dateRange.start.valueOf();
  let endVal = dateRange.end.valueOf();

  let bins = dateBinValues.filter((bin) => {
    let startsAfter = bin.dateRange.start.valueOf() >= startVal;
    let endsBefore = bin.dateRange.end.valueOf() <= endVal;

    return startsAfter && endsBefore;
  });

  return bins.reduce((total, bin) => total + bin.value, 0);
}
