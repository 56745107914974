import Component from '@glimmer/component';
import { service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import { DateTime } from 'luxon';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { DateRange } from 'fabscale-app/models/date-range';
import SettingsService from 'fabscale-app/services/settings';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { generateComparativeValue } from 'fabscale-app/utilities/utils/transform-kpi-data';
import ErrorParserService from 'fabscale-app/services/error-parser';

interface Args {
  dateRange: DateRange;
  exactDateRange: DateRange;
  kpiType: KpiType;
  plantAssetIds?: string[];
  recipeIds?: string[];
  setUnit: (unit: Unit | undefined) => void;
}

export default class KpiDetailsReportOverall extends Component<Args> {
  @service settings: SettingsService;
  @service l10n: L10nService;
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked overallKpiData?: KpiData;
  @tracked error?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.loadKpiDataTask.perform();
  }

  get currentTimeframeLabel() {
    return this._getDateRangeLabel(this.args.exactDateRange);
  }

  get hasInvalidDateRange() {
    return (
      this.args.exactDateRange.end.valueOf() <=
      this.args.exactDateRange.start.valueOf()
    );
  }

  get previousTimeframeLabel() {
    let { exactDateRange } = this.args;

    let previousDateRange = this._getPreviousDateRange(exactDateRange);
    return this._getDateRangeLabel(previousDateRange);
  }

  async _loadData({
    kpiType,
    dateRange,
    plantAssetIds,
    recipeIds,
  }: {
    kpiType: KpiType;
    dateRange: DateRange;
    plantAssetIds: string[] | undefined;
    recipeIds: string[] | undefined;
  }) {
    let { kpiDataStore } = this;

    let kpiData: KpiData = await kpiDataStore.find(kpiType, {
      dateRange,
      recipeIds,
      plantAssetIds,
    });

    let previousDateRange = this._getPreviousDateRange(dateRange);

    let comparativeKpiData: KpiData = await kpiDataStore.find(kpiType, {
      dateRange: previousDateRange,
      recipeIds,
      plantAssetIds,
    });

    generateComparativeValue(kpiData, comparativeKpiData);

    return kpiData;
  }

  loadKpiDataTask = restartableTask(async () => {
    let { exactDateRange, kpiType, plantAssetIds, recipeIds } = this.args;

    let kpiData: KpiData | undefined;

    try {
      kpiData = await this._loadData({
        kpiType,
        dateRange: exactDateRange,
        plantAssetIds,
        recipeIds,
      });
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);

      logError(error);
      return;
    }

    this.overallKpiData = kpiData;

    // We save the unit on the parent element, to make it easier to use it everywhere without querying...
    let unit = kpiData?.unit;
    this.args.setUnit(unit);
  });

  _getDateRangeLabel(dateRange: DateRange) {
    let { l10n } = this;
    let { start, end } = dateRange;

    if (!end.hasSame(start, 'day')) {
      return `${formatDate(start, DateFormat.DateTime)} - ${formatDate(
        end,
        DateFormat.DateTime
      )}`;
    }

    let today = DateTime.local();
    let yesterday = DateTime.local().minus({ days: 1 });

    let startTime = formatDate(start, DateFormat.Time);
    let endTime = formatDate(end, DateFormat.Time);

    if (end.hasSame(today, 'day')) {
      return `${l10n.t('Today')}, ${startTime} - ${endTime}`;
    }

    if (end.hasSame(yesterday, 'day')) {
      return `${l10n.t('Yesterday')}, ${startTime} - ${endTime}`;
    }

    return `${formatDate(end)}, ${startTime} - ${endTime}`;
  }

  _getPreviousDateRange(dateRange: DateRange) {
    let days = Math.max(
      Math.ceil(dateRange.end.diff(dateRange.start, 'days').days),
      1
    );

    return new DateRange({
      start: dateRange.start.minus({ days }),
      end: dateRange.end.minus({ days }),
    });
  }
}
