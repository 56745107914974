import { service } from '@ember/service';
import { DateRange } from 'fabscale-app/models/date-range';
import BaseAsyncResource from './base-async-resource';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import { PageDef, SortDef } from 'fabscale-app';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { PaginatedLabSamples } from 'fabscale-app/models/lab-sample';

interface LabSamplesPaginatedRequest {
  filters: { dateRange: DateRange; coffeeTypes: any };
  page: number;
  pageSize: number;
  sortBy: RoastpicSortOption;
  sortDirection: SortDirection;
}

export default class LoadLabSamplesResource extends BaseAsyncResource<
  PaginatedLabSamples,
  LabSamplesPaginatedRequest
> {
  @service('store/lab-sample') labSamplesStore: LabSampleStore;

  async loadData(options: LabSamplesPaginatedRequest) {
    const pageDef: PageDef = {
      page: options.page,
      pageSize: options.pageSize,
    };

    const sortDef: SortDef = {
      sortBy: options.sortBy,
      sortDirection: options.sortDirection,
    };

    return this.labSamplesStore.getLabSamplesPaginated(
      options.filters,
      pageDef,
      sortDef
    );
  }
}
