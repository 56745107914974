import Service, { service } from '@ember/service';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import GraphQLService from 'fabscale-app/services/-graphql';
import featureByNameQuery from 'fabscale-app/gql/admin/queries/feature-by-name.graphql';
import allFeaturesQuery from 'fabscale-app/gql/admin/queries/all-features.graphql';
import createFeatureMutation from 'fabscale-app/gql/admin/mutations/create-feature.graphql';
import updateFeatureMutation from 'fabscale-app/gql/admin/mutations/update-feature.graphql';
import deleteFeatureMutation from 'fabscale-app/gql/admin/mutations/delete-feature.graphql';
import toggleFeatureForCompanyMutation from 'fabscale-app/gql/admin/mutations/toggle-feature-for-company.graphql';
import { Feature } from 'fabscale-app/models/feature';
import { sortBy } from 'fabscale-app/utilities/utils/array';

export default class StoreFeatureService extends Service {
  @service graphql: GraphQLService;

  // METHODS
  async findByName(name: string): Promise<Feature> {
    let { graphql } = this;

    let variables = { name };

    try {
      let response = await graphql.query({
        query: featureByNameQuery,
        variables,
        namespace: 'featureByName',
      });
      return new Feature(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = `The feature with name ${name} coult not be found.`;
      }

      throw error;
    }
  }

  async findAll(): Promise<Feature[]> {
    let { graphql } = this;

    let variables = {};

    let response = await graphql.query({
      query: allFeaturesQuery,
      variables,
      namespace: 'internal_featuresList',
    });

    return sortBy(
      response.map((featureData: any) => new Feature(featureData)),
      'name'
    );
  }

  async toggleForCompany({
    featureId,
    companyId,
    enable,
  }: {
    featureId: string;
    companyId: string;
    enable: boolean;
  }) {
    let { graphql } = this;

    let variables = {
      featureId,
      companyId,
      enable,
    };

    await graphql.mutate({
      mutation: toggleFeatureForCompanyMutation,
      variables,
      namespace: 'internal_toggleFeature',
    });
  }

  async create(options: { name: string; label?: string }): Promise<Feature> {
    let { graphql } = this;

    let variables = {
      name: options.name,
      label: options.label,
    };

    let response = await graphql.mutate({
      mutation: createFeatureMutation,
      variables,
      namespace: 'internal_createFeature',
    });

    return new Feature(response);
  }

  async update(id: string, options: { label?: string }): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
      label: options.label,
    };

    await graphql.mutate({ mutation: updateFeatureMutation, variables });
  }

  async delete(id: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate({ mutation: deleteFeatureMutation, variables });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/feature': StoreFeatureService;
  }
}
