import Component from '@glimmer/component';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';

interface Args {
  alarmLevels: PlantAssetAlertLevel;
  alarmCount: number;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class PageAlarmReportLevelReportOverallAlarmLevelsItem extends Component<Args> {
  get isAlarmSelected() {
    const { alarmLevels } = this.args;

    return undefined || !alarmLevels?.length || alarmLevels?.includes('ALARM');
  }

  get isWarningSelected() {
    const { alarmLevels } = this.args;

    return (
      undefined || !alarmLevels?.length || alarmLevels?.includes('WARNING')
    );
  }

  get isInfoSelected() {
    const { alarmLevels } = this.args;

    return undefined || !alarmLevels?.length || alarmLevels?.includes('INFO');
  }
}
