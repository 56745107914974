import { EnumsBase } from './-base';
import { ContinuousDataType } from './plant-asset-monitoring';

export class PlantAssetSensorCategoriesEnums extends EnumsBase<ContinuousDataType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in ContinuousDataType]: string } = {
      GAS_CONSUMPTION_MAINBURNER: l10n.t('Gas consumption mainburner'),
      GAS_CONSUMPTION_AFTERBURNER: l10n.t('Gas consumption afterburner'),
      GAS_CONSUMPTION: l10n.t('Gas consumption'),
      POWER_CONSUMPTION: l10n.t('Power consumption'),
      WATER_CONSUMPTION: l10n.t('Water consumption'),
      AIR_CONSUMPTION: l10n.t('Air consumption'),
      BATCH_COUNTER: l10n.t('Batch counter'),
      BATCH_COUNTER_SINCE_MAINTENANCE: l10n.t(
        'Batch counter since maintenance'
      ),
      OPERATING_HOURS: l10n.t('Operating hours'),
      OPERATING_HOURS_SINCE_MAINTENENANCE: l10n.t(
        'Operating hours since maintenance'
      ),
      MOTOR_STARTS: l10n.t('Motor starts'),
      MOTOR_RUNTIME_SINCE_LAST_START: l10n.t('Motor runtime since last start'),
      MOTOR_TOTAL_RUNTIME: l10n.t('Motor total runtime'),
      MOTOR_MAX_CONTINUOUS_RUNTIME: l10n.t('Motor max continuous runtime'),
      PROCESS_VALUE: l10n.t('Process value'),
      SETPOINT_VALUE: l10n.t('Setpoint value'),
      PRODUCT_STATUS: l10n.t('Product status'),
      STATE: l10n.t('State'),
      MOTOR_CURRENT: l10n.t('Motor current'),
      MOTOR_POWER: l10n.t('Motor power'),
      MOTOR_TEMPERATURE: l10n.t('Motor temperature'),
    };

    this.labels = labels;
  }
}
