import { EnumsBase } from './-base';

export const PlantAssetAlertLevels = ['INFO', 'WARNING', 'ALARM'] as const;

export type PlantAssetAlertLevel = typeof PlantAssetAlertLevels[number];

export class PlantAssetAlertLevelsEnums extends EnumsBase<PlantAssetAlertLevel> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in PlantAssetAlertLevel]: string } = {
      INFO: l10n.t('Info'),
      WARNING: l10n.t('Warning'),
      ALARM: l10n.t('Alarm'),
    };

    this.labels = labels;
  }
}
