import { EnumsBase } from './-base';

export const RecipeIdentificationStrategies = [
  'PLANTASSET_NUMBER',
  'PLANTASSET_NUMBER_NAME',
  'PLANTASSET_NAME',
  'EXTERNAL_ID',
] as const;

export type RecipeIdentificationStrategy =
  typeof RecipeIdentificationStrategies[number];

export class RecipeIdentificationStrategiesEnums extends EnumsBase<RecipeIdentificationStrategy> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in RecipeIdentificationStrategy]: string } = {
      PLANTASSET_NUMBER: l10n.t('Plant asset & number'),
      PLANTASSET_NUMBER_NAME: l10n.t('Plant asset, number & name'),
      PLANTASSET_NAME: l10n.t('Plant asset & name'),
      EXTERNAL_ID: l10n.t('External ID'),
    };

    this.labels = labels;
  }
}
