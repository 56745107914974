import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { TimeRangeOptionalEnd } from './time-range';
import { ContinuousDataType } from './enums/plant-asset-monitoring';
import { PlantAssetAlertLevel } from './enums/plant-asset-alert-level';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

export interface KpiDataFilterSettingsInput {
  relativeTimeframe?: RelativeTimeframe;
  absoluteTimeframe?: DateRangeOptional;
  timeRange?: TimeRangeOptionalEnd;
  plantAssetIds?: string[];
  recipeIds?: string[];
  kpiTypes?: KpiType[];
  sensorNames?: string[];
  sensorCategories?: ContinuousDataType[];
  alarmLevels?: PlantAssetAlertLevel[];
  alarmType?: PlantAssetAlarmType;
  alarmExternalIds?: string[];
}

export class KpiDataFilterSettings {
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked absoluteTimeframe?: DateRangeOptional;
  @tracked timeRange?: TimeRangeOptionalEnd;
  @tracked plantAssetIds: string[];
  @tracked recipeIds: string[];
  @tracked kpiTypes: KpiType[];
  @tracked sensorNames: string[];
  @tracked sensorCategories: ContinuousDataType[];
  @tracked alarmLevels: PlantAssetAlertLevel[];
  @tracked alarmType?: PlantAssetAlarmType;
  @tracked alarmExternalIds: string[];

  constructor(options: KpiDataFilterSettingsInput) {
    this.relativeTimeframe = options.relativeTimeframe;
    this.absoluteTimeframe = options.absoluteTimeframe;
    this.timeRange = options.timeRange;
    this.plantAssetIds = options.plantAssetIds || [];
    this.recipeIds = options.recipeIds || [];
    this.kpiTypes = options.kpiTypes || [];
    this.sensorNames = options.sensorNames || [];
    this.sensorCategories = options.sensorCategories || [];
    this.alarmLevels = options.alarmLevels || [];
    this.alarmType = options.alarmType;
    this.alarmExternalIds = options.alarmExternalIds || [];

    assert(
      'You have to assign either a relativeTimeframe or an absoluteTimeframe',
      (!this.relativeTimeframe && this.absoluteTimeframe) ||
        (this.relativeTimeframe && !this.absoluteTimeframe)
    );
  }

  get dateRange() {
    let { absoluteTimeframe, relativeTimeframe, timeRange } = this;

    if (absoluteTimeframe) {
      return new DateRangeOptional(absoluteTimeframe);
    }

    return relativeTimeframeToDateRange(relativeTimeframe!, timeRange);
  }

  clone() {
    return new KpiDataFilterSettings({
      relativeTimeframe: this.relativeTimeframe,
      absoluteTimeframe:
        this.absoluteTimeframe && new DateRangeOptional(this.absoluteTimeframe),

      timeRange: this.timeRange ? Object.assign({}, this.timeRange) : undefined,
      plantAssetIds: this.plantAssetIds.slice(),
      recipeIds: this.recipeIds.slice(),
      kpiTypes: this.kpiTypes.slice(),
    });
  }
}
