import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

export default function datePickerIsSelected(
  date: DateTime,
  dateOrRange: DateTime | undefined | DateRangeOptional
) {
  if (!dateOrRange) {
    return false;
  }

  if (dateOrRange instanceof DateTime) {
    return date.hasSame(dateOrRange, 'day');
  }

  let { start, end } = dateOrRange;

  return Boolean(start?.hasSame(date, 'day') || end?.hasSame(date, 'day'));
}
