import { assert } from '@ember/debug';

export function serializeArray(arr: any[]) {
  return JSON.stringify(arr);
}

export function deserializeArray(str: string) {
  let arr = JSON.parse(str);
  assert(
    `deserializeArray should only be called for arrays, but was called for "${str}"`,
    Array.isArray(arr)
  );
  return arr;
}
