import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';

interface Args {
  placeholder?: string;
  value?: string;
  onChange: (value?: string) => void;
  close: () => void;
}

export default class UiColorInputDropdown extends Component<Args> {
  @service l10n: L10nService;

  @tracked _colorText?: string;
  @tracked error?: string;

  get colorText() {
    return typeof this._colorText === 'undefined'
      ? this.selectedColor
      : this._colorText;
  }

  get selectedColor() {
    return this.args.value ? this.args.value.toUpperCase() : undefined;
  }

  get availableColors(): readonly string[] {
    return chartColors;
  }

  @action
  updateColorText(colorText?: string) {
    this._colorText = colorText;
  }

  @action
  setColorText() {
    let { colorText, l10n } = this;

    let value = colorText ? parseColorText(colorText) : undefined;

    if (value && !validateColor(value)) {
      this.error = l10n.t(
        'The color has to be a valid Hex-Color, like #FF0000.'
      );
      return;
    }

    this.args.onChange(value);
    this._colorText = undefined;
    this.error = undefined;
    this.args.close();
  }

  @action
  selectColor(color: string) {
    this.args.onChange(color);
    this.args.close();
  }
}

function parseColorText(color: string) {
  let value = color.trim().toUpperCase();

  if (!value.startsWith('#')) {
    return `#${value}`;
  }

  return value;
}

function validateColor(color: string) {
  return !!color.match(/^#([0-9a-fA-F]{6})$/);
}
