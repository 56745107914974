import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  RoastBatchSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRangeOptional } from 'fabscale-app/models/time-range';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import { RoastBatchesOverviewFilters } from 'fabscale-app/components/page/roast-batches/overview';
import { RoastBatchGoalResultFilter } from 'fabscale-app/services/store/roast-batch';

export default class RoastBatchesIndexController extends Controller {
  queryParams = [
    'dateRange',
    'timeRange',
    'plantAssets',
    'recipes',
    'page',
    'sortBy',
    'sortDirection',
    'pageSize',
    'goalResults',
  ];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssets?: string;
  @tracked recipes?: string;
  @tracked sortBy?: RoastBatchSortOption;
  @tracked sortDirection?: SortDirection;
  @tracked goalResults?: RoastBatchGoalResultFilter;

  // These are deserialized from the URL as strings, so we need to ensure they are converted to numbers
  @tracked page?: number | string;
  @tracked pageSize?: number | string;

  get pageNumber() {
    return typeof this.page === 'string' ? parseInt(this.page) : this.page;
  }

  get pageSizeNumber() {
    return typeof this.pageSize === 'string'
      ? parseInt(this.pageSize)
      : this.pageSize;
  }

  get parsedDateRange(): DateRangeOptional | undefined {
    let { dateRange } = this;

    return dateRange ? deserializeDateRange(dateRange) : undefined;
  }

  get parsedTimeRange(): TimeRangeOptional | undefined {
    let { timeRange } = this;

    return timeRange ? JSON.parse(timeRange) : undefined;
  }

  get plantAssetIds(): string[] | undefined {
    let { plantAssets } = this;

    return plantAssets ? deserializeArray(plantAssets) : undefined;
  }

  get recipeIds(): string[] | undefined {
    let { recipes } = this;

    return recipes ? deserializeArray(recipes) : undefined;
  }

  get filters(): RoastBatchesOverviewFilters {
    let {
      parsedDateRange: dateRange,
      parsedTimeRange: timeRange,
      plantAssetIds,
      recipeIds,
      goalResults,
    } = this;

    return {
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
      goalResults,
    };
  }

  @action
  updateFilters(filters: RoastBatchesOverviewFilters) {
    let { dateRange, timeRange, plantAssetIds, recipeIds, goalResults } =
      filters;

    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssets =
      plantAssetIds && plantAssetIds.length > 0
        ? serializeArray(plantAssetIds)
        : undefined;
    this.recipes =
      recipeIds && recipeIds.length > 0 ? serializeArray(recipeIds) : undefined;

    this.page = undefined;
    this.goalResults = goalResults;
  }

  @action
  updatePage(page?: number) {
    if (page !== this.page) {
      this.page = page;
    }
  }

  @action
  updatePageSize(pageSize?: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.page = undefined;
    }
  }

  @action
  updateSortBy(sortBy?: RoastBatchSortOption) {
    if (sortBy !== this.sortBy) {
      this.sortBy = sortBy;
      this.page = undefined;
    }
  }

  @action
  updateSortDirection(sortDirection?: SortDirection) {
    if (sortDirection !== this.sortDirection) {
      this.sortDirection = sortDirection;
      this.page = undefined;
    }
  }
}
