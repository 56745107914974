import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { KpiDataFilterTemplateConfig } from 'fabscale-app/models/kpi-data-filter-template-config';
import { User, UserFrontendSettingsInput } from 'fabscale-app/models/user';
import StoreUserService from 'fabscale-app/services/store/user';
import {
  deserializeDashboardConfig,
  serializeDashboardConfig,
} from 'fabscale-app/utilities/utils/serialize-dashboard-config';
import {
  deserializeDefaultNavigationConfiguration,
  serializeDefaultNavigationConfiguration,
} from 'fabscale-app/utilities/utils/serialize-default-navigation-configuration';
import {
  deserializeKpiDataFilterTemplateConfig,
  serializeKpiDataFilterTemplateConfig,
} from 'fabscale-app/utilities/utils/serialize-kpi-data-filter-template-config';
import { DashboardConfig } from './dashboard-config';
import {
  defaultNavigationConfiguration,
  DefaultNavigationConfiguration,
  RootNavigations,
} from './default-navigation-configuration';
import TableConfig from './table-config';

export class UserFrontendSettings extends Service {
  @tracked dashboardConfig?: DashboardConfig;
  @tracked kpiDataFilterTemplateConfig?: KpiDataFilterTemplateConfig;
  @tracked tableConfig: TableConfig;
  @tracked navigationConfig: DefaultNavigationConfiguration;

  get kpiDataFilterTemplates() {
    return this.kpiDataFilterTemplateConfig?.templates || [];
  }

  set kpiDataFilterTemplates(kpiDataFilterTemplates: KpiDataFilterTemplate[]) {
    let kpiDataFilterTemplateConfig = new KpiDataFilterTemplateConfig({
      version: 1,
      templates: [],
    });
    kpiDataFilterTemplateConfig.templates = kpiDataFilterTemplates;

    this.kpiDataFilterTemplateConfig = kpiDataFilterTemplateConfig;
  }

  _user: User;
  _store: StoreUserService;

  constructor({ user, store }: { user: User; store: StoreUserService }) {
    super();

    this._user = user;
    this._store = store;

    this.fromUserFrontendSettings(user.frontendSettings || {});
  }

  setDefaultNavigation(
    route: RootNavigations,
    defaultNavigation: string | null
  ) {
    const currentNavigation =
      this.navigationConfig[route] === defaultNavigation
        ? null
        : defaultNavigation;

    this.navigationConfig = {
      ...this.navigationConfig,
      [route]: currentNavigation,
    };
  }

  fromUserFrontendSettings(settings: UserFrontendSettingsInput) {
    this.kpiDataFilterTemplateConfig = settings.kpiDataFilterTemplates
      ? deserializeKpiDataFilterTemplateConfig(settings.kpiDataFilterTemplates)
      : undefined;

    this.dashboardConfig = settings.dashboardConfig
      ? deserializeDashboardConfig(settings.dashboardConfig)
      : undefined;

    this.navigationConfig = settings.navigationConfig
      ? deserializeDefaultNavigationConfiguration(settings.navigationConfig)
      : defaultNavigationConfiguration();

    this.tableConfig = settings.tableConfig
      ? new TableConfig(settings.tableConfig)
      : new TableConfig();
  }

  toUserFrontendSettings(): UserFrontendSettingsInput {
    let {
      dashboardConfig,
      kpiDataFilterTemplateConfig,
      navigationConfig,
      tableConfig,
    } = this;

    return {
      dashboardConfig: dashboardConfig
        ? serializeDashboardConfig(dashboardConfig)
        : undefined,

      kpiDataFilterTemplates: kpiDataFilterTemplateConfig
        ? serializeKpiDataFilterTemplateConfig(kpiDataFilterTemplateConfig)
        : undefined,

      navigationConfig: navigationConfig
        ? serializeDefaultNavigationConfiguration(navigationConfig)
        : undefined,

      tableConfig: tableConfig.toJSON(),
    };
  }

  async save(): Promise<void> {
    let { _store: store, _user: user } = this;
    let input = this.toUserFrontendSettings();

    await store.updateFrontendSettings(user.id, input);
  }
}
