import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { DateTime } from 'luxon';

export interface KpiDataFilterTemplateInput {
  name: string;
  filterSettings: KpiDataFilterSettings;
  creationDate?: DateTime;
}

export class KpiDataFilterTemplate {
  name: string;
  filterSettings: KpiDataFilterSettings;
  creationDate: DateTime;

  constructor(options: KpiDataFilterTemplateInput) {
    this.name = options.name;
    this.filterSettings = options.filterSettings;
    this.creationDate = options.creationDate || DateTime.local();
  }
}
