import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { CssConfig, ICssConfig } from 'fabscale-app/models/css-config';
import {
  POPOVER_ARROW_DEFAULT_CSS_CONFIG,
  POPOVER_DEFAULT_CSS_CONFIG,
  PopoverArrowTypes,
} from 'fabscale-app/models/popover-config';

interface Args {
  cssConfig: ICssConfig;
  hasArrow: boolean;
  arrowType: number;
  customArrowType?: ICssConfig;
}

export default class UiPopover extends Component<Args> {
  get style() {
    const cssConfig = Object.assign(
      {},
      this.args.cssConfig,
      POPOVER_DEFAULT_CSS_CONFIG
    );

    return htmlSafe(CssConfig.toString(cssConfig));
  }

  get arrowStyle() {
    switch (this.args.arrowType) {
      case PopoverArrowTypes.TopLeft:
        return htmlSafe(
          CssConfig.toString(POPOVER_ARROW_DEFAULT_CSS_CONFIG.topLeft)
        );
      case PopoverArrowTypes.TopCenter:
        return htmlSafe(
          CssConfig.toString(POPOVER_ARROW_DEFAULT_CSS_CONFIG.topCenter)
        );
      case PopoverArrowTypes.TopRight:
        return htmlSafe(
          CssConfig.toString(POPOVER_ARROW_DEFAULT_CSS_CONFIG.topRight)
        );
      case PopoverArrowTypes.Custom:
        return this.args.customArrowType
          ? htmlSafe(CssConfig.toString(this.args.customArrowType))
          : htmlSafe(
              CssConfig.toString(POPOVER_ARROW_DEFAULT_CSS_CONFIG.topCenter)
            );
      default:
        return CssConfig.toString(POPOVER_ARROW_DEFAULT_CSS_CONFIG.topCenter);
    }
  }
}
