import { isTesting, macroCondition } from '@embroider/macros';

interface Timeouts {
  chartResizeDebounce: number;
  tooltipShow: number;
  tooltipHide: number;
  mobileTouchClickDebounce: number;
  dashboardConfigSaveDebounce: number;
  waitForRender: number;
  confirmButtonReset: number;
  validationDebounce: number;
  printResize: number;
  searchTypeDebounce: number;
}

let TIMEOUTS_PROD: Timeouts = {
  chartResizeDebounce: 200,
  tooltipShow: 25,
  tooltipHide: 250,
  mobileTouchClickDebounce: 250,
  dashboardConfigSaveDebounce: 100,
  waitForRender: 250,
  confirmButtonReset: 5000,
  validationDebounce: 300,
  printResize: 1000,
  searchTypeDebounce: 200,
};

// @ts-ignore
export const TIMEOUTS: Timeouts = macroCondition(isTesting())
  ? Object.keys(TIMEOUTS_PROD).reduce((timeouts, key) => {
      // @ts-ignore
      timeouts[key] = 1;
      return timeouts;
    }, {})
  : TIMEOUTS_PROD;
