import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import reduceStringLengthHelper from 'fabscale-app/helpers/reduce-string-length';

interface Args {
  extra: any;
  onCreate: (searchTerm: string) => void;
}

export default class UiPowerSelectCreateOption extends Component<Args> {
  @tracked
  _searchTerm = '';

  get noResultsFound() {
    const { noResultsFound } = this.args.extra;

    return noResultsFound;
  }

  get searchTerm() {
    return this._searchTerm;
  }

  get btnText() {
    return `Create "${reduceStringLengthHelper(this._searchTerm, 15)}"`;
  }

  @action
  handleCreate(): void {
    this.args.onCreate(this.searchTerm);
  }

  @action
  updateSearchTerm() {
    const { searchTerm } = this.args.extra;
    this._searchTerm = searchTerm;
  }
}
