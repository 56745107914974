import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import { focusElement } from 'fabscale-app/utilities/utils/dom/focus-element';
import { service } from '@ember/service';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';

interface Signature {
  Args: {
    value?: string;
    message?: string;
  };
}

export default class FormPasswordInput extends Component<Signature> {
  @service declare l10n: L10nService;

  @tracked inputType = 'password';

  wrapperId: string;

  get startsWithSpace() {
    return this.args.value && this.args.value.match(/^\s/);
  }

  get endsWithSpace() {
    return this.args.value && this.args.value.match(/\s$/);
  }

  get message() {
    if (this.startsWithSpace) {
      return this.l10n.t('Is the leading space correct?');
    }

    if (this.endsWithSpace) {
      return this.l10n.t('Is the trailing space correct?');
    }

    return this.args.message;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.wrapperId = guidFor(this);
  }

  @action
  toggleInputType() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';

    let fieldSelector = `[data-form-password="${this.wrapperId}"] input`;

    schedule('afterRender', () => {
      let input = document.querySelector(fieldSelector) as
        | HTMLInputElement
        | undefined;

      if (input) {
        focusElement(input);
      }
    });
  }
}
