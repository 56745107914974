import Component from '@glimmer/component';
import { KpiCompareReportTableType } from 'fabscale-app/routes/reports/kpi-compare/index/controller';

interface Args {
  tableId: KpiCompareReportTableType;
}

export default class KpiCompareReportTableTabBar extends Component<Args> {
  queryParamPerHour = { tableId: KpiCompareReportTableType.PerHour };
  queryParamPerDay = { tableId: KpiCompareReportTableType.PerDay };
  queryParamPerWeek = { tableId: KpiCompareReportTableType.PerWeek };
  queryParamPerMonth = { tableId: KpiCompareReportTableType.PerMonth };
  queryParamPerMachine = { tableId: KpiCompareReportTableType.PerPlantAsset };
  queryParamPerRecipe = { tableId: KpiCompareReportTableType.PerRecipe };

  get isActive() {
    let { tableId } = this.args;

    return {
      perHour: tableId === KpiCompareReportTableType.PerHour,
      perDay: tableId === KpiCompareReportTableType.PerDay,
      perWeek: tableId === KpiCompareReportTableType.PerWeek,
      perMonth: tableId === KpiCompareReportTableType.PerMonth,
      perMachine: tableId === KpiCompareReportTableType.PerPlantAsset,
      perRecipe: tableId === KpiCompareReportTableType.PerRecipe,
    };
  }
}
