import Component from '@glimmer/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { LineChartData } from 'fabscale-app/components/chart';

interface Args {
  x: number;
  y: number;
  item: LineChartData;
  color: string;
  width: number;
  nextY?: number;
  previousY?: number;
}

export default class LineChartCircleValue extends Component<Args> {
  @tracked valueXOffset = 0;
  @tracked valueYOffset = 0;
  @tracked valueWidth = 0;
  @tracked valueHeight = 0;

  textPadding = 6;

  get valueRectPos() {
    let { x, y } = this.args;
    let { valueHeight, valueWidth, textPadding } = this;

    let halfTextPadding = textPadding / 2;

    return {
      x: x - halfTextPadding,
      y: y - valueHeight - halfTextPadding + 1,
      width: valueWidth + textPadding,
      height: valueHeight + textPadding - 2,
    };
  }

  get valueStyle() {
    let { valueXOffset, valueYOffset } = this;
    return htmlSafe(
      `transform: translate(${valueXOffset}px, ${valueYOffset}px);`
    );
  }

  @action
  positionValue(textElement: HTMLElement) {
    let { textPadding } = this;
    let { width: chartWidth, x, y, nextY, previousY } = this.args;

    let bbox = textElement.getBoundingClientRect();
    let { width, height } = bbox;

    // Ascending, position below
    // Descending, position above
    let positionBelow = true;

    if (isNumber(nextY)) {
      positionBelow = nextY < y;
    } else if (isNumber(previousY)) {
      // If there is no next element, it means it is the last point, and there we want to position it inverted
      positionBelow = previousY < y;
    }

    let valueYOffset = textPadding * -1;

    if (positionBelow) {
      valueYOffset = height + textPadding;
    }

    // If item would exceed on the top, adjust it to below
    if (y + valueYOffset - height < 0) {
      valueYOffset = height + textPadding; // (y + valueYOffset - height) * -1;
    }

    // By default center all items
    let valueXOffset = width / -2;

    // For items that would exceed out of the right side, just align them at the right
    if (x + width + valueXOffset > chartWidth) {
      valueXOffset = width * -1;
    }

    // For items that would exceed out of the left side, just align them at the left
    if (x + valueXOffset < 0) {
      valueXOffset = 0;
    }

    this.valueXOffset = valueXOffset;
    this.valueYOffset = valueYOffset;
    this.valueWidth = width;
    this.valueHeight = height;
  }
}

function isNumber(value?: number): value is number {
  return typeof value === 'number';
}
