import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';

export default function percentageBarStyle(
  percentage: number,
  color?: string
): SafeString {
  let styles = [`--width: ${percentage}%`];

  if (color) {
    styles.push(`--color: ${color}`);
  }

  return htmlSafe(styles.join('; '));
}
