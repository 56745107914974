import { assert } from '@ember/debug';

export default function mathPlus(a: number, b: number) {
  assert(
    `{{math-plus}} expects to numbers as arguments, but got ${a} & ${b}`,
    typeof a === 'number' && typeof b === 'number'
  );

  return a + b;
}
