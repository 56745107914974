import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { TaskCategory } from 'fabscale-app/models/enums/task-categories';
import { TaskRequirement } from 'fabscale-app/models/enums/task-requirements';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import { RepeatConfigInput } from 'fabscale-app/models/repeat-config';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import ErrorParserService from 'fabscale-app/services/error-parser';
import MaintenanceTaskManagerService from 'fabscale-app/services/maintenance-task-manager';
import { shiftDateByInterval } from 'fabscale-app/utilities/utils/date-interval';
import { logError } from 'fabscale-app/utilities/utils/log-error';
import { convertTimeAmount } from 'fabscale-app/utilities/utils/unit-converter';
import { DateTime } from 'luxon';
import Transition from '@ember/routing/-private/transition';

export interface TaskFormData {
  title: string;
  dueDate: DateTime;
  assignedUserId?: string;
  plantAssetId?: string;
  plantAssetAreaId?: string;
  category: TaskCategory;
  estimatedDurationSeconds?: number;
  requirements: TaskRequirement[];
  description?: string;
  repeatConfig?: RepeatConfigInput;
}

interface Args {
  task: MaintenanceTask;
  onSubmit: (taskFormData: TaskFormData) => Promise<void>;
}

type TaskMode = 'ONE_TIME' | 'REPEATING';

export default class PageMaintenanceTasksTaskForm extends Component<Args> {
  @service l10n: L10nService;
  @service router: RouterService;
  @service maintenanceTaskManager: MaintenanceTaskManagerService;
  @service('error-parser') errorParser: ErrorParserService;
  @service enumLabels: EnumLabelsService;

  @tracked error?: string;
  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked repeatConfigFormData: RepeatConfigFormData;
  @tracked repeatConfigFormModel: FormDataModel<RepeatConfigFormData>;
  @tracked taskMode: TaskMode = 'REPEATING';
  @tracked showDialog = false;

  private transition?: Transition;
  private shouldAbort = true;

  _dueDateWasUpdated = false;
  hasSubmitted = false;

  get repeatConfigTimeProjection() {
    let { dueDate } = this.formData;

    let now = DateTime.local().startOf('day');
    let startDate = this.args.task?.creationDate || now;

    let percentage = 0;

    if (dueDate) {
      let totalDays = dueDate.diff(startDate, 'days').days;
      let pastDays = now.diff(startDate, 'days').days;

      percentage = Math.min((pastDays * 100) / totalDays, 100);
    }

    return {
      startDate,
      dueDate,
      percentage,
    };
  }

  get isNew(): boolean {
    return !this.args.task;
  }

  get hasChanges(): boolean {
    return this.formModel.hasChanges || this.repeatConfigFormModel.hasChanges;
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    this._initialiseFormData();
  }

  willDestroy(): void {
    this.resetRouteHandler();

    super.willDestroy();
  }

  @action
  updateTaskMode(taskMode: TaskMode) {
    this.taskMode = taskMode;
    this.formModel.hasChanges = true;

    if (taskMode === 'REPEATING') {
      this._calculateDueDate();
    }
    this.addRouteHandler();
  }

  @action
  updateTitle(title: string) {
    this.formModel.updateProperty('title', title);
    this.addRouteHandler();
  }

  @action
  updateDueDate(dueDate: DateTime) {
    this.formModel.updateProperty('dueDate', dueDate);
    this._dueDateWasUpdated = true;
    this.addRouteHandler();
  }

  @action
  updateAssignedUserId(assignedUserId?: string) {
    this.formModel.updateProperty('assignedUserId', assignedUserId);
    this.addRouteHandler();
  }

  @action
  updatePlantAssetId(plantAssetId?: string) {
    this.formModel.updateProperty('plantAssetId', plantAssetId);
    this.addRouteHandler();
  }

  @action
  updatePlantAssetAreaId(plantAssetAreaId?: string) {
    this.formModel.updateProperty('plantAssetAreaId', plantAssetAreaId);
    this.addRouteHandler();
  }

  @action
  updateCategory(category: TaskCategory) {
    this.formModel.updateProperty('category', category);
    this.addRouteHandler();
  }

  @action
  updateEstimatedDurationSeconds(estimatedDurationSeconds?: number) {
    this.formModel.updateProperty(
      'estimatedDurationSeconds',
      estimatedDurationSeconds
    );
    this.addRouteHandler();
  }

  @action
  updateRequirements(requirements: TaskRequirement[]) {
    this.formModel.updateProperty('requirements', requirements);
    this.addRouteHandler();
  }

  @action
  updateDescription(description?: string) {
    this.formModel.updateProperty('description', description);
    this.addRouteHandler();
  }

  @action
  updateRepeatConfigTimeAmount(amount?: number) {
    this.repeatConfigFormModel.updateProperty('timeAmount', amount);

    this._calculateDueDate();
    this.addRouteHandler();
  }

  @action
  updateRepeatConfigTimeInterval(interval: Interval) {
    this.repeatConfigFormModel.updateProperty('timeInterval', interval);

    this._calculateDueDate();
    this.addRouteHandler();
  }

  @action
  updateRepeatConfigStartDate(startDate: DateTime) {
    this.repeatConfigFormModel.updateProperty('startDate', startDate);

    this._calculateDueDate();
    this.addRouteHandler();
  }

  @action
  updateRepeatConfigUptimeHours(uptimeHours?: number) {
    this.repeatConfigFormModel.updateProperty('uptimeAmountHours', uptimeHours);
    this.addRouteHandler();
  }

  @action
  cancel(): void {
    if (this.isNew) {
      this.maintenanceTaskManager.goToPreviousPage();
    } else {
      this.router.transitionTo(
        'routes/maintenance.tasks.show.index',
        this.args.task.id
      );
    }
  }

  @action
  handleOnCancel() {
    this.shouldAbort = true;
    this.showDialog = false;
  }

  @action
  handleOnYes() {
    this.showDialog = false;
    this.shouldAbort = false;
    if (this.transition) {
      this.router.transitionTo(this.transition.to.name);
    }
  }

  submitFormTask = dropTask(async () => {
    let { formData } = this;

    this.hasSubmitted = true;
    await this.formModel.validate();

    if (this.taskMode === 'REPEATING') {
      await this.repeatConfigFormModel.validate();
    }

    if (this.formModel.isInvalid) {
      return;
    }

    if (this.taskMode === 'REPEATING' && this.repeatConfigFormModel.isInvalid) {
      return;
    }

    let {
      title,
      dueDate,
      assignedUserId,
      plantAssetId,
      plantAssetAreaId,
      description,
      category,
      requirements,
      estimatedDurationSeconds,
    } = formData;

    let repeatConfig =
      this.taskMode === 'REPEATING'
        ? this.repeatConfigFormData.getRepeatConfig()
        : undefined;

    let taskData: TaskFormData = {
      title: title!,
      dueDate: dueDate!,
      assignedUserId,
      plantAssetId,
      plantAssetAreaId,
      category: category!,
      requirements,
      estimatedDurationSeconds,
      description,
      repeatConfig,
    };

    try {
      this.resetRouteHandler();
      await this.args.onSubmit(taskData);
    } catch (error) {
      this._handleError(error);
      return;
    }

    this._initialiseFormData();
  });

  _handleError(error: unknown) {
    logError(error);

    this.formModel.addError(this.errorParser.getErrorMessage(error));
  }

  _initialiseFormData() {
    let { l10n } = this;

    let formData = new FormData();

    this._dueDateWasUpdated = false;
    this.hasSubmitted = false;

    let repeatConfigFormData = new RepeatConfigFormData();

    let { task } = this.args;
    if (task) {
      formData.id = task.id;
      formData.title = task.title;
      formData.dueDate = task.dueDate;
      formData.description = task.description;
      formData.assignedUserId = task.assignedUser?.id;
      formData.plantAssetId = task.plantAsset?.id;
      formData.plantAssetAreaId = task.plantAssetArea?.id;
      formData.category = task.category;
      formData.requirements = task.requirements;
      formData.estimatedDurationSeconds = task.estimatedDurationSeconds;

      repeatConfigFormData.startDate =
        task.repeatConfig?.startDate || DateTime.local().startOf('day');
      repeatConfigFormData.timeAmount = task.repeatConfig?.timeInterval.amount;
      repeatConfigFormData.timeInterval =
        task.repeatConfig?.timeInterval.interval;

      // This is always converted to HOUR beforehand
      repeatConfigFormData.uptimeAmountHours =
        task.repeatConfig?.uptimeInterval?.amount;

      this._dueDateWasUpdated = true;
      this.taskMode = task.repeatConfig ? 'REPEATING' : 'ONE_TIME';
    }

    this.formData = formData;
    this.repeatConfigFormData = repeatConfigFormData;

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'title',
          message: l10n.t('You must enter a title.'),
          validate: (value) => value,
        },
        {
          propertyName: 'category',
          message: l10n.t('You must select a category.'),
          validate: (value) => value,
        },
        {
          propertyName: 'dueDate',
          message: l10n.t('You must select a due date.'),
          validate: (value) => value,
        },
        {
          propertyName: 'plantAssetId',
          message: l10n.t(
            'You must select a plant asset when setting an uptime interval.'
          ),
          validate: (value) =>
            !this.repeatConfigFormData.uptimeAmountHours || !!value,
        },
      ],
    });

    this.repeatConfigFormModel = new FormDataModel({
      data: this.repeatConfigFormData,
      validations: [
        {
          propertyName: 'timeAmount',
          message: l10n.t('You must enter an amount.'),
          validate: (value) => {
            return !!value;
          },
        },
        {
          propertyName: 'timeAmount',
          message: l10n.t('The time amount must be larger than 0.'),
          validate: (value) => {
            return typeof value === 'number' && value > 0;
          },
        },
        {
          propertyName: 'timeInterval',
          message: l10n.t('You must select an interval.'),
          validate: (value) => {
            return !!value;
          },
        },
        {
          propertyName: 'uptimeAmountHours',
          message: l10n.t('The uptime amount must be larger than 0.'),
          validate: (value) => {
            return typeof value === 'undefined' || value > 0;
          },
        },
      ],
    });
  }

  _calculateDueDate() {
    let startDate = this.repeatConfigFormData.startDate;
    let timeAmount = this.repeatConfigFormData.timeAmount;
    let timeInterval = this.repeatConfigFormData.timeInterval;

    if (timeAmount && timeInterval) {
      let dueDate = shiftDateByInterval(startDate, timeInterval, timeAmount);

      this.formModel.updateProperty('dueDate', dueDate);
    }
  }

  public beforeRouteChangeHandler?: (transition: Transition) => void;

  private addRouteHandler() {
    if (!this.beforeRouteChangeHandler) {
      this.beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (this.shouldAbort) {
          this.showDialog = true;
          transition.abort();
        }

        this.transition = transition;
        this.shouldAbort = true;
      };

      this.router.on('routeWillChange', this.beforeRouteChangeHandler);
    }
  }

  private resetRouteHandler() {
    if (this.beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this.beforeRouteChangeHandler);
      this.beforeRouteChangeHandler = undefined;
    }
  }
}

export class FormData {
  id: string;
  @tracked title?: string;
  @tracked dueDate?: DateTime;
  @tracked assignedUserId?: string;
  @tracked plantAssetId?: string;
  @tracked plantAssetAreaId?: string;
  @tracked category?: TaskCategory;
  @tracked estimatedDurationSeconds?: number;
  @tracked requirements: TaskRequirement[] = [];
  @tracked description?: string;
}

export class RepeatConfigFormData {
  @tracked startDate: DateTime = DateTime.local().startOf('day');
  @tracked timeAmount?: number;
  @tracked timeInterval?: Interval;
  @tracked uptimeAmountHours?: number;

  constructor(repeatConfig?: RepeatConfigInput) {
    if (repeatConfig) {
      this.startDate = repeatConfig.startDate;
      this.timeAmount = repeatConfig.timeInterval.amount;
      this.timeInterval = repeatConfig.timeInterval.interval;

      if (repeatConfig.uptimeInterval) {
        this.uptimeAmountHours = convertTimeAmount(
          repeatConfig.uptimeInterval.amount,
          repeatConfig.uptimeInterval.unit,
          'HOUR'
        );
      }
    }
  }

  getRepeatConfig(): RepeatConfigInput {
    if (!this.timeAmount || !this.timeInterval) {
      throw new Error('Repeat config is missing required data.');
    }

    return {
      startDate: this.startDate,
      timeInterval: {
        amount: this.timeAmount,
        interval: this.timeInterval,
      },
      uptimeInterval: this.uptimeAmountHours
        ? {
            amount: this.uptimeAmountHours,
            unit: 'HOUR',
          }
        : undefined,
    };
  }
}
