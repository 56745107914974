import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { UserRole } from 'fabscale-app/models/user-role';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreUserRoleService from 'fabscale-app/services/store/user-role';

interface Args {
  role: UserRole;
}

export default class PageSettingsRolesDetails extends Component<Args> {
  @service l10n: L10nService;
  @service('store/user-role') userRoleStore: StoreUserRoleService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  @tracked error?: string;

  // TODO FN: Update this once users have roles
  get isCurrentRole() {
    return false;
  }

  deleteRoleTask = dropTask(async () => {
    let { userRoleStore, errorParser } = this;
    let { role } = this.args;

    this.error = undefined;

    try {
      await userRoleStore.delete(role.id);
    } catch (error) {
      this.error = errorParser.getErrorMessage(error);
      return;
    }

    this.router.transitionTo('routes/company-settings.roles.index');
  });
}
