import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { Permission } from 'fabscale-app/models/enums/permissions';
import StoreUserRoleService from 'fabscale-app/services/store/user-role';

export default class PageSettingsCreateRole extends Component<any> {
  @service l10n: L10nService;
  @service('store/user-role') userRoleStore: StoreUserRoleService;

  @tracked createdUserRole?: { id: string; name: string };

  createUserRoleTask = dropTask(
    async ({
      name,
      permissions,
    }: {
      name: string;
      permissions: Permission[];
    }) => {
      this.createdUserRole = undefined;

      let { id }: { id: string } = await this.userRoleStore.create({
        name,
        permissions,
      });

      this.createdUserRole = { id, name };
    }
  );
}
