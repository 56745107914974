import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  TickableMonitoringSensor,
  MonitoringSensor,
} from 'fabscale-app/models/plant-asset-monitoring';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UiSensorDetailsListItemArgs {
  onSensorTick: (sensor: any) => void;
  onSensorUntick: (sensor: any) => void;
  sensor: TickableMonitoringSensor;
}

export default class UiSensorDetailsListItem extends Component<UiSensorDetailsListItemArgs> {
  @tracked
  isTicked = this.args.sensor.isTicked;

  @action
  onChange(flag: boolean) {
    this.isTicked = flag;

    flag && this.args.onSensorTick(this.args.sensor as MonitoringSensor);
    !flag && this.args.onSensorUntick(this.args.sensor as MonitoringSensor);
  }
}
