import Component from '@glimmer/component';
import { PlantAlarmSummary } from 'fabscale-app/models/alarm-report';

interface Args {
  plantAlarmsSummaryGrouped: PlantAlarmSummary[];
}

export default class PageAlarmReportLevelReportOverallAlarmLevels extends Component<Args> {
  get alarmsCount() {
    const { plantAlarmsSummaryGrouped } = this.args;

    return plantAlarmsSummaryGrouped.find(
      (record) => record.alarmLevel === 'ALARM'
    )?.alarmCount;
  }

  get warningsCount() {
    const { plantAlarmsSummaryGrouped } = this.args;

    return plantAlarmsSummaryGrouped.find(
      (record) => record.alarmLevel === 'WARNING'
    )?.alarmCount;
  }

  get infosCount() {
    const { plantAlarmsSummaryGrouped } = this.args;

    return plantAlarmsSummaryGrouped.find(
      (record) => record.alarmLevel === 'INFO'
    )?.alarmCount;
  }
}
