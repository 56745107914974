import { service } from '@ember/service';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DateRange } from 'fabscale-app/models/date-range';
import { KpiData } from 'fabscale-app/models/kpi-data';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { cutOffDateRange } from 'fabscale-app/utilities/utils/date-range';
import { DateTime } from 'luxon';
import BaseAsyncResource from './base-async-resource';

interface LoadKpiDataOptions {
  kpiType: KpiType;
  dateRange: DateRange;
  plantAssetIds?: string[];
  includeComparativeData?: boolean;
  recipeIds?: string[];
  cutOffDate?: DateTime;
}

export default class LoadKpiDataResource extends BaseAsyncResource<
  { kpiData: KpiData; comparativeData?: KpiData },
  LoadKpiDataOptions
> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData({
    kpiType,
    dateRange,
    plantAssetIds,
    recipeIds,
    includeComparativeData,
    cutOffDate,
  }: LoadKpiDataOptions) {
    let { kpiDataStore } = this;

    let fetchOptions = {
      dateRange,
      plantAssetIds,
      recipeIds,
    };

    if (includeComparativeData) {
      // We cannot use cutOffDate here, as we auto-calculate the previous time period from the dateRange
      return kpiDataStore.findWithComparative(kpiType, fetchOptions);
    }

    fetchOptions.dateRange = cutOffDateRange(dateRange, cutOffDate);

    let kpiData = await kpiDataStore.find(kpiType, fetchOptions);

    return { kpiData, comparativeData: undefined };
  }
}
