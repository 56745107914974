export class Company {
  id: string;
  name: string;
  features: string[];
  locationIds: string[];

  constructor(options: CompanyInput) {
    this.id = options.id;
    this.name = options.name;
    this.features = options.features || [];
    this.locationIds = options.locationIds || [];
  }
}

export interface CompanyInput {
  id: string;
  name: string;
  features?: string[];
  locationIds?: string[];
}
