import { DateTimeString } from 'fabscale-app';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { DateTime } from 'luxon';
import { deserializeDate, serializeDate } from './serialize-date';
import {
  deserializeKpiDataFilterSettings,
  serializeKpiDataFilterSettings,
} from './serialize-kpi-data-filter-settings';

export function serializeKpiDataFilterTemplate(
  machineSettingsTemplate: KpiDataFilterTemplate
): string {
  let obj = {
    name: machineSettingsTemplate.name,
    creationDate: serializeDate(machineSettingsTemplate.creationDate),
    filterSettings: serializeKpiDataFilterSettings(
      machineSettingsTemplate.filterSettings
    ),
  };

  return JSON.stringify(obj);
}

export function deserializeKpiDataFilterTemplate(
  str: string
): KpiDataFilterTemplate {
  let obj: {
    name: string;
    creationDate?: DateTimeString;
    filterSettings: string;
  } = JSON.parse(str);

  let creationDate = DateTime.local();

  if (obj.creationDate) {
    creationDate = deserializeDate(obj.creationDate);
  }

  let options = {
    name: obj.name,
    creationDate,
    filterSettings: deserializeKpiDataFilterSettings(obj.filterSettings),
  };

  return new KpiDataFilterTemplate(options);
}
