import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { TimeInterval } from 'fabscale-app/models/repeat-config';

export default class FormatTimeIntervalHelper extends Helper<{
  PositionalArgs: [timeInterval: TimeInterval];
}> {
  @service l10n: L10nService;

  compute([timeInterval]: [TimeInterval]) {
    let { interval, amount } = timeInterval;
    let { l10n } = this;

    if (amount === 1 && interval === 'HOUR') {
      return l10n.t('hourly');
    }

    if (amount === 1 && interval === 'DAY') {
      return l10n.t('daily');
    }

    if (amount === 1 && interval === 'WEEK') {
      return l10n.t('weekly');
    }

    if (amount === 1 && interval === 'MONTH') {
      return l10n.t('monthly');
    }

    if (amount === 1 && interval === 'YEAR') {
      return l10n.t('yearly');
    }

    let intervalUnitMap: { [key in Interval]: string } = {
      HOUR: l10n.t('hours'),
      DAY: l10n.t('days'),
      WEEK: l10n.t('weeks'),
      MONTH: l10n.t('months'),
      YEAR: l10n.t('years'),
    };

    let intervalUnit: string = intervalUnitMap[interval];

    return l10n.t('every {{amount}} {{intervalUnit}}', {
      amount,
      intervalUnit,
    });
  }
}
