import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import EnumLabelsService from 'fabscale-app/services/enum-labels';

export abstract class BaseEnumHelper<EnumType> extends Helper<{
  PositionalArgs: [enumValue: EnumType];
}> {
  @service enumLabels: EnumLabelsService;

  compute([enumValue]: [EnumType]) {
    return this.getLabel(enumValue);
  }

  abstract getLabel(enumValue: EnumType): string;
}
