import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  InvalidAuthorizationError,
  MfaCodeRequiredError,
} from 'fabscale-app/models/errors/cognito';
import CognitoService from 'fabscale-app/services/cognito';
import UserSessionService from 'fabscale-app/services/user-session';

export default class SettingsSecurityMfaSetupPassword extends Component {
  @service userSession: UserSessionService;
  @service cognito: CognitoService;
  @service l10n: L10nService;
  @service router: RouterService;

  @tracked password?: string;
  @tracked error?: string;

  get userName() {
    return this.userSession.user!.name;
  }

  @action
  updatePassword(password: string) {
    this.password = password;
  }

  verifyPasswordTask = dropTask(async () => {
    let { password, cognito, l10n, router } = this;
    let { email } = this.userSession.user!;

    if (!password) {
      return;
    }

    this.error = undefined;

    try {
      await cognito.authenticateUser(cognito.cognitoData!.cognitoUser, {
        username: email,
        password,
      });
    } catch (error) {
      if (error instanceof InvalidAuthorizationError) {
        this.error = l10n.t('The password you provided is incorrect.');
        return;
      }

      if (!(error instanceof MfaCodeRequiredError)) {
        this.error =
          (error as MfaCodeRequiredError).message ||
          l10n.t('An error occurred!');
        return;
      }
    }

    cognito.hasAuthenticatedForMfaSetup = true;
    router.transitionTo('routes/my-settings.security.mfa-setup');
  });
}
