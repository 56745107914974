import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  close: () => void;
  goPrevious?: () => void;
  goNext?: () => void;
}

export default class Lightbox extends Component<Args> {
  targetElement = document.querySelector('#lightbox-target');

  _keyboardHandler: (event: KeyboardEvent) => void;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._keyboardHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.args.close();
      }

      if (event.key === 'ArrowRight' && this.args.goNext) {
        event.preventDefault();
        this.args.goNext();
      }

      if (event.key === 'ArrowLeft' && this.args.goPrevious) {
        event.preventDefault();
        this.args.goPrevious();
      }
    };

    document.documentElement.addEventListener('keydown', this._keyboardHandler);
  }

  willDestroy() {
    document.documentElement.removeEventListener(
      'keydown',
      this._keyboardHandler
    );
    super.willDestroy();
  }

  @action
  closeOnOutsideClick(event: MouseEvent) {
    let target = event.target as HTMLElement;

    event.stopPropagation();

    // We want to ignore events when clicking a link, button or image
    let selectors = 'button,a,img';
    if (target.matches(selectors) || target.closest(selectors)) {
      return;
    }

    this.args.close();
  }

  @action
  print() {
    let siteWrapper = document.querySelector('.site-wrapper');

    siteWrapper?.classList.add('print-hide');
    window.print();
    siteWrapper?.classList.add('print-hide');
  }
}
