import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { Comment } from 'fabscale-app/models/comment';
import { User } from 'fabscale-app/models/user';
import ErrorParserService from 'fabscale-app/services/error-parser';
import UserSessionService from 'fabscale-app/services/user-session';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';

interface Args {
  comment: Comment;
  updateComment: ({
    message,
    commentId,
  }: {
    message: string;
    commentId: string;
  }) => Promise<Comment>;
  deleteComment: (commentId: string) => Promise<Comment>;
}

export default class ModuleTimelineComment extends Component<Args> {
  @service userSession: UserSessionService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked editMode = false;
  @tracked error?: string;

  get currentUser(): User {
    return this.userSession.user!;
  }

  get isCreatedByCurrentUser() {
    return this.currentUser.id === this.args.comment.createdBy.id;
  }

  @action
  startEditMode() {
    this.editMode = true;
  }

  @action
  closeEditMode() {
    this.editMode = false;
  }

  deleteCommentTask = dropTask(async () => {
    let commentId = this.args.comment.id;

    this.error = undefined;

    try {
      await this.args.deleteComment(commentId);
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      sentryCaptureException(error);
    }
  });

  updateCommentTask = dropTask(async (message: string) => {
    let commentId = this.args.comment.id;

    this.error = undefined;

    try {
      await this.args.updateComment({ message, commentId });
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      sentryCaptureException(error);
    }
  });
}
