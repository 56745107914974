import { Ability } from 'ember-can';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';

export default class RoastPicAbility extends Ability {
  @service userSession: UserSessionService;

  get canView() {
    return Boolean(this.userSession.permissionMap.ROASTPIC_VIEW);
  }
}
