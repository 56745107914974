import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AbilitiesService } from 'ember-can';
import { restartableTask, timeout } from 'ember-concurrency';
import CellActions from 'fabscale-app/components/page/plant/plant-assets/details/area-list/cells/actions';
import CellName from 'fabscale-app/components/page/plant/plant-assets/details/area-list/cells/name';
import CellType from 'fabscale-app/components/page/plant/plant-assets/details/area-list/cells/type';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';

interface Args {
  plantAssetAreas: PlantAssetArea[];
}

export default class PlantPlantAssetDetailsAreaListIndex extends Component<Args> {
  @service l10n: L10nService;
  @service abilities: AbilitiesService;

  @tracked searchTerm?: string;
  columns: TableColumnDefinitionInput[];

  get filteredData() {
    let { searchTerm } = this;
    let { plantAssetAreas } = this.args;

    if (!searchTerm) {
      return plantAssetAreas;
    }

    return filterRecords(plantAssetAreas, searchTerm, {
      propertyName: 'name',
    });
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { l10n } = this;
    let columns = [
      {
        title: l10n.t('Name'),
        propertyName: 'name',
        noCompactTitle: true,
        component: CellName,
      },
      {
        title: l10n.t('Type'),
        propertyName: 'type',
        component: CellType,
      },
    ];

    if (this.abilities.can('edit plant-asset-area')) {
      columns.push({
        title: '',
        propertyName: '',
        component: CellActions,
      });
    }

    this.columns = columns;
  }

  updateSearchTermTask = restartableTask(async (searchTerm: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.searchTerm = searchTerm;
  });
}
