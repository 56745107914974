import { action } from '@ember/object';
import { service } from '@ember/service';
import NavigationController from 'fabscale-app/routes/base/-base-navigation-controller';
import { DefaultNavigationConfiguration } from 'fabscale-app/models/default-navigation-configuration';
import SettingsService from 'fabscale-app/services/settings';

export default class ReportsIndexController extends NavigationController {
  queryParams = ['fromBreadcrumb'];

  @service('settings') settings: SettingsService;

  get navigationConfiguration(): DefaultNavigationConfiguration {
    return this.settings.userFrontendSettings.navigationConfig;
  }

  get defaultNavigation(): string | null {
    return this.navigationConfiguration.reports;
  }

  @action
  async changeDefaultReport(navigation: string) {
    await this.applyDefaultNavigation(navigation ?? null);
  }

  private async applyDefaultNavigation(navigation: string | null) {
    this.settings.userFrontendSettings.setDefaultNavigation(
      'reports',
      navigation
    );
    await this.settings.userFrontendSettings.save();
  }
}
