import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface Args {
  cardType: 'ALARM_LEVEL' | 'ALARM_TYPE';
}

export default class PageAlarmReportOverviewCard extends Component<Args> {
  get icon() {
    const { cardType } = this.args;

    return cardType.toLocaleLowerCase().replace('_', '-');
  }

  get cardDetails() {
    const { cardType } = this.args;

    return cardType === 'ALARM_LEVEL'
      ? [
          'Alarm levels per plant assets',
          'Alarm levels per recipes',
          'Alarm levels for date ranges',
        ]
      : [
          'Alarm type per plant assets',
          'Alarm type per recipes',
          'Alarm external ID per roasters',
        ];
  }

  get marginBottom4() {
    return htmlSafe('margin-bottom: 4px');
  }

  get marginBottom8() {
    return htmlSafe('margin-bottom: 8px');
  }

  get marginBottom14() {
    return htmlSafe('margin-bottom: 14px');
  }

  get marginBottom24() {
    return htmlSafe('margin-bottom: 24px');
  }

  get marginTop40() {
    return htmlSafe('margin-top: 40px');
  }
}
