import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import RouterService from '@ember/routing/router-service';
import { RouteModel } from '@ember/routing/route';
import { getOwner } from '@ember/application';
import ApplicationInstance from '@ember/application/instance';

interface Args {
  route: string;
  model?: RouteModel;
  query?: { [key: string]: any };
}

export default class UiLinkPlain extends Component<Args> {
  @service router: RouterService;

  @tracked isActive = false;

  get routeName() {
    return this.args.route;
  }

  get queryParams() {
    return this.args.query;
  }

  get model() {
    return this.args.model;
  }

  get _routeArgs():
    | [routeName: string, model: RouteModel, options?: { queryParams: any }]
    | [routeName: string, options?: { queryParams: any }] {
    let { routeName, model, queryParams } = this;

    let options = queryParams ? { queryParams: { ...queryParams } } : undefined;

    // @ts-ignore
    return [routeName, model, options].filter(
      (opt) => typeof opt !== 'undefined'
    );
  }

  get isRouterInitialized() {
    // Ideally we would use the public `router` service here, but accessing
    // the `currentURL` on that service automatically starts the routing layer
    // as a side-effect, which is not our intention here. Once or if Ember.js
    // provides a flag on the `router` service to figure out if routing was
    // already initialized we should switch back to the public service instead.
    //
    // Inspiration for this workaround was taken from the `currentURL()` test
    // helper (see https://github.com/emberjs/ember-test-helpers/blob/v2.1.4/addon-test-support/@ember/test-helpers/setup-application-context.ts#L180)

    let app = getOwner(this) as ApplicationInstance;
    // eslint-disable-next-line ember/no-private-routing-service
    let router = app.lookup('router:main') as { currentURL?: string };

    return Boolean(router.currentURL);
  }

  get url() {
    if (!this.isRouterInitialized) {
      return '#';
    }

    // @ts-ignore
    return this.router.urlFor(...this._routeArgs);
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // So we can easily bind & remove it from this.router
    this._checkIsActive = this._checkIsActive.bind(this);

    this._checkIsActive();
    this.router.on('routeDidChange', this._checkIsActive);
  }

  willDestroy() {
    // @ts-ignore - missing type def, see: https://github.com/typed-ember/ember-cli-typescript/issues/1161
    this.router.off('routeDidChange', this._checkIsActive);
    super.willDestroy();
  }

  @action
  onClick(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    event.preventDefault();

    // @ts-ignore
    this.router.transitionTo(...this._routeArgs);
  }

  _checkIsActive() {
    if (!this.isRouterInitialized) {
      return;
    }

    // @ts-ignore
    this.isActive = this.router.isActive(...this._routeArgs);
  }
}
