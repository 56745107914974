import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { PlantCompareReportFilters } from 'fabscale-app/components/page/plant-compare-report';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRangeOptional } from 'fabscale-app/models/time-range';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';

export default class ReportsPlantCompareController extends Controller {
  queryParams = [
    'dateRange',
    'timeRange',
    'kpiTypes',
    'locations',
    'unitSystem',
  ];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked kpiTypes?: string;
  @tracked locations?: string;
  @tracked unitSystem?: UnitSystem;

  get filters(): PlantCompareReportFilters {
    return {
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      kpiTypes: this.parsedKpiTypes,
      locationIds: this.locationIds,
      unitSystem: this.unitSystem,
    };
  }

  get parsedDateRange(): DateRangeOptional | undefined {
    let { dateRange } = this;

    return dateRange ? deserializeDateRange(dateRange) : undefined;
  }

  get parsedTimeRange(): TimeRangeOptional | undefined {
    let { timeRange } = this;

    return timeRange ? JSON.parse(timeRange) : undefined;
  }

  get locationIds() {
    let { locations } = this;

    return locations ? deserializeArray(locations) : [];
  }

  get parsedKpiTypes(): KpiType[] {
    let { kpiTypes } = this;

    return kpiTypes ? deserializeArray(kpiTypes) : [];
  }

  @action
  updateFilters(filters: PlantCompareReportFilters) {
    let { kpiTypes, locationIds, dateRange, timeRange, unitSystem } = filters;

    this.kpiTypes = kpiTypes.length > 0 ? serializeArray(kpiTypes) : undefined;
    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.locations =
      locationIds.length > 0 ? serializeArray(locationIds) : undefined;
    this.unitSystem = unitSystem;
  }

  resetFilters() {
    this.kpiTypes = undefined;
    this.dateRange = undefined;
    this.timeRange = undefined;
    this.locations = undefined;
    this.unitSystem = undefined;
  }
}
