import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DashboardCard } from 'fabscale-app/models/dashboard-config';

export default class PageDashboardCustomizeItem extends Component<{
  Args: {
    item: DashboardCard;
    isCompact: boolean;
    startDrag: () => void;
    endDrag: () => void;
    moveCard: (cardId: number, opt: { x: number; y: number }) => void;
  };
}> {
  @action
  onDragStart(event: DragEvent) {
    // event.dataTransfer can only be null if e.g. manually creating this event, but to be safe...
    // For the compact UI we cannot allow re-ordering, as the grid is reduced to single-col here
    if (!event.dataTransfer || this.args.isCompact) {
      return;
    }

    let { item } = this.args;

    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('id', `${item.id}`);

    let offset = this.getPointerOffset(event);

    // We store the offset of where the drag started
    // As when you e.g. start dragging on the bottom right corner, the actual position
    // needs to be adjusted accordingly when dropping it
    event.dataTransfer.setData('offsetX', `${offset.x}`);
    event.dataTransfer.setData('offsetY', `${offset.y}`);

    this.args.startDrag();
  }

  @action
  onDragEnd() {
    this.args.endDrag();
  }

  @action
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  @action
  onDrop(event: DragEvent) {
    // You can drop something on itself
    // - that _can_ be possible, if you move something to the left, for example.
    // Imagine: | |A|A|B
    // If you grab AA on the right side and move it one col to the left, you drop it on the left side of AA

    if (!event.dataTransfer) {
      return;
    }

    let id = parseInt(event.dataTransfer.getData('id'));

    if (Number.isNaN(id)) {
      return;
    }

    // Dropping on itself?
    if (id === this.args.item.id) {
      this._dropOnItself(event);
      return;
    }

    this._dropOnOtherCard(id, event);
  }

  _dropOnItself(event: DragEvent) {
    let { id, x, y } = this.args.item;

    let offsetStartX = parseInt(event.dataTransfer!.getData('offsetX'));
    let offsetStartY = parseInt(event.dataTransfer!.getData('offsetY'));

    let offset = this.getPointerOffset(event);
    let offsetEndX = offset.x;
    let offsetEndY = offset.y;

    let cardX = x - offsetStartX + offsetEndX;
    let cardY = y - offsetStartY + offsetEndY;

    this.args.moveCard(id, { x: cardX, y: cardY });
  }

  _dropOnOtherCard(id: number, event: DragEvent) {
    let { x, y } = this.args.item;

    let offsetStartX = parseInt(event.dataTransfer!.getData('offsetX'));
    let offsetStartY = parseInt(event.dataTransfer!.getData('offsetY'));

    let offset = this.getPointerOffset(event);
    let offsetEndX = offset.x;
    let offsetEndY = offset.y;

    let cardX = x - offsetStartX + offsetEndX;
    let cardY = y - offsetStartY + offsetEndY;

    this.args.moveCard(id, { x: cardX, y: cardY });
  }

  getPointerOffset(event: DragEvent) {
    let percentage = getPointerPercentagePos(event);
    let { width, height } = this.args.item;

    let x = getClosestPos(percentage.x, width);
    let y = getClosestPos(percentage.y, height);

    return { x, y };
  }
}

function getClosestPos(percentage: number, size: number) {
  let exact = size * percentage;

  return Math.floor(exact);
}

// pos. in percent of the container size
function getPointerPercentagePos(event: DragEvent) {
  let el = event.target;

  if (!el) {
    return { x: 0, y: 0 };
  }

  let dropElement = ((el as HTMLElement).closest('[data-droppable]') ||
    el) as HTMLElement;

  let { width, x, y, height } = dropElement.getBoundingClientRect();
  let { clientX, clientY } = event;

  let xInside = clientX - x;
  let yInside = clientY - y;

  let xPercentage = xInside / width;
  let yPercentage = yInside / height;

  return {
    x: xPercentage,
    y: yPercentage,
  };
}
