import { EnumsBase } from './-base';

export type GoalResultStatus = 'PASSED' | 'FAILED' | 'UNIT_NOT_CONVERTABLE';

export class GoalResultStatusEnums extends EnumsBase<GoalResultStatus> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in GoalResultStatus]: string } = {
      PASSED: l10n.t('Passed'),
      FAILED: l10n.t('Failed'),
      UNIT_NOT_CONVERTABLE: l10n.t('Unit not convertable'),
    };

    this.labels = labels;
  }
}
