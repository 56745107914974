import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'fabscale-app/config/environment';

// To ensure this remains removed when the nav changes
let GLOBAL_CONFIG = {
  environmentName: config.environmentName,
};

export default class SiteWrapperEnvironmentName extends Component {
  @tracked environmentName = GLOBAL_CONFIG.environmentName;

  @action
  hideEnvironmentName() {
    this.environmentName = undefined;
    GLOBAL_CONFIG.environmentName = undefined;
  }
}
