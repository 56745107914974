import {
  getTaskRequirementColor,
  TaskRequirement,
} from 'fabscale-app/models/enums/task-requirements';

export default function taskRequirementColorStyle(
  requirement: TaskRequirement
) {
  return getTaskRequirementColor(requirement);
}
