import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { TableColumnDefinition } from 'fabscale-app/models/table-column-definition';

interface Args {
  columns: TableColumnDefinition[];
}

export default class UiDataTableHorizontalInner extends Component<Args> {
  @tracked tableContainerElement: HTMLElement;
  @tracked tableElement: HTMLElement;
  @tracked isScrollable = false;
  @tracked tableContainerWidth = 0;
  @tracked largestCellWidth = 0;

  @tracked currentOffset = 0;

  get pageSize() {
    return this.visibleDataCells;
  }

  get totalItems() {
    return this.args.columns.length - 1;
  }

  // By default, we always want to show all columns in tests, to ensure consistency
  allowPagination = !isTesting();

  get visibleColumns() {
    let { columns } = this.args;
    let { visibleDataCells, currentOffset } = this;

    let start = currentOffset + 1;
    let end = start + visibleDataCells;

    return [columns[0], ...columns.slice(start, end)];
  }

  get visibleCells() {
    let { isScrollable, tableContainerWidth, largestCellWidth } = this;
    let { columns } = this.args;

    // At least 2 need to be visible
    let cells = isScrollable
      ? Math.floor(tableContainerWidth / largestCellWidth)
      : columns.length;

    return Math.max(cells, 2);
  }

  get cellWidth() {
    if (
      this.tableContainerWidth &&
      this.isScrollable &&
      this.visibleCells > 1
    ) {
      return Math.floor(this.tableContainerWidth / this.visibleCells);
    }

    return null;
  }

  // The first column is fixed, so we only move the other columns
  get visibleDataCells() {
    return this.visibleCells - 1;
  }

  get resizeElement() {
    return macroCondition(isTesting())
      ? this.tableContainerElement?.parentElement
      : document.body;
  }

  get tableContainerStyle() {
    let { cellWidth, isScrollable, tableContainerWidth } = this;

    let styles = [];

    if (cellWidth && isScrollable) {
      styles.push(`--column-width: ${cellWidth}px`);
    }

    if (tableContainerWidth) {
      styles.push(`width: ${this.tableContainerWidth}px`);
    }

    return htmlSafe(styles.join('; '));
  }

  @action
  onResizeTable({ runCount }: { runCount: number }) {
    if (runCount === 0) {
      // Initial run - ignore this, handled in `initTableElement()`
      return;
    }

    this.recalculateTableSizeDebounced.perform();
  }

  @action
  initTableElement(element: HTMLElement) {
    this.tableContainerElement = element;
    this.tableElement = element.querySelector('.table') as HTMLElement;
    this._calculateTableCellWidth();
  }

  @action
  startOrStopPrinting() {
    this.recalculateTableSize.perform();
  }

  @action
  moveTo(currentOffset: number) {
    this.currentOffset = currentOffset;
  }

  recalculateTableSizeDebounced = restartableTask(async () => {
    // Wait for everything to settle
    // Otherwise, this collides with the column width being calculated
    await timeout(TIMEOUTS.waitForRender);

    this.recalculateTableSize.perform();
  });

  recalculateTableSize = restartableTask(async () => {
    // Reset everything
    this.isScrollable = false;
    this.largestCellWidth = 0;
    this.tableContainerWidth = 0;
    this.currentOffset = 0;

    // Wait for it to render...
    await timeout(1);

    // Re-calculate everything
    this._calculateTableCellWidth();
  });

  _calculateTableCellWidth() {
    let { tableElement, tableContainerElement } = this;

    let tableContentWidth = tableElement.offsetWidth;
    let tableContainerWidth = tableContainerElement.offsetWidth;

    // Check all columns
    let largestCellWidth = 0;
    Array.from(tableElement.querySelectorAll('th')).forEach((element) => {
      if (element.offsetWidth > largestCellWidth) {
        largestCellWidth = element.offsetWidth;
      }
    });

    this.largestCellWidth = largestCellWidth;
    this.tableContainerWidth = tableContainerWidth;

    this.isScrollable =
      this.allowPagination && tableContentWidth > tableContainerWidth;
  }
}
