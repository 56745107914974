import { Ability } from 'ember-can';
import { service } from '@ember/service';
import { assert } from '@ember/debug';
import UserSessionService from 'fabscale-app/services/user-session';

export default class CommentAbility extends Ability {
  @service userSession: UserSessionService;

  get canView() {
    return true;
  }

  get canCreate() {
    return true;
  }

  get canEdit() {
    let { model } = this;
    let { user } = this.userSession;

    assert('You have to specify a model for (can "edit comment")', !!model);

    return model.createdBy?.id === user!.id
      ? this._canEditOwn
      : this._canEditOther;
  }

  get canDelete() {
    let { model } = this;
    let { user } = this.userSession;

    assert('You have to specify a model for (can "delete comment")', !!model);

    return model.createdBy?.id === user!.id
      ? this._canDeleteOwn
      : this._canDeleteOther;
  }

  get _canEditOwn() {
    return true;
  }

  get _canEditOther() {
    return false;
  }

  get _canDeleteOwn() {
    return true;
  }

  get _canDeleteOther() {
    return Boolean(this.userSession.permissionMap['TASK_MANAGE']);
  }
}
