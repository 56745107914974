import Service, { service } from '@ember/service';
import { DateString, DateTimeString } from 'fabscale-app';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import kpiDataMultipleQuery from 'fabscale-app/gql/queries/kpi-data-multiple.graphql';
import kpiDataOverTimeQuery from 'fabscale-app/gql/queries/kpi-data-over-time.graphql';
import kpiDataPerPlantAssetQuery from 'fabscale-app/gql/queries/kpi-data-per-plant-asset.graphql';
import kpiDataPerRecipeQuery from 'fabscale-app/gql/queries/kpi-data-per-recipe.graphql';
import kpiDataQuery from 'fabscale-app/gql/queries/kpi-data.graphql';
import kpiDataWithComparativeDataQuery from 'fabscale-app/gql/queries/kpi-data-with-comparative-data.graphql';
import { DateRange } from 'fabscale-app/models/date-range';
import { GroupBinValue } from 'fabscale-app/models/group-bin-value';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import { Location } from 'fabscale-app/models/location';
import GraphQLService from 'fabscale-app/services/-graphql';
import SettingsService from 'fabscale-app/services/settings';
import UserSessionService from 'fabscale-app/services/user-session';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import {
  serializeDate,
  serializeLocalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import {
  KpiDataGroupedPojo,
  KpiDataPojo,
  transformKpiData,
  transformKpiDataGrouped,
  transformKpiDataOverTime,
} from 'fabscale-app/utilities/utils/transform-kpi-data';

interface KpiDataQueryOptions {
  dateRange: DateRange;
  plantAssetIds?: string[];
  recipeIds?: string[];
  locationId?: string;
  unitSystem?: UnitSystem;
}

export interface KpiDataFilterOptions {
  locationId: string;
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
  plantAssetIds?: string[];
  recipeIds?: string[];
  unitSystem: UnitSystem;
}

export interface KpiDataOverTimeFilterOptions {
  locationId: string;
  dateFrom: DateString;
  dateTo: DateString;
  plantAssetIds?: string[];
  recipeIds?: string[];
  unitSystem: UnitSystem;
  intervalDefinition: {
    interval: Interval;
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    timezoneName: string;
  };
}

export default class StoreKpiDataService extends Service {
  @service userSession: UserSessionService;
  @service settings: SettingsService;
  @service graphql: GraphQLService;

  get location() {
    return this.userSession.currentLocation!;
  }

  get locationId() {
    return this.location.id;
  }

  get unitSystem() {
    return this.settings.locationSettings.unitSystem;
  }

  // METHODS
  async findMultiple(
    kpiTypes: KpiType[],
    options: KpiDataQueryOptions
  ): Promise<KpiData[]> {
    let { graphql } = this;

    let variables = {
      types: kpiTypes,
      filters: this._getKpiDataFilters(options),
    };

    let response: KpiDataPojo[] = await graphql.query(
      {
        query: kpiDataMultipleQuery,
        variables,
        namespace: 'kpiDataMultiple',
      },
      { cacheEntity: 'KpiData', cacheSeconds: 60 }
    );

    return response.map((data) => transformKpiData(data));
  }

  async find(kpiType: KpiType, options: KpiDataQueryOptions): Promise<KpiData> {
    let { graphql } = this;

    let variables = {
      type: kpiType,
      filters: this._getKpiDataFilters(options),
    };

    let response: KpiDataPojo = await graphql.query(
      {
        query: kpiDataQuery,
        variables,
        namespace: 'kpiData',
      },
      { cacheEntity: 'KpiData', cacheSeconds: 60 }
    );

    return transformKpiData(response);
  }

  async findWithComparative(
    kpiType: KpiType,
    options: KpiDataQueryOptions
  ): Promise<{ kpiData: KpiData; comparativeData: KpiData }> {
    let { graphql } = this;

    let filters = this._getKpiDataFilters(options);

    let diff = Math.round(
      options.dateRange.end.diff(options.dateRange.start, 'days').days
    );

    let comparativeDateFrom = options.dateRange.start.minus({ days: diff });
    let comparativeDateTo = options.dateRange.end.minus({ days: diff });

    let comparativeFilters = Object.assign({}, filters, {
      dateFrom: serializeDate(comparativeDateFrom),
      dateTo: serializeDate(comparativeDateTo),
    });

    let variables = {
      type: kpiType,
      filters,
      comparativeFilters,
    };

    let response: { kpiData: KpiDataPojo; comparativeData: KpiDataPojo } =
      await graphql.query(
        {
          query: kpiDataWithComparativeDataQuery,
          variables,
        },
        { cacheEntity: 'KpiData', cacheSeconds: 60 }
      );

    return {
      kpiData: transformKpiData(response.kpiData),
      comparativeData: transformKpiData(response.comparativeData),
    };
  }

  async findMultiplePerLocation(
    kpiTypes: KpiType[],
    locations: Location[],
    { dateRange, unitSystem }: KpiDataQueryOptions
  ): Promise<KpiDataGrouped[]> {
    let kpiDataPerLocation = await promiseQueue(locations, async (location) => {
      let { name, id } = location;

      let kpiData = await this.findMultiple(kpiTypes, {
        dateRange,
        locationId: id,
        unitSystem,
      });

      return { name, id, kpiData };
    });

    return kpiTypes
      .map((type) => {
        let first = kpiDataPerLocation
          .flatMap((group) => group.kpiData)
          .find((kpiData) => kpiData.type === type);

        if (!first) {
          return undefined;
        }

        let { unit, higherIsBetter } = first;

        let values = kpiDataPerLocation
          .map((group) => {
            let kpiData = group.kpiData.find(
              (kpiData) => kpiData.type === type
            );

            return kpiData
              ? new GroupBinValue({
                  group: { id: group.id, name: group.name },
                  value: kpiData.value,
                })
              : undefined;
          })
          .filter(filterEmptyGroupBinValue);

        return new KpiDataGrouped({ type, unit, higherIsBetter, values });
      })
      .filter(filterEmptyKpiDataGrouped);
  }

  async findOverTime(
    kpiType: KpiType,
    options: KpiDataQueryOptions,
    { interval }: { interval: Interval }
  ): Promise<KpiDataOverTime> {
    let { graphql, settings } = this;

    let { startDayOfWeek, dayStartTime } = settings.locationSettings;
    let { timezoneName } = this.location;
    let { dateRange } = options;

    let baseFilters = this._getKpiDataFilters(options);

    let filters: KpiDataOverTimeFilterOptions = Object.assign(baseFilters, {
      dateFrom: serializeLocalDate(dateRange.start),
      dateTo: serializeLocalDate(dateRange.end),
      intervalDefinition: {
        startDayOfWeek,
        dayStartTime,
        interval,
        timezoneName,
      },
    });

    let variables = {
      type: kpiType,
      filters,
    };

    let response = await graphql.query(
      {
        query: kpiDataOverTimeQuery,
        variables,
        namespace: 'kpiDataOverTime',
      },
      { cacheEntity: 'KpiData', cacheSeconds: 60 }
    );

    return transformKpiDataOverTime({
      values: response.values,
      unit: response.unit,
      type: kpiType,
      higherIsBetter: response.higherIsBetter,
    });
  }

  async findPerRecipe(
    kpiType: KpiType,
    options: KpiDataQueryOptions
  ): Promise<KpiDataGrouped> {
    let { graphql } = this;

    let variables = {
      type: kpiType,
      filters: this._getKpiDataFilters(options),
    };

    let response: KpiDataGroupedPojo = await graphql.query(
      {
        query: kpiDataPerRecipeQuery,
        variables,
        namespace: 'kpiDataPerRecipe',
      },
      { cacheEntity: 'KpiData', cacheSeconds: 60 }
    );

    return transformKpiDataGrouped({
      data: response,
      groupIds: options.recipeIds,
    });
  }

  async findPerPlantAsset(
    kpiType: KpiType,
    options: KpiDataQueryOptions
  ): Promise<KpiDataGrouped> {
    let { graphql } = this;

    let variables = {
      type: kpiType,
      filters: this._getKpiDataFilters(options),
    };

    let response: KpiDataGroupedPojo = await graphql.query(
      {
        query: kpiDataPerPlantAssetQuery,
        variables,
        namespace: 'kpiDataPerPlantAsset',
      },
      { cacheEntity: 'KpiData', cacheSeconds: 60 }
    );

    return transformKpiDataGrouped({
      data: response,
      groupIds: options.plantAssetIds,
    });
  }

  _getKpiDataFilters({
    dateRange,
    plantAssetIds,
    recipeIds,
    locationId = this.locationId,
    unitSystem = this.unitSystem,
  }: KpiDataQueryOptions): KpiDataFilterOptions {
    let filters: KpiDataFilterOptions = {
      dateFrom: serializeDate(dateRange.start),
      dateTo: serializeDate(dateRange.end),
      locationId,
      unitSystem,
    };

    if (plantAssetIds && plantAssetIds.length > 0) {
      filters.plantAssetIds = plantAssetIds;
    }

    if (recipeIds && recipeIds.length > 0) {
      filters.recipeIds = recipeIds;
    }

    return filters;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/kpi-data': StoreKpiDataService;
  }
}

function filterEmptyKpiDataGrouped(
  group: KpiDataGrouped | undefined
): group is KpiDataGrouped {
  return !!group;
}

function filterEmptyGroupBinValue(
  group: GroupBinValue | undefined
): group is GroupBinValue {
  return !!group;
}
