import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  DashboardCardConfig,
  DashboardCardConfigStatusMap,
} from 'fabscale-app/models/dashboard-config';
import { PlantAsset } from 'fabscale-app/models/plant-asset';

interface Args {
  id: number;
  config: DashboardCardConfigStatusMap;
  updateConfig: (config: DashboardCardConfig) => void;
  validateConfig: (config: DashboardCardConfig) => boolean;
}

export default class PageDashboardCustomizeCardsStatusMap extends Component<{
  Args: Args;
}> {
  get hasError() {
    return !this.args.validateConfig(this.args.config);
  }

  @action
  updateColor(color: string) {
    let { config } = this.args;

    let newConfig = Object.assign({}, config, { color });
    this.args.updateConfig(newConfig);
  }

  @action
  updatePlantAsset(plantAsset?: PlantAsset) {
    let { config } = this.args;

    let newConfig = Object.assign({}, config, { plantAssetId: plantAsset?.id });
    this.args.updateConfig(newConfig);
  }
}
