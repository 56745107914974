import { tracked } from '@glimmer/tracking';
import { DateTimeString } from 'fabscale-app';
import { formatFileSize } from 'fabscale-app/utilities/utils/format-file-size';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export interface FileWrapperInput {
  id: string;
  name: string;
  size: number; // in B
  extension: string;
  url: string;
  thumbnailUrl?: string;
  creationDate: DateTimeString;
}

export class FileWrapper {
  id: string;
  @tracked name: string;
  size: number; // in B
  extension: string;
  url: string;
  thumbnailUrl?: string;
  creationDate: DateTime;

  get sizeFormatted() {
    return formatFileSize(this.size);
  }

  constructor(fileInput: FileWrapperInput) {
    this.id = fileInput.id;
    this.name = fileInput.name;
    this.size = fileInput.size;
    this.extension = fileInput.extension;
    this.url = fileInput.url;
    this.thumbnailUrl = fileInput.thumbnailUrl;
    this.creationDate = deserializeDate(fileInput.creationDate);
  }
}
