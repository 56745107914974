import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FormDateRangePicker extends Component {
  @tracked dropdownAPI: any;

  @action
  onRegisterDropdownAPI(dropdownAPI: any) {
    this.dropdownAPI = dropdownAPI;
  }

  @action
  onClickLabel() {
    if (this.dropdownAPI) {
      this.dropdownAPI.actions.open();
    }
  }
}
