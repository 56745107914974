import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { removeItem } from 'fabscale-app/utilities/utils/array';
import { determineAfterCommaPlaces } from 'fabscale-app/utilities/utils/format-number';
import { DateTime } from 'luxon';

export type KpiCompareReportTableData = {
  label: string | DateTime;
  searchLabel: string;
} & {
  [key in KpiType]?: ValueUnitPair;
};

export interface ValueUnitPair {
  value: number;
  unit: Unit;
}

function checkValueUnitPair(
  valueUnitPair: ValueUnitPair | undefined | string
): valueUnitPair is ValueUnitPair {
  return (
    typeof valueUnitPair === 'object' &&
    typeof valueUnitPair?.value === 'number'
  );
}

export default class KpiCompareReportTableColumnsHelper extends Helper<{
  PositionalArgs: [
    options: {
      data: KpiCompareReportTableData[];
      labelColumn: TableColumnDefinitionInput;
    }
  ];
}> {
  @service enumLabels: EnumLabelsService;

  compute([{ data, labelColumn }]: [
    {
      data: KpiCompareReportTableData[];
      labelColumn: TableColumnDefinitionInput;
    }
  ]) {
    let { enumLabels } = this;

    if (data.length === 0) {
      return [];
    }

    // Assume that all rows have all keys...
    // @ts-ignore
    let kpiTypes: KpiType[] = Object.keys(data[0]);
    removeItem(kpiTypes, 'label');
    removeItem(kpiTypes, 'searchLabel');

    let dataColumns: TableColumnDefinitionInput[] = kpiTypes.map((kpiType) => {
      let valueUnitPairs = data
        .map((rowData) => rowData[kpiType])
        .filter(checkValueUnitPair);

      if (valueUnitPairs.length === 0) {
        return {
          title: enumLabels.kpiType(kpiType),
          propertyName: `${kpiType}.value`,
        };
      }

      let allValues = valueUnitPairs.map(({ value }) => value);
      let maxValue = Math.max(...allValues);
      let afterComma = Math.max(determineAfterCommaPlaces(maxValue), 2);

      // Special case: All numbers have no decimal places (e.g. # of batches)
      if (!allValues.some((value) => !Number.isInteger(value))) {
        afterComma = 0;
      }

      let { unit } = valueUnitPairs[0]!;

      return {
        title: enumLabels.kpiType(kpiType),
        propertyName: `${kpiType}.value`,
        cellType: 'AMOUNT',
        cellData: {
          unit,
          // Use at least 2 decimal places here, otherwise let it be auto-determined
          afterComma,
          afterCommaExact: true,
        },
      };
    });

    return [labelColumn].concat(dataColumns);
  }
}
