import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { PageDef, SortDef } from 'fabscale-app';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';
import { DateRangeOptional } from 'fabscale-app/models/date-range';
import BackgroundJobService from 'fabscale-app/services/background-job';
import StorePlantAssetAlertService, {
  PaginatedPlantAssetAlerts,
  PlantAssetAlarmFilters,
} from 'fabscale-app/services/store/plant-asset-alert';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';
import BaseAsyncResource from './base-async-resource';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';

export interface LoadPlantAssetAlertOptions {
  dateRange: DateRangeOptional;
  alarmLevels?: PlantAssetAlertLevel[];
  alarmTypes?: PlantAssetAlarmType[];
  plantAssetIds?: string[];
  alarmExternalIds?: string[];
  alarmTitle?: string;

  // Pagination-related
  page: number;
  pageSize: number;
  sortBy: PlantAssetAlarmSortOption;
  sortDirection: SortDirection;
}

export const LOAD_PLANT_ASSET_ALERTS_REFETCH_INTERVAL = 30;
const JOB_ID = 'LoadPlantAssetAlerts';

export default class LoadPlantAssetAlertsResource extends BaseAsyncResource<
  PaginatedPlantAssetAlerts,
  LoadPlantAssetAlertOptions
> {
  @service('store/plant-asset-alert')
  plantAssetAlertStore: StorePlantAssetAlertService;

  @service backgroundJob: BackgroundJobService;

  jobId = `${JOB_ID}-${guidFor(this)}`;

  setup() {
    // Just to ensure shouldAutoRefresh is tracked
    // See: https://github.com/pzuraq/ember-could-get-used-to-this/issues/48
    this.args.named.shouldAutoRefresh;

    this.backgroundJob.registerJob({
      id: this.jobId,
      intervalSeconds: LOAD_PLANT_ASSET_ALERTS_REFETCH_INTERVAL,
      callback: () => {
        if (this.args.named.shouldAutoRefresh) {
          let [options] = this.args.positional;
          this.loadDataTask.perform(options);
        }
      },
    });

    super.setup();
  }

  teardown(): void {
    this.backgroundJob.unregisterJob(this.jobId);

    super.teardown();
  }

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  loadData(options: LoadPlantAssetAlertOptions) {
    const {
      page,
      pageSize,
      sortBy,
      sortDirection,
      dateRange,
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    } = options;

    const pageDef: PageDef = {
      page,
      pageSize,
    };

    const sortDef: SortDef = {
      sortBy,
      sortDirection,
    };

    const filters: PlantAssetAlarmFilters = {
      startDate: serializeOptionalDate(dateRange.start),
      endDate: serializeOptionalDate(dateRange.end),
      plantAssetIds,
      alarmLevels,
      alarmTypes,
      alarmExternalIds,
      alarmTitle,
    };

    return this.plantAssetAlertStore.queryPaginated(filters, pageDef, sortDef);
  }
}
