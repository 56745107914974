import { service } from '@ember/service';
import { UserInfo } from 'fabscale-app/models/user-info';
import StoreUserService from 'fabscale-app/services/store/user';
import BaseAsyncResource from './base-async-resource';

interface LoadUserInfoOptions {
  userId: string;
}

export default class LoadUserInfoResource extends BaseAsyncResource<
  UserInfo,
  LoadUserInfoOptions
> {
  @service('store/user') userStore: StoreUserService;

  loadData(options: LoadUserInfoOptions) {
    return this.userStore.findInfoById(options.userId);
  }
}
