import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { PlantAssetMonitoringFilters } from 'fabscale-app/models/plant-asset-monitoring';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';

export default class PlantAssetMonitoringController extends Controller {
  queryParams = [
    'dateRange',
    'timeRange',
    'plantAsset',
    'sensorCategories',
    'sensorNames',
  ];

  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAsset?: string;
  @tracked sensorCategories?: string;
  @tracked sensorNames?: string;

  get filters() {
    return {
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      plantAssetId: this.parsedPlantAssetId,
      sensorCategories: this.parsedSensorCategories,
      sensorNames: this.parsedSensorNames,
    };
  }

  get parsedDateRange() {
    return this.dateRange ? deserializeDateRange(this.dateRange) : undefined;
  }

  get parsedTimeRange() {
    return this.timeRange ? JSON.parse(this.timeRange) : undefined;
  }

  get parsedPlantAssetId() {
    return this.plantAsset ? this.plantAsset : undefined;
  }

  get parsedSensorCategories() {
    return this.sensorCategories
      ? deserializeArray(this.sensorCategories)
      : undefined;
  }

  get parsedSensorNames() {
    return this.sensorNames ? deserializeArray(this.sensorNames) : undefined;
  }

  @action
  updateFilters(filters: PlantAssetMonitoringFilters) {
    const {
      plantAssetId,
      dateRange,
      timeRange,
      sensorCategories,
      sensorNames,
    } = filters;

    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAsset = plantAssetId ? plantAssetId : undefined;
    this.sensorCategories = sensorCategories
      ? serializeArray(sensorCategories)
      : undefined;
    this.sensorNames = sensorNames ? serializeArray(sensorNames) : undefined;
  }

  resetFilters() {
    this.dateRange = this.timeRange = this.plantAsset = undefined;
  }
}
