import { service } from '@ember/service';
import { DateRange } from 'fabscale-app/models/date-range';
import StoreRoastBatchService, {
  RoastBatchesGoalSummary,
} from 'fabscale-app/services/store/roast-batch';
import BaseAsyncResource from './base-async-resource';

interface LoadRoastBatchesGoalSummaryOptions {
  dateRange: DateRange;
}

export default class LoadRoastBatchesGoalSummaryResource extends BaseAsyncResource<
  RoastBatchesGoalSummary,
  LoadRoastBatchesGoalSummaryOptions
> {
  @service('store/roast-batch')
  roastBatchStore: StoreRoastBatchService;

  async loadData({ dateRange }: LoadRoastBatchesGoalSummaryOptions) {
    return this.roastBatchStore.roastBatchesGoalSummary({
      dateRange,
    });
  }
}
