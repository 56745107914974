import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { PlantAssetAreaType } from 'fabscale-app/models/enums/plant-asset-area-types';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';

interface Args {
  plantAssetArea: PlantAssetArea;
}

export default class PlantPlantAssetAreaEdit extends Component<Args> {
  @service router: RouterService;
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  updatePlantAssetAreaTask = dropTask(
    async (
      data: {
        name: string;
        type: PlantAssetAreaType;
        description?: string;
      },
      { plantAssetId, id }: { plantAssetId: string; id: string }
    ) => {
      await this.plantAssetStore.updateArea(id, data, {
        plantAssetId,
      });

      this.router.transitionTo(
        'routes/plant-settings.plant-assets.areas.index',
        id
      );
    }
  );
}
