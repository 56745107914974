import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import CognitoService from 'fabscale-app/services/cognito';

type Args = any;

export default class SettingsSecurityMfaStatus extends Component<Args> {
  @service cognito: CognitoService;

  @tracked mfaIsEnabled = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._checkMfa();
  }

  async _checkMfa() {
    this.mfaIsEnabled = await this.cognito.cognitoData!.mfa.isEnabled();
  }
}
