import Component from '@glimmer/component';
import { action } from '@ember/object';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { service } from '@ember/service';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import ExcelService from 'fabscale-app/services/excel';
import { tracked } from '@glimmer/tracking';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import CustomAnalyticsService from 'fabscale-app/services/analytics';
import { dropTask } from 'ember-concurrency';
import { DateTime } from 'luxon';
import { PlantAssetAlert } from 'fabscale-app/models/plant-asset-alert';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import {
  PlantAssetAlarmSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';

interface Args {
  plantAssetAlerts: PlantAssetAlert[];
  columns: TableColumnDefinitionInput[];
  page?: number;
  pageSize?: number;
  sortBy?: PlantAssetAlarmSortOption;
  sortDirection?: SortDirection;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: PlantAssetAlarmSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageAlarmReportTypeReportPerRoasterTable extends Component<Args> {
  @service l10n: L10nService;
  @service excel: ExcelService;
  @service enumLabels: EnumLabelsService;
  @service analytics: CustomAnalyticsService;

  @tracked searchTerm?: string;

  get filteredData() {
    const { plantAssetAlerts } = this.args;
    const { searchTerm } = this;

    if (!searchTerm) {
      return plantAssetAlerts;
    }

    return filterRecords(plantAssetAlerts, searchTerm, {
      propertyNames: [
        'alert.alertLevel',
        'alert.alertType',
        'alert.externalId',
        'alert.localLocale',
        'alert.localLabel',
        'alert.localDescription',
        'alert.plantAsset.name',
        'title',
      ],
    });
  }

  downloadExcelTask = dropTask(async () => {
    const { plantAssetAlerts } = this.args;
    const { excel, l10n, enumLabels } = this;

    const rows = plantAssetAlerts.map((plantAssetAlert) => {
      const { id, sourceSystemRecordingDate, sourceSystemFinishDate, alert } =
        plantAssetAlert;

      const row: {
        id: string;
        sourceSystemRecordingDate: DateTime;
        sourceSystemFinishDate?: DateTime;
        title: string;
        plantAssetName: string;
        alarmType: string;
        alertLevel: string;
      } = {
        id,
        sourceSystemRecordingDate,
        sourceSystemFinishDate,
        plantAssetName: alert.plantAsset.name,
        title: alert.localLabel,
        alarmType: alert.alertType,
        alertLevel: enumLabels.plantAssetAlertLevel(alert.alertLevel),
      };

      return row;
    });

    const columns = [
      { header: l10n.t('ID'), id: 'id' },
      { header: l10n.t('Start date'), id: 'sourceSystemRecordingDate' },
      { header: l10n.t('Finish date'), id: 'sourceSystemFinishDate' },
      { header: l10n.t('Title'), id: 'title' },
      { header: l10n.t('Plant asset'), id: 'plantAssetName' },
      { header: l10n.t('Alert type'), id: 'alarmType' },
      { header: l10n.t('Alert level'), id: 'alertLevel' },
    ];

    const sheetConfig = {
      sheetName: l10n.t('Plant alarms'),
      autoFilter: true,
      columns,
      rows,
    };

    await excel.create(
      sheetConfig,
      `fabscale-plant-asset-alerts-${DateTime.local().toISODate()}.xlsx`
    );
  });

  @action
  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }
}
