import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import {
  dateIsRelative,
  formatDateRelative,
} from 'fabscale-app/utilities/utils/format-date-relative';
import { DateTime } from 'luxon';

interface TaskGroup {
  tasks: MaintenanceTask[];
  date?: DateTime;
  showDueDate: boolean;
  title: string;
}

interface Options {
  tasks: MaintenanceTask[];
  relativeToDate: DateTime;
  getTitleFn?: (l10n: L10nService, date?: DateTime) => string;
}

export default class GroupTasksByDateHelper extends Helper<{
  PositionalArgs: [options: Options];
}> {
  @service l10n: L10nService;

  compute([options]: [Options]) {
    let { l10n } = this;
    let { tasks, relativeToDate, getTitleFn = getTitle } = options;

    let groups: TaskGroup[] = [
      {
        tasks: [],
        date: relativeToDate,
        showDueDate: false,
        title: getTitleFn(l10n, relativeToDate),
      },
    ];

    let overdueGroup: TaskGroup = {
      tasks: [],
      title: getTitleFn(l10n, undefined),
      showDueDate: true,
    };

    tasks.forEach((task) => {
      let group = groups.find((group) => {
        return group.date!.hasSame(task.dueDate, 'day');
      });

      if (group) {
        group.tasks.push(task);
        return;
      }

      if (+task.dueDate < +relativeToDate) {
        overdueGroup.tasks.push(task);
        return;
      }

      // Add new group
      groups.push({
        tasks: [task],
        date: task.dueDate,
        showDueDate: false,
        title: getTitleFn(l10n, task.dueDate),
      });
    });

    if (overdueGroup.tasks.length > 0) {
      groups.unshift(overdueGroup);
    }

    return groups;
  }
}

function getTitle(l10n: L10nService, date?: DateTime) {
  if (!date) {
    return l10n.t('Overdue');
  }

  let parts = [];

  if (dateIsRelative(date)) {
    let relative = formatDateRelative(date);
    // capitalize - e.g. from today to Today
    parts.push(relative.charAt(0).toUpperCase() + relative.slice(1));
  }

  parts.push(
    date.toLocaleString({
      day: 'numeric',
      month: 'long',
      weekday: 'long',
    })
  );

  return parts.join(' - ');
}
