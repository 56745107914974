import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import {
  DefaultNavigationConfiguration,
  RootNavigations,
} from 'fabscale-app/models/default-navigation-configuration';
import { UserFrontendSettings } from 'fabscale-app/models/user-frontend-settings';
import PdfService from 'fabscale-app/services/pdf';
import SettingsService from 'fabscale-app/services/settings';

export default class ApplicationController extends Controller {
  @service pdf: PdfService;
  @service settings: SettingsService;

  get userFrontendSettings(): UserFrontendSettings {
    return this.settings.userFrontendSettings;
  }

  public get navigationConfiguration(): DefaultNavigationConfiguration {
    return this.userFrontendSettings.navigationConfig;
  }

  get isPrinting() {
    return this.pdf.isPrinting;
  }

  @action
  async defaultNavigationChanged(
    route?: RootNavigations,
    defaultNavigation?: string
  ) {
    await this.applyNavigationChange(route, defaultNavigation);
  }

  private async applyNavigationChange(
    route?: RootNavigations,
    defaultNavigation?: string
  ) {
    if (!route || !defaultNavigation) {
      throw new Error(
        `Parameters 'route' and 'defaultNavigation' must be specified`
      );
    }
    this.userFrontendSettings.setDefaultNavigation(route, defaultNavigation);
    await this.userFrontendSettings.save();
  }
}
