import { DateRangeOptional } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

export default function datePickerIsInSelectedRange(
  date: DateTime,
  dateOrRange: DateTime | undefined | DateRangeOptional
) {
  if (!dateOrRange || dateOrRange instanceof DateTime) {
    return false;
  }

  let { start, end } = dateOrRange;

  return (
    start instanceof DateTime &&
    end instanceof DateTime &&
    start.valueOf() < date.valueOf() &&
    end.valueOf() > date.valueOf()
  );
}
