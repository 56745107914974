import { assert } from '@ember/debug';

interface TimeValuePair {
  time: number;
  value: number;
}

// secondsSmoothing: e.g. 5, 10, 15, 30, 60
export function createRorCurve(
  curveValues: TimeValuePair[],
  secondsSmoothing: number,
  seconds = 4,
  smaWindow = 18
) {
  let sma = new SimpleMovingAverage(smaWindow);

  let everySecondValues = normalizeCurveValues(curveValues);

  let rorValues = everySecondValues.map((curveValue, index) => {
    let { time } = curveValue;

    if (index < seconds) {
      return {
        time,
      };
    }

    let previousValue = everySecondValues[index - seconds]!.value;
    let recentValue = curveValue.value - previousValue;
    sma.addValue(recentValue);
    let currentAverage = sma.calculateCurrent();

    if (typeof currentAverage === 'number') {
      return {
        time,
        value: currentAverage / (seconds / secondsSmoothing),
      };
    }

    return {
      time,
    };
  });

  let firstNonZeroValueIndex = rorValues.findIndex(
    (item) => item.value && item.value >= 0
  );

  return rorValues.map((curveValue, index) => {
    if (index < firstNonZeroValueIndex) {
      return { time: curveValue.time };
    }

    return curveValue;
  });
}

export function normalizeCurveValues(
  curveValues: TimeValuePair[]
): TimeValuePair[] {
  let everySecondValues: TimeValuePair[] = [];

  let currentTime = 0;
  let lastValue = 0;
  let binSize = 1000; // 1000ms = 1s

  curveValues.forEach(({ time, value }) => {
    while (currentTime + binSize <= time) {
      // There is a gap between the last two items! fill it up
      everySecondValues.push({ time: currentTime, value: lastValue });
      currentTime += binSize;
    }

    if (time >= currentTime && time < currentTime + binSize) {
      everySecondValues.push({ time: currentTime, value });
      lastValue = value;
      currentTime += binSize;
    }
  });

  return everySecondValues;
}

class SimpleMovingAverage {
  period: number;
  values: number[];

  constructor(period: number) {
    assert('period must be positive integer', period > 0);

    this.period = period;
    this.values = [];
  }

  addValue(value: number) {
    this.values.push(value);
  }

  calculateCurrent() {
    let { values, period } = this;

    if (values.length < period) {
      return undefined;
    }

    let sumForPeriod = values
      .slice(values.length - period)
      .reduce((a, b) => a + b, 0);

    return sumForPeriod / period;
  }
}
