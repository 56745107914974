import { DateTimeString } from 'fabscale-app';
import {
  BooleanRoastBatchParameterType,
  NumericCurveRoastBatchParameterType,
  NumericRoastBatchParameterType,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { RoastBatchStatus } from 'fabscale-app/models/enums/roast-batch-status';
import { Unit } from 'fabscale-app/models/enums/units';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { ParameterGoalResult } from './parameter-goal';

export class RoastBatch {
  id: string;
  creationDate: DateTime;
  sourceSystemRecordingDate: DateTime;
  startDate: DateTime;
  externalId?: string;
  status: RoastBatchStatus;
  plantAsset: { id: string; name: string };
  recipe: { id: string; name: string };

  numericRoastBatchParameters: NumericRoastBatchParameter[];
  booleanRoastBatchParameters: BooleanRoastBatchParameter[];
  numericCurveRoastBatchParameters: NumericCurveRoastBatchParameter[];

  /**
   * @returns {string} UI-representation of the id-field
   */
  get roastId(): string {
    return `#${this.id}`;
  }

  /**
   * Constructs a new RoastBatch-instance from a POJO-object from API
   * @param options Representation as POJO (from API) to construct
   */
  constructor(options: RoastBatchPojo) {
    this.id = options.id;
    this.creationDate = deserializeDate(options.creationDate);
    this.sourceSystemRecordingDate = deserializeDate(
      options.sourceSystemRecordingDate
    );
    this.externalId = options.externalId;
    this.status = options.status;
    this.plantAsset = options.plantAsset;
    this.recipe = options.recipe;
    this.numericRoastBatchParameters = [
      ...(options.numericRoastBatchParameters ?? []),
    ];
    this.booleanRoastBatchParameters = [
      ...(options.booleanRoastBatchParameters ?? []),
    ];
    this.numericCurveRoastBatchParameters = [
      ...(options.numericCurveRoastBatchParameters ?? []),
    ];
    this.startDate = deserializeDate(options.startDate);
  }
}

export interface NumericRoastBatchParameter {
  parameterType: NumericRoastBatchParameterType;
  externalId?: string;
  value: number;
  unit: Unit;
  goalResults?: ParameterGoalResult[];
}

export interface BooleanRoastBatchParameter {
  parameterType: BooleanRoastBatchParameterType;
  externalId?: string;
  value: boolean;
}

export interface NumericCurveRoastBatchParameter {
  parameterType: NumericCurveRoastBatchParameterType;
  externalId?: string;
  value: { time: number; value: number }[];
  unit: Unit;
}

export interface RoastBatchPojo {
  id: string;
  creationDate: DateTimeString;
  sourceSystemRecordingDate: DateTimeString;
  startDate: DateTimeString;
  externalId?: string;
  status: RoastBatchStatus;
  plantAsset: { id: string; name: string };
  recipe: { id: string; name: string };

  numericRoastBatchParameters?: NumericRoastBatchParameter[];
  booleanRoastBatchParameters?: BooleanRoastBatchParameter[];
  numericCurveRoastBatchParameters?: NumericCurveRoastBatchParameter[];
}
