export function calculateDomXPosOffset(
  element: HTMLElement,
  currentXPosOffset = 0,
  {
    padding = 5,
    windowWidth = document.documentElement.clientWidth ||
      document.body.clientWidth ||
      0,
  } = {}
): number {
  let bbox = element.getBoundingClientRect();

  let paddedWindowWidth = windowWidth - padding;

  // We adjust the left/right values we use by the current offset,
  // otherwise we get wrong values if we re-run this again if an offset is already applied
  let left = bbox.left - currentXPosOffset;
  let right = bbox.right - currentXPosOffset;

  // Escape hatch: if it is too small, do nothing - to prevent endless recursion
  if (bbox.width >= paddedWindowWidth) {
    return 0;
  }

  if (left < padding) {
    // Move it a bit to the right, so that the left edge of the tooltip is `padding` away from 0
    // e.g. if `bbox.left` is -10, the tooltip needs to be moved right by 15
    return left * -1 + padding + 1;
  }

  if (right > paddedWindowWidth) {
    // Move it a bit to the right, so that there is `padding` space to windowWidth
    // e.g. if `bbox.right` is 800, and `windowWidth` is 750, the tooltip needs to be moved left by 55
    return paddedWindowWidth - right + 1;
  }

  return 0;
}
