import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class UiChartSelectorItemLabel extends Component {
  @tracked
  showTooltip = false;

  @action
  showTip() {
    this.showTooltip = true;
  }

  @action
  hideTip() {
    this.showTooltip = false;
  }
}
