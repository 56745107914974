import { DateTimeString } from 'fabscale-app';
import { PlantAssetAlertLevel } from 'fabscale-app/models/enums/plant-asset-alert-level';
import {
  deserializeDate,
  deserializeOptionalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export class PlantAssetAlert {
  id: string;
  sourceSystemRecordingDate: DateTime;
  sourceSystemFinishDate?: DateTime;
  alert: PlantAssetAlertDefinition;
  title: string;

  get durationSeconds(): number {
    let { sourceSystemFinishDate, sourceSystemRecordingDate } = this;

    if (!sourceSystemFinishDate) {
      return 0;
    }

    return sourceSystemFinishDate.diff(sourceSystemRecordingDate, 'second')
      .seconds;
  }

  constructor(input: PlantAssetAlertPojo) {
    this.id = input.id;
    this.sourceSystemRecordingDate = deserializeDate(
      input.sourceSystemRecordingDate
    );
    this.sourceSystemFinishDate = deserializeOptionalDate(
      input.sourceSystemFinishDate
    );
    this.alert = input.alert;
    this.title = input.alert.localLabel;
  }
}

export interface PlantAssetAlertPojo {
  id: string;
  sourceSystemRecordingDate: DateTimeString;
  sourceSystemFinishDate?: DateTimeString;
  alert: PlantAssetAlertDefinition;
}

interface PlantAssetAlertDefinition {
  alertLevel: PlantAssetAlertLevel;
  // We handle these enums as string in the UI, otherwise we'd need to keep this always in sync
  alertType: string;
  externalId?: string;
  localLocale: string;
  localLabel: string;
  localDescription?: string;
  plantAsset: {
    id: string;
    name: string;
  };
}
