import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class StyleguideButtonController extends Controller {
  @tracked buttonIsLoading = false;

  @action
  noop() {
    // noop
  }

  @action
  updateButtonIsLoading(buttonIsLoading: boolean) {
    this.buttonIsLoading = buttonIsLoading;
  }
}
