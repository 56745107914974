import { assert } from '@ember/debug';
import {
  ALL_RELATIVE_TIMEFRAMES,
  RelativeTimeframeId,
  RelativeTimeframe,
} from 'fabscale-app/models/enums/relative-timeframe';
import { TimeRangeOptionalEnd } from 'fabscale-app/models/time-range';
import { DateTime } from 'luxon';
import { getTimeValue, parseTime } from './parse-time';

export function relativeTimeframeToDateRange(
  relativeTimeframe: RelativeTimeframe,
  timeRange?: TimeRangeOptionalEnd,
  // This can be overwritten in tests
  _getToday = DateTime.local
) {
  let { unit, includeThis } = relativeTimeframe;

  let end = includeThis
    ? _getToday().endOf(unit).startOf('day')
    : _getToday().startOf(unit).minus({ days: 1 }).startOf('day');

  let start = end.startOf(unit);

  if (timeRange) {
    // If the end time is after the start time, we have to move the end date one day back
    // E.g. if you have 08:00-07:59, you'd otherwise have no range selected
    if (
      !timeRange.end ||
      getTimeValue(parseTime(timeRange.end)) <=
        getTimeValue(parseTime(timeRange.start))
    ) {
      end = end.plus({ days: 1 });
    }
  }

  return {
    start,
    end,
  };
}

export function serializeRelativeTimeframe(
  relativeTimeframe: RelativeTimeframe
): RelativeTimeframeId {
  return relativeTimeframe.id;
}

export function deserializeRelativeTimeframe(
  relativeTimeframeId: RelativeTimeframeId
): RelativeTimeframe {
  let timeframe = ALL_RELATIVE_TIMEFRAMES.find(
    (timeframe) => timeframe.id === relativeTimeframeId
  );

  assert(
    `Could not deserialize relative timeframe "${relativeTimeframeId}"`,
    !!timeframe
  );

  return timeframe;
}
