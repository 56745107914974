import Component from '@glimmer/component';
import { ShiftData } from 'fabscale-app/models/location';

interface Args {
  shiftName: string;
  shiftData: ShiftData;
  isDisabled: boolean;
}

export default class PlantShiftsShiftDataIndex extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get inputsDisabled() {
    return this.args.isDisabled || !this.args.shiftData.isEnabled;
  }
}
