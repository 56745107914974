// @ts-ignore
import * as Sentry from '@sentry/ember';
import { OfflineError } from 'fabscale-app/models/errors/offline-error';
import { macroCondition, isTesting, isDevelopingApp } from '@embroider/macros';
import { UnauthenticatedError } from 'fabscale-app/models/errors/unauthenticated-error';

export function sentryCaptureException(error: unknown) {
  if (error instanceof OfflineError || error instanceof UnauthenticatedError) {
    return;
  }

  if (macroCondition(isDevelopingApp())) {
    if (!isTesting()) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  Sentry.captureException(error);
}
