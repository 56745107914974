import { UserLocalStorage } from 'fabscale-app/models/user';

const LOCAL_STORAGE_USER_NAMESPACE = 'fabscale-app-current-user';
const LOCAL_STORAGE_SIMULATION_USER_NAMESPACE =
  'fabscale-app-simulation-current-user';

export function setUser(user: UserLocalStorage) {
  try {
    window.localStorage.setItem(
      LOCAL_STORAGE_USER_NAMESPACE,
      JSON.stringify(user)
    );
  } catch (error) {
    // ignore errors here...
  }
}

export function getUser(isSimulation?: boolean): UserLocalStorage {
  try {
    let str = window.localStorage.getItem(
      isSimulation
        ? LOCAL_STORAGE_SIMULATION_USER_NAMESPACE
        : LOCAL_STORAGE_USER_NAMESPACE
    );
    return JSON.parse(str!) || {};
  } catch (error) {
    return {} as UserLocalStorage;
  }
}

export function setSimulationUser(email: string) {
  try {
    window.localStorage.setItem(
      LOCAL_STORAGE_SIMULATION_USER_NAMESPACE,
      JSON.stringify({
        ...getUser(),
        email: email,
      })
    );
  } catch (error) {
    // ignore errors here...
  }
}

export function setPlantAssetMonitoringSensorColors(colors: {
  [key: string]: string;
}) {
  try {
    window.localStorage.setItem(
      'plant-asset-monitoring',
      JSON.stringify({
        sensorColors: colors,
      })
    );
  } catch (error) {
    // ignore errors here...
  }
}

export function getPlantAssetMonitoringSensorColors(): {
  [key: string]: string;
} {
  try {
    const plantAssetMonitoringObj = JSON.parse(
      window.localStorage.getItem('plant-asset-monitoring')!
    );
    return plantAssetMonitoringObj.sensorColors || {};
  } catch (error) {
    return {} as any;
  }
}
