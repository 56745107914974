import { service } from '@ember/service';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetAreaOptions {
  plantAssetAreaId: string;
}

export default class LoadPlantAssetAreaResource extends BaseAsyncResource<
  PlantAssetArea,
  LoadPlantAssetAreaOptions
> {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  loadData(options: LoadPlantAssetAreaOptions) {
    return this.plantAssetStore.findAreaById(options.plantAssetAreaId);
  }
}
