import { GraphqlError } from 'fabscale-app/models/errors/graphql-error';

export class ResourceNotFoundError extends GraphqlError {
  translatedMessage?: string;

  constructor(error: any) {
    super(error);

    this.code = 'RESOURCE_NOT_FOUND';
  }
}
