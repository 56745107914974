import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { LabSamplesListFilters } from 'fabscale-app/services/store/lab-sample';
import { TimeRange } from 'fabscale-app/models/time-range';

interface Args {
  filters: LabSamplesListFilters & { timeRange: TimeRange };
  updateFilters: (
    filters: LabSamplesListFilters & { timeRange: TimeRange }
  ) => void;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: RoastpicSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageRoastPic extends Component<Args> {
  get filters() {
    const { dateRange, timeRange, coffeeTypes } = this.args.filters || {};

    return {
      dateRange,
      timeRange,
      coffeeTypes,
    };
  }

  get hasAllRequiredData() {
    return (
      !!this.args.filters?.dateRange?.start &&
      !!this.args.filters?.dateRange?.end
    );
  }

  @action
  updateFilters(filters: LabSamplesListFilters & { timeRange: TimeRange }) {
    const { dateRange, timeRange, coffeeTypes } = filters;

    this.args.updateFilters({
      dateRange,
      timeRange,
      coffeeTypes,
    });
  }
}
