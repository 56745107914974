import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import { DateRange } from 'fabscale-app/models/date-range';
import { Location } from 'fabscale-app/models/location';
import StoreKpiDataService from 'fabscale-app/services/store/kpi-data';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import BaseAsyncResource from './base-async-resource';
import { KpiDataOverTimeGrouped } from './load-kpi-data-over-time-grouped';

interface LoadKpiDataOverTimePerLocationOptions {
  kpiType: KpiType;
  dateRange: DateRange;
  interval: Interval;
  locations: Location[];
  unitSystem?: UnitSystem;
}

export default class LoadKpiDataPerLocationResource extends BaseAsyncResource<
  KpiDataOverTimeGrouped[],
  LoadKpiDataOverTimePerLocationOptions
> {
  @service('store/kpi-data') kpiDataStore: StoreKpiDataService;

  update() {
    let [options] = this.args.positional;

    this.loadDataIfUpdated(options);
  }

  async loadData({
    kpiType,
    dateRange,
    interval,
    locations,
    unitSystem,
  }: LoadKpiDataOverTimePerLocationOptions) {
    assert(
      `{{resources/load-kpi-data-over-time-per-location}}: You have to provide a list of locations`,
      !!locations?.length
    );

    let { kpiDataStore } = this;

    let data: KpiDataOverTimeGrouped[] = await promiseQueue(
      locations,
      async (location) => {
        let kpiDataOverTime = await kpiDataStore.findOverTime(
          kpiType,
          { dateRange, unitSystem, locationId: location.id },
          { interval }
        );

        return {
          data: kpiDataOverTime,
          groupId: location.name,
        };
      }
    );

    return data;
  }
}
