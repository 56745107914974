import { modifier } from 'ember-could-get-used-to-this';
import { assert } from '@ember/debug';
import { schedule } from '@ember/runloop';
import { focusElement } from 'fabscale-app/utilities/utils/dom/focus-element';

export function autoFocus(
  element: HTMLElement,
  _: unknown,
  { afterRender = false, shouldAutoFocus = true } = {}
) {
  let allowedTagNames = ['input', 'textarea', 'button', 'a', 'select'];
  assert(
    `{{auto-focus}} was called on "<${element.tagName.toLowerCase()}>" but should only be called on one of: ${allowedTagNames.join(
      ', '
    )}`,
    allowedTagNames.includes(element.tagName.toLowerCase())
  );

  if (!shouldAutoFocus) {
    return;
  }

  if (afterRender) {
    schedule('afterRender', () => focusElement(element));
  } else {
    focusElement(element);
  }
}

export default modifier(autoFocus);
