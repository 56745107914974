import { AxisTimeInterval } from 'd3-axis';
import { NumberValue, ScaleBand } from 'd3-scale';
import {
  timeMonday,
  timeSunday,
  timeHour,
  timeDay,
  timeMonth,
  timeYear,
} from 'd3-time';
import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';
import { DateTime } from 'luxon';
import { getLocalTimezone } from './timezone';

// Estimate how much space a list of numeric labels will roughly need
export function calculateNumbersWidth(numbers: number[]) {
  // Note on characterWidth: This is a very rough estimation based on trial and error!
  // These settings seemed to work fine for a wide range of values
  return calculateStringsWidth(
    numbers.map((number) => formatNumber(number)),
    6.1
  );
}

// Note on characterWidth: This is a very rough estimation based on trial and error!
// These settings seemed to work fine for a wide range of values
export function calculateStringsWidth(strings: string[], baseWidth = 5) {
  if (strings.length === 0) {
    return 0;
  }

  let numberOfCharacters = Math.max(...strings.map((str) => str.length));
  let characterWidth = getCharacterWidht(numberOfCharacters, baseWidth);

  return numberOfCharacters * characterWidth;
}

function getCharacterWidht(numberOfCharacters: number, baseWidth = 6.1) {
  if (numberOfCharacters <= 1) {
    return 10;
  }

  if (numberOfCharacters < 5) {
    return baseWidth * 1.5;
  }

  return baseWidth;
}

export function scaleBandInvert(
  scale: ScaleBand<string>,
  value: number
): string {
  let domain = scale.domain();
  let paddingOuter = scale(domain[0]!) as number;
  let eachBand = scale.step();

  let index = Math.floor((value - paddingOuter) / eachBand);
  return domain[Math.max(0, Math.min(index, domain.length - 1))]!;
}

export function getPaddedMinMaxValues(
  dataValues: number[],
  scalePaddingMax: number,
  scalePaddingMin: number = scalePaddingMax
): MinMax {
  if (dataValues.length === 0) {
    return { minValue: 0, maxValue: 0 };
  }

  let minValue = Math.min(...dataValues);
  let maxValue = Math.max(...dataValues);

  let valueSpan = Math.abs(maxValue - minValue);
  let valuePaddingMax = valueSpan * scalePaddingMax;
  let valuePaddingMin = valueSpan * scalePaddingMin;

  // If the min value is larger than 0, we do not want to let it fall below 0
  // As it would be weird to have "-12,000" as the lowest bar
  if (minValue >= 0) {
    minValue = 0;
  } else {
    minValue = minValue - valuePaddingMin;
  }

  maxValue = maxValue + valuePaddingMax;

  return { minValue, maxValue };
}

export function getDateAxisTickFormat(
  axisTickFormat: Intl.DateTimeFormatOptions
) {
  // Since we converted all dates into the local timezone for the js-dates to work,
  // we need to ensure to also display them that way
  let timeZone = getLocalTimezone();

  return (date: Date | NumberValue) => {
    return date instanceof Date
      ? DateTime.fromJSDate(date)
          .setZone(timeZone)
          .toLocaleString(axisTickFormat)
      : date.valueOf().toString();
  };
}

export function getAxisTicks(
  minMax: MinMax,
  tickNumber: number,
  dataValues: number[]
): number[] {
  let { minValue, maxValue } = minMax;

  let allValuesAreRound = !dataValues.some(
    (value) => value !== Math.round(value)
  );

  let diff = maxValue - minValue;

  let ticks = [];
  for (let i = 0; i < tickNumber; i++) {
    let value = minValue + (i * diff) / (tickNumber - 1);

    if (value > 100 || allValuesAreRound) {
      value = Math.round(value);
    }

    ticks.push(value);
  }

  // TODO FN: Maybe remove values that are not used, e.g. if you have values [0,0,1,0,1] only show ticks 1 and 0.
  return ticks;
}

export function getActualMargins(
  defaultMargins: Margins,
  margins?: OptionalMargins
): Margins {
  // We need to make sure to remove `undefined` fields from `margins`
  let marginOverwrites: Margins = margins
    ? JSON.parse(JSON.stringify(margins))
    : {};

  return Object.assign({}, defaultMargins, marginOverwrites);
}

export function getD3TickIntervalForDates(
  dates: DateTime[],
  interval: Interval,
  startDayOfWeek: DayOfWeek = 'MONDAY',
  maxItems = 12
): AxisTimeInterval {
  let startDate = dates[0]!;
  let endDate = dates[dates.length - 1]!;

  if (interval === 'HOUR') {
    return timeHour.every(
      getTimeIntervalSteps(startDate, endDate, 'hours', maxItems)
    )!;
  }

  if (interval === 'WEEK') {
    let steps = getTimeIntervalSteps(startDate, endDate, 'weeks', maxItems);

    return startDayOfWeek === 'SUNDAY'
      ? timeSunday.every(steps)!
      : timeMonday.every(steps)!;
  }

  if (interval === 'MONTH') {
    return timeMonth.every(
      getTimeIntervalSteps(startDate, endDate, 'months', maxItems)
    )!;
  }

  if (interval === 'YEAR') {
    return timeYear.every(1)!;
  }

  // Note: timeDay.every(x) always resets on changes of month, leading to potential issues
  // So instead, we manually filter these out
  let step = getTimeIntervalSteps(startDate, endDate, 'days', maxItems);
  return timeDay.filter(
    (d) => timeDay.count(startDate.toJSDate(), d) % step === 0
  );
}

export interface MinMax {
  minValue: number;
  maxValue: number;
}

export interface OptionalMargins {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export interface Margins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export function getTimeIntervalSteps(
  startDate: DateTime,
  endDate: DateTime,
  startOf: 'hours' | 'days' | 'weeks' | 'months',
  maxItems: number
) {
  let diff = endDate.diff(startDate, startOf)[startOf];

  let maxActualItems = Math.min(diff, maxItems);

  return Math.ceil(diff / maxActualItems);
}

export const PARSING_OPTIONS = {
  xAxisKey: 'dateTime',
  yAxisKey: 'value',
};

export const cssObj = {
  borders: {
    solid: 'solid',
  },
  colors: {
    inherit: 'inherit',
    cultured: '#F5F7FA',
    lightGray: '#D3D4D9',
    sonicSilver: '#70717A',
    white: 'white',
    transparent: 'transparent',
  },
  cursor: {
    pointer: 'pointer',
    default: 'default',
  },
  display: {
    flex: 'flex',
    inlineBlock: 'inline-block',
  },
  flex: {
    flexDirection: {
      row: 'row',
    },
  },
  fontFamily: {
    lato: "'Lato', Helvetica, Arial, sans-serif",
  },
  fontWeight: {
    _700: '700',
  },
  position: {
    absolute: 'absolute',
    left: 'left',
    right: 'right',
  },
  transform: {
    translate1: 'translate(-50%, 0)',
  },
  transition: {
    allEase1: 'all .1s ease',
  },
  pointerEvents: {
    none: 'none',
  },
  spacings: {
    _0px: '0px',
    _1px: '1px',
    _2px: '2px',
    _3px: '3px',
    _8px: '8px',
    _10px: '10px',
    _11px: '11px',
    _12px: '12px',
    _14px: '14px',
  },
  opacity: {
    _0: '0',
    _1: '1',
  },
  whiteSpace: {
    nowrap: 'nowrap',
  },
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const oeeCardsExternalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipId = `${chart.ctx.canvas.id}-tooltip`;

  // Get or create tooltip
  let tooltipEl = document.getElementById(tooltipId);

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = cssObj.colors.white;
    tooltipEl.style.borderRadius = cssObj.spacings._3px;
    tooltipEl.style.borderWidth = cssObj.spacings._1px;
    tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
    tooltipEl.style.borderColor = cssObj.colors.lightGray;

    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
    tooltipEl.style.position = cssObj.position.absolute;
    tooltipEl.style.transform = cssObj.transform.translate1;
    tooltipEl.style.transition = cssObj.transition.allEase1;

    const table = document.createElement('table');
    table.style.margin = cssObj.spacings._0px;

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  if (!tooltip?.dataPoints || !tooltip?.dataPoints?.length) {
    return;
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = cssObj.opacity._0;
    return;
  }

  tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
  tooltipEl.id = tooltipId;

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b: any) => b.lines);
    const tableHead = document.createElement('thead');

    const tableBody = document.createElement('tbody');
    tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

    bodyLines.forEach((body: any, i: any) => {
      const colors = tooltip.labelColors[i];
      const label = tooltip.dataPoints[i].dataset.label;
      const value = tooltip.dataPoints[i].dataset.data[0];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = cssObj.spacings._0px;
      span.style.marginRight = cssObj.spacings._10px;
      span.style.height = cssObj.spacings._12px;
      span.style.width = cssObj.spacings._12px;
      span.style.display = cssObj.display.inlineBlock;

      const tr = document.createElement('tr');
      tr.style.backgroundColor = cssObj.colors.inherit;
      const td = document.createElement('td');

      let span1 = document.createElement('span');
      span1.style.display = cssObj.display.flex;
      span1.style.flexDirection = cssObj.flex.flexDirection.row;

      let p1 = document.createElement('p');
      p1.innerHTML = `${label}:`;
      p1.style.marginRight = cssObj.spacings._8px;

      let p2 = document.createElement('p');
      p2.innerHTML = `${value}% avg`;
      p1.style.marginBottom = p2.style.marginBottom = cssObj.spacings._0px;
      p2.style.fontWeight = cssObj.fontWeight._700;

      span1.append(p1, p2);

      let tableDataContainer = document.createElement('div');
      tableDataContainer.style.display = 'flex';
      tableDataContainer.style.flexDirection = 'row';
      tableDataContainer.style.alignItems = 'center';

      tableDataContainer.append(span, span1);

      td.appendChild(tableDataContainer);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot?.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = cssObj.opacity._1;
  tooltipEl.style.left = `${Number(positionX)}px`;
  tooltipEl.style.top = `${Number(positionY) + Number(tooltip.caretY)}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = cssObj.spacings._8px;
};
