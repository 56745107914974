import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { TASK_CALENDAR_TODAY } from 'fabscale-app/routes/maintenance/tasks/calendar/date/route';
import { DateTime } from 'luxon';
import { CalendarMode } from '.';

interface Args {
  date: DateTime;
  mode: CalendarMode;
  searchTerm?: string;
  updateSearchTerm: (searchTerm?: string) => void;
  updateDate: (date: DateTime | typeof TASK_CALENDAR_TODAY) => void;
  updateMode: (mode: CalendarMode) => void;
}

export default class TaskCalendarMonth extends Component<Args> {
  @service l10n: L10nService;

  get modeDay() {
    return this.args.mode === 'DAY';
  }

  get _dateStep(): 'month' | 'day' {
    return this.modeDay ? 'day' : 'month';
  }

  get dateFormatOptions(): Intl.DateTimeFormatOptions {
    return this.modeDay
      ? { month: 'long', year: 'numeric', day: 'numeric' }
      : { month: 'long', year: 'numeric' };
  }

  @cached
  get modeOptions(): { value: CalendarMode; label: string }[] {
    let { l10n } = this;

    return [
      { value: undefined, label: l10n.t('Month') },
      { value: 'DAY', label: l10n.t('Day') },
    ];
  }

  get selectedModeOption() {
    let { mode } = this.args;

    return this.modeOptions.find((opt) => opt.value === mode)!;
  }

  @action
  gotoToday() {
    this.args.updateDate(TASK_CALENDAR_TODAY);
  }

  @action
  gotoPrevious() {
    let step = this._dateStep;
    let date = this.args.date.minus({ [step]: 1 }).startOf(step);
    this.args.updateDate(date);
  }

  @action
  gotoNext() {
    let step = this._dateStep;
    let date = this.args.date.plus({ [step]: 1 }).startOf(step);
    this.args.updateDate(date);
  }

  @action
  updateMode(mode: { value: CalendarMode }) {
    this.args.updateMode(mode.value);
  }

  updateSearchTermTask = restartableTask(async (searchTerm: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.args.updateSearchTerm(searchTerm);
  });
}
