import { assert } from '@ember/debug';
import { DateTime } from 'luxon';

export default function dateIsSameDay(
  date: DateTime,
  date2: DateTime
): boolean {
  assert(
    '{{date/is-same-day}}: You have to provide two dates',
    date instanceof DateTime && date2 instanceof DateTime
  );

  return date2.hasSame(date, 'day');
}
