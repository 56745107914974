import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Transition from '@ember/routing/transition';
import { schedule } from '@ember/runloop';
import UserSessionService from 'fabscale-app/services/user-session';
import AlarmReportLevelReportController from './controller';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';

export default class RoutesReportsAlarmLevelReport extends AuthenticatedRoute {
  @service userSession: UserSessionService;
  @service router: RouterService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (!this.userSession.permissionMap.PLANT_ASSET_ALERT_MANAGE) {
      window.history.back();
    }
  }

  resetController(
    controller: AlarmReportLevelReportController,
    isExiting: boolean
  ) {
    if (isExiting) {
      schedule('afterRender', () => {
        controller.resetFilters();
      });
    }
  }
}
