import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AbilitiesService } from 'ember-can';
import { restartableTask, timeout } from 'ember-concurrency';
import CellActions from 'fabscale-app/components/page/plant/plant-assets/overview/cells/actions';
import CellName from 'fabscale-app/components/page/plant/plant-assets/overview/cells/name';
import CellType from 'fabscale-app/components/page/plant/plant-assets/overview/cells/type';
import {
  PlantAssetType,
  PlantAssetTypes,
} from 'fabscale-app/models/enums/plant-asset-types';
import { TIMEOUTS } from 'fabscale-app/utilities/fixtures/timeouts';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';

interface Args {
  plantAssets: PlantAsset[];
}

export default class PlantPlantAssetsOverviewTable extends Component<Args> {
  @service l10n: L10nService;
  @service abilities: AbilitiesService;

  availablePlantAssetTypes = PlantAssetTypes;

  columns: TableColumnDefinitionInput[];

  @tracked filterType?: PlantAssetType;
  @tracked searchTerm = '';

  get filteredData() {
    let { searchTerm, filterType } = this;
    let { plantAssets } = this.args;

    if (!searchTerm && !filterType) {
      return plantAssets;
    }

    let filteredData = filterType
      ? plantAssets.filter((record) => record.type === filterType)
      : plantAssets;

    return searchTerm
      ? filterRecords(plantAssets, searchTerm, { propertyName: 'name' })
      : filteredData;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._setupColumns();
  }

  _setupColumns() {
    let { l10n, abilities } = this;

    let columns = [
      {
        propertyName: 'name',
        title: l10n.t('Name'),
        noCompactTitle: true,
        component: CellName,
      },
      {
        propertyName: 'type',
        title: l10n.t('Type'),
        component: CellType,
      },
      { propertyName: 'model.fullName', title: l10n.t('Model') },
    ];

    if (abilities.can('edit plant-asset')) {
      columns.push({
        propertyName: '',
        title: '',
        component: CellActions,
      });
    }

    this.columns = columns;
  }

  @action
  updateFilterType(filterType?: PlantAssetType) {
    this.filterType = filterType;
  }

  updateSearchTermTask = restartableTask(async (searchTerm: string) => {
    await timeout(TIMEOUTS.searchTypeDebounce);

    this.searchTerm = searchTerm;
  });
}
