import { action } from '@ember/object';
import Component from '@glimmer/component';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { PermissionDefinition } from 'fabscale-app/services/store/user-role';

interface Args {
  permissions: Permission[];
  availablePermissionDefinitions: PermissionDefinition[];
}

export default class PageSettingsRolesPermissionList extends Component<Args> {
  get permissionDefinitions() {
    let { permissions, availablePermissionDefinitions } = this.args;

    return availablePermissionDefinitions.filter((def) => {
      return permissions.includes(def.permission);
    });
  }

  @action
  hasParent(def: PermissionDefinition) {
    let { permissions } = this.args;

    return def.parentPermission && permissions.includes(def.parentPermission);
  }
}
