import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import CellActions from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/actions';
import CellAlertLevel from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/alert-level';
import CellAlarmType from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/alarm-type';
import CellDuration from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/duration';
import CellAlertExternalId from 'fabscale-app/components/page/plant-asset-alerts/overview/table/cell/alert-external-id';
import { PlantAssetAlert } from 'fabscale-app/models/plant-asset-alert';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import ExcelService from 'fabscale-app/services/excel';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import { DateTime } from 'luxon';

interface Args {
  plantAssetAlerts: PlantAssetAlert[];
  shouldAutoRefresh: boolean;
  disableAutoRefresh: () => void;
  enableAutoRefreshIfHasDefaults: () => void;
}

export default class PlantAssetAlertsOverviewTable extends Component<Args> {
  @service l10n: L10nService;
  @service excel: ExcelService;
  @service enumLabels: EnumLabelsService;

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    let cols: TableColumnDefinitionInput[] = [
      {
        propertyName: 'sourceSystemRecordingDate',
        title: l10n.t('Recording date'),
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
        sortBy: 'RECORDING_DATE',
      },
      {
        propertyName: 'title',
        title: l10n.t('Title'),
        sortBy: 'TITLE',
      },
      {
        propertyName: 'alert.plantAsset.name',
        title: l10n.t('Plant asset'),
        sortBy: 'PLANT_ASSET',
      },
      {
        propertyName: 'alert.alarmType',
        title: l10n.t('Alarm type'),
        component: CellAlarmType,
        sortBy: 'ALARM_TYPE',
      },
      {
        propertyName: 'alert.externalId',
        title: l10n.t('Alarm external ID'),
        component: CellAlertExternalId,
        sortBy: 'ALARM_EXTERNAL_ID',
      },
      {
        propertyName: 'alert.alertLevel',
        title: l10n.t('Alarm level'),
        component: CellAlertLevel,
        sortBy: 'ALARM_LEVEL',
      },
      {
        propertyName: 'alert.sourceSystemFinishDate',
        title: l10n.t('Duration'),
        component: CellDuration,
        sortBy: 'DURATION',
      },
      {
        propertyName: '',
        title: '',
        disableSorting: true,
        component: CellActions,
        tdClass: 'mobile-order-first',
      },
    ];

    return cols;
  }

  downloadExcelTask = dropTask(async () => {
    let { plantAssetAlerts } = this.args;
    let { excel, l10n, enumLabels } = this;

    let rows = plantAssetAlerts.map((plantAssetAlert) => {
      let { id, sourceSystemRecordingDate, sourceSystemFinishDate, alert } =
        plantAssetAlert;

      let row: {
        id: string;
        sourceSystemRecordingDate: DateTime;
        sourceSystemFinishDate?: DateTime;
        title: string;
        plantAssetName: string;
        alarmType: string;
        alertLevel: string;
      } = {
        id,
        sourceSystemRecordingDate,
        sourceSystemFinishDate,
        plantAssetName: alert.plantAsset.name,
        title: alert.localLabel,
        alarmType: alert.alertType,
        alertLevel: enumLabels.plantAssetAlertLevel(alert.alertLevel),
      };

      return row;
    });

    let columns = [
      { header: l10n.t('ID'), id: 'id' },
      { header: l10n.t('Start date'), id: 'sourceSystemRecordingDate' },
      { header: l10n.t('Finish date'), id: 'sourceSystemFinishDate' },
      { header: l10n.t('Title'), id: 'title' },
      { header: l10n.t('Plant asset'), id: 'plantAssetName' },
      { header: l10n.t('Alert type'), id: 'alarmType' },
      { header: l10n.t('Alert level'), id: 'alertLevel' },
    ];

    let sheetConfig = {
      sheetName: l10n.t('Plant alarms'),
      autoFilter: true,
      columns,
      rows,
    };

    await excel.create(
      sheetConfig,
      `fabscale-plant-asset-alerts-${DateTime.local().toISODate()}.xlsx`
    );
  });
}
