import { EnumsBase } from './-base';

export const UnitsOrdinal = ['COUNT'] as const;
export type UnitOrdinal = typeof UnitsOrdinal[number];

export const UnitsWeight = ['KG', 'LB', 'GRAM'] as const;
export type UnitWeight = typeof UnitsWeight[number];

export const UnitsTime = ['SECOND', 'MILLISECOND', 'MINUTE', 'HOUR'] as const;
export type UnitTime = typeof UnitsTime[number];

export const UnitsTemperature = ['CELSIUS', 'FAHRENHEIT'] as const;
export type UnitTemperature = typeof UnitsTemperature[number];

export const UnitsSpeed = ['METER_PER_SECONDS', 'KILOMETER_PER_HOUR'] as const;
export type UnitSpeed = typeof UnitsSpeed[number];

// export const UnitsFrequency = ['RPM'] as const;
// export type UnitFrequency = typeof UnitsFrequency[number];

export const UnitsVolume = [
  'LITER',
  'CUBIC_METER',
  'HECTOLITER',
  'GALLON_US',
  'GALLON_UK',
] as const;
export type UnitVolume = typeof UnitsVolume[number];

export const UnitsVolumeFlowrate = [
  'LITER_PER_MINUTE',
  'CUBIC_METER_PER_HOUR',
] as const;
export type UnitVolumeFlowrate = typeof UnitsVolumeFlowrate[number];

export const UnitsPressure = ['DECIPASCAL', 'MILLIBAR'] as const;
export type UnitPressure = typeof UnitsPressure[number];

export const UnitsRatio = ['PERCENTAGE', 'PARTS_PER_MILLION'] as const;
export type UnitRatio = typeof UnitsRatio[number];

export const UnitsEnergy = ['KWH'] as const;
export type UnitEnergy = typeof UnitsEnergy[number];

export const UnitsMassFlowrate = [
  'KG_PER_HOUR',
  'LB_PER_HOUR',
  'GRAM_PER_HOUR',
] as const;
export type UnitMassFlowrate = typeof UnitsMassFlowrate[number];

export const UnitsDensity = ['GRAM_PER_LITER', 'GRAM_PER_MILLILITER'] as const;
export type UnitDensity = typeof UnitsDensity[number];

export const UnitsRoastColor = [
  'PROBAT_COLORETTE',
  'KONICA_MINOLTA',
  'AGTRON',
  'NEUHAUS_NEOTEC',
  'HUNTER_LAB',
] as const;
export type UnitRoastColor = typeof UnitsRoastColor[number];

export type Unit =
  | UnitOrdinal
  | UnitWeight
  | UnitTime
  | UnitTemperature
  | UnitSpeed
  | UnitVolume
  | UnitVolumeFlowrate
  | UnitPressure
  | UnitRatio
  | UnitEnergy
  | UnitMassFlowrate
  | UnitDensity
  | UnitRoastColor;

export type UnitType =
  | typeof UnitsOrdinal
  | typeof UnitsWeight
  | typeof UnitsTime
  | typeof UnitsTemperature
  | typeof UnitsSpeed
  | typeof UnitsVolume
  | typeof UnitsVolumeFlowrate
  | typeof UnitsPressure
  | typeof UnitsRatio
  | typeof UnitsEnergy
  | typeof UnitsMassFlowrate
  | typeof UnitsDensity
  | typeof UnitsRoastColor;

export class UnitsEnums extends EnumsBase<Unit> {
  constructor(...rest: any) {
    super(...rest);

    let labels: { [key in Unit]: string } = {
      COUNT: '',
      MILLISECOND: 'ms',
      SECOND: 's',
      MINUTE: 'm',
      HOUR: 'h',
      GRAM: 'g',
      KG: 'kg',
      LB: 'lb',
      CELSIUS: '°C',
      FAHRENHEIT: '°F',
      METER_PER_SECONDS: 'm/s',
      KILOMETER_PER_HOUR: 'km/h',
      // RPM: 'rpm',
      LITER: 'l',
      HECTOLITER: 'hl',
      CUBIC_METER: 'm³',
      GALLON_US: 'gal',
      GALLON_UK: 'gal',
      CUBIC_METER_PER_HOUR: 'm³/h',
      LITER_PER_MINUTE: 'l/h',
      DECIPASCAL: 'dPa',
      MILLIBAR: 'mbar',
      PERCENTAGE: '%',
      PARTS_PER_MILLION: 'ppm',
      KWH: 'kWh',
      KG_PER_HOUR: 'kg/h',
      GRAM_PER_HOUR: 'g/h',
      LB_PER_HOUR: 'lb/h',
      GRAM_PER_LITER: 'g/l',
      GRAM_PER_MILLILITER: 'g/ml',
      PROBAT_COLORETTE: 'Colorette',
      KONICA_MINOLTA: 'Minolta',
      AGTRON: 'Agtron',
      NEUHAUS_NEOTEC: 'Neotec',
      HUNTER_LAB: 'Hunter Lab',
    };

    this.labels = labels;
  }

  getLabel(unit: string) {
    return this.labels[unit];
  }
}
