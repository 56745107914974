import { DayOfWeek } from 'fabscale-app/models/enums/day-of-week';
import { UnitSystem } from 'fabscale-app/models/enums/unit-systems';
import { UnitRoastColor } from 'fabscale-app/models/enums/units';

export interface LocationSettingsPojo {
  dayStartTime: string;
  startDayOfWeek: DayOfWeek;
  unitSystem: UnitSystem;
  defaultRoastColorUnit?: UnitRoastColor;
  shiftEnabled: boolean;
  shiftSchedule?: string;
}

export interface LocationSettingsInput {
  dayStartTime: string;
  startDayOfWeek: DayOfWeek;
  unitSystem: UnitSystem;
  defaultRoastColorUnit?: UnitRoastColor;
}

export interface ShiftData {
  isEnabled: boolean;
  start: string;
  end: string;
}

export interface ShiftSchedule {
  morningShift?: ShiftData;
  afternoonShift?: ShiftData;
  nightShift?: ShiftData;
}

export interface LocationShiftSettingsPojo {
  shiftEnabled: boolean;
  shiftSchedule: ShiftSchedule;
}

export class Location {
  id: string;
  name: string;
  timezoneName: string;
  settings: LocationSettingsPojo;

  constructor(options: LocationInput) {
    this.id = options.id;
    this.name = options.name;
    this.timezoneName = options.timezoneName;
    this.settings = options.settings;
  }
}

export function cloneLocation(location: Location, overwriteProperties = {}) {
  let properties = {
    id: location.id,
    name: location.name,
    timezoneName: location.timezoneName,
    settings: location.settings,
  };

  return new Location(Object.assign(properties, overwriteProperties));
}

export interface LocationInput {
  id: string;
  name: string;
  timezoneName: string;
  settings: LocationSettingsPojo;
}
