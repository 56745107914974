import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';

interface Args {
  formModel: FormDataModel<any>;
  templateNameProperty: string;
}

export default class ModuleReportFiltersSaveTemplate extends Component<Args> {
  @tracked shouldSaveAsTemplate = false;

  get formData() {
    return this.args.formModel.data;
  }

  get templateName() {
    return this.formData[this.args.templateNameProperty];
  }

  @action
  toggleShouldSaveAsTemplate() {
    this.updateTemplateName('');
    this.shouldSaveAsTemplate = !this.shouldSaveAsTemplate;
  }

  @action
  updateTemplateName(name: string) {
    this.formData[this.args.templateNameProperty] = name;
  }
}
