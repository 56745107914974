import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  NumericRoastBatchParameter,
  RoastBatch,
} from 'fabscale-app/models/roast-batch';

export default function getNumericRoastBatchParameter(
  roastBatch: RoastBatch,
  parameterType: NumericRoastBatchParameterType
): NumericRoastBatchParameter | undefined {
  let { numericRoastBatchParameters } = roastBatch;

  return getNumericAmount(numericRoastBatchParameters, parameterType);
}

function getNumericAmount(
  parameters: NumericRoastBatchParameter[],
  type: NumericRoastBatchParameterType
): NumericRoastBatchParameter | undefined {
  let parameter = parameters.find(
    (parameter) => parameter.parameterType === type
  );

  return parameter || undefined;
}
