import { Interval } from 'fabscale-app/models/enums/intervals';
import { DateRange } from 'fabscale-app/models/date-range';
import { DateTime, DateTimeFormatOptions, DurationUnit } from 'luxon';

export function getDateFormatForInterval(interval: Interval) {
  let modeMap: { [key in Interval]: DateTimeFormatOptions } = {
    HOUR: { hour: 'numeric' },
    DAY: { day: 'numeric', month: 'numeric' },
    WEEK: { day: 'numeric', month: 'numeric' },
    MONTH: { month: 'short' },
    YEAR: { year: 'numeric' },
  };

  return modeMap[interval];
}

export function getAutoDateInterval(dateRange: DateRange): Interval {
  let { start, end } = dateRange;

  if (end.diff(start, 'months').months >= 3) {
    return 'MONTH';
  }

  if (end.diff(start, 'weeks').weeks >= 5) {
    return 'WEEK';
  }

  if (end.diff(start, 'hours').hours > 24) {
    return 'DAY';
  }

  return 'HOUR';
}

const intervalShiftMap: { [key in Interval]: DurationUnit } = {
  HOUR: 'hours',
  DAY: 'days',
  WEEK: 'weeks',
  MONTH: 'months',
  YEAR: 'years',
};

export function shiftDateByInterval(
  date: DateTime,
  interval: Interval,
  amount: number
) {
  let unit = intervalShiftMap[interval];

  return date.plus({ [unit]: amount });
}
