import { service } from '@ember/service';
import BaseAsyncResource from './base-async-resource';
import {
  AlarmTypeReportFilters,
  PlantAlarmOverTimeTimeSeries,
} from 'fabscale-app/models/alarm-type-report';
import StoreTypeLevelReportService from 'fabscale-app/services/store/type-level-report';

export default class PlantAlarmTypeResource extends BaseAsyncResource<
  PlantAlarmOverTimeTimeSeries[],
  AlarmTypeReportFilters
> {
  @service('store/type-level-report')
  alarmLevelReportStore: StoreTypeLevelReportService;

  loadData(filters: AlarmTypeReportFilters) {
    return this.alarmLevelReportStore.findPlantAlarmsTypeOvertime(filters);
  }
}
