import { service } from '@ember/service';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAssetInfoSummary } from 'fabscale-app/models/plant-asset-info-summary';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetInfoSummariesOptions {
  dateRange: DateRange;
  plantAssetIds: string[];
}

export default class LoadPlantAssetInfoSummariesResource extends BaseAsyncResource<
  PlantAssetInfoSummary[],
  LoadPlantAssetInfoSummariesOptions
> {
  @service('store/plant-asset-info') plantAssetInfo: StorePlantAssetInfoService;

  async loadData(options: LoadPlantAssetInfoSummariesOptions) {
    let { plantAssetIds, dateRange } = options;
    let filters = {
      dateRange,
      plantAssetIds,
    };

    let summaries = await this.plantAssetInfo.findSummaries(filters);
    return summaries.filter((summary) => summary.totalRuntime > 0);
  }
}
