import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import Service, { service } from '@ember/service';
import plantAlarmsTypeOvertimeQuery from 'fabscale-app/gql/queries/plant-alarms-type-overtime.graphql';
import GraphQLService from '../-graphql';
import EnumLabelsService from '../enum-labels';
import SettingsService from '../settings';
import UserSessionService from '../user-session';
import { serializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import {
  AlarmTypeReportFilters,
  PlantAlarmOverTimeTimeSeries,
} from 'fabscale-app/models/alarm-type-report';

export default class StoreTypeLevelReportService extends Service {
  @service l10n: L10nService;
  @service graphql: GraphQLService;
  @service enumLabels: EnumLabelsService;
  @service settings: SettingsService;
  @service userSession: UserSessionService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  async findPlantAlarmsTypeOvertime(
    filters: AlarmTypeReportFilters
  ): Promise<PlantAlarmOverTimeTimeSeries[]> {
    const { graphql, locationId } = this;

    const variables = {
      filters: {
        dateFrom: serializeDate(filters.dateRange!.start!),
        dateTo: serializeDate(filters.dateRange!.end!),
        alarmType: filters.alarmType,
        alarmExternalId: filters.alarmExternalIds,
        plantAssetIds: filters.plantAssetIds,
        recipeIds: filters.recipeIds,
        intervalDefinition: filters.intervalDefinition,
      },
      locationId: locationId,
    };

    return graphql.query({
      query: plantAlarmsTypeOvertimeQuery,
      variables,
      namespace: 'plantAlarmsTypeOvertime',
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/type-level-report': StoreTypeLevelReportService;
  }
}
