import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import ScreenService from 'fabscale-app/services/screen';

export default class IsMobileHelper extends Helper {
  @service screen: ScreenService;

  _callback: () => void;

  constructor(owner: any) {
    super(owner);

    let callback = () => this.recompute();
    this.screen.addWidthListener(callback);
    this._callback = callback;
  }

  willDestroy() {
    this.screen.removeWidthListener(this._callback);

    super.willDestroy();
  }

  compute() {
    return this.screen.hasMobileMenu;
  }
}
