import Transition from '@ember/routing/-private/transition';
import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import UserSessionService from 'fabscale-app/services/user-session';

export default class PlantShiftsRoute extends AuthenticatedRoute {
  @service userSession: UserSessionService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);
  }

  model() {
    return this.userSession.currentLocation;
  }
}
