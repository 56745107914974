import { buildWaiter } from '@ember/test-waiters';

let waiter = buildWaiter('promise-queue:waiter-1');

export function promiseQueue<T, R>(
  items: T[],
  callback: (item: T) => Promise<R> | R
): Promise<R[]> {
  return _promiseQueue([], items.slice(), callback);
}

async function _promiseQueue<T, R>(
  responses: R[],
  items: T[],
  callback: (item: T) => Promise<R> | R
): Promise<R[]> {
  if (items.length === 0) {
    return responses;
  }

  let item = items.shift();
  let token = waiter.beginAsync();

  try {
    let response = await callback(item!);
    responses.push(response);
  } catch (error) {
    waiter.endAsync(token);
    throw error;
  }

  waiter.endAsync(token);

  return _promiseQueue(responses, items, callback);
}
