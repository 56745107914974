import { assert } from '@ember/debug';
import { UnitsTime, UnitTime } from 'fabscale-app/models/enums/units';

const FACTOR_MAP: { [key in UnitTime]: number } = {
  MILLISECOND: 0.001,
  SECOND: 1,
  MINUTE: 60,
  HOUR: 60 * 60,
};

export default function formatDurationHelper(
  amount: number,
  unit: UnitTime,
  options?: { expectHours: boolean }
) {
  assert(
    `{{format-duration}}: You have to pass a number as first parameter, but passed "${amount}"`,
    typeof amount === 'number'
  );
  assert(
    `{{format-duration}}: You have to pass a valid duration unit as second parameter, but passed "${unit}"`,
    UnitsTime.includes(unit)
  );

  let factor = FACTOR_MAP[unit] || 1;
  let remainingAmount = amount * factor;

  let hours = Math.floor(remainingAmount / 60 / 60);
  remainingAmount -= hours * 60 * 60;

  let minutes = Math.floor(remainingAmount / 60);
  remainingAmount -= minutes * 60;

  let seconds = Math.floor(remainingAmount);

  if (hours === 0 && !options?.expectHours) {
    return `${padNumber(minutes)}:${padNumber(seconds)}`;
  }

  return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
}

function padNumber(number: number) {
  if (number < 10) {
    return `0${number}`;
  }

  return `${number}`;
}
