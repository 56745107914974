import { DateTime } from 'luxon';

export function dateIsRelative(date: DateTime): boolean {
  let today = DateTime.local();
  let diff = Math.round(
    today.startOf('day').diff(date.startOf('day'), 'days').days
  );

  // We cover: today, yesterday, tomorrow
  return Math.abs(diff) <= 1;
}

export function formatDateRelative(
  date: DateTime,
  { withTime = false }: { withTime?: boolean } = {}
): string {
  let day = date.toRelativeCalendar({ unit: 'days' });

  if (withTime) {
    return `${day}, ${date.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }

  return day!;
}
