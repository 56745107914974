import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import CognitoService from 'fabscale-app/services/cognito';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import {
  InvalidPasswordError,
  VerificationCodeExpiredError,
  VerificationCodeMismatchError,
} from 'fabscale-app/models/errors/cognito';

interface Args {
  username: string;
  verificationCode?: string;
}

export default class ResetPasswordSetNewPassword extends Component<Args> {
  @service cognito: CognitoService;
  @service l10n: L10nService;
  @service userAuthentication: UserAuthenticationService;

  formData: ResetPasswordFormData;
  formModel: FormDataModel<ResetPasswordFormData>;

  get isUpdatePasswordButtonDisabled(): boolean {
    const {
      formModel: { isInvalid, hasChanges },
    } = this;
    return !hasChanges || isInvalid;
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    let { l10n } = this;

    this.formData = new ResetPasswordFormData();
    this.formData.username = this.args.username;
    this.formData.verificationCode = this.args.verificationCode || '';

    assert(
      '<SetNewPassword>: @username is required to be passed in',
      !!this.args.username
    );

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'newPassword',
          message: l10n.t('The password must not be blank.'),
          validate: (value) => value,
        },
        {
          propertyName: 'newPassword',
          message: l10n.t('The password must be at least 8 characters long.'),
          validate: (value) => value && value.length >= 8,
        },
        {
          propertyName: 'verificationCode',
          message: l10n.t('The verification code must be 6 characters long.'),
          validate: (value) => value.length === 6,
        },
      ],
    });
  }

  @action
  updateVerificationCode(verificationCode: string) {
    this.formModel.updateProperty(
      'verificationCode',
      verificationCode.replace(/[^0-9]/g, '')
    );
  }

  @action
  updateNewPassword(newPassword: string) {
    this.formModel.updateProperty('newPassword', newPassword, {
      debounced: true,
    });
  }

  setNewPasswordTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { cognito, userAuthentication } = this;
    let { username, verificationCode, newPassword } = this.formData;

    try {
      await cognito.updateResetPassword({
        username,
        code: verificationCode,
        newPassword,
      });

      this.userAuthentication.afterLoginMessage = this.l10n.t(
        'Your password was updated'
      );

      await userAuthentication.login({ username, password: newPassword });
    } catch (error) {
      this._handleError(error);
    }
  });

  _handleError(error: any) {
    let { l10n } = this;

    if (error instanceof VerificationCodeMismatchError) {
      this.formModel.addError(
        l10n.t('The provided verification code is incorrect.'),
        'verificationCode'
      );
      return;
    }

    if (error instanceof VerificationCodeExpiredError) {
      this.formModel.addError(
        l10n.t(
          'The provided verification code has expired. Please request a new one.'
        ),
        'verificationCode'
      );
      return;
    }

    if (error instanceof InvalidPasswordError) {
      this.formModel.addError(
        l10n.t(
          'The provided password is invalid - it needs to be at least 8 characters long.'
        ),
        'newPassword'
      );
      return;
    }

    this.formModel.addError(error.message || l10n.t('An error occurred!'));
  }
}

class ResetPasswordFormData {
  @tracked username: string;
  @tracked verificationCode: string;
  @tracked newPassword: string;
}
