import { service } from '@ember/service';
import BaseAsyncResource from './base-async-resource';
import StoreAlarmLevelReportService from 'fabscale-app/services/store/alarm-level-report';
import {
  PlantAlarmFilters,
  PlantAlarmSummary,
} from 'fabscale-app/models/alarm-report';

export default class PlantAlarmSummaryResource extends BaseAsyncResource<
  PlantAlarmSummary[],
  PlantAlarmFilters
> {
  @service('store/alarm-level-report')
  alarmLevelReportStore: StoreAlarmLevelReportService;

  loadData(filters: PlantAlarmFilters) {
    return this.alarmLevelReportStore.findPlantAlarmsSummaryGrouped(filters);
  }
}
