import Component from '@glimmer/component';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';

export type MaintenanceTaskDetailPageTab = 'AREA' | 'RELATED_TASKS';

interface Args {
  task: MaintenanceTask;
  details?: MaintenanceTaskDetailPageTab;
}

export default class MaintenanceTasksDetailPageIndex extends Component<Args> {
  get hasPlantAssetAreaOrPreviousTask() {
    return Boolean(
      this.args.task.plantAssetArea || this.args.task.previousMaintenanceTask
    );
  }

  get showUpdates() {
    return !this.hasPlantAssetAreaOrPreviousTask || !this.args.details;
  }

  get showDetailsArea() {
    return Boolean(
      this.args.task.plantAssetArea && this.args.details === 'AREA'
    );
  }

  get showDetailsRelatedTasks() {
    return (
      this.hasPlantAssetAreaOrPreviousTask &&
      this.args.details === 'RELATED_TASKS'
    );
  }
}
