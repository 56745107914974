import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { DateRange } from 'fabscale-app/models/date-range';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import { PlantAssetInfoSummaryGrouped } from 'fabscale-app/services/store/plant-asset-info';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import * as _ from 'lodash-es';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';

export default class StatusSummariesStackedBarChartDataHelper extends Helper<{
  PositionalArgs: [
    summaries: PlantAssetInfoSummaryGrouped[],
    dateRange: DateRange
  ];
}> {
  @service enumLabels: EnumLabelsService;
  @service l10n: L10nService;

  compute([summaries, dateRange]: [PlantAssetInfoSummaryGrouped[], DateRange]) {
    if (!summaries) {
      return [];
    }

    const grouped = Object.values(_.groupBy(summaries, 'plantAsset.id'));

    const mapped = grouped.map((group, index) => {
      const prettifiedGroup = group.map((groupItem) => {
        let prettifiedStatus = this.enumLabels.plantAssetStatus(
          groupItem.status
        );

        if (groupItem.status === 'STANDBY_LACK_OF_RAW_MATERIAL') {
          prettifiedStatus = this.l10n.t('Standby (LORM)');
        } else if (groupItem.status === 'STANDBY_TRANSPORT_BLOCKED') {
          prettifiedStatus = this.l10n.t('Standby (TP)');
        }

        return {
          label: groupItem.plantAsset?.name,
          value: groupItem.runtimeInHours,
          unit: 'HOUR',
          status: prettifiedStatus,
          dateRange,
        };
      });

      return {
        data: prettifiedGroup,
        backgroundColor: chartColors[index],
        barThickness: 48,
        parsing: {
          xAxisKey: 'status',
          yAxisKey: 'value',
        },
      };
    });

    return {
      datasets: mapped,
    };
  }
}
