import Component from '@glimmer/component';
import { scheduleOnce, later } from '@ember/runloop';
import { action } from '@ember/object';
import { focusElement } from 'fabscale-app/utilities/utils/dom/focus-element';
import { service } from '@ember/service';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  select: any;
  onKeydown: (event: Event) => false | void;
}

export default class UiSelectBeforeOptions extends Component<Args> {
  @service screen: ScreenService;

  @action
  clearSearch(): void {
    scheduleOnce('actions', this.args.select.actions, 'search', '');
  }

  @action
  handleInput(value: string) {
    this.args.select.actions.search(value);
  }

  @action
  handleKeydown(event: KeyboardEvent): false | void {
    if (this.args.onKeydown(event) === false) {
      return false;
    }

    if (event.key === 'Enter') {
      this.args.select.actions.close(event);
    }
  }

  @action
  focusInput(el: HTMLElement) {
    if (this.screen.isMobile) {
      return;
    }

    later(() => {
      focusElement(el);
    }, 0);
  }
}
