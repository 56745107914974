import { service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import { ReportType } from 'fabscale-app/models/enums/template';
import StoreTemplate, { ITemplate } from 'fabscale-app/services/store/template';
import { tracked } from '@glimmer/tracking';
import SharedDropdownBase from '../../base/dropdown-base';
import { assert } from '@ember/debug';

export default class SharedDropdownTemplate extends SharedDropdownBase<ITemplate> {
  @service('store/template')
  templateService: StoreTemplate;

  @tracked _templates: ITemplate[];

  override get options() {
    return this._templates;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.loadAvailableTemplatesTask.perform();
  }

  private loadAvailableTemplatesTask = restartableTask(async () => {
    const { reportType } = this.args;

    assert('SharedDropdownTemplate missing ReportType argument', !!reportType);
    assert(
      'wrong reportType value',
      (Object.values(ReportType) as string[]).includes(reportType)
    );

    if (reportType) {
      this._templates = await this.templateService.findTemplates(reportType);
    }
  });
}
