import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import RouteInfo from '@ember/routing/-private/route-info';
import RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import { MaintenanceTask } from 'fabscale-app/models/maintenance-task';
import UserSessionService from 'fabscale-app/services/user-session';
import { uniq } from 'fabscale-app/utilities/utils/array';
import { sentryCaptureException } from 'fabscale-app/utilities/utils/sentry-capture-exception';

export enum TaskOverviewPage {
  My = 'my',
  Open = 'open',
  Completed = 'completed',
  Calendar = 'calendar',
}

export type TaskCalendarQuickFilter = undefined | 'MY_OPEN' | 'ALL_COMPLETED';

export const TASK_QUICK_SEARCH_COUNT = 5;

export default class MaintenanceTaskManagerService extends Service {
  @service l10n: L10nService;
  @service userSession: UserSessionService;
  @service router: RouterService;

  constructor(...rest: any) {
    super(...rest);

    this._loadRecentTasks();
  }

  // The url to go "back" to for tasks
  @tracked previousRouteInfo?: RouteInfo;

  @tracked calendarPlantAssetIds?: string[];
  @tracked calendarQuickFilter: TaskCalendarQuickFilter = undefined;

  get previousPageData(): {
    label: string;
    routeName: string;
    model?: any;
    queryParams: Record<string, any>;
  } {
    let { previousRouteInfo } = this;

    if (!previousRouteInfo) {
      return {
        routeName: 'routes/maintenance.tasks.index',
        model: undefined,
        queryParams: {},
        label: this.l10n.t('Tasks'),
      };
    }

    let { name, queryParams } = previousRouteInfo;
    let models = getModels([], previousRouteInfo);
    let label = this.getRouteLabel(name);

    return {
      label,
      routeName: name,
      queryParams,
      model: models.length > 0 ? models[0] : undefined,
    };
  }

  goToPreviousPage() {
    let { previousPageData } = this;

    if (this.previousPageData.model) {
      this.router.transitionTo(
        previousPageData.routeName,
        previousPageData.model,
        {
          queryParams: previousPageData.queryParams,
        }
      );
    } else {
      this.router.transitionTo(previousPageData.routeName, {
        queryParams: previousPageData.queryParams,
      });
    }
  }

  capturePreviousPage(routeInfo: RouteInfo | null) {
    if (!routeInfo) {
      this.previousRouteInfo = undefined;
      return;
    }

    // To avoid infinite loops of "back",
    // we ignore it when coming from another detail page
    // We also want to ignore the application_loading page here
    if (
      [
        'routes/maintenance.tasks.show.index',
        'application_loading',
        'routes/maintenance.tasks.create',
        'routes/maintenance.tasks.show.edit',
      ].includes(routeInfo.name)
    ) {
      return;
    }

    this.previousRouteInfo = routeInfo;
  }

  getRouteLabel(routeName: string): string {
    let { l10n } = this;

    if (routeName === 'routes/maintenance.tasks.my') {
      return l10n.t('My tasks');
    }

    if (routeName === 'routes/maintenance.tasks.open') {
      return l10n.t('Open tasks');
    }

    if (routeName === 'routes/maintenance.tasks.completed') {
      return l10n.t('Completed tasks');
    }

    if (routeName.startsWith('routes/maintenance.tasks.calendar')) {
      return l10n.t('Task calendar');
    }

    if (routeName === 'routes/dashboard.index') {
      return l10n.t('Dashboard');
    }

    return l10n.t('Previous page');
  }

  // Recent tasks
  recentTaskIds: string[] = [];

  get _namespace() {
    let { user } = this.userSession;

    if (!user || isTesting()) {
      return undefined;
    }

    return `${user.id}-maintenance-tasks`;
  }

  _loadRecentTasks() {
    let { _namespace } = this;

    if (!_namespace) {
      this.recentTaskIds = [];
      return;
    }

    try {
      let json = window.localStorage.getItem(_namespace) || '[]';
      let recentTaskIds: string[] = JSON.parse(json);
      if (!Array.isArray(recentTaskIds)) {
        throw new Error('recentTaskIds has to be an error');
      }

      this.recentTaskIds = recentTaskIds;
    } catch (error) {
      sentryCaptureException(error);
      this.recentTaskIds = [];
    }
  }

  _saveRecentTasks() {
    let { _namespace } = this;

    if (!_namespace) {
      return;
    }

    try {
      let json = JSON.stringify(this.recentTaskIds);
      window.localStorage.setItem(_namespace, json);
    } catch (error) {
      sentryCaptureException(error);
    }
  }

  viewedTask(task: MaintenanceTask) {
    let recentTaskIds = this.recentTaskIds.slice();
    let { id } = task;

    recentTaskIds.unshift(id);
    recentTaskIds = uniq(recentTaskIds).slice(0, TASK_QUICK_SEARCH_COUNT);
    this.recentTaskIds = recentTaskIds;

    this._saveRecentTasks();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'maintenance-task-manager': MaintenanceTaskManagerService;
  }
}

function getModels(models: any[], routeInfo: RouteInfo): any[] {
  if (routeInfo.parent) {
    getModels(models, routeInfo.parent);
  }

  routeInfo.paramNames.forEach((paramName) => {
    models.push(routeInfo.params[paramName]);
  });

  return models;
}
