import { DateTimeString } from 'fabscale-app';
import { Locale } from 'fabscale-app/models/enums/locales';
import { UserStatus } from 'fabscale-app/models/enums/user-status';
import {
  deserializeOptionalDate,
  serializeOptionalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';
import { UserInfo } from './user-info';
import { UserRole, UserRoleInput } from './user-role';

export class User extends UserInfo {
  // id & name inherited
  email: string;
  locale: Locale;
  role: UserRole;
  authenticationInfo: { status: UserStatus; isMfaEnabled: boolean };
  creationDate?: DateTime;
  frontendSettings?: UserFrontendSettingsInput;
  locations: { id: string; name: string }[];

  constructor(options: UserInput) {
    super({ id: options.id, name: options.name });

    this.email = options.email;
    this.locale = options.locale || 'en';
    this.role = new UserRole(options.role);
    this.authenticationInfo = options.authenticationInfo || {
      status: 'CONFIRMED',
      isMfaEnabled: false,
    };
    this.frontendSettings = options.frontendSettings;
    this.creationDate = deserializeOptionalDate(options.creationDate);
    this.locations = options.locations || [];
  }
}

export function cloneUser(user: User, overwriteProperties = {}) {
  let properties = {
    id: user.id,
    name: user.name,
    email: user.email,
    locale: user.locale,
    role: user.role,
    authenticationInfo: user.authenticationInfo,
    locations: user.locations,
    creationDate: serializeOptionalDate(user.creationDate),
  };

  return new User(Object.assign(properties, overwriteProperties));
}

export interface UserFrontendSettingsInput {
  dashboardConfig?: string;
  kpiDataFilterTemplates?: string;
  navigationConfig?: string;
  tableConfig?: string;
}

export interface UserInput {
  id: string;
  name: string;
  email: string;
  locale?: Locale;
  role: UserRoleInput;
  authenticationInfo?: { status: UserStatus; isMfaEnabled: boolean };
  creationDate?: DateTimeString;
  frontendSettings?: UserFrontendSettingsInput;
  locations?: { id: string; name: string }[];
}

export interface UserLocalStorage {
  email: string | undefined;
  locationId: string | undefined;
  locale: Locale | undefined;
}
