import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  registerAPI?: (dd: any) => void;
  onOpen?: (dd: any, event: Event) => void;
}

export default class UiBasicDropdownAccessible extends Component<Args> {
  @tracked didOpenWithKeyboard = false;

  @action
  registerAPI(dd: any) {
    if (this.args.registerAPI) {
      this.args.registerAPI(dd);
    }
  }

  @action
  didOpenDropdown(dd: any, event: Event) {
    this.didOpenWithKeyboard = event instanceof KeyboardEvent;

    if (this.args.onOpen) {
      return this.args.onOpen(dd, event);
    }
  }
}
