export type RootNavigations = 'reports' | 'maintenance';

type DefaultNavigationRoutes = {
  [key in RootNavigations]: string | null;
};

export type DefaultNavigationConfiguration = DefaultNavigationRoutes;

export function defaultNavigationConfiguration(): DefaultNavigationConfiguration {
  return {
    reports: null,
    maintenance: null,
  };
}
