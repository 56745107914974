import { assert } from '@ember/debug';
import { DateRange } from 'fabscale-app/models/date-range';
import { TimeRange } from 'fabscale-app/models/time-range';
import { getExactDate } from 'fabscale-app/utilities/utils/date-range';
import { DateTime } from 'luxon';

export default function getExactDateRangeHelper({
  dateRange,
  timeRange,
  maxNow = false,
}: {
  dateRange: DateRange;
  timeRange: TimeRange;
  maxNow?: boolean;
}) {
  assert(
    `{{get-exact-date-range}}: You have to provide {dateRange}`,
    !!dateRange
  );

  assert(
    `{{get-exact-date-range}}: You have to provide {timeRange}`,
    !!timeRange
  );

  let start = getExactDate(dateRange.start, timeRange.start);
  let end = getExactDate(dateRange.end, timeRange.end);

  if (maxNow) {
    let now = DateTime.local();
    if (end.valueOf() > now.valueOf()) {
      end = now;
    }
  }

  return new DateRange({ start, end });
}
