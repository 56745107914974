import { service } from '@ember/service';
import BaseAsyncResource from './base-async-resource';
import StoreAlarmLevelReportService from 'fabscale-app/services/store/alarm-level-report';
import {
  PlantAlarmFilters,
  PlantAlarmsPerRecipe,
} from 'fabscale-app/models/alarm-report';

export default class PlantAlarmPerRecipeResource extends BaseAsyncResource<
  PlantAlarmsPerRecipe[],
  PlantAlarmFilters
> {
  @service('store/alarm-level-report')
  alarmLevelReportStore: StoreAlarmLevelReportService;

  loadData(filters: PlantAlarmFilters) {
    return this.alarmLevelReportStore.findPlantAlarmPerRecipe(filters);
  }
}
