import transition from '@ember/routing/-private/transition';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import PlantAssetAlertsIndexController from './controller';

export default class PlantAssetAlertsIndexRoute extends AuthenticatedRoute {
  resetController(
    controller: PlantAssetAlertsIndexController,
    isExiting: boolean,
    transition: transition<unknown>
  ): void {
    if (isExiting) {
      controller.dateRange = undefined;
      controller.timeRange = undefined;
      controller.plantAssets = undefined;
      controller.alarmLevels = undefined;
      controller.alarmTypes = undefined;
      controller.alarmTitle = undefined;
      controller.page = undefined;
      controller.pageSize = undefined;
      controller.sortBy = undefined;
      controller.sortDirection = undefined;
    }

    return super.resetController(controller, isExiting, transition);
  }
}
