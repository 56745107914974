import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAssetInfoSummary } from 'fabscale-app/models/plant-asset-info-summary';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetInfoSummaryOptions {
  dateRange: DateRange;
  plantAssetId: string;
}

export default class LoadPlantAssetInfoSummaryResource extends BaseAsyncResource<
  PlantAssetInfoSummary,
  LoadPlantAssetInfoSummaryOptions
> {
  @service('store/plant-asset-info') plantAssetInfo: StorePlantAssetInfoService;

  async loadData(options: LoadPlantAssetInfoSummaryOptions) {
    let { plantAssetId, dateRange } = options;

    assert(
      '{{resources/load-plant-asset-info-summary}}: You have to pass a plantAssetId',
      !!plantAssetId
    );

    let filters = {
      dateRange,
      plantAssetId,
    };

    return this.plantAssetInfo.getUptimeSummary(filters);
  }
}
