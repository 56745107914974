import Service from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import { restartableTask, task, timeout } from 'ember-concurrency';
import { removeItem } from 'fabscale-app/utilities/utils/array';

export type ScreenCallback = (width: number) => void;

export default class ScreenService extends Service {
  @tracked _width?: number;
  _callbacks: ScreenCallback[] = [];
  _resizeObserver?: any;

  _documentElement = document.documentElement || document.body;
  _canUseResizeObserver = 'ResizeObserver' in window;

  get width(): number {
    if (this._width) {
      return this._width;
    }

    this._setupResizeObserver();
    return this._calculateWidth();
  }

  get widthCategory() {
    let { width } = this;

    if (width > 1920) {
      return 'xlarge';
    }

    if (width >= 1300) {
      return 'large';
    }

    if (width >= 800) {
      return 'medium';
    }

    if (width >= 380) {
      return 'small';
    }

    return 'xsmall';
  }

  get hasMobileMenu() {
    // Ensure consistent tests...
    if (macroCondition(isTesting())) {
      return false;
    }

    return this.width < 1100;
  }

  get isMobile() {
    // Ensure consistent tests...
    if (macroCondition(isTesting())) {
      return false;
    }

    return this.width < 480;
  }

  willDestroy(): void {
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
    }

    super.willDestroy();
  }

  addWidthListener(callback: ScreenCallback): void {
    this._setupResizeObserver();
    this._callbacks.push(callback);
  }

  removeWidthListener(callback: ScreenCallback): void {
    removeItem(this._callbacks, callback);
  }

  _setupResizeObserver(): void {
    if (!this._canUseResizeObserver || this._resizeObserver) {
      return;
    }

    // Build a global resize observer, to recompute this if screen size changes
    let resizeObserver = new window.ResizeObserver(() => {
      this._calculateWidth();
    });

    resizeObserver.observe(this._documentElement);
    this._resizeObserver = resizeObserver;
  }

  _calculateWidth(): number {
    let width = this._documentElement.clientWidth;

    this._saveWidthTask.perform(width);
    this._triggerCallbacksTask.perform(width);

    return width;
  }

  _saveWidthTask = task(async (width: number) => {
    await timeout(1);

    if (width !== this._width) {
      this._width = width;
    }
  });

  _triggerCallbacksTask = restartableTask(async (width: number) => {
    await timeout(1);

    this._callbacks.forEach((callback) => {
      callback(width);
    });
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    screen: ScreenService;
  }
}
