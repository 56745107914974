import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import { keepLatestTask, timeout } from 'ember-concurrency';
import CognitoService from './cognito';
import UserAuthenticationService from './user-authentication';

interface BackgroundJob {
  id: string;
  intervalSeconds: number;
  callback: () => void;
}

export default class BackgroundJobService extends Service {
  @service cognito: CognitoService;
  @service userAuthentication: UserAuthenticationService;

  jobs: BackgroundJob[] = [];

  /**
   * This is an array, where each array position is either undefined (do nothing) or a job to execute
   * Then, it will wait for a second, and move to the next position, etc.
   * The next tick to look at is always the last one.
   */
  ticks: (BackgroundJob | undefined)[] = [];

  registerJob(job: BackgroundJob) {
    assert(
      `registerJob: A job must have an id, but you passed ${JSON.stringify(
        job
      )}.`,
      typeof job === 'object' && !!job.id
    );
    assert(
      `registerJob: A job must have a numeric intervalSeconds, but you passed ${JSON.stringify(
        job
      )}.`,
      typeof job === 'object' && typeof job.intervalSeconds === 'number'
    );
    assert(
      `registerJob: A job must have a callback, but you passed ${JSON.stringify(
        job
      )}.`,
      typeof job === 'object' && typeof job.callback === 'function'
    );

    assert(
      `A job with the id ${job.id} is already registered!`,
      !this.jobs.some((existingJob) => existingJob.id === job.id)
    );

    this.jobs.push(job);
    this._enqueueJob(job);
  }

  unregisterJob(jobId: string) {
    let jobIndex = this.jobs.findIndex((job) => job.id === jobId);

    assert(
      `unregisterJob: Could not find job with id ${jobId}.`,
      jobIndex > -1
    );

    if (jobIndex > -1) {
      let jobs = this.jobs.slice();
      jobs.splice(jobIndex, 1);
      this.jobs = jobs;
    }

    // Remove from all existing ticks
    for (let i = 0; i < this.ticks.length; i++) {
      let tick = this.ticks[i];

      if (tick?.id === jobId) {
        this.ticks[i] = undefined;
      }
    }
  }

  executeNextTick() {
    let tick = this.ticks[this.ticks.length - 1];

    // If tick is empty, just remove it and wait for next one
    if (!tick) {
      this.ticks.pop();
      return;
    }

    // Else, only remove it & execute it if page is currently visible
    // Otherwise, wait until it becomes visible to execute next tick
    if (this._pageIsActive() && this._userSessionIsActive()) {
      this.ticks.pop();
      tick.callback();
      this._enqueueJob(tick);
    } else {
      // remove next empty tick, until there are only queued jobs left
      // Otherwise, jobs further down the line will remain more ticks away than needed
      let lastEmptyPos = this.ticks.lastIndexOf(undefined);

      if (lastEmptyPos > -1) {
        this.ticks.splice(lastEmptyPos, 1);
      }
    }
  }

  tickTask = keepLatestTask(async () => {
    await timeout(1000);

    this.executeNextTick();

    this.tickTask.perform();
  });

  _enqueueJob(job: BackgroundJob) {
    let { intervalSeconds } = job;
    let { ticks } = this;

    // Ensure we have enough ticks added
    if (ticks.length < intervalSeconds) {
      let diff = intervalSeconds - ticks.length;
      let newTicks = new Array(diff);
      newTicks.fill(undefined);
      ticks = newTicks.concat(ticks);
    }

    let pos = ticks.length - intervalSeconds;
    putJobIntoTicks(ticks, job, pos);

    this.ticks = ticks;

    if (macroCondition(!isTesting())) {
      this.tickTask.perform();
    }
  }

  _pageIsActive() {
    return macroCondition(isTesting())
      ? true
      : document.visibilityState === 'visible';
  }

  /**
   * Ensure we don't try to run background jobs if the session is expired in the meanwhile
   * If the access token needs refreshing, ensure we do that before continuing.
   */
  _userSessionIsActive() {
    if (!this.userAuthentication.isAuthenticated) {
      return false;
    }

    if (this.cognito.cognitoTokenNeedsRefresh()) {
      this.cognito.refreshAccessTokenTask.perform();
      return false;
    }

    return true;
  }
}

function putJobIntoTicks(
  ticks: (undefined | BackgroundJob)[],
  job: BackgroundJob,
  position: number
) {
  // Try exact position
  let atTick = ticks[position];

  if (atTick === undefined) {
    ticks[position] = job;
    return;
  }

  // If position is 0, add it as new first item
  if (position === 0) {
    ticks.unshift(job);
    return;
  }

  // Else, try position before
  putJobIntoTicks(ticks, job, position - 1);
}
