import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ScreenService, { ScreenCallback } from 'fabscale-app/services/screen';

export default class IsMobileHelper extends Helper {
  @service screen: ScreenService;

  _callback?: ScreenCallback;

  @tracked isMobile = false;

  constructor(owner: any) {
    super(owner);

    this.isMobile = this.screen.isMobile;

    let callback = () => {
      if (this.isMobile !== this.screen.isMobile) {
        this.isMobile = this.screen.isMobile;
      }
    };

    this.screen.addWidthListener(callback);
    this._callback = callback;
  }

  willDestroy() {
    if (this._callback) {
      this.screen.removeWidthListener(this._callback);
    }

    super.willDestroy();
  }

  compute() {
    return this.isMobile;
  }
}
