import { schedule } from '@ember/runloop';
import { AuthenticatedRoute } from 'fabscale-app/routes/base/-base-authenticated-route';
import ReportsKpiCompareIndexController from './controller';

export default class ReportsKpiCompareIndexRoute extends AuthenticatedRoute {
  resetController(
    controller: ReportsKpiCompareIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      // Without the schedule part here, it leads to weird issues
      // with the arguments changing for components right before they are destroyed
      // This could be seen e.g. when setting filters, then going to the dashboard
      schedule('afterRender', () => {
        controller.settings = undefined;
        controller.tableId = undefined;
      });
    }
  }
}
