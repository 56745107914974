import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import {
  formatTimeString,
  formatTimeStringToIso,
} from 'fabscale-app/utilities/utils/parse-time';

interface Args {
  value?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
}

export default class UiTimeInput extends Component<Args> {
  @tracked _value?: string;

  get value() {
    if (typeof this._value !== 'undefined') {
      return this._value;
    }

    return this.args.value;
  }

  get formattedTime() {
    return this.value ? formatTimeString(this.value) : '';
  }

  @action
  updateTime(dayStartTime: string): void {
    let previousValue = this.args.value;

    let startTimeIsoTimeString;

    try {
      startTimeIsoTimeString = formatTimeStringToIso(dayStartTime);
    } catch (error) {
      this._resetStartTimeOfDayTask.perform();

      return;
    }

    // Ensure it changes back if we have changed it, but the new iso value is the same as before
    if (startTimeIsoTimeString === previousValue) {
      this._resetStartTimeOfDayTask.perform();
    }

    this.args.onChange(startTimeIsoTimeString);
  }

  @action
  selectInputText(event: FocusEvent) {
    (event.target as HTMLInputElement).select();
  }

  _resetStartTimeOfDayTask = task(async () => {
    // To ensure the input visually changes back, we need to force-trigger a change of the value
    this._value = '';

    await timeout(1);

    this._value = undefined;
  });
}
