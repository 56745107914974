import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import StoreUserRoleService from 'fabscale-app/services/store/user-role';

export default class SettingsRolesShowRoute extends AuthenticatedRoute {
  @service('store/user-role') userRoleStore: StoreUserRoleService;

  model(params: { id: string }) {
    return this.userRoleStore.findById(params.id);
  }
}
