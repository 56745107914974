import { DateTimeString } from 'fabscale-app';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateRange } from './date-range';
import { ComparativeValue } from './comparative-value';

export class KpiData {
  type: KpiType;
  value: number;
  dateRange: DateRange;
  unit: Unit;
  higherIsBetter: boolean;
  comparativeValue?: ComparativeValue;

  constructor({
    type,
    value,
    dateFrom,
    dateTo,
    unit,
    higherIsBetter,
    comparativeValue,
  }: KpiDataInput) {
    this.type = type;
    this.value = value;

    this.dateRange = new DateRange({
      start: deserializeDate(dateFrom),
      end: deserializeDate(dateTo),
    });

    this.unit = unit;
    this.higherIsBetter = higherIsBetter;
    this.comparativeValue = comparativeValue;
  }
}

interface KpiDataInput {
  type: KpiType;
  value: number;
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
  unit: Unit;
  higherIsBetter: boolean;
  comparativeValue?: ComparativeValue;
}
