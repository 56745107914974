import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import ScreenService from 'fabscale-app/services/screen';
import SettingsService from 'fabscale-app/services/settings';
import { MaintenanceTaskQueryOptions } from 'fabscale-app/services/store/maintenance-task';
import { DateTime } from 'luxon';

interface Args {
  date: DateTime;
  query: MaintenanceTaskQueryOptions;
}

export default class TaskCalendarMonth extends Component<Args> {
  @service settings: SettingsService;
  @service screen: ScreenService;

  get isCompact() {
    return this.screen.hasMobileMenu;
  }

  get startDayOfWeek() {
    return this.settings.locationSettings.startDayOfWeek;
  }

  get groupByCompletionDate() {
    return this.args.query.status === 'COMPLETED';
  }

  @cached
  get today() {
    return DateTime.local().startOf('day');
  }
}
