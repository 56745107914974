import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { KpiDetailsReportFilters } from 'fabscale-app/components/page/kpi-details-report';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import {
  deserializeArray,
  serializeArray,
} from 'fabscale-app/utilities/utils/serialize-array';
import {
  deserializeDateRange,
  serializeDateRange,
} from 'fabscale-app/utilities/utils/serialize-date-range';

export default class ReportsKpiDetailsController extends Controller {
  queryParams = ['kpiType', 'dateRange', 'timeRange', 'plantAssets', 'recipes'];

  @tracked kpiType?: KpiType;
  @tracked dateRange?: string;
  @tracked timeRange?: string;
  @tracked plantAssets?: string;
  @tracked recipes?: string;

  get filters(): KpiDetailsReportFilters {
    return {
      kpiType: this.kpiType,
      dateRange: this.parsedDateRange,
      timeRange: this.parsedTimeRange,
      plantAssetIds: this.parsedPlantAssetIds,
      recipeIds: this.parsedRecipeIds,
    };
  }

  get parsedDateRange() {
    return this.dateRange ? deserializeDateRange(this.dateRange) : undefined;
  }

  get parsedTimeRange() {
    return this.timeRange ? JSON.parse(this.timeRange) : undefined;
  }

  get parsedPlantAssetIds() {
    return this.plantAssets ? deserializeArray(this.plantAssets) : [];
  }

  get parsedRecipeIds() {
    return this.recipes ? deserializeArray(this.recipes) : [];
  }

  @action
  updateFilters(filters: KpiDetailsReportFilters) {
    let { kpiType, plantAssetIds, recipeIds, dateRange, timeRange } = filters;

    this.kpiType = kpiType;
    this.dateRange = dateRange ? serializeDateRange(dateRange) : undefined;
    this.timeRange = timeRange ? JSON.stringify(timeRange) : undefined;
    this.plantAssets =
      plantAssetIds.length > 0 ? serializeArray(plantAssetIds) : undefined;
    this.recipes = recipeIds.length > 0 ? serializeArray(recipeIds) : undefined;
  }

  resetFilters() {
    this.kpiType = undefined;
    this.dateRange = undefined;
    this.timeRange = undefined;
    this.plantAssets = undefined;
    this.recipes = undefined;
  }
}
