import { action } from '@ember/object';
import Component from '@glimmer/component';
import { RoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { removeItem, sortByList } from 'fabscale-app/utilities/utils/array';

interface Args {
  selectedRoastBatchParameterTypes: RoastBatchParameterType[];
  availableRoastBatchParameterTypes: RoastBatchParameterType[];
  close: () => void;
  updateSelectedRoastBatchParameterTypes: (
    selectedRoastBatchParameterTypes: RoastBatchParameterType[]
  ) => void;
}

export default class RoastBatchesOverviewSelectColumns extends Component<Args> {
  _lastSelectedType?: RoastBatchParameterType;

  @action
  toggleColumn(
    roastBatchParameterType: RoastBatchParameterType,
    isSelected: boolean,
    { shiftKey }: { shiftKey?: boolean }
  ) {
    let selectedRoastBatchParameterTypes =
      this.args.selectedRoastBatchParameterTypes.slice();

    this._toggleColumn(
      selectedRoastBatchParameterTypes,
      roastBatchParameterType,
      isSelected
    );

    // Select all items between last selection & current selection if pressing shift
    if (shiftKey && this._lastSelectedType) {
      let { availableRoastBatchParameterTypes } = this.args;
      let index = availableRoastBatchParameterTypes.indexOf(
        roastBatchParameterType
      );
      let lastIndex = availableRoastBatchParameterTypes.indexOf(
        this._lastSelectedType
      );

      let start = Math.min(index, lastIndex);
      let end = Math.max(index, lastIndex);

      for (let i = start; i <= end; i++) {
        this._toggleColumn(
          selectedRoastBatchParameterTypes,
          availableRoastBatchParameterTypes[i]!,
          isSelected
        );
      }

      // Prevent text from being selected
      document.getSelection()?.removeAllRanges();
    }

    // Ensure the selected list is consistently sorted
    let sortedParameterTypes = sortByList(
      selectedRoastBatchParameterTypes,
      this.args.availableRoastBatchParameterTypes
    );

    this.args.updateSelectedRoastBatchParameterTypes(sortedParameterTypes);

    this._lastSelectedType = roastBatchParameterType;
  }

  _toggleColumn(
    selectedRoastBatchParameterTypes: RoastBatchParameterType[],
    roastBatchParameterType: RoastBatchParameterType,
    isSelected: boolean
  ) {
    if (
      isSelected &&
      !selectedRoastBatchParameterTypes.includes(roastBatchParameterType)
    ) {
      selectedRoastBatchParameterTypes.push(roastBatchParameterType);
    }

    if (
      !isSelected &&
      selectedRoastBatchParameterTypes.includes(roastBatchParameterType)
    ) {
      removeItem(selectedRoastBatchParameterTypes, roastBatchParameterType);
    }
  }
}
