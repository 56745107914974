import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';

export default class ResetPasswordRoute extends Route {
  @service userAuthentication: UserAuthenticationService;
  @service router: RouterService;

  beforeModel() {
    if (this.userAuthentication.isAuthenticated) {
      this.router.transitionTo('routes/index');
    }
  }
}
