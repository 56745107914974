import Component from '@glimmer/component';
import { DateRange } from 'fabscale-app/models/date-range';
import { DateTime } from 'luxon';

interface Signature {
  Args: {
    date: DateTime;
    plantAssetId?: string;
    updateDate: (date: DateTime | undefined) => void;
    updatePlantAssetId: (plantAssetId: string) => void;
  };
}

export default class PagePlantAssetStatusMapIndex extends Component<Signature> {
  get dateRange() {
    return new DateRange({
      start: this.args.date.startOf('day'),
      end: this.args.date.endOf('day'),
    });
  }
}
