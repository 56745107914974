import { EnumsBase } from './-base';

export const PlantAssetStatusReasons = [
  'OTHER',
  'SCHEDULED_MAINTENANCE',
  'GENERAL_OTHER_ASSET',
  'GENERAL_OPERATING_ERROR',
  'GENERAL_MECHANICAL',
  'GENERAL_PRESSURE',
  'GENERAL_TEMPERATURE',
  'GENERAL_ELECTRICAL',
  'GENERAL_SOFTWARE',
] as const;

export type PlantAssetStatusReason = typeof PlantAssetStatusReasons[number];

export class PlantAssetStatusReasonEnums extends EnumsBase<PlantAssetStatusReason> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in PlantAssetStatusReason]: string } = {
      OTHER: l10n.t('Other'),
      SCHEDULED_MAINTENANCE: l10n.t('Scheduled maintenance'),
      GENERAL_OTHER_ASSET: l10n.t('Problem with other asset'),
      GENERAL_OPERATING_ERROR: l10n.t('Operating error'),
      GENERAL_MECHANICAL: l10n.t('Mechanical issue'),
      GENERAL_PRESSURE: l10n.t('Pressure issue'),
      GENERAL_TEMPERATURE: l10n.t('Temperature issue'),
      GENERAL_ELECTRICAL: l10n.t('Electrical issue'),
      GENERAL_SOFTWARE: l10n.t('Software issue'),
    };

    this.labels = labels;
  }
}
