import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type DashboardDateOption =
  | 'LAST_24H'
  | 'TODAY'
  | 'YESTERDAY'
  | 'THIS_WEEK'
  | 'LAST_WEEK';

export default class DashboardIndexController extends Controller {
  @tracked dateOption: DashboardDateOption = 'LAST_24H';

  queryParams = ['dateOption'];

  @action
  updateDateOption(option: DashboardDateOption) {
    this.dateOption = option;
  }
}
