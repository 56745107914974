import { DateTimeString } from 'fabscale-app';
import { Location, LocationInput } from 'fabscale-app/models/location';
import {
  deserializeDate,
  deserializeOptionalDate,
} from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export type AdminLocationInput = LocationInput & {
  lastHeartbeat?: DateTimeString;
  cloudConnectorInfo?: CloudConnectorInfoInput;
};

interface CloudConnectorInfoInput {
  version: string;
  status: string;
  lastModifiedDate: DateTimeString;
  lastRoastBatchDate?: DateTimeString;
  jobs: {
    name: string;
    percentage?: number;
    status: string;
    plantAssets: {
      id: string;
      name: string;
    }[];
  }[];
}

interface CloudConnectorInfo {
  version: string;
  status: string;
  lastModifiedDate: DateTime;
  lastRoastBatchDate?: DateTime;
  jobs: {
    name: string;
    percentage?: number;
    status: string;
    plantAssets: {
      id: string;
      name: string;
    }[];
  }[];
}

export class AdminLocation extends Location {
  lastHeartbeat?: DateTime;
  cloudConnectorInfo?: CloudConnectorInfo;

  constructor(options: AdminLocationInput) {
    super(options);

    this.lastHeartbeat = deserializeOptionalDate(options.lastHeartbeat);
    this.cloudConnectorInfo = options.cloudConnectorInfo
      ? deserializeCloudConnectorInfo(options.cloudConnectorInfo)
      : undefined;

    if (this.cloudConnectorInfo) {
      this.lastHeartbeat = this.cloudConnectorInfo.lastModifiedDate;
    }
  }
}

function deserializeCloudConnectorInfo(
  input: CloudConnectorInfoInput
): CloudConnectorInfo {
  return Object.assign({}, input, {
    lastModifiedDate: deserializeDate(input.lastModifiedDate),
    lastRoastBatchDate: deserializeOptionalDate(input.lastRoastBatchDate),
  });
}
