import SharedDropdownBase from 'fabscale-app/components/shared/base/dropdown-base';

export const CoffeeTypes: string[] = ['GROUND', 'GREEN', 'ROASTED'];
export type CoffeeType = typeof CoffeeTypes[number];

export default class SharedDropdownMultiselectCoffeeType extends SharedDropdownBase<CoffeeType> {
  override get options() {
    return CoffeeTypes;
  }
}
