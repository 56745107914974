import { service } from '@ember/service';
import BaseAsyncResource from './base-async-resource';
import StoreAlarmLevelReportService from 'fabscale-app/services/store/alarm-level-report';
import {
  PlantAlarmsOverTimeFilters,
  PlantAlarmOverTime,
} from 'fabscale-app/models/alarm-report';

export default class PlantAlarmOvertimeResource extends BaseAsyncResource<
  PlantAlarmOverTime[],
  PlantAlarmsOverTimeFilters
> {
  @service('store/alarm-level-report')
  alarmLevelReportStore: StoreAlarmLevelReportService;

  loadData(filters: PlantAlarmsOverTimeFilters) {
    return this.alarmLevelReportStore.findPlantAlarmsOvertime(filters);
  }
}
