export function parseVersionNumeric(version: string): number {
  let versionParts = version.split('.');
  let versionNumberString = versionParts
    .map((part) => {
      return part.length === 2 ? part : `0${part}`;
    })
    .join('');

  return parseInt(versionNumberString);
}
