import { EnumsBase } from './-base';

export type RoastBatchStatus =
  | 'COMPLETED'
  | 'FAILED'
  | 'VALIDATION_FAILED'
  | 'IN_PROGRESS';

export class RoastBatchStatusEnums extends EnumsBase<RoastBatchStatus> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in RoastBatchStatus]: string } = {
      COMPLETED: l10n.t('Completed'),
      FAILED: l10n.t('Failed'),
      VALIDATION_FAILED: l10n.t('Validation failed'),
      IN_PROGRESS: l10n.t('In progress'),
    };

    this.labels = labels;
  }
}
