import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  options: any[];
  placeholder?: string;
  onChange: (value: any) => void;
}

export default class UiSelectDropdown extends Component<Args> {
  @tracked didOpenWithKeyboard = false;

  _dropdown?: any;

  get radioOptions() {
    let { options, placeholder } = this.args;

    let radioOptions: { value: any; label?: string }[] = options.map(
      (option) => {
        return { value: option };
      }
    );

    if (placeholder) {
      radioOptions.unshift({ value: null, label: placeholder });
    }

    return radioOptions;
  }

  @action
  didOpenDropdown(dropdown: any, event: Event) {
    this._dropdown = dropdown;
    this.didOpenWithKeyboard = event instanceof KeyboardEvent;
  }

  @action
  onChange(newValue: any) {
    if (this._dropdown && !this.didOpenWithKeyboard) {
      this._dropdown.actions.close();
    }

    this.args.onChange(newValue);
  }
}
