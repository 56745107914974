import { service } from '@ember/service';
import { PlantAssetArea } from 'fabscale-app/models/plant-asset-area';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetAreasOptions {
  plantAssetId: string;
}

export default class LoadPlantAssetAreasResource extends BaseAsyncResource<
  PlantAssetArea[],
  LoadPlantAssetAreasOptions
> {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  async loadData(options: LoadPlantAssetAreasOptions) {
    let plantAsset = await this.plantAssetStore.findById(options.plantAssetId);

    return plantAsset.areas || [];
  }
}
