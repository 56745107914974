import Component from '@glimmer/component';
import { ChartData, ChartOptions } from 'chart.js';

interface Args {
  data: ChartData;
  options: ChartOptions;
}

export default class PageKpiDetailsReportOverallOverTimeChart extends Component<Args> {
  get data() {
    const { data } = this.args;

    return data;
  }

  get options() {
    const { options } = this.args;

    return options;
  }
}
