import { compare } from '@ember/utils';
import { DateTimeString } from 'fabscale-app';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { Unit } from 'fabscale-app/models/enums/units';
import {
  ComparativeValue,
  ComparativeValueInput,
} from 'fabscale-app/models/comparative-value';
import { DateBinValue } from 'fabscale-app/models/date-bin-value';
import {
  GroupBinValue,
  GroupBinValueInput,
} from 'fabscale-app/models/group-bin-value';
import { KpiData } from 'fabscale-app/models/kpi-data';
import { KpiDataGrouped } from 'fabscale-app/models/kpi-data-grouped';
import { KpiDataOverTime } from 'fabscale-app/models/kpi-data-over-time';
import {
  sortBy,
  sortByPropertyInList,
} from 'fabscale-app/utilities/utils/array';

export interface KpiDataPojo {
  type: KpiType;
  value: number;
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
  unit: Unit;
  higherIsBetter: boolean;
  comparativeValue?: ComparativeValueInput;
}

export interface KpiDataGroupedPojo {
  type: KpiType;
  higherIsBetter: boolean;
  unit: Unit;
  values: GroupBinValueInput[];
}

export interface KpiDataOverTimePojo {
  type: KpiType;
  unit: Unit;
  values: DateBinValuePojo[];
  higherIsBetter: boolean;
}

interface DateBinValuePojo {
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
  value: number;
}

export function transformKpiData({
  type,
  value,
  dateFrom,
  dateTo,
  unit,
  higherIsBetter,
}: KpiDataPojo) {
  return new KpiData({
    type,
    value,
    dateFrom,
    dateTo,
    unit,
    higherIsBetter,
  });
}

export function transformKpiDataGrouped({
  data,
  groupIds,
}: {
  data: KpiDataGroupedPojo;
  groupIds?: string[];
}): KpiDataGrouped {
  // If groupIds are given, ensure the return order is the same
  // Else, sort by name
  let originalValues = data.values;

  let filteredValues = groupIds?.length
    ? originalValues.filter((item) => groupIds.includes(item.group.id))
    : originalValues;

  let groups = filteredValues.map((item) => new GroupBinValue(item));

  let values = groupIds?.length
    ? sortByPropertyInList(groups, groupIds, 'id')
    : sortBy(groups, 'name');

  return new KpiDataGrouped(Object.assign({}, data, { values }));
}

export function transformKpiDataOverTime({
  values,
  type,
  unit,
  higherIsBetter,
}: KpiDataOverTimePojo): KpiDataOverTime {
  let dateBinValues = values
    .map((dateBinValue) => transformDateBinValueData(dateBinValue))
    .sort((a, b) =>
      compare(a.dateRange.start.valueOf(), b.dateRange.start.valueOf())
    );

  return new KpiDataOverTime({ unit, type, dateBinValues, higherIsBetter });
}

export function generateComparativeValue(
  kpiData: KpiData,
  comparativeKpiData: KpiData
) {
  let { higherIsBetter } = kpiData;
  let previousValue = comparativeKpiData.value;
  let tendency = calculateTendency(previousValue, kpiData.value);

  if (typeof tendency === 'undefined') {
    kpiData.comparativeValue = new ComparativeValue({
      tendency: 0,
      previousValue,
      tendencyIsPositive: true,
    });
    return;
  }

  let tendencyIsPositive = checkTendencyIsPositive(tendency, higherIsBetter);

  kpiData.comparativeValue = new ComparativeValue({
    tendency,
    previousValue,
    tendencyIsPositive,
  });
}

function transformDateBinValueData({
  dateFrom,
  dateTo,
  value,
}: DateBinValuePojo): DateBinValue {
  return new DateBinValue({
    value,
    dateFrom,
    dateTo,
  });
}

export function checkTendencyIsPositive(
  tendency: number,
  higherIsBetter: boolean
) {
  return (
    (higherIsBetter && tendency >= 0) || (!higherIsBetter && tendency <= 0)
  );
}

export function calculateTendency(lastValue: number, currentValue: number) {
  if (lastValue === currentValue) {
    return 0;
  }

  if (lastValue === 0) {
    return undefined;
  }

  if (currentValue === 0) {
    return lastValue > 0 ? -1 : 1;
  }

  return currentValue / lastValue - 1;
}
