import { assert } from '@ember/debug';
import { TimeInterval } from 'fabscale-app/models/repeat-config';
import { shiftDateByInterval } from 'fabscale-app/utilities/utils/date-interval';
import { DateTime } from 'luxon';

export default function estimateTimeDuedateHelper(
  startDate: DateTime,
  timeInterval: TimeInterval
): DateTime | undefined {
  assert('Repeat config has no timeInterval', !!timeInterval);

  let { amount, interval } = timeInterval;

  return shiftDateByInterval(startDate, interval, amount);
}
