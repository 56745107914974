import { service } from '@ember/service';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetOptions {
  plantAssetId: string;
}

export default class LoadPlantAssetResource extends BaseAsyncResource<
  PlantAsset,
  LoadPlantAssetOptions
> {
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  loadData(options: LoadPlantAssetOptions) {
    return this.plantAssetStore.findById(options.plantAssetId);
  }
}
