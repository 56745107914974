import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import StoreParameterGoalService from 'fabscale-app/services/store/parameter-goal';

export default class PlantParameterGoalsShowRoute extends AuthenticatedRoute {
  @service('store/parameter-goal')
  parameterGoalStore: StoreParameterGoalService;

  model(params: { id: string }) {
    let { parameterGoalStore } = this;
    let { id } = params;

    return parameterGoalStore.findById(id);
  }
}
