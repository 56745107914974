import Service, { service } from '@ember/service';
import { Locale } from 'fabscale-app/models/enums/locales';
import resetMfaMutation from 'fabscale-app/gql/admin/mutations/user-reset-mfa.graphql';
import setTemporaryPasswordMutation from 'fabscale-app/gql/admin/mutations/user-set-temporary-password.graphql';
import updateLocationsForUserMutation from 'fabscale-app/gql/admin/mutations/update-user-locations.graphql';
import userByEmailQuery from 'fabscale-app/gql/admin/queries/user-by-email.graphql';
import { Company } from 'fabscale-app/models/company';
import { User } from 'fabscale-app/models/user';
import { UserRoleInput } from 'fabscale-app/models/user-role';
import GraphQLService from 'fabscale-app/services/-graphql';

export default class StoreUserAdminService extends Service {
  @service graphql: GraphQLService;

  // METHODS
  async resetMfa(userId: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      userId,
    };

    await graphql.query({
      query: resetMfaMutation,
      variables,
    });
  }

  async setTemporaryPassword(userId: string, password: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      userId,
      password,
    };

    await graphql.query({
      query: setTemporaryPasswordMutation,
      variables,
    });
  }

  async getUserByEmail(email: string) {
    let { graphql } = this;

    let variables = {
      email,
    };

    let response: {
      id: string;
      email: string;
      name: string;
      locale: Locale;
      role: UserRoleInput;
      locations: { id: string; name: string }[];
      company: {
        id: string;
        name: string;
        features: { name: string }[];
        locations: { id: string; name: string }[];
      };
    } = await graphql.query({
      query: userByEmailQuery,
      variables,
      namespace: 'userByEmail',
    });

    let { id, name, locale, role, locations } = response;
    let user = new User({ id, name, email, locale, role });

    let companyData = Object.assign({}, response.company, {
      features: response.company.features.map(
        (feature: { name: string }) => feature.name
      ),
    });
    let company = new Company(companyData);

    return {
      user,
      company,
      locations,
      companyLocations: companyData.locations,
    };
  }

  async updateLocationsForUser({
    userId,
    locationIds,
  }: {
    userId: string;
    locationIds: string[];
  }): Promise<void> {
    let { graphql } = this;

    let variables = {
      userId,
      locationIds,
    };

    await graphql.mutate(
      {
        mutation: updateLocationsForUserMutation,
        variables,
      },
      {
        invalidateCache: [{ cacheEntity: 'User' }],
      }
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/user-admin': StoreUserAdminService;
  }
}
