import { DateRange } from './date-range';
import { TimeRange } from './time-range';
import { tracked } from '@glimmer/tracking';
import { RelativeTimeframe } from './enums/relative-timeframe';
import { PlantAssetAlertLevel } from './enums/plant-asset-alert-level';
import { DateTime } from 'luxon';
import { Interval } from './enums/intervals';
import { DayOfWeek } from './enums/day-of-week';
import { DateTimeString } from 'fabscale-app';
import { IdNamePair } from './common';
import { PlantAssetAlarmType } from 'fabscale-app/models/enums/plant-asset-alarm-type';

export interface AlarmLevelReportFilters {
  dateRange: DateRange;
  timeRange: TimeRange;
  alarmLevels?: PlantAssetAlertLevel[];
  plantAssetIds?: string[];
  recipeIds?: string[];
}

export interface PlantAlarmFilters {
  dateFrom: DateTime;
  dateTo: DateTime;
  plantAssetIds?: string[];
  recipeIds?: string[];
  alarmLevels?: PlantAssetAlertLevel[];
  alarmType?: PlantAssetAlarmType;
  alarmExternalIds?: string[];
  intervalDefinition?: {
    interval: Interval;
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    timezoneName: string;
  };
}

export interface PlantAlarmsOverTimeFilters {
  dateFrom: DateTime;
  dateTo: DateTime;
  plantAssetIds?: string[];
  recipeIds?: string[];
  intervalDefinition: {
    interval: Interval;
    startDayOfWeek: DayOfWeek;
    dayStartTime: string;
    timezoneName: string;
  };
}

export class AlarmLevelReportFormData {
  @tracked dateRange: DateRange;
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked timeRange: TimeRange;
  @tracked templateName = '';
  @tracked alarmLevels?: PlantAssetAlertLevel[];
  @tracked plantAssetIds?: string[];
  @tracked recipeIds?: string[];
}

export interface PlantAlarmSummary {
  alarmLevel: PlantAssetAlertLevel;
  alarmCount: number;
}

export interface PlantAlarmOverTimeTimeSeries {
  dateValueTimeSeries: {
    dateFrom: DateTimeString;
    dateTo: DateTimeString;
    value: number;
  }[];
  idNamePair: IdNamePair;
}

export interface PlantAlarmOverTime {
  alarmLevel: PlantAssetAlertLevel;
  alarmOverTimeItems: PlantAlarmOverTimeTimeSeries[];
}

export interface PlantAlarmsPerRecipe {
  recipeIdNamePair: IdNamePair;
  value: number;
}
