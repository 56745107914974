import { axisLeft, axisRight } from 'd3-axis';
import { AxisConfigNumbers } from 'fabscale-app/components/chart';

export default function chartYAxis(
  axisConfig: AxisConfigNumbers,
  { position = 'left' }: { position?: 'left' | 'right' } = {}
) {
  let { scale, ticks, tickFormat } = axisConfig;

  return (position === 'right' ? axisRight(scale) : axisLeft(scale))
    .tickSizeInner(6)
    .tickSizeOuter(0)
    .tickValues(ticks)
    .tickFormat(tickFormat);
}
