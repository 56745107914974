import Service, { service } from '@ember/service';
import { Permission } from 'fabscale-app/models/enums/permissions';
import createCompanyMutation from 'fabscale-app/gql/admin/mutations/create-company.graphql';
import deleteCompanyMutation from 'fabscale-app/gql/admin/mutations/delete-company.graphql';
import createUserRoleMutation from 'fabscale-app/gql/admin/mutations/create-user-role.graphql';
import allCompaniesQuery from 'fabscale-app/gql/admin/queries/all-companies.graphql';
import companyByIdQuery from 'fabscale-app/gql/admin/queries/company-by-id.graphql';
import {
  AdminCompany,
  AdminCompanyInput,
} from 'fabscale-app/models/admin-company';
import { UserRole, UserRoleInput } from 'fabscale-app/models/user-role';
import GraphQLService from 'fabscale-app/services/-graphql';

export default class StoreCompanyService extends Service {
  @service graphql: GraphQLService;

  // METHODS
  async findById(id: string): Promise<AdminCompany> {
    let { graphql } = this;

    let variables = {
      id,
    };

    let response: AdminCompanyInput = await graphql.query({
      query: companyByIdQuery,
      variables,
      namespace: 'companyById',
    });

    return new AdminCompany(response);
  }

  async findAll(): Promise<AdminCompany[]> {
    let { graphql } = this;

    let variables = {
      filters: {},
    };

    let response: AdminCompanyInput[] = await graphql.query({
      query: allCompaniesQuery,
      variables,
      namespace: 'internal_companiesList',
    });

    return response.map((companyData) => new AdminCompany(companyData));
  }

  async create(options: {
    name: string;
  }): Promise<{ id: string; name: string }> {
    let { graphql } = this;

    let variables = {
      name: options.name,
    };

    let response: { id: string; name: string } = await graphql.mutate({
      mutation: createCompanyMutation,
      variables,
      namespace: 'internal_createCompany',
    });

    return response;
  }

  async delete(id: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate({
      mutation: deleteCompanyMutation,
      variables,
    });
  }

  async createUserRole({
    name,
    permissions,
    companyId,
  }: {
    name: string;
    permissions: Permission[];
    companyId: string;
  }): Promise<UserRole> {
    let { graphql } = this;

    let variables = {
      input: { name, permissions },
      companyId,
    };

    let userRole: UserRoleInput = await graphql.mutate(
      {
        mutation: createUserRoleMutation,
        variables,
        namespace: 'internal_createUserRole',
      },
      {
        invalidateCache: [{ cacheEntity: 'UserRole' }],
      }
    );

    return new UserRole(userRole);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/company': StoreCompanyService;
  }
}
