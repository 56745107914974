import { Ability } from 'ember-can';
import { service } from '@ember/service';
import UserSessionService from 'fabscale-app/services/user-session';

export default class RoastBatchAbility extends Ability {
  @service userSession: UserSessionService;

  get canView() {
    return Boolean(this.userSession.permissionMap.ROAST_BATCH_VIEW);
  }

  get canEdit() {
    return Boolean(this.userSession.permissionMap.ROAST_BATCH_MANAGE);
  }
}
