import { FormDataModel } from 'fabscale-app/models/form-data';

export default function formModelUpdateProperty(
  formModel: FormDataModel<any>,
  propertyName: string,
  emptyIsUndefined?: boolean
) {
  return (value?: any) => {
    let actualValue = emptyIsUndefined && !value ? undefined : value;
    formModel.updateProperty(propertyName, actualValue);
  };
}
