import { service } from '@ember/service';
import { Interval } from 'fabscale-app/models/enums/intervals';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { DateRange } from 'fabscale-app/models/date-range';
import { PlantAssetInfoSummaryOverTime } from 'fabscale-app/models/plant-asset-info-summary';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';
import { promiseQueue } from 'fabscale-app/utilities/utils/promise-queue';
import BaseAsyncResource from './base-async-resource';

interface LoadPlantAssetInfoSummariesOverTimeOptions {
  dateRange: DateRange;
  interval: Interval;
  plantAssetIds: string[];
  plantAssetStatus: PlantAssetStatus[];
}

export default class LoadKpiDataOverTimeResource extends BaseAsyncResource<
  PlantAssetInfoSummaryOverTime[],
  LoadPlantAssetInfoSummariesOverTimeOptions
> {
  @service('store/plant-asset-info')
  plantAssetInfoStore: StorePlantAssetInfoService;

  async loadData({
    dateRange,
    interval,
    plantAssetIds,
    plantAssetStatus,
  }: LoadPlantAssetInfoSummariesOverTimeOptions) {
    let { plantAssetInfoStore } = this;

    let fetchOptions = {
      dateRange,
      interval,
      plantAssetIds,
    };

    let data = await promiseQueue(plantAssetStatus, (plantAssetStatus) =>
      loadPlantAssetInfoSummariesOverTime(
        plantAssetInfoStore,
        plantAssetStatus,
        fetchOptions
      )
    );

    return data.filter((summary) => summary.bins.some((bin) => bin.value > 0));
  }
}

export function loadPlantAssetInfoSummariesOverTime(
  plantAssetInfoStore: StorePlantAssetInfoService,
  plantAssetStatus: PlantAssetStatus,
  {
    dateRange,
    interval,
    plantAssetIds,
  }: {
    dateRange: DateRange;
    interval: Interval;
    plantAssetIds: string[];
  }
) {
  return plantAssetInfoStore.getSummariesOverTime(plantAssetStatus, {
    dateRange,
    plantAssetIds,
    interval,
  });
}
