import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { PageDef, PageInfo, SortDef } from 'fabscale-app';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import mutationCreate from 'fabscale-app/gql/mutations/create-roast-batch-parameter-goal.graphql';
import mutationDelete from 'fabscale-app/gql/mutations/delete-roast-batch-parameter-goal.graphql';
import mutationUpdate from 'fabscale-app/gql/mutations/update-roast-batch-parameter-goal.graphql';
import queryFindById from 'fabscale-app/gql/queries/roast-batch-parameter-goal-by-id.graphql';
import queryPaginated from 'fabscale-app/gql/queries/roast-batch-parameter-goals-paginated.graphql';
import queryCount from 'fabscale-app/gql/queries/roast-batch-parameter-goals-count.graphql';
import {
  ParameterGoal,
  ParameterGoalInput,
  ParameterGoalPojo,
} from 'fabscale-app/models/parameter-goal';
import GraphQLService from 'fabscale-app/services/-graphql';
import UserSessionService from '../user-session';

export interface PaginatedParameterGoals {
  pageInfo: PageInfo;
  parameterGoals: ParameterGoal[];
}

export default class StoreParameterGoalService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service graphql: GraphQLService;

  get locationId() {
    return this.userSession.currentLocation!.id;
  }

  // METHODS
  async findById(id: string): Promise<ParameterGoal> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let parameterGoal: ParameterGoalPojo = await graphql.query(
        {
          query: queryFindById,
          variables,
          namespace: 'roastBatchParameterGoalById',
        },
        {
          cacheEntity: 'ParameterGoal',
          cacheId: id,
          cacheSeconds: 300,
        }
      );

      return new ParameterGoal(parameterGoal);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The parameter goal with ID {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async queryPaginated(
    filters: {
      search?: string;
      parameterType?: NumericRoastBatchParameterType;
    },
    pageDef: PageDef,
    { sortBy, sortDirection }: SortDef
  ): Promise<PaginatedParameterGoals> {
    let { graphql, locationId } = this;

    assert(
      `parameterGoalStore.queryPaginated: You have to specify sortBy`,
      !!sortBy
    );
    assert(
      `parameterGoalStore.queryPaginated: You have to specify sortDirection`,
      !!sortDirection
    );

    let variables = {
      pageDef,
      sortBy,
      sortDirection,
      filters: Object.assign({ locationId }, filters),
    };

    let response: {
      items: ParameterGoalPojo[];
      pageInfo: PageInfo;
    } = await graphql.query(
      {
        query: queryPaginated,
        variables,
        namespace: 'roastBatchParameterGoalsPaginated',
      },
      {
        cacheSeconds: 10,
        cacheEntity: 'ParameterGoal',
      }
    );

    return {
      parameterGoals: response.items.map((item) => new ParameterGoal(item)),
      pageInfo: response.pageInfo,
    };
  }

  async queryCount(): Promise<number> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
    };

    let response: {
      pageInfo: PageInfo;
    } = await graphql.query(
      {
        query: queryCount,
        variables,
        namespace: 'roastBatchParameterGoalsPaginated',
      },
      {
        cacheSeconds: 10,
        cacheEntity: 'ParameterGoal',
      }
    );

    return response.pageInfo.totalItemCount;
  }

  async create({
    minValue,
    maxValue,
    parameterType,
    unit,
    recipeIds,
    plantAssetIds,
  }: ParameterGoalInput): Promise<{ id: string }> {
    let { graphql, locationId } = this;

    let variables = {
      locationId,
      input: {
        minValue,
        maxValue,
        parameterType,
        unit,
        recipeIds,
        plantAssetIds,
      },
    };

    let { id }: { id: string } = await graphql.mutate(
      {
        mutation: mutationCreate,
        variables,
        namespace: 'createRoastBatchParameterGoal',
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'ParameterGoal',
          },
        ],
      }
    );

    return { id };
  }

  async update(
    id: string,
    {
      minValue,
      maxValue,
      parameterType,
      unit,
      recipeIds,
      plantAssetIds,
    }: ParameterGoalInput
  ): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
      input: {
        minValue,
        maxValue,
        parameterType,
        unit,
        recipeIds,
        plantAssetIds,
      },
    };

    await graphql.mutate(
      {
        mutation: mutationUpdate,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'ParameterGoal',
          },
          {
            cacheEntity: 'ParameterGoal',
            cacheId: id,
          },
        ],
      }
    );
  }

  async delete(id: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
    };

    await graphql.mutate(
      {
        mutation: mutationDelete,
        variables,
      },
      {
        invalidateCache: [
          {
            cacheEntity: 'ParameterGoal',
          },
          {
            cacheEntity: 'ParameterGoal',
            cacheId: id,
          },
        ],
      }
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/parameter-goal': StoreParameterGoalService;
  }
}
