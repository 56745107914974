import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { getOwnConfig, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, timeout } from 'ember-concurrency';
import CognitoService from 'fabscale-app/services/cognito';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StoreUserService from 'fabscale-app/services/store/user';
import UserAuthenticationService from 'fabscale-app/services/user-authentication';
import UserSessionService from 'fabscale-app/services/user-session';
import { GraphqlDb } from 'fabscale-app/utils/mock-graphql/-config/graphql-db';

interface Args {
  impersonateUserEmail?: string;
}

export default class Debug extends Component<Args> {
  @service cognito: CognitoService;
  @service userSession: UserSessionService;
  @service userAuthentication: UserAuthenticationService;
  @service('store/user') userStore: StoreUserService;
  @service('error-parser') errorParser: ErrorParserService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.impersonateEmail = args.impersonateUserEmail;
  }

  @tracked impersonateEmail?: string;
  @tracked error?: string;

  get jwtToken() {
    return this.cognito.cognitoData!.jwtToken;
  }

  get user() {
    return this.userSession.user;
  }

  get company() {
    return this.userSession.company;
  }

  get locations() {
    return this.userSession.locations;
  }

  get apiExplorerUrl() {
    let domainParts = window.location.host.split('.');
    let domainEnd = domainParts[2] || 'cc';
    return `https://api.fabscale.${domainEnd}/v1/explorer/index.html?accessToken=${this.jwtToken}`;
  }

  get cognitoUserId() {
    // @ts-ignore
    return this.cognito.cognitoData?.cognitoUser?.username;
  }

  @action
  updateImpersonateEmail(email: string) {
    this.impersonateEmail = email;
  }

  impersonateUserTask = dropTask(async () => {
    let { impersonateEmail, userStore } = this;

    if (!impersonateEmail) {
      return;
    }

    this.error = undefined;

    try {
      await userStore.impersonateUser(impersonateEmail);

      // Wait for LS to be saved
      await timeout(1);
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      return;
    }

    window.location.reload();
  });

  @action
  async resetMockDb() {
    if (macroCondition(getOwnConfig<any>().mockGraphqlApi)) {
      let owner = getOwner(this) as any & { graphqlDb: GraphqlDb };

      await owner.graphqlDb.clear();
      window.location.reload();
    }
  }
}
