import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class DashboardCustomizeController extends Controller {
  @tracked newCardId?: number;

  queryParams = ['newCardId'];

  get newCardIdNum() {
    return typeof this.newCardId === 'string'
      ? parseInt(this.newCardId)
      : this.newCardId;
  }

  @action
  onUpdateCards() {
    this.newCardId = undefined;
  }
}
