import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { filterRecords } from 'fabscale-app/utilities/utils/filter-records';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import AnalyticsService from 'fabscale-app/services/analytics';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import CellActions from 'fabscale-app/components/page/kpi-compare-report/manage-templates/cells/actions';

export default class KpiCompareReportManageTemplates extends Component {
  @service kpiDataFilter: KpiDataFilterService;
  @service l10n: L10nService;
  @service analytics: AnalyticsService;

  @tracked searchTerm?: string;
  @tracked allCustomTemplates: KpiDataFilterTemplate[];
  columns: TableColumnDefinitionInput[];

  get filteredTemplates() {
    let { allCustomTemplates, searchTerm } = this;

    if (!searchTerm) {
      return allCustomTemplates;
    }

    return filterRecords(allCustomTemplates, searchTerm, {
      propertyName: 'name',
    });
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.allCustomTemplates = this.kpiDataFilter.getCustomTemplates();
    this._setupColumns();
  }

  _setupColumns() {
    let { l10n } = this;

    this.columns = [
      {
        title: l10n.t('Name'),
        propertyName: 'name',
        noCompactTitle: true,
      },
      {
        title: l10n.t('Creation date'),
        propertyName: 'creationDate',
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
      },
      {
        component: CellActions,
      },
    ];
  }

  @action
  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  deleteTemplateTask = dropTask(
    async (machineReportTemplate: KpiDataFilterTemplate) => {
      await this.kpiDataFilter.deleteCustomTemplate(machineReportTemplate);

      this.allCustomTemplates = this.kpiDataFilter.getCustomTemplates();

      this.analytics.addEvent('kpi-compare-report-template-delete');
    }
  );
}
