import Service, { service } from '@ember/service';
import updateLocationMutation from 'fabscale-app/gql/mutations/update-location.graphql';
import updateLocationSettingsMutation from 'fabscale-app/gql/mutations/update-location-settings.graphql';
import updateLocationShiftSettings from 'fabscale-app/gql/mutations/update-location-shift-settings.graphql';
import UserSessionService from 'fabscale-app/services/user-session';
import {
  Location,
  LocationSettingsInput,
  LocationShiftSettingsPojo,
} from 'fabscale-app/models/location';
import GraphQLService from 'fabscale-app/services/-graphql';
import jsonStringify from 'fabscale-app/helpers/json-stringify';

export default class StoreLocationService extends Service {
  @service userSession: UserSessionService;
  @service graphql: GraphQLService;

  async findById(id: string): Promise<Location | undefined> {
    return this.userSession.locations.find((location) => location.id === id);
  }

  // METHODS
  async update(
    id: string,
    { name, timezoneName }: { name?: string; timezoneName?: string }
  ): Promise<void> {
    let { graphql } = this;

    let variables = {
      id,
      name,
      timezoneName,
    };

    await graphql.mutate({
      mutation: updateLocationMutation,
      variables,
    });
  }

  async updateSettings(
    locationId: string,
    settings: LocationSettingsInput
  ): Promise<void> {
    let { graphql } = this;

    if (!locationId) {
      throw new Error(`Cannot update location settings without a locationId`);
    }

    let variables = {
      locationId,
      settings,
    };

    await graphql.mutate({
      mutation: updateLocationSettingsMutation,
      variables,
    });
  }

  async updateShiftSettings(
    locationId: string,
    shiftSettings: LocationShiftSettingsPojo
  ): Promise<void> {
    let { graphql } = this;

    if (!locationId) {
      throw new Error(`Cannot update location settings without a locationId`);
    }

    let variables = {
      locationId,
      shiftEnabled: shiftSettings.shiftEnabled,
      shiftSchedule: jsonStringify(shiftSettings.shiftSchedule),
    };

    await graphql.mutate({
      mutation: updateLocationShiftSettings,
      variables,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/location': StoreLocationService;
  }
}
