import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { enqueueTask, restartableTask } from 'ember-concurrency';
import { PlantAssetStatus } from 'fabscale-app/models/enums/plant-asset-status';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetStateInfo } from 'fabscale-app/models/plant-asset-state-info';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetInfoService from 'fabscale-app/services/store/plant-asset-info';

interface Args {
  plantAsset?: PlantAsset;
}

export default class PageDashboardCardsStatusMapLoadStateInfo extends Component<{
  Args: Args;
}> {
  @service('store/plant-asset-info')
  plantAssetInfoStore: StorePlantAssetInfoService;

  @service errorParser: ErrorParserService;

  @tracked error?: string;
  @tracked completedCount = 0;
  @tracked remainingCount = 0;

  get totalCount() {
    return this.completedCount + this.remainingCount;
  }

  get isLoading(): boolean {
    return this.loadStateInfoTask.isRunning;
  }

  get isLoadingFirstTime(): boolean {
    return this.isLoading && !this.loadStateInfoTask.lastSuccessful;
  }

  get plantAssetStateInfo(): PlantAssetStateInfo | undefined {
    return this.loadStateInfoTask.lastSuccessful?.value || undefined;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.loadStateInfoTask.perform();
  }

  loadStateInfoTask = restartableTask(async () => {
    let { plantAsset } = this.args;
    let plantAssetIds = plantAsset ? [plantAsset.id] : undefined;

    this.error = undefined;

    let filters: {
      plantAssetIds?: string[];
      status?: PlantAssetStatus[];
      reasonType: 'WITHOUT_REASON';
    } = {
      plantAssetIds,
      status: ['MACHINE_FAILURE'],
      reasonType: 'WITHOUT_REASON',
    };

    try {
      let { items, pageInfo } = await this.plantAssetInfoStore.findPaginated(
        filters,
        {
          pageSize: 1,
          page: 1,
        }
      );

      this.remainingCount = pageInfo.totalItemCount;

      return items[0];
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
    }
  });

  onCompleteTask = enqueueTask(async () => {
    await this.loadStateInfoTask.perform();

    this.completedCount++;
  });
}
