import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { AbilitiesService } from 'ember-can';
import { use } from 'ember-could-get-used-to-this';
import { RoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import LoadAvailableRoastBatchParameterTypesResource from 'fabscale-app/helpers/resources/load-available-roast-batch-parameter-types';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AvailableDataService from 'fabscale-app/services/available-data';
import SettingsService from 'fabscale-app/services/settings';
import { RoastBatchGoalResultFilter } from 'fabscale-app/services/store/roast-batch';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import { DateTime } from 'luxon';

export interface RoastBatchesOverviewFilters {
  dateRange?: DateRangeOptional;
  timeRange?: TimeRangeOptional;
  plantAssetIds?: string[];
  recipeIds?: string[];
  goalResults?: RoastBatchGoalResultFilter;
}

interface Args {
  filters: RoastBatchesOverviewFilters;
  updateFilters: (filters: RoastBatchesOverviewFilters) => void;
}

export const ROAST_BATCHES_DEFAULT_PARAMETER_TYPES: RoastBatchParameterType[] =
  [
    'INPUT_WEIGHT',
    'ROAST_DURATION',
    'CYCLE_DURATION',
    'ROAST_COLOR',
    'END_TEMPERATURE_ROAST',
  ];

export const ROAST_BATCHES_TABLE_ID = 'roast-batches-overview';

export default class RoastBatchesOverviewIndex extends Component<Args> {
  @service settings: SettingsService;
  @service availableData: AvailableDataService;
  @service abilities: AbilitiesService;

  @tracked showSelectColumns = false;
  @tracked _selectedRoastBatchParameterTypes: RoastBatchParameterType[];

  get selectedRoastBatchParameterTypes(): RoastBatchParameterType[] {
    let { availableRoastBatchParameterTypes } = this;

    // We want to exclude non-available types
    return this._selectedRoastBatchParameterTypes.filter((type) =>
      availableRoastBatchParameterTypes.includes(type)
    );
  }

  @use
  availableRoastBatchParameterTypesAsyncResource = new LoadAvailableRoastBatchParameterTypesResource(
    () => [{}]
  );

  get availableRoastBatchParameterTypes(): RoastBatchParameterType[] {
    let data = getAsyncResourceData(
      this.availableRoastBatchParameterTypesAsyncResource,
      undefined
    );

    if (!data) {
      return [];
    }

    return ([] as RoastBatchParameterType[]).concat(data.numeric, data.boolean);
  }

  get filters(): RoastBatchesOverviewFilters {
    return Object.assign({}, this.args.filters, { timeRange: this.timeRange });
  }

  get timeRange(): TimeRange {
    return Object.assign(
      {},
      this.defaultTimeRange,
      this.args.filters.timeRange
    );
  }

  @cached
  get defaultTimeRange() {
    let { dayStartTime } = this.settings.locationSettings;
    let end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get dateRange(): DateRange {
    return getActualDateRange(this.args.filters.dateRange);
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let tableConfig =
      this.settings.userFrontendSettings.tableConfig.dataForTableId(
        ROAST_BATCHES_TABLE_ID
      );

    this._selectedRoastBatchParameterTypes =
      tableConfig?.selectedRoastBatchParameterTypes ||
      ROAST_BATCHES_DEFAULT_PARAMETER_TYPES;
  }

  @action
  toggleShowSelectColumns() {
    this.showSelectColumns = !this.showSelectColumns;
  }

  @action
  updateSelectedRoastBatchParameterTypes(
    selectedRoastBatchParameterTypes: RoastBatchParameterType[]
  ) {
    this._selectedRoastBatchParameterTypes = selectedRoastBatchParameterTypes;

    this.settings.userFrontendSettings.tableConfig.update(
      ROAST_BATCHES_TABLE_ID,
      {
        selectedRoastBatchParameterTypes,
      }
    );
    this.settings.userFrontendSettings.save();
  }
}

// The default range is supposed to cover "all", but allow us to always have a date range to work with.
export function getActualDateRange(dateRange?: DateRangeOptional) {
  let { start, end } = dateRange || {};

  let mergeObj: DateRangeOptional = {};
  if (start) {
    mergeObj.start = start;
  }

  if (end) {
    mergeObj.end = end;
  }

  return new DateRange(
    Object.assign(
      {
        start: DateTime.local().minus({ years: 10 }).startOf('day'),
        end: DateTime.local().plus({ days: 1 }).startOf('day'),
      },
      mergeObj
    )
  );
}
