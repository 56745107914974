import { service } from '@ember/service';
import Component from '@glimmer/component';
import SettingsService from 'fabscale-app/services/settings';

export default class FunctionalParseOverTimeDates extends Component {
  @service settings: SettingsService;

  get dayStartTime() {
    return this.settings.locationSettings.dayStartTime;
  }
}
