import { guidFor } from '@ember/object/internals';
import { waitForPromise } from '@ember/test-waiters';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { enqueueTask, keepLatestTask, timeout } from 'ember-concurrency';
import { Resource, use } from 'ember-could-get-used-to-this';

interface Args {
  item: any;
}

/*
  This component will show a left-moving swiping transition when the passed in `item` changes.
  It will yield the item, which can be rendered in any way.
  This means that while the transition happens, multiple items can be rendered at the same time.
  "Old" items will be fully removed once they are moved out of view.
*/
export default class ModuleTransitionContainer extends Component<{
  Args: Args;
}> {
  // How long a transition takes
  transitionTime = macroCondition(isTesting()) ? 2 : 600;

  @use transitionViews = new TransitionResource(() => [
    this.args.item,
    this.transitionTime,
  ]);
}

type PositionalArgs = [item: any, transitionTime: number];

class TransitionItem {
  item: any;
  id: string;
  @tracked offset: number;

  constructor({ item, offset }: { item: any; offset: number }) {
    this.item = item;
    this.id = guidFor(item);
    this.offset = offset;
  }
}

class TransitionResource extends Resource<PositionalArgs, any> {
  transitionTime: number;

  @tracked items: TransitionItem[] = [];

  get value() {
    return this.items;
  }

  setup() {
    let [item, transitionTime] = this.args.positional;

    this.items = [new TransitionItem({ item, offset: 0 })];
    this.transitionTime = transitionTime;
  }

  update() {
    let [item] = this.args.positional;
    let { items } = this;

    let id = guidFor(item);

    if (items.some((item) => item.id === id)) {
      return;
    }

    this.addItemTask.perform(items, item);
  }

  addItemTask = enqueueTask(async (items: TransitionItem[], item: any) => {
    // We have to wait a tick before we add a new item, to prevent a write-after-read assertion
    await waitForAnimationFrame();
    await timeout(1);

    // add the new item to the right
    items.push(
      new TransitionItem({
        item,
        offset: items[items.length - 1]!.offset + 1,
      })
    );

    this.items = items;

    // Wait a tick for everything to render
    await waitForAnimationFrame();
    await timeout(1);

    // Now move all cards one position to the left
    this.moveLeftTask.perform();
  });

  moveLeftTask = keepLatestTask(async () => {
    let { items, transitionTime } = this;

    // offset is tracked, so this will rerender
    items.forEach((item) => item.offset--);

    // Wait for animations to finish
    await timeout(transitionTime);

    // Remove elements that are out of screen on left side
    let cleanedUpItems = items.filter((item) => item.offset >= 0);
    this.items = cleanedUpItems;

    // Maybe run again (if multiple have been added)
    if (cleanedUpItems.length > 1) {
      await timeout(transitionTime);
      this.moveLeftTask.perform();
    }
  });
}

async function waitForAnimationFrame() {
  let promise = new Promise((resolve) => {
    window.requestAnimationFrame(resolve);
  });

  return waitForPromise(promise);
}
