import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { NumericRoastBatchParameterType } from 'fabscale-app/models/enums/roast-batch-parameter-type';
import {
  ParameterGoalSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';

export default class ParameterGoalsIndexController extends Controller {
  queryParams = [
    'parameterType',
    'search',
    'page',
    'sortBy',
    'sortDirection',
    'pageSize',
  ];

  @tracked search?: string;
  @tracked parameterType?: NumericRoastBatchParameterType;
  @tracked sortBy?: ParameterGoalSortOption;
  @tracked sortDirection?: SortDirection;

  // These are deserialized from the URL as strings, so we need to ensure they are converted to numbers
  @tracked page?: number | string;
  @tracked pageSize?: number | string;

  get pageNumber() {
    return typeof this.page === 'string' ? parseInt(this.page) : this.page;
  }

  get pageSizeNumber() {
    return typeof this.pageSize === 'string'
      ? parseInt(this.pageSize)
      : this.pageSize;
  }

  @action
  updateParameterType(parameterType?: NumericRoastBatchParameterType) {
    this.parameterType = parameterType;
    this.page = undefined;
  }

  @action
  updateSearch(search?: string) {
    this.search = search;
    this.page = undefined;
  }

  @action
  updatePage(page?: number) {
    if (page !== this.page) {
      this.page = page;
    }
  }

  @action
  updatePageSize(pageSize?: number) {
    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.page = undefined;
    }
  }

  @action
  updateSortBy(sortBy?: ParameterGoalSortOption) {
    if (sortBy !== this.sortBy) {
      this.sortBy = sortBy;
      this.page = undefined;
    }
  }

  @action
  updateSortDirection(sortDirection?: SortDirection) {
    if (sortDirection !== this.sortDirection) {
      this.sortDirection = sortDirection;
      this.page = undefined;
    }
  }
}
