import { service } from '@ember/service';
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';

export abstract class EnumsBase<EnumType> extends EmberObject {
  @service l10n: L10nService;

  labels: any = {};

  getLabel(enumValue: EnumType): string {
    let label = this.labels[enumValue];

    assert(
      `Could not find enum "${enumValue}" - allowed values: ${Object.keys(
        this.labels
      )}`,
      typeof label !== 'undefined'
    );

    return typeof label === 'undefined' ? enumValue : label;
  }
}
