import Transition from '@ember/routing/-private/transition';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import RoastBatchesShowIndexController from './controller';

export default class RoastBatchesShowIndexRoute extends AuthenticatedRoute {
  resetController(
    controller: RoastBatchesShowIndexController,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.details = undefined;
    }
  }
}
