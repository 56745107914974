import Service, { service } from '@ember/service';
import { AbilitiesService } from 'ember-can';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { DashboardCard } from 'fabscale-app/models/dashboard-config';
import { UserFrontendSettings } from 'fabscale-app/models/user-frontend-settings';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import AvailableDataService from './available-data';
import SettingsService from './settings';

export default class DashboardConfigManagerService extends Service {
  @service settings: SettingsService;
  @service abilities: AbilitiesService;
  @service availableData: AvailableDataService;

  get userFrontendSettings(): UserFrontendSettings {
    return this.settings!.userFrontendSettings;
  }

  async saveDashboardCards(cards: DashboardCard[] | undefined) {
    let newFullConfig = Object.assign(
      {},
      this.userFrontendSettings.dashboardConfig,
      {
        cards,
      }
    );

    this.userFrontendSettings.dashboardConfig = newFullConfig;

    await this.userFrontendSettings.save();
  }

  async saveDashboardHeaderIsCollapsed(headerIsCollapsed: boolean) {
    let newFullConfig = Object.assign(
      {},
      this.userFrontendSettings.dashboardConfig,
      {
        headerIsCollapsed,
      }
    );

    this.userFrontendSettings.dashboardConfig = newFullConfig;

    await this.userFrontendSettings.save();
  }

  async getDefaultDashboardCards(): Promise<DashboardCard[]> {
    let availableKpiTypes = await this.availableData.getAvailableKpiTypes();

    function useIfAvailableElse(
      kpiType: KpiType,
      fallbackKpiType: KpiType
    ): KpiType {
      return availableKpiTypes.includes(kpiType) ? kpiType : fallbackKpiType;
    }

    let hasTasks = this.abilities.can('view maintenance-task');
    let hasStatusMap = this.abilities.can('view plant-asset-info');

    return [
      {
        id: 0,
        x: 0,
        y: 0,
        height: 2,
        width: 1,
        config: {
          cardType: 'KPI_PER_PLANT_ASSET',
          kpiType: 'UPTIME',
          color: chartColors[4],
        },
      },
      {
        id: 1,
        x: 1,
        y: 0,
        width: 1,
        height: 2,
        config: hasStatusMap
          ? { cardType: 'STATUS_MAP', color: chartColors[15] }
          : {
              cardType: 'KPI_PER_PLANT_ASSET',
              kpiType: 'NUMBER_OF_BATCHES',
              color: chartColors[15],
            },
      },
      {
        id: 2,
        x: 2,
        y: 0,
        height: 1,
        width: 1,
        config: { cardType: 'ROAST_SUMMARY' },
      },
      {
        id: 3,
        x: 3,
        y: 0,
        height: 1,
        width: 1,
        config: {
          cardType: 'KPI_TOTAL_VALUE',
          kpiType: 'NUMBER_OF_BAD_BATCHES',
          color: chartColors[2],
        },
      },
      {
        id: 4,
        x: 2,
        y: 1,
        width: 2,
        height: 2,
        config: hasTasks
          ? { cardType: 'TASKS_OPEN', color: chartColors[13] }
          : {
              cardType: 'KPI_OVER_TIME',
              kpiType: 'UPTIME',
              color: chartColors[13],
            },
      },
      {
        id: 5,
        x: 0,
        y: 2,
        height: 2,
        width: 2,
        config: { cardType: 'KPI_OVER_TIME', kpiType: 'TOTAL_INPUT_WEIGHT' },
      },
      {
        id: 6,
        x: 0,
        y: 4,
        width: 1,
        height: 1,
        config: {
          cardType: 'KPI_TOTAL_VALUE',
          kpiType: useIfAvailableElse(
            'GAS_CONSUMPTION_PER_100KG',
            'AVERAGE_BATCH_SIZE'
          ),
          color: chartColors[16],
        },
      },
      {
        id: 7,
        x: 1,
        y: 4,
        height: 1,
        width: 1,
        config: {
          cardType: 'KPI_TOTAL_VALUE',
          kpiType: useIfAvailableElse(
            'ELECTRICITY_CONSUMPTION_PER_100KG',
            'NUMBER_OF_BATCHES_WITH_FAILED_GOALS'
          ),
          color: chartColors[1],
        },
      },
      {
        id: 8,
        x: 2,
        y: 3,
        height: 2,
        width: 2,
        config: {
          cardType: 'KPI_OVER_TIME',
          kpiType: 'INPUT_WEIGHT_PERFORMANCE',
        },
      },
    ];
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'dashboard-config-manager': DashboardConfigManagerService;
  }
}
