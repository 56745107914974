import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { KpiType } from 'fabscale-app/models/enums/kpi-types';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { PlantAsset } from './plant-asset';

export const DASHBOARD_CURRENT_VERSION = 3;

export interface DashboardConfig {
  version: number;
  cards?: DashboardCard[];
  headerIsCollapsed?: boolean;
}

export interface DashboardConfigInput {
  version: number;
  cards?: DashboardCardInput[];
  headerIsCollapsed?: boolean;
}

export interface DashboardCardConfigKpiOverTime {
  cardType: 'KPI_OVER_TIME';
  kpiType: KpiType;
  color?: string;
}

export interface DashboardCardConfigKpiPerPlantAsset {
  cardType: 'KPI_PER_PLANT_ASSET';
  kpiType: KpiType;
  color?: string;
}

export interface DashboardCardConfigKpiPerRecipe {
  cardType: 'KPI_PER_RECIPE';
  kpiType: KpiType;
  color?: string;
}

export interface DashboardCardConfigKpiTotalValue {
  cardType: 'KPI_TOTAL_VALUE';
  kpiType: KpiType;
  color?: string;
}

export interface DashboardCardConfigOeeKpi {
  cardType: 'OEE_KPI' | 'OEE_KPI_LARGE';
  kpiTypes: KpiType[];
  color?: string;
  heading?: string;
  configHeading?: string;
  plantAsset: PlantAsset;
  availablePlantAssets: PlantAsset[];
}
export interface DashboardCardConfigRoastSummary {
  cardType: 'ROAST_SUMMARY';
  color?: string;
}

export interface DashboardCardConfigStatusMap {
  cardType: 'STATUS_MAP';
  color?: string;
  plantAssetId?: string;
}

export interface DashboardCardConfigIncomplete {
  cardType: DashboardCardType;
  color?: string;
}

export type DashboardCardConfig =
  | DashboardCardConfigKpiOverTime
  | DashboardCardConfigKpiPerPlantAsset
  | DashboardCardConfigKpiPerRecipe
  | DashboardCardConfigKpiTotalValue
  | DashboardCardConfigRoastSummary
  | DashboardCardConfigStatusMap
  | DashboardCardConfigIncomplete
  | DashboardCardConfigOeeKpi;

export interface DashboardCardInput {
  x: number;
  y: number;
  width: number;
  height: number;
  config: DashboardCardConfig;
}

export interface DashboardCardPosition {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

export type DashboardCard = DashboardCardPosition & {
  config: DashboardCardConfig;
};

export const DASHBOARD_DEFAULT_COLOR = chartColors[0];

export type DashboardCardType =
  | 'KPI_OVER_TIME'
  | 'KPI_PER_PLANT_ASSET'
  | 'KPI_TOTAL_VALUE'
  | 'KPI_PER_RECIPE'
  | 'ROAST_SUMMARY'
  | 'TASKS_OPEN'
  | 'TASKS_MY_OPEN'
  | 'STATUS_MAP'
  | 'OEE_KPI'
  | 'OEE_KPI_LARGE';

export type DashboardCardKpiType =
  | 'KPI_OVER_TIME'
  | 'KPI_PER_PLANT_ASSET'
  | 'KPI_TOTAL_VALUE'
  | 'KPI_PER_RECIPE';

export type DashboardCardConfigKpi =
  | DashboardCardConfigKpiOverTime
  | DashboardCardConfigKpiPerPlantAsset
  | DashboardCardConfigKpiPerRecipe
  | DashboardCardConfigKpiTotalValue;

export function configIsKpiCard(
  config: DashboardCardConfig
): config is DashboardCardConfigKpi {
  return [
    'KPI_OVER_TIME',
    'KPI_PER_PLANT_ASSET',
    'KPI_PER_RECIPE',
    'KPI_TOTAL_VALUE',
  ].includes(config.cardType);
}

export interface DashboardCardAddConfig {
  type: DashboardCardType;
  width: number;
  height: number;
  label: string;
  icon: string;
}

export function getDashboardCardForType(
  cardType: DashboardCardType,
  l10n: L10nService
): DashboardCardAddConfig {
  switch (cardType) {
    case 'KPI_OVER_TIME':
      return {
        type: 'KPI_OVER_TIME',
        label: l10n.t('KPI over time'),
        icon: 'drag-n-drop',
        width: 1,
        height: 2,
      };
    case 'KPI_PER_PLANT_ASSET':
      return {
        type: 'KPI_PER_PLANT_ASSET',
        label: l10n.t('KPI per roaster'),
        icon: 'drag-n-drop',
        width: 1,
        height: 2,
      };
    case 'KPI_PER_RECIPE':
      return {
        type: 'KPI_PER_RECIPE',
        label: l10n.t('KPI per recipe'),
        icon: 'drag-n-drop',
        width: 1,
        height: 2,
      };
    case 'KPI_TOTAL_VALUE':
      return {
        type: 'KPI_TOTAL_VALUE',
        label: l10n.t('KPI total value'),
        icon: 'drag-n-drop',
        width: 1,
        height: 1,
      };
    case 'ROAST_SUMMARY':
      return {
        type: 'ROAST_SUMMARY',
        label: l10n.t('Roast summary'),
        icon: 'drag-n-drop',
        width: 1,
        height: 1,
      };
    case 'TASKS_OPEN':
      return {
        type: 'TASKS_OPEN',
        label: l10n.t('Open tasks'),
        icon: 'drag-n-drop',
        width: 2,
        height: 2,
      };
    case 'TASKS_MY_OPEN':
      return {
        type: 'TASKS_MY_OPEN',
        label: l10n.t('My tasks'),
        icon: 'drag-n-drop',
        width: 2,
        height: 2,
      };
    case 'STATUS_MAP':
      return {
        type: 'STATUS_MAP',
        label: l10n.t('Status map'),
        icon: 'drag-n-drop',
        width: 1,
        height: 2,
      };
    case 'OEE_KPI':
      return {
        type: 'OEE_KPI',
        label: l10n.t('OEE KPI'),
        icon: 'drag-n-drop',
        width: 1,
        height: 2,
      };
    case 'OEE_KPI_LARGE':
      return {
        type: 'OEE_KPI_LARGE',
        label: l10n.t('OEE KPI (Large)'),
        icon: 'drag-n-drop',
        width: 2,
        height: 2,
      };
  }
}

export function getDashboardCardForTypeVariant(
  cardType: DashboardCardType,
  l10n: L10nService,
  variant: { height: number; width: number; label: string }
) {
  let config = getDashboardCardForType(cardType, l10n);
  Object.assign(config, variant);
  return config;
}
