import { parseTime } from 'fabscale-app/utilities/utils/parse-time';
import { DateTime } from 'luxon';

export function dateAdjustedToday(dayStartTime: string): DateTime {
  let now = DateTime.local();
  let today = now.startOf('day');

  let timeParts = parseTime(dayStartTime);

  let todayWithStartTime = now.set(timeParts);

  if (+now < +todayWithStartTime) {
    return today.minus({ days: 1 });
  }

  return today;
}
