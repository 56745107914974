import { service } from '@ember/service';
import { UserInfo } from 'fabscale-app/models/user-info';
import StoreUserService from 'fabscale-app/services/store/user';
import BaseAsyncResource from './base-async-resource';

export default class LoadUserInfosResource extends BaseAsyncResource<
  UserInfo[],
  any
> {
  @service('store/user') userStore: StoreUserService;

  loadData() {
    return this.userStore.findInfoAll();
  }
}
