export class PermissionError extends Error {
  translatedMessage?: string;
  code = 'PERMISSION_ERROR';

  constructor(message: string) {
    super(message);

    this.translatedMessage = message;
  }
}
