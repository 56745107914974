import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import { Resource } from 'ember-could-get-used-to-this';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { logError } from 'fabscale-app/utilities/utils/log-error';

export interface AsyncResource<DataType> {
  isLoading: boolean;
  data: DataType | string | undefined;
  isError: boolean;
}

export default class BaseAsyncResource<
  DataType,
  AsyncResourceOptions
> extends Resource<[AsyncResourceOptions], any> {
  @service('error-parser') errorParser: ErrorParserService;

  @tracked isError = false;
  @tracked error?: string;
  @tracked data?: DataType;

  _lastOptions?: AsyncResourceOptions;
  _afterLoadData?: (options: AsyncResourceOptions) => void;

  get value(): AsyncResource<DataType> {
    let isLoading = this.loadDataTask.isRunning;
    let { isError } = this;
    let data = isError ? this.error : this.data;

    return {
      isLoading,
      data,
      isError,
    };
  }

  setup() {
    let [options] = this.args.positional;

    this.loadDataTask.perform(options);
  }

  teardown() {
    this.loadDataTask.cancelAll();
  }

  loadDataTask = restartableTask(async (optionsProxy: AsyncResourceOptions) => {
    this.isError = false;

    let options = Object.assign({}, optionsProxy);

    try {
      let data = await this.loadData(options);
      this.data = data;
      this._lastOptions = options;
    } catch (error) {
      this.error = this.errorParser.getErrorMessage(error);
      this.isError = true;

      logError(error);
    }

    if (this._afterLoadData) {
      this._afterLoadData(options);
    }
  });

  loadDataIfUpdated(optionsProxy: AsyncResourceOptions) {
    let options = Object.assign({}, optionsProxy);

    if (this._optionsHaveChanged(options)) {
      this.loadDataTask.perform(options);
      return true;
    }

    return false;
  }

  _optionsHaveChanged(options: AsyncResourceOptions) {
    let lastOptions = this._lastOptions;

    return JSON.stringify(options) !== JSON.stringify(lastOptions);
  }

  loadData(options: AsyncResourceOptions): Promise<DataType | undefined>;
  async loadData() {
    assert('AsycnResource: You have to overwrite loadData!', false);
    return undefined;
  }
}
