import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import {
  ParameterGoal,
  ParameterGoalInput,
} from 'fabscale-app/models/parameter-goal';
import StoreParameterGoalService from 'fabscale-app/services/store/parameter-goal';

interface Args {
  parameterGoal: ParameterGoal;
}

export default class PagePlantParameterGoalsEdit extends Component<Args> {
  @service('store/parameter-goal')
  parameterGoalStore: StoreParameterGoalService;

  @service router: RouterService;

  updateParameterGoalTask = dropTask(async (data: ParameterGoalInput) => {
    await this.parameterGoalStore.update(this.args.parameterGoal.id, data);

    this.router.transitionTo(
      'routes/plant-settings.parameter-goals.show.index',
      this.args.parameterGoal.id
    );
  });
}
